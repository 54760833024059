import ApiManager from '../../../api/ApiManager'
import Button from '../../common/Button/Button.component'
import { HttpMethods } from '../../../enums/HttpMethodsEnum'
import PasswordField from '../../common/InputField/PasswordField.component'
import PasswordStrength from '../../common/PasswordStrength/PasswordStrength.component'
import React from 'react'
import RouteConstants from '../../../constants/RouteConstants'
import { SnackbarTypes } from '../../../enums/SnackbarTypesEnum'
import StringConstants from '../../../constants/StringConstants'
import UrlConstants from '../../../constants/UrlConstants'
import ValidationUtils from '../../../utils/validation/ValidationUtils'
import customerStore from '../../../datastore/CustomerStore'
import { getApiErrorMessage } from '../../../utils/StringUtils'
import { getUserDataFromCookie } from '../../../utils/AuthUtils'
import { isEmpty } from 'lodash'
import { observer } from 'mobx-react'
import snackbarStore from '../../../datastore/SnackbarStore'
import styled from '@emotion/styled'
import theme from '../../../global/theme'
import { useNavigate } from 'react-router-dom'
import { withRouter } from '../../common/WithRouter/WithRouter.component'

interface ResetProps {
  handleNext: () => void
  handleBack: () => void
  getPassword: () => string
  setPassword: (pwd: string) => void
}

interface ChangePasswordBody {
  currentPassword: string
  newPassword: string
}

const Container = styled.div`
  width: 400px;
  h2 {
    color: ${theme.colors.primary};
    padding: 20px 0px;
    font-weight: bold;
  }

  p {
    padding-top: 10px;
    color: ${theme.colors.primary};
    text-decoration: underline;
    font-weight: bold;
  }

  .MuiFormHelperText-root.Mui-error.MuiFormHelperText-sizeSmall.MuiFormHelperText-contained {
    font-weight: 400;
    text-decoration: none;
  }

  @media screen and (max-width: 470px) {
    width: 320px;
  }
`

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 30px;

  @media screen and (max-width: 500px) {
    flex-direction: column;
  }
`

const ButtonWrapper = styled.div`
  width: 150px;
  @media screen and (max-width: 500px) {
    width: 100%;
  }
`

const Reset: React.FC<ResetProps> = (props: ResetProps) => {
  const currentPassword = props.getPassword()
  const name = customerStore.getFullName()
  const { email, phone } = customerStore
  const navigate = useNavigate()
  const [password, setPassword] = React.useState('')
  const [confirmPassword, setConfirmPassword] = React.useState('')
  const [state, setState] = React.useState({
    passwordError: false,
    passwordHelperText: '',
    confirmPasswordError: false,
    confirmPasswordHelperText: '',
    apiError: false,
    apiErrorText: '',
    loading: false,
  })
  const [disabled, setDisabled] = React.useState(false)
  React.useEffect(() => {
    window.scroll({ top: 0, behavior: 'smooth' })
  }, [])
  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value)
    const passwordError = isEmpty(e.target.value)
    setState({
      ...state,
      passwordError,
      passwordHelperText: passwordError
        ? 'New password should have some value'
        : '',
    })
  }

  const validatePasswordOnBlur = (_e: React.FocusEvent<HTMLInputElement>) => {
    const passwordError = isEmpty(password)
    setState({
      ...state,
      passwordError,
      passwordHelperText: passwordError
        ? 'New password should have some value'
        : '',
    })
  }

  const handleConfirmPasswordChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setConfirmPassword(e.target.value)
    const confirmPasswordError = isEmpty(e.target.value)
    setState({
      ...state,
      confirmPasswordError,
      confirmPasswordHelperText: confirmPasswordError
        ? 'Confirm password should have some value'
        : '',
    })
  }

  const validateConfirmPasswordOnBlur = (
    _e: React.FocusEvent<HTMLInputElement>
  ) => {
    const confirmPasswordError = isEmpty(confirmPassword)
    setState({
      ...state,
      confirmPasswordError,
      confirmPasswordHelperText: confirmPasswordError
        ? 'Confirm password should have some value'
        : '',
    })
  }

  const isValidPassword = (pwd: string) => {
    return ValidationUtils.validatePasswordToBeSet(pwd)
  }

  const handleNext = (_e: React.MouseEvent<HTMLButtonElement>) => {
    setDisabled(true)
    const passwordError = !isValidPassword(password)
    const passwordHelperText =
      passwordError === true ? 'Please check Password strength indicators.' : ''
    const confirmPasswordError =
      password.valueOf() !== confirmPassword.valueOf()
    const confirmPasswordHelperText =
      confirmPasswordError === true
        ? 'Confirm password entry should match New password entry.'
        : ''

    if (isEmpty(password)) {
      setDisabled(false)
      setState({
        ...state,
        passwordError: true,
        passwordHelperText: 'New password should have some value',
      })
    } else if (isEmpty(confirmPassword)) {
      setDisabled(false)
      setState({
        ...state,
        confirmPasswordError: true,
        confirmPasswordHelperText: 'Confirm password should have some value',
      })
    } else if (passwordError) {
      setDisabled(false)
      setState({
        ...state,
        passwordError,
        passwordHelperText,
      })
    } else if (confirmPasswordError) {
      setDisabled(false)
      setState({
        ...state,
        confirmPasswordError,
        confirmPasswordHelperText,
      })
    } else if (
      password.length > 0 &&
      ((email &&
        (password.toLowerCase().includes(email.toLowerCase()) ||
          password
            .toLowerCase()
            .includes(email.toLowerCase().split('@')[0]))) ||
        (phone &&
          (password.toLowerCase().includes(phone) ||
            password.toLowerCase().includes(phone.substring(-10)))) ||
        (name &&
          (password.toLowerCase().includes(name.toLowerCase()) ||
            name
              .toLowerCase()
              .split(' ')
              .some(
                (subName) =>
                  subName.length > 1 && password.toLowerCase().includes(subName)
              ))))
    ) {
      setDisabled(false)
      setState({
        ...state,
        passwordError: true,
        passwordHelperText: 'Please check Password strength indicators.',
      })
    } else {
      const user = getUserDataFromCookie()
      if (!isEmpty(user)) {
        const requestBody: ChangePasswordBody = {
          currentPassword: currentPassword,
          newPassword: password,
        }

        setState({
          ...state,
          loading: true,
        })

        ApiManager.makeApiCallWithAuthentication(
          UrlConstants.CHANGE_PASSWORD.USECASE,
          HttpMethods.PATCH_METHOD,
          requestBody
        )
          .then((response) => {
            if (response.status === 200) {
              snackbarStore.set({
                snackbarOpen: true,
                snackbarMessage: 'Password changed successfully',
                snackbarType: SnackbarTypes.SUCCESS,
              })
              if (customerStore.status === 'new')
                customerStore.setStatus('active')
              props.setPassword(password)
              props.handleNext()
            } else throw response
          })
          .catch((err) => {
            if (
              err.data?.message ===
              StringConstants.API_ERROR_MSG_FOR_INVALID_CURRENT_PASSWORD
            ) {
              snackbarStore.set({
                snackbarOpen: true,
                snackbarMessage:
                  StringConstants.UI_ERROR_MSG_FOR_INVALID_CURRENT_PASSWORD,
                snackbarType: SnackbarTypes.ERROR,
              })
            } else if (
              err.data?.message ===
              StringConstants.API_ERROR_MSG_FOR_CHANGE_PASSWORD
            ) {
              snackbarStore.set({
                snackbarOpen: true,
                snackbarMessage:
                  StringConstants.UI_ERROR_MSG_FOR_INVALID_CHANGE_PASSWORD,
                snackbarType: SnackbarTypes.ERROR,
              })
            } else if (
              err.data?.message ===
              StringConstants.API_ERROR_MSG_FOR_SAME_PASSWORD_RESET
            ) {
              snackbarStore.set({
                snackbarOpen: true,
                snackbarMessage:
                  StringConstants.UI_ERROR_MSG_FOR_REUSING_CURRENT_PASSWORD,
                snackbarType: SnackbarTypes.ERROR,
              })
            } else {
              snackbarStore.set({
                snackbarOpen: true,
                snackbarMessage: getApiErrorMessage(
                  'change password. Please try again later'
                ),
                snackbarType: SnackbarTypes.ERROR,
              })
            }
          })
          .finally(() => {
            setDisabled(false)
            setState({
              ...state,
              loading: false,
            })
          })
      } else {
        setDisabled(false)
        navigate(RouteConstants.LOGIN)
      }
    }
  }

  return (
    <Container>
      <h2>Choose a strong password</h2>
      <PasswordField
        error={state.passwordError}
        helperText={state.passwordHelperText}
        fullWidth
        label='Enter new Password'
        value={password}
        color='info'
        onChange={handlePasswordChange}
        onBlur={validatePasswordOnBlur}
      />
      <PasswordField
        error={state.confirmPasswordError}
        helperText={state.confirmPasswordHelperText}
        fullWidth
        label='Confirm Password'
        color='info'
        value={confirmPassword}
        onChange={handleConfirmPasswordChange}
        onBlur={validateConfirmPasswordOnBlur}
      />
      <PasswordStrength
        password={password}
        name={name}
        email={email}
        phone={phone}
      />
      <ButtonContainer>
        <ButtonWrapper>
          <Button width='100%' color='info' onClick={props.handleBack}>
            Back
          </Button>
        </ButtonWrapper>
        <ButtonWrapper>
          <Button
            width='100%'
            color='secondary'
            variant='contained'
            onClick={handleNext}
            loading={state.loading}
            disabled={disabled}
          >
            Next
          </Button>
        </ButtonWrapper>
      </ButtonContainer>
    </Container>
  )
}

export default withRouter(observer(Reset))
