const convertToUSCurrency = (priceToBeConverted: number) => {
  if (priceToBeConverted === null) return '-'
  else {
    let currencyFormatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
    })
    return currencyFormatter.format(priceToBeConverted)
  }
}

export { convertToUSCurrency }
