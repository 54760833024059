export class StateAndMSPlanTypesConstants {
  public static readonly StdPlans: string[] = [
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
    'HDG',
    'HDF',
    'I',
    'K',
    'L',
    'M',
    'N',
  ]
}
