import React, { useEffect, useState } from 'react'

import ApiManager from '../../../api/ApiManager'
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import CollapsibleContainer from '../CollapsibleContainer'
import { HttpMethods } from '../../../enums/HttpMethodsEnum'
import InfoTooltip from '../../common/Tooltip/InfoTooltip.component'
import { MSPlan } from '../../../interface/quotes/mSupplementPlans/MSPlan'
import { MSPlanDiscountsAppliedType } from '../../../datastore/CustomerStore'
import StringConstants from '../../../constants/StringConstants'
import TableColumn from '../TableColumn'
import TableRow from '../TableRow'
import UrlConstants from '../../../constants/UrlConstants'
import { getApiErrorMessage } from '../../../utils/StringUtils'
import { getMonthlyPremiumWithDiscounts } from '../../../utils/DiscountCalculationUtils'
import { isEmpty } from 'lodash'
import snackbarStore from '../../../datastore/SnackbarStore'

interface TableProps {
  cardDetailsInOrder: (MSPlan | undefined)[]
}

const MedicareSupplementTable: React.FC<TableProps> = ({
  cardDetailsInOrder,
}) => {
  const [medigapChartDetails, setMedigapChartDetails] = useState<any>({})

  useEffect(() => {
    ApiManager.makeApiCall(
      UrlConstants.GET_MEDIGAP_CHART_DETAILS.USECASE,
      HttpMethods.GET_METHOD
    )
      .then((response) => {
        if (response.status === 200) {
          setMedigapChartDetails(response.data.data)
        } else throw response
      })
      .catch(() => {
        snackbarStore.set({
          snackbarOpen: true,
          snackbarMessage: getApiErrorMessage(
            'retrieve medigap chart details.'
          ),
          snackbarType: 'error',
        })
      })
  }, [])

  const monthlyPremiumWithDiscounts = cardDetailsInOrder.map(
    (iterator) =>
      (iterator &&
        getMonthlyPremiumWithDiscounts(
          iterator.mSupplementRate.month / 100,
          iterator.id
        )) ||
      0
  )

  const householdDiscounts = cardDetailsInOrder.map(
    (iterator) =>
      (iterator && iterator.discounts
        ? iterator.discounts.find((x) => x.name === 'household')
          ? (iterator.discounts.find((x) => x.name === 'household')?.value ||
              0) * 100
          : 0
        : 0) || 0
  )

  return (
    <>
      <CollapsibleContainer
        header='Estimated Costs and Savings'
        className='scrollTarget'
        isCollapsed={false}
      >
        <TableRow
          header='Monthly Premium'
          tooltip={StringConstants.TOOLTIP_MSG_MONTHLY_PREMIUM}
        >
          {cardDetailsInOrder.map((iterator, index) => (
            <TableColumn key={index}>
              $
              {iterator
                ? monthlyPremiumWithDiscounts[index].toFixed(2)
                : (0).toFixed(2) || (0).toFixed(2)}
            </TableColumn>
          ))}
        </TableRow>
        <TableRow
          header='Household Discount'
          background='dark'
          tooltip={StringConstants.TOOLTIP_MSG_MSPLAN_HH_DISCOUNT}
        >
          {cardDetailsInOrder.map((iterator, index) => (
            <TableColumn key={index}>
              {iterator
                ? householdDiscounts[index].toFixed(2)
                : (0).toFixed(2) || (0).toFixed(2)}
              %
            </TableColumn>
          ))}
        </TableRow>
        <TableRow
          header='Monthly Premium with Household Discount'
          tooltip={
            StringConstants.TOOLTIP_MSG_MSPLAN_MONTHLY_PREMIUM_WITH_HH_DISCOUNT
          }
        >
          {cardDetailsInOrder.map((iterator, index) => (
            <TableColumn key={index}>
              $
              {iterator
                ? getMonthlyPremiumWithDiscounts(
                    monthlyPremiumWithDiscounts[index],
                    iterator.id,
                    {
                      discountsName: ['household'],
                      discountsPercent: householdDiscounts[index] / 100,
                    } as any as MSPlanDiscountsAppliedType
                  ).toFixed(2)
                : (0).toFixed(2) || (0).toFixed(2)}
            </TableColumn>
          ))}
        </TableRow>
      </CollapsibleContainer>

      <CollapsibleContainer
        header='Plan Details'
        className='scrollTarget'
        isCollapsed={false}
      >
        <TableRow
          header='Carrier'
          tooltip={StringConstants.TOOLTIP_MSG_CARRIER}
        >
          {cardDetailsInOrder.map((iterator, index) => (
            <TableColumn key={index}>
              {iterator?.msCompanyBase.name || 'NA'}
            </TableColumn>
          ))}
        </TableRow>
        <TableRow
          header='Plan Type'
          background='dark'
          tooltip={StringConstants.TOOLTIP_MSG_MSPLAN_TYPE}
        >
          {cardDetailsInOrder.map((iterator, index) => (
            <TableColumn key={index}>
              {iterator?.planName === 'N' ? (
                <span style={{ display: 'flex', alignItems: 'center' }}>
                  {'Plan ' + iterator.planName}
                  <InfoTooltip
                    title='Plan N pays 100% of the Part B
            coinsurance, except for a copayment of up to $20 for some office
            visits and up to $50 copayment for emergency room visits that don’t
            result in an inpatient admission.'
                  />
                </span>
              ) : (
                'Plan ' + iterator?.planName
              )}
            </TableColumn>
          ))}
        </TableRow>
      </CollapsibleContainer>

      <CollapsibleContainer
        header='Premiums'
        className='scrollTarget'
        isCollapsed={false}
      >
        <TableRow
          header='Monthly Premium'
          tooltip={StringConstants.TOOLTIP_MSG_PREMIUM_MONTHLY}
        >
          {cardDetailsInOrder.map((iterator, index) => (
            <TableColumn key={index}>
              $
              {iterator
                ? monthlyPremiumWithDiscounts[index].toFixed(2)
                : (0).toFixed(2) || (0).toFixed(2)}
            </TableColumn>
          ))}
        </TableRow>
        <TableRow
          header='Quarterly Premium'
          background='dark'
          tooltip={StringConstants.TOOLTIP_MSG_PREMIUM_QUARTERLY}
        >
          {cardDetailsInOrder.map((iterator, index) => (
            <TableColumn key={index}>
              $
              {iterator
                ? (monthlyPremiumWithDiscounts[index] * 3).toFixed(2)
                : (0).toFixed(2) || (0).toFixed(2)}
            </TableColumn>
          ))}
        </TableRow>
        <TableRow
          header='Semi-Annual Premium'
          tooltip={StringConstants.TOOLTIP_MSG_PREMIUM_SEMI_ANNUALLY}
        >
          {cardDetailsInOrder.map((iterator, index) => (
            <TableColumn key={index}>
              $
              {iterator
                ? (monthlyPremiumWithDiscounts[index] * 6).toFixed(2)
                : (0).toFixed(2) || (0).toFixed(2)}
            </TableColumn>
          ))}
        </TableRow>
        <TableRow
          header='Annual Premium'
          background='dark'
          tooltip={StringConstants.TOOLTIP_MSG_PREMIUM_ANNUALLY}
        >
          {cardDetailsInOrder.map((iterator, index) => (
            <TableColumn key={index}>
              $
              {iterator
                ? (monthlyPremiumWithDiscounts[index] * 12).toFixed(2)
                : (0).toFixed(2) || (0).toFixed(2)}
            </TableColumn>
          ))}
        </TableRow>
      </CollapsibleContainer>

      <CollapsibleContainer
        header='Ratings'
        className='scrollTarget'
        isCollapsed={false}
      >
        <TableRow
          header='AM Best Outlook'
          tooltip={StringConstants.TOOLTIP_MSG_AM_BEST_OUTLOOK}
        >
          {cardDetailsInOrder.map((iterator, index) => (
            <TableColumn key={index}>
              {iterator?.msCompanyBase.ambestOutlook}
            </TableColumn>
          ))}
        </TableRow>
        <TableRow
          header='AM Best Rating'
          background='dark'
          tooltip={StringConstants.TOOLTIP_MSG_AM_BEST_RATING}
        >
          {cardDetailsInOrder.map((iterator, index) => (
            <TableColumn key={index}>
              {iterator?.msCompanyBase.ambestRating}
            </TableColumn>
          ))}
        </TableRow>
      </CollapsibleContainer>

      {!isEmpty(medigapChartDetails) && (
        <CollapsibleContainer
          header='Plan Benefits'
          className='scrollTarget'
          isCollapsed={false}
        >
          <TableRow
            header='Medicare Part A Coinsurance
          hospital costs up to an additional 365 days after Medicare benefits are used up'
            tooltip={StringConstants.TOOLTIP_MSG_MCARE_PART_A_COINSURANCE}
          >
            {cardDetailsInOrder.map((iterator, index) => (
              <TableColumn key={index}>
                {iterator && medigapChartDetails[iterator.planName] ? (
                  medigapChartDetails[iterator.planName]
                    .part_a_copay_hospital_cost_upto_365_days !== '0%' &&
                  (medigapChartDetails[iterator.planName]
                    .part_a_copay_hospital_cost_upto_365_days === '100%' ? (
                    <CheckCircleRoundedIcon style={{ color: 'green' }} />
                  ) : (
                    <div>
                      {
                        medigapChartDetails[iterator.planName]
                          .part_a_copay_hospital_cost_upto_365_days
                      }
                    </div>
                  ))
                ) : (
                  <div>{StringConstants.NON_STD_COMPARE_PLANS_MSG}</div>
                )}
              </TableColumn>
            ))}
          </TableRow>

          <TableRow
            header='Medicare Part B Coinsurance or Co-pay'
            background='dark'
            tooltip={StringConstants.TOOLTIP_MSG_MCARE_PART_B_COINSURANCE}
          >
            {cardDetailsInOrder.map((iterator, index) => (
              <TableColumn key={index}>
                {iterator && medigapChartDetails[iterator.planName] ? (
                  medigapChartDetails[iterator.planName].part_b_copay !==
                    '0%' &&
                  (medigapChartDetails[iterator.planName].part_b_copay ===
                  '100%' ? (
                    <CheckCircleRoundedIcon style={{ color: 'green' }} />
                  ) : (
                    <div>
                      {medigapChartDetails[iterator.planName].part_b_copay}
                    </div>
                  ))
                ) : (
                  <div>{StringConstants.NON_STD_COMPARE_PLANS_MSG}</div>
                )}
              </TableColumn>
            ))}
          </TableRow>

          <TableRow
            header='Blood (First 3 Pints)'
            tooltip={StringConstants.TOOLTIP_MSG_BLOOD_3_PINTS}
          >
            {cardDetailsInOrder.map((iterator, index) => (
              <TableColumn key={index}>
                {iterator && medigapChartDetails[iterator.planName] ? (
                  medigapChartDetails[iterator.planName].blood_first_3_pints !==
                    '0%' &&
                  (medigapChartDetails[iterator.planName]
                    .blood_first_3_pints === '100%' ? (
                    <CheckCircleRoundedIcon style={{ color: 'green' }} />
                  ) : (
                    <div>
                      {
                        medigapChartDetails[iterator.planName]
                          .blood_first_3_pints
                      }
                    </div>
                  ))
                ) : (
                  <div>{StringConstants.NON_STD_COMPARE_PLANS_MSG}</div>
                )}
              </TableColumn>
            ))}
          </TableRow>

          <TableRow
            header='Part A Hospice Care Coinsurance or Co-pay'
            background='dark'
            tooltip={StringConstants.TOOLTIP_MSG_HOSPICE_CARE_COINSURANCE}
          >
            {cardDetailsInOrder.map((iterator, index) => (
              <TableColumn key={index}>
                {iterator && medigapChartDetails[iterator.planName] ? (
                  medigapChartDetails[iterator.planName]
                    .part_a_hospice_care_copay !== '0%' &&
                  (medigapChartDetails[iterator.planName]
                    .part_a_hospice_care_copay === '100%' ? (
                    <CheckCircleRoundedIcon style={{ color: 'green' }} />
                  ) : (
                    <div>
                      {
                        medigapChartDetails[iterator.planName]
                          .part_a_hospice_care_copay
                      }
                    </div>
                  ))
                ) : (
                  <div>{StringConstants.NON_STD_COMPARE_PLANS_MSG}</div>
                )}
              </TableColumn>
            ))}
          </TableRow>

          <TableRow
            header='Skilled Nursing Facility Care Coinsurance'
            tooltip={
              StringConstants.TOOLTIP_MSG_SKILLED_NURSING_FACILITY_COINSURANCE
            }
          >
            {cardDetailsInOrder.map((iterator, index) => (
              <TableColumn key={index}>
                {iterator && medigapChartDetails[iterator.planName] ? (
                  medigapChartDetails[iterator.planName].snf_copay !== '0%' &&
                  (medigapChartDetails[iterator.planName].snf_copay ===
                  '100%' ? (
                    <CheckCircleRoundedIcon style={{ color: 'green' }} />
                  ) : (
                    <div>
                      {medigapChartDetails[iterator.planName].snf_copay}
                    </div>
                  ))
                ) : (
                  <div>{StringConstants.NON_STD_COMPARE_PLANS_MSG}</div>
                )}
              </TableColumn>
            ))}
          </TableRow>

          <TableRow
            header='Medicare Part A Deductible $1,600'
            background='dark'
            tooltip={StringConstants.TOOLTIP_MSG_MCARE_PART_A_DEDUCTIBLE}
          >
            {cardDetailsInOrder.map((iterator, index) => (
              <TableColumn key={index}>
                {iterator && medigapChartDetails[iterator.planName] ? (
                  medigapChartDetails[iterator.planName]
                    .part_a_deductible_1600 !== '0%' &&
                  (medigapChartDetails[iterator.planName]
                    .part_a_deductible_1600 === '100%' ? (
                    <CheckCircleRoundedIcon style={{ color: 'green' }} />
                  ) : (
                    <div>
                      {
                        medigapChartDetails[iterator.planName]
                          .part_a_deductible_1600
                      }
                    </div>
                  ))
                ) : (
                  <div>{StringConstants.NON_STD_COMPARE_PLANS_MSG}</div>
                )}
              </TableColumn>
            ))}
          </TableRow>

          <TableRow
            header='Medicare Part B Deductible $226'
            tooltip={StringConstants.TOOLTIP_MSG_MCARE_PART_B_DEDUCTIBLE}
          >
            {cardDetailsInOrder.map((iterator, index) => (
              <TableColumn key={index}>
                {iterator && medigapChartDetails[iterator.planName] ? (
                  medigapChartDetails[iterator.planName]
                    .part_b_deductible_226 !== '0%' &&
                  (medigapChartDetails[iterator.planName]
                    .part_b_deductible_226 === '100%' ? (
                    <CheckCircleRoundedIcon style={{ color: 'green' }} />
                  ) : (
                    <div>
                      {
                        medigapChartDetails[iterator.planName]
                          .part_b_deductible_226
                      }
                    </div>
                  ))
                ) : (
                  <div>{StringConstants.NON_STD_COMPARE_PLANS_MSG}</div>
                )}
              </TableColumn>
            ))}
          </TableRow>

          <TableRow
            header='Medicare Part B Excess Charges'
            background='dark'
            tooltip={StringConstants.TOOLTIP_MSG_MCARE_PART_B_EXCESS_CHARGES}
          >
            {cardDetailsInOrder.map((iterator, index) => (
              <TableColumn key={index}>
                {iterator && medigapChartDetails[iterator.planName] ? (
                  medigapChartDetails[iterator.planName]
                    .part_b_excess_charges !== '0%' &&
                  (medigapChartDetails[iterator.planName]
                    .part_b_excess_charges === '100%' ? (
                    <CheckCircleRoundedIcon style={{ color: 'green' }} />
                  ) : (
                    <div>
                      {
                        medigapChartDetails[iterator.planName]
                          .part_b_excess_charges
                      }
                    </div>
                  ))
                ) : (
                  <div>{StringConstants.NON_STD_COMPARE_PLANS_MSG}</div>
                )}
              </TableColumn>
            ))}
          </TableRow>

          <TableRow
            header='Foreign Travel Emergency (Up to Plan Limits)'
            tooltip={StringConstants.TOOLTIP_MSG_FOREIGN_TRAVEL_EMERGENCY}
          >
            {cardDetailsInOrder.map((iterator, index) => (
              <TableColumn key={index}>
                {iterator && medigapChartDetails[iterator.planName] ? (
                  medigapChartDetails[iterator.planName]
                    .foreign_travel_emergency !== '0%' &&
                  (medigapChartDetails[iterator.planName]
                    .foreign_travel_emergency === '100%' ? (
                    <CheckCircleRoundedIcon style={{ color: 'green' }} />
                  ) : (
                    <div>
                      {
                        medigapChartDetails[iterator.planName]
                          .foreign_travel_emergency
                      }
                    </div>
                  ))
                ) : (
                  <div>{StringConstants.NON_STD_COMPARE_PLANS_MSG}</div>
                )}
              </TableColumn>
            ))}
          </TableRow>

          <TableRow
            header='Medicare Preventive Care Part B Coinsurance'
            background='dark'
            tooltip={StringConstants.TOOLTIP_MSG_MCARE_PART_B_PREVENTIVE_CARE}
          >
            {cardDetailsInOrder.map((iterator, index) => (
              <TableColumn key={index}>
                {iterator && medigapChartDetails[iterator.planName] ? (
                  medigapChartDetails[iterator.planName]
                    .preventive_care_part_b_copay !== '0%' &&
                  (medigapChartDetails[iterator.planName]
                    .preventive_care_part_b_copay === '100%' ? (
                    <CheckCircleRoundedIcon style={{ color: 'green' }} />
                  ) : (
                    <div>
                      {
                        medigapChartDetails[iterator.planName]
                          .preventive_care_part_b_copay
                      }
                    </div>
                  ))
                ) : (
                  <div>{StringConstants.NON_STD_COMPARE_PLANS_MSG}</div>
                )}
              </TableColumn>
            ))}
          </TableRow>
        </CollapsibleContainer>
      )}
    </>
  )
}

export default MedicareSupplementTable
