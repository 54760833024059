import React from 'react'
import { TbZoomExclamation } from 'react-icons/tb'
import styled from '@emotion/styled'
import theme from '../../global/theme'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 900px;
  margin: auto;
  padding: 2rem 4rem;

  @media (max-width: 600) {
    min-width: 200px;
    padding: 2rem 1rem;
  }

  @media screen and (max-width: 1250px) {
    width: 700px;
  }

  @media screen and (max-width: 1050px) {
    width: 600px;
  }

  @media screen and (max-width: 640px) {
    width: 100%;
    margin: 20px 0px;
  }
`

const HeadingContent = styled.h3`
  color: ${theme.colors.textGrey};
  font-weight: 500;
  font-size: 1.5rem;
  margin-top: 3rem;
`

const NoPlanFoundCard = () => {
  return (
    <Container>
      <TbZoomExclamation color={theme.colors.textGrey} size='8rem' />
      <HeadingContent>No plans found</HeadingContent>
    </Container>
  )
}

export default NoPlanFoundCard
