import {
  AiOutlineCheck as Covered,
  AiOutlineClose as NotCovered,
} from 'react-icons/ai'
import { Table, TableBody } from '@mui/material'

import { IProviderCoverage } from '../../interface/providers/Provider'
import Icon from '../common/Icon/Icon.component'
import MuiTableCell from '@mui/material/TableCell'
import MuiTableRow from '@mui/material/TableRow'
import StringConstants from '../../constants/StringConstants'
import Tooltip from '../common/Tooltip/Tooltip.component'
import { observer } from 'mobx-react'
import styled from '@emotion/styled'
import theme from '../../global/theme'
import { useNavigate } from 'react-router-dom'

interface ProviderCoverageProps {
  providerCoverages: IProviderCoverage[]
}

const ProviderCoverageWrapper = styled.div`
  div {
    display: 'block';
    width: 100%;
  }
  h2 {
    font-size: 12px;
    color: ${theme.colors.textGrey};
    padding-bottom: 8px;
  }
`

const ProviderCoverageTableCell = styled(MuiTableCell)<{
  width: string
}>`
  font-weight: 700;
  color: ${theme.colors.textGrey};
  width: ${({ width }) => width};
  vertical-align: middle;
  padding: 5px;
  border: none;
  align-self: center;
  & p {
    font-size: 12px !important;
    color: ${theme.colors.textGrey}!important;
  }
  span {
    display: inline-flex;
    p {
      font-size: 12px !important;
      font-weight: 500;
      color: ${theme.colors.textGrey}!important;
    }
  }
`
const ProviderCoverageTableRow = styled(MuiTableRow)`
  background: white;
`
const NavigationWrapper = styled.span`
  padding-left: 10px;
  text-decoration: underline;
  font-weight: bold;
  color: ${theme.colors.primary};
  font-size: 14px;
`
const Label = styled.p`
  color: ${theme.colors.primary};
  font-size: 16px;
  font-weight: 700;
  padding-right: 20px;
  margin: 5px 0px;
`

const AddPhysicianWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`

const ProviderCoverage: React.FC<ProviderCoverageProps> = ({
  providerCoverages,
}) => {
  const navigate = useNavigate()
  return (
    <>
      <AddPhysicianWrapper>
        <Table>
          <TableBody>
            <ProviderCoverageTableRow>
              <ProviderCoverageTableCell width={'40%'} colSpan={1}>
                <h2>Physician Coverage</h2>{' '}
              </ProviderCoverageTableCell>
              <ProviderCoverageTableCell width={'60%'} colSpan={1}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                  }}
                >
                  <NavigationWrapper
                    style={{
                      cursor: 'pointer',
                      padding: '0px',
                      marginTop: '3px',
                    }}
                    onClick={() => {
                      navigate({
                        pathname: '/profile/myprofile/doctors',
                      })
                    }}
                  >
                    + Add Physicians
                  </NavigationWrapper>
                  <Label>
                    <Tooltip title={StringConstants.TOOLTIP_ADD_Physician} />
                  </Label>
                </div>
              </ProviderCoverageTableCell>
            </ProviderCoverageTableRow>
          </TableBody>
        </Table>
      </AddPhysicianWrapper>
      {providerCoverages?.length > 0 && (
        <ProviderCoverageWrapper>
          <Table>
            <TableBody>
              <ProviderCoverageTableRow>
                <ProviderCoverageTableCell width={'60%'} colSpan={1}>
                  {providerCoverages.map((providerCoverage, index) => (
                    <span key={index}>
                      <p>
                        {providerCoverage.isCovered ? (
                          <Icon
                            childComponent={<Covered />}
                            color={theme.colors.success}
                            fontSize='12px'
                          />
                        ) : (
                          <Icon
                            childComponent={<NotCovered />}
                            color={theme.colors.danger}
                            fontSize='12px'
                          />
                        )}

                        {providerCoverage.provider?.name}
                      </p>
                    </span>
                  ))}
                </ProviderCoverageTableCell>
              </ProviderCoverageTableRow>
            </TableBody>
          </Table>
        </ProviderCoverageWrapper>
      )}
    </>
  )
}

export default observer(ProviderCoverage)
