import styled from '@emotion/styled'

const Container = styled.div`
padding-top: 20px;
display: block!important;
font-size: 12px;
line-height: 1.5;
color: #6c6c6c!important;
`

const Linktext = styled.a`
text-decoration: underline;
font-weight: bold;
color: inherit;
text-decoration: none;
`

const NpPopUpPromptFooter: React.FC<{ isMsTab: boolean }> = ({
    isMsTab,
  }) => {
  return (
    <Container>
        {!isMsTab && 'Information related to date of birth, gender and tobacco use questions is not needed for MA and Part D Plans. '}
        By clicking the submit button above, you expressly consent via electronic 
        signature to receive emails, text messages and/or phone calls via 
        automated telephone dialing system or by artificial/pre-recorded 
        message from representatives or licensed insurance agents of Elite
        Insurance Partners LLC, its affiliates or{' '}
        <Linktext target='_blank' rel='noreferrer' href='https://eliteinsurancepartners.com/third-party-partners/'>
        third-party partners
        </Linktext>{' '}
        at the email address and telephone number provided, including your
        wireless number (if provided), regarding Medicare Supplement
        Insurance, Medicare Advantage, Medicare Part D and/or other
        insurance plans. Your consent is not a condition of purchase and
        you may revoke your consent at any time. This program is subject
        to our{' '}
        <Linktext target='_blank' rel='noreferrer' href='https://eliteinsurancepartners.com/privacy'>
        Privacy Policy
        </Linktext>{' '}
        and{' '}
        <Linktext target='_blank' rel='noreferrer' href='https://eliteinsurancepartners.com/terms-and-conditions/'>
        Terms of Use.
        </Linktext>{' '}
        This is a solicitation for insurance. This authorization overrides 
        any previous registration on a federal, state or corporate 
        Do Not Call Registry.
    </Container>
   )
}

export default NpPopUpPromptFooter
