interface BrandSpecificTheme {
  [key: string]: string
}

export const BrandSpecificThemeEnum: BrandSpecificTheme = {
  'aetna-medicare': '#7D3F98',
  'mutual of omaha-medicare': '#053870',
  medicareassociation: '#053870',
  'devoted': '#FF4F00'
}
