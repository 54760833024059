import React, { useEffect, useState } from 'react'
import { RiArrowDropDownLine, RiArrowDropUpLine } from 'react-icons/ri'
import { isEmpty, isEqual, isNull, isUndefined } from 'lodash'

import ApiManager from '../../../../api/ApiManager'
import BouncingDotsLoader from '../../../common/Loading/BouncingDotsLoader'
import CheckboxGroup from '../../../common/CheckboxGroup/CheckboxGroup.component'
import CloseIconButton from '../../../common/Button/CloseIconButton.component'
import DateField from '../../../common/DateField'
import { HttpMethods } from '../../../../enums/HttpMethodsEnum'
import { MSPlanRequestFilter } from '../../../../interface/quotes/mSupplementPlans/request/MSPlanRequestFilter'
import { MSPlansDisplayFilters } from '../../../../interface/quotes/mSupplementPlans/MSPlansDisplayFilters'
import OptionalMessage from '../../Common/OptionalMessageFilter'
import PaginationConstants from '../../../../constants/PaginationConstants'
import QuotesConstants from '../../../../constants/QuotesConstants'
import RouteConstants from '../../../../constants/RouteConstants'
import SliderComponent from '../../../common/Slider/Slider'
import { SnackbarTypes } from '../../../../enums/SnackbarTypesEnum'
import StringConstants from '../../../../constants/StringConstants'
import { Switch } from '@mui/material'
import Tooltip from '../../../common/Tooltip/Tooltip.component'
import UrlConstants from '../../../../constants/UrlConstants'
import { convertToUSCurrency } from '../../../../utils/CurrencyConverterUtil'
import customerStore from '../../../../datastore/CustomerStore'
import dayjs from 'dayjs'
import { fireEvent } from '../../../../cra'
import { getApiErrorMessage } from '../../../../utils/StringUtils'
import moment from 'moment'
import msPlanFiltersStore from '../../../../datastore/medicareQuotes/MSupplementPlanFiltersStore'
import { observer } from 'mobx-react'
import snackbarStore from '../../../../datastore/SnackbarStore'
import styled from '@emotion/styled'
import theme from '../../../../global/theme'

// import Button from '../../../common/Button/Button.component'

interface FilterContainerProps {
  showFilter?: boolean
  greyOut?: boolean
}

const FixedContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 360px;
  bottom: 10px;
  margin: 60px 0px 0px 0px;

  @media (max-width: 900px) {
    margin: 0px;
    width: 0px;
  }
`

const Container = styled.div<FilterContainerProps>`
  position: sticky;
  // overflow-y: auto;
  height: 160vh;
  top: 100px;

  .filterPlan {
    padding: 20px 0px;
    border-top: 1px solid ${theme.colors.borderColor};
    h3 {
      font-size: 16px;
      font-weight: bold;
      color: ${theme.colors.textDark};
      padding-bottom: 5px;
    }
    p {
      color: ${theme.colors.textGrey};
      font-weight: normal;
      font-size: 14px;
    }

    .optionalMessage {
      text-decoration: none;
      padding-top: 10px;
      color: ${theme.colors.textDark};
      span {
        color: ${theme.colors.primary};
        font-weight: 600;
        text-decoration: underline;
      }
    }
  }

  @media screen and (max-width: 950px) {
    position: fixed;
    max-height: 100vh;
    top: 0;
    left: 0;
    margin: 0px 0px;
    margin-top: 75px;
    z-index: 100;
    max-width: 90%;
    width: 50%;
    overflow-y: auto;
    box-shadow: 8px 0px 108px -78px rgba(0, 0, 0, 0.75);
    display: ${({ showFilter }) => (showFilter ? 'block' : 'none')};
    height: 100vh;
  }

  @media screen and (max-width: 750px) {
    width: 60%;
    margin-top: 61px;
  }

  @media screen and (max-width: 650px) {
    width: 80%;
  }
  @media screen and (max-width: 450px) {
    margin-top: 53px;
  }
  opacity: ${({ greyOut }) => (greyOut ? 0.5 : 1)};
  pointer-events: ${({ greyOut }) => (greyOut ? 'none' : 'auto')};
`

const Wrapper = styled.div`
  border-right: 1px solid ${theme.colors.borderColor};
  padding: 0px 50px 0px 20px;
  @media screen and (max-width: 950px) {
    padding: 90px 50px 50px 50px;
    background-color: ${theme.colors.white};
    border-right: none;
    overflow-y: scroll;
    height: 100%;
  }
`

const FiltersWrapper = styled.div``

const Filter = styled.div`
  padding: 15px 0px;
  border-top: 1px solid ${theme.colors.borderColor};
`

const Label = styled.p`
  color: ${theme.colors.primary};
  font-size: 16px;
  font-weight: 700;
  padding-right: 20px;
  margin: 5px 0px;
`
const CloseButtonWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  padding: 20px;
  display: none;
  @media screen and (max-width: 950px) {
    padding-top: 50px;
    display: block;
  }
`

const FloatingFilterContainer = styled.div`
  position: sticky;
  top: 0;
  background-color: ${theme.colors.white};
  z-index: 100;
  width: 105%;
  padding: 16px 20px;
  border: solid 1px ${theme.colors.borderColor};
  border-radius: 4px;

  h3 {
    color: ${theme.colors.primary};
    font-size: 18px;
    margin-bottom: 12px;
    text-align: center;
  }

  @media (max-width: 900px) {
    top: 40px;
  }
`

const SliderContentContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

const Overlay = styled.div<FilterContainerProps>`
  @media screen and (max-width: 950px) {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 99;
    display: ${({ showFilter }) => (showFilter ? 'block' : 'none')};
    transition: background-color 0.3s ease;
  }
`

interface FilterSupplementProps {
  showFilter: boolean
  handleShowFilter: () => void
  filterRef: React.MutableRefObject<any>
  clearFilterState: boolean
  clearButtonState: () => void
  setSelectedFilters?: (data: string[]) => void
  msPlanFilters: MSPlansDisplayFilters
  setMSPlanFilter: (data: MSPlansDisplayFilters) => void
}

const FilterSupplement: React.FC<FilterSupplementProps> = ({
  showFilter,
  handleShowFilter,
  filterRef,
  clearFilterState,
  clearButtonState,
  setSelectedFilters,
  msPlanFilters,
  setMSPlanFilter,
}) => {
  const msPlanRequestFilter: MSPlanRequestFilter = {} as MSPlanRequestFilter
  const [tobaccoConsumption, setTobaccoConsumption] = useState<boolean>(false)

  useEffect(() => {
    setTobaccoConsumption(customerStore.consumesTobacco)
  }, [])

  const [monthlyPremiumSliderValue, setMonthlyPremiumSliderValue] =
    useState<number>(
      msPlanFiltersStore.getMSPlansFilters()?.monthlyPremium.currentValue
    )

  const [carrierDropdown, setCarrierDropdown] = useState(false)
  const [typeDropdown, setTypeDropdown] = useState(false)
  const [ambestDropdown, setAmbestDropdown] = useState(false)

  const partBDateFromStore = isEmpty(customerStore.currentMedicarePartBDateSelf)
    ? ''
    : customerStore.currentMedicarePartBDateSelf!.toString().split('T')[0]
  const currentDate = new Date().toISOString().split('T')[0]
  const minEffectiveDate = moment(partBDateFromStore).isBefore(currentDate)
    ? partBDateFromStore
    : currentDate

  interface HealthQuestionProps {
    id: string
    inputType: 'RADIO' | 'CHECKBOX'
    isRequired: boolean
    options: { option: string }[]
    question: string
    response: string[]
  }

  const [tobaccoConsumptionQuestion, setTobaccoConsumptionQuestion] =
    useState<HealthQuestionProps>({} as HealthQuestionProps)

  useEffect(() => {
    ApiManager.makeApiCallWithAuthentication(
      UrlConstants.GET_HEALTH_QUESTIONNAIRE.USECASE,
      HttpMethods.GET_METHOD,
      {
        'for-spouse': false,
      }
    )
      .then((res) => {
        if (res.status === 200) {
          const healthQuestion = res.data.data.find(
            (x: HealthQuestionProps) =>
              x.question === 'Have you used tobacco in the last 12 months?'
          )
          const response =
            (healthQuestion.responses &&
              healthQuestion.responses.length > 0 &&
              healthQuestion.responses[0] &&
              healthQuestion.responses[0].response) ||
            []
          const tempTobaccoConsumption = response.includes('Yes') ? true : false
          setTobaccoConsumptionQuestion({
            id: healthQuestion.id,
            inputType: healthQuestion.inputType,
            isRequired: healthQuestion.isRequired,
            options: healthQuestion.options,
            question: healthQuestion.question,
            response: response,
          } as HealthQuestionProps)
          if (
            isEmpty(msPlanFilters) &&
            !isUndefined(msPlanFiltersStore.getMSPlansFilters())
          ) {
            setTobaccoConsumption(tempTobaccoConsumption)
          } else {
            setTobaccoConsumption(tempTobaccoConsumption)
          }
          customerStore.setConsumesTobacco(tempTobaccoConsumption)
        } else throw res
      })
      .catch(() => {
        snackbarStore.set({
          snackbarMessage: getApiErrorMessage(
            'fetch tobacco consumption preference'
          ),
          snackbarOpen: true,
          snackbarType: 'error',
        })
      })
  }, [])

  useEffect(() => {
    if (msPlanFilters.clearFiltersApplied) {
      msPlanFilters.clearFiltersApplied = false
      setMSPlanFilter({ ...msPlanFilters, clearFiltersApplied: false })
      msPlanFiltersStore.setClearFilters(true)
      msPlanFiltersStore.setMSPlansFilters(msPlanFilters)
      msPlanFiltersStore.clearMSPlanRequestFilters()
    }
  }, [msPlanFilters.clearFiltersApplied])

  useEffect(() => {
    handleSelectedFilters()
  }, [msPlanFilters])

  // const renderCount = useRef(0)
  useEffect(() => {
    // renderCount.current = renderCount.current + 1
    // if (renderCount.current > 1) {
    if (msPlanFiltersStore.getIsFilterChanged()) {
      applyFilters()
      handleSelectedFilters()
      // msPlanFiltersStore.setIsFilterChanged(false)
    }
    // }
  }, [msPlanFiltersStore.getIsFilterChanged()])

  const handleSelectedFilters = () => {
    const carrierFilters = msPlanFilters.carriers
      ?.filter((carrier) => carrier.checked === true)
      .map((carrier) => carrier.value)
    const ambestFilters = msPlanFilters.ambestRatings
      ?.filter((ambest) => ambest.checked === true)
      .map((ambest) => ambest.value)
    const planTypeFilters = msPlanFilters.planTypes
      ?.filter((planType) => planType.checked === true)
      .map((planType) => 'Plan ' + planType.value + ' ')
    const selectedCarrierFilters = Array.isArray(carrierFilters)
      ? carrierFilters
      : [carrierFilters]
    const selectedAMBestFilters = Array.isArray(ambestFilters)
      ? ambestFilters
      : [ambestFilters]
    const selectedPlanTypeFilters = Array.isArray(planTypeFilters)
      ? planTypeFilters
      : [planTypeFilters]

    setSelectedFilters &&
      setSelectedFilters([
        ...selectedPlanTypeFilters,
        ...selectedCarrierFilters,
        ...selectedAMBestFilters,
      ])
    msPlanFiltersStore.setFiltersActive(
      [
        ...selectedPlanTypeFilters,
        ...selectedCarrierFilters,
        ...selectedAMBestFilters,
      ].length !== 0
    )
  }
  const [loading, setLoading] = useState(false)
  const handleTobaccoConsumption = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setLoading(true)
    customerStore.setConsumesTobacco(!customerStore.consumesTobacco)
    setTobaccoConsumption(event.target.checked)
    if (msPlanFilters) {
      const response = event.target.checked === true ? ['Yes'] : ['No']
      const data = !isEmpty(tobaccoConsumptionQuestion)
        ? [{ ...tobaccoConsumptionQuestion, response }]
        : []
      if (!isEmpty(data)) {
        ApiManager.makeApiCallWithAuthentication(
          UrlConstants.POST_HEALTH_QUESTIONNAIRE_RESPONSE.USECASE,
          HttpMethods.POST_METHOD,
          { $forSpouse: false, data }
        )
          .then((res) => {
            if (res.status === 200) {
              customerStore.setConsumesTobacco(event.target.checked)
              msPlanFiltersStore.setIsQuotesPullStatusFetchTriggered(true)
              msPlanFiltersStore.setIsTobaccoChanged(true)
              snackbarStore.set({
                snackbarMessage: StringConstants.SNACKBAR_SAVE_SUCCESS_MSG,
                snackbarOpen: true,
                snackbarType: 'success',
              })
            } else throw res
          })
          .catch((err) => {
            setTobaccoConsumption(!event.target.checked)
            snackbarStore.set({
              snackbarMessage: 'Unable to save tobacco consumption preference',
              snackbarOpen: true,
              snackbarType: 'error',
            })
          })
          .finally(() => {
            setLoading(false)
          })
      } else {
        setLoading(false)
      }
    }
  }

  const handlePlanTypeCheckboxChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (msPlanFilters) {
      const checkedPlanTypes = msPlanFilters.planTypes?.map((item) =>
        item.value === e.target.value
          ? { ...item, checked: !item.checked }
          : item
      )
      setMSPlanFilter({
        ...msPlanFilters,
        planTypes: checkedPlanTypes,
      })
      msPlanFiltersStore.setIsFilterChanged(true)
    }
  }

  const handleCarrierCheckboxChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (msPlanFilters) {
      const checkedCarriers = msPlanFilters.carriers?.map((item) =>
        item.value === e.target.value
          ? { ...item, checked: !item.checked }
          : item
      )
      setMSPlanFilter({
        ...msPlanFilters,
        carriers: checkedCarriers,
      })
      msPlanFiltersStore.setIsFilterChanged(true)
    }
  }

  const handleAMBestRatingChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (msPlanFilters) {
      const checkedAMBestRatings = msPlanFilters.ambestRatings?.map((item) =>
        item.value === e.target.value
          ? { ...item, checked: !item.checked }
          : item
      )
      setMSPlanFilter({
        ...msPlanFilters,
        ambestRatings: checkedAMBestRatings,
      })
      msPlanFiltersStore.setIsFilterChanged(true)
    }
  }

  // TODO: Remove currentPlanYear if not necessary
  // const handlePlanYear = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   if (msPlanFilters) {
  //     if (e.target.checked)
  //       setMSPlanFilter({
  //         ...msPlanFilters!,
  //         currentPlanYear: msPlanFilters.planYear.toString(),
  //       })
  //     else
  //       setMSPlanFilter({
  //         ...msPlanFilters!,
  //         currentPlanYear: '',
  //       })
  //   }
  // }

  const handleDateChange = (e: any) => {
    if (e) {
      msPlanFiltersStore.setTempEffectiveDate(dayjs(e.$d).format('YYYY-MM-DD'))
    }
  }

  const handleMaxMonthlyPremium = (e: any) => {
    if (msPlanFilters) {
      setMSPlanFilter({
        ...msPlanFilters,
        monthlyPremium: {
          ...msPlanFilters.monthlyPremium,
          currentValue: monthlyPremiumSliderValue,
        },
      })
      msPlanFiltersStore.setIsFilterChanged(true)
    }
  }

  // TODO: Remove Annual Deductible and EAC if not needed
  // const handleMaxAnnualDeductible = (e: any) => {
  //   if (msPlanFilters) {
  //     setMSPlanFilter({
  //       ...msPlanFilters,
  //       annualDeductible: {
  //         ...msPlanFilters.annualDeductible,
  //         currentValue: +e.target.value,
  //       },
  //     })
  //   }
  // }

  // const handleEstimatedAnnualCost = (e: any) => {
  //   if (msPlanFilters) {
  //     setMSPlanFilter({
  //       ...msPlanFilters,
  //       estimatedAnnualCost: {
  //         ...msPlanFilters.estimatedAnnualCost,
  //         currentValue: +e.target.value,
  //       },
  //     })
  //   }
  // }

  const updateMSEffectiveDateFilterSelf = (
    msEffectiveDateFilterSelf: string
  ) => {
    msPlanFiltersStore.setIsOverLayLoading(true)
    ApiManager.makeApiCallWithAuthentication(
      UrlConstants.UPDATE_CUSTOMER_DETAILS.USECASE,
      HttpMethods.PATCH_METHOD,
      {
        msEffectiveDateFilterSelf: msEffectiveDateFilterSelf,
      }
    )
      .then((response) => {
        if (response.status === 200) {
          customerStore.setMSEffectiveDateFilterSelf(msEffectiveDateFilterSelf)
          msPlanFiltersStore.setIsQuotesPullStatusFetchTriggered(true)
          snackbarStore.set({
            snackbarMessage: StringConstants.SNACKBAR_SAVE_SUCCESS_MSG,
            snackbarOpen: true,
            snackbarType: SnackbarTypes.SUCCESS,
          })
        } else throw response
      })
      .catch(() => {
        snackbarStore.set({
          snackbarMessage: getApiErrorMessage('update customer details'),
          snackbarOpen: true,
          snackbarType: SnackbarTypes.ERROR,
        })
      })
      .finally(() => {
        msPlanFiltersStore.setIsOverLayLoading(false)
      })
  }

  const validateDate = () => {
    if (msPlanFiltersStore.effectiveDate === '') return true
    if (
      parseInt(msPlanFiltersStore.effectiveDate.split('-')[0]) <=
      moment().year() + 5
    )
      return true
    else return false
  }

  useEffect(() => {
    const startOfNextMonth =
      moment().add(1, 'month').toISOString().split('T')[0].substring(0, 8) +
      '01'
    if (!isEmpty(msPlanFiltersStore.effectiveDate)) {
      if (validateDate()) {
        if (
          msPlanFiltersStore.effectiveDate !==
          customerStore.msEffectiveDateFilterSelf!.split('T')[0]
        ) {
          if (isEmpty(msPlanFiltersStore.effectiveDate)) {
            updateMSEffectiveDateFilterSelf(
              isEmpty(customerStore.currentMedicarePartBDateSelf)
                ? startOfNextMonth
                : customerStore.currentMedicarePartBDateSelf!.split('T')[0]
            )
          } else {
            updateMSEffectiveDateFilterSelf(msPlanFiltersStore.effectiveDate)
          }
        }
      } else {
        snackbarStore.set({
          snackbarMessage: 'The selected date is wrong or invalid',
          snackbarOpen: true,
          snackbarType: 'error',
        })
      }
    }
  }, [msPlanFiltersStore.effectiveDate])

  const applyFilters = () => {
    if (!isEmpty(msPlanFilters)) {
      msPlanRequestFilter.carriers = msPlanFilters.carriers
        ?.filter((item) => item.checked)
        .map((item) => item.value)

      msPlanRequestFilter.ambestRatings = msPlanFilters.ambestRatings
        ?.filter((item) => item.checked)
        .map((item) => item.value)

      msPlanRequestFilter.planTypes = msPlanFilters.planTypes
        ?.filter((item) => item.checked)
        .map((item) => item.value)

      if (
        msPlanFilters.annualDeductible.currentValue !==
        msPlanFilters.annualDeductible.maxValue
      ) {
        msPlanRequestFilter.annualDeductible = {
          start: msPlanFilters.annualDeductible.minValue,
          end: msPlanFilters.annualDeductible.currentValue,
        }
      }

      if (
        msPlanFilters.estimatedAnnualCost.currentValue !==
        msPlanFilters.estimatedAnnualCost.maxValue
      ) {
        msPlanRequestFilter.estimatedAnnualCost = {
          start: msPlanFilters.estimatedAnnualCost.minValue,
          end: msPlanFilters.estimatedAnnualCost.currentValue,
        }
      }

      if (
        msPlanFilters.monthlyPremium.currentValue !==
        msPlanFilters.monthlyPremium.maxValue
      ) {
        msPlanRequestFilter.monthlyPremium = {
          start: Math.trunc(msPlanFilters.monthlyPremium.minValue * 100),
          end: Math.trunc(msPlanFilters.monthlyPremium.currentValue * 100),
        }
      }

      if (
        !isNull(msPlanFiltersStore.msPlanFilters.selectedSortOption) &&
        msPlanFiltersStore.msPlanFilters.selectedSortOption !== ''
      ) {
        msPlanRequestFilter.sortDetail = {
          order: 'ASC',
          type: msPlanFiltersStore.msPlanFilters.selectedSortOption,
        }
      }

      if (
        !isEqual(
          JSON.stringify(msPlanRequestFilter),
          JSON.stringify(msPlanFiltersStore.getMSPlanRequestFilters())
        )
      ) {
        msPlanFilters.selectedSortOption =
          msPlanFiltersStore.msPlanFilters.selectedSortOption
        msPlanFiltersStore.setFilterApplied(true)
        msPlanFiltersStore.setMSPlansFilters(msPlanFilters)
        msPlanRequestFilter.paginationDetail = {
          page: PaginationConstants.DEFAULT_PAGE,
          size: PaginationConstants.DEFAULT_PAGE_SIZE,
        }
        customerStore.setMSPageSize(PaginationConstants.DEFAULT_PAGE_SIZE)
        msPlanFiltersStore.setMSPlanRequestFilters(msPlanRequestFilter)
        handleShowFilter()
      }

      msPlanFiltersStore.setMSPlanRequestFilters(msPlanRequestFilter)
      handleShowFilter()
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }
  }

  const clearFilters = () => {
    if (msPlanFilters) {
      setMonthlyPremiumSliderValue(msPlanFilters.monthlyPremium.maxValue)
      setMSPlanFilter({
        ...msPlanFilters,
        annualDeductible: {
          ...msPlanFilters.annualDeductible,
          currentValue: msPlanFilters.annualDeductible.maxValue,
        },
        carriers: msPlanFilters.carriers.map((item) => ({
          ...item,
          checked: false,
        })),
        // currentPlanYear: '',
        monthlyPremium: {
          ...msPlanFilters.monthlyPremium,
          currentValue: msPlanFilters.monthlyPremium.maxValue,
        },
        estimatedAnnualCost: {
          ...msPlanFilters.estimatedAnnualCost,
          currentValue: msPlanFilters.estimatedAnnualCost.maxValue,
        },
        planTypes: msPlanFilters.planTypes.map((item) => ({
          ...item,
          checked: false,
        })),
        ambestRatings: msPlanFilters.ambestRatings.map((item) => ({
          ...item,
          checked: false,
        })),
        clearFiltersApplied: true,
        selectedSortOption: QuotesConstants.initialMSPlanSortOption,
      })
      setSelectedFilters && setSelectedFilters([])
    }
  }

  const handleDropdownClick = (id: string) => {
    if (id === 'carrier') {
      setCarrierDropdown(!carrierDropdown)
      setTypeDropdown(false)
      setAmbestDropdown(false)
    }
    if (id === 'type') {
      setTypeDropdown(!typeDropdown)
      setCarrierDropdown(false)
      setAmbestDropdown(false)
    }
    if (id === 'ambest') {
      setAmbestDropdown(!ambestDropdown)
      setCarrierDropdown(false)
      setTypeDropdown(false)
    }
  }

  useEffect(() => {
    if (clearFilterState) {
      clearFilters()
      clearButtonState()
    }
  }, [clearFilterState])

  return !isEmpty(msPlanFilters) ? (
    <FixedContainer ref={filterRef}>
      <Overlay showFilter={showFilter} />
      <Container
        greyOut={msPlanFiltersStore.filterApplied}
        showFilter={showFilter}
      >
        <CloseButtonWrapper>
          <CloseIconButton
            fontSize='32px'
            onClick={() => {
              handleShowFilter()
            }}
          />
        </CloseButtonWrapper>
        <Wrapper>
          <OptionalMessage
            heading='Showing plans for'
            link={RouteConstants.PROFILE + '/myprofile/contact'}
            linkText={customerStore.getPostalCodeAndCounty()}
            showToolTip={true}
          />
          {/* <FloatingFilterContainer>
            <h3>FILTER PLANS</h3>
            <ButtonsContainer>
              <Button
                width='80px'
                variant='contained'
                color='primary'
                bold={false}
                height='30px'
                margin='0px 12px 0px 0px'
                onClick={applyFilters}
              >
                Apply
              </Button>
              <Button
                width='80px'
                variant='outlined'
                color='secondary'
                bold={false}
                height='30px'
                margin='0px'
                onClick={clearFilters}
              >
                Clear
              </Button>
            </ButtonsContainer>
          </FloatingFilterContainer> */}
          <FiltersWrapper>
            <Filter>
              <Label>
                Tobacco Usage
                <Tooltip
                  title={StringConstants.TOOLTIP_MSG_MS_TOBACCO_CONSUMPTION}
                />
              </Label>
              {loading ? (
                <BouncingDotsLoader />
              ) : (
                <>
                  <span
                    style={{
                      color: `${theme.colors.textGrey}`,
                      fontSize: '14px',
                    }}
                  >
                    No
                  </span>
                  <Switch
                    checked={tobaccoConsumption}
                    onChange={(e) => {
                      fireEvent('switchInput', e, {
                        label: 'Tobacco Consumption',
                        value: tobaccoConsumption ? 'No' : 'Yes',
                        description:
                          'Rx Coverage Included/Not Included Filter - Medicare Advantage',
                      })
                      handleTobaccoConsumption(e)
                    }}
                  />
                  <span
                    style={{
                      color: `${theme.colors.textGrey}`,
                      fontSize: '14px',
                    }}
                  >
                    Yes
                  </span>
                </>
              )}
            </Filter>

            <Filter>
              <Label>
                Effective Date{' '}
                <Tooltip
                  title={StringConstants.TOOLTIP_MSG_MS_PART_B_EFFECTIVE_DATE}
                />
              </Label>
              <DateField
                format='MM/DD/YYYY'
                value={dayjs(
                  isEmpty(msPlanFiltersStore.tempEffectiveDate)
                    ? isEmpty(msPlanFiltersStore.effectiveDate)
                      ? isEmpty(customerStore.currentMedicarePartBDateSelf)
                        ? moment()
                            .add(1, 'month')
                            .toISOString()
                            .split('T')[0]
                            .substring(0, 8) + '01'
                        : customerStore.currentMedicarePartBDateSelf
                            ?.toString()
                            .split('T')[0]
                      : msPlanFiltersStore.effectiveDate
                    : isEmpty(msPlanFiltersStore.tempEffectiveDate)
                    ? isEmpty(customerStore.currentMedicarePartBDateSelf)
                      ? moment()
                          .add(1, 'month')
                          .toISOString()
                          .split('T')[0]
                          .substring(0, 8) + '01'
                      : customerStore.currentMedicarePartBDateSelf
                          ?.toString()
                          .split('T')[0]
                    : msPlanFiltersStore.tempEffectiveDate
                )}
                minDate={dayjs(
                  moment()
                    .add(1, 'month')
                    .toISOString()
                    .split('T')[0]
                    .substring(0, 8) + '01'
                )}
                maxDate={dayjs(
                  moment().add(1, 'year').year().toString() + '-12-31'
                )}
                disablePast={true}
                onAccept={handleDateChange}
              />
            </Filter>

            {msPlanFilters.planTypes && (
              <Filter>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    handleDropdownClick('type')
                  }}
                >
                  <Label>
                    Plan Type{' '}
                    <Tooltip
                      title={StringConstants.TOOLTIP_MSG_MS_PLAN_TYPES}
                    />
                  </Label>
                  {typeDropdown ? (
                    <RiArrowDropUpLine
                      style={{ cursor: 'pointer', marginLeft: 'auto' }}
                    />
                  ) : (
                    <RiArrowDropDownLine
                      style={{ cursor: 'pointer', marginLeft: 'auto' }}
                    />
                  )}
                </div>
                {typeDropdown ? (
                  <div style={{ maxHeight: '200px', overflowY: 'scroll' }}>
                    <CheckboxGroup
                      checkboxState={msPlanFilters.planTypes}
                      onChange={handlePlanTypeCheckboxChange}
                      width='100%'
                      type='unstyled'
                      checkboxGroupName='Plan Type Filter - Medicare Supplement'
                    />
                  </div>
                ) : null}
              </Filter>
            )}
            {msPlanFilters.carriers && (
              <Filter>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    handleDropdownClick('carrier')
                  }}
                >
                  <Label>
                    Carrier
                    <Tooltip title={StringConstants.TOOLTIP_MSG_MS_CARRIERS} />
                  </Label>
                  {carrierDropdown ? (
                    <RiArrowDropUpLine
                      style={{ cursor: 'pointer', marginLeft: 'auto' }}
                    />
                  ) : (
                    <RiArrowDropDownLine
                      style={{ cursor: 'pointer', marginLeft: 'auto' }}
                    />
                  )}
                </div>
                {carrierDropdown ? (
                  <div style={{ maxHeight: '350px', overflowY: 'scroll' }}>
                    <CheckboxGroup
                      checkboxState={msPlanFilters.carriers}
                      onChange={handleCarrierCheckboxChange}
                      width='100%'
                      type='unstyled'
                      checkboxGroupName='Carrier Filter - Medicare Supplement'
                    />
                  </div>
                ) : null}
              </Filter>
            )}
            {msPlanFilters.ambestRatings && (
              <Filter>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    handleDropdownClick('ambest')
                  }}
                >
                  <Label>
                    AM Best Rating
                    <Tooltip
                      title={StringConstants.TOOLTIP_MSG_MS_AM_BEST_RATING}
                    />
                  </Label>
                  {ambestDropdown ? (
                    <RiArrowDropUpLine
                      style={{ cursor: 'pointer', marginLeft: 'auto' }}
                    />
                  ) : (
                    <RiArrowDropDownLine
                      style={{ cursor: 'pointer', marginLeft: 'auto' }}
                    />
                  )}
                </div>
                {ambestDropdown ? (
                  <div style={{ maxHeight: '350px', overflowY: 'scroll' }}>
                    <CheckboxGroup
                      checkboxState={msPlanFilters.ambestRatings}
                      onChange={handleAMBestRatingChange}
                      width='100%'
                      type='unstyled'
                      checkboxGroupName='AM Best Rating Filter - Medicare Supplement'
                    />
                  </div>
                ) : null}
              </Filter>
            )}
            {msPlanFilters.monthlyPremium && (
              <Filter>
                <SliderComponent
                  label='Maximum Monthly Premium'
                  minValue={msPlanFilters.monthlyPremium.minValue}
                  maxValue={msPlanFilters.monthlyPremium.maxValue}
                  step={msPlanFilters.monthlyPremium.stepValue}
                  size='small'
                  value={monthlyPremiumSliderValue}
                  onChange={(e: any) => {
                    setMonthlyPremiumSliderValue(e.target.value)
                  }}
                  onChangeCommitted={(e: any) => {
                    handleMaxMonthlyPremium(e)
                    const label = 'Maximum Monthly Premium'
                    const value = monthlyPremiumSliderValue
                    fireEvent('sliderInput', e, {
                      label,
                      value,
                      description: `Value of ${label} set to ${value}`,
                    })
                  }}
                  withTooltip={true}
                  tooltipContent={
                    StringConstants.TOOLTIP_MSG_MAXIMUM_MONTHLY_PREMIUM
                  }
                />
                <SliderContentContainer>
                  <div>
                    {convertToUSCurrency(msPlanFilters.monthlyPremium.minValue)}
                  </div>
                  <div>
                    {convertToUSCurrency(msPlanFilters.monthlyPremium.maxValue)}
                  </div>
                </SliderContentContainer>
              </Filter>
            )}

            {/* TODO: Remove Annual Deductible and EAC if not needed */}
            {/* {msPlanFilters.annualDeductible && (
              <Filter>
                <SliderComponent
                  label='Maximum Annual Deductible'
                  minValue={msPlanFilters.annualDeductible.minValue}
                  maxValue={msPlanFilters.annualDeductible.maxValue}
                  step={msPlanFilters.annualDeductible.stepValue}
                  size='small'
                  onChange={(e) => handleMaxAnnualDeductible(e)}
                  value={
                    msPlanFilters.annualDeductible.currentValue !== null
                      ? msPlanFilters.annualDeductible.currentValue
                      : -1
                  }
                  withTooltip={true}
                  tooltipContent={
                    StringConstants.TOOLTIP_MSG_MAXIMUM_ANNUAL_DEDUCTIBLE
                  }
                />
              </Filter>
            )}
            {msPlanFilters.estimatedAnnualCost && (
              <Filter>
                <SliderComponent
                  label='Maximum Estimated Annual Cost'
                  minValue={msPlanFilters.estimatedAnnualCost.minValue}
                  maxValue={msPlanFilters.estimatedAnnualCost.maxValue}
                  step={msPlanFilters.estimatedAnnualCost.stepValue}
                  size='small'
                  onChange={(e) => handleEstimatedAnnualCost(e)}
                  value={
                    msPlanFilters.estimatedAnnualCost.currentValue !== null
                      ? msPlanFilters.estimatedAnnualCost.currentValue
                      : -1
                  }
                  withTooltip={true}
                  tooltipContent={
                    StringConstants.TOOLTIP_MSG_MS_MAXIMUM_ESTIMATED_ANNUAL_COST
                  }
                />
              </Filter>
            )} */}
          </FiltersWrapper>
        </Wrapper>
      </Container>
    </FixedContainer>
  ) : null
}

export default observer(FilterSupplement)
