import Button from '../common/Button/Button.component'
import CircularProgress from '../common/CircularProgress/CircularProgress'
import CloseIconButton from '../common/Button/CloseIconButton.component'
import Icon from '../common/Icon/Icon.component'
import React from 'react'
import RouteConstants from '../../constants/RouteConstants'
import customerStore from '../../datastore/CustomerStore'
import { observer } from 'mobx-react'
import styled from '@emotion/styled'
import theme from '../../global/theme'
import { useNavigate } from 'react-router-dom'

interface LeftNavbarProps {
  navLinks: {
    label: string
    icon: React.ReactNode
    link: string
  }[]
  activeNav: string
  onClick: (link: string) => void
  showLeftNav?: boolean
  showLeftNavHandler?: () => void
  isSpouse?: boolean
}

interface LeftNavbarContainerProps {
  showNav?: boolean
}

const Container = styled.div<LeftNavbarContainerProps>`
  flex-basis: 300px;
  flex-grow: 0;
  flex-shrink: 0;
  margin-right: 50px;
  background-color: ${theme.colors.white};
  z-index: 500;

  @media screen and (max-width: 900px) {
    position: fixed;
    display: ${({ showNav }) => (showNav === true ? 'flex' : 'none')};
    left: 0;
    top: 0;
    margin-top: 75px;
    width: 60%;
    min-height: 100vh;
    justify-content: center;
    align-items: center;
    border-right: 1px solid ${theme.colors.borderColor};
    box-shadow: 8px 0px 108px -78px rgba(0, 0, 0, 0.75);
  }

  @media screen and (max-width: 750px) {
    margin-top: 66px;
  }

  @media screen and (max-width: 600px) {
    width: 90%;
    border: none;
  }
  @media screen and (max-width: 450px) {
    margin-top: 65px;
  }
`

const ContentWrapper = styled.div`
  width: 300px;
  border: 1px solid ${theme.colors.borderColor};
  border-radius: 4px;
  padding: 20px 15px;

  @media screen and (max-width: 900px) {
    width: 100%;
    min-height: 100vh;
    border: none;
    border-radius: 0px;
    padding: 80px 50px;
    position: relative;
  }

  @media screen and (max-width: 650px) {
    padding: 80px 20px;
  }
`

const HeaderContainer = styled.div`
  display: flex;
  padding-bottom: 20px;
`

const UserNameWrapper = styled.div`
  padding-left: 15px;
  h2 {
    display: block;
    color: ${theme.colors.primary};
    font-size: 24px;
    word-break: break-all;
  }
  p {
    color: ${theme.colors.textDark};
  }
`

const NotificationContainer = styled.div`
  font-size: 14px;
  color: ${theme.colors.textGrey};
  p {
    span {
      color: ${theme.colors.primary};
      font-weight: bold;
      text-decoration: underline;
    }
  }
`

const NavigationContainer = styled.div`
  padding-top: 20px;
`

const CloseButtonWrapper = styled.div`
  position: absolute;
  top: 25px;
  right: 0;
  padding: 20px;
  display: none;
  @media screen and (max-width: 900px) {
    display: block;
  }
`

const ProfileLink = styled.p`
  font-weight: bold;
  display: block;
  color: ${theme.colors.primary};
  text-decoration: underline;
  cursor: pointer;
  margin-top: 4px;
`

const LeftNavbar: React.FC<LeftNavbarProps> = ({
  navLinks,
  activeNav,
  onClick,
  showLeftNav,
  showLeftNavHandler,
  isSpouse = false,
}) => {
  const [progress, setProgress] = React.useState(10)
  const navigate = useNavigate()

  React.useEffect(() => {
    let progress: number = 10
    if (customerStore.infoSteps.length !== 0) {
      if (customerStore.infoSteps.includes('prescription')) progress += 10
      if (customerStore.infoSteps.includes('healthQuestion')) progress += 10
      if (customerStore.infoSteps.includes('pharmacy')) progress += 10
      if (customerStore.infoSteps.includes('currentPlan')) progress += 10
      if (customerStore.infoSteps.includes('doctor')) progress += 10
      if (customerStore.gender) progress += 10
      if (customerStore.dateOfBirth) progress += 10
    }
    if (customerStore.spouseOptIn) {
      progress += 20
    }

    console.log('progress', progress)
    setProgress(progress)
  }, [
    customerStore.infoSteps,
    customerStore.spouseOptIn,
    customerStore.gender,
    customerStore.dateOfBirth,
  ])

  return (
    <Container showNav={showLeftNav}>
      <ContentWrapper>
        <CloseButtonWrapper>
          <CloseIconButton fontSize='20px' onClick={showLeftNavHandler} />
        </CloseButtonWrapper>
        <HeaderContainer style={isSpouse ? { marginBottom: '-20px' } : {}}>
          {!isSpouse && <CircularProgress progress={progress} />}
          <UserNameWrapper style={isSpouse ? { marginLeft: '-16px' } : {}}>
            <h2>
              {isSpouse && customerStore.spouseOptIn
                ? customerStore.getSpouseFullName()
                : customerStore.getFullName().length > 33
                ? customerStore.getFullName().slice(0, 30) + '...'
                : customerStore.getFullName()}
            </h2>
            <p>{customerStore.getPostalCodeAndCounty()}</p>
          </UserNameWrapper>
        </HeaderContainer>
        {!isSpouse && (
          <NotificationContainer>
            {progress !== 100 && <p>To complete your profile - </p>}
            {!customerStore.spouseOptIn && (
              <ProfileLink
                onClick={() => {
                  onClick('/profile/spouseprofile/profile')
                }}
              >
                Add your spouse/partner
              </ProfileLink>
            )}
            {progress === 100 && (
              <strong style={{ color: theme.colors.primary }}>
                Profile status - 100% complete
              </strong>
            )}
            {!customerStore.infoSteps.includes('prescription') && (
              <ProfileLink
                onClick={() => {
                  onClick('prescriptions')
                }}
              >
                Add your prescription drugs
              </ProfileLink>
            )}
            {!customerStore.infoSteps.includes('healthQuestion') && (
              <ProfileLink
                onClick={() => {
                  onClick('health')
                }}
              >
                Answer health questions
              </ProfileLink>
            )}
            {!customerStore.infoSteps.includes('pharmacy') && (
              <ProfileLink
                onClick={() => {
                  onClick('pharmacy')
                }}
              >
                Add your pharmacy
              </ProfileLink>
            )}
            {!customerStore.infoSteps.includes('doctor') && (
              <ProfileLink
                onClick={() => {
                  onClick('doctors')
                }}
              >
                Add your physician
              </ProfileLink>
            )}
            {!customerStore.infoSteps.includes('currentPlan') && (
              <ProfileLink
                onClick={() => {
                  navigate(RouteConstants.INFO_STEPS_CURRENT_PLAN)
                }}
              >
                Add your Medicare Plan Info
              </ProfileLink>
            )}
          </NotificationContainer>
        )}
        <NavigationContainer>
          {navLinks.map((navLink) => (
            <Button
              key={navLink.label}
              value={navLink.link}
              width='100%'
              borderRadius='2px'
              justifyContent='flex-start'
              margin='5px 0px'
              variant='contained'
              onClick={() => onClick(navLink.link)}
              bold={false}
              color={activeNav === navLink.link ? 'primary' : 'contrastPrimary'}
            >
              <span style={{ display: 'flex', alignItems: 'center' }}>
                <Icon
                  childComponent={navLink.icon}
                  padding='0px 5px'
                  fontSize='20px'
                />
                {navLink.label}
              </span>
            </Button>
          ))}
        </NavigationContainer>
      </ContentWrapper>
    </Container>
  )
}

export default observer(LeftNavbar)
