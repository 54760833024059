import { makeAutoObservable, observable } from 'mobx'

import { create } from '../mobx-persist'

interface CurrentPlanDetails {
  coveredByHealthInsurance: boolean
  effectiveDate: string | null
  quoteType: string
  anticipatedEndDate: string
  msPlan: any
  maPlan: any
}

type CurrentPlanDTO = Partial<CurrentPlanDetails> &
  Required<Pick<CurrentPlanDetails, 'effectiveDate' | 'quoteType'>>

const hydrate = create({
  storage: sessionStorage,
  jsonify: true,
})

class CurrentPlanStore {
  @observable anticipatedEndDate = ''
  @observable coveredByHealthInsurance: boolean | undefined = undefined
  @observable effectiveDate: string = ''
  @observable monthlyPremium = ''
  @observable provider = ''
  @observable planType = ''
  @observable quoteType = ''
  @observable rxIncluded: boolean | null = false
  @observable isPharmacyChecked: boolean = false
  @observable isCurrentPlanQuestionChecked: boolean = false

  constructor() {
    makeAutoObservable(this)
  }

  setAnticipatedEndDate(response: string) {
    this.anticipatedEndDate = response
  }

  setCoveredByHealthInsurance(response: boolean) {
    this.coveredByHealthInsurance = response
  }

  setEffectiveDate(response: string | null) {
    if (response) this.effectiveDate = response
    else this.effectiveDate = ''
  }

  setPlanType(response: string) {
    this.planType = response
  }

  setProvider(response: string) {
    this.provider = response
  }

  setQuoteType(response: string) {
    this.quoteType = response
  }

  getIsPharmacyChecked(): boolean {
    return this.isPharmacyChecked
  }

  setIsPharmacyChecked(isPharmacyChecked: boolean) {
    this.isPharmacyChecked = isPharmacyChecked
  }

  getIsCurrentPlanQuestionChecked(): boolean {
    return this.isCurrentPlanQuestionChecked
  }

  setIsCurrentPlanQuestionChecked(isCurrentPlanQuestionChecked: boolean) {
    this.isCurrentPlanQuestionChecked = isCurrentPlanQuestionChecked
  }

  get() {
    return {
      anticipatedEndDate: this.anticipatedEndDate,
      coveredByHealthInsurance: this.coveredByHealthInsurance,
      effectiveDate: this.effectiveDate,
      planType: this.planType,
      provider: this.provider,
      monthlyPremium: this.monthlyPremium,
      quoteType: this.quoteType,
      rxIncluded: this.rxIncluded,
    }
  }

  private resetData = () => {
    this.coveredByHealthInsurance = undefined
    this.quoteType = ''
    this.effectiveDate = ''
    this.anticipatedEndDate = ''
    this.provider = ''
    this.planType = ''
    this.monthlyPremium = ''
    this.rxIncluded = null
  }

  reset = () => {
    this.resetData()
  }

  set = (currentPlanObj: CurrentPlanDTO | null) => {
    if (currentPlanObj) {
      this.effectiveDate = currentPlanObj.effectiveDate || ''
      this.quoteType = currentPlanObj.quoteType
      if (currentPlanObj.anticipatedEndDate)
        this.anticipatedEndDate = currentPlanObj.anticipatedEndDate
      if (currentPlanObj.msPlan) {
        this.coveredByHealthInsurance = true
        this.monthlyPremium = (
          Number(currentPlanObj.msPlan.mSupplementRate.month) / 100
        ).toFixed(2)
        this.rxIncluded = null
      } else if (currentPlanObj.maPlan) {
        this.coveredByHealthInsurance = true
        this.monthlyPremium = Number(
          currentPlanObj.maPlan.monthlyPremium
        ).toFixed(2)
        this.rxIncluded = currentPlanObj.maPlan.rxIncluded
      } else {
        this.resetData()
      }
    } else {
      this.resetData()
    }
  }
}

const currentPlanStore = new CurrentPlanStore()

export default currentPlanStore
