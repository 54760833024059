import { AiOutlineMedicineBox, AiOutlineSetting } from 'react-icons/ai'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'

import { CgProfile } from 'react-icons/cg'
import { FiMapPin } from 'react-icons/fi'
import { FiMenu } from 'react-icons/fi'
import { GiHeartPlus } from 'react-icons/gi'
import { IconButton } from '@mui/material'
import { ImFileText2 } from 'react-icons/im'
import LeftNavbar from './LeftNavbar.component'
import ProviderIcon from '../common/Icon/ProviderIcon.component'
import React from 'react'
import RouteConstants from '../../constants/RouteConstants'
import SecureComponent from '../../pages/common/SecureComponent'
import { observer } from 'mobx-react'
import styled from '@emotion/styled'
import theme from '../../global/theme'
import { useCurrentSubrouteContext } from '../../pages/ProfilePage/ProfilePage'
import { withRouter } from '../common/WithRouter/WithRouter.component'

interface MyProfileSectionContainerProps {
  display?: 'block' | 'none'
  location?: {
    state: {
      from: {
        pathname: string
      }
    }
  }
}

interface MyProfileSectionProps extends MyProfileSectionContainerProps {}

const Container = styled.div<MyProfileSectionContainerProps>`
  display: ${(props) => props.display};
  margin: 0px 120px 0px 240px;
  padding: 50px 20px;

  @media screen and (max-width: 1300px) {
    margin: 0px 60px 0px 60px;
  }

  @media screen and (max-width: 1000px) {
    margin: 0px 20px 0px 20px;
  }
`
const ContentContainer = styled.div`
  display: flex;
`
const SectionHeading = styled.h2`
  display: flex;
  align-items: center;
  padding-bottom: 20px;
  font-size: 24px;
  color: ${theme.colors.textDark};
  width: inherit;

  @media screen and (max-width: 900px) {
    padding-top: 5px;
  }
`

const UtilityContainer = styled.div`
  display: none;
  width: fit-content;
  flex-wrap: wrap;
  grid-gap: 20px;
  padding: 20px 0px;
  margin-top: -30px;
  margin-left: -20px;
  color: ${theme.colors.primary};
  width: 50%;

  @media screen and (max-width: 900px) {
    cursor: pointer;
    display: flex;
  }
  h6 {
    align-items: center;
    padding: 0px 10px;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
    border: 1px solid ${theme.colors.primary};
  }
`

const MyProfileSection: React.FC<MyProfileSectionProps> = (
  props: MyProfileSectionProps
) => {
  const { setCurrentSubRoute, currentSubRoute } = useCurrentSubrouteContext()
  const [activeNav, setActiveNav] = React.useState(currentSubRoute)
  const [showLeftNav, setShowLeftNav] = React.useState(false)

  const navigate = useNavigate()
  const location = useLocation()

  const navbarProps = {
    navLinks: [
      {
        label: 'Profile',
        link: 'profile',
        icon: <CgProfile />,
      },
      {
        label: 'Contact & Address',
        link: 'contact',
        icon: <FiMapPin />,
      },
      {
        label: 'Health',
        link: 'health',
        icon: <GiHeartPlus />,
      },
      {
        label: 'Prescriptions',
        link: 'prescriptions',
        icon: <ImFileText2 />,
      },
      {
        label: 'Pharmacy',
        link: 'pharmacy',
        icon: <AiOutlineMedicineBox />,
      },
      {
        label: 'Physicians',
        link: 'doctors',
        icon: (
          <ProviderIcon
            color={activeNav === 'doctors' ? '#FFFFFF' : undefined}
          ></ProviderIcon>
        ),
      },
      //{
      //   label: 'Preferences',
      //   link: 'preferences',
      //   icon: <AiOutlineSetting />,
      // },
    ],
  }

  const activeNavHandler = (link: string) => {
    setShowLeftNav(false)
    setActiveNav(link)
    setCurrentSubRoute(link)
    navigate(link, {
      state: {
        from:
          (props.location &&
            props.location.state &&
            props.location.state.from) ||
          RouteConstants.DASHBOARD,
      },
    })
  }

  React.useEffect(() => {
    const currentScreenLink =
      location.pathname.split('/')[location.pathname.split('/').length - 1]
    if (currentScreenLink !== activeNav) activeNavHandler(currentScreenLink)
  }, [location])

  const showLeftNavHandler = () => {
    setShowLeftNav(!showLeftNav)
  }

  return (
    <Container display={props.display}>
      <ContentContainer>
        <LeftNavbar
          {...navbarProps}
          activeNav={activeNav}
          onClick={activeNavHandler}
          showLeftNavHandler={showLeftNavHandler}
          showLeftNav={showLeftNav}
          isSpouse={false}
        />
        <div
          style={{
            flex: 1,
          }}
        >
          <UtilityContainer>
            <h6
              onClick={() => setShowLeftNav(!showLeftNav)}
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <IconButton style={{ padding: '4px' }} size='small'>
                <FiMenu style={{ color: `${theme.colors.primary}` }} />
              </IconButton>
              Menu
            </h6>
          </UtilityContainer>
          <SectionHeading>
            {
              navbarProps.navLinks.find(
                (iterator) => iterator.link === activeNav
              )?.label
            }
          </SectionHeading>
          <Outlet />
        </div>
      </ContentContainer>
    </Container>
  )
}

export default SecureComponent(withRouter(observer(MyProfileSection)))
