import React, { useCallback, useEffect, useRef } from 'react'
import {
  deleteToken,
  getUserDataFromCookie,
  isAuthenticatedUser,
} from '../../utils/AuthUtils'
import {
  getAllParamsAsStringFromUrl,
  getParamsFromUrl,
} from '../../utils/UrlUtils'
import { useLocation, useNavigate } from 'react-router-dom'

import ApiManager from '../../api/ApiManager'
import { HttpMethods } from '../../enums/HttpMethodsEnum'
import Loader from '../../components/common/Loader'
import { PreferenceCoverageShortNameEnum } from '../../enums/PreferenceCoverageShortNameEnum'
// import LoadingComponent from '../../components/common/Loading/Loading.component'
import RoleConstants from '../../constants/RoleConstants'
import RouteConstants from '../../constants/RouteConstants'
import StringConstants from '../../constants/StringConstants'
import UrlConstants from '../../constants/UrlConstants'
import cookie from 'react-cookies'
import currentPlanStore from '../../datastore/CurrentPlanStore'
import customerStore from '../../datastore/CustomerStore'
import { isEmpty } from 'lodash'
import mPartDPlansFilterStore from '../../datastore/medicareQuotes/MPartPlanFilterQuotesStore'
import medicareAdvantageFilterQuoteStore from '../../datastore/medicareQuotes/MedicareAdvantageFilterQuoteStore'
// import medicareQuotes from '../../mock_data/medicareQuotes'
import msPlanFiltersStore from '../../datastore/medicareQuotes/MSupplementPlanFiltersStore'
import { observer } from 'mobx-react'
import prescriptionStore from '../../datastore/PrescriptionStore'
import snackbarStore from '../../datastore/SnackbarStore'
import userActionStore from '../../datastore/UserActionStore'
import { withRouter } from '../../components/common/WithRouter/WithRouter.component'

const RedirectScreen = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const isMounted = React.useRef(true)
  const timerRef: { current: NodeJS.Timeout | null } = useRef(null)
  const [leadBrand, setLeadBrand] = React.useState<string>('')
  const params = new URLSearchParams(window.location.search)

  useEffect(() => {
    // Clear the interval when the component unmounts
    return () => {
      if (timerRef.current) clearTimeout(timerRef.current)
    }
  }, [])

  useEffect(() => {
    const leadBrand = params.has('leadBrand') ? params.get('leadBrand') : ''
    setLeadBrand(leadBrand as string)
  }, [])

  const redirectUser = useCallback(
    (reload?: boolean, redirect?: string) => {
      if (isMounted.current) {
        const user = getUserDataFromCookie()
        if (!isEmpty(user)) {
          ApiManager.makeApiCallWithAuthentication(
            UrlConstants.GET_CUSTOMER_DETAILS.USECASE,
            HttpMethods.GET_METHOD
          )
            .then((response) => {
              if (response.status === 200) {
                const customerData = response.data.data
                customerStore.set(customerData)

                if (redirect) {
                  timerRef.current = setTimeout(() => {
                    navigate(redirect)
                  }, 3000)
                  return
                } else if (customerData.type === RoleConstants.CUSTOMER) {
                  const pc = params.has('pc') ? params.get('pc') : ''
                  var dashboardURL = RouteConstants.DASHBOARD
                  if (pc === PreferenceCoverageShortNameEnum.ma)
                    dashboardURL = RouteConstants.DASHBOARD_MA
                  else if (pc === PreferenceCoverageShortNameEnum.ms)
                    dashboardURL = RouteConstants.DASHBOARD_MS
                  else if (pc === PreferenceCoverageShortNameEnum.pdp)
                    dashboardURL = RouteConstants.DASHBOARD_PDP
                  navigate(dashboardURL, { replace: true })
                }
              } else throw response
            })
            .catch((err) => {
              deleteToken()
              customerStore.reset()
              userActionStore.reset()
              snackbarStore.reset()
              prescriptionStore.reset()
              currentPlanStore.reset()
              medicareAdvantageFilterQuoteStore.clearStore()
              medicareAdvantageFilterQuoteStore.clearMAPlanRequestFilters()
              msPlanFiltersStore.clearMSPlanFilterStore()
              msPlanFiltersStore.clearMSPlanRequestFilters()
              mPartDPlansFilterStore.clearStore()
              mPartDPlansFilterStore.clearMPartDPlanRequestFilters()
              localStorage.clear()
              sessionStorage.clear()
              navigate(RouteConstants.LOGIN, { replace: true })
            })
        } else if (reload) {
          window.location.reload()
        } else {
          navigate(RouteConstants.LOGIN)
        }
      }
    },
    [navigate]
  )

  useEffect(() => {
    if (location.search) {
      const token = getParamsFromUrl(
        location.search,
        StringConstants.COOKIE_TOKEN
      )
      const redirect = getParamsFromUrl(location.search, 'redirect')
      if (token) {
        cookie.save(StringConstants.COOKIE_TOKEN, token, {
          path: RouteConstants.HOME,
        })
        redirectUser(true)
      } else if (redirect) {
        const otherParams = getAllParamsAsStringFromUrl(location.search, [
          'redirect',
        ])
        redirectUser(false, redirect + otherParams)
      }
    } else if (isAuthenticatedUser()) {
      redirectUser()
    } else {
      navigate(RouteConstants.LOGIN)
    }
    return () => {
      isMounted.current = false
    }
  }, [location, redirectUser, navigate])

  return (
    <Loader
      message={StringConstants.AUTO_LOGIN_LOADER_MESSAGE}
      contents={StringConstants.AUTO_LOGIN_LOADER_CAROUSEL_CONTENT}
      leadBrand={
        leadBrand
          ? leadBrand
          : customerStore.brandName
          ? customerStore.brandName
          : ''
      }
    />
  ) //{/*<LoadingComponent data={medicareQuotes} />*/}
}

export default withRouter(observer(RedirectScreen))
