import { capitalize, isEmpty, isUndefined } from 'lodash'

import { ActionContext } from '../../../cra'
import ApiManager from '../../../api/ApiManager'
import Button from '../../common/Button/Button.component'
import { County } from '../../../interface/CountyInterface'
import { HttpMethods } from '../../../enums/HttpMethodsEnum'
import InputField from '../../common/InputField/InputField.component'
import MaskedInput from 'react-maskedinput'
import React from 'react'
import SecureComponent from '../../../pages/common/SecureComponent'
import Select from '../../common/Select/Select.component'
import { SelectChangeEvent } from '@mui/material/Select/SelectInput'
import { SnackbarTypes } from '../../../enums/SnackbarTypesEnum'
import StringConstants from '../../../constants/StringConstants'
import UrlConstants from '../../../constants/UrlConstants'
import ValidationUtils from '../../../utils/validation/ValidationUtils'
import ZipUtils from '../../../utils/ZipUtils'
import customerStore from '../../../datastore/CustomerStore'
import { getApiErrorMessage } from '../../../utils/StringUtils'
import mPartDPlansFilterStore from '../../../datastore/medicareQuotes/MPartPlanFilterQuotesStore'
import medicareAdvantageFilterQuoteStore from '../../../datastore/medicareQuotes/MedicareAdvantageFilterQuoteStore'
import msPlanFiltersStore from '../../../datastore/medicareQuotes/MSupplementPlanFiltersStore'
import { observer } from 'mobx-react'
import snackbarStore from '../../../datastore/SnackbarStore'
import styled from '@emotion/styled'
import theme from '../../../global/theme'
import userActionStore from '../../../datastore/UserActionStore'
import { withRouter } from '../../common/WithRouter/WithRouter.component'

interface DataProps {
  mailingOptIn: boolean
  mailingStreet: string
  mailingCity: string
  mailingCounty: string
  mailingState: string
  mailingPostalCode: string
  postalCode: string
  county: string
  countyFips: string
  state: string
  city: string
  street: string
  email: string
  phone: string
  additionalPhone: string | null
}

const Container = styled.div`
  width: 100%;
`

const SectionWrapper = styled.div`
  width: 100%;
  padding: 10px 0px 30px 0px;
  border-top: 1px solid ${theme.colors.borderColor};
`

const Label = styled.h4`
  font-size: 16px;
  color: ${theme.colors.textGrey};
  padding: 20px 10px 5px 10px;
  font-weight: 600;
`
const AddressElementsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  grid-gap: 10px;
`
const DisableText = styled.div`
  font-size: 14px;
  margin-left: 5px;
  color: ${theme.colors.secondary};
`

const Contact = () => {
  const [addrZip, setAddrZip] = React.useState('')
  const [addrState, setAddrState] = React.useState('')
  const [optCounties, setOptCounties] = React.useState<County[]>([])
  const [addrCounty, setAddrCounty] = React.useState<County>({} as County)
  const [addrCity, setAddrCity] = React.useState('')
  const [addrStreet, setAddrStreet] = React.useState('')
  const [email, setEmail] = React.useState('')
  const [phone, setPhone] = React.useState('')
  const [alternatePhone, setAlternatePhone] = React.useState('')
  const isButtonDisabled =
    customerStore.isMAQuotesProcessing ||
    customerStore.isMSQuotesProcessing ||
    customerStore.isMPartDQuotesProcessing
  const [state, setState] = React.useState({
    addrZipError: false,
    addrZipHelperText: '',
    addrStateError: false,
    addrStateHelperText: '',
    addrCountyError: false,
    addrCityError: false,
    addrCityHelperText: '',
    addrStreetError: false,
    addrStreetHelperText: '',
    emailError: false,
    emailHelperText: '',
    phoneError: false,
    phoneHelperText: '',
    alternatePhoneError: false,
    alternatePhoneHelperText: '',
    loading: false,
  })

  const { trackCurrentPage } = React.useContext(ActionContext)

  const resetInputErrors = () => {
    setState({
      ...state,
      addrZipError: false,
      addrZipHelperText: '',
      addrCityError: false,
      addrCountyError: false,
      addrStateError: false,
      addrStreetError: false,
    })
  }

  React.useEffect(() => {
    trackCurrentPage('Profile - Contact & Address')
    setAddrZip(customerStore.postalCode)
    setAddrState(customerStore.state)
    if (!isEmpty(customerStore.county) && !isEmpty(customerStore.countyFips)) {
      setAddrCounty({
        countyName: customerStore.county,
        countyFips: customerStore.countyFips,
      })
    }
    setAddrCity(customerStore.city)
    setAddrStreet(customerStore.street)
    setEmail(customerStore.email)
    setPhone(customerStore.phone.replace('+1', ''))
    customerStore.additionalPhone &&
      setAlternatePhone(customerStore.additionalPhone.replace('+1', ''))
  }, [
    customerStore.postalCode,
    customerStore.state,
    customerStore.county,
    customerStore.countyFips,
    customerStore.city,
    customerStore.street,
    customerStore.email,
    customerStore.phone,
    customerStore.additionalPhone,
  ])

  const GetCountiesCityAndStateFromZip = (zipCode: string) => {
    const zipCodeCache = userActionStore.zipCodeCache.find((iterator) => {
      if (iterator.code === zipCode) return iterator
    })

    if (!zipCodeCache) {
      // console.log('Fetch not from zipCodeCache')
      ZipUtils.GetCountyCityAndStateForZip(zipCode)
        .then(
          (response: { counties: County[]; city: string; state: string }) => {
            if (!isEmpty(response.city)) {
              if (
                zipCode !== customerStore.postalCode ||
                (isEmpty(addrCity) && isEmpty(customerStore.city))
              ) {
                setAddrCity(response.city)
                userActionStore.setZipCodeCache({
                  code: zipCode,
                  city: response.city,
                })
              } else {
                userActionStore.setZipCodeCache({
                  code: zipCode,
                  city: isEmpty(addrCity) ? customerStore.city : addrCity,
                })
              }
            } else {
              if (isEmpty(addrCity)) setAddrCity('')
            }

            if (!isEmpty(response.state)) {
              if (zipCode !== customerStore.postalCode || isEmpty(addrState))
                setAddrState(response.state)
              userActionStore.setZipCodeCache({
                code: zipCode,
                city: addrCity,
                state: response.state,
              })
            } else {
              if (isEmpty(addrState)) setAddrState('')
            }
            if (!isEmpty(response.counties)) {
              if (zipCode !== customerStore.postalCode || isEmpty(optCounties))
                setOptCounties(response.counties)
              let county: County
              if (
                zipCode !== customerStore.postalCode ||
                isEmpty(customerStore.county) ||
                isEmpty(customerStore.countyFips)
              ) {
                county = response.counties[0]
                setAddrCounty(county)
              } else {
                const tempCounty = response.counties.find(
                  (county) => county.countyFips === customerStore.countyFips
                )
                county = {
                  countyName: tempCounty ? tempCounty.countyName : '',
                  countyFips: customerStore.countyFips,
                }
                setAddrCounty(county)
              }
              resetInputErrors()

              userActionStore.setZipCodeCache({
                code: zipCode,
                city: addrCity,
                state: addrState,
                county: county,
                optCounties: response.counties,
              })
            } else {
              setOptCounties([])
              setAddrCity('')
              setAddrState('')
              setAddrCounty({} as County)
              setAddrStreet('')
              setState({ ...state, addrCountyError: true })
            }
          }
        )
        .catch((error: any) => {
          snackbarStore.set({
            snackbarMessage:
              error.data &&
              error.data.length > 0 &&
              !isEmpty(error.data[0]) &&
              error.data[0].reason
                ? error.data[0].reason
                : StringConstants.UI_ERROR_MSG_ON_COUNTY_NOT_RETRIEVED_FROM_ZIP,
            snackbarOpen: true,
            snackbarType: SnackbarTypes.ERROR,
          })
          setAddrCity('')
          setAddrState('')
          setAddrCounty({} as County)
          setAddrStreet('')
          setOptCounties([])
          setState({
            ...state,
            addrZipError: true,
            addrZipHelperText:
              StringConstants.UI_ERROR_MSG_FOR_INVALID_ZIP_CODE,
            addrCityError: false,
            addrCountyError: false,
            addrStateError: false,
            addrStreetError: false,
          })
        })
    } else {
      // console.log('Fetch from zipCodeCache')
      setOptCounties(zipCodeCache.optCounties || [])
      setAddrState(zipCodeCache.state || '')
      setAddrCounty(zipCodeCache.county || ({} as County))
      if (isEmpty(addrCity)) setAddrCity(zipCodeCache.city || '')
      resetInputErrors()
    }
  }

  React.useEffect(() => {
    if (!isEmpty(customerStore.postalCode)) {
      GetCountiesCityAndStateFromZip(customerStore.postalCode)
      if (optCounties[0]?.countyName !== '' && !isEmpty(customerStore.county)) {
        setAddrCounty({
          countyFips: customerStore.countyFips,
          countyName: customerStore.county,
        })
      }
    }
  }, [customerStore.postalCode, customerStore.county])

  React.useEffect(() => {
    window.scroll({ top: 0, behavior: 'smooth' })
  }, [])

  const validateAddrZip = (addrZip: string) => {
    let addrZipError = !ValidationUtils.validateZipcode(addrZip)
    setState({
      ...state,
      addrZipHelperText: addrZipError
        ? StringConstants.UI_ERROR_MSG_FOR_INVALID_ZIP_CODE
        : '',
      addrZipError,
    })
    return addrZipError
  }

  const handleAddrZipChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value && e.target.value.length < 6) {
      let hasError = validateAddrZip(e.target.value)
      if (/(^\d{0,5}$)/.test(e.target.value)) {
        setAddrZip(e.target.value)
      }
      if (!hasError) {
        GetCountiesCityAndStateFromZip(e.target.value)
      } else {
        setAddrCity('')
        setAddrState('')
        setAddrCounty({} as County)
        setAddrStreet('')
        setOptCounties([])
      }
    } else if (!e.target.value) {
      setAddrZip(e.target.value)
    }
  }

  const validateAddrState = (addrState: string) => {
    let addrStateError = !ValidationUtils.validateState(addrState)

    setState({
      ...state,
      addrStateHelperText: addrStateError
        ? StringConstants.UI_ERROR_MSG_FOR_INVALID_STATE
        : '',
      addrStateError,
    })
  }

  const handleAddrStateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    validateAddrState(e.target.value)
    setAddrState(e.target.value)
  }

  const validateAddrCounty = (addrCounty: string) => {
    const addrCountyError = isEmpty(addrCounty)

    setState({
      ...state,
      addrCountyError: addrCountyError,
    })
  }

  const handleAddrCountyChange = (e: SelectChangeEvent<string>) => {
    validateAddrCounty(e.target.value)
    const county = optCounties.find(
      (county) => county.countyName === e.target.value
    )
    if (!isUndefined(county)) setAddrCounty(county)
  }

  const validateAddrCity = (addrCity: string) => {
    let addrCityError = !ValidationUtils.validateCity(addrCity)

    setState({
      ...state,
      addrCityHelperText: addrCityError
        ? StringConstants.UI_ERROR_MSG_FOR_INVALID_CITY
        : '',
      addrCityError,
    })
  }

  const handleAddrCityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value.replace(/\w+/g, capitalize)
    validateAddrCity(value)
    setAddrCity(value)
  }

  const validateAddrStreet = (addrStreet: string) => {
    let addrStreetError = !ValidationUtils.validateStreet(addrStreet)

    setState({
      ...state,
      addrStreetHelperText: addrStreetError
        ? StringConstants.UI_ERROR_MSG_FOR_INVALID_STREET
        : '',
      addrStreetError,
    })
  }

  const handleAddrStreetChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    validateAddrStreet(e.target.value)
    setAddrStreet(e.target.value)
  }

  const validateEmail = (email: string) => {
    const emailError = !ValidationUtils.validateEmail(email)
    setState({
      ...state,
      emailError,
      emailHelperText: isEmpty(email)
        ? StringConstants.UI_ERROR_MSG_FOR_EMPTY_EMAIL
        : emailError
        ? StringConstants.UI_ERROR_MSG_FOR_INVALID_EMAIL
        : '',
    })
  }

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    validateEmail(e.target.value)
    setEmail(e.target.value)
  }

  const validatePhone = (phone: string) => {
    var phoneError = true
    if (phone === '') {
      setState({
        ...state,
        phoneError: false,
        phoneHelperText: '',
      })
    } else {
      let conditionalStateData = {}
      if (
        state.alternatePhoneError &&
        state.alternatePhoneHelperText ===
          StringConstants.UI_ERROR_MSG_FOR_ALTERNATE_PHONE_SAME_AS_PHONE &&
        phone !== alternatePhone
      ) {
        conditionalStateData = {
          alternatePhoneError: false,
          alternatePhoneHelperText: '',
        }
      }
      ValidationUtils.verifyPhoneNumber(phone)
        .then((response: any) => {
          if (response.data.data.status === 'valid') {
            phoneError = false
            setState({
              ...state,
              phoneError: phoneError || phone === alternatePhone,
              phoneHelperText: isEmpty(phone)
                ? StringConstants.UI_ERROR_MSG_FOR_EMPTY_PHONE
                : phoneError
                ? StringConstants.UI_ERROR_MSG_FOR_INVALID_PHONE
                : phone === alternatePhone
                ? StringConstants.UI_ERROR_MSG_FOR_PHONE_SAME_AS_ALTERNATE_PHONE
                : '',
            })
          }
        })
        .catch((err: Error) => {
          console.log(err)
        })
      setState({
        ...state,
        phoneError: phoneError || phone === alternatePhone,
        phoneHelperText: isEmpty(phone)
          ? ''
          : phoneError
          ? StringConstants.UI_ERROR_MSG_FOR_INVALID_PHONE
          : phone === alternatePhone
          ? StringConstants.UI_ERROR_MSG_FOR_PHONE_SAME_AS_ALTERNATE_PHONE
          : '',
        ...conditionalStateData,
      })
    }
  }

  const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let phone = e.target.value.replace(/[- )( _]/g, '')
    validatePhone(phone)
    setPhone(phone)
  }

  const validateAlternatePhone = (alternatePhone: string) => {
    var alternatePhoneError = true
    if (alternatePhone === '') {
      setState({
        ...state,
        alternatePhoneError: false,
        alternatePhoneHelperText: '',
      })
    } else {
      let conditionalStateData = {}
      if (
        state.phoneError &&
        state.phoneHelperText ===
          StringConstants.UI_ERROR_MSG_FOR_PHONE_SAME_AS_ALTERNATE_PHONE &&
        phone !== alternatePhone
      ) {
        conditionalStateData = {
          phoneError: false,
          phoneHelperText: '',
        }
      }
      ValidationUtils.verifyPhoneNumber(alternatePhone)
        .then((response: any) => {
          if (response.data.data.status === 'valid') {
            alternatePhoneError = false
            setState({
              ...state,
              alternatePhoneError:
                alternatePhoneError || phone === alternatePhone,
              alternatePhoneHelperText: alternatePhoneError
                ? StringConstants.UI_ERROR_MSG_FOR_INVALID_ALTERNATE_PHONE
                : phone === alternatePhone
                ? StringConstants.UI_ERROR_MSG_FOR_ALTERNATE_PHONE_SAME_AS_PHONE
                : '',
              ...conditionalStateData,
            })
          }
        })
        .catch((err: Error) => {
          console.log(err)
        })
      setState({
        ...state,
        alternatePhoneError: alternatePhoneError || phone === alternatePhone,
        alternatePhoneHelperText: alternatePhoneError
          ? StringConstants.UI_ERROR_MSG_FOR_INVALID_ALTERNATE_PHONE
          : phone === alternatePhone
          ? StringConstants.UI_ERROR_MSG_FOR_ALTERNATE_PHONE_SAME_AS_PHONE
          : '',
        ...conditionalStateData,
      })
    }
  }

  const handleAlternatePhoneChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    let alternatePhone = e.target.value.replace(/[- )( _]/g, '')
    validateAlternatePhone(alternatePhone)
    setAlternatePhone(alternatePhone)
  }

  const handleSave = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    customerStore.setIsQuotesPullAPiCalled(false)
    let hasError = false
    let isQuotesRelatedDetailsChanged = false
    e.preventDefault()
    if (isEmpty(addrZip)) {
      hasError = true
      setState({
        ...state,
        addrZipError: true,
        addrZipHelperText: StringConstants.UI_ERROR_MSG_FOR_INVALID_ZIP_CODE,
      })
    }
    if (isEmpty(addrState)) {
      hasError = true
      setState({
        ...state,
        addrStateError: true,
        addrStateHelperText: StringConstants.UI_ERROR_MSG_FOR_INVALID_STATE,
      })
    }
    if (isEmpty(addrCounty) || isEmpty(optCounties)) {
      hasError = true
      setState({
        ...state,
        addrCountyError: true,
      })
    }
    if (isEmpty(addrCity)) {
      hasError = true
      setState({
        ...state,
        addrCityError: true,
        addrCityHelperText: StringConstants.UI_ERROR_MSG_FOR_INVALID_CITY,
      })
    }
    if (isEmpty(addrStreet)) {
      hasError = true
      setState({
        ...state,
        addrStreetError: true,
        addrStreetHelperText: StringConstants.UI_ERROR_MSG_FOR_INVALID_STATE,
      })
    }
    if (isEmpty(email)) {
      hasError = true
      setState({
        ...state,
        emailError: true,
        emailHelperText: StringConstants.UI_ERROR_MSG_FOR_INVALID_EMAIL,
      })
    }
    if (isEmpty(phone)) {
      hasError = true
      setState({
        ...state,
        phoneError: true,
        phoneHelperText: StringConstants.UI_ERROR_MSG_FOR_INVALID_PHONE,
      })
    }
    if (
      state.phoneHelperText !==
        StringConstants.UI_ERROR_MSG_FOR_PHONE_SAME_AS_ALTERNATE_PHONE &&
      !state.alternatePhoneError &&
      !isEmpty(alternatePhone) &&
      phone === alternatePhone
    ) {
      hasError = true
      setState({
        ...state,
        alternatePhoneError: true,
        alternatePhoneHelperText:
          StringConstants.UI_ERROR_MSG_FOR_ALTERNATE_PHONE_SAME_AS_PHONE,
      })
    }
    if (
      hasError ||
      state.addrZipError ||
      state.addrStateError ||
      state.addrCountyError ||
      state.addrCityError ||
      state.addrStreetError ||
      state.emailError ||
      state.phoneError ||
      state.alternatePhoneError
    ) {
      snackbarStore.set({
        snackbarMessage: StringConstants.SNACKBAR_ERROR_ON_INPUTS,
        snackbarOpen: true,
        snackbarType: SnackbarTypes.ERROR,
      })
    } else {
      let data: Partial<DataProps> = {}
      if (addrZip !== customerStore.postalCode) {
        data.mailingOptIn = true
        data.postalCode = addrZip
        data.mailingPostalCode = addrZip
        isQuotesRelatedDetailsChanged = true
      }
      if (addrState !== customerStore.state) {
        data.mailingOptIn = true
        data.state = addrState
        data.mailingState = addrState
      }
      if (addrCounty.countyName !== customerStore.county) {
        data.county = addrCounty.countyName
        data.countyFips = addrCounty.countyFips
        if (isUndefined(data.postalCode)) isQuotesRelatedDetailsChanged = true
      }
      if (addrCounty.countyName !== customerStore.mailingCounty) {
        data.mailingOptIn = true
        data.mailingCounty = addrCounty.countyName
      }
      if (addrCity !== customerStore.city) {
        data.city = addrCity
      }
      if (addrCity !== customerStore.mailingCity) {
        data.mailingOptIn = true
        data.mailingCity = addrCity
      }
      if (addrStreet !== customerStore.street) {
        data.street = addrStreet
      }
      if (addrStreet !== customerStore.mailingStreet) {
        data.mailingOptIn = true
        data.mailingStreet = addrStreet
      }
      if (email !== customerStore.email) {
        data.email = email
      }
      if ('+1' + phone !== customerStore.phone) {
        data.phone = '+1' + phone
      }
      if (
        '+1' + alternatePhone !== customerStore.additionalPhone &&
        !(isEmpty(alternatePhone) && customerStore.additionalPhone === null)
      ) {
        data.additionalPhone = isEmpty(alternatePhone)
          ? null
          : '+1' + alternatePhone
      }

      if (isEmpty(data)) {
        snackbarStore.set({
          snackbarMessage: StringConstants.SNACKBAR_NO_DATA_TO_SAVE_MSG,
          snackbarOpen: true,
          snackbarType: SnackbarTypes.WARNING,
        })
      } else {
        setState({
          ...state,
          loading: true,
        })
        ApiManager.makeApiCallWithAuthentication(
          UrlConstants.UPDATE_CUSTOMER_DETAILS.USECASE,
          HttpMethods.PATCH_METHOD,
          data
        )
          .then((response) => {
            if (response.status === 200) {
              customerStore.set(data)
              userActionStore.setZipCodeCache({
                code: addrZip,
                city: addrCity,
                county: addrCounty,
              })
              snackbarStore.set({
                snackbarMessage: StringConstants.SNACKBAR_SAVE_SUCCESS_MSG,
                snackbarOpen: true,
                snackbarType: SnackbarTypes.SUCCESS,
              })
              if (isQuotesRelatedDetailsChanged) {
                customerStore.setQuotePulledStatusMA(true)
                if (
                  !isEmpty(customerStore.dateOfBirth) &&
                  !isEmpty(customerStore.gender)
                ) {
                  customerStore.setQuotePulledStatusMS(true)
                  msPlanFiltersStore.clearMSPlanFilterStore()
                }
                customerStore.setQuotePulledStatusMPartD(true)
                customerStore.setIsRidersApplied(true)
                medicareAdvantageFilterQuoteStore.clearStore()
                mPartDPlansFilterStore.clearStore()
              }
            } else throw response
          })
          .catch((err) => {
            snackbarStore.set({
              snackbarMessage: getApiErrorMessage('update customer details'),
              snackbarOpen: true,
              snackbarType: SnackbarTypes.ERROR,
            })
          })
          .finally(() => {
            setState({
              ...state,
              loading: false,
            })
          })
      }
    }
  }

  return (
    <Container>
      <SectionWrapper>
        <Label>Your Full Address</Label>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            margin: '0px 0px 16px 0px',
          }}
        >
          <InputField
            error={state.addrStreetError}
            helperText={state.addrStreetHelperText}
            width='100%'
            label='Your Street'
            value={addrStreet}
            onChange={handleAddrStreetChange}
          />
        </div>
        <InputField
          error={state.addrCityError}
          helperText={state.addrCityHelperText}
          width='100%'
          label='Your City'
          value={addrCity}
          onChange={handleAddrCityChange}
        />
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            margin: '10px 0px 4px 0px',
          }}
        >
          <Select
            error={state.addrCountyError}
            formLabel='Your County'
            options={optCounties.map((county) => county.countyName)}
            width='200px'
            height='39px'
            margin='10px 0px'
            labelMargin='0px 0px 0px 0px'
            value={
              optCounties.length === 1
                ? optCounties[0].countyName
                : addrCounty.countyName
            }
            onChange={handleAddrCountyChange}
          />
        </div>
        <AddressElementsWrapper>
          <InputField
            readOnly
            error={state.addrStateError}
            helperText={state.addrStateHelperText}
            width='200px'
            label='Your State'
            value={addrState}
            onChange={handleAddrStateChange}
          />
          <InputField
            error={state.addrZipError}
            helperText={state.addrZipHelperText}
            width='200px'
            label='Your Zip Code'
            value={addrZip}
            onChange={handleAddrZipChange}
          />
        </AddressElementsWrapper>
      </SectionWrapper>
      <SectionWrapper>
        <Label>Your Email</Label>
        <InputField
          error={state.emailError}
          helperText={state.emailHelperText}
          width='100%'
          label='Enter your email address'
          value={email}
          onChange={handleEmailChange}
        />
        <Label>Your Phone Number</Label>
        <InputField
          error={state.phoneError}
          helperText={state.phoneHelperText}
          width='100%'
          label='Enter your phone number'
          value={phone}
          onChange={handlePhoneChange}
          inputComponent={MaskedInput}
          inputProps={{ mask: '(111) 111-1111' }}
          shrink={phone ? true : undefined}
          placeholder='(123) 456-7890'
        />
        <Label>Alternate Phone Number (optional)</Label>
        <InputField
          error={state.alternatePhoneError}
          helperText={state.alternatePhoneHelperText}
          width='100%'
          label='Enter your alternate phone number'
          value={alternatePhone}
          onChange={handleAlternatePhoneChange}
          inputComponent={MaskedInput}
          inputProps={{ mask: '(111) 111-1111' }}
          shrink={alternatePhone ? true : undefined}
          placeholder='(123) 456-7890'
        />
      </SectionWrapper>
      <SectionWrapper>
        <Button
          color='secondary'
          variant='contained'
          onClick={handleSave}
          loading={state.loading}
          disabled={
            customerStore.isMAQuotesProcessing ||
            customerStore.isMSQuotesProcessing ||
            customerStore.isMPartDQuotesProcessing ||
            state.loading
          }
        >
          Save Changes
        </Button>
        {isButtonDisabled && (
          <DisableText>{StringConstants.DISABLE_BUTTON_MSG}</DisableText>
        )}
      </SectionWrapper>
    </Container>
  )
}

export default SecureComponent(withRouter(observer(Contact)))
