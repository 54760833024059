import Button from '../common/Button/Button.component'
import Filter from 'bad-words'
import InputField from '../common/InputField/InputField.component'
import React from 'react'
import ValidationUtils from '../../utils/validation/ValidationUtils'
import { isEmpty } from 'lodash'
import styled from '@emotion/styled'
import theme from '../../global/theme'
import { withRouter } from '../common/WithRouter/WithRouter.component'

interface EnterEmailProps {
  handleEmailEntered: () => void
  username: string
  setUsername: (
    username: string
  ) => React.Dispatch<React.SetStateAction<string>>
  usernameError: boolean
  setUsernameError: (
    isError: boolean
  ) => React.Dispatch<React.SetStateAction<boolean>>
  isLoading: boolean
}

const Container = styled.div`
  width: 350px;
  h2 {
    color: ${theme.colors.primary};
    font-weight: 700;
    font-size: 24px;
    padding-bottom: 30px;
  }
  p {
    color: ${theme.colors.textDark};
    padding-bottom: 10px;
  }

  @media screen and (max-width: 475px) {
    width: 320px;
  }
`
const ButtonWrapper = styled.div`
  padding-top: 20px;
`

const EnterEmail: React.FC<EnterEmailProps> = ({
  handleEmailEntered,
  username,
  setUsername,
  usernameError,
  setUsernameError,
  isLoading = false,
}) => {
  const filter = new Filter()

  const isValidUsername = (email: string) => {
    return ValidationUtils.validateUsername(email)
  }

  const handleOnUsernameTextChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const username = isEmpty(e.target.value)
      ? ''
      : filter.clean(e.target.value).replaceAll('*', '')
    setUsername(username)
    setUsernameError(!isValidUsername(username))
  }

  const validateUsernameOnBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    setUsernameError(!isValidUsername(username))
  }

  return (
    <Container>
      <h2>
        Forgot password? <br />
        We are here to help!
      </h2>
      <p style={{ fontWeight: 600 }}>Enter your username to continue</p>
      <InputField
        error={usernameError}
        helperText={usernameError ? 'Please enter a valid username' : ''}
        fullWidth
        label='Your username'
        description='Enter username for Forgot Password'
        onChange={handleOnUsernameTextChange}
        onBlur={validateUsernameOnBlur}
        value={username}
      />
      <ButtonWrapper>
        <Button
          width='100%'
          color='secondary'
          variant='contained'
          onClick={handleEmailEntered}
          loading={isLoading}
        >
          Continue
        </Button>
      </ButtonWrapper>
    </Container>
  )
}

export default withRouter(EnterEmail)
