import ApiManager from '../../api/ApiManager'
import { HttpMethods } from '../../enums/HttpMethodsEnum'
import React from 'react'
import SkeletonLoader from '../../assets/images/Skeleton-loader.gif'
import UrlConstants from '../../constants/UrlConstants'
import customerStore from '../../datastore/CustomerStore'
import eipLogo from '../../assets/vectors/eipLogo.svg'
import flag from '../../assets/images/flag-logo.png'
import { isEmpty } from 'lodash'
import userActionStore from '../../datastore/UserActionStore'

// import { PulseLoader } from 'react-spinners'

interface LazyLoadImageFromUrlProps {
  brandName: string
  width?: string
  height?: string
  useAltLogo?: boolean
  isNav?: boolean
  isLoading?: boolean
}

const LazyLoadImageFromUrl: React.FC<LazyLoadImageFromUrlProps> = ({
  brandName,
  width,
  height,
  useAltLogo = false,
  isNav = false,
  isLoading,
}) => {
  const [imgSrc, setImageSrc] = React.useState('')
  const [dimensions, setDimensions] = React.useState({
    height: window.innerHeight,
    width: window.innerWidth,
  })

  const debounce = <A extends unknown[]>(
    callback: (...args: A) => unknown,
    msDelay: number
  ) => {
    let timer: NodeJS.Timeout | undefined

    return (...args: A) => {
      clearTimeout(timer)

      timer = setTimeout(() => {
        timer = undefined
        callback(...args)
      }, msDelay)
    }
  }

  React.useEffect(() => {
    const debouncedHandleResize = debounce(function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      })
    }, 100)

    window.addEventListener('resize', debouncedHandleResize)

    return () => {
      window.removeEventListener('resize', debouncedHandleResize)
    }
  })

  React.useEffect(() => {
    if (!isEmpty(brandName)) {
      const brandLogoUrl = customerStore.getBrandLogoUrl()
      const brandLogoAltUrl = customerStore.getBrandLogoAltUrl()
      const isCarrierBrandLogo =
        brandName.endsWith('-medicare') ||
        brandName.endsWith('medicareassociation') || brandName.startsWith('devoted')
      const isCarrierBrandNameInBrandLogoUrl = brandLogoUrl.includes(
        brandName.replace(/ /g, '-').replace('-medicare', '')
      )
      if (isCarrierBrandLogo) {
        if (isCarrierBrandNameInBrandLogoUrl) {
          if (dimensions.width <= 600 && brandLogoAltUrl !== '')
            setImageSrc(brandLogoAltUrl)
          else setImageSrc(brandLogoUrl)
        } else {
          ApiManager.makeApiCall(
            UrlConstants.CDN_ENDPOINT.USECASE,
            HttpMethods.GET_METHOD,
            {
              brand: brandName,
            }
          )
            .then((response) => {
              if (response.status === 200) {
                if ('logo' in response.data) {
                  if (
                    !brandName.startsWith('mutual') ||
                    (brandName.startsWith('mutual') && !useAltLogo)
                  )
                    setImageSrc(response.data.logo)
                  customerStore.setBrandLogoUrl(response.data.logo)
                }
                if ('alternateLogo' in response.data) {
                  if (brandName.startsWith('mutual') && useAltLogo === true)
                    setImageSrc(response.data.alternateLogo)
                  customerStore.setBrandLogoAltUrl(response.data.alternateLogo)
                }
              } else throw response
            })
            .catch(() => {
              setImageSrc('flag')
              customerStore.setBrandLogoUrl('')
              customerStore.setBrandLogoAltUrl('')
            })
        }
      } else {
        customerStore.setBrandLogoUrl('')
        customerStore.setBrandLogoAltUrl('')
        setImageSrc('flag')
      }
    }
  }, [brandName])

  React.useEffect(() => {
    console.log('imgSrc: ', imgSrc)
  }, [imgSrc])

  return (
    <div>
      {imgSrc === 'flag' && !isNav && (
        <img
          src={flag}
          alt={brandName}
          width={width}
          height={height}
          style={
            dimensions.width < 300
              ? { marginTop: '2px' }
              : brandName.startsWith('mutual')
                ? { marginTop: '6px' }
                : {}
          }
        />
      )}

      {(imgSrc === 'flag' || imgSrc === '') &&
        isNav &&
        !brandName.startsWith('mutual') &&
        !brandName.startsWith('aetna') &&
        !brandName.startsWith('medicareassociation') && !brandName.startsWith('devoted') &&
        !isLoading && (
          <img
            src={eipLogo}
            alt={'EIP Logo'}
            width={width}
            height={height}
            style={
              dimensions.width < 300
                ? { marginTop: '2px' }
                : brandName.startsWith('mutual')
                  ? { marginTop: '6px' }
                  : {}
            }
          />
        )}

      {(((imgSrc === 'flag' || imgSrc === '') &&
        isNav &&
        (brandName.startsWith('mutual') ||
          brandName.startsWith('aetna') || brandName.startsWith('devoted') ||
          brandName.startsWith('medicareassociation'))) ||
        (isNav && isLoading)) && (
          // <PulseLoader size={15} color={'#a0aec0'} loading={true} />
          <img src={SkeletonLoader} alt='loading...' />
        )}

      {imgSrc && imgSrc !== 'flag' && (
        <img
          src={imgSrc}
          alt={brandName}
          width={dimensions.width < 300 ? 78 : width}
          height={height}
          style={
            dimensions.width < 300
              ? { marginTop: '2px' }
              : brandName.startsWith('mutual')
                ? { marginTop: '6px' }
                : {}
          }
          onError={({ currentTarget }) => {
            currentTarget.onerror = null // prevents looping
            currentTarget.src = flag
            if (height) currentTarget.height = +height.replace('px', '')
          }}
        />
      )}
    </div>
  )
}

export default LazyLoadImageFromUrl
