import MuiSelect, { SelectChangeEvent } from '@mui/material/Select'
import React, { MouseEventHandler } from 'react'

import FormControl from '@mui/material/FormControl'
import { FormHelperText } from '@mui/material'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import styled from '@emotion/styled'
import theme from '../../../global/theme'

interface SelectProps {
  width?: string
  height?: string
  margin?: string
  onChange?: (e: SelectChangeEvent) => void
  value?: string | any
  options: string[] | number[]
  formLabel: string
  borderRadius?: string
  labelMargin?: string
  padding?: string
  error?: boolean
  helperText?: string
  notched?: boolean
  shrink?: boolean
  onMouseUp?: MouseEventHandler<HTMLDivElement>
  responsivePopover?: boolean
  isInfoSteps?: boolean
  disabled?: boolean
}

const Container = styled.div<{ margin?: string; isInfoSteps?: boolean }>`
  margin: ${(props) => props.margin};

  ${(props) =>
    props.isInfoSteps &&
    `
    .MuiFormControl-root {
      width: 100%;
    }
  `}
`

const Select: React.FC<SelectProps> = ({
  width = '100%',
  height = '30px',
  margin = '0px 0px',
  padding = '0px 0px',
  onChange,
  value,
  options,
  formLabel,
  borderRadius = '50px',
  labelMargin = '0px 0px 4px 0px',
  error = false,
  helperText = '',
  notched,
  shrink,
  onMouseUp,
  responsivePopover = false,
  isInfoSteps = false,
  disabled = false,
}) => {
  const isResponsivePopover: string = responsivePopover
    ? width
    : width + ' !important'
  return (
    <Container
      margin={margin}
      style={{ flex: '1', borderRadius: '20px' }}
      isInfoSteps={isInfoSteps}
    >
      <FormControl sx={{ minWidth: `${width}` }}>
        <InputLabel
          shrink={shrink}
          error={error}
          sx={{
            fontStyle: 'bold',
            fontSize: '14px',
            margin: labelMargin,
            top: '-6px',
            '&.Mui-focused': {
              top: '0px',
            },
            '&.MuiInputLabel-shrink': {
              top: '0px',
            },
          }}
        >
          {formLabel}
        </InputLabel>
        <MuiSelect
          notched={notched}
          label={formLabel}
          labelId='demo-simple-select-label'
          id='demo-simple-select'
          value={value || ''}
          onChange={onChange}
          onMouseUp={onMouseUp}
          MenuProps={{ keepMounted: true, disablePortal: true }}
          error={error}
          disabled={disabled}
          sx={{
            height: `${height}`,
            fontSize: '14px',
            backgroundColor: `${theme.colors.backgroundColorContainer}`,
            borderRadius: `${borderRadius}`,
            padding: `${padding}`,
            '& .MuiMenu-list': {
              maxHeight: '300px',
            },
            '@media screen and (max-width: 750px)': {
              width: width,
              '& .MuiMenu-list': {
                width: width,
              },
              '& .MuiPopover-paper': {
                minWidth: `${isResponsivePopover}`,
              },
            },
          }}
        >
          {options.map((option) => (
            <MenuItem value={option} key={option} style={{ minWidth: width }}>
              {option}
            </MenuItem>
          ))}
        </MuiSelect>
        <FormHelperText sx={{ marginTop: 0.5 }} error={error}>
          {helperText}
        </FormHelperText>
      </FormControl>
    </Container>
  )
}

export default Select
