import Button from '../../../common/Button/Button.component'
import React from 'react'
import customerStore from '../../../../datastore/CustomerStore'
import theme from '../../../../global/theme'

interface FallbackStepProps {
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const FallbackStep: React.FC<FallbackStepProps> = ({ setModalOpen }) => {
  return (
    <div>
      <h3
        style={{
          fontSize: '20px',
          color: theme.colors.primary,
          paddingBottom: '10px',
        }}
      >
        Would you like to manually input your prescriptions?
      </h3>
      <p
        style={{
          fontWeight: 700,
          color: theme.colors.textGrey,
          maxWidth: '650px',
          paddingBottom: '10px',
        }}
      >
        {customerStore.getIsSsnProvided()
          ? 'We have nothing on file for that Social Security Number (SSN). You will need to manually input your prescriptions.'
          : 'Something went wrong while fetching your prescriptions automatically. You will need to manually input your prescriptions.'}
      </p>
      <div style={{ display: 'flex', justifyContent: 'center', gap: '20px' }}>
        <Button
          width='100px'
          color='secondary'
          variant='contained'
          onClick={() => setModalOpen(false)}
        >
          Ok
        </Button>
      </div>
    </div>
  )
}

export default FallbackStep
