import BottomStepNavigator from '../../BottomStepNavigator.component'
import { PrescriptionCountEnum } from '../../../../enums/PrescriptionCountEnum'
import Question from '../Common/Question'
import React from 'react'
import RouteConstants from '../../../../constants/RouteConstants'
import Select from '../../../common/Select/Select.component'
import customerStore from '../../../../datastore/CustomerStore'
import styled from '@emotion/styled'
import { useNavigate } from 'react-router-dom'

interface PrescriptionQuestionProps {
  setStep: React.Dispatch<React.SetStateAction<number>>
  completePrescriptionStep: () => void
  buttonLoading: boolean
}

const QuestionContainer = styled.div`
  width: 100%;
`

const PrescriptionCountQuestionWrapper = styled.div`
  text-align: center;
  padding: 20px 0px;

  .head {
    font-weight: 600;
  }

  .select-wrapper {
    padding-top: 20px;
  }
`

const PrescriptionQuestion: React.FC<PrescriptionQuestionProps> = ({
  setStep,
  completePrescriptionStep,
  buttonLoading,
}) => {
  const navigate = useNavigate()

  const [questionResponse, setQuestionResponse] = React.useState<
    'Yes' | 'No' | null
  >(null)
  const [prescriptionCount, setPrescriptionCount] =
    React.useState<PrescriptionCountEnum | null>(null)

  const getPrescriptionCountRange = (
    prescriptionCount: PrescriptionCountEnum | null
  ) => {
    switch (prescriptionCount) {
      case PrescriptionCountEnum.LESS_THAN_THREE:
        return [0, 2]
      case PrescriptionCountEnum.THREE_TO_FIVE:
        return [3, 5]
      case PrescriptionCountEnum.SIX_TO_TEN:
        return [6, 10]
      case PrescriptionCountEnum.ELEVEN_PLUS:
        return [11, 100]
      default:
        return [0, 0]
    }
  }

  const handleYes = () => {
    if (customerStore.getIsSsnProvided()) {
      setStep(6)
    } else {
      customerStore.getIsMEnabled() ? setQuestionResponse('Yes') : setStep(6)
    }
  }

  React.useEffect(() => {
    if (
      prescriptionCount &&
      getPrescriptionCountRange(prescriptionCount)[0] >=
        customerStore.getMMinimumDrugCount()
    ) {
      setStep(2)
    } else if (
      prescriptionCount &&
      getPrescriptionCountRange(prescriptionCount)[0] <
        customerStore.getMMinimumDrugCount()
    ) {
      setStep(6)
    }
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [prescriptionCount, setStep])

  return (
    <QuestionContainer>
      <Question
        handleYes={handleYes}
        handleNo={completePrescriptionStep}
        heading='Are you currently using any prescribed medications?'
        description='Adding your prescription drugs can help us determine your drug costs.'
        width='100%'
      />
      {customerStore.getIsMEnabled() && questionResponse === 'Yes' && (
        <PrescriptionCountQuestionWrapper>
          <p className='head'>
            Please select your number of prescriptions below.
          </p>
          <div className='select-wrapper'>
            <Select
              formLabel=''
              options={[
                PrescriptionCountEnum.LESS_THAN_THREE,
                PrescriptionCountEnum.THREE_TO_FIVE,
                PrescriptionCountEnum.SIX_TO_TEN,
                PrescriptionCountEnum.ELEVEN_PLUS,
              ]}
              width='150px'
              height='40px'
              value={prescriptionCount}
              onChange={(e) => {
                setPrescriptionCount(e.target.value as PrescriptionCountEnum)
              }}
            />
          </div>
        </PrescriptionCountQuestionWrapper>
      )}
      <BottomStepNavigator
        handleNext={completePrescriptionStep}
        isLoading={buttonLoading}
        currentInfoStep='Prescriptions'
        disableBackButton={true}
        disableNext={
          customerStore.getIsMEnabled() &&
          getPrescriptionCountRange(prescriptionCount)[1] === 0 &&
          questionResponse === 'Yes'
        }
        handleViewPlans={() => {
          window.scrollTo({ top: 0, behavior: 'smooth' })
          navigate(RouteConstants.DASHBOARD)
        }}
      />
    </QuestionContainer>
  )
}

export default PrescriptionQuestion
