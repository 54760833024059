import React from 'react'
import RouteConstants from '../../../constants/RouteConstants'
import customerStore from '../../../datastore/CustomerStore'
import { fireEvent } from '../../../cra'
import { getContact } from '../../../utils/getContact'
import styled from '@emotion/styled'
import theme from '../../../global/theme'

interface FooterProps {
  showFooter?: boolean
}

const Container = styled.div<FooterProps>`
  display: ${(props) => (props.showFooter ? 'block' : 'none')};
  background-color: ${theme.colors.primary};
  color: ${theme.colors.textLight};
  font-size: 12px;
  padding: 20px;
  text-align: center;
  p {
    padding-top: 10px;

    a {
      color: inherit;
    }
  }
`
const TextContainer = styled.a`
  font-size: 14px;
  text-decoration: none;
  transition: color 0.3s;

  &:hover {
    text-decoration: underline;
    opacity: 0.8;
  }
`

const Footer: React.FC<{ showDiscountsDisclaimer: boolean }> = ({
  showDiscountsDisclaimer,
}) => {
  const [showFooter, setShowFooter] = React.useState(true)

  const currentURLPath = window.location.href.split('/')[3]

  const fontStyle = { fontStyle: 'italic' }

  const footerSmid = process.env.REACT_APP_FOOTER_SMID
  const smid = footerSmid ? footerSmid.split('_')[0] : ''

  const remainingSmidText = footerSmid
    ? footerSmid.split('_').slice(1).join('_')
    : ''

  const leadBrand = customerStore.getBrandName()

  React.useEffect(() => {
    if (currentURLPath === RouteConstants.INFO_STEPS) {
      setShowFooter(false)
    } else {
      setShowFooter(true)
    }
  }, [currentURLPath])

  React.useEffect(() => {
    window.Invoca && window.Invoca.PNAPI.run()
  }, [])

  return (
    <Container showFooter={showFooter}>
      {showDiscountsDisclaimer && (
        <p>
          <span>
            * Disclaimer: Discounts are subject to approval and are only
            indicative of potential reduction in costs
          </span>
        </p>
      )}
      <p>
        ©{' '}
        <a
          target='_blank'
          rel='noreferrer'
          href='https://eliteinsurancepartners.com/'
        >
          {new Date().getFullYear()} Elite Insurance Partners
        </a>
        . All Rights Reserved.
      </p>
      <p>
        Owned by: Elite Insurance Partners LLC. This website is not connected
        with the federal government or the federal Medicare program. The purpose
        of this website is the solicitation of insurance. We do not offer every
        plan available in your area. Currently we represent{' '}
        {leadBrand.startsWith('aetna') ||
        leadBrand.startsWith('mutual') ||
        leadBrand.startsWith('devoted')
          ? '1 organization '
          : '26 organizations '}
        which offer{' '}
        {leadBrand.startsWith('aetna') || leadBrand.startsWith('devoted')
          ? '854'
          : leadBrand.startsWith('mutual')
          ? '150'
          : '3,740'}{' '}
        products in your area. Please contact Medicare.gov or 1-800-MEDICARE or
        your local State Health Insurance Program to get information on all of
        your options.
        <br />
      </p>
      <p style={{ fontSize: '14px' }}>
        <TextContainer
          target='_blank'
          rel='noreferrer'
          href='https://eliteinsurancepartners.com/legal-disclosure/'
        >
          Legal Disclosure
        </TextContainer>
        &nbsp; | &nbsp;
        <TextContainer
          target='_blank'
          rel='noreferrer'
          href='https://eliteinsurancepartners.com/terms-and-conditions/'
        >
          Terms of Use
        </TextContainer>
        &nbsp; | &nbsp;
        <TextContainer
          target='_blank'
          rel='noreferrer'
          href='https://eliteinsurancepartners.com/privacy-policy/'
        >
          Privacy Policy
        </TextContainer>
      </p>
      <p style={{ paddingTop: '10px' }}> MULTIPLAN_EIPQUOTE_M </p>
    </Container>
  )
}

export default Footer
