const ProviderIcon: React.FC<{ color?: string }> = ({ color = '#222C69' }) => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M8 3V5H6V9C6 11.21 7.79 13 10 13C12.21 13 14 11.21 14 9V5H12V3H15C15.552 3 16 3.448 16 4V9C16 11.973 13.838 14.44 11 14.917V16.5C11 18.433 12.567 20 14.5 20C15.997 20 17.275 19.06 17.775 17.737C16.728 17.27 16 16.22 16 15C16 13.343 17.343 12 19 12C20.657 12 22 13.343 22 15C22 16.371 21.08 17.527 19.824 17.885C19.21 20.252 17.059 22 14.5 22C11.462 22 9 19.538 9 16.5V14.917C6.162 14.441 4 11.973 4 9V4C4 3.448 4.448 3 5 3H8Z'
        fill={color}
      />
    </svg>
  )
}
export default ProviderIcon
