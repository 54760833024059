import customerStore, { MSPlanDiscountsAppliedType } from "../datastore/CustomerStore"

const getMonthlyPremiumWithDiscounts = (
  premium: number,
  planId: string,
  discounts?: MSPlanDiscountsAppliedType
): number => {
  let discountsApplied = discounts ? discounts : customerStore.getMSPlanDiscountsApplied(planId)
  let discountsPercent = discountsApplied
    ? discountsApplied.discountsPercent
    : 0
  return discountsPercent <= 1 ? (1 - discountsPercent) * premium : 0
}

export {
  getMonthlyPremiumWithDiscounts,
}
