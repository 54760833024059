import React from 'react'
import styled from '@emotion/styled'

interface IconProps extends IconContainerStyledProps {
  childComponent?: React.ReactNode
}

interface IconContainerStyledProps {
  color?: string
  fontSize?: string
  padding?: string
}

const IconContainer = styled.span<IconContainerStyledProps>`
  color: ${(props) => props.color};
  font-size: ${(props) => props.fontSize};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${(props) => props.padding};
`

const Icon: React.FC<IconProps> = ({
  childComponent,
  color,
  fontSize,
  padding = '0px 0px',
}) => {
  return (
    <IconContainer
      color={color}
      fontSize={fontSize ? fontSize : '16px'}
      padding={padding}
    >
      {childComponent}
    </IconContainer>
  )
}

export default Icon
