import ApiManager from '../../../api/ApiManager'
import Button from '../../common/Button/Button.component'
import { FaCheckCircle as CheckedCircle } from 'react-icons/fa'
import { BiCircle as Circle } from 'react-icons/bi'
import DateField from '../../common/DateField'
import { HttpMethods } from '../../../enums/HttpMethodsEnum'
import Icon from '../../common/Icon/Icon.component'
import InputField from '../../common/InputField/InputField.component'
import LinearProgress from '@mui/material/LinearProgress'
import React from 'react'
import { SnackbarTypes } from '../../../enums/SnackbarTypesEnum'
import StringConstants from '../../../constants/StringConstants'
import UrlConstants from '../../../constants/UrlConstants'
import ValidationUtils from '../../../utils/validation/ValidationUtils'
import customerStore from '../../../datastore/CustomerStore'
import dayjs from 'dayjs'
import { getApiErrorMessage } from '../../../utils/StringUtils'
import { getDateWithinApprovedDOBRange } from '../../../utils/CommonUtils'
import { isEmpty } from 'lodash'
import moment from 'moment'
import oldMan from '../../../assets/vectors/oldMan.svg'
import oldWoman from '../../../assets/vectors/oldWoman.svg'
import snackbarStore from '../../../datastore/SnackbarStore'
import styled from '@emotion/styled'
import theme from '../../../global/theme'

interface DataProps {
  spouseGender: string
  spouseDateOfBirth: string
  spouseFirstName: string
  spouseLastName: string
}

const Container = styled.div`
  max-width: 350px;
  height: 100%;
  h2 {
    color: ${theme.colors.primary};
    font-size: 22px;
    padding-bottom: 20px;
  }
`
const ProgressWrapper = styled.div`
  display: flex;
  align-items: center;

  span {
    font-size: 12px;
    line-height: 20px;
    padding-right: 10px;
    font-weight: 600px;
  }
`

const LinearProgressWrapper = styled.div`
  width: 250px;
`
const FieldLabel = styled.h3`
  font-size: 14px;
  font-weight: 700;
  color: ${theme.colors.primary};
  padding-top: 20px;
  padding-bottom: 5px;
  span {
    color: ${theme.colors.secondary};
  }
`
const GenderInputWrapper = styled.div<{
  error: boolean
}>`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 20px;
  border: ${({ error }) =>
    error === true
      ? `1px solid ${theme.colors.secondary}`
      : `0px solid ${theme.colors.secondary}`};
`
const GenderInput = styled.div<{
  value: string
}>`
  width: 150px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${theme.colors.borderColor};
  border-radius: 4px;
  position: relative;
  &:hover {
    border: 2px solid ${theme.colors.success};
  }
  img {
    height: 60px;
  }
  span {
    position: absolute;
    top: 10px;
    right: 10px;
  }
`
const GenderHelperLabel = styled.h3`
  font-size: 12px;
  font-weight: 400;
  color: ${theme.colors.primary};
  padding-top: 5px;
  padding-bottom: 5px;
  span {
    color: ${theme.colors.secondary};
    padding-left: 15px;
  }
`

const ButtonWrapper = styled.div`
  padding-top: 30px;
`

const AddSpouseQuestionnaire = () => {
  const [step, setStep] = React.useState(1)
  const [dob, setDOB] = React.useState('')
  const [dobError, setDOBError] = React.useState(false)
  const [dobHelperText, setDOBHelperText] = React.useState('')
  const [gender, setGender] = React.useState('')
  const [genderError, setGenderError] = React.useState(false)
  const [genderHelperText, setGenderHelperText] = React.useState('')
  const [firstName, setFirstName] = React.useState('')
  const [firstNameError, setFirstNameError] = React.useState(false)
  const [firstNameHelperText, setFirstNameHelperText] = React.useState('')
  const [lastName, setLastName] = React.useState('')
  const [lastNameError, setLastNameError] = React.useState(false)
  const [lastNameHelperText, setLastNameHelperText] = React.useState('')
  const yearsTo = dayjs().year()
  const defaultYear = yearsTo - 67
  const currentDate = dayjs(dob || new Date())
  const defaultDate = currentDate.year(defaultYear).format('YYYY-MM-DD')
  const [isDisable, setIsDisable] = React.useState(false)

  const validateDOB = (dob: string) => {
    const dobError = !ValidationUtils.validateDOB(dob)
    setDOBError(dobError)
    setDOBHelperText(
      isEmpty(dob)
        ? StringConstants.UI_ERROR_MSG_FOR_EMPTY_DOB
        : dobError
        ? StringConstants.UI_ERROR_MSG_FOR_INVALID_DOB
        : ''
    )
    return dobError
  }
  const handleDOBChange = (e: any) => {
    if (e) {
      const givenDOB = dayjs(e.$d).format('YYYY-MM-DD')
      if (validateDOB(givenDOB)) setDOB(givenDOB)
    }
  }
  const handleInputDOB = (e: any) => {
    if (e) {
      const givenDOB = dayjs(e.$d).format('YYYY-MM-DD')
      if (e.$y >= 1000) {
        const finalDate = getDateWithinApprovedDOBRange(e)
        validateDOB(finalDate)
        setDOB(finalDate)
      } else validateDOB(givenDOB)
    }
  }

  const genderHandler = (e: React.MouseEvent<HTMLDivElement>) => {
    setGenderHelperText('')
    setGenderError(false)
    let value = e.currentTarget.getAttribute('value')
    setGender(value ? value : '')
  }

  const handleNext = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault()
    let hasError = false

    if (isEmpty(dob)) {
      setDOBHelperText(StringConstants.UI_ERROR_MSG_FOR_EMPTY_DOB)
      setDOBError(true)
      hasError = true
    }
    if (!isEmpty(dob)) {
      validateDOB(dob)
    }
    if (isEmpty(gender)) {
      setGenderHelperText(StringConstants.UI_ERROR_MSG_FOR_GENDER_NOT_SELECTED)
      setGenderError(true)
      hasError = true
    }

    if (!(genderError || dobError || hasError)) {
      setStep(step + 1)
    }
  }

  const validateFirstName = (firstName: string) => {
    const firstNameError = !ValidationUtils.validateFirstName(firstName)
    setFirstNameError(firstNameError)
    setFirstNameHelperText(
      isEmpty(firstName)
        ? StringConstants.UI_ERROR_MSG_FOR_EMPTY_FIRST_NAME
        : firstNameError
        ? StringConstants.UI_ERROR_MSG_FOR_INVALID_FIRST_NAME
        : ''
    )
  }
  const handleFirstNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    validateFirstName(e.target.value)
    setFirstName(e.target.value)
  }

  const validateLastName = (lastName: string) => {
    const lastNameError = !ValidationUtils.validateLastName(lastName)
    setLastNameError(lastNameError)
    setLastNameHelperText(
      isEmpty(lastName)
        ? StringConstants.UI_ERROR_MSG_FOR_EMPTY_LAST_NAME
        : lastNameError
        ? StringConstants.UI_ERROR_MSG_FOR_INVALID_LAST_NAME
        : ''
    )
  }
  const handleLastNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    validateLastName(e.target.value)
    setLastName(e.target.value)
  }

  const handleSave = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault()
    let hasError = false

    if (isEmpty(firstName)) {
      setFirstNameHelperText(StringConstants.UI_ERROR_MSG_FOR_EMPTY_FIRST_NAME)
      setFirstNameError(true)
      hasError = true
    }

    if (isEmpty(lastName)) {
      setLastNameHelperText(StringConstants.UI_ERROR_MSG_FOR_EMPTY_LAST_NAME)
      setLastNameError(true)
      hasError = true
    }

    if (!(firstNameError || lastNameError || hasError)) {
      setIsDisable(true)
      let data: DataProps = {
        spouseDateOfBirth: dob,
        spouseGender: gender,
        spouseFirstName: firstName,
        spouseLastName: lastName,
      }

      if (!isEmpty(data)) {
        ApiManager.makeApiCallWithAuthentication(
          UrlConstants.ADD_SPOUSE.USECASE,
          HttpMethods.POST_METHOD,
          data
        )
          .then((response) => {
            if (response.status === 200) {
              snackbarStore.set({
                snackbarMessage: StringConstants.SNACKBAR_SAVE_SUCCESS_MSG,
                snackbarOpen: true,
                snackbarType: SnackbarTypes.SUCCESS,
              })
              customerStore.setSpouseBasicDetails({
                ...data,
                spouseOptIn: true,
              })
              customerStore.setForSpouse(true)
            } else throw response
          })
          .catch((err) => {
            snackbarStore.set({
              snackbarMessage: getApiErrorMessage('add spouse'),
              snackbarOpen: true,
              snackbarType: SnackbarTypes.ERROR,
            })
          })
          .finally(() => {
            setIsDisable(false)
          })
      }
    }
  }

  return (
    <Container>
      <h2>Add your Spouse/Partner</h2>
      <ProgressWrapper>
        <span>Your Progress</span>
        <LinearProgressWrapper>
          <LinearProgress
            color='primary'
            variant='determinate'
            value={step * 50}
            sx={{ width: '100%' }}
          />
        </LinearProgressWrapper>
      </ProgressWrapper>
      {step === 1 ? (
        <>
          <FieldLabel>
            Your Spouse/Partner’s Date of Birth<span>*</span>
          </FieldLabel>
          <DateField
            format='MM/DD/YYYY'
            value={dayjs(dayjs(dob).format('YYYY-MM-DD'))}
            minDate={dayjs(
              (moment().year() - 120).toString().substring(0, 2) + '00-01-01'
            )}
            maxDate={dayjs(moment().format('YYYY-MM-DD'))}
            defaultCalendarMonth={dayjs(
              moment(defaultDate).startOf('day').format('YYYY-MM-DD')
            )}
            error={dobError}
            errorText={dobHelperText}
            readonly={false}
            onAccept={handleDOBChange}
            onChange={handleInputDOB}
          />
          <FieldLabel>
            Gender<span>*</span>
          </FieldLabel>
          <GenderInputWrapper error={genderError}>
            <div style={{ flexDirection: 'column' }}>
              <GenderInput value='male' onClick={genderHandler}>
                <img alt='male' src={oldMan} />
                <span>
                  {gender === 'male' ? (
                    <Icon
                      childComponent={<CheckedCircle />}
                      color={theme.colors.success}
                    />
                  ) : (
                    <Icon childComponent={<Circle />} />
                  )}
                </span>
              </GenderInput>
              <FieldLabel style={{ textAlign: 'center' }}>MALE</FieldLabel>
            </div>
            <div style={{ flexDirection: 'column' }}>
              <GenderInput value='female' onClick={genderHandler}>
                <img alt='female' src={oldWoman} />
                <span>
                  {gender === 'female' ? (
                    <Icon
                      childComponent={<CheckedCircle />}
                      color={theme.colors.success}
                    />
                  ) : (
                    <Icon childComponent={<Circle />} />
                  )}
                </span>
              </GenderInput>
              <FieldLabel style={{ textAlign: 'center' }}>FEMALE</FieldLabel>
            </div>
          </GenderInputWrapper>
          <GenderHelperLabel>
            <span>{genderHelperText}</span>
          </GenderHelperLabel>
          <Button
            width='100%'
            variant='contained'
            color='secondary'
            onClick={handleNext}
            id={`Spouse - Gender & DOB`}
            height='10%'
          >
            <span
              style={{ fontSize: '14px', fontWeight: '600', lineHeight: '1.5' }}
            >
              Tell us a bit about your Partner/Spouse
            </span>
          </Button>
        </>
      ) : (
        <>
          <FieldLabel>
            Your Spouse/Partner’s first name <span>*</span>
          </FieldLabel>
          <InputField
            error={firstNameError}
            helperText={firstNameHelperText}
            value={firstName}
            label='First name'
            width='100%'
            color='primary'
            onChange={handleFirstNameChange}
          />
          <FieldLabel>
            Your Spouse/Partner’s last name <span>*</span>
          </FieldLabel>
          <InputField
            error={lastNameError}
            helperText={lastNameHelperText}
            value={lastName}
            label='Last name'
            width='100%'
            color='primary'
            onChange={handleLastNameChange}
          />
          <ButtonWrapper>
            <Button
              width='100%'
              variant='contained'
              color='primary'
              onClick={() => setStep(step - 1)}
            >
              Back to previous step
            </Button>

            <Button
              width='100%'
              variant='contained'
              color='secondary'
              onClick={handleSave}
              id={`Add Spouse/Partner`}
              disabled={isDisable}
            >
              Finish
            </Button>
          </ButtonWrapper>
        </>
      )}
    </Container>
  )
}

export default AddSpouseQuestionnaire
