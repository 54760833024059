import Button from '../Button/Button.component'
import InputField from '../InputField/InputField.component'
import { MdOutlineLocationOn } from 'react-icons/md'
import React from 'react'
import { SnackbarTypes } from '../../../enums/SnackbarTypesEnum'
import ValidationUtils from '../../../utils/validation/ValidationUtils'
import ZipUtils from '../../../utils/ZipUtils'
import customerStore from '../../../datastore/CustomerStore'
import { getApiErrorMessage } from '../../../utils/StringUtils'
import { isEmpty } from 'lodash'
import { observer } from 'mobx-react'
import snackbarStore from '../../../datastore/SnackbarStore'
import styled from '@emotion/styled'
import theme from '../../../global/theme'
import userActionStore from '../../../datastore/UserActionStore'

const MenuWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 10px 0px;
  grid-gap: 10px;
  max-width: 700px;
`
interface PostalCodeSelectionProps {
  onSubmit?: (zipInfo: {
    code: string
    county?: { countyName: string; countyFips: string }
    state?: string
    optCounties?: { countyName: string; countyFips: string }[]
  }) => void
  onCancel?: () => void
}

const PostalCodeSelection: React.FC<PostalCodeSelectionProps> = ({
  onSubmit,
  onCancel,
}) => {
  const [zip, setZip] = React.useState('')
  const [loading, setLoading] = React.useState<boolean>(false)

  const inputHandler = (e: any) => {
    setZip(e.target.value)
  }

  const onZipcodeSubmit = () => {
    setLoading(true)
    console.log('onZipcodeSubmit triggered')

    if (zip) {
      const zipCodeCache = userActionStore.zipCodeCache.find(
        (iterator) => iterator.code === zip
      )

      if (!zipCodeCache) {
        ZipUtils.GetCountyCityAndStateForZip(zip)
          .then((data) => {
            customerStore.setPharmacyPostalCode(zip)
            if (!isEmpty(data.state)) {
              userActionStore.setZipCodeCache({
                code: zip,
                state: data.state,
              })
            }
            if (!isEmpty(data.counties)) {
              userActionStore.setZipCodeCache({
                code: zip,
                optCounties: data.counties,
                county: data.counties[0],
              })
            }

            const zipInfo = {
              code: zip,
              state: data.state || '',
              county: data.counties[0] || '',
              optCounties: data.counties || [],
            }
            onSubmit && onSubmit(zipInfo)
          })
          .catch((err) => {
            snackbarStore.set({
              snackbarOpen: true,
              snackbarMessage:
                err && err.message
                  ? err.message
                  : getApiErrorMessage(
                      `fetch county, city and state for ${zip}`
                    ),
              snackbarType: SnackbarTypes.ERROR,
            })
          })
          .finally(() => {
            setLoading(false)
          })
      } else {
        onSubmit && onSubmit(zipCodeCache)
      }
    }
  }

  return (
    <MenuWrapper>
      <InputField
        placeholder='Enter Zip'
        width={window.innerWidth < 600 ? '100%' : '180px'}
        value={zip}
        onChange={inputHandler}
        symbol={
          <MdOutlineLocationOn style={{ color: `${theme.colors.secondary}` }} />
        }
      />

      <Button
        loading={loading}
        variant='contained'
        disabled={loading || !ValidationUtils.validateZipcode(zip)}
        width={window.innerWidth < 600 ? '100%' : '180px'}
        onClick={onZipcodeSubmit}
      >
        Change Zip Code
      </Button>
      {onCancel && (
        <Button
          loading={loading}
          variant='outlined'
          color='secondary'
          disabled={loading}
          width={window.innerWidth < 600 ? '100%' : '180px'}
          onClick={() => {
            onCancel()
          }}
        >
          Cancel
        </Button>
      )}
    </MenuWrapper>
  )
}

export default observer(PostalCodeSelection)
