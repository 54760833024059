import { makeAutoObservable, observable } from 'mobx'

import { MSPlanRequestFilter } from '../../interface/quotes/mSupplementPlans/request/MSPlanRequestFilter'
import { MSPlansDisplayFilters } from '../../interface/quotes/mSupplementPlans/MSPlansDisplayFilters'
import { PaginationDetailType } from '../../interface/common/PaginationType'
import { SortDetailType } from '../../interface/common/SortDetailType'

class MSupplementPlanFiltersStore {
  @observable msPlanFilters: MSPlansDisplayFilters
  @observable msPlanRequestFilter: MSPlanRequestFilter
  // @observable currentPlanYear: string
  @observable filterApplied: boolean
  @observable clearFilters: boolean
  @observable plansFetched: boolean
  @observable filtersFetched: boolean
  @observable totalCount: number | null
  @observable isQuotesPullStatusFetchTriggered: boolean
  @observable isEffectiveDateFilterApplied: boolean
  @observable effectiveDate: string
  @observable tempEffectiveDate: string
  @observable isFilterChanged: boolean
  @observable filtersActive: boolean
  @observable overlayLoading: boolean
  @observable isTobaccoChanged: boolean
  @observable prevSortType: string | null
  @observable paginationLoader: boolean
  @observable paginationApplied: boolean

  constructor() {
    makeAutoObservable(this, {
      msPlanFilters: observable.struct,
    })
    this.msPlanFilters = {} as MSPlansDisplayFilters
    this.msPlanRequestFilter = {} as MSPlanRequestFilter
    // this.currentPlanYear = ''
    this.filterApplied = false
    this.clearFilters = false
    this.plansFetched = false
    this.filtersFetched = false
    this.totalCount = null
    this.isQuotesPullStatusFetchTriggered = false
    this.isEffectiveDateFilterApplied = false
    this.effectiveDate = ''
    this.tempEffectiveDate = ''
    this.isFilterChanged = false
    this.filtersActive = false
    this.overlayLoading = false
    this.isTobaccoChanged = false
    this.prevSortType = null
    this.paginationLoader = false
    this.paginationApplied = false
  }

  setPlansFetched(planFetchedStatus: boolean) {
    this.plansFetched = planFetchedStatus
  }

  isPlanFetched() {
    return this.plansFetched
  }

  setFiltersFetched(filtersFetchedStatus: boolean) {
    this.filtersFetched = filtersFetchedStatus
  }

  isFiltersFetched() {
    return this.filtersFetched
  }

  getMSPlansFilters() {
    return this.msPlanFilters
  }

  setMSPlansFilters(_msPlanFilters: MSPlansDisplayFilters) {
    this.msPlanFilters = _msPlanFilters
  }

  getMSPlanRequestFilters() {
    return this.msPlanRequestFilter
  }

  setMSPlanRequestFilters(_msPlanRequestFilter: MSPlanRequestFilter) {
    this.msPlanRequestFilter = _msPlanRequestFilter
  }

  setSortDetail(_sortDetail: SortDetailType) {
    this.msPlanRequestFilter.sortDetail = _sortDetail
  }

  getSortDetail() {
    return this.msPlanRequestFilter.sortDetail
  }

  isFilterApplied() {
    return this.filterApplied
  }

  setFilterApplied(_filterApplied: boolean) {
    this.filterApplied = _filterApplied
  }

  getIsFilterChanged() {
    return this.isFilterChanged
  }

  setIsFilterChanged(_isFilterChanged: boolean) {
    this.isFilterChanged = _isFilterChanged
  }

  getIsOverLayLoading() {
    return this.overlayLoading
  }

  setIsOverLayLoading(_overLayLoading: boolean) {
    this.overlayLoading = _overLayLoading
  }

  isFiltersCleared() {
    return this.clearFilters
  }

  setClearFilters(_clearFilters: boolean) {
    this.clearFilters = _clearFilters
  }

  setSelectedSortOption(_selectedSortOption: string) {
    this.msPlanFilters.selectedSortOption = _selectedSortOption
  }

  setPrevSortType(_prevSortType: string | null) {
    this.prevSortType = _prevSortType
  }

  getSelectedSortOption() {
    return this.msPlanFilters.selectedSortOption
  }

  setPaginationDetail(_paginationDetail: PaginationDetailType) {
    this.msPlanRequestFilter.paginationDetail = _paginationDetail
  }

  getPaginationDetail(_paginationDetail: PaginationDetailType) {
    return this.msPlanRequestFilter.paginationDetail
  }

  getTotalCount() {
    return this.totalCount
  }

  setPaginationLoader(_paginationLoader: boolean) {
    this.paginationLoader = _paginationLoader
  }

  setPaginationApplied(_paginationApplied: boolean) {
    this.paginationApplied = _paginationApplied
  }

  setTotalCount(_totalCount: number | null) {
    this.totalCount = _totalCount
  }

  clearMSPlanRequestFilters() {
    this.msPlanRequestFilter = {} as MSPlanRequestFilter
  }

  clearMSPlanFilterStore() {
    this.msPlanFilters = {} as MSPlansDisplayFilters
    // this.currentPlanYear = ''
    this.filterApplied = false
    this.clearFilters = false
    this.plansFetched = false
    this.filtersFetched = false
    this.filtersActive = false
    this.totalCount = null
    this.overlayLoading = false
  }

  getisQuotesPullStatusFetchTriggered() {
    return this.isQuotesPullStatusFetchTriggered
  }
  setIsQuotesPullStatusFetchTriggered(status: boolean) {
    this.isQuotesPullStatusFetchTriggered = status
  }
  getIsEffectiveDateFilterApplied() {
    return this.isEffectiveDateFilterApplied
  }
  setIsEffectiveDateFilterApplied(status: boolean) {
    this.isEffectiveDateFilterApplied = status
  }

  setEffectiveDate(date: string) {
    this.effectiveDate = date
  }

  getEffectiveDate(): string {
    return this.effectiveDate
  }

  setTempEffectiveDate(date: string) {
    this.tempEffectiveDate = date
  }

  getTempEffectiveDate(): string {
    return this.tempEffectiveDate
  }

  isFiltersActive(): boolean {
    return this.filtersActive
  }

  setFiltersActive(activeStatus: boolean) {
    this.filtersActive = activeStatus
  }

  setIsTobaccoChanged(isTobaccoChanged: boolean) {
    this.isTobaccoChanged = isTobaccoChanged
  }

  getIsTobaccoChanged(): boolean {
    return this.isTobaccoChanged
  }
}

const msPlanFiltersStore = new MSupplementPlanFiltersStore()

export default msPlanFiltersStore
