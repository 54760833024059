import styled from '@emotion/styled'
import React from 'react'
import { ImCross } from 'react-icons/im'
import { VscClose } from 'react-icons/vsc'
import IconButton from '@mui/material/IconButton'
import theme from '../../../global/theme'

interface ButtonStyledProps {
  fontSize?: string
  textColor?: string
  iconType?: 'bold' | 'light' | undefined
}

interface ButtonProps extends ButtonStyledProps {
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined
}

const CloseButton = styled(IconButton)<ButtonStyledProps>`
  font-size: ${(props) => props.fontSize};
`

const CloseIconButton: React.FC<ButtonProps> = ({
  onClick,
  fontSize,
  textColor = theme.colors.primary,
  iconType,
}) => {
  return (
    <CloseButton
      aria-label='close'
      onClick={onClick}
      fontSize={fontSize ? fontSize : '16px'}
    >
      {iconType === 'bold' ? (
        <ImCross style={{ color: textColor }} />
      ) : (
        <VscClose style={{ color: textColor }} />
      )}
    </CloseButton>
  )
}

export default CloseIconButton
