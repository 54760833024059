import {
  frequencyToText,
  textToFrequency,
  titleCase,
} from '../../../../utils/StringUtils'

import Button from '../../../common/Button/Button.component'
import InputField from '../../../common/InputField/InputField.component'
import { MillimanResponse } from '../../../../interface/prescriptionDrug/PrescriptionDrug'
import ModalComponent from '../../../common/Modal/Modal.component'
import { PrescriptionDrugFrequencyEnum } from '../../../../enums/PrescriptionDrugFrequencyEnum'
import RadioGroup from '../../../common/RadioGroup/RadioGroup.component'
import React from 'react'
import Select from '../../../common/Select/Select.component'
import styled from '@emotion/styled'
import theme from '../../../../global/theme'

interface MillimanEditPrescriptionModalProps {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  data: MillimanResponse
  setMillimanResponse: React.Dispatch<React.SetStateAction<MillimanResponse[]>>
}

const ModalWrapper = styled.div`
  min-width: 500px;
  max-height: 500px;
  overflow-y: auto;
  @media screen and (max-width: 650px) {
    min-width: 200px;
  }
  h2 {
    font-size: 20px;
    color: ${theme.colors.primary};
  }
  .section {
    padding-bottom: 20px;
  }
  .label {
    font-size: 14px;
    color: ${theme.colors.textGrey};
  }
`

const MillimanEditPrescriptionModal: React.FC<
  MillimanEditPrescriptionModalProps
> = ({ setOpen, data, setMillimanResponse }) => {
  const [selectedDosageId, setSelectDosageId] = React.useState<number>(
    data.currentDosage.id
  )
  const [quantity, setQuantity] = React.useState<number>(data.currentDosage.qty)
  const [frequency, setFrequency] = React.useState<number>(
    data.currentDosage.frequency
  )

  const handleSave = () => {
    let selectedDosage = data.sfInfo.drugDosage.dosages.find(
      (dosage: any) => dosage.id === selectedDosageId
    )
    selectedDosage.qty = quantity
    selectedDosage.frequency = frequency
    setMillimanResponse((prevResponse) => {
      const newResponse = prevResponse.map((response) => {
        if (response.id === data.id) {
          response.currentDosage = selectedDosage
        }
        return response
      })
      return newResponse
    })
    setOpen(false)
  }

  return (
    <ModalComponent setOpen={setOpen} hideCloseButton={true}>
      <ModalWrapper>
        <div className='section'>
          <h2>{titleCase(data.millimanInfo.drugBrandName)}</h2>
          <p className='label'>Brand Drug Name</p>
        </div>

        <div className='section'>
          <p className='label'>Select dose</p>
          <RadioGroup
            radioButtons={data.sfInfo.drugDosage.dosages.map(
              (dosage: any) => dosage.name
            )}
            value={
              data.sfInfo.drugDosage.dosages.find(
                (dosage: any) => dosage.id === selectedDosageId
              )?.name
            }
            onChange={(e) => {
              const selection = e.target.value
              const selectedMatch = data.sfInfo.drugDosage.dosages.find(
                (dosage: any) => dosage.name === selection
              )
              setSelectDosageId(selectedMatch.id)
            }}
          />
        </div>
        <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
          <InputField
            type='number'
            label='Add Quantity'
            value={quantity}
            onChange={(e) => {
              let value = +e.target.value
              if (value <= 1) {
                value = 1
              }
              setQuantity(value)
            }}
          />
          <Select
            height='40px'
            width='100%'
            margin='8px 10px'
            formLabel='Select Frequency'
            options={Object.values(PrescriptionDrugFrequencyEnum).map((key) =>
              frequencyToText(+key)
            )}
            value={frequencyToText(frequency)}
            onChange={(e) => {
              setFrequency(textToFrequency(e.target.value))
            }}
          />
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            gap: '10px',
            paddingTop: '20px',
          }}
        >
          <Button
            width='100px'
            onClick={() => {
              setOpen(false)
            }}
          >
            Cancel
          </Button>
          <Button variant='contained' width='100px' onClick={handleSave}>
            Save
          </Button>
        </div>
      </ModalWrapper>
    </ModalComponent>
  )
}

export default MillimanEditPrescriptionModal
