import CloseIconButton from '../Button/CloseIconButton.component'
import React from 'react'
import { fireEvent } from '../../../cra'
import styled from '@emotion/styled'
import theme from '../../../global/theme'

interface ModalProps extends ContainerProps {
  children?: JSX.Element | string | undefined
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  description?: string
  hideCloseButton?: boolean
}

interface ContainerProps {
  height?: string | number
  width?: string | number
  padding?: string
}

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 2000;
  top: 0px;
  left: 0px;
  background: rgb(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
`

const Modal = styled.div<ContainerProps>`
  width: ${(p: ContainerProps) => p.width && p.width};
  height: ${(p: ContainerProps) => p.height};
  background-color: ${theme.colors.white};
  border-radius: 4px;
  position: relative;
  padding: ${(p: ContainerProps) => (p.padding ? p.padding : '40px 50px')};
  overflow-y: scroll;
  margin: 10px;
  @media screen and (max-width: 500px) {
    padding: 20px 20px;
  }
`
const CloseButtonContainer = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
`

const ModalComponent: React.FC<ModalProps> = ({
  children,
  setOpen,
  height,
  width,
  description,
  hideCloseButton = false,
  padding,
}) => {
  React.useEffect(() => {
    fireEvent('popupOpen', null, {
      description,
    })

    return () => {
      fireEvent('popupClose', null, {
        description,
      })
    }
  }, [description])

  return (
    <Container>
      <Modal
        height={height}
        width={width}
        style={{ overflowY: 'hidden' }}
        padding={padding}
      >
        {!hideCloseButton && (
          <CloseButtonContainer>
            <CloseIconButton
              onClick={(e) => setOpen(false)}
              fontSize='14px'
              textColor={theme.colors.primary}
              iconType='bold'
            />
          </CloseButtonContainer>
        )}
        {children}
      </Modal>
    </Container>
  )
}

export default ModalComponent
