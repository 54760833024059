import Button from '../../components/common/Button/Button.component'
import { FiChevronLeft } from 'react-icons/fi'
import ForgotPasswordReset from '../../components/ResetPasswordPage/ForgotPasswordReset'
import Icon from '../../components/common/Icon/Icon.component'
import LayoutComponent from '../../components/common/Layout/Layout.component'
import PasswordResetSuccessful from '../../components/ResetPasswordPage/PasswordResetSuccessful.component'
import React from 'react'
import ResetPasswordStepper from '../../components/ResetPasswordPage/Stepper'
import RouteConstants from '../../constants/RouteConstants'
import styled from '@emotion/styled'
import theme from '../../global/theme'
import { useNavigate } from 'react-router-dom'

const Container = styled.div`
  background-color: ${theme.colors.lightBack};
  display: flex;
  justify-content: center;
  padding: 100px 0px;
`
const ButtonDiv = styled.div`
  position: absolute;
  top: 108px;
  left: 30px;
  z-index: 500;

  @media (max-width: 750px) {
    position: absolute;
    top: 50;
    left: 0;
  }
`
const CenteredCard = styled.div`
  background-color: ${theme.colors.white};
  border-radius: 5px;
  position: relative;
  padding: 40px 50px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);

  @media screen and (max-width: 500px) {
    padding: 30px 20px;
  }

  @media screen and (max-width: 420px) {
    padding: 20px 10px;
  }
`

const ResetPasswordPage = () => {
  const currentURLPath = window.location.href.split('/')[3]
  const [resetSuccess, setResetSuccess] = React.useState(false)
  const handleResetSuccess = () => {
    setResetSuccess(true)
  }

  const navigate = useNavigate()

  return (
    <LayoutComponent>
      <Container>
        <ButtonDiv>
          <Button
            variant='text'
            width='100px'
            onClick={() => {
              navigate(RouteConstants.LOGIN, { replace: true })
            }}
          >
            <span style={{ display: 'flex', alignItems: 'center' }}>
              <Icon childComponent={<FiChevronLeft />} />
              Back
            </span>
          </Button>
        </ButtonDiv>
        <CenteredCard>
          {`/${currentURLPath}` === RouteConstants.FORGOT_PASSWORD ? (
            resetSuccess ? (
              <PasswordResetSuccessful />
            ) : (
              <ForgotPasswordReset handleResetSuccess={handleResetSuccess} />
            )
          ) : (
            <ResetPasswordStepper />
          )}
        </CenteredCard>
      </Container>
    </LayoutComponent>
  )
}

export default ResetPasswordPage
