import { RiCheckFill, RiCloseFill } from 'react-icons/ri'

import Icon from '../Icon/Icon.component'
import React from 'react'
import styled from '@emotion/styled'
import theme from '../../../global/theme'

interface PasswordStrengthProps {
  password?: string
  name?: string
  phone?: string
  email?: string
}

const Container = styled.div`
  padding-top: 15px;
  font-size: 14px;
  ul {
    list-style: none;
    padding-top: 15px;
    li {
      font-size: 12px;
      font-weight: 400;
      padding-bottom: 4px;
      display: flex;
      align-items: center;
    }
  }
`
const ProgressBar = styled.div`
  display: flex;
  align-items: center;
  color: #000000;
  span {
    font-weight: 700;
    padding-right: 10px;
  }
`

const LinearProgress = styled.span`
  width: 35px;
  height: 8px;
  border: 1px solid #dededd;
  border-radius: 1px;
  margin-right: 5px;
`

const PasswordStrength: React.FC<PasswordStrengthProps> = ({
  password,
  name,
  email,
  phone,
}) => {
  const [minlength, setminLength] = React.useState(false)
  const [minNumberAlpha, setMinNumberAlpha] = React.useState(false)
  const [passInclude, setPassInclude] = React.useState(false)
  const [specialChar, setSpecialChar] = React.useState(false)
  const [passwordStrength, setPasswordStrength] = React.useState(0)

  React.useEffect(() => {
    setPasswordStrength(0)

    if (password !== undefined) {
      if (password.match(/^.[\S]{6,}[\S]$/)) {
        setminLength(true)
        setPasswordStrength((v) => v + 1)
      } else {
        setminLength(false)
      }

      if (password.match(/.*(?=.*\d)(?=.*[A-Z])(?=.*[a-z]).*/)) {
        setMinNumberAlpha(true)
        setPasswordStrength((v) => v + 1)
      } else {
        setMinNumberAlpha(false)
      }
      const passwordInLower = password.toLowerCase()
      if (
        password.length > 0 &&
        !(
          (email &&
            (passwordInLower.includes(email.toLowerCase()) ||
              passwordInLower.includes(email.toLowerCase().split('@')[0]))) ||
          (phone &&
            (passwordInLower.includes(phone) ||
              passwordInLower.includes(phone.substring(-10)))) ||
          (name &&
            (passwordInLower.includes(name.toLowerCase()) ||
              name
                .toLowerCase()
                .split(' ')
                .some(
                  (subName) =>
                    subName.length > 1 && passwordInLower.includes(subName)
                )))
        )
      ) {
        setPassInclude(true)
        setPasswordStrength((v) => v + 1)
      } else {
        setPassInclude(false)
      }

      if (password.match(/^(?=.*[~!@#$?])(?!.*[^a-zA-Z0-9~!@#$?]).{1,}$/)) {
        setSpecialChar(true)
        setPasswordStrength((v) => v + 1)
      } else {
        setSpecialChar(false)
      }
    }
  }, [password, email, name, phone])

  return (
    <Container>
      <ProgressBar>
        <span>Password strength</span>
        <LinearProgress
          style={{
            backgroundColor: `${
              passwordStrength >= 4
                ? theme.colors.success
                : passwordStrength >= 3
                ? theme.colors.warning
                : passwordStrength >= 1
                ? theme.colors.danger
                : theme.colors.white
            }`,
          }}
        />
        <LinearProgress
          style={{
            backgroundColor: `${
              passwordStrength >= 4
                ? theme.colors.success
                : passwordStrength >= 3
                ? theme.colors.warning
                : passwordStrength >= 2
                ? theme.colors.danger
                : theme.colors.white
            }`,
          }}
        />
        <LinearProgress
          style={{
            backgroundColor: `${
              passwordStrength >= 4
                ? theme.colors.success
                : passwordStrength >= 3
                ? theme.colors.warning
                : theme.colors.white
            }`,
          }}
        />
        <LinearProgress
          style={{
            backgroundColor: `${
              passwordStrength >= 4 ? theme.colors.success : theme.colors.white
            }`,
          }}
        />
      </ProgressBar>
      <ul>
        <li>
          {minlength === true ? (
            <Icon
              color={theme.colors.success}
              fontSize='18px'
              childComponent={<RiCheckFill />}
            />
          ) : (
            <Icon
              color={theme.colors.danger}
              fontSize='18px'
              childComponent={<RiCloseFill />}
            />
          )}{' '}
          <span>Must be at least 8 characters long & without space</span>
        </li>
        <li>
          {minNumberAlpha === true ? (
            <Icon
              color={theme.colors.success}
              fontSize='18px'
              childComponent={<RiCheckFill />}
            />
          ) : (
            <Icon
              color={theme.colors.danger}
              fontSize='18px'
              childComponent={<RiCloseFill />}
            />
          )}{' '}
          <span>
            Must contain at least 1 number, 2 alphabets - 1 in lower case and
            another in upper case
          </span>
        </li>
        <li>
          {passInclude === true ? (
            <Icon
              color={theme.colors.success}
              fontSize='18px'
              childComponent={<RiCheckFill />}
            />
          ) : (
            <Icon
              color={theme.colors.danger}
              fontSize='18px'
              childComponent={<RiCloseFill />}
            />
          )}{' '}
          <span>Must not contain your name, phone number or email</span>
        </li>
        <li>
          {specialChar === true ? (
            <Icon
              color={theme.colors.success}
              fontSize='18px'
              childComponent={<RiCheckFill />}
            />
          ) : (
            <Icon
              color={theme.colors.danger}
              fontSize='18px'
              childComponent={<RiCloseFill />}
            />
          )}{' '}
          <span>
            Must include atleast 1 special character.
            <br />
            Only the following special characters are allowed (~,!,@,#,$,?).
          </span>
        </li>
      </ul>
    </Container>
  )
}

export default PasswordStrength
