import theme from './theme'
import { createTheme } from '@mui/material/styles'

declare module '@mui/material/styles' {
  interface Palette {
    contrastPrimary: Palette['primary']
  }

  interface PaletteOptions {
    contrastPrimary?: PaletteOptions['primary']
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    contrastPrimary: true
  }
}

export default createTheme({
  palette: {
    primary: {
      main: theme.colors.primary,
    },
    secondary: {
      main: theme.colors.secondary,
    },
    warning: {
      main: theme.colors.warning,
    },
    info: {
      main: theme.colors.grey,
    },
    contrastPrimary: {
      main: theme.colors.contrastPrimary,
      contrastText: theme.colors.primary,
    },
  },
})
