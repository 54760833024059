import { AiFillInfoCircle } from 'react-icons/ai'
import { Link } from 'react-router-dom'
import RouteConstants from '../../../../constants/RouteConstants'
import styled from '@emotion/styled'
import theme from '../../../../global/theme'

const ContentBox = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  font-size: 24px;
  color: ${theme.colors.textLight};

  p {
    color: ${theme.colors.textLight};
    font-size: 12px;
    padding-right: 10px;
  }
`

const CompleteYourProfileNotification: React.FC = () => {
  return (
    <ContentBox>
      <AiFillInfoCircle style={{ paddingRight: '5px' }} />
      <p>
        <span
          style={{
            fontWeight: 'bold',
            paddingRight: '5px',
          }}
        >
          <Link
            to={RouteConstants.PROFILE + '/myprofile/profile'}
            style={{
              color: `${theme.colors.textLight}`,

              cursor: 'pointer',
            }}
          >
            Complete your profile now
          </Link>
        </span>
        to get better, more customized quotes!
      </p>
    </ContentBox>
  )
}

export default CompleteYourProfileNotification
