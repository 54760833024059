import { Outlet, useLocation, useNavigate } from 'react-router-dom'

import AddSpouse from './AddSpouse.component'
import { AiOutlineMedicineBox } from 'react-icons/ai'
import { CgProfile } from 'react-icons/cg'
import { FiMenu } from 'react-icons/fi'
import { GiHeartPlus } from 'react-icons/gi'
import { IconButton } from '@mui/material'
import { ImFileText2 } from 'react-icons/im'
import LeftNavbar from './LeftNavbar.component'
import ProviderIcon from '../common/Icon/ProviderIcon.component'
import React from 'react'
import RouteConstants from '../../constants/RouteConstants'
import SecureComponent from '../../pages/common/SecureComponent'
import customerStore from '../../datastore/CustomerStore'
import { observer } from 'mobx-react'
import styled from '@emotion/styled'
import theme from '../../global/theme'
import { useCurrentSubrouteContext } from '../../pages/ProfilePage/ProfilePage'
import { withRouter } from '../common/WithRouter/WithRouter.component'

interface SpouseSectionContainerProps {
  display?: 'block' | 'none'
  location?: {
    state: {
      from: {
        pathname: string
      }
    }
  }
}

interface SpouseSectionProps extends SpouseSectionContainerProps {}

const Container = styled.div<SpouseSectionContainerProps>`
  display: ${(props) => props.display};
  margin: 0px 120px 0px 240px;
  padding: 50px 20px;

  @media screen and (max-width: 1300px) {
    margin: 0px 60px 0px 60px;
  }

  @media screen and (max-width: 1000px) {
    margin: 0px 20px 0px 20px;
  }
`

const ContentContainer = styled.div`
  display: flex;
`

const SectionHeading = styled.h2`
  display: flex;
  align-items: center;
  padding-bottom: 20px;
  font-size: 24px;
  color: ${theme.colors.textDark};
  span {
    display: none;
    @media screen and (max-width: 900px) {
      display: inline;
    }
  }
`

const SpouseSection: React.FC<SpouseSectionProps> = (
  props: SpouseSectionProps
) => {
  const { setCurrentSubRoute, currentSubRoute } = useCurrentSubrouteContext()
  const [activeNav, setActiveNav] = React.useState(currentSubRoute)
  const [showLeftNav, setShowLeftNav] = React.useState(false)

  const navigate = useNavigate()
  const location = useLocation().pathname

  const navbarProps = {
    navLinks: [
      {
        label: 'Profile',
        icon: <CgProfile />,
        link: 'profile',
      },

      {
        label: 'Health',
        icon: <GiHeartPlus />,
        link: 'health',
      },
      {
        label: 'Prescriptions',
        icon: <ImFileText2 />,
        link: 'prescriptions',
      },
      {
        label: 'Pharmacy',
        icon: <AiOutlineMedicineBox />,
        link: 'pharmacy',
      },
      {
        label: 'Physicians',
        link: 'doctors',
        icon: (
          <ProviderIcon
            color={activeNav === 'doctors' ? '#FFFFFF' : undefined}
          ></ProviderIcon>
        ),
      },
    ],
  }

  const activeNavHandler = (link: string) => {
    setShowLeftNav(!showLeftNav)
    setActiveNav(link)
    setCurrentSubRoute(link)
    navigate(link, {
      state: {
        from:
          (props.location &&
            props.location.state &&
            props.location.state.from) ||
          RouteConstants.DASHBOARD,
      },
    })
  }

  const showLeftNavHandler = () => {
    setShowLeftNav(!showLeftNav)
  }

  React.useEffect(() => {
    if (currentSubRoute !== location.split('/')[3]) {
      setActiveNav(location.split('/')[3])
      setCurrentSubRoute(location.split('/')[3])
    }
  }, [currentSubRoute, location, setCurrentSubRoute])

  React.useEffect(() => {
    window.scroll({ top: 0, behavior: 'smooth' })
  }, [])

  return (
    <Container display={props.display}>
      {customerStore.spouseOptIn ? (
        <ContentContainer>
          <LeftNavbar
            {...navbarProps}
            activeNav={activeNav}
            onClick={activeNavHandler}
            showLeftNavHandler={showLeftNavHandler}
            showLeftNav={showLeftNav}
            isSpouse={true}
          />
          <div
            style={{
              flex: 1,
            }}
          >
            <SectionHeading>
              <span>
                <IconButton onClick={() => setShowLeftNav(!showLeftNav)}>
                  <FiMenu style={{ color: `${theme.colors.primary}` }} />
                </IconButton>
              </span>
              {
                navbarProps.navLinks.find(
                  (iterator) => iterator.link === activeNav
                )?.label
              }
            </SectionHeading>
            <Outlet />
          </div>
        </ContentContainer>
      ) : (
        <AddSpouse />
      )}
    </Container>
  )
}

export default SecureComponent(withRouter(observer(SpouseSection)))
