import React, { useState } from 'react'
import {
  capitalizeFirstCharacter,
  convertCamelCaseToString,
  removeSpaces,
  toSentenceCase,
} from '../../utils/StringUtils'

import AddDrugConfirmationPopup from '../DashboardPage/Modals/AddDrugsConfirmation'
import BouncingDotsLoader from '../common/Loading/BouncingDotsLoader'
import Button from '../common/Button/Button.component'
import Card from '../common/Card/Card.component'
import InputField from '../common/InputField/InputField.component'
import ModalComponent from '../common/Modal/Modal.component'
import { PrescriptionDrugFrequencyEnum } from '../../enums/PrescriptionDrugFrequencyEnum'
import RadioGroup from '../common/RadioGroup/RadioGroup.component'
import Select from '../common/Select/Select.component'
import { SelectChangeEvent } from '@mui/material'
import SkeletonCard from '../DashboardPage/SkeletonCard'
import { SnackbarTypes } from '../../enums/SnackbarTypesEnum'
import StringConstants from '../../constants/StringConstants'
import ValidationUtils from '../../utils/validation/ValidationUtils'
import customerStore from '../../datastore/CustomerStore'
import { isEmpty, isUndefined } from 'lodash'
import { observer } from 'mobx-react'
import prescriptionStore from '../../datastore/PrescriptionStore'
import snackbarStore from '../../datastore/SnackbarStore'
import styled from '@emotion/styled'
import theme from '../../global/theme'

interface DrugDataProps {
  id: string
  name: string
  currentDosage: {
    id: number
    name: string
    qty: number
    strength: string
    strengthUOM: string
    form: string
    alt: boolean
    packages: []
    proxyNdc: string
    commonDosage: boolean
    frequency: number
  }
  isSpouse: boolean
}

interface PrescriptionDrugCardInfoStepsProps {
  data: {
    title: string
    id: string
    dosage: string[]
    genericOptions: string[]
    currentDosage: {
      id: number
      name: string
      qty: number
      strength: string
      strengthUOM: string
      form: string
      alt: boolean
      packages: []
      proxyNdc: string
      commonDosage: boolean
      frequency: number
    }
    frequencyOptions: string[]
    isEditing: boolean
    isSpouse: boolean
    availableDosages?: {
      id: number
      name: string
      qty: number
      strength: string
      strengthUOM: string
      form: string
      alt: boolean
      packages: []
      proxyNdc: string
      commonDosage: boolean
      frequency: number
    }[]
    dosageNamesWithUsage: {
      name: string
      commonDosage: boolean
    }[]
  }
  handleAddDrugs: any
  handleEditDrugs: any
  handleCancel?: () => void
  buttonLoading?: boolean
  addDrugLoading?: boolean
  setpopUpModal?: (data: boolean) => void
  genericDrug?: any
}

interface ErrorStates {
  drugDosageError: boolean
  quantityError: boolean
  quantityHelperText: string
  frequencyError: boolean
  frequencyHelperText: string
}

const Container = styled.div``

const Header = styled.div`
  padding-bottom: 20px;
  max-width: 80%;
`

const Body = styled.div`
  background-color: #f2f7fe;
  padding: 10px 15px;
  font-weight: 500;
`
const RadioButtonWrapper = styled.div`
  width: 100%;
  padding: 10px 0px;
`

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
  gap: 10px;
`

const ChecklistWrapper = styled.div`
  display: flex;
  padding: 10px 0px;
`
const DisableText = styled.div`
  font-size: 14px;
  margin-left: 5px;
  color: ${theme.colors.secondary};
`

const PrescriptionDrugCardInfoSteps: React.FC<
  PrescriptionDrugCardInfoStepsProps
> = ({
  data,
  handleAddDrugs,
  handleCancel,
  handleEditDrugs,
  buttonLoading = false,
  addDrugLoading,
  setpopUpModal,
  genericDrug,
}) => {
    const [dose, setDose] = React.useState(true)
    const [close, setClose] = React.useState(false)
    const [drugDosage, setDrugDosage] = React.useState('')
    const [quantity, setQuantity] = React.useState<number>()
    const [frequency, setFrequency] = React.useState<number>()
    const [isEditing, setIsEditing] = React.useState(
      data ? data.isEditing : false
    )
    const [selectedDrug, setSelectedDrug] = React.useState<string>()
    const [frequencyOptions, setFrequencyOptions] = React.useState<any>()
    const isButtonDisabled =
      customerStore.isMAQuotesProcessing ||
      customerStore.isMSQuotesProcessing ||
      customerStore.isMPartDQuotesProcessing
    const [state, setState] = React.useState({
      drugDosageError: false,
      quantityError: false,
      quantityHelperText: '',
      frequencyError: false,
      frequencyHelperText: '',
    })
    const [openAddDrugConfirmationPopup, setOpenAddDrugConfirmationPopup] =
      useState<boolean>(false)
    const [drug, setDrug] = useState<string>('')
    React.useEffect(() => {
      const tempDrug = `${drugDosage.includes('_common_') ? drugDosage.split('_common_')[0] : drugDosage} ${quantity} ${frequency === 30
        ? 'Every Month'
        : frequency === 60
          ? 'Every 2 Months'
          : frequency === 90
            ? 'Every 3 Months'
            : frequency === 180 ? 'Every 6 Months' : frequency === 360 ? 'Every 12 Months' : ''
        }`
      setDrug(tempDrug)
    }, [drugDosage, frequency, quantity])

    const togglePlanYearFilterPopupOpen = () => {
      setOpenAddDrugConfirmationPopup(!openAddDrugConfirmationPopup)
    }

    React.useEffect(() => {

      if (data) {
        setDrugDosage(
          capitalizeFirstCharacter(
            data.currentDosage?.name.toLowerCase() as string
          )
        )
        setQuantity(data.currentDosage?.qty || 1)
        setFrequency(
          data.currentDosage?.frequency ? data.currentDosage.frequency : parseInt(data.frequencyOptions[0]) || 30
        )
        setFrequencyOptions(data.currentDosage?.frequency ? convertCamelCaseToString(Object.keys(PrescriptionDrugFrequencyEnum).find(
          key => PrescriptionDrugFrequencyEnum[key as keyof typeof PrescriptionDrugFrequencyEnum] === data.currentDosage?.frequency.toString()
        ) || '') : convertCamelCaseToString(Object.keys(PrescriptionDrugFrequencyEnum)[0]))

        setIsEditing(data.isEditing)
      }
    }, [data])

    const handleDrugDosageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      let drugDosageError = isEmpty(e.target.value)
      setDrugDosage(e.target.value)
      setState({
        ...state,
        drugDosageError,
      })
    }

    const validateQuantity = (quantity: string) => {
      const quantityError = !ValidationUtils.validateDrugQuantity(quantity)
      setState({
        ...state,
        quantityError,
        quantityHelperText: isEmpty(quantity)
          ? StringConstants.UI_ERROR_MSG_FOR_INVALID_DRUG_QUANTITY
          : ValidationUtils.checkDrugQuantity(quantity)
            ? StringConstants.UI_ERROR_MSG_FOR_INVALID_DRUG_QUANTITY
            : quantityError
              ? StringConstants.UI_ERROR_MSG_FOR_DRUG_QUANTITY_MORE_THAN_LIMIT
              : '',
      })
    }
    const handleQuantityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (+e.target.value >= 0) {
        validateQuantity(e.target.value)
        setQuantity(parseInt(e.target.value))
      }
    }

    const validateFrequency = (frequency: string) => {
      const frequencyError = isEmpty(frequency)
      setState({
        ...state,
        frequencyError: frequencyError,
        frequencyHelperText: frequencyError
          ? StringConstants.UI_ERROR_MSG_FOR_EMPTY_DRUG_FREQUENCY
          : '',
      })
    }

    const handleFrequencyChange = (e: SelectChangeEvent<string>) => {
      setFrequencyOptions(convertCamelCaseToString(e.target.value))
      const frequencyInNumber = PrescriptionDrugFrequencyEnum[removeSpaces(e.target.value) as keyof typeof PrescriptionDrugFrequencyEnum]
      validateFrequency(frequencyInNumber)
      setFrequency(parseInt(frequencyInNumber))
    }

    const handleAdd = () => {
      // setAddDrugLoading && setAddDrugLoading(true)
      let hasError = false
      let errorState: Partial<ErrorStates> = {}
      if (isEmpty(drugDosage)) {
        hasError = true
        errorState.drugDosageError = true
      }
      if (isUndefined(quantity) || quantity === 0) {
        hasError = true
        errorState.quantityError = true
        errorState.quantityHelperText =
          StringConstants.UI_ERROR_MSG_FOR_INVALID_DRUG_QUANTITY
      }
      if (isUndefined(frequency)) {
        hasError = true
        errorState.frequencyError = true
        errorState.frequencyHelperText =
          StringConstants.UI_ERROR_MSG_FOR_EMPTY_DRUG_FREQUENCY
      }
      if (hasError) setState({ ...state, ...errorState })
      else if (isEditing) {
        if (
          !errorState.drugDosageError &&
          drugDosage.toLowerCase() === data.currentDosage?.name.toLowerCase() &&
          !errorState.quantityError &&
          quantity === data.currentDosage.qty &&
          !errorState.frequencyError &&
          frequency === data.currentDosage.frequency
        ) {
          hasError = true
          snackbarStore.set({
            snackbarMessage: StringConstants.SNACKBAR_NO_DATA_TO_SAVE_MSG,
            snackbarOpen: true,
            snackbarType: SnackbarTypes.WARNING,
          })
        }
      }
      if (
        !(
          hasError ||
          state.drugDosageError ||
          state.quantityError ||
          state.frequencyError
        )
      ) {
        if (isEditing) {
          let drugData: Partial<DrugDataProps> = {}
          drugData.id = data.id
          drugData.isSpouse = data.isSpouse
          drugData.name = data.title

          if (drugDosage !== data.currentDosage?.name) {
            let newDosage =
              (data.availableDosages &&
                data.availableDosages.filter((item) => {
                  return toSentenceCase(item.name) === drugDosage
                    ? item
                    : undefined
                })) ||
              []
            if (newDosage.length > 0) drugData.currentDosage = newDosage[0]
          }
          if (quantity !== data.currentDosage.qty && drugData.currentDosage) {
            drugData.currentDosage.qty = quantity || 1
          }
          if (frequency !== data.currentDosage.frequency && drugData.currentDosage) {
            drugData.currentDosage.frequency = frequency || 30
          }
          handleEditDrugs(drugData)
          setpopUpModal && setpopUpModal(false)
          setDose(true)
        } else {
          let modifiedDrugDosage = drugDosage.includes('_common_') ? drugDosage.split('_common_')[0] : drugDosage
          let drugData: DrugDataProps = {
            id: data.id,
            name: data.title,
            isSpouse: false,
            currentDosage: {
              id: 0, name: '', qty: 0, strength: '', strengthUOM: '', form: '', alt: false, packages: [], proxyNdc: '', commonDosage: false
              , frequency: parseInt(PrescriptionDrugFrequencyEnum.EveryMonth)
            },
          }
          drugData.name = data.title
          drugData.isSpouse = data.isSpouse

          drugData.currentDosage = prescriptionStore.customerDosage.find((x) => {
            return toSentenceCase(x.name) === modifiedDrugDosage ? x.name : undefined
          })!
          drugData.currentDosage.qty = quantity ? quantity : 1
          drugData.currentDosage.frequency = frequency ? frequency : 30
          handleAddDrugs(drugData)
          setpopUpModal && setpopUpModal(false)
          setDose(true)
        }
      }
      // setAddDrugLoading && setAddDrugLoading(false)
    }

    const handleDrugAddition = () => {
      if (isEmpty(drugDosage)) {
        setState({ ...state, drugDosageError: true })
      }
      else
        togglePlanYearFilterPopupOpen()
    }

    const handleOnRejectDrugAddition = () => {
      togglePlanYearFilterPopupOpen()
    }

    const handleOnConfirmDrugAddition = () => {
      handleAdd()
      togglePlanYearFilterPopupOpen()
    }

    const handleRadioChange = (e: any) => {
      const words = (e.target.value).trim().split(" ")
      setSelectedDrug(words.slice(2).join(' '))
      setDrugDosage('')
    };

    const getDosageValues = () => {
      let dosageValues = []
  
      if (isEmpty(genericDrug)) {
        dosageValues = [...data.dosage].map((dosage) => toSentenceCase(dosage))
      }
      else if (selectedDrug !== genericDrug.drugName) {
        let selectedDosages = data?.dosageNamesWithUsage?.filter(d =>
          genericDrug.genericDrugDosage.some((g: any) =>
            d.name.trim().split(" ")[0].toLowerCase() !== g.name.trim().split(" ")[0].toLowerCase()))
  
        dosageValues = selectedDosages?.map(dosage => toSentenceCase(dosage.commonDosage ? dosage.name + '_common_' : dosage.name))
      }
      else {
        let selectedDosages = data?.dosageNamesWithUsage?.filter(d => genericDrug.genericDrugDosage.some((g: any) => g.name === d.name))
        dosageValues = selectedDosages?.map(dosage => toSentenceCase(dosage.commonDosage ? dosage.name + '_common_' : dosage.name))
      }
      
      return dosageValues
    }

    return addDrugLoading ? (
      <BouncingDotsLoader />
    ) : (
      <Card borderWidth='0px solid'>
        <Container>
          {openAddDrugConfirmationPopup && (
            <ModalComponent
              setOpen={handleOnRejectDrugAddition}
              hideCloseButton={true}
            >
              <AddDrugConfirmationPopup
                onConfirm={handleOnConfirmDrugAddition}
                onReject={handleOnRejectDrugAddition}
                drug={drug}
                edit={isEditing}

              />
            </ModalComponent>
          )}
          {!data.title && <SkeletonCard />}
          {data.title && (
            <>
              <Header>
                <h2>{capitalizeFirstCharacter(data.title)}</h2>
                <p>Brand Drug Name</p>
              </Header>
              {!isEmpty(genericDrug) && (

                <Header>
                  <h4>Generic Available</h4>
                  <p>{data.title} has a generic option called {genericDrug.drugName}</p>
                  <RadioButtonWrapper>
                    <RadioGroup
                      radioButtons={[
                        `Keep ${data.title}`,
                        `Switch to ${genericDrug.drugName}`,
                      ]}
                      row={false}
                      width='100%'
                      onChange={handleRadioChange}
                      value={`${isEmpty(selectedDrug) ? `Keep ${data.title}` : `Switch to ${selectedDrug}`}`}
                      radioGroupName={`Selected drug's Generic Options - Prescriptions`}
                    />
                  </RadioButtonWrapper>
                </Header>
              )
              }
              {!dose && (
                <>
                  <Body>
                    <p>{data.title} has following Generic options:</p>
                    {data.genericOptions.map((item, key) => (
                      <p key={key} style={{ fontWeight: '700' }}>
                        {item}
                      </p>
                    ))}
                  </Body>
                  <RadioButtonWrapper>
                    <RadioGroup
                      radioButtons={[
                        `Keep ${data.title}`,
                        `Switch to ${data.genericOptions[0]}`,
                      ]}
                      row={false}
                      width='100%'
                      onChange={(e) => e.target.value}
                      radioGroupName={`Selected drug's Generic Options - Prescriptions`}
                    />
                  </RadioButtonWrapper>
                  <ButtonWrapper>
                    <Button
                      variant='contained'
                      width='100%'
                      bold={false}
                      onClick={() => {
                        setDose(true)
                      }}
                    >
                      Continue
                    </Button>
                    <Button
                      width='100%'
                      bold={false}
                      onClick={() => {
                        setClose(true)
                        if (handleCancel) {
                          handleCancel()
                        }
                      }}
                    >
                      Cancel
                    </Button>
                  </ButtonWrapper>
                </>
              )}
              {dose && (
                <>
                  <RadioButtonWrapper>
                    <RadioGroup
                      formLabel='Select dose'
                      radioButtons={getDosageValues()}
                      width='100%'
                      onChange={handleDrugDosageChange}
                      value={drugDosage}
                      error={state.drugDosageError}
                      showToolTipForDosage={true}
                      valueToBeRemoved={'_common_'}
                    />
                  </RadioButtonWrapper>
                  <ChecklistWrapper>
                    <InputField
                      error={state.quantityError}
                      helperText={state.quantityHelperText}
                      label='Add Quantity'
                      width='43%'
                      value={quantity}
                      onChange={handleQuantityChange}
                      type='number'
                    />
                    <Select
                      error={state.frequencyError}
                      helperText={state.frequencyHelperText}
                      height='40px'
                      formLabel='Select Frequency'
                      options={Object.keys(PrescriptionDrugFrequencyEnum).map(key => convertCamelCaseToString(key))}
                      margin='8px 10px'
                      value={frequencyOptions}
                      onChange={handleFrequencyChange}
                    />
                  </ChecklistWrapper>
                  <ButtonWrapper>
                    {isButtonDisabled && (
                      <DisableText>
                        {StringConstants.DISABLE_BUTTON_MSG}
                      </DisableText>
                    )}
                    <Button
                      width='fit-content'
                      bold={false}
                      onClick={() => {
                        setClose(true)
                        if (handleCancel) {
                          handleCancel()
                        }
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant='contained'
                      width='fit-content'
                      bold={false}
                      onClick={handleDrugAddition}
                      loading={buttonLoading}
                      disabled={
                        customerStore.isMAQuotesProcessing ||
                        customerStore.isMSQuotesProcessing ||
                        customerStore.isMPartDQuotesProcessing
                      }
                    >
                      {isEditing ? 'Save Changes' : 'Add Drug'}
                    </Button>
                  </ButtonWrapper>
                </>
              )}{' '}
            </>
          )}
        </Container>
      </Card>
    )
  }

export default observer(PrescriptionDrugCardInfoSteps)
