import styled from '@emotion/styled'
import React from 'react'
import theme from '../../global/theme'
import { BsChevronDown, BsChevronUp } from 'react-icons/bs'

interface CollapsibleContainerProps {
  header: string
  className?: string
  isCollapsed?: boolean
}

const Container = styled.div`
  border: 1px solid ${theme.colors.borderColor};
  margin-bottom: 10px;
`

const Header = styled.div`
  background-color: ${theme.colors.primary};
  padding: 10px 40px;
  color: ${theme.colors.textLight};
  font-weight: 700;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  span {
    padding-right: 10px;
  }

  @media screen and (max-width: 1200px) {
    padding: 10px 10px;
  }
`

const ContentWrapper = styled.div<{ collapsed: boolean }>`
  display: ${({ collapsed }) => (collapsed ? 'none' : 'block')} !important;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`
const ContentMainWrapper = styled.div`
  overflow: hidden;
  position: relative;
`

const CollapsibleContainer: React.FC<CollapsibleContainerProps> = ({
  header,
  children,
  className,
  isCollapsed = true
}) => {
  const [collapsed, setCollapsed] = React.useState(isCollapsed)
  return (
    <Container>
      <Header
        onClick={() => {
          setCollapsed(!collapsed)
        }}
      >
        <span>{header}</span>
        {collapsed ? <BsChevronDown /> : <BsChevronUp />}
      </Header>
      <ContentMainWrapper>
        <ContentWrapper collapsed={collapsed} className={className}>
          {children}
        </ContentWrapper>
      </ContentMainWrapper>
    </Container>
  )
}

export default CollapsibleContainer
