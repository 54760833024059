import React from 'react'
import RouteConstants from '../../constants/RouteConstants'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import Stepper from '@mui/material/Stepper'
import styled from '@emotion/styled'
import { useLocation } from 'react-router-dom'

const StepperLabelStyled = styled.span`
  @media screen and (max-width: 470px) {
    display: none;
  }
`

const InfoStepsStepper = () => {
  const stepDetails = [
    {
      order: 0,
      label: 'Prescriptions',
      route: RouteConstants.INFO_STEPS_PRESCRIPTION,
    },
    {
      order: 1,
      label: 'Health',
      route: RouteConstants.INFO_STEPS_HEALTH,
    },
    {
      order: 2,
      label: 'Pharmacy',
      route: RouteConstants.INFO_STEPS_PHARMACY,
    },
    {
      order: 3,
      label: 'Physicians',
      route: RouteConstants.INFO_STEPS_DOCTORS
    },
    {
      order: 4,
      label: 'Current Plan',
      route: RouteConstants.INFO_STEPS_CURRENT_PLAN,
    },
  ]

  const location = useLocation().pathname

  const getActiveStep = () => {
    const currentStep = stepDetails.find(
      (iterator) => iterator.route === location
    )
    return currentStep
  }

  return (
    <>
      <Stepper
        activeStep={getActiveStep()?.order || 0}
        sx={{ width: '100%' }}
        alternativeLabel={window.innerWidth < 600}
        orientation='vertical'
      >
        {stepDetails.map((iterator) => {
          return (
            <Step key={iterator.order}>
              <StepLabel style={{ width: 'max-content' }}>
                <StepperLabelStyled>{iterator.label}</StepperLabelStyled>
              </StepLabel>
            </Step>
          )
        })}
      </Stepper>
    </>
  )
}

export default InfoStepsStepper
