import React, { useState } from 'react'
import { isEmpty, isUndefined } from 'lodash'

import Button from '../../common/Button/Button.component'
import { CheckBoxType } from '../../../interface/common/CheckBoxType'
import CheckboxGroup from '../../common/CheckboxGroup/CheckboxGroup.component'
import { MSPlansRiderType } from '../../../interface/quotes/mSupplementPlans/MSPlansRiderType'
import RadioGroup from '../../common/RadioGroup/RadioGroup.component'
import { RiderTypesEnum } from '../../../enums/RiderTypesEnum'
import RidersPlans from '../../../constants/RidersPlans'
import StringConstants from '../../../constants/StringConstants'
import Tooltip from '../../common/Tooltip/Tooltip.component'
import styled from '@emotion/styled'
import theme from '../../../global/theme'
import userActionStore from '../../../datastore/UserActionStore'

interface ModelProps {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  buttonLoading: boolean
  planId: string
  planName: string
  carrierName: string
  planRiders: MSPlansRiderType[]
  onApply: () => void
  stdPlans: string[]
  appliedRiders: string[]
  isPlanEnrolmentRequested: boolean
}

const Container = styled.div`
  width: 900px; //400px;
  min-height: 150px;
  overflow: hidden;

  h3 {
    color: #272f69;
    padding-bottom: 30px;
  }
  p {
    margin-bottom: 16px;
    font-weight: 600;
    font-size: 14px;
    color: ${theme.colors.textDark};
    font-family: Roboto, Helvetica, Arial, sans-serif;
  }

  div {
    display: flex;
    justify-content: space-between;
  }

  @media screen and (max-width: 1250px) {
    width: 700px;
  }
  @media screen and (max-width: 1050px) {
    width: 600px;
  }
  @media screen and (max-width: 750px) {
    width: 560px;
  }
  @media screen and (max-width: 640px) {
    width: ${88 - (650 - window.innerWidth) / 50}vw;
  }

  @media screen and (min-width: 515px) and (max-width: 750px) {
    div {
      flex-direction: column;
      justify-content: left;
      align-items: left;
    }
  }

  @media screen and (max-width: 380px) {
    Button {
      width: 90%;
    }
  }

  @media screen and (min-width: 381px) and (max-width: 514px) {
    Button {
      width: 90%;
    }
  }

  @media screen and (min-width: 501px) and (max-width: 750px) {
    padding-top: 3px;
    margin: -20px -30px;
  }

  @media screen and (max-width: 500px) {
    padding-top: 3px;
  }

  @media screen and (min-width: 751px) {
    padding-top: 23px;
    padding-bottom: 20px;
    padding-right: 27px;
    padding-left: 31px;
    margin: -40px -50px;
  }
`
const ModalButton = styled.div`
  width: 150px;
  text-decoration: none;

  @media screen and (max-width: 750px) {
    width: 100%;
  }
`

const RidersSelectionModal: React.FC<ModelProps> = ({
  setOpen,
  buttonLoading,
  planId,
  planName,
  carrierName,
  planRiders,
  onApply,
  stdPlans,
  appliedRiders,
  isPlanEnrolmentRequested,
}) => {
  const labels = Object.entries(RiderTypesEnum)
  const [riders, setRiders] = useState<string[]>(appliedRiders)
  const [checkboxState, setCheckboxState] = useState<CheckBoxType[]>(
    planRiders
      .map((item, index) => ({
        id: index,
        checked: isEmpty(riders) ? false : riders.includes(item.name),
        label: labels.find(([key, val]) => key === item.name)?.[1] || item.name,
        value: item.name,
        disabled: isPlanEnrolmentRequested ? true : false,
      }))
      .sort((a, b) => a.label.localeCompare(b.label))
  )
  const [selectedPlan, setSelectedPlan] = useState<string>(
    userActionStore.getStdPlanApplied(planId)
      ? userActionStore.getStdPlanApplied(planId)!
      : stdPlans.length > 0
      ? stdPlans[0]
      : ''
  )

  const setPlanBasedOnRidersSelected = (newCheckboxState: CheckBoxType[]) => {
    const selectedCheckboxes = newCheckboxState
      .filter((item) => item.checked === true)
      .map((selected) => selected.value)
    let correspondingStdPlan = ''
    if (!isEmpty(stdPlans)) {
      if (
        Object.keys(RidersPlans).includes(carrierName) &&
        !isUndefined(RidersPlans[carrierName][planName])
      ) {
        if (!isEmpty(RidersPlans[carrierName][planName])) {
          Object.keys(RidersPlans[carrierName][planName]).forEach(
            (stdPlanName) => {
              if (
                RidersPlans[carrierName][planName][stdPlanName].length ===
                  selectedCheckboxes.length &&
                RidersPlans[carrierName][planName][stdPlanName].every((rider) =>
                  selectedCheckboxes.includes(rider)
                )
              ) {
                correspondingStdPlan = stdPlanName
              }
            }
          )
        }
      } else {
        // Others
        if (!isEmpty(RidersPlans['Others'][planName])) {
          Object.keys(RidersPlans['Others'][planName]).forEach(
            (stdPlanName) => {
              if (
                RidersPlans['Others'][planName][stdPlanName].length ===
                  selectedCheckboxes.length &&
                RidersPlans['Others'][planName][stdPlanName].every((rider) =>
                  selectedCheckboxes.includes(rider)
                )
              ) {
                correspondingStdPlan = stdPlanName
              }
            }
          )
        }
      }
    }
    setCheckboxState(newCheckboxState)
    setRiders(
      newCheckboxState.filter((x) => x.checked === true).map((x) => x.value)
    )
    setSelectedPlan(correspondingStdPlan)
  }

  const setRidersBasedOnPlanSelected = (selectedStdPlan: string) => {
    let newCheckboxState
    if (
      Object.keys(RidersPlans).includes(carrierName) &&
      !isUndefined(RidersPlans[carrierName][planName])
    ) {
      if (!isEmpty(RidersPlans[carrierName][planName])) {
        if (selectedStdPlan !== '') {
          newCheckboxState = checkboxState.map((item) => ({
            ...item,
            checked: RidersPlans[carrierName][planName][
              selectedStdPlan
            ].includes(item.value)
              ? true
              : false,
          }))
        }
      }
    } else {
      // Others
      if (selectedStdPlan !== '') {
        newCheckboxState = checkboxState.map((item) => ({
          ...item,
          checked: RidersPlans['Others'][planName][selectedStdPlan].includes(
            item.value
          )
            ? true
            : false,
        }))
      }
    }
    if (newCheckboxState) {
      setCheckboxState(newCheckboxState)
      setRiders(
        newCheckboxState.filter((x) => x.checked === true).map((x) => x.value)
      )
    }
    setSelectedPlan(selectedStdPlan)
  }

  React.useEffect(() => {
    setPlanBasedOnRidersSelected(checkboxState)
    // setRidersBasedOnPlanSelected(selectedPlan)
  }, [])

  const handleRidersCheckboxChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    // const newCheckboxState = checkboxState.map((item) =>
    //   item.value === e.target.value ? { ...item, checked: !item.checked } : item
    // )
    // setPlanBasedOnRidersSelected(newCheckboxState)
    e.preventDefault()
    return false
  }

  const handlePlansRadioChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRidersBasedOnPlanSelected(e.target.value)
  }

  return (
    <Container>
      <h3 style={{ paddingLeft: '10px' }}>
        {isPlanEnrolmentRequested && 'View '}
        {'Riders Applied'}
      </h3>
      <div
        style={{
          display: 'inline-flex',
          position: 'fixed',
          marginTop: '-51px',
          marginLeft: isPlanEnrolmentRequested ? '192px' : '142px',
        }}
      >
        <Tooltip title={StringConstants.TOOLTIP_MSG_RIDERS} />
      </div>
      <div
        style={{
          marginTop: '-20px',
          marginBottom: '10px',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <h4
          style={
            isPlanEnrolmentRequested
              ? {
                  paddingLeft: '10px',
                  paddingBottom: '10px',
                  color: theme.colors.borderColor,
                }
              : { paddingLeft: '10px', paddingBottom: '10px' }
          }
        >
          {'Select a plan to select the corresponding riders'}
        </h4>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            maxHeight: '200px',
            width: '100%',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '41%',
              marginRight: '1%',
              justifyContent: 'normal',
            }}
          >
            <h4 style={{ paddingLeft: '10px' }}>{'Plans'}</h4>
            <div
              style={{
                marginTop: '10px',
                maxHeight: '200px',
                paddingLeft: '15px',
              }}
            >
              {
                <RadioGroup
                  radioButtons={stdPlans}
                  onChange={handlePlansRadioChange}
                  value={selectedPlan}
                  width='100%'
                  type='unstyled'
                  radioGroupName='Select Std Plans for Rider Selection - Medicare Supplement'
                  extraPaddingBottom='3px'
                  disabledValues={
                    isPlanEnrolmentRequested ? stdPlans : undefined
                  }
                />
              }
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '58%',
              paddingLeft: '2%',
              marginRight: '1%',
              justifyContent: 'initial',
            }}
          >
            <h4>{'Riders'}</h4>
            <div
              style={{
                marginTop: '10px',
                maxHeight: '200px',
                overflowY: 'scroll',
                cursor: 'pointer !important',
              }}
            >
              {
                <CheckboxGroup
                  checkboxState={checkboxState}
                  onChange={handleRidersCheckboxChange}
                  width='100%'
                  type='unstyled'
                  checkboxGroupName='Select Riders - Medicare Supplement'
                  addBottomBorderSeparator={true}
                  readOnly={true}
                />
              }
            </div>
          </div>
        </div>
      </div>
      {!isPlanEnrolmentRequested && (
        <div style={{ paddingLeft: '10px', paddingRight: '10px' }}>
          <ModalButton>
            <Button
              variant='contained'
              bold={false}
              width='100%'
              color='secondary'
              onClick={() => {
                userActionStore.setRidersApplied(planId, riders || [])
                !isEmpty(selectedPlan) &&
                  userActionStore.setStdPlanApplied(planId, selectedPlan)
                onApply()
              }}
              loading={buttonLoading}
              disabled={buttonLoading}
              fontSize='14px'
              description={`Apply selected riders`}
            >
              Refresh quotes
            </Button>
          </ModalButton>

          <ModalButton>
            <Button
              color='info'
              bold={false}
              width='100%'
              onClick={() => setOpen(false)}
              disabled={buttonLoading}
              fontSize='14px'
              description={`Cancel riders selection`}
            >
              Cancel
            </Button>
          </ModalButton>
        </div>
      )}
    </Container>
  )
}

export default RidersSelectionModal
