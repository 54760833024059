import Button from '../../common/Button/Button.component'
import React from 'react'
import styled from '@emotion/styled'
import theme from '../../../global/theme'

interface FinishProps {
  handleFinish: () => void
  handleBack: () => void
}

const Container = styled.div`
  width: 400px;
  h2 {
    color: ${theme.colors.primary};
    padding: 20px 0px;
    font-weight: bold;
  }

  p {
    padding-bottom: 20px;
    span {
      font-weight: bold;
      color: ${theme.colors.primary};
    }
  }

  @media screen and (max-width: 470px) {
    width: 320px;
  }
`

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 30px;

  @media screen and (max-width: 500px) {
    flex-direction: column;
  }
`

const ButtonWrapper = styled.div`
  width: 150px;
  @media screen and (max-width: 500px) {
    width: 100%;
  }
`

const Finish: React.FC<FinishProps> = ({ handleBack, handleFinish }) => {
  React.useEffect(() => {
    window.scroll({ top: 0, behavior: 'smooth' })
  }, [])
  return (
    <Container>
      <h2>Success!</h2>
      <p>
        Your password has been updated successfully. Use this new password to
        login to your account from now on.
      </p>
      <p>
        Your quote has been saved for <span>30 days</span>.
      </p>
      <p>
        You can refresh your quote again after 30 days to get the latest plans.
      </p>
      <ButtonContainer>
        <ButtonWrapper>
          <Button width='100%' color='info' onClick={handleBack}>
            Back
          </Button>
        </ButtonWrapper>
        <ButtonWrapper>
          <Button
            width='100%'
            color='secondary'
            variant='contained'
            onClick={handleFinish}
          >
            Finish
          </Button>
        </ButtonWrapper>
      </ButtonContainer>
    </Container>
  )
}

export default Finish
