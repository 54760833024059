import { groupBy, isNull } from 'lodash'

import { MSPlan } from '../interface/quotes/mSupplementPlans/MSPlan'
import { MSPlanGenericDetails } from '../interface/quotes/mSupplementPlans/MSPlanGenericDetails'
import { MSPlanGenericDisplayDetail } from '../interface/common/MSPlanGenericDisplayDetail'
import SortOrderConstants from '../constants/SortOrderConstants'
import { titleCase } from './StringUtils'

const constructCompletePlanDetail = (msPlan: MSPlan) => {
  let constructedPlanDetail: MSPlanGenericDisplayDetail =
    {} as MSPlanGenericDisplayDetail
  if (msPlan.msPlanGenericDetails) {
    const msPlanGenericDetailsResponse: MSPlanGenericDetails[] =
      msPlan.msPlanGenericDetails

    constructedPlanDetail.planName = msPlan.planName

    const temp = groupBy(msPlanGenericDetailsResponse, 'partType')
    const sorted: string[] = []
    const others: string[] = []
    const otherBenefits: string[] = []
    const order = SortOrderConstants.MSPlanDetailsSortOrder
    Object.keys(temp).forEach((key: string) => {
      if (order.includes(key)) {
        sorted[order.indexOf(key)] = key
      } else if (key === 'Other Benefits') {
        otherBenefits.push(key)
      } else others.push(key)
    })
    const unique = sorted.concat(others.sort()).concat(otherBenefits)
    unique.forEach((key) => {
      const tableRecord: { key: string; value: string }[] = []
      temp[key].forEach((planDetail) => {
        if (planDetail.noOfDays) {
          planDetail.noOfDays?.forEach((day, index) => {
            tableRecord.push({
              key: `${
                !isNull(planDetail.serviceName)
                  ? planDetail.serviceName + ':'
                  : ''
              } ${day}`,
              value: `Plan pays ${
                planDetail.amountPaidByPlan[index]
              } and you pay ${planDetail.amountPaidByCustomer[
                index
              ].toLowerCase()}`,
            })
          })
        } else {
          tableRecord.push({
            key: 'Additional Deductible',
            value: `$${planDetail.additionalDeductible.toString()}`,
          })
        }
      })
      constructedPlanDetail.planDetail
        ? constructedPlanDetail.planDetail.push({
            partType: titleCase(key),
            partTypeDetails: tableRecord,
          })
        : (constructedPlanDetail.planDetail = [
            {
              partType: titleCase(key),
              partTypeDetails: tableRecord,
            },
          ])
    })
  }
  return constructedPlanDetail
}

export { constructCompletePlanDetail }
