import DisclosureStep from './DisclosureStep'
import FallbackStep from './FallbackStep'
import ModalComponent from '../../../common/Modal/Modal.component'
import React from 'react'
import ReviewPrescriptionStep from './ReviewPrescriptionStep'
import styled from '@emotion/styled'

interface MillimanStepperProps {
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>
  setAddPrescriptionState: React.Dispatch<
    React.SetStateAction<{
      AddedDrugs: any[]
      PrescriptionDrug: any[]
      addingDrugs: boolean
    }>
  >
}

const ContentWrapper = styled.div`
  max-height: 700px;
  overflow-y: auto;
  @media screen and (max-width: 750px) {
    max-height: 600px;
  }
`

const MillimanStepper: React.FC<MillimanStepperProps> = ({
  setModalOpen,
  setAddPrescriptionState,
}) => {
  const [step, setStep] = React.useState(1)
  return (
    <ModalComponent setOpen={setModalOpen} hideCloseButton={true} width='850px'>
      <ContentWrapper>
        {step === 1 && (
          <DisclosureStep setStep={setStep} setModalOpen={setModalOpen} />
        )}
        {step === 2 && (
          <ReviewPrescriptionStep
            setAddPrescriptionState={setAddPrescriptionState}
            setModalOpen={setModalOpen}
            setStep={setStep}
          />
        )}
        {step === 3 && <FallbackStep setModalOpen={setModalOpen} />}
      </ContentWrapper>
    </ModalComponent>
  )
}

export default MillimanStepper
