import React, { useState } from 'react'

import { Link } from 'react-router-dom'
import RouteConstants from '../../../constants/RouteConstants'
import StringConstants from '../../../constants/StringConstants'
import Tooltip from '../../common/Tooltip/Tooltip.component'
import styled from '@emotion/styled'
import theme from '../../../global/theme'

interface OptionalMessageProps extends ContainerProps {
  heading?: string
  link?: string
  linkText?: string
  message?: string
  showToolTip?: boolean
}

interface ContainerProps {
  fontSize?: string
  fontcolor?: string
  padding?: string
  margin?: string
}

const HeadingContainer = styled.div<ContainerProps>`
display: flex;
  flex-direction: row;
  margin: ${(props) => props.margin};
  text-decoration: none;
  cursor: default;

  h3{
    font-size: 16px;
      font-weight: bold;
      color: ${theme.colors.textDark};
      padding-bottom: 5px;
  }
  p {
    color: ${(props) => props.fontcolor};
    font-weight: normal;
    font-size: ${(props) => props.fontSize}
    text-decoration: none;
  }
  a {
    font-weight: 600;
    color: ${theme.colors.primary};
  }
`
const Container = styled(Link)<ContainerProps>`
  display: flex;
  flex-direction: column;
  padding: ${(props) => props.padding};
  margin: ${(props) => props.margin};
  text-decoration: none;
  cursor: default;

  span {
    text-decoration: underline;
    font-weight: bold;
    cursor: pointer;
  }

  h3{
    font-size: 16px;
      font-weight: bold;
      color: ${theme.colors.textDark};
      padding-bottom: 5px;
  }
  p {
    color: ${(props) => props.fontcolor};
    font-weight: normal;
    font-size: ${(props) => props.fontSize}
    text-decoration: none;
  }
  a {
    font-weight: 600;
    color: ${theme.colors.primary};
  }
`

const Label = styled.p`
  color: ${theme.colors.primary};
  font-size: 16px;
  font-weight: 700;
  padding-right: 20px;
  margin: 5px 0px;

  span {
    text-decoration: none;
    font-weight: bold;
    cursor: pointer;
  }
`

const OptionalMessage: React.FC<OptionalMessageProps> = ({
  heading = '',
  link,
  linkText = 'change your preference',
  message = '',
  padding = '10px 0px',
  margin = '0px',
  fontcolor = `${theme.colors.textGrey}`,
  fontSize = '14px',
  showToolTip = false,
}) => {
  return (
    <div>
      <HeadingContainer
        fontcolor={fontcolor}
        fontSize={fontSize}
        padding={padding}
        margin={margin}
      >
        <h3>{heading}</h3>
        <p>{message}</p>
        {showToolTip ? (
          <div style={{ marginTop: '-6px' }}>
            <Label>
              <Tooltip title={StringConstants.TOOLTIP_AUTO_APPLY_INFOSTEPS} />
            </Label>
          </div>
        ) : null}
      </HeadingContainer>
      <Container
        fontcolor={fontcolor}
        fontSize={fontSize}
        padding={padding}
        margin={margin}
        to={link || RouteConstants.PROFILE + '/myprofile/profile'}
      >
        <span>{linkText}</span>
      </Container>
    </div>
  )
}

export default OptionalMessage
