import { action, computed, makeAutoObservable, observable } from 'mobx'
import { create, persist } from '../mobx-persist'

type SnackbarType = 'success' | 'error' | 'warning' | 'default'

interface SnackbarStoreI {
  snackbarOpen: boolean
  snackbarMessage: string
  snackbarType: SnackbarType
}

const hydrate = create({
  storage: sessionStorage,
  jsonify: true,
})

class SnackbarStore {
  @persist @observable snackbarOpen = false
  @persist @observable snackbarMessage = ''
  @persist @observable snackbarType = 'default'

  @action set(snackBarObj: SnackbarStoreI) {
    this.snackbarOpen = snackBarObj.snackbarOpen
    this.snackbarMessage = snackBarObj.snackbarMessage
    this.snackbarType = snackBarObj.snackbarType
  }

  @computed get() {
    return {
      snackbarMessage: this.snackbarMessage,
      snackBarOpen: this.snackbarOpen,
      snackbarType: this.snackbarType,
    }
  }

  @action reset = () => {
    this.snackbarOpen = false
    this.snackbarMessage = ''
    this.snackbarType = 'default'
  }

  constructor() {
    makeAutoObservable(this)
    Promise.all([hydrate('snackbarStore', this)])
  }
}

const snackbarStore = new SnackbarStore()

export default snackbarStore
