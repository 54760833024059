import ApiManager from '../../api/ApiManager'
import Button from '../../components/common/Button/Button.component'
import EmailSent from '../../components/ForgotPasswordPage/EmailSent.component'
import EnterEmail from '../../components/ForgotPasswordPage/EnterEmail.component'
import { FiChevronLeft } from 'react-icons/fi'
import { HttpMethods } from '../../enums/HttpMethodsEnum'
import Icon from '../../components/common/Icon/Icon.component'
import LayoutComponent from '../../components/common/Layout/Layout.component'
import React from 'react'
import UrlConstants from '../../constants/UrlConstants'
import ValidationUtils from '../../utils/validation/ValidationUtils'
import { getApiErrorMessage } from '../../utils/StringUtils'
import snackbarStore from '../../datastore/SnackbarStore'
import styled from '@emotion/styled'
import theme from '../../global/theme'
import { useNavigate } from 'react-router-dom'

const Container = styled.div`
  padding: 100px 0px;
  display: flex;
  justify-content: center;
`
const ButtonDiv = styled.div`
  position: absolute;
  top: 108px;
  left: 30px;
  z-index: 500;

  @media (max-width: 750px) {
    position: absolute;
    top: 50;
    left: 0;
  }
`
const CenteredCard = styled.div`
  background-color: ${theme.colors.white};
  border-radius: 4px;
  position: relative;
  padding: 40px 50px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
  @media screen and (max-width: 550px) {
    padding: 30px 20px;
  }
`

const ForgotPasswordPage = () => {
  const [state, setState] = React.useState({
    apiError: false,
    apiErrorText: '',
    loading: false,
  })
  const [usernameEntered, setUsernameEntered] = React.useState(false)
  const [username, setUsername] = React.useState('')
  const [usernameError, setUsernameError] = React.useState(false)

  const isValidUsername = (username: string) => {
    return ValidationUtils.validateUsername(username)
  }

  const handleUsernameEntered = () => {
    if (isValidUsername(username)) {
      setState({
        ...state,
        loading: true,
      })
      ApiManager.makeApiCall(
        UrlConstants.FORGOT_PASSWORD.USECASE,
        HttpMethods.POST_METHOD,
        { username: username }
      )
        .then((response) => {
          if (response.status === 200) {
            setUsernameEntered(true)
          } else throw response
        })
        .catch((err) => {
          setState({
            ...state,
            apiError: true,
            apiErrorText: err.data?.message,
          })
          if (
            err.data?.message ===
            'Error while trying to generate token using customer forgot password flow - Customer not found'
          ) {
            snackbarStore.set({
              snackbarMessage: 'Username not found',
              snackbarOpen: true,
              snackbarType: 'error',
            })
          } else {
            snackbarStore.set({
              snackbarMessage: getApiErrorMessage(
                'reset password. Please try again later'
              ),
              snackbarOpen: true,
              snackbarType: 'error',
            })
          }
        })
        .finally(() => {
          setState({
            ...state,
            loading: false,
          })
        })
    } else {
      setUsernameError(!isValidUsername(username))
    }
  }

  const navigate = useNavigate()

  return (
    <LayoutComponent>
      <Container>
        <ButtonDiv>
          <Button
            variant='text'
            width='100px'
            onClick={() => {
              navigate({
                pathname: '/',
              })
            }}
          >
            <span style={{ display: 'flex', alignItems: 'center' }}>
              <Icon childComponent={<FiChevronLeft />} />
              Back
            </span>
          </Button>
        </ButtonDiv>
        <CenteredCard>
          {usernameEntered ? (
            <EmailSent />
          ) : (
            <EnterEmail
              handleEmailEntered={handleUsernameEntered}
              username={username}
              setUsername={setUsername}
              usernameError={usernameError}
              setUsernameError={setUsernameError}
              isLoading={state.loading}
            />
          )}
        </CenteredCard>
      </Container>
    </LayoutComponent>
  )
}

export default ForgotPasswordPage
