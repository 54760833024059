import {
  MillimanPrescriptionDrug,
  MillimanResponse,
} from '../../../../interface/prescriptionDrug/PrescriptionDrug'
import {
  bulkInsertPrescriptions,
  fetchMillimanPrefilledPrescriptions,
  getDosageForDrugName,
} from '../../../../service/prescriptions'
import { frequencyToText, titleCase } from '../../../../utils/StringUtils'

import Button from '../../../common/Button/Button.component'
import CheckboxGroup from '../../../common/CheckboxGroup/CheckboxGroup.component'
import MillimanEditPrescriptionModal from '../../../InfoStepsPage/StepperComponents/Prescriptions/MillimanEditPrescriptionModal'
import OverlayLoader from '../../../common/OverlayLoader/OverlayLoader'
import { QuotesRefreshContext } from '../../../../context/QuotesRefreshContext'
import React from 'react'
import customerStore from '../../../../datastore/CustomerStore'
import { fetchQuotesPullStatus } from '../../../../utils/quotesPullStatus'
import { getPlanYear } from '../../../../utils/CommonUtils'
import mPartDPlansFilterStore from '../../../../datastore/medicareQuotes/MPartPlanFilterQuotesStore'
import medicareAdvantageFilterQuoteStore from '../../../../datastore/medicareQuotes/MedicareAdvantageFilterQuoteStore'
import styled from '@emotion/styled'
import theme from '../../../../global/theme'

interface ReviewPrescriptionStepProps {
  setStep: React.Dispatch<React.SetStateAction<number>>
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>
  setAddPrescriptionState: React.Dispatch<
    React.SetStateAction<{
      AddedDrugs: any[]
      PrescriptionDrug: any[]
      addingDrugs: boolean
    }>
  >
}

const ReviewPrescriptionStepWrapper = styled.div`
  width: 100%;
  h3 {
    color: ${theme.colors.primary};
  }
  .subheading {
    color: ${theme.colors.textGrey};
    font-size: 14px;
    padding: 5px 0px;
  }
`

const CheckBoxLabel = styled.div`
  font-size: 14px;
  padding: 10px 20px;
  .heading {
    font-weight: bold;
  }

  .edit-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    text-decoration: underline;
    font-weight: bold;
    color: ${theme.colors.primary};
    font-size: 12px;
  }
`

const ReviewPrescriptionStep: React.FC<ReviewPrescriptionStepProps> = ({
  setStep,
  setModalOpen,
  setAddPrescriptionState,
}) => {
  const { setIsQuotesRefreshing } = React.useContext(QuotesRefreshContext)

  const [loading, setLoading] = React.useState(false)
  const [showEditModal, setShowEditModal] = React.useState(false)
  const [currentEditableData, setCurrentEditableData] =
    React.useState<MillimanResponse>()
  const [checkboxState, setCheckboxState] = React.useState<
    {
      id: number
      checked: boolean
      label: JSX.Element
      value: number
    }[]
  >([])
  const [millimanResponse, setMillimanResponse] = React.useState<
    MillimanResponse[]
  >([])

  const handleMillimanResponse = () => {
    setLoading(true)
    window.scrollTo({ top: 0, behavior: 'smooth' })
    const requestBody = millimanResponse
      .filter(
        (response) =>
          checkboxState.find((item) => item.id === response.id)?.checked
      )
      .map((response) => ({
        id: response.id,
        isSpouse: customerStore.forSpouse,
        name: titleCase(response.millimanInfo.drugBrandName),
        currentDosage: response.currentDosage,
        isMDrug: true,
      }))
    const payload = {
      data: requestBody,
      $forSpouse: customerStore.get().forSpouse,
    }

    bulkInsertPrescriptions(payload)
      .then(() => {
        medicareAdvantageFilterQuoteStore.clearStore()
        mPartDPlansFilterStore.clearStore()
        setIsQuotesRefreshing(true)
        fetchQuotesPullStatus(getPlanYear())
        setAddPrescriptionState((prevState) => ({
          ...prevState,
          AddedDrugs: [...prevState.AddedDrugs, ...payload.data],
        }))
        setModalOpen(false)
      })
      .catch((err) => {
        setStep(3)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  React.useEffect(() => {
    setLoading(true)
    let filteredPrescriptions: MillimanPrescriptionDrug[] = []
    fetchMillimanPrefilledPrescriptions()
      .then((res: any) => {
        const prescriptions: MillimanPrescriptionDrug[] = res.data
        const uniquePrescriptions: string[] = []
        filteredPrescriptions = prescriptions.filter((prescription) => {
          if (
            prescription.sfDrugDetails &&
            !uniquePrescriptions.includes(prescription.drugBrandName)
          ) {
            uniquePrescriptions.push(prescription.drugBrandName)
            return true
          }
          return false
        })
        return Promise.all(
          filteredPrescriptions.map((prescription) =>
            getDosageForDrugName({
              $drugName: prescription.sfDrugDetails?.drugDosage.id,
            })
          )
        )
      })
      .then((res) => {
        let sfMappedPrescriptions: MillimanResponse[] = []
        sfMappedPrescriptions = res.map((data: any) => {
          const result: MillimanResponse = {
            id: data.data.data.drugDosage.id,
            sfInfo: data.data.data,
            millimanInfo: filteredPrescriptions.find(
              (prescription) =>
                prescription.sfDrugDetails?.drugDosage.id ===
                data.data.data.drugDosage.id
            ) as MillimanPrescriptionDrug,
            currentDosage: data.data.data.drugDosage.dosages.find(
              (dosage: any) => {
                const millimanInfo = filteredPrescriptions.find(
                  (prescription) =>
                    prescription.sfDrugDetails?.drugDosage.id ===
                    data.data.data.drugDosage.id
                ) as MillimanPrescriptionDrug

                if (
                  dosage.name ===
                  millimanInfo.drugGenericNameAndDosage
                    .toLowerCase()
                    .replace(/\s+/g, '')
                ) {
                  return true
                }
                return false
              }
            ),
          }

          if (!result.currentDosage) {
            result.currentDosage = data.data.data.drugDosage.dosages[0]
          }

          result.currentDosage.qty =
            (result.millimanInfo.quantity / result.millimanInfo.daysSupply) *
            result.currentDosage.frequency

          return result
        })
        setMillimanResponse(sfMappedPrescriptions)
        if (sfMappedPrescriptions.length === 0) {
          setStep(3)
        }
      })
      .catch((err) => {
        setStep(3)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [])

  React.useEffect(() => {
    const checkboxes = millimanResponse.map((prescription) => ({
      id: prescription.id,
      checked:
        checkboxState.find((checkbox) => checkbox.id === prescription.id)
          ?.checked || false,
      label: (
        <CheckBoxLabel>
          <p className='heading'>
            {titleCase(prescription.millimanInfo.drugBrandName)}
          </p>
          <p>
            {prescription.currentDosage.name} - {prescription.currentDosage.qty}{' '}
            {frequencyToText(prescription.currentDosage.frequency)}
          </p>
          <span
            className='edit-btn'
            data-id={prescription.id}
            onClick={(e) => {
              e.preventDefault()
              console.log(prescription)
              setCurrentEditableData(prescription)
              setShowEditModal(true)
            }}
          >
            edit
          </span>
        </CheckBoxLabel>
      ),
      value: prescription.id,
    }))
    setCheckboxState(checkboxes)
  }, [millimanResponse])

  return (
    <ReviewPrescriptionStepWrapper>
      <h3>Review your prescription drugs</h3>
      <p className='subheading'>
        Please select your current prescriptions from the list below.
      </p>
      {loading && <OverlayLoader />}
      <CheckboxGroup
        width='100%'
        checkboxState={checkboxState}
        onChange={(e) => {
          const eventValue = +e.target.value
          const updatedCheckboxState = checkboxState.map((item) =>
            item.value === eventValue
              ? { ...item, checked: !item.checked }
              : item
          )
          setCheckboxState(updatedCheckboxState)
        }}
      />
      <div
        style={{
          padding: '20px 0px 0px 0px',
          display: 'flex',
          justifyContent: 'center',
          flexWrap: 'wrap',
          columnGap: '10px',
        }}
      >
        <Button
          onClick={() => {
            setModalOpen(false)
          }}
        >
          Cancel
        </Button>
        <Button variant='contained' onClick={handleMillimanResponse}>
          Save Prescriptions
        </Button>
      </div>

      {showEditModal && currentEditableData && (
        <MillimanEditPrescriptionModal
          setOpen={setShowEditModal}
          data={currentEditableData}
          setMillimanResponse={setMillimanResponse}
        />
      )}
    </ReviewPrescriptionStepWrapper>
  )
}

export default ReviewPrescriptionStep
