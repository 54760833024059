import { ActionContext, fireEvent } from '../../../../cra'
import React, { useEffect, useRef, useState } from 'react'
import { isEmpty, isNull } from 'lodash'

import ApiManager from '../../../../api/ApiManager'
import BottomAddToCompare from '../../BottomAddToCompare/BottomAddToCompare.component'
import Button from '../../../common/Button/Button.component'
import CallUsNow from '../../Common/Recommendation/CallUsNow.component'
import FilterBox from '../../Common/FilterBox.component.tsx/FilterBox'
import FilterSupplement from './FilterSupplement'
import { HttpMethods } from '../../../../enums/HttpMethodsEnum'
import { MSPlan } from '../../../../interface/quotes/mSupplementPlans/MSPlan'
import { MSPlanRequestFilter } from '../../../../interface/quotes/mSupplementPlans/request/MSPlanRequestFilter'
import { MSPlansDisplayFilters } from '../../../../interface/quotes/mSupplementPlans/MSPlansDisplayFilters'
import { MedicareQuotesType } from '../../../../enums/MedicareQuoteTypeEnum'
import MedicareSupplementCard from './MedicareSupplementCard'
import ModalComponent from '../../../common/Modal/Modal.component'
import NoPlanFoundCard from '../../NoPlanFoundCard'
import { Pagination } from '@mui/material'
import PaginationConstants from '../../../../constants/PaginationConstants'
import { RiFilter2Fill } from 'react-icons/ri'
import SortBy from '../../Common/SortByDropdown.component.tsx/SortBy'
import ThankYouModal from '../../Modals/ThankYouModal'
import { ThreeDots } from 'react-loader-spinner'
import UrlConstants from '../../../../constants/UrlConstants'
import customerStore from '../../../../datastore/CustomerStore'
import { getApiErrorMessage } from '../../../../utils/StringUtils'
import { getContact } from '../../../../utils/getContact'
import { isUndefined } from 'lodash'
import msPlanFiltersStore from '../../../../datastore/medicareQuotes/MSupplementPlanFiltersStore'
import { observer } from 'mobx-react'
import snackbarStore from '../../../../datastore/SnackbarStore'
import styled from '@emotion/styled'
import theme from '../../../../global/theme'
import userActionStore from '../../../../datastore/UserActionStore'

const Container = styled.div`
  display: flex;
  justify-content: center;
  @media screen and (max-width: 950px) {
    justify-content: center;
  }
`
const CardsWrapper = styled.div`
  padding: 20px 20px 150px 20px;
`
const UtilityContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  grid-gap: 20px;
  margin: 30px 20px;
  padding: 10px 0px;
  border-bottom: 1px solid ${theme.colors.borderColor};
  h3 {
    display: none;
    align-items: center;
    padding: 8px 24px;
    border-radius: 4px;
    border: 1px solid ${theme.colors.primary};
    @media screen and (max-width: 950px) {
      cursor: pointer;
      display: flex;
    }
  }
  @media screen and (max-width: 640px) {
    margin: 30px 0px;
  }
`

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 1.25em 0;
`
const ThreeDotsWrapper = styled.div`
  display: flex;
  margin: 20px;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  width: 900px;

  @media screen and (max-width: 1250px) {
    width: 700px;
  }

  @media screen and (max-width: 1050px) {
    width: 600px;
  }

  @media screen and (max-width: 640px) {
    width: 100%;
    margin: 20px 0px;
  }
`

const FilterContainer = styled.div`
  display: block;

  @media screen and (max-width: 600px) {
    display: flex;
    flex-direction: column-reverse;
  }
`

const MedicareSupplement: React.FC<{
  medicareSupplementPlans: MSPlan[]
}> = ({ medicareSupplementPlans }) => {
  const [sortPlanOptions, setSortPlanOptions] = useState<
    {
      title: string
      value: string
    }[]
  >([])
  const [currentSortOption, setCurrentSortOption] = useState<{
    label: string
    index: number
  }>()

  const [thankYouModalActive, setThankYouModalActive] = React.useState(false)

  const paginationCount = Math.ceil(
    isNull(msPlanFiltersStore.getTotalCount())
      ? 0
      : msPlanFiltersStore.getTotalCount()! /
          (msPlanFiltersStore.getMSPlanRequestFilters().paginationDetail
            ?.size as number)
  )

  const { trackCurrentPage } = React.useContext(ActionContext)

  const [showFilter, setShowFilter] = useState(false)
  const filterSupplementRef = useRef<any>(null)
  const handleShowFilter = () => {
    setShowFilter(!showFilter)
  }

  const [clearFilterState, setClearFilterState] = React.useState(false)

  const [medigapChartDetails, setMedigapChartDetails] = React.useState<any>({})

  React.useEffect(() => {
    ApiManager.makeApiCall(
      UrlConstants.GET_MEDIGAP_CHART_DETAILS.USECASE,
      HttpMethods.GET_METHOD
    )
      .then((response) => {
        if (response.status === 200) {
          setMedigapChartDetails(response.data.data)
        } else throw response
      })
      .catch(() => {
        snackbarStore.set({
          snackbarOpen: true,
          snackbarMessage: getApiErrorMessage(
            'retrieve medigap chart details.'
          ),
          snackbarType: 'error',
        })
      })
  }, [])

  useEffect(() => {
    trackCurrentPage('Dashboard - Medicare Supplements')
  }, [trackCurrentPage])

  useEffect(() => {
    if (!isEmpty(msPlanFiltersStore.getMSPlansFilters())) {
      const planOptions = msPlanFiltersStore
        .getMSPlansFilters()
        .sortOptions.map((option) => {
          return { title: option, value: option }
        })

      setSortPlanOptions(planOptions)
      setCurrentSortOption({
        label: msPlanFiltersStore.getSelectedSortOption()!,
        index: planOptions.findIndex((option) => {
          return option.title === msPlanFiltersStore.getSelectedSortOption()!
        }),
      })
    }
  }, [
    JSON.stringify(msPlanFiltersStore.getMSPlansFilters().selectedSortOption),
  ])

  useEffect(() => {
    if (!isEmpty(medicareSupplementPlans)) {
      msPlanFiltersStore.setPlansFetched(true)
      msPlanFiltersStore.setPaginationApplied(false)
      msPlanFiltersStore.setPaginationLoader(false)
    }
    msPlanFiltersStore.setClearFilters(false)
  }, [medicareSupplementPlans])

  const [msPlanFilters, setMSPlanFilter] = useState<MSPlansDisplayFilters>(
    {} as MSPlansDisplayFilters
  )

  useEffect(() => {
    if (!isUndefined(msPlanFiltersStore.getMSPlansFilters())) {
      setMSPlanFilter({
        ...msPlanFiltersStore.getMSPlansFilters(),
      })
    }
  }, [msPlanFiltersStore.getMSPlansFilters()])

  const handleSortChange = (e: any) => {
    const sortOption: string = e.target.innerText
      .split()[0]
      .split(' (High to Low)')[0]
    if (
      msPlanFiltersStore.getMSPlansFilters().selectedSortOption !== sortOption
    ) {
      msPlanFiltersStore.setPrevSortType(
        msPlanFiltersStore.getMSPlansFilters().selectedSortOption
      )
      msPlanFiltersStore.setSelectedSortOption(sortOption)
      msPlanFiltersStore.setSortDetail({
        order: 'ASC',
        type: sortOption,
      })
      msPlanFiltersStore.setFilterApplied(true)
      msPlanFiltersStore.setPaginationDetail({
        page: PaginationConstants.DEFAULT_PAGE,
        size: PaginationConstants.DEFAULT_PAGE_SIZE,
      })
      customerStore.setMSPageNumber(PaginationConstants.DEFAULT_PAGE)
      customerStore.setMSPageSize(PaginationConstants.DEFAULT_PAGE_SIZE)
    }
  }

  const handleOnEnroll = () => {
    setThankYouModalActive(true)
  }

  const handlePaginationChange = () => {
    msPlanFiltersStore.setPaginationLoader(true)
    msPlanFiltersStore.setPaginationDetail({
      page: customerStore.msPageNumber + PaginationConstants.DEFAULT_PAGE,
      size: PaginationConstants.DEFAULT_PAGE_SIZE,
    })
    customerStore.setMSPageSize(
      customerStore.msPageSize + PaginationConstants.DEFAULT_PAGE_SIZE
    )
    customerStore.setMSPageNumber(
      customerStore.msPageNumber + PaginationConstants.DEFAULT_PAGE
    )
    msPlanFiltersStore.setPaginationApplied(true)
  }

  useEffect(() => {
    const closeFilterSupplement = (e: any) => {
      if (
        filterSupplementRef.current !== null &&
        e.composedPath() &&
        !e
          .composedPath()
          .includes(filterSupplementRef.current.lastElementChild) &&
        showFilter === true
      ) {
        setShowFilter(false)
      }
    }
    document.body.addEventListener('click', closeFilterSupplement)
    return () =>
      document.body.removeEventListener('click', closeFilterSupplement)
  }, [])

  const clearButtonState = () => {
    setClearFilterState(!clearFilterState)
  }

  const [selectedFilters, setSelectedFilters] = useState<string[]>([])

  const updateFilters = (unFilter: string) => {
    selectedFilters.splice(selectedFilters.indexOf(unFilter), 1)
    if (msPlanFilters) {
      const checkedPlanTypes = msPlanFilters.planTypes?.map((item) =>
        item.label === unFilter || item.label === unFilter + '(High Deductible)'
          ? { ...item, checked: !item.checked }
          : item
      )

      const checkedCarriers = msPlanFilters.carriers?.map((item) =>
        item.value === unFilter ? { ...item, checked: !item.checked } : item
      )

      const checkedAMBestRatings = msPlanFilters.ambestRatings?.map((item) =>
        item.value === unFilter ? { ...item, checked: !item.checked } : item
      )
      setMSPlanFilter({
        ...msPlanFilters,
        planTypes: checkedPlanTypes,
        carriers: checkedCarriers,
        ambestRatings: checkedAMBestRatings,
      })
      msPlanFiltersStore.setIsFilterChanged(true)
    }
  }

  return !msPlanFiltersStore.isPlanFetched() ? (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <ThreeDots color='#222C69' height={80} width={80} />
    </div>
  ) : (
    <Container>
      {thankYouModalActive && (
        <ModalComponent setOpen={() => setThankYouModalActive(false)}>
          <ThankYouModal setOpen={() => setThankYouModalActive(false)} />
        </ModalComponent>
      )}
      <FilterSupplement
        filterRef={filterSupplementRef}
        showFilter={showFilter}
        handleShowFilter={handleShowFilter}
        clearFilterState={clearFilterState}
        clearButtonState={clearButtonState}
        setSelectedFilters={(Value: string[]) => {
          setSelectedFilters(Value)
        }}
        msPlanFilters={msPlanFilters}
        setMSPlanFilter={setMSPlanFilter}
      />
      <CardsWrapper>
        <FilterContainer>
          <FilterBox
            clearButtonState={clearButtonState}
            selectedFilters={selectedFilters}
            updateFilters={updateFilters}
          />
          <UtilityContainer>
            <h3
              onClick={() => {
                handleShowFilter()
              }}
              style={{ color: `${theme.colors.primary}`, fontWeight: '600' }}
            >
              <RiFilter2Fill /> Filters
            </h3>
            {sortPlanOptions && (
              <SortBy
                options={sortPlanOptions}
                value={currentSortOption}
                onChange={handleSortChange}
              />
            )}
          </UtilityContainer>
        </FilterContainer>

        {msPlanFiltersStore.isFilterApplied() ||
        msPlanFiltersStore.isFiltersCleared() ? (
          <>
            <ThreeDotsWrapper>
              <ThreeDots color='#222C69' height={80} width={80} />
            </ThreeDotsWrapper>
          </>
        ) : (
          <>
            {medicareSupplementPlans.length > 0 ? (
              <>
                {medicareSupplementPlans.map((cardData: MSPlan, index) => (
                  <div key={cardData.id}>
                    <MedicareSupplementCard
                      msPlan={cardData}
                      onEnroll={handleOnEnroll}
                      medigapChartDetails={medigapChartDetails}
                    />
                    {index % 2 !== 0 && (
                      <CallUsNow
                        heading='Not sure which Medicare plan is right for you?'
                        message='Our licensed insurance agents are here to help'
                        phoneNumber={getContact(customerStore.getBrandName())}
                      />
                    )}
                  </div>
                ))}
                <PaginationWrapper>
                  {/* <Pagination
                    count={paginationCount}
                    color='primary'
                    size='small'
                    onChange={(event, page: number) => {
                      fireEvent('changePage', event, {
                        label: page,
                        description: `Medicare Supplement page number changed to ${page}`,
                        value: page,
                      })
                      handlePaginationChange(page)
                    }}
                    page={
                      msPlanFiltersStore.getMSPlanRequestFilters()
                        .paginationDetail?.page
                    }
                  /> */}
                  {msPlanFiltersStore.getTotalCount() !==
                    medicareSupplementPlans.length &&
                    !msPlanFiltersStore.paginationLoader && (
                      <Button
                        color='primary'
                        variant='contained'
                        onClick={handlePaginationChange}
                      >
                        Load More Plans
                      </Button>
                    )}
                  {msPlanFiltersStore.paginationLoader && (
                    <ThreeDotsWrapper>
                      <ThreeDots color='#222C69' height={80} width={80} />
                    </ThreeDotsWrapper>
                  )}
                </PaginationWrapper>
              </>
            ) : (
              <>
                {msPlanFiltersStore.isPlanFetched() &&
                !msPlanFiltersStore.paginationLoader ? (
                  <NoPlanFoundCard />
                ) : (
                  <ThreeDotsWrapper>
                    <ThreeDots color='#222C69' height={80} width={80} />
                  </ThreeDotsWrapper>
                )}
              </>
            )}
          </>
        )}
      </CardsWrapper>
      {userActionStore.comparePlanCards.planType ===
        MedicareQuotesType.MEDICARE_SUPPLEMENT &&
        userActionStore.comparePlanCards.compareCardsData.length !== 0 && (
          <BottomAddToCompare />
        )}
    </Container>
  )
}

export default observer(MedicareSupplement)
