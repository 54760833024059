import { action, makeAutoObservable, observable } from 'mobx'
import { create, persist } from '../mobx-persist'

const hydrate = create({
  storage: sessionStorage,
  jsonify: true,
})

interface PrescriptionDrugRxCUIDetailsDTO {
  id: number
  name: string
  qty: number
  strength: string
  strengthUOM: string
  form: string
  alt: boolean
  packages: []
  proxyNdc: string
  commonDosage: boolean
  frequency: number
}

class PrescriptionStore {
  @persist('list') @observable drugNames: string[] = []
  @persist @observable quantity = 1
  @persist('list')
  @observable
  customerDosage: PrescriptionDrugRxCUIDetailsDTO[] = []

  @action setDrugNameList(drugNameList: string[]) {
    this.drugNames = drugNameList
  }

  @action setCustomerDosage(dosage: PrescriptionDrugRxCUIDetailsDTO[]) {
    this.customerDosage = dosage
  }

  @action reset() {
    this.drugNames = []
    this.quantity = 1
    this.customerDosage = []
  }

  get() {
    return {
      drugNames: this.drugNames,
    }
  }

  constructor() {
    makeAutoObservable(this)
    Promise.all([hydrate('prescriptionStore', this)])
  }
}

const prescriptionStore = new PrescriptionStore()

export default prescriptionStore
