import React, { useEffect } from 'react'

import { CgArrowsV } from 'react-icons/cg'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import { RiArrowDropDownLine } from 'react-icons/ri'
import { fireEvent } from '../../../../cra'
import mPartDPlansFilterStore from '../../../../datastore/medicareQuotes/MPartPlanFilterQuotesStore'
import medicareAdvantageFilterQuoteStore from '../../../../datastore/medicareQuotes/MedicareAdvantageFilterQuoteStore'
import msPlanFiltersStore from '../../../../datastore/medicareQuotes/MSupplementPlanFiltersStore'
import styled from '@emotion/styled'
import theme from '../../../../global/theme'

interface SortByProps {
  value?: { label: string; index: number }
  options: any[]
  onChange: (e: any, item: any) => void
}

interface SortByContainerProps {
  greyOut: boolean
}

const Container = styled.div`
  position: relative;
  border-radius: 4px;
  border: 1px solid ${theme.colors.primary};
  display: flex;

  align-items: center;
  justify-content: center;
  padding: 4px;
  cursor: pointer;
  height: 100%;
`
const VerticalBorder = styled.div`
  height: 16px;
  width: 1px;
  background-color: ${theme.colors.borderColor};
  margin: 0px 12px;
`
const HorizontalBorder = styled.div`
  width: 90%;
  height: 1px;
  background-color: ${theme.colors.borderColor};
  margin: 4px 12px;
`

const Left = styled.div`
  font-weight: 400;
  color: #515151;
  min-width: 54px;
`

const Right = styled.div`
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  min-width: 240px;
  p {
    margin: 0px 12px;
  }

  @media (max-width: 600px) {
    min-width: 100px;
  }
`

const Dropdown = styled.div`
  position: absolute;
  top: 32px;
  left: 0px;
  z-index: 1;
  border-radius: 4px;
  box-shadow: 0px 4px 10px 4px rgba(0, 0, 0, 0.1);
  width: 100%;
  border: 1px solid ${theme.colors.borderColor};
  background-color: white;
  padding: 10px;
`
const DropdownElements = styled.p`
  margin: 4px 0px;
  padding: 5px 8px 5px 8px;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 600;
  color: ${theme.colors.primary};
  display: flex;
  align-items: center;
  cursor: pointer;
  margin: 0px 8px;
`

const SortByContainer = styled.div<SortByContainerProps>`
  opacity: ${({ greyOut }) => (greyOut ? 0.5 : 1)};
  pointer-events: ${({ greyOut }) => (greyOut ? 'none' : 'auto')};
`

const SortBy: React.FC<SortByProps> = ({ options, value, onChange }) => {
  const [dropDownState, setDropDownState] = React.useState(false)
  const [currentValue, setCurrentValue] = React.useState(value?.label)
  const [selectedState, setSelectedState] = React.useState(value?.index)

  useEffect(() => {
    setCurrentValue(value?.label)
    setSelectedState(value?.index)
  }, [value])
  return (
    <ClickAwayListener onClickAway={() => setDropDownState(false)}>
      <SortByContainer
        greyOut={
          msPlanFiltersStore.filterApplied ||
          medicareAdvantageFilterQuoteStore.filterApplied ||
          mPartDPlansFilterStore.filterApplied
        }
      >
        <Container
          onClick={(e) => {
            setDropDownState(!dropDownState)
            fireEvent('click', e, {
              label: 'Sort By',
              value: value?.label,
              description: 'Dashboard - Sort By option',
            })
          }}
        >
          <CgArrowsV style={{ margin: '2px 8px 0px 16px' }} />
          <Left>Sort by</Left>
          <VerticalBorder />
          <Right>
            <p>{currentValue}</p>
            <RiArrowDropDownLine style={{ margin: '2px 4px 0px 24px' }} />
          </Right>
          {dropDownState && (
            <Dropdown>
              {options.map((item, index) => (
                <div key={index + 1}>
                  <DropdownElements
                    key={index + 1}
                    onClick={(e) => {
                      onChange(e, item)
                      setCurrentValue(item.value)
                      setDropDownState(false)
                      setSelectedState(index)
                    }}
                    style={{
                      backgroundColor: `${
                        selectedState === index
                          ? theme.colors.contrastPrimary
                          : ''
                      }`,
                    }}
                  >
                    {item.title}
                  </DropdownElements>
                  <HorizontalBorder />
                </div>
              ))}
            </Dropdown>
          )}
        </Container>
      </SortByContainer>
    </ClickAwayListener>
  )
}

export default SortBy
