import { BsCheckLg, BsXLg } from 'react-icons/bs'

import Button from '../../../common/Button/Button.component'
import CarrierLogo from '../../../common/CarrierLogo'
import { MSPlan } from '../../../../interface/quotes/mSupplementPlans/MSPlan'
import React from 'react'
import { StateAndMSPlanTypesConstants } from '../../../../constants/StateAndMSPlanTypes'
import { observer } from 'mobx-react'
import styled from '@emotion/styled'
import theme from '../../../../global/theme'

interface SupplementPopUpCardProps {
  handleSubmit(): any
  medicareSupplementPlans: MSPlan[]
}

const Container = styled.div`
  width: 900px;
  border: 1px solid ${theme.colors.borderColor};
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  margin: 20px;
  overflow: hidden;

  @media screen and (max-width: 1250px) {
    width: 700px;
  }

  @media screen and (max-width: 1050px) {
    width: 600px;
  }

  @media screen and (max-width: 640px) {
    width: 100%;
    margin: 20px 0px;
  }
`

const Wrapper = styled.div`
  padding: 0px 30px;
  @media screen and (max-width: 530px) {
    padding: 0px 15px;
  }
`

const Header = styled.div`
  padding: 12px 20px;
  border-bottom: 1px solid ${theme.colors.borderColor};
  display: flex;
  justify-content: space-between;
  div {
    display: flex;
    align-items: center;
    img {
      max-width: 80px;
      margin-right: 20px;
    }
    h2 {
      font-size: 16px;
      color: ${theme.colors.textDark};
    }
    span {
      font-size: 12px;
    }
  }

  @media screen and (max-width: 530px) {
    padding: 15px 10px;
    flex-direction: column;
    div {
      img {
        width: 60px;
        margin-right: 10px;
      }
      h2 {
        font-size: 16px;
      }
      span {
        font-size: 12px;
      }
    }
  }
`

const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${theme.colors.borderColor};
  padding: 16px 0px;

  @media screen and (max-width: 530px) {
    padding: 20px 0px;
  }

  @media screen and (max-width: 500px) {
    flex-direction: column;
  }
`

const BenefitsWrapper = styled.div`
  flex: 1.5;
  border-right: 1px solid ${theme.colors.borderColor};
  padding: 0px 30px;
  @media screen and (max-width: 530px) {
    padding: 0px 10px;
  }

  @media screen and (max-width: 500px) {
    border-right: none;
    border-bottom: 1px solid ${theme.colors.borderColor};
    margin-bottom: 20px;
  }
`

const PricingWrapper = styled.div`
  flex: 1;
  padding: 6px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  h2 {
    font-size: 14px;
    color: ${theme.colors.textGrey};
    // padding-bottom: px;
  }
  p {
    font-size: 24px;
    font-weight: bold;
    color: ${theme.colors.primary};
    padding-bottom: 10px;
    :nth-of-type(1) {
      padding: 4px 0px;
    }
  }

  .info {
    font-size: 12px;
    font-weight: 600;
    color: ${theme.colors.textDark};
  }

  div {
    padding: 4px 0px 0px 0px;
    p {
      margin-top: 4px;
      font-size: 14px;
    }
    span {
      font-size: 12px;
      color: ${theme.colors.textGrey};
      font-weight: 600;
    }
  }
  .discounts {
    span {
      margin-right: 6px;
    }
  }

  @media screen and (max-width: 1250px) {
    .discounts {
      :nth-of-type(1) {
        max-width: 100px;
      }
      :nth-of-type(2) {
        max-width: 60px;
        padding-right: 4px;
        padding-top: 16px;
      }
      :nth-of-type(3) {
        padding-top: 16px;
      }
    }
  }
  @media screen and (max-width: 623px) {
    .policyFee {
      :nth-of-type(1) {
        max-width: 60px;
      }
      :nth-of-type(2) {
        max-width: 60px;
        padding-right: 4px;
        padding-top: 16px;
      }
      :nth-of-type(3) {
        padding-top: 16px;
      }
    }
  }
  @media screen and (max-width: 565px) {
    padding: 6px 10px;
  }
  @media screen and (max-width: 545px) {
    padding: 0px 10px;
  }
  @media screen and (max-width: 533px) {
    .discounts {
      :nth-of-type(2),
      :nth-of-type(3) {
        padding-top: 30px;
      }
    }
  }
  @media screen and (max-width: 530px) {
    padding: 6px 10px;
    .discounts {
      :nth-of-type(2),
      :nth-of-type(3) {
        padding-top: 16px;
      }
    }
  }
  @media screen and (max-width: 500px) {
    padding: 0px 10px;

    p {
      padding-bottom: 10px;
    }
    .discounts,
    .policyFee {
      :nth-of-type(1) {
        max-width: initial;
      }
      :nth-of-type(2) {
        max-width: initial;
        padding-right: initial;
        padding-top: initial;
      }
      :nth-of-type(3) {
        padding-top: initial;
      }
    }
  }
`

const BenefitContent = styled.div`
  padding-bottom: 8px;
  h2 {
    font-size: 14px;
    color: ${theme.colors.textGrey};
    padding-bottom: 10px;
  }
  p {
    font-size: 12px;
    color: ${theme.colors.textGrey};
    padding-bottom: 10px;
    display: flex;
  }
  .deductible {
    font-size: 36px;
    font-weight: bold;
    color: ${theme.colors.primary};
  }
  div {
    display: flex;
    flex-wrap: wrap;
    p {
      padding-right: 15px;
      padding-bottom: 3px;
      font-size: 14px;
      color: ${theme.colors.textGrey};
      display: flex;
      align-items: center;
      span {
        margin-right: 2px;
      }
    }
  }
`

const BenefitsRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

const FeaturesRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  margin-top: 1.5rem;
  font-size: 14px;
`

const FeaturesList = styled.ul`
  display: flex;
  flex-direction: column;
  font-size: 14px;
`

const FeaturesListItem = styled.li`
  display: flex;
  align-items: center;
  padding: 2px 0;
  margin-left: 48px;
`

const FeatureName = styled.span`
  margin-left: 8px;
  fontsize: 12px;
`

const SupplementPopUpCard: React.FC<SupplementPopUpCardProps> = ({
  handleSubmit,
  medicareSupplementPlans,
}) => {
  window.onbeforeunload = () => {}

  return (
    <div>
      {medicareSupplementPlans.map((plan) => {
        return (
          <Container style={{ marginLeft: 'auto', marginRight: 'auto' }}>
            <Wrapper>
              <Header>
                <div>
                  <CarrierLogo
                    carrier={plan.msCompanyBase.name}
                    useAltLogo={true}
                  />
                  <h2>{`${plan.msCompanyBase.name} (Plan ${plan.planName})`}</h2>
                </div>
              </Header>
              <ContentWrapper>
                <BenefitsWrapper>
                  <BenefitContent>
                    <BenefitsRow>
                      {plan.planName && (
                        <>
                          <h2
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            Plan
                            <span
                              style={{
                                color: theme.colors.primary,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                marginLeft: '0.25rem',
                              }}
                            >
                              {plan.planName}
                            </span>
                          </h2>
                        </>
                      )}
                      {plan.msCompanyBase &&
                        plan.msCompanyBase.ambestRating && (
                          <>
                            <h2
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                              }}
                            >
                              AM Best Rating -
                              <span
                                style={{
                                  color: theme.colors.primary,
                                  border: `solid 1px ${theme.colors.primary}`,
                                  width: '2rem',
                                  height: '2rem',
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  marginLeft: '0.5rem',
                                }}
                              >
                                {plan.msCompanyBase.ambestRating}
                              </span>
                            </h2>
                          </>
                        )}
                    </BenefitsRow>
                    {/* TODO when BE sends in the Benefits Details */}
                    {StateAndMSPlanTypesConstants.StdPlans.includes(
                      plan.planName
                    ) &&
                      plan.planFeatures &&
                      plan.planFeatures.length > 0 && (
                        <FeaturesRow>
                          <span>Plan Features: </span>
                          <FeaturesList>
                            {plan.planFeatures.includes(
                              'Part B Excess Charges'
                            ) ? (
                              <FeaturesListItem>
                                <BsCheckLg
                                  fontSize='14px'
                                  color={theme.colors.green}
                                />
                                <FeatureName>Part B Excess Charges</FeatureName>
                              </FeaturesListItem>
                            ) : (
                              <FeaturesListItem>
                                <BsXLg
                                  fontSize='14px'
                                  color={theme.colors.secondary}
                                />
                                <FeatureName>Part B Excess Charges</FeatureName>
                              </FeaturesListItem>
                            )}
                            {plan.planFeatures.includes('Snf Copay') ? (
                              <FeaturesListItem>
                                <BsCheckLg
                                  fontSize='14px'
                                  color={theme.colors.green}
                                />
                                <FeatureName>
                                  Skilled Nursing Facility Care
                                </FeatureName>
                              </FeaturesListItem>
                            ) : (
                              <FeaturesListItem>
                                <BsXLg
                                  fontSize='14px'
                                  color={theme.colors.secondary}
                                />
                                <FeatureName>
                                  Skilled Nursing Facility Care
                                </FeatureName>
                              </FeaturesListItem>
                            )}
                            {plan.planFeatures.includes(
                              'Foreign Travel Emergency'
                            ) ? (
                              <FeaturesListItem>
                                <BsCheckLg
                                  fontSize='14px'
                                  color={theme.colors.green}
                                />
                                <FeatureName>Foreign Travel</FeatureName>
                              </FeaturesListItem>
                            ) : (
                              <FeaturesListItem>
                                <BsXLg
                                  fontSize='14px'
                                  color={theme.colors.secondary}
                                />
                                <FeatureName>Foreign Travel</FeatureName>
                              </FeaturesListItem>
                            )}
                          </FeaturesList>
                        </FeaturesRow>
                      )}
                  </BenefitContent>
                </BenefitsWrapper>
                <PricingWrapper
                  style={{ display: 'flex', justifyContent: 'center' }}
                >
                  <h2>Monthly Premium</h2>
                  <Button
                    variant='contained'
                    color='secondary'
                    width='190px'
                    onClick={handleSubmit}
                    additionalPadding={true}
                    id={`view_rates-${plan.msCompanyBase.name} (Plan ${plan.planName})`}
                  >
                    View Rates
                  </Button>
                </PricingWrapper>
              </ContentWrapper>
            </Wrapper>
          </Container>
        )
      })}
    </div>
  )
}

export default observer(SupplementPopUpCard)
