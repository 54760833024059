export enum CurrentPlanISComponentEnum {
  QUESTION_1 = 'Question1',
  QUESTION_2 = 'Question2',
  QUESTION_3 = 'Question3',
  QUESTION_4 = 'Question4',
  QUESTION_5 = 'Question5',
  QUESTION_6 = 'Question6',
  QUESTION_7 = 'Question7',
  QUESTION_8 = 'Question8',
  QUESTION_9 = 'Question9',
  QUESTION_10 = 'Question10',
  FINISHED = 'FINISHED',
}
