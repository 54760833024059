import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormLabel from '@mui/material/FormLabel'
import MuiRadioGroup from '@mui/material/RadioGroup'
import Radio from '@mui/material/Radio'
import React from 'react'
import { fireEvent } from '../../../cra'
import theme from '../../../global/theme'
import Tooltip from '../Tooltip/Tooltip.component'
import StringConstants from '../../../constants/StringConstants'

const radioStyles = {
  backgroundColor: `${theme.colors.backgroundColorContainer}`,
  border: `1px solid ${theme.colors.borderColor}`,
  borderRadius: '4px',
  padding: '0px 10px',
  margin: '3px 10px 3px 0px',
}

const errorRadioStyles = {
  backgroundColor: `${theme.colors.backgroundColorContainer}`,
  border: `1px solid ${theme.colors.secondary}`,
  borderRadius: '4px',
  padding: '0px 10px',
  margin: '3px 10px 3px 0px',
}

const unstyledRadioStyles = {}

interface RadioGroupProps {
  allUncheck?: boolean | undefined
  formLabel?: string
  radioButtons: Array<string>
  row?: boolean
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  width?: string
  type?: 'default' | 'unstyled'
  value?: string
  error?: boolean
  fontSize?: string
  radioGroupName?: string
  disabledValues?: Array<string>
  extraPaddingBottom?: string
  radioButtonWidth?: string
  alignItems?: string
  justifyContent?: string
  isValueModified?: boolean
  valueToBeRemoved?: string
  showToolTipForDosage?: boolean
}

const RadioGroup: React.FC<RadioGroupProps> = ({
  allUncheck,
  formLabel,
  radioButtons,
  row = false,
  onChange,
  width = '100%',
  type = 'default',
  value,
  error = false,
  fontSize = '16px',
  radioGroupName = '',
  disabledValues = [],
  extraPaddingBottom = '0px',
  radioButtonWidth,
  alignItems,
  justifyContent,
  isValueModified=false,
  valueToBeRemoved='',
  showToolTipForDosage = false
}) => {
  const [radioValue, setRadioValue] = React.useState(value)
  const disable =
    disabledValues.length > 0
      ? disabledValues.every((x) => radioButtons.includes(x))
      : false

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRadioValue((event.target as HTMLInputElement).value)
    onChange && onChange(event)
  }

  React.useEffect(() => {
    setRadioValue(value)
  }, [value])

  const getLabel = (
    line: string | number | JSX.Element
  ): JSX.Element | string | number => {
    if (typeof line === 'string') {
      if(showToolTipForDosage && valueToBeRemoved !== '') {
        return (
          <>
            {line.includes(valueToBeRemoved) ? line.split(valueToBeRemoved)[0] : line}
            {line.includes(valueToBeRemoved) && (
              <Tooltip
                title={StringConstants.TOOLTIP_COMMON_DOSAGE_MSG}
                content={'i'}
              />
            )}
          </>      
        )
      }
      if (line.includes('\n')) {
        const label = (
          <p>
            {line.split('\n').map((iterator) => (
              <React.Fragment key={iterator}>
                <span>{iterator}</span>
                <br />
              </React.Fragment>
            ))}
          </p>
        )
        return label
      } else {
        return line
      }
    } else {
      return line
    }
  }

  return (
    <FormControl
      sx={{
        width: `${width}`,
        alignItems: `${alignItems}`,
      }}
    >
      <FormLabel
        sx={
          error === false
            ? { fontStyle: 'italic', fontSize: '14px' }
            : {
                fontStyle: 'italic',
                fontSize: '14px',
                color: theme.colors.secondary,
              }
        }
      >
        {formLabel}
      </FormLabel>
      <MuiRadioGroup
        sx={{ justifyContent: `${justifyContent}` }}
        onChange={handleChange}
        row={row}
        value={radioValue}
      >
        {radioButtons &&
          radioButtons.map((radio) => (
            <FormControlLabel
              key={radio}
              value={radio}
              checked={allUncheck}
              disabled={
                disable && disabledValues && disabledValues.includes(radio)
              }
              style={{
                paddingBottom: extraPaddingBottom,
                width: radioButtonWidth ? radioButtonWidth : '',
              }}
              control={
                <Radio
                  sx={{
                    '& .MuiSvgIcon-root': {
                      fontSize: 18,
                    },
                  }}
                  onChange={(e) => {
                    fireEvent('radioInput', e, {
                      label: radioGroupName,
                      value: radio,
                      description: `${'Selected'} option ${radio} in ${radioGroupName}`,
                    })
                  }}
                />
              }
              label={isValueModified ? getLabel(radio.toString().substring(0,radio.toString().indexOf(valueToBeRemoved))) : getLabel(radio)}
              sx={
                error === true
                  ? {
                      ...errorRadioStyles,
                      '& .MuiFormControlLabel-label': {
                        fontSize,
                      },
                      '& .MuiFormControlLabel-root': {
                        paddingBottom: '3px !important',
                      },
                    }
                  : type === 'default'
                  ? {
                      '@media screen and (max-width: 750px)': {
                        width: width,
                      },
                      ...radioStyles,
                      '& .MuiFormControlLabel-label': {
                        fontSize,
                      },
                      '& .MuiFormControlLabel-root': {
                        paddingBottom: '3px !important',
                      },
                    }
                  : {
                      ...unstyledRadioStyles,
                      '& .MuiFormControlLabel-label': {
                        fontSize,
                      },
                    }
              }
            />
          ))}
      </MuiRadioGroup>
    </FormControl>
  )
}

export default RadioGroup
