import ApiManager from '../../../api/ApiManager'
import Button from '../../common/Button/Button.component'
import { HttpMethods } from '../../../enums/HttpMethodsEnum'
import { Link } from 'react-router-dom'
import PasswordField from '../../common/InputField/PasswordField.component'
import ReCAPTCHA from 'react-google-recaptcha'
import React from 'react'
import RouteConstants from '../../../constants/RouteConstants'
import UrlConstants from '../../../constants/UrlConstants'
import ValidationUtils from '../../../utils/validation/ValidationUtils'
import customerStore from '../../../datastore/CustomerStore'
import { observer } from 'mobx-react'
import styled from '@emotion/styled'
import theme from '../../../global/theme'
import { withRouter } from '../../common/WithRouter/WithRouter.component'

interface VerificationProps {
  handleNext: () => void
  handleBack: () => void
  setPassword: (pwd: string) => void
}

const Container = styled.div`
  width: 400px;
  h2 {
    font-size: 24px;
    color: ${theme.colors.primary};
    padding: 20px 0px;
    font-weight: 700;
  }

  p {
    padding-top: 10px;
    color: ${theme.colors.primary};
    text-decoration: underline;
    font-weight: 600;
  }

  .MuiFormHelperText-root.Mui-error.MuiFormHelperText-sizeSmall.MuiFormHelperText-contained {
    font-weight: 400;
    text-decoration: none;
  }

  @media screen and (max-width: 470px) {
    width: 320px;
  }
`

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 30px;

  @media screen and (max-width: 500px) {
    flex-direction: column;
  }
`

const ButtonWrapper = styled.div`
  width: 150px;
  @media screen and (max-width: 500px) {
    width: 100%;
  }
`

const LinkContainer = styled(Link)`
  text-decoration: underline;
  font-weight: 600;
  font-size: 16px;
  color: ${theme.colors.primary};
  margin-left: 10px;
`

const CaptchaWrapper = styled.div`
  .grecaptcha-badge {
    visibility: hidden;
  }
`

const Verification: React.FC<VerificationProps> = (
  props: VerificationProps
) => {
  const [state, setState] = React.useState({
    password: '',
    passwordError: false,
    apiError: false,
    apiErrorText: '',
    loading: false,
    currentPasswordHelperText: false,
  })

  const reCaptchaRef = React.useRef<ReCAPTCHA>(null)
  const [disabled, setDisabled] = React.useState(false)
  React.useEffect(() => {
    window.scroll({ top: 0, behavior: 'smooth' })
  }, [])

  const isValidPassword = (password: string) => {
    return ValidationUtils.validateCurrentPassword(password)
  }
  const handlePasswordInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const passwordError = !isValidPassword(e.target.value)
    setState({
      ...state,
      passwordError: passwordError,
      password: e.target.value,
    })
  }

  const validatePasswordOnBlur = (_e: React.FocusEvent<HTMLInputElement>) => {
    setState({
      ...state,
      passwordError: !isValidPassword(state.password),
    })
  }

  const handleNext = async (_e: React.MouseEvent<HTMLButtonElement>) => {
    setDisabled(true)
    const token = await reCaptchaRef.current?.executeAsync().catch((err) => {
      console.log(err.message)
    })
    let data: Partial<{
      username: string
      password: string
      passthrough: string
      reCaptchaToken: string
    }> = {
      username: customerStore.getUsername(),
      password: state.password,
    }
    if (token) {
      data.reCaptchaToken = token
    }
    ApiManager.makeApiCall(
      UrlConstants.SIGNIN.USECASE,
      HttpMethods.POST_METHOD,
      data
    ).then((response) => {
      if (response.status === 200) {
        props.setPassword(state.password)
        props.handleNext()
      } else {
        setDisabled(false)
        const currentPassword = true
        setState({
          ...state,
          currentPasswordHelperText: currentPassword,
        })
      }
    })
  }

  // const passwordError = !isValidPassword(state.password)
  // if (passwordError) {
  //   setState({
  //     ...state,
  //     passwordError,
  //   })
  // } else {
  //   props.setPassword(state.password)
  //   props.handleNext()
  // }

  return (
    <Container>
      <h2>Let’s first verify that it’s you!</h2>
      <PasswordField
        error={
          state.passwordError
            ? true
            : state.currentPasswordHelperText
            ? true
            : false
        }
        helperText={
          state.passwordError
            ? 'Please enter a valid password'
            : state.currentPasswordHelperText
            ? 'Invalid Current Password'
            : ''
        }
        fullWidth
        label='Your current Password'
        color='info'
        onChange={handlePasswordInput}
        onBlur={validatePasswordOnBlur}
        additionMarginBottom='15px'
      />
      <LinkContainer to={RouteConstants.FORGOT_PASSWORD}>
        Forgot Password?
      </LinkContainer>
      <ButtonContainer>
        <ButtonWrapper>
          <Button width='100%' disabled onClick={props.handleBack}>
            Back
          </Button>
        </ButtonWrapper>
        <ButtonWrapper>
          <Button
            width='100%'
            color='secondary'
            variant='contained'
            onClick={handleNext}
            disabled={disabled}
          >
            Next
          </Button>
        </ButtonWrapper>
      </ButtonContainer>
      <CaptchaWrapper>
        <ReCAPTCHA
          ref={reCaptchaRef}
          sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY!}
          size='invisible'
        />
      </CaptchaWrapper>
    </Container>
  )
}

export default withRouter(observer(Verification))
