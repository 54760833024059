import { useEffect, useRef, useState } from 'react'

import Button from '../../../common/Button/Button.component'
import { SnackbarTypes } from '../../../../enums/SnackbarTypesEnum'
import Tooltip from '../../../common/Tooltip/Tooltip.component'
import { ellipsis } from '../../../../utils/StringUtils'
import snackbarStore from '../../../../datastore/SnackbarStore'
import styled from '@emotion/styled'

interface Props {
  cities: any
  setSelectedCity: any
  setShowProvidersSearchBar?: any
}

declare global {
  interface Window {
    google: any
  }
}

interface InputProps {
  isHovered?: boolean
}

const GMapInputField = styled.input<InputProps>`
  width: 100%;
  border: 1px solid ${(props) => (props.isHovered ? '#333' : '#bdbdbd')};
  padding: 10px;
  border-radius: 30px;
  transition: border-color 0.2s ease-in-out;
  outline: none;
`

const NearCities = (props: Props) => {
  const inputRef = useRef(null)
  const [isHovered, setIsHovered] = useState(false)

  useEffect(() => {
    if (!window.google) {
      const script = document.createElement('script')
      script.src = `${process.env.REACT_APP_GMAP_API_URL}&key=${process.env.REACT_APP_GMAP_API_KEY}&callback=Function.prototype`;
      script.onload = loadPlacesService
      document.body.appendChild(script)
    } else {
      loadPlacesService()
    }
  }, [])

  const loadPlacesService = () => {
    const autocomplete = new window.google.maps.places.Autocomplete(
      inputRef.current,
      {
        types: ['address'],
        componentRestrictions: { country: 'us' },
      }
    )

    const suggestionBox = document.querySelector(
      '.pac-container'
    ) as HTMLElement
    if (suggestionBox) {
      suggestionBox.style.border = 'none'
      suggestionBox.style.boxShadow = 'none'
    }

    autocomplete.addListener('place_changed', () => {
      const place = autocomplete.getPlace()
      handleSelect(place)
    })
  }

  const handleSelect = (place: any) => {
    let city = null
    let stateCode = null

    place.address_components.forEach((component: any) => {
      if (component.types.includes('locality')) {
        city = component.long_name
      }
      if (component.types.includes('administrative_area_level_1')) {
        stateCode = component.short_name
      }
    })

    if (stateCode !== null && city !== null) {
      props.setSelectedCity({
        city: city,
        state: stateCode,
        latitude: place.geometry.location.lat(),
        longitude: place.geometry.location.lng(),
      })
      props?.setShowProvidersSearchBar(true)
    } else {
      snackbarStore.set({
        snackbarOpen: true,
        snackbarMessage:
          'There is no city or state code present for the given address',
        snackbarType: SnackbarTypes.WARNING,
      })
    }
  }

  const handleMouseEnter = () => {
    setIsHovered(true)
  }

  const handleMouseLeave = () => {
    setIsHovered(false)
  }

  return (
    <>
      <p>Nearby cities/towns</p>
      <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: '10px' }}>
        {props.cities.slice(0, 4).map((item: any, index: any) => (
          <Button
            key={index}
            variant='outlined'
            width='180px'
            borderRadius='10px'
            bold={false}
            fontSize='18px'
            margin='10px'
            onClick={() => {
              props.setSelectedCity(item)
              props?.setShowProvidersSearchBar(true)
            }}
            flexBasis='42%'
          >
            <span style={{ display: 'flex', alignItems: 'center' }}>
              <Tooltip title={item.city} content={ellipsis(item.city, 12)} />
            </span>
          </Button>
        ))}
      </div>
      <p>Enter your physician’s address</p>
      <div style={{ paddingTop: '10px' }}>
        <GMapInputField
          isHovered={isHovered}
          ref={inputRef}
          type='text'
          placeholder='Enter a location'
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        />
      </div>
    </>
  )
}

export default NearCities