import { Radio, Rating, Switch } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { RiArrowDropDownLine, RiArrowDropUpLine } from 'react-icons/ri'
import { isEmpty, isEqual, isNull, isUndefined, set } from 'lodash'

import Checkbox from '@mui/material/Checkbox'
import CheckboxGroup from '../../../common/CheckboxGroup/CheckboxGroup.component'
import CloseIconButton from '../../../common/Button/CloseIconButton.component'
import { MAPlanFiltersRequest } from '../../../../interface/quotes/mNonSupplementPlans/medicareAdvantage/request/MAPlanFiltersRequest'
import { MedicareAdvantageDisplayFilter } from '../../../../interface/quotes/mNonSupplementPlans/medicareAdvantage/MedicareAdvantageDisplayFilters'
import ModalComponent from '../../../common/Modal/Modal.component'
import OptionalMessage from '../../Common/OptionalMessageFilter'
import PaginationConstants from '../../../../constants/PaginationConstants'
import PlanYearChangeConfirmationPopUp from '../../Modals/PlanYearChangeConfirmationPopUp'
import QuotesConstants from '../../../../constants/QuotesConstants'
import RadioGroup from '../../../common/RadioGroup/RadioGroup.component'
import RouteConstants from '../../../../constants/RouteConstants'
import SliderComponent from '../../../common/Slider/Slider'
import StringConstants from '../../../../constants/StringConstants'
import Tooltip from '../../../common/Tooltip/Tooltip.component'
import { clear } from 'console'
import { convertToUSCurrency } from '../../../../utils/CurrencyConverterUtil'
import customerStore from '../../../../datastore/CustomerStore'
import { fireEvent } from '../../../../cra'
import medicareAdvantageFilterQuoteStore from '../../../../datastore/medicareQuotes/MedicareAdvantageFilterQuoteStore'
import { observer } from 'mobx-react'
import styled from '@emotion/styled'
import theme from '../../../../global/theme'

interface FilterContainerProps {
  showFilter?: boolean
  greyOut?: boolean
}

const FixedContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 360px;
  bottom: 10px;
  margin: 60px 0px 0px 0px;

  @media (max-width: 900px) {
    margin: 0px;
    width: 0px;
  }
`

const Overlay = styled.div<FilterContainerProps>`
  @media screen and (max-width: 950px) {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 99;
    display: ${({ showFilter }) => (showFilter ? 'block' : 'none')};
    transition: background-color 0.3s ease;
  }
`

const Container = styled.div<FilterContainerProps>`
  position: sticky;
  // overflow-y: auto;
  height: 175vh;
  top: 100px;

  .filterPlan {
    padding: 20px 0px;
    border-top: 1px solid ${theme.colors.borderColor};
    h3 {
      font-size: 16px;
      font-weight: bold;
      color: ${theme.colors.textDark};
      padding-bottom: 5px;
    }
    p {
      color: ${theme.colors.textGrey};
      font-weight: normal;
      font-size: 14px;
    }
  }

  @media screen and (max-width: 950px) {
    position: fixed;
    max-height: 100vh;
    top: 0;
    left: 0;
    margin: 0px 0px;
    margin-top: 75px;
    z-index: 100;
    max-width: 90%;
    width: 50%;
    overflow-y: auto;
    box-shadow: 8px 0px 108px -78px rgba(0, 0, 0, 0.75);
    display: ${({ showFilter }) => (showFilter ? 'block' : 'none')};
    height: 100vh;
  }

  @media screen and (max-width: 750px) {
    width: 60%;
    margin-top: 61px;
  }

  @media screen and (max-width: 650px) {
    width: 80%;
  }
  @media screen and (max-width: 450px) {
    margin-top: 53px;
  }
  opacity: ${({ greyOut }) => (greyOut ? 0.5 : 1)};
  pointer-events: ${({ greyOut }) => (greyOut ? 'none' : 'auto')};
`

const Wrapper = styled.div`
  border-right: 1px solid ${theme.colors.borderColor};
  padding: 0px 50px 0px 20px;
  position: relative;
  @media screen and (max-width: 950px) {
    padding: 90px 50px 50px 50px;
    background-color: ${theme.colors.white};
    border-right: none;
    overflow-y: scroll;
    height: 100%;
  }
`

const FiltersWrapper = styled.div``

const Filter = styled.div`
  padding: 15px 0px;
  border-top: 1px solid ${theme.colors.borderColor};
`

const Label = styled.p`
  color: ${theme.colors.primary};
  font-size: 16px;
  font-weight: 700;
  padding-right: 20px;
  margin: 5px 0px;
`
const CloseButtonWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  padding: 20px;
  display: none;
  z-index: 1000;
  @media screen and (max-width: 950px) {
    padding-top: 50px;
    display: block;
  }
`

const FloatingFilterContainer = styled.div`
  position: sticky;
  top: 0;
  background-color: ${theme.colors.white};
  z-index: 100;
  width: 105%;
  padding: 16px 20px;
  border: solid 1px ${theme.colors.borderColor};
  border-radius: 4px;

  h3 {
    color: ${theme.colors.primary};
    font-size: 18px;
    margin-bottom: 12px;
    text-align: center;
  }

  @media (max-width: 900px) {
    top: 40px;
  }
`

const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

const SliderContentContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

interface FilterAdvantageProps {
  showFilter: boolean
  handleShowFilter: () => void
  filterRef: React.MutableRefObject<any>
  clearFilterState: boolean
  clearButtonState: () => void
  setSelectedFilters?: (data: string[]) => void
  maPlansFilters: MedicareAdvantageDisplayFilter
  setMAPlansFilters: (data: MedicareAdvantageDisplayFilter) => void
}

const FilterAdvantage: React.FC<FilterAdvantageProps> = ({
  showFilter,
  handleShowFilter,
  filterRef,
  clearFilterState,
  clearButtonState,
  setSelectedFilters,
  maPlansFilters,
  setMAPlansFilters,
}) => {
  const mAdvantageFilterRequest: MAPlanFiltersRequest = {}
  const [monthlyPremiumSliderValue, setMonthlyPremiumSliderValue] =
    useState<number>(
      medicareAdvantageFilterQuoteStore.getMedicareAdvantageFilters()
        .monthlyPremium.currentValue
    )
  const [moopSliderValue, setMoopSliderValue] = useState<number>(
    medicareAdvantageFilterQuoteStore.getMedicareAdvantageFilters().moop
      .currentValue
  )
  const [
    openPlanYearFilterConfirmationPopup,
    setOpenPlanYearFilterConfirmationPopup,
  ] = useState<boolean>(false)
  const [tempPlanYear, setTempPlanYear] = useState('')
  const [carrierDropdown, setCarrierDropdown] = useState(false)
  const [typeDropdown, setTypeDropdown] = useState(false)
  const [benefitsDropdown, setBenefitsDropdown] = useState(false)
  const [drugsDropdown, setDrugsDropdown] = useState(false)
  const [providersDropDown, setProvidersDropDown] = useState(false)

  useEffect(() => {
    if (maPlansFilters.clearFiltersApplied) {
      medicareAdvantageFilterQuoteStore.setClearFilters(true)
      maPlansFilters.clearFiltersApplied = false
      setMAPlansFilters({ ...maPlansFilters, clearFiltersApplied: false })
      medicareAdvantageFilterQuoteStore.setMedicareAdvantageFilters(
        maPlansFilters
      )
      medicareAdvantageFilterQuoteStore.clearMAPlanRequestFilters()
    }
  }, [maPlansFilters.clearFiltersApplied])

  const renderCount = useRef(0)

  useEffect(() => {
    handleSelectedFilters()
  }, [maPlansFilters])

  useEffect(() => {
    // renderCount.current = renderCount.current + 1
    // if (renderCount.current !== 1) {
    if (medicareAdvantageFilterQuoteStore.getIsFilterChanged()) {
      applyFilters()
      handleSelectedFilters()
      // medicareAdvantageFilterQuoteStore.setIsFilterChanged(false)
    }
    // }
  }, [medicareAdvantageFilterQuoteStore.getIsFilterChanged()])

  useEffect(() => {
    if (clearFilterState) {
      clearFilters()
      clearButtonState()
    }
  }, [clearFilterState])

  const handleSelectedFilters = () => {
    const carrierFilters = maPlansFilters.carriers
      ?.filter((carrier) => carrier.checked === true)
      .map((carrier) => carrier.value)
    const benefitsFilters = maPlansFilters.planBenefitsCheckbox
      ?.filter((benefit) => benefit.checked === true)
      .map((benefit) => benefit.value)
    const planTypeFilters = maPlansFilters.planTypeCheckbox
      ?.filter((planType) => planType.checked === true)
      .map((planType) => planType.value)
    const drugsFilters = maPlansFilters.drugsCheckbox
      ?.filter((drug) => drug.checked === true)
      .map((drug) => drug.value)
    const providerFilters = maPlansFilters.providers
      ?.filter((provider) => provider.checked === true)
      .map((provider) => provider.value)
    const selectedCarrierFilters = Array.isArray(carrierFilters)
      ? carrierFilters
      : [carrierFilters]
    const selectedBenefitsFilters = Array.isArray(benefitsFilters)
      ? benefitsFilters
      : [benefitsFilters]
    const selectedPlanTypeFilters = Array.isArray(planTypeFilters)
      ? planTypeFilters
      : [planTypeFilters]
    const selectedDrugsFilters = Array.isArray(drugsFilters)
      ? drugsFilters
      : [drugsFilters]
    const selectProviderFilters = Array.isArray(providerFilters)
      ? providerFilters
      : [providerFilters]

    setSelectedFilters &&
      setSelectedFilters([
        ...selectedCarrierFilters,
        ...selectedPlanTypeFilters,
        ...selectedBenefitsFilters,
        ...selectedDrugsFilters,
        ...selectProviderFilters,
      ])

    // Set status to active to disable count update
    medicareAdvantageFilterQuoteStore.setFiltersActive(
      [
        ...selectedCarrierFilters,
        ...selectedPlanTypeFilters,
        ...selectedBenefitsFilters,
        ...selectedDrugsFilters,
        ...selectProviderFilters,
      ].length !== 0
    )
  }

  const handleCarrierCheckboxChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (maPlansFilters) {
      const checkedCarriers = maPlansFilters.carriers?.map((item) =>
        item.value === e.target.value
          ? { ...item, checked: !item.checked }
          : item
      )
      setMAPlansFilters({
        ...maPlansFilters,
        carriers: checkedCarriers,
      })
      medicareAdvantageFilterQuoteStore.setIsFilterChanged(true)
    }
  }

  const handlePlanBenefitsCheckbox = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (maPlansFilters) {
      const checkedPlanBenefits = maPlansFilters.planBenefitsCheckbox?.map(
        (item) =>
          item.value === e.target.value
            ? { ...item, checked: !item.checked }
            : item
      )
      setMAPlansFilters({
        ...maPlansFilters,
        planBenefitsCheckbox: checkedPlanBenefits,
      })
      medicareAdvantageFilterQuoteStore.setIsFilterChanged(true)
    }
  }

  const handlePlanTypeCheckboxChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (maPlansFilters) {
      const checkedPlanTypes = maPlansFilters.planTypeCheckbox?.map((item) =>
        item.value === e.target.value
          ? { ...item, checked: !item.checked }
          : item
      )
      setMAPlansFilters({
        ...maPlansFilters,
        planTypeCheckbox: checkedPlanTypes,
      })
      medicareAdvantageFilterQuoteStore.setIsFilterChanged(true)
    }
  }

  const handleDrugsCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (maPlansFilters) {
      const checkedDrugs = maPlansFilters.drugsCheckbox?.map((item) =>
        item.value.toString() === e.target.value.toString()
          ? { ...item, checked: !item.checked }
          : item
      )
      setMAPlansFilters({
        ...maPlansFilters,
        drugsCheckbox: checkedDrugs,
      })
      medicareAdvantageFilterQuoteStore.setIsFilterChanged(true)
    }
  }

  const handleProvidersCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (maPlansFilters) {
      const checkedProviders = maPlansFilters.providers?.map((item) =>
        item.value.toString() === e.target.value.toString()
          ? { ...item, checked: !item.checked }
          : item
      )
      setMAPlansFilters({
        ...maPlansFilters,
        providers: checkedProviders,
      })
      medicareAdvantageFilterQuoteStore.setIsFilterChanged(true)
    }
  }

  const handleRxCoverage = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (maPlansFilters) {
      setMAPlansFilters({
        ...maPlansFilters,
        rxIncluded: event.target.checked,
      })
      medicareAdvantageFilterQuoteStore.setIsFilterChanged(true)
    }
    medicareAdvantageFilterQuoteStore.setIsRxIncluded(
      !medicareAdvantageFilterQuoteStore.getIsRxIncluded()
    )
  }
  const togglePlanYearFilterPopupOpen = () => {
    setOpenPlanYearFilterConfirmationPopup(!openPlanYearFilterConfirmationPopup)
  }
  const handleOnConfirmPlanYearChange = () => {
    setMAPlansFilters({
      ...maPlansFilters!,
      currentPlanYear: tempPlanYear,
    })
    customerStore.setMAFilterYear(tempPlanYear)
    customerStore.setMAPageSize(0)
    togglePlanYearFilterPopupOpen()
    window.scrollTo({ top: 0, behavior: 'smooth' })
    // medicareAdvantageFilterQuoteStore.setIsFilterChanged(true)
  }

  const handleOnRejectPlanYearChange = () => {
    setTempPlanYear('')
    togglePlanYearFilterPopupOpen()
  }

  const handlePlanYear = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (maPlansFilters) {
      const year = e.target.value

      if (e.target.checked) {
        setTempPlanYear(year.toString())
        togglePlanYearFilterPopupOpen()
      }
    }
  }

  const handleRatingChange = (value: number | null) => {
    if (maPlansFilters) {
      if (value && value >= maPlansFilters.ratingValue.minValue) {
        setMAPlansFilters({
          ...maPlansFilters!,
          ratingValue: {
            ...maPlansFilters!.ratingValue!,
            currentValue: value,
          },
        })
        medicareAdvantageFilterQuoteStore.setIsFilterChanged(true)
      }
    }
  }

  const handleMaxMonthlyPremium = (e: any) => {
    if (maPlansFilters) {
      setMAPlansFilters({
        ...maPlansFilters,
        monthlyPremium: {
          ...maPlansFilters.monthlyPremium,
          currentValue: monthlyPremiumSliderValue,
        },
      })
      medicareAdvantageFilterQuoteStore.setIsFilterChanged(true)
    }
  }

  const handleMaxAnnualDeductible = (e: any) => {
    if (maPlansFilters) {
      setMAPlansFilters({
        ...maPlansFilters,
      })
      medicareAdvantageFilterQuoteStore.setIsFilterChanged(true)
    }
  }

  const handleMaxOutOfPocket = (e: any) => {
    if (maPlansFilters) {
      setMAPlansFilters({
        ...maPlansFilters,
        moop: {
          ...maPlansFilters.moop,
          currentValue: moopSliderValue,
        },
      })
      medicareAdvantageFilterQuoteStore.setIsFilterChanged(true)
    }
  }

  const applyFilters = () => {
    if (!isEmpty(maPlansFilters)) {
      mAdvantageFilterRequest.carriers = maPlansFilters.carriers
        ?.filter((item) => item.checked)
        .map((item) => item.id)
      mAdvantageFilterRequest.policyTypes = maPlansFilters.planTypeCheckbox
        ?.filter((item) => item.checked)
        .map((item) => item.value)
      if (maPlansFilters.currentPlanYear !== '') {
        mAdvantageFilterRequest.planYear =
          customerStore.maFilterYear === ''
            ? maPlansFilters.currentPlanYear.toString()
            : customerStore.maFilterYear
      }

      mAdvantageFilterRequest.planBenefits = maPlansFilters.planBenefitsCheckbox
        ?.filter((item) => item.checked)
        .map((item) => item.value)

      mAdvantageFilterRequest.prescriptions = maPlansFilters.drugsCheckbox
        .filter((item) => item.checked)
        .map((item) => (item.optionalValue ? item.optionalValue : ''))

      mAdvantageFilterRequest.providers = maPlansFilters.providers
        .filter((item) => item.checked)
        .map((item) => (item.optionalValue ? item.optionalValue : ''))

      mAdvantageFilterRequest.rxIncluded = maPlansFilters.rxIncluded

      if (
        maPlansFilters.ratingValue.currentValue !==
        maPlansFilters.ratingValue.minValue
      ) {
        mAdvantageFilterRequest.minStarRating = {
          start: maPlansFilters.ratingValue.minValue,
          end: maPlansFilters.ratingValue.currentValue,
        }
      }

      if (maPlansFilters.moop.currentValue !== maPlansFilters.moop.maxValue) {
        mAdvantageFilterRequest.moop = {
          start: maPlansFilters.moop.minValue,
          end: maPlansFilters.moop.currentValue,
        }
      }

      if (
        maPlansFilters.monthlyPremium.currentValue !==
        maPlansFilters.monthlyPremium.maxValue
      ) {
        mAdvantageFilterRequest.monthlyPremium = {
          start: maPlansFilters.monthlyPremium.minValue,
          end: maPlansFilters.monthlyPremium.currentValue,
        }
      }

      if (
        !isNull(
          medicareAdvantageFilterQuoteStore.medicareAdvantageFilters
            .selectSortOption
        ) &&
        !isEmpty(
          medicareAdvantageFilterQuoteStore.medicareAdvantageFilters
            .selectSortOption
        )
      ) {
        if (
          medicareAdvantageFilterQuoteStore.medicareAdvantageFilters
            .selectSortOption === 'Overall Rating'
        ) {
          mAdvantageFilterRequest.sortDetail = {
            order: 'DESC',
            type: medicareAdvantageFilterQuoteStore.medicareAdvantageFilters
              .selectSortOption,
          }
        } else {
          mAdvantageFilterRequest.sortDetail = {
            order: 'ASC',
            type: medicareAdvantageFilterQuoteStore.medicareAdvantageFilters
              .selectSortOption,
          }
        }
      }
      if (
        !isEqual(
          JSON.stringify(mAdvantageFilterRequest),
          JSON.stringify(
            medicareAdvantageFilterQuoteStore.getMAPlanRequestFilter()
          )
        )
      ) {
        maPlansFilters.selectSortOption =
          medicareAdvantageFilterQuoteStore.medicareAdvantageFilters.selectSortOption
        medicareAdvantageFilterQuoteStore.setFilterApplied(true)
        medicareAdvantageFilterQuoteStore.setMedicareAdvantageFilters(
          maPlansFilters
        )

        mAdvantageFilterRequest.paginationDetail = {
          page: PaginationConstants.DEFAULT_PAGE,
          size: PaginationConstants.DEFAULT_PAGE_SIZE,
        }
        customerStore.setMAPageSize(PaginationConstants.DEFAULT_PAGE_SIZE)
        medicareAdvantageFilterQuoteStore.setMAPlanRequestFilter(
          mAdvantageFilterRequest
        )
      }
      handleShowFilter()
    }
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  const clearFilters = () => {
    if (maPlansFilters) {
      setMonthlyPremiumSliderValue(maPlansFilters.monthlyPremium.maxValue)
      setMoopSliderValue(maPlansFilters.moop.maxValue)
      setMAPlansFilters({
        ...maPlansFilters,
        carriers: maPlansFilters.carriers.map((item) => ({
          ...item,
          checked: false,
        })),
        currentPlanYear: maPlansFilters.planYear.defaultPlanYear,
        drugsCheckbox: maPlansFilters.drugsCheckbox.map((item) => ({
          ...item,
          checked: false,
        })),
        providers: maPlansFilters.providers.map((item) => ({
          ...item,
          checked: false,
        })),
        monthlyPremium: {
          ...maPlansFilters.monthlyPremium,
          currentValue: maPlansFilters.monthlyPremium.maxValue,
        },
        moop: {
          ...maPlansFilters.moop,
          currentValue: maPlansFilters.moop.maxValue,
        },
        planBenefitsCheckbox: maPlansFilters.planBenefitsCheckbox.map(
          (item) => ({ ...item, checked: false })
        ),
        planTypeCheckbox: maPlansFilters.planTypeCheckbox.map((item) => ({
          ...item,
          checked: false,
        })),
        ratingValue: {
          ...maPlansFilters?.ratingValue,
          currentValue: maPlansFilters.ratingValue.minValue,
        },
        rxIncluded: true,
        clearFiltersApplied: true,
        selectSortOption: QuotesConstants.initialMAPlanSortOption,
      })
      setSelectedFilters && setSelectedFilters([])
    }
  }

  const handleDropdownClick = (id: string) => {
    switch (id) {
      case 'carrier':
        setCarrierDropdown(!carrierDropdown)
        setTypeDropdown(false)
        setBenefitsDropdown(false)
        setDrugsDropdown(false)
        setProvidersDropDown(false)
        break
      case 'type':
        setTypeDropdown(!typeDropdown)
        setCarrierDropdown(false)
        setBenefitsDropdown(false)
        setDrugsDropdown(false)
        setProvidersDropDown(false)
        break
      case 'benefits':
        setBenefitsDropdown(!benefitsDropdown)
        setCarrierDropdown(false)
        setTypeDropdown(false)
        setDrugsDropdown(false)
        setProvidersDropDown(false)
        break
      case 'drugs':
        setDrugsDropdown(!drugsDropdown)
        setCarrierDropdown(false)
        setTypeDropdown(false)
        setBenefitsDropdown(false)
        setProvidersDropDown(false)
        break
      case 'providers':
        setProvidersDropDown(!providersDropDown)
        setDrugsDropdown(false)
        setCarrierDropdown(false)
        setTypeDropdown(false)
        setBenefitsDropdown(false)
        break
    }
  }
  return !isEmpty(maPlansFilters) ? (
    <FixedContainer ref={filterRef}>
      {openPlanYearFilterConfirmationPopup && (
        <ModalComponent
          setOpen={handleOnRejectPlanYearChange}
          hideCloseButton={true}
        >
          <PlanYearChangeConfirmationPopUp
            onConfirm={handleOnConfirmPlanYearChange}
            onReject={handleOnRejectPlanYearChange}
            year={tempPlanYear}
          />
        </ModalComponent>
      )}
      <Overlay showFilter={showFilter} />
      <Container
        greyOut={medicareAdvantageFilterQuoteStore.filterApplied}
        showFilter={showFilter}
      >
        <CloseButtonWrapper>
          <CloseIconButton
            fontSize='32px'
            onClick={() => {
              handleShowFilter()
            }}
          />
        </CloseButtonWrapper>
        <Wrapper>
          <OptionalMessage
            heading='Showing plans for'
            link={RouteConstants.PROFILE + '/myprofile/contact'}
            linkText={customerStore.getPostalCodeAndCounty()}
            showToolTip={true}
          />
          {/* <FloatingFilterContainer>
            <h3>FILTER PLANS</h3>
            <ButtonsContainer>
              <Button
                width='80px'
                variant='contained'
                color='primary'
                bold={false}
                height='30px'
                margin='0 12px 0 0'
                onClick={applyFilters}
              >
                Apply
              </Button>
              <Button
                width='80px'
                variant='outlined'
                color='secondary'
                bold={false}
                height='30px'
                margin='0px'
                onClick={clearFilters}
              >
                Clear
              </Button>
            </ButtonsContainer>
          </FloatingFilterContainer> */}

          <FiltersWrapper>
            {maPlansFilters && (
              <Filter
                key='PLanYear'
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Label>Plan Year</Label>

                {/* <Radio
                  value={maPlansFilters.planYear[0]}
                  onChange={(e) => handlePlanYear(e)}
                  size='small'
                  checked={
                    maPlansFilters.currentPlanYear.toString() ===
                    maPlansFilters.planYear[0].toString()
                      ? true
                      : false
                  }
                />
                {maPlansFilters.planYear[0]}
                {maPlansFilters.planYear.length > 1 && (
                  <>
                    <Radio
                      value={maPlansFilters.planYear[1]}
                      onChange={(e) => handlePlanYear(e)}
                      size='small'
                      checked={
                        maPlansFilters.currentPlanYear.toString() ===
                        maPlansFilters.planYear[1].toString()
                          ? true
                          : false
                      }
                    />
                    {maPlansFilters.planYear[1]}
                  </>
                )} */}
                {maPlansFilters.planYear.planYears.map((planYear) => (
                  <>
                    <Radio
                      key={'Plan_Year_Radio'}
                      value={planYear}
                      onChange={handlePlanYear}
                      size='small'
                      checked={
                        customerStore.maFilterYear === ''
                          ? maPlansFilters.currentPlanYear.toString() ===
                            planYear.toString()
                          : customerStore.maFilterYear === planYear.toString()
                      }
                    />
                    {planYear}
                  </>
                ))}
              </Filter>
            )}
            <Filter
              key={'Star_Rating'}
              style={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Label>
                Star Rating
                <Tooltip title={StringConstants.TOOLTIP_MSG_MA_STAR_RATING} />
              </Label>
              {maPlansFilters.ratingValue && (
                <Rating
                  name='simple-controlled'
                  value={maPlansFilters.ratingValue.currentValue}
                  precision={0.5}
                  onChange={(event, newValue) => {
                    fireEvent('starRatingInput', event, {
                      value: newValue,
                      label: 'Star Rating',
                      description: 'Star Rating Filter - Medicare Advantage',
                    })
                    handleRatingChange(newValue)
                  }}
                />
              )}
            </Filter>
            {!isUndefined(maPlansFilters.rxIncluded) && (
              <Filter key={'RX_Coverage'}>
                <Label>
                  Rx Coverage
                  <Tooltip title={StringConstants.TOOLTIP_MSG_MA_RX_COVERAGE} />
                </Label>

                <span
                  style={{
                    color: `${theme.colors.textGrey}`,
                    fontSize: '14px',
                  }}
                >
                  Not Included
                </span>
                <Switch
                  checked={maPlansFilters.rxIncluded}
                  onChange={(e) => {
                    fireEvent('switchInput', e, {
                      label: 'Rx Coverage',
                      value: maPlansFilters.rxIncluded
                        ? 'Not Included'
                        : 'Included',
                      description:
                        'Rx Coverage Included/Not Included Filter - Medicare Advantage',
                    })
                    handleRxCoverage(e)
                  }}
                />
                <span
                  style={{
                    color: `${theme.colors.textGrey}`,
                    fontSize: '14px',
                  }}
                >
                  Included
                </span>
              </Filter>
            )}

            <Filter key={'Carrier'}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  handleDropdownClick('carrier')
                }}
              >
                <Label>
                  Carrier
                  <Tooltip title={StringConstants.TOOLTIP_MSG_MA_CARRIERS} />
                </Label>
                {carrierDropdown ? (
                  <RiArrowDropUpLine
                    style={{ cursor: 'pointer', marginLeft: 'auto' }}
                  />
                ) : (
                  <RiArrowDropDownLine
                    style={{ cursor: 'pointer', marginLeft: 'auto' }}
                  />
                )}
              </div>
              {carrierDropdown ? (
                <div>
                  {maPlansFilters.carriers && (
                    <CheckboxGroup
                      checkboxState={maPlansFilters.carriers}
                      onChange={handleCarrierCheckboxChange}
                      width='100%'
                      type='unstyled'
                      checkboxGroupName='Carrier Filter - Medicare Advantage'
                    />
                  )}
                </div>
              ) : null}
            </Filter>
            <Filter key={'Plan_Type'}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  handleDropdownClick('type')
                }}
              >
                <Label>
                  Plan Type
                  <Tooltip title={StringConstants.TOOLTIP_MSG_MA_PLAN_TYPE} />
                </Label>
                {typeDropdown ? (
                  <RiArrowDropUpLine
                    style={{ cursor: 'pointer', marginLeft: 'auto' }}
                  />
                ) : (
                  <RiArrowDropDownLine
                    style={{ cursor: 'pointer', marginLeft: 'auto' }}
                  />
                )}
              </div>
              {typeDropdown ? (
                <div style={{ maxHeight: '350px', overflowY: 'scroll' }}>
                  {maPlansFilters.planBenefitsCheckbox && (
                    <CheckboxGroup
                      checkboxState={maPlansFilters.planTypeCheckbox}
                      onChange={handlePlanTypeCheckboxChange}
                      width='100%'
                      type='unstyled'
                      checkboxGroupName='Plan Type Filter - Medicare Advantage'
                    />
                  )}
                </div>
              ) : null}
            </Filter>
            <Filter key={'Plan_Benefits'}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  handleDropdownClick('benefits')
                }}
              >
                <Label>
                  Plan Benefits
                  <Tooltip title={StringConstants.TOOLTIP_MSG_PLAN_BENEFITS} />
                </Label>
                {benefitsDropdown ? (
                  <RiArrowDropUpLine
                    style={{ cursor: 'pointer', marginLeft: 'auto' }}
                  />
                ) : (
                  <RiArrowDropDownLine
                    style={{ cursor: 'pointer', marginLeft: 'auto' }}
                  />
                )}
              </div>
              {benefitsDropdown ? (
                <div style={{ maxHeight: '350px', overflowY: 'scroll' }}>
                  {maPlansFilters.planBenefitsCheckbox && (
                    <>
                      <CheckboxGroup
                        checkboxState={maPlansFilters.planBenefitsCheckbox}
                        onChange={handlePlanBenefitsCheckbox}
                        width='100%'
                        type='unstyled'
                        checkboxGroupName='Plan Benefits Filter - Medicare Advantage'
                      />
                    </>
                  )}
                </div>
              ) : null}
            </Filter>
            {/* <Filter>
            <Label>
              Doctors Covered
              <Tooltip title='Doctors covered gives out the names of doctors covered with the plan' />
            </Label>
            <CheckboxGroup
              checkboxState={doctorsCoveredCheckbox}
              onChange={handleDoctorsCoveredCheckbox}
              width='100%'
              type='unstyled'
            />
          </Filter> */}
            {/* {customerStore.isPharmacyAdded() && */}
            {maPlansFilters.drugsCheckbox &&
              maPlansFilters.drugsCheckbox.length > 0 && (
                <Filter key={'Drug_Coverage'}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      handleDropdownClick('drugs')
                    }}
                  >
                    <Label>
                      Drugs Covered
                      <Tooltip
                        title={StringConstants.TOOLTIP_MSG_MA_DRUGS_COVERED}
                      />
                    </Label>
                    {drugsDropdown ? (
                      <RiArrowDropUpLine
                        style={{ cursor: 'pointer', marginLeft: 'auto' }}
                      />
                    ) : (
                      <RiArrowDropDownLine
                        style={{ cursor: 'pointer', marginLeft: 'auto' }}
                      />
                    )}
                  </div>
                  {drugsDropdown ? (
                    <div style={{ maxHeight: '350px', overflowY: 'scroll' }}>
                      <CheckboxGroup
                        checkboxState={maPlansFilters.drugsCheckbox}
                        onChange={handleDrugsCheckbox}
                        width='100%'
                        type='unstyled'
                        checkboxGroupName='Drugs Covered Filter - Medicare Advantage'
                      />
                    </div>
                  ) : null}
                </Filter>
              )}
            {maPlansFilters.providers?.length > 0 && (
              <Filter key={'Providers_Coverage'}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    handleDropdownClick('providers')
                  }}
                >
                  <Label>
                    Physician Covered
                    <Tooltip
                      title={StringConstants.TOOLTIP_MSG_MA_Physician_COVERED}
                    />
                  </Label>
                  {providersDropDown ? (
                    <RiArrowDropUpLine
                      style={{ cursor: 'pointer', marginLeft: 'auto' }}
                    />
                  ) : (
                    <RiArrowDropDownLine
                      style={{ cursor: 'pointer', marginLeft: 'auto' }}
                    />
                  )}
                </div>
                {providersDropDown ? (
                  <div style={{ maxHeight: '350px', overflowY: 'scroll' }}>
                    <CheckboxGroup
                      checkboxState={maPlansFilters.providers}
                      onChange={handleProvidersCheckbox}
                      width='100%'
                      type='unstyled'
                      checkboxGroupName='Drugs Covered Filter - Medicare Advantage'
                    />
                  </div>
                ) : null}
              </Filter>
            )}
            {!isUndefined(maPlansFilters.monthlyPremium) && (
              <Filter key={'Maximum_Monthly_Premium'}>
                <SliderComponent
                  label='Maximum Monthly Premium'
                  minValue={maPlansFilters.monthlyPremium.minValue}
                  maxValue={maPlansFilters.monthlyPremium.maxValue}
                  step={maPlansFilters.monthlyPremium.stepValue}
                  size='small'
                  value={monthlyPremiumSliderValue}
                  onChange={(e: any) => {
                    setMonthlyPremiumSliderValue(e.target.value)
                  }}
                  onChangeCommitted={(e: any) => {
                    handleMaxMonthlyPremium(e)
                    const label = 'Maximum Monthly Premium'
                    const value = monthlyPremiumSliderValue
                    fireEvent('sliderInput', e, {
                      label,
                      value,
                      description: `Value of ${label} set to ${value}`,
                    })
                  }}
                  withTooltip={true}
                  tooltipContent={
                    StringConstants.TOOLTIP_MSG_MAXIMUM_MONTHLY_PREMIUM
                  }
                />
                <SliderContentContainer>
                  <div>
                    {convertToUSCurrency(
                      maPlansFilters.monthlyPremium.minValue
                    )}
                  </div>
                  <div>
                    {convertToUSCurrency(
                      maPlansFilters.monthlyPremium.maxValue
                    )}
                  </div>
                </SliderContentContainer>
              </Filter>
            )}
            {/* TODO: Remove Annual Deductible if not needed */}
            {/* {!isUndefined(maPlansFilters.annualDeductible) && (
              <Filter>
                <SliderComponent
                  label='Maximum Annual Deductible'
                  minValue={maPlansFilters.annualDeductible.minValue}
                  maxValue={maPlansFilters.annualDeductible.maxValue}
                  step={maPlansFilters.annualDeductible.stepValue}
                  value={maPlansFilters.annualDeductible.currentValue}
                  size='small'
                  onChange={(e) => handleMaxAnnualDeductible(e)}
                  withTooltip={true}
                  tooltipContent={
                    StringConstants.TOOLTIP_MSG_MAXIMUM_ANNUAL_DEDUCTIBLE
                  }
                />
                <SliderContentContainer>
                  <div>{maPlansFilters.annualDeductible.minValue}</div>
                  <div>{maPlansFilters.annualDeductible.maxValue}</div>
                </SliderContentContainer>
              </Filter>
            )} */}
            {!isUndefined(maPlansFilters.moop) && (
              <Filter key={'Maximum_Out_of_Pocket'}>
                <SliderComponent
                  label='Maximum Out of Pocket'
                  minValue={maPlansFilters.moop.minValue}
                  maxValue={maPlansFilters.moop.maxValue}
                  step={maPlansFilters.moop.stepValue}
                  size='small'
                  value={moopSliderValue}
                  onChange={(e: any) => {
                    setMoopSliderValue(e.target.value)
                  }}
                  onChangeCommitted={(e: any) => {
                    handleMaxOutOfPocket(e)
                    const label = 'Maximum Out of Pocket'
                    const value = moopSliderValue
                    fireEvent('sliderInput', e, {
                      label,
                      value,
                      description: `Value of ${label} set to ${value}`,
                    })
                  }}
                  withTooltip={true}
                  tooltipContent={
                    StringConstants.TOOLTIP_MSG_MAXIMUM_OUT_OF_POCKET
                  }
                />
                <SliderContentContainer>
                  <div>{convertToUSCurrency(maPlansFilters.moop.minValue)}</div>
                  <div>{convertToUSCurrency(maPlansFilters.moop.maxValue)}</div>
                </SliderContentContainer>
              </Filter>
            )}
          </FiltersWrapper>
        </Wrapper>
      </Container>
    </FixedContainer>
  ) : null
}

export default observer(FilterAdvantage)
