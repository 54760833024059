import React from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import MuiTableCell from '@mui/material/TableCell'
import MuiTableRow from '@mui/material/TableRow'
import styled from '@emotion/styled'
import theme from '../../../global/theme'

interface CardTableProps {
  rows: { [key: string]: string }[]
}

const Container = styled.div`
  border: 1px solid ${theme.colors.borderColor};
`

const TableRow = styled(MuiTableRow)`
  &:nth-of-type(odd) {
    background-color: ${theme.colors.backgroundColorContainer};
  }
`

const TableCell = styled(MuiTableCell)`
  font-weight: 700;
  color: ${theme.colors.textGrey};
  font-size: 14px;
  &.MuiTableCell-root {
    width: 50%;
  }
`

const CardTable: React.FC<CardTableProps> = ({ rows }) => {
  return (
    <Container>
      <Table size='small'>
        <TableBody>
          {rows.map((row, index) => (
            <TableRow
              key={index}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              {Object.keys(row).map((column, index) => (
                <TableCell key={index}>{row[column]}</TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Container>
  )
}

export default CardTable
