import CryptoJS from 'crypto-js'

const secret = process.env.REACT_APP_ENCRYPTION_KEY!

export const encrypt = (message: string) => {
  return CryptoJS.AES.encrypt(message, secret).toString()
}

export const decrypt = (encryptedMessage: string) => {
  const bytes = CryptoJS.AES.decrypt(encryptedMessage, secret)
  return bytes.toString(CryptoJS.enc.Utf8)
}
