import { useLocation, useNavigate } from 'react-router-dom'

import ApiManager from '../../api/ApiManager'
import { HttpMethods } from '../../enums/HttpMethodsEnum'
import LoadingComponent from '../../components/common/Loading/Loading.component'
import RouteConstants from '../../constants/RouteConstants'
import { SnackbarTypes } from '../../enums/SnackbarTypesEnum'
import StringConstants from '../../constants/StringConstants'
import UrlConstants from '../../constants/UrlConstants'
import { getParamsFromUrl } from '../../utils/UrlUtils'
import medicareQuotes from '../../mock_data/medicareQuotes'
import snackbarStore from '../../datastore/SnackbarStore'
import { useEffect } from 'react'

interface CustomerContactDetailsDTOProps {
  fullName: string
  email: string
  phone: string
}

const ForgotPasswordReset = () => {
  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    if (location.search) {
      const token = getParamsFromUrl(
        location.search,
        StringConstants.COOKIE_TOKEN
      )
      if (token) {
        ApiManager.makeApiCall(
          UrlConstants.VALIDATE_FORGOT_PASSWORD.USECASE,
          HttpMethods.GET_METHOD,
          { $token: token }
        )
          .then((response) => {
            if (response.status === 200) {
              const customerData = response.data
                .data as CustomerContactDetailsDTOProps
              navigate(RouteConstants.RESET_PASSWORD_FORGOT, {
                state: { ...customerData, token: token },
              })
            } else throw response
          })
          .catch((err) => {
            navigate(RouteConstants.LOGIN, { replace: true })
            snackbarStore.set({
              snackbarOpen: true,
              snackbarMessage:
                err && err.message
                  ? err.message
                  : 'Invalid Token. Please check the link',
              snackbarType: SnackbarTypes.ERROR,
            })
          })
      } else {
        snackbarStore.set({
          snackbarOpen: true,
          snackbarMessage: 'Invalid Link. Please check the link',
          snackbarType: SnackbarTypes.ERROR,
        })
        navigate(RouteConstants.LOGIN, { replace: true })
      }
    } else {
      snackbarStore.set({
        snackbarOpen: true,
        snackbarMessage: 'Invalid Link. Please check the link',
        snackbarType: SnackbarTypes.ERROR,
      })
      navigate(RouteConstants.LOGIN, { replace: true })
    }
  }, [location, navigate])

  return <LoadingComponent data={medicareQuotes} />
}

export default ForgotPasswordReset
