import BottomStepNavigator from '../../BottomStepNavigator.component'
import RadioGroup from '../../../common/RadioGroup/RadioGroup.component'
import React from 'react'
import RouteConstants from '../../../../constants/RouteConstants'
import styled from '@emotion/styled'
import theme from '../../../../global/theme'
import { useNavigate } from 'react-router-dom'

interface MillimanQuestionProps {
  setStep: React.Dispatch<React.SetStateAction<number>>
  buttonLoading: boolean
  handleStepBack: () => void
}

const MillimanQuestionWrapper = styled.div`
  width: 100%;
  h3 {
    color: ${theme.colors.primary};
  }
  .subheading {
    color: ${theme.colors.textGrey};
    font-size: 14px;
    padding: 5px 0px;
  }
`

const MillimanQuestion: React.FC<MillimanQuestionProps> = ({
  setStep,
  buttonLoading,
  handleStepBack,
}) => {
  const navigate = useNavigate()
  const [useMilliman, setUseMilliman] = React.useState<boolean>(true)

  const millimanQuestionOptions = [
    {
      label:
        'Look up list of prescriptions using my Social Security Number (SSN)',
      value: true,
    },
    {
      label: 'I want to add my prescriptions in manually',
      value: false,
    },
  ]

  return (
    <MillimanQuestionWrapper>
      <h3>Add your prescription drugs</h3>
      <p className='subheading'>Please select your preferred method below.</p>
      <RadioGroup
        fontSize='14px'
        radioButtons={millimanQuestionOptions.map((option) => option.label)}
        value={
          millimanQuestionOptions.find((option) => option.value === useMilliman)
            ?.label
        }
        onChange={(e) => {
          const selection = e.target.value
          const selectedMatch = millimanQuestionOptions.find(
            (option) => option.label === selection
          )
          setUseMilliman(selectedMatch?.value || false)
        }}
      />
      <BottomStepNavigator
        handleNext={() => {
          useMilliman ? setStep(3) : setStep(6)
          window.scrollTo({ top: 0, behavior: 'smooth' })
        }}
        handleBack={handleStepBack}
        handleViewPlans={() => {
          window.scrollTo({ top: 0, behavior: 'smooth' })
          navigate(RouteConstants.DASHBOARD)
        }}
        isLoading={buttonLoading}
        currentInfoStep='Prescriptions'
      />
    </MillimanQuestionWrapper>
  )
}

export default MillimanQuestion
