import {
  AiFillHeart as FilledHeart,
  AiOutlineHeart as OutlinedHeart,
} from 'react-icons/ai'
import React, { useState } from 'react'

import ApiManager from '../../../api/ApiManager'
import { Checkbox } from '@mui/material'
import { FaHourglassHalf } from 'react-icons/fa'
import { HttpMethods } from '../../../enums/HttpMethodsEnum'
import { MedicareQuotesType } from '../../../enums/MedicareQuoteTypeEnum'
import StringConstants from '../../../constants/StringConstants'
import UrlConstants from '../../../constants/UrlConstants'
import customerStore from '../../../datastore/CustomerStore'
import { getApiErrorMessage } from '../../../utils/StringUtils'
import snackbarStore from '../../../datastore/SnackbarStore'
import theme from '../../../global/theme'
import { fireEvent } from '../../../cra'

interface SavePlanIconButtonProps {
  planId: string
  planType: MedicareQuotesType
  saved: boolean
  onAddSaved: (planId: string) => void
  onRemoveSaved: (planId: string) => void
}

const SavePlanIconButton: React.FC<SavePlanIconButtonProps> = (
  planInfo: SavePlanIconButtonProps
) => {
  const [loading, setLoading] = useState<boolean>(false)

  const savePlanAction = (e: any) => {
    fireEvent('click', e, {
      label: 'Save Plan',
      description: 'Dashboard Card - Save Plan',
    })
    setLoading(true)
    if (!planInfo.saved) {
      ApiManager.makeApiCallWithAuthentication(
        UrlConstants.SAVE_PLAN.USECASE,
        HttpMethods.POST_METHOD,
        {
          planId: planInfo.planId,
          medicareQuoteType: planInfo.planType,
        }
      )
        .then((response) => {
          if (response.status === 200) {
            snackbarStore.set({
              snackbarOpen: true,
              snackbarMessage: StringConstants.SNACKBAR_SAVE_SUCCESS_MSG,
              snackbarType: 'success',
            })
            planInfo.onAddSaved(planInfo.planId)
            customerStore.setSavePlanStatusObject({
              planId: planInfo.planId,
              planType: planInfo.planType,
              status: true,
            })
            fireEvent('savePlanSuccessful', e, {
              planId: planInfo.planId,
              planType: planInfo.planType,
              label: 'Save Plan',
              description: 'Dashboard Card - Saving Plan Successful',
              value: 'Successful',
            })
          } else throw response
        })
        .catch((err) => {
          if (err.data.message === 'Maximum limit reached for saving plans') {
            snackbarStore.set({
              snackbarOpen: true,
              snackbarMessage: getApiErrorMessage(
                'save plan. You can save only upto 5 plans'
              ),
              snackbarType: 'error',
            })
            fireEvent('savePlanFailed', e, {
              planId: planInfo.planId,
              planType: planInfo.planType,
              label: 'Save Plan',
              value: 'Failed',
              description:
                'Dashboard Card - Saving Plan Failed - Exceeding Max Number of Saved Plans',
            })
          } else {
            snackbarStore.set({
              snackbarOpen: true,
              snackbarMessage: getApiErrorMessage('save plan'),
              snackbarType: 'error',
            })
            fireEvent('savePlanFailed', e, {
              planId: planInfo.planId,
              planType: planInfo.planType,
              label: 'Save Plan',
              value: 'Failed',
              description: 'Dashboard Card - Saving Plan Failed',
            })
          }
        })
        .finally(() => setLoading(false))
    } else {
      ApiManager.makeApiCallWithAuthentication(
        UrlConstants.DELETE_SAVED_PLAN.USECASE,
        HttpMethods.DELETE_METHOD,
        {
          $planId: planInfo.planId,
          'quote-type': planInfo.planType,
        }
      )
        .then((response) => {
          if (response.status === 200) {
            snackbarStore.set({
              snackbarOpen: true,
              snackbarMessage: 'Plan unsaved successfully',
              snackbarType: 'success',
            })
            planInfo.onRemoveSaved(planInfo.planId)
            customerStore.setSavePlanStatusObject({
              planId: planInfo.planId,
              planType: planInfo.planType,
              status: false,
            })
            fireEvent('removeSavedPlanSuccessful', e, {
              planId: planInfo.planId,
              planType: planInfo.planType,
              label: 'Save Plan',
              description: 'Dashboard Card - Removing Saved Plan Successful',
              value: 'Successful',
            })
          } else throw response
        })
        .catch((err) => {
          snackbarStore.set({
            snackbarOpen: true,
            snackbarMessage: getApiErrorMessage('delete plan'),
            snackbarType: 'error',
          })
          fireEvent('removeSavedPlanFailed', e, {
            planId: planInfo.planId,
            planType: planInfo.planType,
            label: 'Save Plan',
            value: 'Failed',
            description: 'Dashboard Card - Removing Saved Plan Successful',
          })
        })
        .finally(() => setLoading(false))
    }
  }

  return (
    <div>
      <>
        <Checkbox
          icon={
            !loading ? (
              <OutlinedHeart
                style={{
                  fontSize: '18px',
                  color: `${theme.colors.secondary}`,
                }}
              />
            ) : (
              <FaHourglassHalf
                style={{
                  fontSize: '18px',
                }}
              />
            )
          }
          checkedIcon={
            !loading ? (
              <FilledHeart
                style={{
                  fontSize: '18px',
                  color: `${theme.colors.secondary}`,
                }}
              />
            ) : (
              <FaHourglassHalf
                style={{
                  fontSize: '18px',
                }}
              />
            )
          }
          checked={planInfo.saved}
          onChange={savePlanAction}
          disabled={loading}
        />
        <span>{planInfo.saved ? 'Saved Plan' : 'Save Plan'}</span>
      </>
    </div>
  )
}

export default SavePlanIconButton
