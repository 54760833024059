import React, { useEffect, useState } from 'react'

import { FaChevronUp } from 'react-icons/fa'
import ScrollToTop from 'react-scroll-to-top'
import customerStore from '../../datastore/CustomerStore'
import { fireEvent } from '../../cra'
import styled from '@emotion/styled'
import theme from '../../global/theme'

interface ScrollToTopButtonProps {
  bottom?: string
  right?: string
}

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${theme.colors.white};
  width: 100%;
  height: 100%;
  border-radius: 4px;
  transition: all 0.2s;
  border: solid 2px ${theme.colors.primary};

  &:hover {
    transform: scale(1.1);
  }
`

const ScrollToTopButton: React.FC<ScrollToTopButtonProps> = ({
  bottom,
  right,
}) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 600)

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 600)
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <ScrollToTop
      component={
        <Container
          onClick={(e) => {
            fireEvent('click', e, {
              label: 'Scroll to Top',
              description:
                'Scroll to Top Button on the bottom right of Quoting Tool',
            })
          }}
        >
          <FaChevronUp size='1.25rem' color={theme.colors.primary} />
        </Container>
      }
      smooth
      style={{
        bottom: bottom ? bottom : '180px',
        right: right ? right : '10px',
        display:
          isMobile && customerStore.isComparePlansCarouselOpen
            ? 'none'
            : 'block',
      }}
      top={400}
    />
  )
}

export default ScrollToTopButton
