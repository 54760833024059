import {
  BenefitSection,
  PackageBenefit,
} from '../../interface/quotes/mNonSupplementPlans/common/PackageBenefit'
import { Rating, Table, TableBody, TableHead } from '@mui/material'
import React, { useEffect, useState } from 'react'
import {
  convertCamelCaseToString,
  convertSnakeCaseToHeading,
  titleCase,
} from '../../utils/StringUtils'
import { isEmpty, startCase } from 'lodash'

import { AbstractBenefits } from '../../interface/quotes/mNonSupplementPlans/common/AbstractBenefits'
import CardTable from '../DashboardPage/Common/CardTable.component'
import { DrugTierCost } from '../../interface/quotes/mNonSupplementPlans/common/DrugTierCost'
import InPageHeader from '../common/InPageHeader/InPageHeader.component'
import { Link } from 'react-router-dom'
import { MSPlanGenericDisplayDetail } from '../../interface/common/MSPlanGenericDisplayDetail'
import { MSPlansRiderType } from '../../interface/quotes/mSupplementPlans/MSPlansRiderType'
import { MedicareQuotesType } from '../../enums/MedicareQuoteTypeEnum'
import MuiTableCell from '@mui/material/TableCell'
import MuiTableRow from '@mui/material/TableRow'
import { PrescriptionDrugCoverage } from '../../interface/prescriptionDrug/PrescriptionDrug'
import ReactVisibilitySensor from 'react-visibility-sensor'
import { RiderTypesEnum } from '../../enums/RiderTypesEnum'
import { convertToUSCurrency } from '../../utils/CurrencyConverterUtil'
import styled from '@emotion/styled'
import theme from '../../global/theme'

const mccpdc =
  process.env.REACT_APP_CDN_BASE_URL +
  '/carriers/cost-plus-drug-company-logo.png'

const Container = styled.div`
  @media print {
    @page {
      margin-top: 20mm;
      margin-bottom: 20mm;
      page-break-inside: avoid;
      page-break-after: avoid;
    }
  }
`

const SectionWrapper = styled.div`
  @media print {
    page-break-before: avoid;
    page-break-after: avoid;
    &:nth-of-type(1) {
      margin-top: 30mm;
    }
    h3 {
      page-break-before: avoid;
      page-break-after: avoid;
    }
  }
  border: 1px solid ${theme.colors.borderColor};
  margin-bottom: 30px;
  h3 {
    background-color: ${theme.colors.primary};
    color: ${theme.colors.textLight};
    padding: 15px 16px;
  }
`

const TableRow = styled(MuiTableRow)`
  &:nth-of-type(odd) {
    background-color: ${theme.colors.backgroundColorContainer};
  }
  @media print {
    page-break-before: avoid;
    page-break-after: avoid;
    page-break-inside: avoid;
  }
`

const TableCell = styled(MuiTableCell)`
  align-content: start;
  &.MuiTableCell-root {
    width: ${(props) => (props.width ? props.width : '50%')};
  }
  @media print {
    page-break-before: avoid;
    page-break-after: avoid;
  }
`

interface TableRecordType {
  key: string
  value: string
}

/**
 * Renders network cost value
 * Adds line break if <br /> is present in the given value
 * @param value
 */
const renderNetworkCostValue = (value: any) => {
  const formattedValue =
    value === 'false' || value === false
      ? 'No'
      : value === 'true' || value === true
      ? 'Yes'
      : value

  if (value.toString().includes('<br />')) {
    return formattedValue
      .toString()
      .split('<br />')
      .map((eachLine: string) => <p>{eachLine.replace('_', '')}</p>)
  } else {
    const startCaseWord = formattedValue
      .toString()
      .split('_')
      .map((itr: string) => {
        if (itr.charAt(0).match(/[a-z]/)) {
          return itr[0].toUpperCase() + itr.substr(1)
        }
        return itr
      })
      .join(' ')
    return startCaseWord
  }
}

const DetailSection: React.FC<{
  overview: any
  planBenefitsTopics: string[]
  planBenefits: PackageBenefit[] | undefined
  msPlanGenericDetails: MSPlanGenericDisplayDetail | undefined
  abstractDetails: AbstractBenefits[] | undefined
  prescriptionDrugCoverages: PrescriptionDrugCoverage[] | undefined
  setCurrentTabTop: React.Dispatch<React.SetStateAction<string>>
  setCurrentTabBottom: React.Dispatch<React.SetStateAction<string | undefined>>
  categories: Map<string, string[]>
  planType: MedicareQuotesType
  riders?: MSPlansRiderType[]
}> = ({
  overview,
  planBenefits,
  msPlanGenericDetails,
  abstractDetails,
  prescriptionDrugCoverages,
  setCurrentTabTop,
  planType,
  riders,
}) => {
  const [overviewTableRecords, setOverviewTableRecords] = useState<
    TableRecordType[]
  >([])
  const [packageBenefits, setPackageBenefits] = useState<BenefitSection[]>([])
  const [prescriptionDrugBenefits, setPrescriptionDrugBenefits] = useState<any>(
    []
  )
  const [currentTab, setCurrentTab] = React.useState<any>(0)
  const [valueType, setValueType] = React.useState<string>('30')
  const ridersEntries = Object.entries(RiderTypesEnum)

  useEffect(() => {
    const tempOverviewTableRecords: { key: string; value: string }[] = []
    if (overview.monthlyPremium) {
      tempOverviewTableRecords.push({
        key: 'Monthly Premium',
        value: convertToUSCurrency(overview.monthlyPremium),
      })
    }
    if (overview.fmtPremium) {
      tempOverviewTableRecords.push({
        key: 'Monthly plan premium',
        value: overview.fmtPremium,
      })
    }
    if (overview.fmtMedicalDeductible) {
      tempOverviewTableRecords.push({
        key: 'Medical deductible',
        value: overview.fmtMedicalDeductible,
      })
    }
    if (overview.oonMoop) {
      tempOverviewTableRecords.push({
        key: 'Out-of-network maximum out-of-pocket',
        value:
          overview.oonMoop === -1
            ? 'N/A'
            : convertToUSCurrency(overview.oonMoop),
      })
    }

    if (overview.moop) {
      tempOverviewTableRecords.push({
        key: 'In-network maximum out-of-pocket',
        value:
          overview.moop === -1 ? 'N/A' : convertToUSCurrency(overview.moop),
      })
    }

    if (overview.combMoop) {
      tempOverviewTableRecords.push({
        key: 'Combined maximum out-of-pocket',
        value:
          overview.combMoop === -1
            ? 'N/A'
            : convertToUSCurrency(overview.combMoop),
      })
    }

    if (overview.fmtDeductible) {
      tempOverviewTableRecords.push({
        key: 'Drug deductible',
        value: overview.fmtDeductible,
      })
    }

    if (overview.initialCoverageLimit) {
      tempOverviewTableRecords.push({
        key: 'Initial coverage limit',
        value: convertToUSCurrency(overview.initialCoverageLimit),
      })
    }

    if (overview.catastrophicLimit) {
      tempOverviewTableRecords.push({
        key: 'Catastrophic coverage limit',
        value: convertToUSCurrency(overview.catastrophicLimit),
      })
    }

    setOverviewTableRecords(tempOverviewTableRecords)
  }, [overview])

  useEffect(() => {
    window.scroll(0, 0)
    setCurrentTabTop('Overview')
  }, [])

  const handleChangeDaySupply = (e: any, newValue: number) => {
    setCurrentTab(newValue)
    newValue === 0
      ? setValueType('30')
      : newValue === 1
      ? setValueType('60')
      : setValueType('90')
  }

  useEffect(() => {
    if (planBenefits) {
      let sortedPlanBenefits: BenefitSection[] = []
      planBenefits.sort((a: any, b: any) => {
        if (a.type < b.type) {
          return -1
        }
        if (a.type > b.type) {
          return 1
        }
        return 0
      })

      const tiers = ['Tier 1', 'Tier 2', 'Tier 3', 'Tier 4', 'Tier 5']
      const combinedObject: any = []

      const getCoverageType = (benefitName: any) => {
        return benefitName.match(/(?:\w+) Coverage Level - (.+)/)
      }

      const names = Array.from(
        new Set(
          planBenefits.map((item: any) => {
            const coverageType = getCoverageType(item.name)
            return coverageType ? coverageType[1] : null
          })
        )
      ).filter(Boolean)

      names.forEach((name: string) => {
        combinedObject[name] = {}

        tiers.forEach((tier: string) => {
          combinedObject[name][tier] = []
          planBenefits.forEach((originalObject: any) => {
            const coverageType = getCoverageType(originalObject.name)
            const typeName = coverageType ? coverageType[1] : null
            const typeTempName = coverageType ? coverageType[0] : null

            if (typeName === name) {
              const tierBenefits = originalObject.benefits.filter(
                (benefit: any) => benefit.categoryName === tier
              )

              if (tierBenefits.length > 0) {
                combinedObject[name][tier].push({
                  name: typeTempName,
                  type: originalObject.type,
                  benefits: tierBenefits,
                })
              }
            }
          })
        })
      })

      let DrugBenefitsArray = planBenefits.filter(
        (item: any) => item.type === 'drug-cost-sharing'
      )
      let transformedDrugBenefitsArray: any = []

      DrugBenefitsArray.forEach((item: any) => {
        let nameSplit = item.name.split(' - ')
        let drugBenefitName = nameSplit[0]
        let subname = nameSplit[1]

        let existingDrugBenefitName = transformedDrugBenefitsArray.find(
          (obj: any) => obj.name === drugBenefitName
        )
        if (!existingDrugBenefitName) {
          existingDrugBenefitName = {
            name: drugBenefitName,
            tiers: [],
          }
          transformedDrugBenefitsArray.push(existingDrugBenefitName)
        }

        item.benefits.forEach((benefit: any) => {
          let existingTier = existingDrugBenefitName.tiers.find(
            (tier: any) => tier.name === benefit.categoryName
          )
          if (!existingTier) {
            existingTier = {
              name: benefit.categoryName,
              categoryDesc: benefit.categoryDesc,
              subnames: [],
            }
            existingDrugBenefitName.tiers.push(existingTier)
          }

          let existingDrugBenefitSubname = existingTier.subnames.find(
            (sub: any) => sub.name === subname
          )
          if (!existingDrugBenefitSubname) {
            existingDrugBenefitSubname = {
              name: subname,
              benefits: [],
            }
            existingTier.subnames.push(existingDrugBenefitSubname)
          }

          existingDrugBenefitSubname.benefits.push(benefit)
        })
        existingDrugBenefitName.tiers.sort((a: any, b: any) => {
          const tierA = parseInt(a.name.replace('Tier ', ''))
          const tierB = parseInt(b.name.replace('Tier ', ''))
          return tierA - tierB
        })
      })

      planBenefits.forEach((planBenefit: any) => {
        if (planBenefit.type === 'benefit') {
          sortedPlanBenefits.push(planBenefit)
        }
      })

      setPackageBenefits(sortedPlanBenefits)
      setPrescriptionDrugBenefits(transformedDrugBenefitsArray)
    }
  }, [planBenefits])

  const drugsSection: JSX.Element = (
    <SectionWrapper key={'Drugs'} id={'Drugs'}>
      <ReactVisibilitySensor partialVisibility={false}>
        <>
          <h3>Drugs</h3>
          <Table>
            <TableBody>
              <TableRow>
                {prescriptionDrugCoverages &&
                  prescriptionDrugCoverages?.length > 0 && (
                    <>
                      <Table key={Math.random()}>
                        <TableRow
                          sx={{
                            '&:last-child td, &:last-child th': {
                              border: 0,
                            },
                          }}
                        >
                          <TableCell
                            style={{ textTransform: 'capitalize' }}
                            sx={{
                              backgroundColor: theme.colors.contrastPrimary,
                            }}
                            colSpan={3}
                          >
                            My Drugs{' '}
                            <Link to='/profile/myprofile/prescriptions'>
                              {' '}
                              + Add or Modify Drugs
                            </Link>
                          </TableCell>
                        </TableRow>
                        <TableBody>
                          {prescriptionDrugCoverages?.map(
                            (prescriptionDrugCoverage, index) => (
                              <TableRow
                                sx={{
                                  '&:last-child td, &:last-child th': {
                                    border: 0,

                                    background: '#fffff',
                                  },
                                }}
                                key={index * Math.random()}
                              >
                                <TableCell
                                  width='33.33%'
                                  sx={{
                                    'td, th': {
                                      border: 0,

                                      background: '#fffff',
                                    },
                                  }}
                                >
                                  <TableCell>
                                    {startCase(prescriptionDrugCoverage.name)}
                                  </TableCell>
                                </TableCell>

                                <TableCell
                                  width='33.33%'
                                  sx={{
                                    'td, th': {
                                      border: 0,

                                      background: '#fffff',
                                    },
                                  }}
                                >
                                  <TableCell>
                                    {prescriptionDrugCoverage.covered
                                      ? 'Covered'
                                      : 'Not Covered'}
                                  </TableCell>
                                </TableCell>

                                <TableCell
                                  width='33.33%'
                                  sx={{
                                    'td, th': {
                                      border: 0,

                                      background: '#fffff',
                                    },
                                  }}
                                >
                                  <TableCell style={{ textAlign: 'center' }}>
                                    <p
                                      style={{ padding: '0px', margin: '5px' }}
                                    >
                                      {prescriptionDrugCoverage.pharmacyName ===
                                      'MAIL_ORDER_PHARMACY'
                                        ? 'Mail Order Pharmacy'
                                        : convertSnakeCaseToHeading(
                                            prescriptionDrugCoverage.pharmacyName.toLowerCase()
                                          )}
                                    </p>
                                    <p
                                      style={{
                                        padding: '0px',
                                        fontWeight: '500',
                                      }}
                                    >
                                      {convertSnakeCaseToHeading(
                                        isEmpty(
                                          prescriptionDrugCoverage.pharmacyAddress
                                        )
                                          ? ''
                                          : prescriptionDrugCoverage.pharmacyAddress
                                      )}
                                    </p>
                                  </TableCell>
                                </TableCell>
                              </TableRow>
                            )
                          )}
                        </TableBody>
                      </Table>
                      <Table key={Math.random()}>
                        <TableRow
                          sx={{
                            '&:last-child td, &:last-child th': {
                              border: 0,
                            },
                          }}
                        >
                          <TableCell
                            style={{ textTransform: 'capitalize' }}
                            sx={{
                              backgroundColor: theme.colors.contrastPrimary,
                            }}
                            colSpan={4}
                          >
                            Drug Prices (for the rest of the year){' '}
                          </TableCell>
                        </TableRow>
                        <TableBody>
                          <TableRow>
                            <TableCell
                              width='25%'
                              style={{ paddingLeft: '2rem' }}
                            >
                              Drug Name
                            </TableCell>
                            {/* <TableCell
                              width='25%'
                              style={{ textAlign: 'center' }}
                            >
                              <div>
                                Cost Plus Price
                                <a
                                  href='https://costplusdrugs.com/?utm_source=eip&utm_medium=eip'
                                  target='_blank'
                                  rel='noopener noreferrer'
                                >
                                  <img
                                    style={{
                                      width: '90px',
                                      verticalAlign: 'middle',
                                      marginLeft: '20px',
                                    }}
                                    src={mccpdc}
                                    alt={'cost-plus'}
                                  />
                                </a>
                              </div>
                            </TableCell> */}
                            <TableCell
                              width='25%'
                              style={{ textAlign: 'center' }}
                            >
                              Drug Price
                            </TableCell>
                            <TableCell
                              width='25%'
                              style={{ textAlign: 'center' }}
                            >
                              Pharmacy Name
                            </TableCell>
                          </TableRow>
                          {prescriptionDrugCoverages?.map(
                            (prescriptionDrugCoverage, index) => (
                              <TableRow
                                sx={{
                                  '&:last-child td, &:last-child th': {
                                    border: 0,
                                    background: '#fffff',
                                  },
                                }}
                                key={index * Math.random()}
                              >
                                <TableCell
                                  width='25%'
                                  style={{ paddingLeft: '2rem' }}
                                >
                                  {convertCamelCaseToString(
                                    prescriptionDrugCoverage.name
                                  )}
                                </TableCell>
                                {/* <TableCell
                                  width='25%'
                                  style={{ textAlign: 'center' }}
                                >
                                  {
                                    prescriptionDrugCoverage.costPlusDrugUnitPrice
                                  }
                                </TableCell> */}
                                <TableCell
                                  width='25%'
                                  style={{ textAlign: 'center' }}
                                >
                                  {prescriptionDrugCoverage.drugUnitPrice}
                                </TableCell>
                                <TableCell
                                  width='25%'
                                  style={{ textAlign: 'center' }}
                                >
                                  <p style={{ padding: '0px', margin: '5px' }}>
                                    {prescriptionDrugCoverage.pharmacyName ===
                                    'MAIL_ORDER_PHARMACY'
                                      ? 'Mail Order Pharmacy'
                                      : convertSnakeCaseToHeading(
                                          prescriptionDrugCoverage.pharmacyName.toLowerCase()
                                        )}
                                  </p>
                                  <p
                                    style={{
                                      padding: '0px',
                                      fontWeight: '500',
                                    }}
                                  >
                                    {convertSnakeCaseToHeading(
                                      isEmpty(
                                        prescriptionDrugCoverage.pharmacyAddress
                                      )
                                        ? ''
                                        : prescriptionDrugCoverage.pharmacyAddress
                                    )}
                                  </p>
                                </TableCell>
                              </TableRow>
                            )
                          )}
                        </TableBody>
                      </Table>
                    </>
                  )}
                {abstractDetails &&
                  abstractDetails.map((benefit, index) => (
                    <Table key={index * Math.random()}>
                      <TableRow
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                        }}
                      >
                        <TableCell
                          style={{ textTransform: 'capitalize' }}
                          sx={{
                            backgroundColor: theme.colors.contrastPrimary,
                          }}
                        >
                          {convertSnakeCaseToHeading(benefit.benefitName)}
                        </TableCell>
                      </TableRow>

                      {benefit.tiers.map((tier, index2) => (
                        <TableRow
                          key={index2 * Math.random()}
                          sx={{
                            '&:last-child td, &:last-child th': {
                              border: 0,
                              background: '#fffff',
                            },
                          }}
                        >
                          <TableCell>
                            {convertSnakeCaseToHeading(
                              tier.label.split('COST_SHARE_TIER_')[1]
                            )}
                            <>
                              <Table
                                style={{
                                  background: '#ffffff',
                                  marginTop: '0.5rem',
                                }}
                              >
                                <TableBody
                                  style={{
                                    border: 'solid',
                                    borderColor: theme.colors.primary,
                                  }}
                                >
                                  {tier.general !== '' && (
                                    <TableRow>
                                      <TableCell>General</TableCell>
                                      <TableCell>
                                        {tier.general
                                          .replaceAll('<br />', ' ')
                                          .replaceAll('<br/>', ' ')
                                          .replaceAll(';', '')}
                                      </TableCell>
                                    </TableRow>
                                  )}
                                  {!isEmpty(tier.standardRetail) &&
                                    Object.keys(tier.standardRetail).map(
                                      (days, index3) => (
                                        <TableRow key={index3 * Math.random()}>
                                          <TableCell>
                                            {convertCamelCaseToString(days)}
                                          </TableCell>
                                          <TableCell>
                                            {tier.standardRetail[
                                              days as keyof DrugTierCost
                                            ] !== ''
                                              ? `${
                                                  tier.standardRetail[
                                                    days as keyof DrugTierCost
                                                  ]
                                                } for ${
                                                  days.split('days')[1]
                                                } days`
                                              : '-'}
                                          </TableCell>
                                        </TableRow>
                                      )
                                    )}
                                  {!isEmpty(tier.preferredRetail) &&
                                    Object.keys(tier.preferredRetail).map(
                                      (days, index3) => (
                                        <TableRow key={index3 * Math.random()}>
                                          <TableCell>
                                            Preferred Retail (
                                            {convertCamelCaseToString(days)})
                                          </TableCell>
                                          <TableCell>
                                            {tier.preferredRetail[
                                              days as keyof DrugTierCost
                                            ] !== ''
                                              ? `${
                                                  tier.preferredRetail[
                                                    days as keyof DrugTierCost
                                                  ]
                                                } for ${
                                                  days.split('days')[1]
                                                } days`
                                              : '-'}
                                          </TableCell>
                                        </TableRow>
                                      )
                                    )}
                                  {!isEmpty(tier.standardMailOrder) &&
                                    Object.keys(tier.standardMailOrder).map(
                                      (days, index3) => (
                                        <TableRow key={index3 * Math.random()}>
                                          <TableCell>
                                            Standard Mail Order (
                                            {convertCamelCaseToString(days)})
                                          </TableCell>
                                          <TableCell>
                                            {tier.standardMailOrder[
                                              days as keyof DrugTierCost
                                            ] !== ''
                                              ? `${
                                                  tier.standardMailOrder[
                                                    days as keyof DrugTierCost
                                                  ]
                                                } for ${
                                                  days.split('days')[1]
                                                } days`
                                              : '-'}
                                          </TableCell>
                                        </TableRow>
                                      )
                                    )}
                                  {!isEmpty(tier.preferredMailOrder) &&
                                    Object.keys(tier.preferredMailOrder).map(
                                      (days, index3) => (
                                        <TableRow key={index3 * Math.random()}>
                                          <TableCell>
                                            Preferred Mail Order (
                                            {convertCamelCaseToString(days)})
                                          </TableCell>
                                          <TableCell>
                                            {tier.preferredMailOrder[
                                              days as keyof DrugTierCost
                                            ] !== ''
                                              ? `${
                                                  tier.preferredMailOrder[
                                                    days as keyof DrugTierCost
                                                  ]
                                                } for ${
                                                  days.split('days')[1]
                                                } Days`
                                              : '-'}
                                          </TableCell>
                                        </TableRow>
                                      )
                                    )}
                                </TableBody>
                              </Table>
                            </>
                          </TableCell>
                        </TableRow>
                      ))}
                    </Table>
                  ))}
              </TableRow>
            </TableBody>
          </Table>
        </>
      </ReactVisibilitySensor>
    </SectionWrapper>
  )

  return (
    <Container>
      <SectionWrapper key={'Overview'} id={'Overview'}>
        <ReactVisibilitySensor partialVisibility={false}>
          <>
            <h3>Overview</h3>
            <Table>
              {overviewTableRecords.map((row, index) => (
                <TableRow
                  key={'overview' + index}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell key={'key' + index}>{row['key']}</TableCell>
                  <TableCell key={'value' + index}>
                    {row['key'] === 'Overall Stars' ? (
                      <Rating
                        name='simple-controlled'
                        value={Number(row.value)}
                        precision={0.5}
                        readOnly
                      />
                    ) : (
                      row['value']
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </Table>
          </>
        </ReactVisibilitySensor>
      </SectionWrapper>

      {planType === MedicareQuotesType.MEDICARE_SUPPLEMENT &&
        riders &&
        riders.length > 0 && (
          <SectionWrapper key={'Riders'} id={'Riders'}>
            <ReactVisibilitySensor partialVisibility={false}>
              <>
                <h3>Riders</h3>
                <Table>
                  <TableHead>
                    <MuiTableRow>
                      <MuiTableCell
                        sx={{
                          fontWeight: 700,
                          color: `${theme.colors.primary}`,
                          fontSize: '14px',
                          '&.MuiTableCell-root': {
                            width: '50%',
                          },
                        }}
                      >
                        Type
                      </MuiTableCell>
                      <MuiTableCell
                        sx={{
                          backgroundColor: `${theme.colors.backgroundColorContainerDark}`,
                          fontWeight: 700,
                          color: `${theme.colors.primary}`,
                          fontSize: '14px',
                          '&.MuiTableCell-root': {
                            width: '50%',
                          },
                        }}
                      >
                        Annual Cost
                      </MuiTableCell>
                    </MuiTableRow>
                  </TableHead>
                  {riders
                    .sort((a, b) => {
                      const name1 =
                        ridersEntries.find(
                          ([key, val]) => key === a.name
                        )?.[1] || a.name
                      const name2 =
                        ridersEntries.find(
                          ([key, val]) => key === b.name
                        )?.[1] || b.name
                      return name1.localeCompare(name2)
                    })
                    .map((row, index) => (
                      <MuiTableRow
                        key={'rider' + index}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                        }}
                      >
                        <MuiTableCell
                          key={'name' + index}
                          sx={{
                            fontWeight: 700,
                            color: `${theme.colors.textGrey}`,
                            fontSize: '14px',
                            '&.MuiTableCell-root': {
                              width: '50%',
                            },
                          }}
                        >
                          {ridersEntries.find(
                            ([key, val]) => key === row['name']
                          )?.[1] || row['name']}
                        </MuiTableCell>
                        <MuiTableCell
                          key={'annualCost' + index}
                          sx={{
                            backgroundColor: `${theme.colors.backgroundColorContainer}`,
                            fontWeight: 700,
                            color: `${theme.colors.textGrey}`,
                            fontSize: '14px',
                            '&.MuiTableCell-root': {
                              width: '50%',
                            },
                          }}
                        >
                          {convertToUSCurrency(row['annual'])}
                        </MuiTableCell>
                      </MuiTableRow>
                    ))}
                </Table>
              </>
            </ReactVisibilitySensor>
          </SectionWrapper>
        )}

      {planType === MedicareQuotesType.MEDICARE_PRESCRIPTION &&
        abstractDetails &&
        drugsSection}

      {packageBenefits.map((planBenefit, index) => {
        const planBenefitsElements = (
          <SectionWrapper
            id={startCase(planBenefit.type.replace('_', ' ').toLowerCase())}
            key={startCase(planBenefit.type.replace('_', ' ').toLowerCase())}
          >
            <ReactVisibilitySensor partialVisibility={false}>
              <>
                <h3>{planBenefit.name}</h3>
                <Table>
                  {planBenefit.benefits.length > 0 && (
                    <>
                      <TableRow
                        key={index}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                        }}
                      >
                        <TableCell
                          style={{
                            textTransform: 'capitalize',
                            fontWeight: 'bold',
                          }}
                        >
                          Services
                        </TableCell>
                      </TableRow>
                      {planBenefit.benefits.map((benefit) => (
                        <TableRow
                          key={'benefits'}
                          sx={{
                            '&:last-child td, &:last-child th': {
                              border: 0,
                              background: theme.colors.white,
                            },
                          }}
                        >
                          {benefit.value !== 'N/A' && (
                            <>
                              <Table
                                style={{
                                  background: theme.colors.white,
                                  marginTop: '0.5rem',
                                }}
                              >
                                <TableBody>
                                  <TableRow>
                                    <TableCell>
                                      {benefit.categoryName}
                                    </TableCell>
                                    <TableCell
                                      dangerouslySetInnerHTML={{
                                        __html: benefit.value,
                                      }}
                                    />
                                  </TableRow>
                                </TableBody>
                              </Table>
                            </>
                          )}
                        </TableRow>
                      ))}
                    </>
                  )}
                </Table>
              </>
            </ReactVisibilitySensor>
          </SectionWrapper>
        )
        return planBenefitsElements
      })}

      {prescriptionDrugBenefits.length > 0 && (
        <InPageHeader
          onChange={handleChangeDaySupply}
          currentTab={currentTab}
          showBackButton={false}
          tabLabels={[
            {
              heading: '30 Days Supply',
            },
            { heading: '60 Days Supply' },
            { heading: '90 Days Supply' },
          ]}
          justifyContent='right'
          bgColor='white'
        />
      )}

      {prescriptionDrugBenefits.length > 0 &&
        prescriptionDrugBenefits.map((prescriptionBenefit: any, index: any) => {
          const planBenefitsElements = (
            <SectionWrapper style={{ marginTop: '20px' }}>
              <ReactVisibilitySensor partialVisibility={false}>
                <>
                  <h3>{prescriptionBenefit.name}</h3>
                  <Table>
                    {prescriptionBenefit.tiers.length > 0 && (
                      <>
                        {prescriptionBenefit.tiers.map((tier: any) => {
                          return (
                            <>
                              <TableRow
                                key={index}
                                sx={{
                                  '&:last-child td, &:last-child th': {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell
                                  style={{
                                    textTransform: 'capitalize',
                                    fontWeight: 'bold',
                                  }}
                                >
                                  {tier.name}
                                </TableCell>
                                <TableCell
                                  style={{
                                    textTransform: 'capitalize',
                                    fontWeight: 'bold',
                                  }}
                                >
                                  {tier.categoryDesc}
                                </TableCell>
                              </TableRow>
                              {tier.subnames.map((subname: any) => (
                                <TableRow
                                  key={'benefits'}
                                  sx={{
                                    '&:last-child td, &:last-child th': {
                                      border: 0,
                                      background: theme.colors.white,
                                    },
                                  }}
                                >
                                  <TableCell
                                    style={{ textTransform: 'capitalize' }}
                                  >
                                    {subname.name}
                                  </TableCell>
                                  <TableCell
                                    style={{ textTransform: 'capitalize' }}
                                  >
                                    {
                                      subname.benefits.find(
                                        (item: any) =>
                                          item.valueType === valueType
                                      ).value
                                    }
                                  </TableCell>
                                </TableRow>
                              ))}
                            </>
                          )
                        })}
                      </>
                    )}
                  </Table>
                </>
              </ReactVisibilitySensor>
            </SectionWrapper>
          )
          return planBenefitsElements
        })}

      {msPlanGenericDetails && (
        <SectionWrapper id={'Plan Details'} key={'Plan Details'}>
          <ReactVisibilitySensor partialVisibility={false}>
            <>
              <h3>Plan Details</h3>
              <Table>
                <TableBody>
                  <TableRow>
                    {msPlanGenericDetails.planDetail.map(
                      (planDetails, index) => (
                        <TableRow
                          key={index}
                          sx={{
                            '&:last-child td, &:last-child th': { border: 0 },
                          }}
                        >
                          <Table>
                            <TableHead>
                              <TableRow>
                                <div
                                  style={{
                                    color: theme.colors.primary,
                                    backgroundColor:
                                      theme.colors.contrastPrimary,
                                    fontWeight: '700',
                                    padding: '15px',
                                  }}
                                >
                                  {planDetails.partType}
                                </div>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              <CardTable rows={planDetails.partTypeDetails} />
                            </TableBody>
                          </Table>
                        </TableRow>
                      )
                    )}
                  </TableRow>
                </TableBody>
              </Table>
            </>
          </ReactVisibilitySensor>
        </SectionWrapper>
      )}
    </Container>
  )
}

export default DetailSection
