import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import { useEffect, useState } from 'react'

import LazyLoadImageFromUrl from './LazyLoadImageFromUrl'
import Slider from 'react-slick'
import { ThreeDots } from 'react-loader-spinner'
import styled from '@emotion/styled'

interface LoaderProps {
  message?: string
  contents?: string[]
  leadBrand?: string
}

const Container = styled.div`
  margin: auto;
  width: 45%;
  height: 50%;
  margin-top: 8%;

  @media screen and (max-width: 750px) {
    width: 320px;
  }

  @media screen and (max-width: 380px) {
    width: 290px;
  }

  @media screen and (max-width: 280px) {
    width: 230px;
    height: 300px;
  }

  .slick-prev: before {
    color: black !important;
  }

  .slick-next: before {
    color: black !important;
  }
`

const SliderContainer = styled.div`
  color: #222c69;
  text-align: center;
  padding-top: 20px;
  padding-bottom: 40px;
`
const SliderHeading = styled.div`
  color: #222c69;
  text-align: center;
  padding-top: 20px;
`
const TextContainer = styled.div`
  line-height: 1.5;
  text-align: center;
  padding-top: 4px;
`

const LogoContainer = styled.div`
  margin-top: -2px;
  max-width: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  img {
    max-width: 180px;
    max-height: 80px;
    width: 100%;
    height: 100%;
  }
`

const Loader: React.FC<LoaderProps> = ({ message, contents, leadBrand }) => {
  const settings = {
    infinite: true,
    dots: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnHover: true,
  }

  const debounce = <A extends unknown[]>(
    callback: (...args: A) => unknown,
    msDelay: number
  ) => {
    let timer: NodeJS.Timeout | undefined

    return (...args: A) => {
      clearTimeout(timer)

      timer = setTimeout(() => {
        timer = undefined
        callback(...args)
      }, msDelay)
    }
  }

  useEffect(() => {
    document.body.style.backgroundColor = 'white'
  })

  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth,
  })

  useEffect(() => {
    const debouncedHandleResize = debounce(function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      })
    }, 100)

    window.addEventListener('resize', debouncedHandleResize)

    return () => {
      window.removeEventListener('resize', debouncedHandleResize)
    }
  })

  return (
    <Container>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <ThreeDots color='#222C69' height={70} width={70} />
      </div>

      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <LogoContainer>
          <LazyLoadImageFromUrl
            brandName={leadBrand ? leadBrand : ''}
            useAltLogo={dimensions.width <= 600}
          />
        </LogoContainer>
      </div>
      <div style={{ textAlign: 'center', paddingTop: '10px' }}>
        <h4
          style={{
            color: '#222C69',
            fontSize: '12px',
            fontWeight: '600',
            letterSpacing: '-0.4px',
          }}
        >
          POWERED BY ELITE INSURANCE PARTNERS
        </h4>
      </div>

      {/* Carousel Container*/}
      {contents && (
        <div>
          <SliderHeading>
            <h1>DID YOU KNOW ?</h1>
          </SliderHeading>

          <SliderContainer>
            <Slider {...settings}>
              {contents.map((content) => (
                <h4>{content}</h4>
              ))}
            </Slider>
          </SliderContainer>
        </div>
      )}

      <TextContainer>
        <h2
          style={{ color: 'rgb(206 3 3)', fontWeight: '600', fontSize: '22px' }}
        >
          {message ? message : 'ONE MOMENT PLEASE'}
        </h2>
        <h3
          style={{
            color: 'rgb(155 155 160)',
            fontWeight: '600',
            fontSize: '20px',
          }}
        >
          Average savings of $597/year!
        </h3>
      </TextContainer>
    </Container>
  )
}

export default Loader
