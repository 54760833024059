import { cloneDeep, groupBy, isEmpty, isNull, startCase } from 'lodash'
import { getApiErrorMessage, titleCase } from '../../utils/StringUtils'
import { useContext, useEffect, useRef, useState } from 'react'

import { ActionContext } from '../../cra'
import ApiManager from '../../api/ApiManager'
import BouncingDotsLoader from '../../components/common/Loading/BouncingDotsLoader'
import DetailSection from '../../components/CompletePlanDetailsPage/DetailSection'
import HeaderSection from '../../components/CompletePlanDetailsPage/HeaderSection'
import { HttpMethods } from '../../enums/HttpMethodsEnum'
import LayoutComponent from '../../components/common/Layout/Layout.component'
import { MAPlan } from '../../interface/quotes/mNonSupplementPlans/medicareAdvantage/MAPlan'
import { MPartDPlan } from '../../interface/quotes/mNonSupplementPlans/medicarePartD/MPartDPlan'
import { MSPlan } from '../../interface/quotes/mSupplementPlans/MSPlan'
import { MSPlanGenericDetails } from '../../interface/quotes/mSupplementPlans/MSPlanGenericDetails'
import { MSPlanGenericDisplayDetail } from '../../interface/common/MSPlanGenericDisplayDetail'
import { MSPlansRiderType } from '../../interface/quotes/mSupplementPlans/MSPlansRiderType'
import { MedicarePlanDetailsOverview } from '../../interface/quotes/MedicarePlanDetailsOverview'
import { MedicareQuotesType } from '../../enums/MedicareQuoteTypeEnum'
import ModalComponent from '../../components/common/Modal/Modal.component'
import { PackageBenefit } from '../../interface/quotes/mNonSupplementPlans/common/PackageBenefit'
import { PrescriptionDrugCoverage } from '../../interface/prescriptionDrug/PrescriptionDrug'
import SecureComponent from '../common/SecureComponent'
import SortOrderConstants from '../../constants/SortOrderConstants'
import ThankYouModal from '../../components/DashboardPage/Modals/ThankYouModal'
import UrlConstants from '../../constants/UrlConstants'
import customerStore from '../../datastore/CustomerStore'
import { getMonthlyPremiumWithDiscounts } from '../../utils/DiscountCalculationUtils'
import snackbarStore from '../../datastore/SnackbarStore'
import styled from '@emotion/styled'
import theme from '../../global/theme'
import { useSearchParams } from 'react-router-dom'

const Container = styled.div`
  padding: 50px 200px;
  background-color: ${theme.colors.white};
  min-height: 100vh;

  @media screen and (max-width: 1000px) {
    padding: 50px 100px;
  }

  @media screen and (max-width: 750px) {
    padding: 50px 20px;
  }

  @media print {
    @page {
      margin-top: 20mm;
      margin-bottom: 20mm;
    }
  }
`

const CompletePlanDetailsPage = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [maPlan, setMAPlan] = useState<MAPlan>()
  const [mPartDPlan, setMPartDPlan] = useState<MPartDPlan>()
  const [msPlan, setMSPlan] = useState<MSPlan>()
  const [msPlanGenericDetails, setMSPlanGenericDetails] =
    useState<MSPlanGenericDisplayDetail>()
  const [planType, setPlanType] = useState<MedicareQuotesType>(
    MedicareQuotesType.MEDICARE_SUPPLEMENT
  )
  const [sunfirePlanType, setSunfirePlanType] = useState<MedicareQuotesType>(
    MedicareQuotesType.MEDICARE_SUPPLEMENT
  )
  const [planId, setPlanId] = useState<string | null>(null)
  const [planName, setPlanName] = useState<string>()
  const [isFavorite, setIsFavorite] = useState<boolean>(false)
  const [overview, setOverview] = useState<MedicarePlanDetailsOverview>()
  const [planBenefitsTopics, setPlanBenefitsTopics] = useState<string[]>([])
  const [planBenefits, setPlanBenefits] = useState<PackageBenefit[]>()
  const [drugBenefits, setAbstractBenefits] = useState<any>()
  const [prescriptionCoverage, setPrescriptionCoverage] =
    useState<PrescriptionDrugCoverage[]>()
  const [thankYouModalActive, setThankYouModalActive] = useState<boolean>(false)
  const [state, setState] = useState({
    loading: true,
  })
  const [categories, setCategories] = useState<Map<string, string[]>>()
  const [currentTabTop, setCurrentTabTop] = useState<string>('Overview')
  const [currentTabBottom, setCurrentTabBottom] = useState<string>()
  const printComponentRef = useRef(null)
  const timerRef: { current: NodeJS.Timeout | null } = useRef(null)

  const handleOnEnroll = () => {
    setThankYouModalActive(true)
  }

  const { trackCurrentPage } = useContext(ActionContext)

  useEffect(() => {
    trackCurrentPage('Complete Plan Details')
    window.scrollTo({ top: 0, behavior: 'smooth' })
    // Clear the interval when the component unmounts
    return () => {
      if (timerRef.current) clearTimeout(timerRef.current)
    }
  }, [])

  useEffect(() => {
    setPlanId(searchParams.get('id'))

    if (searchParams.get('type') === 'ma') {
      ApiManager.makeApiCallWithAuthentication(
        UrlConstants.GET_COMPLETE_PLAN_DETAILS_SF.USECASE,
        HttpMethods.GET_METHOD,
        {
          $planId: searchParams.get('id'),
        }
      )
        .then((maPlan) => {
          if (!isEmpty(maPlan.data.data[0])) {
            const convertedMAPlan: any = maPlan.data.data[0]
            setPlanName(convertedMAPlan.name)
            customerStore.setSearchPlan(convertedMAPlan.name)
            setPlanType(MedicareQuotesType.MEDICARE_NON_SUPPLEMENT)
            setSunfirePlanType(MedicareQuotesType.MEDICARE_ADVANTAGE)
            setMAPlan(convertedMAPlan)
            timerRef.current = setTimeout(() => {
              setState({
                ...state,
                loading: false,
              })
            }, 200)
          }
        })
        .catch((err: Error) => {
          snackbarStore.set({
            snackbarOpen: true,
            snackbarMessage: getApiErrorMessage(
              'fetch Medicare advantage plan'
            ),
            snackbarType: 'error',
          })
        })
    } else if (searchParams.get('type') === 'mpd') {
      ApiManager.makeApiCallWithAuthentication(
        UrlConstants.GET_COMPLETE_PLAN_DETAILS_SF.USECASE,
        HttpMethods.GET_METHOD,
        {
          $planId: searchParams.get('id'),
        }
      )
        .then((mPartDPlan) => {
          if (!isEmpty(mPartDPlan.data.data[0])) {
            const convertedMPartDPlan: MPartDPlan = mPartDPlan.data.data[0]
            setPlanName(convertedMPartDPlan.name)
            customerStore.setSearchPlan(convertedMPartDPlan.name)
            setPlanType(MedicareQuotesType.MEDICARE_NON_SUPPLEMENT)
            setSunfirePlanType(MedicareQuotesType.MEDICARE_PRESCRIPTION)
            setMPartDPlan(convertedMPartDPlan)
            timerRef.current = setTimeout(() => {
              setState({
                ...state,
                loading: false,
              })
            }, 200)
          }
        })
        .catch((err: Error) => {
          snackbarStore.set({
            snackbarOpen: true,
            snackbarMessage: getApiErrorMessage('fetch Medicare part D plan'),
            snackbarType: 'error',
          })
        })
    } else if (searchParams.get('type') === 'ms') {
      ApiManager.makeApiCallWithAuthentication(
        UrlConstants.GET_MEDICARE_SUPPLEMENT_COMPLETE_PLAN_DETAILS.USECASE,
        HttpMethods.GET_METHOD,
        {
          $planId: searchParams.get('id'),
        }
      )
        .then((msPlan) => {
          if (msPlan.data) {
            const msPlanDetails: {
              msPlan: MSPlan
              msPlanGenericDetails: MSPlanGenericDetails[]
            } = msPlan.data.data
            if (
              msPlanDetails.msPlan.riders &&
              msPlanDetails.msPlan.riders.length > 0
            ) {
              const tempRiders: MSPlansRiderType[] = []
              msPlanDetails.msPlan.riders.forEach((rider: any) => {
                let x: MSPlansRiderType = JSON.parse(rider)
                tempRiders.push(x)
              })
              msPlanDetails.msPlan.riders = tempRiders
            }
            setPlanName(
              `${msPlanDetails.msPlan.msCompanyBase.name} (Plan ${msPlanDetails.msPlan.planName})`
            )
            customerStore.setSearchPlan(
              `${msPlanDetails.msPlan.msCompanyBase.name} (Plan ${msPlanDetails.msPlan.planName})`
            )
            setPlanType(MedicareQuotesType.MEDICARE_SUPPLEMENT)
            setSunfirePlanType(MedicareQuotesType.MEDICARE_SUPPLEMENT)
            const msPlanGenericDetailsResponse: MSPlanGenericDetails[] =
              msPlanDetails.msPlanGenericDetails
            let displayResponse: MSPlanGenericDisplayDetail =
              {} as MSPlanGenericDisplayDetail
            displayResponse.planName = msPlanDetails.msPlan.planName
            const temp = groupBy(msPlanGenericDetailsResponse, 'partType')
            const sorted: string[] = []
            const others: string[] = []
            const otherBenefits: string[] = []
            const order = SortOrderConstants.MSPlanDetailsSortOrder
            Object.keys(temp).forEach((key: string) => {
              if (order.includes(key)) {
                sorted[order.indexOf(key)] = key
              } else if (key === 'Other Benefits') {
                otherBenefits.push(key)
              } else others.push(key)
            })
            const unique = sorted.concat(others.sort()).concat(otherBenefits)
            unique.forEach((key) => {
              const tableRecord: { key: string; value: string }[] = []
              temp[key].forEach((planDetail) => {
                if (planDetail.noOfDays) {
                  planDetail.noOfDays?.forEach((day, index) => {
                    tableRecord.push({
                      key: `${
                        !isNull(planDetail.serviceName)
                          ? planDetail.serviceName + ':'
                          : ''
                      } ${day}`,
                      value: `Plan pays ${
                        planDetail.amountPaidByPlan[index]
                      } and you pay ${planDetail.amountPaidByCustomer[
                        index
                      ].toLowerCase()}`,
                    })
                  })
                } else {
                  tableRecord.push({
                    key: 'Additional Deductible',
                    value: `$${planDetail.additionalDeductible.toString()}`,
                  })
                }
              })
              displayResponse.planDetail
                ? displayResponse.planDetail.push({
                    partType: titleCase(key),
                    partTypeDetails: tableRecord,
                  })
                : (displayResponse.planDetail = [
                    { partType: titleCase(key), partTypeDetails: tableRecord },
                  ])
            })
            setMSPlan(msPlanDetails.msPlan)
            setMSPlanGenericDetails(cloneDeep(displayResponse))
            timerRef.current = setTimeout(() => {
              setState({
                ...state,
                loading: false,
              })
            }, 200)
          }
        })
        .catch((err: Error) => {
          snackbarStore.set({
            snackbarOpen: true,
            snackbarMessage: getApiErrorMessage(
              'fetch Medicare supplement plan details'
            ),
            snackbarType: 'error',
          })
        })
    }
  }, [searchParams])

  useEffect(() => {
    if (maPlan) {
      const tempOverview: MedicarePlanDetailsOverview = {
        fmtPremium: maPlan.fmtPremium,
        fmtMedicalDeductible: maPlan.fmtMedicalDeductible,
        oonMoop: maPlan.oonMoop,
        moop: maPlan.moop,
        combMoop: maPlan.combMoop,
        fmtDeductible: maPlan.fmtDeductible,
        initialCoverageLimit: maPlan.initialCoverageLimit,
        catastrophicLimit: maPlan.catastrophicLimit,
        rating: maPlan.rating,
      }
      setIsFavorite(maPlan.planSaved)
      setOverview(tempOverview)
      setPlanBenefits(maPlan.benefitSections)
    }
  }, [maPlan])

  useEffect(() => {
    if (mPartDPlan) {
      const tempOverview: MedicarePlanDetailsOverview = {
        fmtPremium: mPartDPlan.fmtPremium,
        fmtMedicalDeductible: mPartDPlan.fmtMedicalDeductible,
        initialCoverageLimit: mPartDPlan.initialCoverageLimit,
        catastrophicLimit: mPartDPlan.catastrophicLimit,
        fmtDeductible: mPartDPlan.fmtDeductible,
        rating: mPartDPlan.rating,
        oonMoop: mPartDPlan.oonMoop,
        moop: mPartDPlan.moop,
        combMoop: mPartDPlan.combMoop,
      }
      if (mPartDPlan.prescriptionCoverages?.length > 0) {
        setPrescriptionCoverage(mPartDPlan.prescriptionCoverages)
      }
      setIsFavorite(mPartDPlan.planSaved)
      setOverview(tempOverview)
      setPlanBenefits(mPartDPlan.benefitSections)
    }
  }, [mPartDPlan])

  useEffect(() => {
    if (msPlan) {
      const monthlyPremiumWithDiscounts = getMonthlyPremiumWithDiscounts(
        +msPlan.mSupplementRate.month / 100,
        msPlan.id
      )
      const estimatedCostWithDiscounts =
        msPlan.estimatedAnnualCost -
        (+msPlan.mSupplementRate.month / 100 - monthlyPremiumWithDiscounts) * 12

      const tempOverview: any = {
        estimatedAnnualCost: estimatedCostWithDiscounts,
        estimatedAnnualDeductible: msPlan.annualDeductible,
        monthlyPremium: monthlyPremiumWithDiscounts,
      }
      setIsFavorite(msPlan.planSaved)
      setOverview(tempOverview)
    }
  }, [msPlan])

  const handleTabChange = (e: React.SyntheticEvent, newValue: string) => {
    setCurrentTabBottom(newValue)
    if (document.getElementById(newValue) !== null) {
      let innerWidth = window.innerWidth
      let siblingHeight = (
        document.getElementById(newValue)?.parentElement?.parentElement
          ?.firstElementChild as HTMLElement
      ).clientHeight
      if (!siblingHeight) siblingHeight = 0
      let adjustment = 0
      if (innerWidth <= 320) {
        adjustment = 130
      } else if (innerWidth <= 375) {
        adjustment = 85
      } else if (innerWidth <= 480) {
        adjustment = 60
      } else if (innerWidth <= 720) {
        adjustment = 30
      } else if (innerWidth <= 1079) {
        adjustment = 20
      }
      if (siblingHeight > 470) {
        if (innerWidth <= 367) adjustment = adjustment + 50
        else adjustment = adjustment + 10
      } else if (siblingHeight > 450) {
        adjustment = adjustment + 60
      } else if (siblingHeight > 430 && siblingHeight < 450) {
        if (innerWidth > 480) adjustment = adjustment + 60
        else if (innerWidth >= 367) adjustment = adjustment + 40
        else adjustment = adjustment + 10
      } else if (siblingHeight < 430 && siblingHeight > 410) {
        if (innerWidth > 322) adjustment = adjustment + 10
        else adjustment = adjustment - 10
      } else if (siblingHeight < 390 && siblingHeight > 370) {
        if (innerWidth > 480) adjustment = adjustment + 10
        else adjustment = adjustment - 30
      } else if (siblingHeight < 370 && siblingHeight > 350) {
        if (innerWidth > 480) adjustment = adjustment + 10
        else adjustment = adjustment - 70
      } else if (siblingHeight < 350) {
        if (innerWidth < 480) adjustment = adjustment - 60
      }
      window.scrollTo({
        top:
          (document.getElementById(newValue) as HTMLElement).offsetTop -
          430 -
          adjustment,
        behavior: 'smooth',
      })
    }
  }

  const getCarrierLogoName = (
    planType: MedicareQuotesType,
    planName: string
  ): string => {
    let logoName: string = ''
    switch (planType) {
      case MedicareQuotesType.MEDICARE_ADVANTAGE:
        logoName = maPlan?.carrierName || ''
        break
      case MedicareQuotesType.MEDICARE_SUPPLEMENT:
        logoName = msPlan?.msCompanyBase.name || ''
        break
      case MedicareQuotesType.MEDICARE_PRESCRIPTION:
        logoName = mPartDPlan?.carrierName || ''
        break
    }
    return logoName
  }

  const getEnrollRequestStatus = () => {
    if (maPlan) {
      return maPlan.enrollRequested
    } else if (msPlan) {
      return msPlan.enrollRequested
    } else if (mPartDPlan) {
      return mPartDPlan.enrollRequested
    }
  }

  return (
    <LayoutComponent
      bottomForScrollToTopButton='100px'
      rightForScrollToTopButton={window.innerWidth < 375 ? '20px' : '10px'}
    >
      <Container ref={printComponentRef}>
        {state.loading ? (
          <div style={{ marginTop: 200 }}>
            <BouncingDotsLoader />
          </div>
        ) : (
          <>
            {thankYouModalActive && (
              <ModalComponent setOpen={() => setThankYouModalActive(false)}>
                <ThankYouModal
                  setOpen={() => setThankYouModalActive(false)}
                  to={`?id=${searchParams.get('id')}&&type=${searchParams.get(
                    'type'
                  )}`}
                />
              </ModalComponent>
            )}
            {planId && planName && (
              <HeaderSection
                carrierLogoName={getCarrierLogoName(sunfirePlanType, planName)}
                planId={planId}
                planName={planName}
                isFavorite={isFavorite}
                planType={planType}
                onAddSaved={() => setIsFavorite(true)}
                onRemoveSaved={() => setIsFavorite(false)}
                handleOnEnroll={handleOnEnroll}
                planBenefitsTopics={planBenefitsTopics}
                categories={categories!}
                abstractDetails={drugBenefits}
                currentTabTop={currentTabTop}
                handleTabChange={handleTabChange}
                printComponentRef={printComponentRef}
                enrollRequestedStatus={getEnrollRequestStatus()!}
                setCurrentTabTop={setCurrentTabTop}
                currentTabBottom={currentTabBottom!}
                sunfirePlanType={sunfirePlanType}
                ridersApplied={
                  planType === MedicareQuotesType.MEDICARE_SUPPLEMENT
                    ? msPlan?.ridersApplied
                    : undefined
                }
              />
            )}
            {state.loading ? (
              <BouncingDotsLoader />
            ) : (
              overview && (
                <DetailSection
                  overview={overview}
                  planBenefitsTopics={planBenefitsTopics}
                  planBenefits={planBenefits!}
                  msPlanGenericDetails={msPlanGenericDetails}
                  abstractDetails={drugBenefits}
                  prescriptionDrugCoverages={prescriptionCoverage}
                  setCurrentTabTop={setCurrentTabTop}
                  setCurrentTabBottom={setCurrentTabBottom}
                  categories={categories!}
                  planType={planType}
                  riders={
                    planType === MedicareQuotesType.MEDICARE_SUPPLEMENT
                      ? msPlan?.riders || []
                      : undefined
                  }
                />
              )
            )}
          </>
        )}
      </Container>
    </LayoutComponent>
  )
}

export default SecureComponent(CompletePlanDetailsPage)
