import Checkbox from '@mui/material/Checkbox'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormGroup from '@mui/material/FormGroup'
import FormLabel from '@mui/material/FormLabel'
import React from 'react'
import { fireEvent } from '../../../cra'
import theme from '../../../global/theme'

const checkboxStyles = {
  backgroundColor: '#FBFBFB',
  border: '1px solid #E5E5E5',
  borderRadius: '4px',
  padding: '0px 10px',
  margin: '3px 10px 3px 0px',
}

const checkboxUnstyledStyles = {
  padding: '0px 0px',
}

interface ChecboxGroupProps {
  formLabel?: string
  checkboxState: {
    id: string | number
    checked: boolean
    label: JSX.Element | number | string
    value?: string | number
    disabled?: boolean
  }[]
  row?: boolean
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  width?: string
  type?: 'default' | 'unstyled'
  checkboxGroupName?: string
  addBottomBorderSeparator?: boolean
  readOnly?: boolean
}

const CheckboxGroup: React.FC<ChecboxGroupProps> = ({
  formLabel,
  onChange,
  row,
  checkboxState,
  width,
  type = 'default',
  checkboxGroupName = '',
  addBottomBorderSeparator = false,
  readOnly = false,
}) => {
  // const checkboxes = Object.keys(checkboxState)

  // this handle change function may be used in parent and sent as a callback(onChange) to this component.
  // const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   const eventValue = e.target.value
  //   const updatedCheckStatus = {
  //     ...checkStatus,
  //     [eventValue]: !checkStatus[eventValue],
  //   }
  //   setCheckStatus(updatedCheckStatus)
  //   console.log(updatedCheckStatus)
  // }
  return (
    <FormControl
      sx={
        readOnly
          ? {
              width: `${width}`,
              pointerEvents: 'none',
            }
          : {
              width: `${width}`,
            }
      }
    >
      <FormLabel sx={{ fontStyle: 'italic', fontSize: '14px' }}>
        {formLabel}
      </FormLabel>
      <FormGroup onChange={onChange} row={row ? row : false}>
        {checkboxState.map((checkbox, index) => (
          <FormControlLabel
            key={checkbox.id}
            control={
              <Checkbox
                onChange={(e) => {
                  fireEvent('checkboxInput', e, {
                    label: checkboxGroupName,
                    value: checkbox.value ? checkbox.value : checkbox.label,
                    description: `${
                      checkbox.checked ? 'Unchecked' : 'Checked'
                    } option ${checkbox.label} in ${checkboxGroupName}`,
                  })
                }}
                style={readOnly ? { color: theme.colors.disabled } : {}}
                size='small'
                disableRipple={readOnly}
              />
            }
            label={checkbox.label}
            value={checkbox.value ? checkbox.value : checkbox.label}
            checked={checkbox.checked}
            sx={type === 'default' ? checkboxStyles : checkboxUnstyledStyles}
            style={
              addBottomBorderSeparator && index < checkboxState.length - 1
                ? {
                    borderBottom: `0.5px solid ${theme.colors.borderColor}`,
                    position: 'relative',
                  }
                : { position: 'relative' }
            }
            disabled={checkbox.disabled ? true : false}
          />
        ))}
      </FormGroup>
    </FormControl>

    // <FormControl sx={{ width: `${width}` }}>
    //   <FormLabel sx={{ fontStyle: 'italic', fontSize: '14px' }}>
    //     {formLabel}
    //   </FormLabel>
    //   <FormGroup onChange={onChange} row={row ? row : false}>
    //     {checkboxes.map((checkbox) => (
    //       <FormControlLabel
    //         key={checkbox}
    //         control={<Checkbox size='small' />}
    //         label={checkbox}
    //         value={checkbox}
    //         checked={checkboxState[checkbox]}
    //         sx={type === 'default' ? checkboxStyles : checkboxUnstyledStyles}
    //       />
    //     ))}
    //   </FormGroup>
    // </FormControl>
  )
}

export default CheckboxGroup
