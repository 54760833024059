import Button from '../../../common/Button/Button.component'
import React from 'react'
import RouteConstants from '../../../../constants/RouteConstants';
import styled from '@emotion/styled'
import theme from '../../../../global/theme'
import { useNavigate } from 'react-router-dom';

const ContentBox = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  p {
    color: ${theme.colors.textLight};
    font-size: 12px;
    font-weight: bold;
    padding-right: 10px;
  }
`

const NewPasswordNotification = () => {
  const navigate = useNavigate()
  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault()
    navigate(RouteConstants.RESET_PASSWORD)
  }

  return (
    <ContentBox>
      <p>
        Your quote will expire in 18 hours. Set a new password now to save your
        quote.
      </p>
      <Button height='30px' color='secondary' variant='contained' bold={false} onClick={handleClick}>
        Set new password
      </Button>
    </ContentBox>
  )
}

export default NewPasswordNotification
