import { FiChevronLeft, FiChevronRight } from 'react-icons/fi'

import Button from '../common/Button/Button.component'
import Icon from '../common/Icon/Icon.component'
import React from 'react'
import styled from '@emotion/styled'

interface NavigatorProps {
  handleNext: () => void
  handleBack?: () => void
  handleViewPlans?: () => void
  disableBack?: boolean
  disableNext?: boolean
  buttonText1?: string
  buttonText2?: string
  buttonText3?: string
  isLoading?: boolean
  currentInfoStep?: string
  disableBackButton?: boolean
  button3Variant?: 'primary' | 'secondary'
}

const Container = styled.div`
  bottom: 0;
  left: 0;
  width: 100%;
  border-top: 1px solid #d2d2d1;
  background-color: #fff;
  padding-top: 20px;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  div {
    display: flex;
  }

  @media screen and (max-width: 900px) {
    padding: 10px 20px;
  }
  @media screen and (max-width: 550px) {
    padding: 5px 10px;
    flex-direction: column;
    div {
      width: 100%;
    }
  }

  @media screen and (max-width: 850px) {
    flex-direction: row;
    div {
      width: 50%;
    }
  }
`

const NavigationButtons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;

  @media screen and (max-width: 850px) {
    justify-content: end;
    gap: 10px;

    p {
      display: none;
    }
  }
`

const BottomStepNavigator: React.FC<NavigatorProps> = ({
  handleBack,
  handleNext,
  handleViewPlans,
  disableBack = false,
  disableNext = false,
  buttonText1 = 'View Plans',
  buttonText2 = 'Back',
  buttonText3 = 'Next',
  isLoading = false,
  currentInfoStep = '',
  disableBackButton = false,
  button3Variant = 'primary',
}) => {
  return (
    <Container>
      <div>
        <Button
          textTransform='uppercase'
          bold={false}
          fontSize='12px'
          width='100%'
          height='35px'
          onClick={handleViewPlans}
          disabled={disableBack}
          description={`'${buttonText1}' Button Click - Info Steps - ${currentInfoStep}`}
          lineHeight='normal'
        >
          {buttonText1}
        </Button>
      </div>
      <NavigationButtons>
        {!disableBackButton && (
          <Button
            textTransform='uppercase'
            bold={false}
            fontSize='12px'
            width='fit-content'
            height='35px'
            onClick={handleBack}
            disabled={disableBack}
            description={`'${buttonText1}' Button Click - Info Steps - ${currentInfoStep}`}
            minWidth='0px'
            isBottomStepNavigator={true}
          >
            <span style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
              <Icon fontSize='18px' childComponent={<FiChevronLeft />} />
              <p>{buttonText2}</p>
            </span>
          </Button>
        )}
        <Button
          variant='contained'
          color={button3Variant}
          bold={false}
          textTransform='uppercase'
          fontSize='12px'
          width='fit-content'
          height='35px'
          onClick={handleNext}
          disabled={disableNext || isLoading}
          loading={isLoading}
          description={`'${buttonText3}' Button Click - Info Steps - ${currentInfoStep}`}
          minWidth='0px'
          isBottomStepNavigator={true}
        >
          <span style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
            <p>{buttonText3}</p>
            <Icon fontSize='18px' childComponent={<FiChevronRight />} />
          </span>
        </Button>
      </NavigationButtons>
    </Container>
  )
}

export default BottomStepNavigator
