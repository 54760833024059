import React from 'react'
import customerStore from '../../../datastore/CustomerStore'
import styled from '@emotion/styled'

interface ContainerProps {
  brand: string
}

const Container = styled.div<ContainerProps>`
  width: 100%;
  background-color: ${({ brand }) =>
    brand === 'aetna-medicare' ? '#7D3F98' : brand === 'devoted' ? '#FF4F00' : '#053870'};
  color: white;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 8px 20px;
`
const Banner = () => {
  const brandNamed =
    customerStore.getBrandName() === 'aetna-medicare'
      ? 'Aetna'
      : customerStore.getBrandName() === 'mutual of omaha-medicare'
        ? 'Mutual of Omaha'
        : customerStore.getBrandName() === 'medicareassociation'
          ? 'medicareassociation'
          : customerStore.getBrandName() === 'devoted'
            ? 'Devoted Health'
            : ''

  return (
    <Container brand={customerStore.getBrandName()}>
      {brandNamed === 'medicareassociation'
        ? 'This website is run by Elite Insurance Partners. We are proud to have earned the trust of the American Association for Medicare Supplement Insurance.'
        : `This website is run by Elite Insurance Partners, licensed to sell ${brandNamed}® Medicare products`}
    </Container>
  )
}

export default Banner
