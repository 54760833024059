import { APIResponse } from '../interface/APIResponseInterface'
import ApiManager from '../api/ApiManager'
import { CustomerDTO } from '../datastore/CustomerStore'
import { HttpMethods } from '../enums/HttpMethodsEnum'
import { SnackbarTypes } from '../enums/SnackbarTypesEnum'
import UrlConstants from '../constants/UrlConstants'
import { getApiErrorMessage } from '../utils/StringUtils'
import snackbarStore from '../datastore/SnackbarStore'

export const updateInfoStepStatus = async (payload: any) => {
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCallWithAuthentication(
      UrlConstants.PATCH_INFO_STEPS.USECASE,
      HttpMethods.PATCH_METHOD,
      {
        $infoStep: payload,
      }
    )
      .then((response: APIResponse<string>) => {
        if (response.status === 201 || response.status === 200) {
          resolve(response)
        } else {
          reject(response)
        }
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const updateCustomerDetails = async (payload: any) => {
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCallWithAuthentication(
      UrlConstants.UPDATE_CUSTOMER_DETAILS.USECASE,
      HttpMethods.PATCH_METHOD,
      payload
    )
      .then(async (response) => {
        if (response.status === 200) {
          resolve(response.data.data)
        } else throw response
      })
      .catch((err) => {
        snackbarStore.set({
          snackbarMessage: getApiErrorMessage('update customer details'),
          snackbarOpen: true,
          snackbarType: SnackbarTypes.ERROR,
        })
        reject(err)
      })
  })
}

export const fetchCustomerDetails = async (): Promise<CustomerDTO> => {
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCallWithAuthentication(
      UrlConstants.GET_CUSTOMER_DETAILS.USECASE,
      HttpMethods.GET_METHOD
    )
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data.data)
        } else reject(response)
      })
      .catch((err) => {
        snackbarStore.set({
          snackbarMessage: getApiErrorMessage('fetch customer details'),
          snackbarOpen: true,
          snackbarType: SnackbarTypes.ERROR,
        })
        reject(err)
      })
  })
}
