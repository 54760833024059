import Button from '../../common/Button/Button.component'
import { Link } from 'react-router-dom'
import React from 'react'
import RouteConstants from '../../../constants/RouteConstants'
import styled from '@emotion/styled'
import theme from '../../../global/theme'

interface ModelProps {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  redirect: string | null
}

const Container = styled.div`
  width: 500px;
  min-height: 220px;

  h2 {
    color: #272f69;
    padding-bottom: 20px;
  }
  p {
    margin-bottom: 16px;
    font-weight: 600;
    color: ${theme.colors.textDark};

    span {
      color: #ba0000;
      font-weight: bold;
    }
  }
  .highlight {
    color: #262e67;
    font-weight: 700;
    padding-bottom: 20px;
    font-size: 18px;
  }

  div {
    display: flex;
    justify-content: space-between;
  }

  @media screen and (max-width: 750px) {
    width: 320px;
    div {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  @media screen and (max-width: 380px) {
    width: 290px;
  }
`
const ModalButton = styled(Link)`
  width: 220px;
  text-decoration: none;

  @media screen and (max-width: 750px) {
    width: 100%;
  }
`

const InfoStepsPrompt: React.FC<ModelProps> = ({ setOpen, redirect }) => {
  return (
    <Container>
      <h2>Hello again!</h2>
      <p>We can help you find the perfect Medicare plan.</p>
      <p>
        Let us walk you through a few simple steps and find personalized plans
        covering your needs.
      </p>
      <p className='highlight'>We highly recommend this! </p>
      <div>
        <ModalButton
          to={redirect ? redirect : RouteConstants.INFO_STEPS_PRESCRIPTION}
        >
          <Button
            variant='contained'
            bold={false}
            width='100%'
            color='secondary'
            description='Dashboard Info Steps Promt - CTA'
            id='findmedicareplans'
          >
            Find my Medicare Plans
          </Button>
        </ModalButton>
        <ModalButton to=''>
          <Button
            color='info'
            bold={false}
            width='100%'
            onClick={(e) => setOpen(false)}
            description='Dashboard Info Steps Prompt - Skip'
          >
            Skip for now
          </Button>
        </ModalButton>
      </div>
    </Container>
  )
}

export default InfoStepsPrompt
