import CloseIconButton from '../Button/CloseIconButton.component'
import React from 'react'
import customerStore from '../../../datastore/CustomerStore'
import styled from '@emotion/styled'
import theme from '../../../global/theme'

interface ContainerProps {
  open?: boolean
  close?: boolean
  height?: string
  width?: string
  borderColor?: string
  borderRadius?: string
  borderWidth?: string
}

interface CardProps extends ContainerProps {
  cardType?: 'close' | 'edit' | 'editOnly' | 'deleteOnly' | 'readOnly'
  children?: JSX.Element | string | undefined
  close?: boolean
  editCard?: () => void
  deleteCard?: () => void
  closeCard?: () => void
  onlyDeleteButton?: boolean
  greyOut?: boolean
  isQuotesRefreshing?: boolean
}

interface EditContainerProps {
  hasTwoButtons: boolean
}

const Container = styled.div<ContainerProps>`
  border: ${(props) => (props.borderWidth ? props.borderWidth : '1.5px solid')}
    ${(props) =>
    props.borderColor ? props.borderColor : theme.colors.borderColor};
  height: ${(props) => props.height};
  width: ${(props) => props.width};
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : '4px'};
  position: relative;
  display: ${(props) => (props.open && !props.close ? 'block' : 'none')};
  padding: 15px 15px;
  margin: 5px 0px;
  max-height: 600px;
  overflow-y: scroll;
`

const ButtonContainer = styled.div`
  position: absolute;
  top: 0px;
  right: 5px;
`

const EditContainer = styled.div<EditContainerProps>`
  display: flex;
  justify-content: ${(props) =>
    props.hasTwoButtons ? 'space-between' : 'flex-end'};
  margin: 5px;
  width: 60px;

  span {
    font-size: 10px;
    text-decoration: underline;
    font-weight: bold;
    color: ${theme.colors.primary};
    cursor: pointer;
  }
`

const Card: React.FC<CardProps> = ({
  height,
  width,
  borderColor,
  borderRadius,
  borderWidth,
  cardType,
  children,
  close = false,
  editCard,
  deleteCard,
  closeCard,
  onlyDeleteButton = false,
  greyOut = false,
  isQuotesRefreshing = false,
}) => {
  const [open, setOpen] = React.useState(true)
  return (
    <Container
      open={open}
      close={close}
      height={height}
      width={width}
      borderColor={borderColor}
      borderRadius={borderRadius}
      borderWidth={borderWidth}
    >
      {cardType !== 'readOnly' && (
        <ButtonContainer>
          {cardType === 'close' ? (
            <CloseIconButton
              onClick={() => {
                setOpen(false)
                if (closeCard) {
                  closeCard()
                }
              }}
              textColor={theme.colors.primary}
            />
          ) : (
            !(
              customerStore.isMAQuotesProcessing ||
              customerStore.isMSQuotesProcessing ||
              customerStore.isMPartDQuotesProcessing ||
              isQuotesRefreshing ||
              greyOut
            ) && (
              <EditContainer hasTwoButtons={cardType === 'edit' ? true : false}>
                {(cardType === 'edit' || cardType === 'editOnly') && (
                  <span
                    onClick={() => {
                      if (editCard) {
                        editCard()
                      }
                    }}
                  >
                    Edit
                  </span>
                )}
                {(cardType === 'edit' || cardType === 'deleteOnly') && (
                  <span
                    onClick={() => {
                      if (deleteCard) deleteCard()
                    }}
                  >
                    Delete
                  </span>
                )}
              </EditContainer>
            )
          )}
        </ButtonContainer>
      )}
      {children}
    </Container>
  )
}

export default Card
