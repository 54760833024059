import { ActionContext } from '../../cra'
import ApiManager from '../../api/ApiManager'
import BouncingDotsLoader from '../../components/common/Loading/BouncingDotsLoader'
import { HttpMethods } from '../../enums/HttpMethodsEnum'
import InPageHeader from '../../components/common/InPageHeader/InPageHeader.component'
import LayoutComponent from '../../components/common/Layout/Layout.component'
import { MAPlan } from '../../interface/quotes/mNonSupplementPlans/medicareAdvantage/MAPlan'
import { MPartDPlan } from '../../interface/quotes/mNonSupplementPlans/medicarePartD/MPartDPlan'
import { MNonSupplementPlan } from '../../interface/quotes/mNonSupplementPlans/MNonSupplementPlan'
import { MSPlan } from '../../interface/quotes/mSupplementPlans/MSPlan'
import MedicareAdvantageCard from '../../components/DashboardPage/PageSections/MedicareAdvantage/MedicareAdvantageCard.component'
import MedicarePrescriptionCardComponent from '../../components/DashboardPage/PageSections/MedicarePrescription/MedicarePrescriptionCard.component'
import MedicareSupplementCard from '../../components/DashboardPage/PageSections/MedicareSupplement/MedicareSupplementCard'
import ModalComponent from '../../components/common/Modal/Modal.component'
import NoPlanFoundCard from '../../components/DashboardPage/NoPlanFoundCard'
import React from 'react'
import SecureComponent from '../common/SecureComponent'
import ThankYouModal from '../../components/DashboardPage/Modals/ThankYouModal'
import UrlConstants from '../../constants/UrlConstants'
import customerStore from '../../datastore/CustomerStore'
import { getApiErrorMessage } from '../../utils/StringUtils'
import { observer } from 'mobx-react-lite'
import snackbarStore from '../../datastore/SnackbarStore'
import styled from '@emotion/styled'
import theme from '../../global/theme'
import { useLocation } from 'react-router-dom'

const Container = styled.div`
  background-color: ${theme.colors.white};
  min-height: 100vh;
`
const ContentWrapper = styled.div`
  padding: 50px 250px;
  @media screen and (max-width: 1200px) {
    padding: 50px 150px;
  }
  @media screen and (max-width: 1000px) {
    padding: 50px 50px;
  }
  @media screen and (max-width: 600px) {
    padding: 20px 20px;
  }
`

const AddressWrapper = styled.div`
  text-align: center;
  p {
    color: ${theme.colors.textDark};
    font-weight: bold;
  }
  .address {
    font-size: 14px;
    font-weight: 600;
    color: ${theme.colors.textGrey};
    span {
      padding-left: 10px;
      color: ${theme.colors.primary};
      font-style: italic;
      text-decoration: underline;
      cursor: pointer;
    }
  }
`

const CardsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const SavedPlansPage = () => {
  const [loading, setLoading] = React.useState(true)
  const [currentTab, setCurrentTab] = React.useState(0)
  const handleTabChange = (e: React.SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue)
  }

  const [maPlans, setMAPlans] = React.useState<MNonSupplementPlan[]>([])
  const [mPartDPlans, setMPartDPlans] = React.useState<MNonSupplementPlan[]>([])
  const [msPlans, setMSPlans] = React.useState<MSPlan[]>([])

  const [thankYouModalActive, setThankYouModalActive] = React.useState(false)

  const [fetchPlans, setFetchPlans] = React.useState(true)
  const [medigapChartDetails, setMedigapChartDetails] = React.useState<any>({})

  React.useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    ApiManager.makeApiCall(
      UrlConstants.GET_MEDIGAP_CHART_DETAILS.USECASE,
      HttpMethods.GET_METHOD
    )
      .then((response) => {
        if (response.status === 200) {
          setMedigapChartDetails(response.data.data)
        } else throw response
      })
      .catch(() => {
        snackbarStore.set({
          snackbarOpen: true,
          snackbarMessage: getApiErrorMessage(
            'retrieve medigap chart details.'
          ),
          snackbarType: 'error',
        })
      })
  }, [])

  const handleOnEnroll = () => {
    setThankYouModalActive(true)
  }

  const handleOnReload = () => {
    setFetchPlans(true)
  }

  const { trackCurrentPage } = React.useContext(ActionContext)
  const type: any = useLocation().state

  const getPlanCards = () => {
    setLoading(true)
    ApiManager.makeApiCallWithAuthentication(
      UrlConstants.GET_SAVED_PLANS.USECASE,
      HttpMethods.GET_METHOD
    )
      .then((res) => {
        if (res.data && res.data.data.length > 0) {
          const convertedMAPlans: MNonSupplementPlan[] = []
          const convertedMPartDPlans: MNonSupplementPlan[] = []
          const convertedMSPlans: MSPlan[] = []
          res.data.data.forEach((plan: any) => {
            if (plan.medicareQuoteType === 'medicareNonSupplement') {
              if( plan.mnsPlan.type === 'PD') 
                convertedMPartDPlans.push(plan.mnsPlan)
              else
                convertedMAPlans.push(plan.mnsPlan)
            } else if (plan.medicareQuoteType === 'medicareSupplement') {
              convertedMSPlans.push(plan.msPlan)
            }
          })
          setMAPlans(convertedMAPlans)
          setMPartDPlans(convertedMPartDPlans)
          setMSPlans(convertedMSPlans)
        } else {
          setMAPlans([])
          setMPartDPlans([])
          setMSPlans([])
          snackbarStore.set({
            snackbarMessage: 'No Plans have been saved',
            snackbarOpen: true,
            snackbarType: 'success',
          })
        }
      })
      .catch((err) => {
        snackbarStore.set({
          snackbarMessage: getApiErrorMessage('fetch saved plans'),
          snackbarOpen: true,
          snackbarType: 'error',
        })
      })
      .finally(() => {
        setLoading(false)
      })
  }

  React.useEffect(() => {
    const currentPage =
      currentTab === 0
        ? 'Saved Plans - Medicare Advantage'
        : currentTab === 1
        ? 'Saved Plans - Medicare Supplements'
        : currentTab === 2
        ? 'Saved Plans - Medicare Prescription Drugs'
        : ''
    trackCurrentPage(currentPage)
  }, [currentTab, trackCurrentPage])

  React.useEffect(() => {
    if (fetchPlans) {
      getPlanCards()
      setFetchPlans(false)
    }
  }, [fetchPlans])

  // these functions needs to be inside the card component as it's in other 2 plan card.
  // const getMedicareSupplementPlans = (
  //   msPlansFiltersPayload?: MSPlanRequestFilter
  // ) => {
  //   ApiManager.makeApiCallWithAuthentication(
  //     UrlConstants.GET_MEDICARE_SUPPLEMENT_PLANS.USECASE,
  //     HttpMethods.POST_METHOD,
  //     msPlansFiltersPayload ? msPlansFiltersPayload : {}
  //   )
  //     .then((msPlansResponse) => {
  //       if (msPlansResponse.status === 200) {
  //         if (msPlansResponse.data && msPlansResponse.data.data) {
  //           const convertedMSPlans: MSPlan[] = []
  //           for (let msPlan of msPlansResponse.data.data) {
  //             convertedMSPlans.push(msPlan)
  //           }
  //           setMSPlans(convertedMSPlans)
  //         }
  //       } else {
  //         setMSPlans([])
  //         snackbarStore.set({
  //           snackbarOpen: true,
  //           snackbarMessage: msPlansResponse.data.message,
  //           snackbarType: 'error',
  //         })
  //       }
  //     })
  //     .catch((err: Error) => {
  //       snackbarStore.set({
  //         snackbarOpen: true,
  //         snackbarMessage: err.message,
  //         snackbarType: 'error',
  //       })
  //     })
  // }

  return (
    <LayoutComponent>
      <Container>
        {thankYouModalActive && (
          <ModalComponent setOpen={() => setThankYouModalActive(false)}>
            <ThankYouModal setOpen={() => setThankYouModalActive(false)} />
          </ModalComponent>
        )}
        <InPageHeader
          onChange={handleTabChange}
          currentTab={currentTab}
          tabLabels={[
            {
              heading: 'Medicare Advantage (Part C)',
              subHeading: `${maPlans && maPlans.length} Plans Available`,
            },
            {
              heading: 'Medicare Supplement (Medigap)',
              subHeading: `${msPlans && msPlans.length} Plans Available`,
            },
            {
              heading: 'Medicare Prescription Drugs (Part D)',
              subHeading: `${
                mPartDPlans && mPartDPlans.length
              } Plans Available`,
            },
          ]}
          heading='Your Saved Plans'
          isSavedPlans={true}
          type={type}
        />
        {loading ? (
          <BouncingDotsLoader />
        ) : (
          <ContentWrapper>
            <AddressWrapper>
              <p>Showing saved plans for</p>
              <div className='address'>
                {customerStore.getPostalCodeAndCounty()}
              </div>
            </AddressWrapper>
            {currentTab === 0 &&
              maPlans &&
              (maPlans.length === 0 ? (
                <NoPlanFoundCard />
              ) : (
                <CardsWrapper>
                  {maPlans.map((card, index) => {
                    const savedCardDetails = card
                    savedCardDetails.planSaved = true
                    return (
                      <MedicareAdvantageCard
                        key={index}
                        medicareAdvantagePlan={savedCardDetails}
                        onEnroll={handleOnEnroll}
                        handleRemoveFavorite={() => getPlanCards()}
                        showCompareCheckbox={false}
                      />
                    )
                  })}
                </CardsWrapper>
              ))}
            {currentTab === 1 &&
              msPlans &&
              (msPlans.length === 0 ? (
                <NoPlanFoundCard />
              ) : (
                <CardsWrapper>
                  {msPlans.map((card, index) => {
                    const savedCardDetails = card
                    savedCardDetails.planSaved = true
                    return (
                      <MedicareSupplementCard
                        key={index}
                        msPlan={savedCardDetails}
                        onEnroll={handleOnEnroll}
                        handleRemoveFavorite={() => getPlanCards()}
                        showCompareCheckbox={false}
                        onReload={handleOnReload}
                        medigapChartDetails={medigapChartDetails}
                      />
                    )
                  })}
                </CardsWrapper>
              ))}
            {currentTab === 2 &&
              mPartDPlans &&
              (mPartDPlans.length === 0 ? (
                <NoPlanFoundCard />
              ) : (
                <CardsWrapper>
                  {mPartDPlans.map((card, index) => {
                    const savedCardDetails = card
                    savedCardDetails.planSaved = true
                    return (
                      <MedicarePrescriptionCardComponent
                        key={index}
                        medicarePartDPlan={savedCardDetails}
                        onEnroll={handleOnEnroll}
                        handleRemoveFavorite={() => getPlanCards()}
                        showCompareCheckbox={false}
                      />
                    )
                  })}
                </CardsWrapper>
              ))}
          </ContentWrapper>
        )}
      </Container>
    </LayoutComponent>
  )
}

export default SecureComponent(observer(SavedPlansPage))
