import { makeAutoObservable, observable } from 'mobx'

import { MAPlanFiltersRequest } from '../../interface/quotes/mNonSupplementPlans/medicareAdvantage/request/MAPlanFiltersRequest'
import { MedicareAdvantageDisplayFilter } from '../../interface/quotes/mNonSupplementPlans/medicareAdvantage/MedicareAdvantageDisplayFilters'
import { PaginationDetailType } from '../../interface/common/PaginationType'
import { SortDetailType } from '../../interface/common/SortDetailType'
import { startCase } from 'lodash'

class MedicareAdvantageFilterQuoteStore {
  @observable medicareAdvantageFilters: MedicareAdvantageDisplayFilter
  @observable maPlansRequestFilter: MAPlanFiltersRequest
  @observable filterApplied: boolean
  @observable clearFilters: boolean
  @observable plansFetched: boolean
  @observable filtersFetched: boolean
  @observable totalCount: number | null
  @observable isFilterChanged: boolean
  @observable filtersActive: boolean
  @observable prevSortType: string | null
  @observable paginationLoader: boolean
  @observable paginationApplied: boolean
  @observable isRxApplied: boolean | undefined

  constructor() {
    makeAutoObservable(this, { medicareAdvantageFilters: observable.struct })
    this.medicareAdvantageFilters = {} as MedicareAdvantageDisplayFilter
    this.maPlansRequestFilter = {} as MAPlanFiltersRequest
    this.filterApplied = false
    this.clearFilters = false
    this.plansFetched = false
    this.filtersFetched = false
    this.isFilterChanged = false
    this.totalCount = null
    this.filtersActive = false
    this.prevSortType = null
    this.paginationLoader = false
    this.paginationApplied = false
    this.isRxApplied = true
  }

  setPlansFetched(planFetchedStatus: boolean) {
    this.plansFetched = planFetchedStatus
  }

  isPlanFetched() {
    return this.plansFetched
  }

  setFiltersFetched(filtersFetchedStatus: boolean) {
    this.filtersFetched = filtersFetchedStatus
  }

  isFiltersFetched() {
    return this.filtersFetched
  }

  getMedicareAdvantageFilters() {
    return this.medicareAdvantageFilters
  }

  setMedicareAdvantageFilters(
    _medicareAdvantageFilters: MedicareAdvantageDisplayFilter
  ) {
    this.medicareAdvantageFilters = _medicareAdvantageFilters
  }

  isFilterApplied() {
    return this.filterApplied
  }

  setFilterApplied(_filterApplied: boolean) {
    this.filterApplied = _filterApplied
  }

  isFiltersCleared() {
    return this.clearFilters
  }

  setClearFilters(_clearFilters: boolean) {
    this.clearFilters = _clearFilters
  }

  getMAPlanRequestFilter() {
    return this.maPlansRequestFilter
  }

  setMAPlanRequestFilter(_maRequestFilters: MAPlanFiltersRequest) {
    this.maPlansRequestFilter = _maRequestFilters
  }

  setSortDetail(_sortDetail: SortDetailType) {
    this.maPlansRequestFilter.sortDetail = _sortDetail
  }

  setPaginationDetails(_paginationDetail: PaginationDetailType) {
    this.maPlansRequestFilter.paginationDetail = _paginationDetail
  }

  setTotalCount(_totalCount: number | null) {
    this.totalCount = _totalCount
  }

  setPrevSortType(_prevSortType: string | null) {
    this.prevSortType = _prevSortType
  }

  setPaginationLoader(_paginationLoader: boolean) {
    this.paginationLoader = _paginationLoader
  }

  setPaginationApplied(_paginationApplied: boolean) {
    this.paginationApplied = _paginationApplied
  }

  getTotalCount() {
    return this.totalCount
  }

  getIsFilterChanged() {
    return this.isFilterChanged
  }

  setIsFilterChanged(_isFilterChanged: boolean) {
    this.isFilterChanged = _isFilterChanged
  }

  setIsRxIncluded(isRxApplied: boolean) {
    this.isRxApplied = isRxApplied
  }

  getIsRxIncluded() {
    return this.isRxApplied
  }

  setDrugNameOnAdditionOfDrug(drugName: string) {
    if (this.medicareAdvantageFilters.drugsCheckbox) {
      if (this.medicareAdvantageFilters.drugsCheckbox.length >= 0) {
        this.medicareAdvantageFilters.drugsCheckbox.push({
          checked: false,
          id: this.medicareAdvantageFilters.drugsCheckbox.length + 1,
          label: startCase(drugName),
          value: drugName,
        })
        if (this.medicareAdvantageFilters.drugsCheckbox.length > 1) {
          this.medicareAdvantageFilters.drugsCheckbox =
            this.medicareAdvantageFilters.drugsCheckbox.sort((a, b) =>
              a.label.localeCompare(b.label)
            )
        }
      }
    }
  }

  removeDrugNameOnDeletionOfDrug(drugName: string) {
    if (this.medicareAdvantageFilters.drugsCheckbox) {
      if (this.medicareAdvantageFilters.drugsCheckbox.length > 0) {
        this.medicareAdvantageFilters.drugsCheckbox =
          this.medicareAdvantageFilters.drugsCheckbox.filter((drugCheckbox) => {
            return drugCheckbox.value !== drugName
          })
        this.maPlansRequestFilter.prescriptions?.splice(
          this.maPlansRequestFilter.prescriptions.indexOf(drugName),
          1
        )
      }
    }
  }

  isFiltersActive(): boolean {
    return this.filtersActive
  }

  setFiltersActive(activeStatus: boolean) {
    this.filtersActive = activeStatus
  }

  clearStore() {
    this.medicareAdvantageFilters = {} as MedicareAdvantageDisplayFilter
    this.filterApplied = false
    this.clearFilters = false
    this.plansFetched = false
    this.filtersFetched = false
    this.totalCount = null
    this.filtersActive = false
  }

  clearMAPlanRequestFilters() {
    this.maPlansRequestFilter = {} as MAPlanFiltersRequest
  }
}

const medicareAdvantageFilterQuoteStore =
  new MedicareAdvantageFilterQuoteStore()

export default medicareAdvantageFilterQuoteStore
