import Footer from '../Footer/Footer.component'
import Helmet from 'react-helmet'
import Navbar from '../Navbar/Navbar.component'
import React from 'react'
import ScrollToTopButton from '../ScrollToTopButton'
import Snackbar from '../Snackbar/Snackbar'
import aetnaFavicon from '../../../assets/images/aetna-favicon.png'
import customerStore from '../../../datastore/CustomerStore'
import defaultFavicon from '../../../assets/images/flag-favicon.png'
import mutualOfOmahaFavicon from '../../../assets/images/mutual-of-omaha-favicon.ico'
import { observer } from 'mobx-react'
import snackbarStore from '../../../datastore/SnackbarStore'
import styled from '@emotion/styled'
import devotedFavicon from '../../../assets/images/devoted-favicon.png'

type SnackbarType = 'success' | 'error' | 'warning' | 'default'

interface LayoutProps {
  children?: JSX.Element | string | undefined
  bottomForScrollToTopButton?: string
  rightForScrollToTopButton?: string
  showDiscountsDisclaimer?: boolean
  type?: string
}

interface BannerProps {
  isBanner?: boolean
}

const Container = styled.div<BannerProps>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
`

const Wrapper = styled.div`
  padding-top: 0px;
`

const Layout: React.FC<LayoutProps> = ({
  children,
  bottomForScrollToTopButton,
  rightForScrollToTopButton,
  showDiscountsDisclaimer = false,
  type,
}) => {
  var isBanner = false
  if (
    customerStore.getBrandName() === 'aetna-medicare' ||
    customerStore.getBrandName() === 'mutual of omaha-medicare' ||
    customerStore.getBrandName() === 'medicareassociation' || customerStore.getBrandName() === 'devoted'
  ) {
    isBanner = true
  }

  const getFavicon = () => {
    const leadBrand = customerStore.getBrandName()

    if (leadBrand?.includes('aetna')) {
      return aetnaFavicon
    } else if (leadBrand?.includes('omaha')) {
      return mutualOfOmahaFavicon
    }
    else if (leadBrand?.includes('devoted')) {
      return devotedFavicon
    }
    return defaultFavicon
  }

  return (
    <Container isBanner={isBanner}>
      <Helmet>
        <title>Quoting Tool</title>
        <link rel='icon' href={getFavicon()} />
      </Helmet>
      <div>
        <Navbar type={type} isBanner={isBanner} />
        <Wrapper>{children}</Wrapper>
      </div>
      <Footer showDiscountsDisclaimer={showDiscountsDisclaimer} />
      <Snackbar
        open={snackbarStore.get().snackBarOpen}
        message={snackbarStore.get().snackbarMessage}
        type={snackbarStore.get().snackbarType as SnackbarType}
        onClose={() => {
          snackbarStore.reset()
        }}
      />
      <ScrollToTopButton
        bottom={bottomForScrollToTopButton}
        right={rightForScrollToTopButton}
      />
    </Container>
  )
}

export default observer(Layout)
