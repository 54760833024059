import { Checkbox, Radio, Rating } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { RiArrowDropDownLine, RiArrowDropUpLine } from 'react-icons/ri'
import { isEmpty, isEqual, isNull, isUndefined, set } from 'lodash'

import Button from '../../../common/Button/Button.component'
import CheckboxGroup from '../../../common/CheckboxGroup/CheckboxGroup.component'
import CloseIconButton from '../../../common/Button/CloseIconButton.component'
import { MParDPlanFiltersRequest } from '../../../../interface/quotes/mNonSupplementPlans/medicarePartD/request/MPartDPlanFiltersRequest'
import { MPartDPlanDisplayFilter } from '../../../../interface/quotes/mNonSupplementPlans/medicarePartD/MPartDPlanDisplayFilters'
import ModalComponent from '../../../common/Modal/Modal.component'
import OptionalMessage from '../../Common/OptionalMessageFilter'
import PaginationConstants from '../../../../constants/PaginationConstants'
import PlanYearChangeConfirmationPopUp from '../../Modals/PlanYearChangeConfirmationPopUp'
import QuotesConstants from '../../../../constants/QuotesConstants'
import RouteConstants from '../../../../constants/RouteConstants'
import SliderComponent from '../../../common/Slider/Slider'
import StringConstants from '../../../../constants/StringConstants'
import Tooltip from '../../../common/Tooltip/Tooltip.component'
import { convertToUSCurrency } from '../../../../utils/CurrencyConverterUtil'
import customerStore from '../../../../datastore/CustomerStore'
import { fireEvent } from '../../../../cra'
import mPartDPlansFilterStore from '../../../../datastore/medicareQuotes/MPartPlanFilterQuotesStore'
import { observer } from 'mobx-react-lite'
import styled from '@emotion/styled'
import theme from '../../../../global/theme'

interface FilterPrescriptionProps {
  showFilter: boolean
  handleShowFilter: () => void
  filterRef: React.MutableRefObject<any>
  clearFilterState: boolean
  clearButtonState: () => void
  setSelectedFilters?: (data: string[]) => void
  mPartDPlansFilters: MPartDPlanDisplayFilter
  setMPartDPlansFilters: (data: MPartDPlanDisplayFilter) => void
}

interface FilterContainerProps {
  showFilter?: boolean
  greyOut?: boolean
}

const FixedContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 360px;
  bottom: 10px;
  margin: 60px 0px 0px 0px;

  @media (max-width: 900px) {
    margin: 0px;
    width: 0px;
  }
`

const Container = styled.div<FilterContainerProps>`
  position: sticky;
  height: 150vh;
  top: 100px;

  .filterPlan {
    padding: 20px 0px;
    border-top: 1px solid ${theme.colors.borderColor};
    h3 {
      font-size: 16px;
      font-weight: bold;
      color: ${theme.colors.textDark};
      padding-bottom: 5px;
    }
    p {
      color: ${theme.colors.textGrey};
      font-weight: normal;
      font-size: 14px;
    }
  }

  @media screen and (max-width: 950px) {
    position: fixed;
    max-height: 100vh;
    top: 0;
    left: 0;
    margin: 0px 0px;
    margin-top: 75px;
    z-index: 100;
    max-width: 90%;
    width: 50%;
    overflow-y: auto;
    box-shadow: 8px 0px 108px -78px rgba(0, 0, 0, 0.75);
    display: ${({ showFilter }) => (showFilter ? 'block' : 'none')};
    height: 100vh;
  }

  @media screen and (max-width: 750px) {
    width: 60%;
    margin-top: 61px;
  }

  @media screen and (max-width: 650px) {
    width: 80%;
  }
  @media screen and (max-width: 450px) {
    margin-top: 53px;
  }
  opacity: ${({ greyOut }) => (greyOut ? 0.5 : 1)};
  pointer-events: ${({ greyOut }) => (greyOut ? 'none' : 'auto')};
`

const Wrapper = styled.div`
  border-right: 1px solid ${theme.colors.borderColor};
  padding: 0px 50px 0px 20px;
  @media screen and (max-width: 950px) {
    padding: 90px 50px 50px 50px;
    background-color: ${theme.colors.white};
    border-right: none;
    overflow-y: scroll;
    height: 100%;
  }
`

const FiltersWrapper = styled.div``

const Filter = styled.div`
  padding: 15px 0px;
  border-top: 1px solid ${theme.colors.borderColor};
`

const Label = styled.p`
  color: ${theme.colors.primary};
  font-size: 16px;
  font-weight: 700;
  padding-right: 20px;
  margin: 5px 0px;
`

const CloseButtonWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  padding: 20px;
  display: none;
  @media screen and (max-width: 950px) {
    padding-top: 50px;
    display: block;
  }
`

const FloatingFilterContainer = styled.div`
  position: sticky;
  top: 0;
  background-color: ${theme.colors.white};
  z-index: 100;
  width: 105%;
  padding: 16px 20px;
  border: solid 1px ${theme.colors.borderColor};
  border-radius: 4px;

  h3 {
    color: ${theme.colors.primary};
    font-size: 18px;
    margin-bottom: 12px;
    text-align: center;
  }

  @media (max-width: 900px) {
    top: 40px;
  }
`

const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

const SliderContentContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

const Overlay = styled.div<FilterContainerProps>`
  @media screen and (max-width: 950px) {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 99;
    display: ${({ showFilter }) => (showFilter ? 'block' : 'none')};
    transition: background-color 0.3s ease;
  }
`

const FilterPrescription: React.FC<FilterPrescriptionProps> = ({
  showFilter,
  handleShowFilter,
  filterRef,
  clearFilterState,
  clearButtonState,
  setSelectedFilters,
  mPartDPlansFilters,
  setMPartDPlansFilters,
}) => {
  const mPartDFilterRequest: MParDPlanFiltersRequest = {}
  const [
    openPlanYearFilterConfirmationPopup,
    setOpenPlanYearFilterConfirmationPopup,
  ] = useState<boolean>(false)
  const [tempPlanYear, setTempPlanYear] = useState('')

  const [monthlyPremiumSliderValue, setMonthlyPremiumSliderValue] =
    useState<number>(
      mPartDPlansFilterStore.getMPartDPlansFilters().monthlyPremium.currentValue
    )
  const [drugSliderValue, setDrugSliderValue] = useState<number>(
    mPartDPlansFilterStore.getMPartDPlansFilters().deductible.currentValue
  )

  const [carrierDropdown, setCarrierDropdown] = useState(false)
  const [drugsDropdown, setDrugsDropdown] = useState(false)
  const [providersDropDown, setProvidersDropDown] = useState(false)

  useEffect(() => {
    if (mPartDPlansFilters.clearFiltersApplied) {
      mPartDPlansFilterStore.setClearFilters(true)
      mPartDPlansFilters.clearFiltersApplied = false
      setMPartDPlansFilters({
        ...mPartDPlansFilters,
        clearFiltersApplied: false,
      })
      mPartDPlansFilterStore.setPartDPlansFilters(mPartDPlansFilters)
      mPartDPlansFilterStore.clearMPartDPlanRequestFilters()
    }
  }, [mPartDPlansFilters.clearFiltersApplied])

  useEffect(() => {
    handleSelectedFilters()
  }, [mPartDPlansFilters])

  const renderCount = useRef(0)
  useEffect(() => {
    // renderCount.current = renderCount.current + 1
    // if (renderCount.current > 1) {
    if (mPartDPlansFilterStore.getIsFilterChanged()) {
      applyFilters()
      handleSelectedFilters()
      // mPartDPlansFilterStore.setIsFilterChanged(false)
    }
    // }
  }, [mPartDPlansFilterStore.getIsFilterChanged()])

  const handleSelectedFilters = () => {
    const carrierFilters = mPartDPlansFilters.carriers
      ?.filter((carrier) => carrier.checked === true)
      .map((carrier) => carrier.value)
    const drugsFilters = mPartDPlansFilters.drugCheckBoxes
      ?.filter((drug) => drug.checked === true)
      .map((drug) => drug.value)
    const providerFilters = mPartDPlansFilters.providers
      ?.filter((provider) => provider.checked === true)
      .map((provider) => provider.value)
    const selectedCarrierFilters = Array.isArray(carrierFilters)
      ? carrierFilters
      : [carrierFilters]
    const selectedDrugsFilters = Array.isArray(drugsFilters)
      ? drugsFilters
      : [drugsFilters]
    const selectProviderFilters = Array.isArray(providerFilters)
      ? providerFilters
      : [providerFilters]

    setSelectedFilters &&
      setSelectedFilters([
        ...selectedCarrierFilters,
        ...selectedDrugsFilters,
        ...selectProviderFilters,
      ])

    mPartDPlansFilterStore.setFiltersActive(
      [
        ...selectedCarrierFilters,
        ...selectedDrugsFilters,
        ...selectProviderFilters,
      ].length !== 0
    )
  }

  const handleDrugsCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (mPartDPlansFilters) {
      const checkedDrugs = mPartDPlansFilters.drugCheckBoxes?.map((item) =>
        item.value === e.target.value
          ? { ...item, checked: !item.checked }
          : item
      )
      setMPartDPlansFilters({
        ...mPartDPlansFilters,
        drugCheckBoxes: checkedDrugs,
      })
      mPartDPlansFilterStore.setIsFilterChanged(true)
    }
  }

  const handleProvidersCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (mPartDPlansFilters) {
      const checkedProviders = mPartDPlansFilters.providers?.map((item) =>
        item.value.toString() === e.target.value.toString()
          ? { ...item, checked: !item.checked }
          : item
      )
      setMPartDPlansFilters({
        ...mPartDPlansFilters,
        providers: checkedProviders,
      })
      mPartDPlansFilterStore.setIsFilterChanged(true)
    }
  }

  const handleRatingChange = (value: number | null) => {
    if (mPartDPlansFilters) {
      if (
        value &&
        value >= mPartDPlansFilterStore.mPartDPlanFilters.minStarRating.minValue
      )
        setMPartDPlansFilters({
          ...mPartDPlansFilters,
          minStarRating: {
            ...mPartDPlansFilters.minStarRating,
            currentValue: value,
          },
        })
      mPartDPlansFilterStore.setIsFilterChanged(true)
    }
  }

  const togglePlanYearFilterPopupOpen = () => {
    setOpenPlanYearFilterConfirmationPopup(!openPlanYearFilterConfirmationPopup)
  }
  const handleOnConfirmPlanYearChange = () => {
    setMPartDPlansFilters({
      ...mPartDPlansFilters!,
      currentPlanYear: tempPlanYear,
    })
    customerStore.setMPartDFilterYear(tempPlanYear)
    customerStore.setMPDPageSize(0)
    togglePlanYearFilterPopupOpen()
    window.scrollTo({ top: 0, behavior: 'smooth' })
    // mPartDPlansFilterStore.setIsFilterChanged(true)
  }

  const handleOnRejectPlanYearChange = () => {
    setTempPlanYear('')
    togglePlanYearFilterPopupOpen()
  }

  const handlePlanYear = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (mPartDPlansFilters) {
      const year = e.target.value
      if (e.target.checked) {
        setTempPlanYear(year.toString())
        togglePlanYearFilterPopupOpen()
      }
    }
  }

  const handleMaxMonthlyPremium = (e: any) => {
    if (mPartDPlansFilters) {
      setMPartDPlansFilters({
        ...mPartDPlansFilters,
        monthlyPremium: {
          ...mPartDPlansFilters.monthlyPremium,
          currentValue: monthlyPremiumSliderValue,
        },
      })
      mPartDPlansFilterStore.setIsFilterChanged(true)
    }
  }

  const handleMaxDrugDeductible = (e: any) => {
    if (mPartDPlansFilters) {
      setMPartDPlansFilters({
        ...mPartDPlansFilters,
        deductible: {
          ...mPartDPlansFilters.deductible,
          currentValue: drugSliderValue,
        },
      })
      mPartDPlansFilterStore.setIsFilterChanged(true)
    }
  }

  const handleCarrierCheckboxChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (mPartDPlansFilters) {
      const checkedCarriers = mPartDPlansFilters.carriers?.map((item) =>
        item.value === e.target.value
          ? { ...item, checked: !item.checked }
          : item
      )
      setMPartDPlansFilters({
        ...mPartDPlansFilters,
        carriers: checkedCarriers,
      })
      mPartDPlansFilterStore.setIsFilterChanged(true)
    }
  }

  const applyFilters = () => {
    if (!isEmpty(mPartDPlansFilters)) {
      mPartDFilterRequest.carriers = mPartDPlansFilters.carriers
        .filter((item) => item.checked)
        .map((item) => item.id)
      mPartDFilterRequest.prescriptions = mPartDPlansFilters.drugCheckBoxes
        .filter((item) => item.checked)
        .map((item) => (item.optionalValue ? item.optionalValue : ''))
      mPartDFilterRequest.providers = mPartDPlansFilters.providers
        .filter((item) => item.checked)
        .map((item) => (item.optionalValue ? item.optionalValue : ''))
      if (
        mPartDPlansFilters.minStarRating.currentValue !==
        mPartDPlansFilters.minStarRating.minValue
      ) {
        mPartDFilterRequest.minStarRating = {
          start: mPartDPlansFilters.minStarRating.minValue,
          end: mPartDPlansFilters.minStarRating.currentValue,
        }
      }

      if (
        mPartDPlansFilters.deductible.currentValue !==
        mPartDPlansFilters.deductible.maxValue
      ) {
        mPartDFilterRequest.deductible = {
          start: mPartDPlansFilters.deductible.minValue,
          end: mPartDPlansFilters.deductible.currentValue,
        }
      }
      if (
        mPartDPlansFilters.monthlyPremium.currentValue !==
        mPartDPlansFilters.monthlyPremium.maxValue
      ) {
        mPartDFilterRequest.monthlyPremium = {
          start: mPartDPlansFilters.monthlyPremium.minValue,
          end: mPartDPlansFilters.monthlyPremium.currentValue,
        }
      }

      if (mPartDPlansFilters.currentPlanYear !== '') {
        mPartDFilterRequest.planYear =
          customerStore.mPartDFilterYear === ''
            ? mPartDPlansFilters.currentPlanYear
            : customerStore.mPartDFilterYear
      }

      if (
        !isNull(mPartDPlansFilterStore.mPartDPlanFilters.selectSortOption) &&
        mPartDPlansFilterStore.mPartDPlanFilters.selectSortOption !== ''
      ) {
        if (
          mPartDPlansFilterStore.mPartDPlanFilters.selectSortOption ===
          'Overall Rating'
        ) {
          mPartDFilterRequest.sortDetail = {
            order: 'DESC',
            type: mPartDPlansFilterStore.mPartDPlanFilters.selectSortOption,
          }
        } else {
          mPartDFilterRequest.sortDetail = {
            order: 'ASC',
            type: mPartDPlansFilterStore.mPartDPlanFilters.selectSortOption,
          }
        }
      }

      if (
        !isEqual(
          JSON.stringify(mPartDFilterRequest),
          JSON.stringify(mPartDPlansFilterStore.getMPartDPlanRequestFilters())
        )
      ) {
        mPartDPlansFilters.selectSortOption =
          mPartDPlansFilterStore.mPartDPlanFilters.selectSortOption
        mPartDPlansFilterStore.setFilterApplied(true)
        mPartDPlansFilterStore.setPartDPlansFilters(mPartDPlansFilters)

        mPartDFilterRequest.paginationDetail = {
          page: PaginationConstants.DEFAULT_PAGE,
          size: PaginationConstants.DEFAULT_PAGE_SIZE,
        }
        // mPartDFilterRequest.sortDetail =
        //   mPartDPlansFilterStore.getMPartDPlanRequestFilters().sortDetail

        mPartDPlansFilterStore.setMPartDPlanRequestFilters(mPartDFilterRequest)
      }
      customerStore.setMPDPageSize(PaginationConstants.DEFAULT_PAGE_SIZE)
      handleShowFilter()
    }
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  const clearFilters = () => {
    if (mPartDPlansFilters) {
      setMonthlyPremiumSliderValue(mPartDPlansFilters.monthlyPremium.maxValue)
      setDrugSliderValue(mPartDPlansFilters.deductible.maxValue)
      setMPartDPlansFilters({
        ...mPartDPlansFilters,
        deductible: {
          ...mPartDPlansFilters.deductible,
          currentValue: mPartDPlansFilters.deductible.maxValue,
        },
        carriers: mPartDPlansFilters.carriers.map((item) => ({
          ...item,
          checked: false,
        })),
        currentPlanYear: mPartDPlansFilters.planYear.defaultPlanYear,
        drugCheckBoxes: mPartDPlansFilters.drugCheckBoxes.map((item) => ({
          ...item,
          checked: false,
        })),
        providers: mPartDPlansFilters.providers.map((item) => ({
          ...item,
          checked: false,
        })),
        monthlyPremium: {
          ...mPartDPlansFilters.monthlyPremium,
          currentValue: mPartDPlansFilters.monthlyPremium.maxValue,
        },
        minStarRating: {
          ...mPartDPlansFilters?.minStarRating,
          currentValue: mPartDPlansFilters.minStarRating.minValue,
        },
        selectSortOption: QuotesConstants.initialMPartDPlanSortOption,
        clearFiltersApplied: true,
      })
      setSelectedFilters && setSelectedFilters([])
    }
  }

  const handleDropdownClick = (id: string) => {
    switch (id) {
      case 'carrier':
        setCarrierDropdown(!carrierDropdown)
        setDrugsDropdown(false)
        setProvidersDropDown(false)
        break
      case 'drugs':
        setDrugsDropdown(!drugsDropdown)
        setCarrierDropdown(false)
        setProvidersDropDown(false)
        break
      case 'providers':
        setProvidersDropDown(!providersDropDown)
        setDrugsDropdown(false)
        setCarrierDropdown(false)
        break
    }
  }

  useEffect(() => {
    if (clearFilterState) {
      clearFilters()
      clearButtonState()
    }
  }, [clearFilterState])

  return mPartDPlansFilters ? (
    <FixedContainer ref={filterRef}>
      {openPlanYearFilterConfirmationPopup && (
        <ModalComponent
          setOpen={handleOnRejectPlanYearChange}
          hideCloseButton={true}
        >
          <PlanYearChangeConfirmationPopUp
            onConfirm={handleOnConfirmPlanYearChange}
            onReject={handleOnRejectPlanYearChange}
            year={tempPlanYear}
          />
        </ModalComponent>
      )}
      <Overlay showFilter={showFilter} />
      <Container
        greyOut={mPartDPlansFilterStore.filterApplied}
        showFilter={showFilter}
      >
        <CloseButtonWrapper>
          <CloseIconButton
            fontSize='32px'
            onClick={() => {
              handleShowFilter()
            }}
          />
        </CloseButtonWrapper>
        <Wrapper>
          <OptionalMessage
            heading='Showing plans for'
            link={RouteConstants.PROFILE + '/myprofile/contact'}
            linkText={customerStore.getPostalCodeAndCounty()}
            showToolTip={true}
          />
          {/* <FloatingFilterContainer>
            <h3>FILTER PLANS</h3>
            <ButtonsContainer>
              <Button
                width='80px'
                variant='contained'
                color='primary'
                bold={false}
                height='30px'
                margin='0 12px 0 0'
                onClick={applyFilters}
              >
                Apply
              </Button>
              <Button
                width='80px'
                variant='outlined'
                color='secondary'
                bold={false}
                height='30px'
                margin='0px'
                onClick={clearFilters}
              >
                Clear
              </Button>
            </ButtonsContainer>
          </FloatingFilterContainer> */}
          {/* TODO - This has to be Dynamic - Validate working */}
          <FiltersWrapper>
            {mPartDPlansFilters.planYear && (
              <Filter
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Label>Plan Year</Label>
                {/* <Checkbox
                  value={mPartDPlansFilters.planYear[0]}
                  onChange={(e) => handlePlanYear(e)}
                  size='small'
                  checked={
                    mPartDPlansFilters.currentPlanYear.toString() ===
                    mPartDPlansFilters.planYear[0].toString()
                      ? true
                      : false
                  }
                />
                {mPartDPlansFilters.planYear[0]}
                {mPartDPlansFilters.planYear.length > 1 && (
                  <>
                    <Checkbox
                      value={mPartDPlansFilters.planYear[1]}
                      onChange={(e) => handlePlanYear(e)}
                      size='small'
                      checked={
                        mPartDPlansFilters.currentPlanYear.toString() ===
                        mPartDPlansFilters.planYear[1].toString()
                          ? true
                          : false
                      }
                    />
                    {mPartDPlansFilters.planYear[1]}
                  </>
                )} */}
                {mPartDPlansFilters.planYear.planYears.map((planYear) => (
                  <>
                    <Radio
                      value={planYear}
                      onChange={handlePlanYear}
                      size='small'
                      checked={
                        customerStore.mPartDFilterYear === ''
                          ? mPartDPlansFilters.currentPlanYear.toString() ===
                          planYear.toString()
                          : customerStore.mPartDFilterYear ===
                          planYear.toString()
                      }
                    />
                    {planYear}
                  </>
                ))}
              </Filter>
            )}
            {mPartDPlansFilters.minStarRating && (
              <Filter
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Label>
                  Star Rating
                  <Tooltip
                    title={StringConstants.TOOLTIP_MSG_MPARTD_STAR_RATING}
                  />
                </Label>
                <Rating
                  name='simple-controlled'
                  value={mPartDPlansFilters.minStarRating.currentValue}
                  precision={0.5}
                  onChange={(event, newValue) => {
                    fireEvent('starRatingInput', event, {
                      value: newValue,
                      label: 'Star Rating',
                      description:
                        'Star Rating Filter - Medicare Prescription Drugs',
                    })
                    handleRatingChange(newValue)
                  }}
                />
              </Filter>
            )}

            {mPartDPlansFilters.carriers && (
              <Filter>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    handleDropdownClick('carrier')
                  }}
                >
                  <Label>
                    Carrier
                    <Tooltip
                      title={StringConstants.TOOLTIP_MSG_MPARTD_CARRIERS}
                    />
                  </Label>

                  {carrierDropdown ? (
                    <RiArrowDropUpLine
                      style={{ cursor: 'pointer', marginLeft: 'auto' }}
                    />
                  ) : (
                    <RiArrowDropDownLine
                      style={{ cursor: 'pointer', marginLeft: 'auto' }}
                    />
                  )}
                </div>
                {carrierDropdown ? (
                  <div style={{ maxHeight: '200px', overflowY: 'scroll' }}>
                    <CheckboxGroup
                      checkboxState={mPartDPlansFilters.carriers}
                      onChange={handleCarrierCheckboxChange}
                      width='100%'
                      type='unstyled'
                      checkboxGroupName='Carrier Filters - Medicare Prescription Drugs'
                    />
                  </div>
                ) : null}
              </Filter>
            )}
            {/* customerStore.isPharmacyAdded() && */}
            {mPartDPlansFilters.drugCheckBoxes &&
              mPartDPlansFilters.drugCheckBoxes.length > 0 && (
                <Filter>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      handleDropdownClick('drugs')
                    }}
                  >
                    <Label>
                      Drugs Covered
                      <Tooltip
                        title={StringConstants.TOOLTIP_MSG_MPARTD_DRUGS_COVERED}
                      />
                    </Label>
                    {drugsDropdown ? (
                      <RiArrowDropUpLine
                        style={{ cursor: 'pointer', marginLeft: 'auto' }}
                      />
                    ) : (
                      <RiArrowDropDownLine
                        style={{ cursor: 'pointer', marginLeft: 'auto' }}
                      />
                    )}
                  </div>
                  {drugsDropdown ? (
                    <div style={{ maxHeight: '350px', overflowY: 'scroll' }}>
                      <CheckboxGroup
                        checkboxState={mPartDPlansFilters.drugCheckBoxes}
                        onChange={handleDrugsCheckbox}
                        width='100%'
                        type='unstyled'
                        checkboxGroupName='Drugs Covered Filter - Medicare Prescription Drugs'
                      />
                    </div>
                  ) : null}
                </Filter>
              )}
            {mPartDPlansFilters.providers?.length > 0 && (
              <Filter key={'Providers_Coverage'}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    handleDropdownClick('providers')
                  }}
                >
                  <Label>
                    Physician Covered
                    <Tooltip
                      title={StringConstants.TOOLTIP_MSG_MA_Physician_COVERED}
                    />
                  </Label>
                  {providersDropDown ? (
                    <RiArrowDropUpLine
                      style={{ cursor: 'pointer', marginLeft: 'auto' }}
                    />
                  ) : (
                    <RiArrowDropDownLine
                      style={{ cursor: 'pointer', marginLeft: 'auto' }}
                    />
                  )}
                </div>
                {providersDropDown ? (
                  <div style={{ maxHeight: '350px', overflowY: 'scroll' }}>
                    <CheckboxGroup
                      checkboxState={mPartDPlansFilters.providers}
                      onChange={handleProvidersCheckbox}
                      width='100%'
                      type='unstyled'
                      checkboxGroupName='Drugs Covered Filter - Medicare Advantage'
                    />
                  </div>
                ) : null}
              </Filter>
            )}
            {mPartDPlansFilters.monthlyPremium && (
              <Filter>
                <SliderComponent
                  label='Maximum Monthly Premium'
                  minValue={mPartDPlansFilters.monthlyPremium.minValue}
                  maxValue={mPartDPlansFilters.monthlyPremium.maxValue}
                  step={mPartDPlansFilters.monthlyPremium.stepValue}
                  size='small'
                  value={monthlyPremiumSliderValue}
                  onChange={(e: any) => {
                    setMonthlyPremiumSliderValue(e.target.value)
                  }}
                  onChangeCommitted={(e: any) => {
                    handleMaxMonthlyPremium(e)
                    const label = 'Maximum Monthly Premium'
                    const value = monthlyPremiumSliderValue
                    fireEvent('sliderInput', e, {
                      label,
                      value,
                      description: `Value of ${label} set to ${value}`,
                    })
                  }}
                  withTooltip={true}
                  tooltipContent={
                    StringConstants.TOOLTIP_MSG_MAXIMUM_MONTHLY_PREMIUM
                  }
                />
                <SliderContentContainer>
                  <div>
                    {convertToUSCurrency(
                      mPartDPlansFilters.monthlyPremium.minValue
                    )}
                  </div>
                  <div>
                    {convertToUSCurrency(
                      mPartDPlansFilters.monthlyPremium.maxValue
                    )}
                  </div>
                </SliderContentContainer>
              </Filter>
            )}
            {!isUndefined(mPartDPlansFilters.deductible) && (
              <Filter>
                <SliderComponent
                  label='Deductible'
                  minValue={mPartDPlansFilters.deductible.minValue}
                  maxValue={mPartDPlansFilters.deductible.maxValue}
                  step={mPartDPlansFilters.deductible.stepValue}
                  size='small'
                  value={drugSliderValue}
                  onChange={(e: any) => {
                    setDrugSliderValue(e.target.value)
                  }}
                  onChangeCommitted={(e: any) => {
                    handleMaxDrugDeductible(e)
                    const label = 'Deductible'
                    const value = drugSliderValue
                    fireEvent('sliderInput', e, {
                      label,
                      value,
                      description: `Value of ${label} set to ${value}`,
                    })
                  }}
                  withTooltip={true}
                  tooltipContent={
                    StringConstants.TOOLTIP_MSG_PDP_PLAN_DEDUCTIBLE
                  }
                />
                <SliderContentContainer>
                  <div>
                    {convertToUSCurrency(
                      mPartDPlansFilters.deductible.minValue
                    )}
                  </div>
                  <div>
                    {convertToUSCurrency(
                      mPartDPlansFilters.deductible.maxValue
                    )}
                  </div>
                </SliderContentContainer>
              </Filter>
            )}
          </FiltersWrapper>
        </Wrapper>
      </Container>
    </FixedContainer>
  ) : null
}

export default observer(FilterPrescription)
