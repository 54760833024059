import MuiCircularProgress, {
  CircularProgressProps,
  circularProgressClasses,
} from '@mui/material/CircularProgress'

import Box from '@mui/material/Box'
import React from 'react'
import Typography from '@mui/material/Typography'
import { isUndefined } from 'lodash'
import { observer } from 'mobx-react'
import theme from '../../../global/theme'

const CircularProgressBar = (
  props: CircularProgressProps & { value: number, visible: boolean }
) => {
  let visible = props.visible
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex', alignItems: 'center' }}>
      <MuiCircularProgress
        variant='determinate'
        sx={{
          color: visible? `${theme.colors.borderColor}` : 'white',
        }}
        size={60}
        thickness={4}
        {...props}
        {...{visible:''}}
        value={100}
      />
      <MuiCircularProgress
        variant='determinate'
        size={60}
        thickness={4}
        sx={{
          position: 'absolute',
          left: 0,
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: 'round',
          },
          color: visible? 'primary' : 'white'
        }}
        {...props}
        {...{visible:''}}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          height: '100%',
          width: '100%',
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography
          variant='caption'
          component='div'
          color={visible ? 'primary' : 'white'}
          fontWeight='bold'
          fontSize='16px'
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  )
}

const CircularProgress: React.FC<{ progress: number, visible?: boolean }> = ({ progress, visible }) => {
  return <CircularProgressBar value={progress} visible={isUndefined(visible) ? true : visible}/>
}

export default observer(CircularProgress)
