import customerStore from '../datastore/CustomerStore'
import dayjs from 'dayjs'

const getKeyByValueOfEnum = <T extends string>(
  enumObject: { [key: string]: T },
  value: T
): keyof typeof enumObject | undefined => {
  return Object.keys(enumObject).find((key) => enumObject[key] === value)
}

const getDateWithinApprovedDOBRange = (e: any) => {
  const defaultYear = dayjs().year() - 67
  const todayDate = dayjs(new Date()).format('YYYY-MM-DD')
  const selectedDate = dayjs(e.$d).format('YYYY-MM-DD')
  const today = new Date(todayDate)
  const selected = new Date(selectedDate)
  let minDateYear = Number(
    (dayjs().year() - 120).toString().substring(0, 2) + '00'
  )
  let finalDate
  if (today >= selected) {
    if (dayjs(selectedDate).toDate().getFullYear() >= minDateYear) {
      finalDate = selectedDate
    } else {
      finalDate = dayjs(selected).year(minDateYear).format('YYYY-MM-DD')
    }
  } else {
    finalDate = dayjs(selected).year(defaultYear).format('YYYY-MM-DD')
  }
  return finalDate
}

// Default plan year during AEP period
const getPlanYear = () => {
  const currentYear = new Date().getFullYear()
  const planYear = customerStore.getAepStatus()
    ? customerStore && customerStore.currentMedicarePartBDateSelf
      ? Number(customerStore.currentMedicarePartBDateSelf.substring(0, 4)) <
        currentYear + 1
        ? currentYear
        : currentYear + 1
      : currentYear + 1
    : currentYear
  return planYear
}
export { getKeyByValueOfEnum, getDateWithinApprovedDOBRange, getPlanYear }
