import { ActionContext, fireEvent } from '../../../../cra'
import React, { useEffect, useRef, useState } from 'react'
import { filter, isEmpty, isEqual, isNull } from 'lodash'
import { isUndefined, set } from 'lodash'

import BottomAddToCompare from '../../BottomAddToCompare/BottomAddToCompare.component'
import Button from '../../../common/Button/Button.component'
import CallUsNow from '../../Common/Recommendation/CallUsNow.component'
import FilterAdvantage from './FilterAdvantage.component'
import FilterBox from '../../../../components/DashboardPage/Common/FilterBox.component.tsx/FilterBox'
import { MAPlan } from '../../../../interface/quotes/mNonSupplementPlans/medicareAdvantage/MAPlan'
import { MNonSupplementPlan } from '../../../../interface/quotes/mNonSupplementPlans/MNonSupplementPlan'
import MedicareAdvantageCard from './MedicareAdvantageCard.component'
import { MedicareAdvantageDisplayFilter } from '../../../../interface/quotes/mNonSupplementPlans/medicareAdvantage/MedicareAdvantageDisplayFilters'
import { MedicareQuotesType } from '../../../../enums/MedicareQuoteTypeEnum'
import ModalComponent from '../../../common/Modal/Modal.component'
import NoPlanFoundCard from '../../NoPlanFoundCard'
import { Pagination } from '@mui/material'
import PaginationConstants from '../../../../constants/PaginationConstants'
import { RiFilter2Fill } from 'react-icons/ri'
import SortBy from '../../Common/SortByDropdown.component.tsx/SortBy'
import ThankYouModal from '../../Modals/ThankYouModal'
import { ThreeDots } from 'react-loader-spinner'
import customerStore from '../../../../datastore/CustomerStore'
import { getContact } from '../../../../utils/getContact'
import { log } from 'console'
import medicareAdvantageFilterQuoteStore from '../../../../datastore/medicareQuotes/MedicareAdvantageFilterQuoteStore'
import { observer } from 'mobx-react'
import styled from '@emotion/styled'
import theme from '../../../../global/theme'
import userActionStore from '../../../../datastore/UserActionStore'

const Container = styled.div`
  display: flex;
  justify-content: center;
  @media screen and (max-width: 950px) {
    justify-content: center;
  }
`
const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 1.25em 0;
`

const CardsWrapper = styled.div`
  padding: 20px 20px 150px 20px;
`
const UtilityContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  /* justify-content: space-between; */
  grid-gap: 20px;
  margin: 30px 20px;
  padding: 10px 0px;
  border-bottom: 1px solid ${theme.colors.borderColor};
  h3 {
    display: none;
    align-items: center;
    padding: 8px 24px;
    border-radius: 4px;
    border: 1px solid ${theme.colors.primary};
    @media screen and (max-width: 950px) {
      cursor: pointer;
      display: flex;
    }
  }
  @media screen and (max-width: 640px) {
    margin: 30px 0px;
  }
`
const ThreeDotsWrapper = styled.div`
  display: flex;
  margin: 20px;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  width: 900px;

  @media screen and (max-width: 1250px) {
    width: 700px;
  }

  @media screen and (max-width: 1050px) {
    width: 600px;
  }

  @media screen and (max-width: 640px) {
    width: 100%;
    margin: 20px 0px;
  }
`

const FilterContainer = styled.div`
  display: block;

  @media screen and (max-width: 600px) {
    display: flex;
    flex-direction: column-reverse;
  }
`

const MedicareAdvantage: React.FC<{
  medicareAdvantagePlans: MNonSupplementPlan[]
}> = ({ medicareAdvantagePlans }) => {
  const paginationCount = Math.ceil(
    isNull(medicareAdvantageFilterQuoteStore.getTotalCount())
      ? 0
      : medicareAdvantageFilterQuoteStore.getTotalCount()! /
          (medicareAdvantageFilterQuoteStore.getMAPlanRequestFilter()
            .paginationDetail?.size as number)
  )
  const [thankYouModalActive, setThankYouModalActive] = React.useState(false)
  const [sortPlanOptions, setSortPlanOptions] = useState<
    {
      title: string
      value: string
    }[]
  >([])
  const [currentSortOption, setCurrentSortOption] = useState<{
    label: string
    index: number
  }>()

  const [maPlansFilters, setMAPlansFilters] =
    useState<MedicareAdvantageDisplayFilter>(
      {} as MedicareAdvantageDisplayFilter
    )
  const { trackCurrentPage } = React.useContext(ActionContext)

  const [showFilter, setShowFilter] = React.useState(false)
  const [selectedFilters, setSelectedFilters] = useState<string[]>([])
  const filterAdvantageRef = useRef<any>(null)
  const handleShowFilter = () => {
    setShowFilter(!showFilter)
  }

  const [clearFilterState, setClearFilterState] = React.useState(false)

  useEffect(() => {
    if (
      !isUndefined(
        medicareAdvantageFilterQuoteStore.getMedicareAdvantageFilters()
      )
    ) {
      setMAPlansFilters(
        medicareAdvantageFilterQuoteStore.getMedicareAdvantageFilters()
      )
    }
  }, [medicareAdvantageFilterQuoteStore.getMedicareAdvantageFilters()])

  useEffect(() => {
    trackCurrentPage('Dashboard - Medicare Advantage')
  }, [trackCurrentPage])

  useEffect(() => {
    if (
      !isEqual(
        medicareAdvantageFilterQuoteStore.getMedicareAdvantageFilters(),
        {}
      )
    ) {
      const planOptions = medicareAdvantageFilterQuoteStore
        .getMedicareAdvantageFilters()
        .sortOptions.map((option) => {
          return { title: option, value: option }
        })
      // const temp = sortPlanOptions.findIndex((option) => {
      //   return (
      //     option.title ===
      //     medicareAdvantageFilterQuoteStore.getMedicareAdvantageFilters()
      //       .selectSortOption!
      //   )
      // })

      setSortPlanOptions(planOptions)

      setCurrentSortOption({
        label:
          medicareAdvantageFilterQuoteStore.getMedicareAdvantageFilters()
            .selectSortOption!,
        index: planOptions.findIndex((option) => {
          return (
            option.title ===
            medicareAdvantageFilterQuoteStore.getMedicareAdvantageFilters()
              .selectSortOption!
          )
        }),
      })
    }
  }, [
    JSON.stringify(
      medicareAdvantageFilterQuoteStore.medicareAdvantageFilters
        .selectSortOption
    ),
  ])

  useEffect(() => {
    if (!isEmpty(medicareAdvantagePlans)) {
      medicareAdvantageFilterQuoteStore.setPlansFetched(true)
      medicareAdvantageFilterQuoteStore.setPaginationApplied(false)
      medicareAdvantageFilterQuoteStore.setPaginationLoader(false)
    }
    medicareAdvantageFilterQuoteStore.setClearFilters(false)
  }, [medicareAdvantagePlans])

  const handleSortChange = (e: any) => {
    const sortOption: string = e.target.innerText
      .split()[0]
      .split(' (High to Low)')[0]

    if (
      medicareAdvantageFilterQuoteStore.getMedicareAdvantageFilters()
        .selectSortOption !== sortOption
    ) {
      medicareAdvantageFilterQuoteStore.setPrevSortType(
        medicareAdvantageFilterQuoteStore.getMedicareAdvantageFilters()
          .selectSortOption
      )

      medicareAdvantageFilterQuoteStore.getMedicareAdvantageFilters().selectSortOption =
        sortOption

      if (sortOption === 'Overall Rating') {
        medicareAdvantageFilterQuoteStore.setSortDetail({
          order: 'DESC',
          type: sortOption,
        })
      } else {
        medicareAdvantageFilterQuoteStore.setSortDetail({
          order: 'ASC',
          type: sortOption,
        })
      }
      medicareAdvantageFilterQuoteStore.setFilterApplied(true)

      medicareAdvantageFilterQuoteStore.setPaginationDetails({
        page: PaginationConstants.DEFAULT_PAGE,
        size: PaginationConstants.DEFAULT_PAGE_SIZE,
      })
      customerStore.setMAPageNumber(PaginationConstants.DEFAULT_PAGE)
      customerStore.setMAPageSize(PaginationConstants.DEFAULT_PAGE_SIZE)
    }
  }

  const handlePaginationChange = () => {
    medicareAdvantageFilterQuoteStore.setPaginationLoader(true)
    medicareAdvantageFilterQuoteStore.setPaginationDetails({
      page: customerStore.maPageNumber + PaginationConstants.DEFAULT_PAGE,
      size: PaginationConstants.DEFAULT_PAGE_SIZE,
    })
    customerStore.setMAPageSize(
      customerStore.maPageSize + PaginationConstants.DEFAULT_PAGE_SIZE
    )
    customerStore.setMAPageNumber(
      customerStore.maPageNumber + PaginationConstants.DEFAULT_PAGE
    )
    medicareAdvantageFilterQuoteStore.setPaginationApplied(true)
  }

  const handleOnEnroll = () => {
    setThankYouModalActive(true)
  }

  useEffect(() => {
    const closeFilterAdvantage = (e: any) => {
      if (
        filterAdvantageRef.current !== null &&
        e.composedPath() &&
        !e
          .composedPath()
          .includes(filterAdvantageRef.current.lastElementChild) &&
        showFilter === true
      ) {
        setShowFilter(false)
      }
    }
    document.body.addEventListener('click', closeFilterAdvantage)
    return () =>
      document.body.removeEventListener('click', closeFilterAdvantage)
  }, [])

  const clearButtonState = () => {
    setClearFilterState(!clearFilterState)
  }

  const updateFilters = (unFilter: string) => {
    selectedFilters.splice(selectedFilters.indexOf(unFilter), 1)
    if (maPlansFilters) {
      const checkedCarriers = maPlansFilters.carriers?.map((item) =>
        item.value === unFilter ? { ...item, checked: !item.checked } : item
      )

      const checkedPlanBenefits = maPlansFilters.planBenefitsCheckbox?.map(
        (item) =>
          item.value === unFilter ? { ...item, checked: !item.checked } : item
      )

      const checkedPlanTypes = maPlansFilters.planTypeCheckbox?.map((item) =>
        item.value === unFilter ? { ...item, checked: !item.checked } : item
      )

      const checkedDrugs = maPlansFilters.drugsCheckbox?.map((item) =>
        item.value === unFilter ? { ...item, checked: !item.checked } : item
      )

      const checkedProviders = maPlansFilters.providers?.map((item) =>
        item.value === unFilter ? { ...item, checked: !item.checked } : item
      )
      setMAPlansFilters({
        ...maPlansFilters,
        carriers: checkedCarriers,
        planBenefitsCheckbox: checkedPlanBenefits,
        planTypeCheckbox: checkedPlanTypes,
        drugsCheckbox: checkedDrugs,
        providers: checkedProviders,
      })
      medicareAdvantageFilterQuoteStore.setIsFilterChanged(true)
    }
  }

  return !medicareAdvantageFilterQuoteStore.isPlanFetched() ? (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <ThreeDots color='#222C69' height={80} width={80} />
    </div>
  ) : (
    <Container>
      {thankYouModalActive && (
        <ModalComponent setOpen={() => setThankYouModalActive(false)}>
          <ThankYouModal setOpen={() => setThankYouModalActive(false)} />
        </ModalComponent>
      )}

      <FilterAdvantage
        filterRef={filterAdvantageRef}
        showFilter={showFilter}
        handleShowFilter={handleShowFilter}
        clearFilterState={clearFilterState}
        clearButtonState={clearButtonState}
        setSelectedFilters={(Value: string[]) => {
          setSelectedFilters(Value)
        }}
        maPlansFilters={maPlansFilters}
        setMAPlansFilters={setMAPlansFilters}
      />

      <CardsWrapper>
        {/* MIGHT NEED LATER SO NOT DELETING */}
        {/* <TopMessageBox>
          <p>
            Help us find the best Medicare plan for you in a few simple steps{' '}
            <Button variant='contained' fontSize='12px'>
              Find Best Plans for
            </Button>
          </p>
        </TopMessageBox>
        <TopMessageBox>
          <>
            <p>
              Based on your requirements, we have recommended you a Medicare
              Advantage Plan. If you prefer a Medicare Supplement Plan instead,
              we can help you find the perfect one.{' '}
            </p>
            <div style={{ maxWidth: '500px' }}>
              <Button variant='contained' fontSize='12px'>
                Find best Supplement Plan
              </Button>
            </div>
          </>
        </TopMessageBox> */}
        <FilterContainer>
          <FilterBox
            clearButtonState={clearButtonState}
            selectedFilters={selectedFilters}
            updateFilters={updateFilters}
          />
          <UtilityContainer>
            <h3
              onClick={() => {
                handleShowFilter()
              }}
              style={{ color: `${theme.colors.primary}`, fontWeight: '600' }}
            >
              <RiFilter2Fill />
              Filters
            </h3>
            {sortPlanOptions && (
              <SortBy
                options={sortPlanOptions}
                value={currentSortOption}
                onChange={handleSortChange}
              />
            )}
          </UtilityContainer>
        </FilterContainer>

        {medicareAdvantageFilterQuoteStore.isFilterApplied() ||
        medicareAdvantageFilterQuoteStore.isFiltersCleared() ? (
          <ThreeDotsWrapper>
            <ThreeDots color='#222C69' height={80} width={80} />
          </ThreeDotsWrapper>
        ) : (
          <>
            {medicareAdvantagePlans.length > 0 ? (
              <>
                {medicareAdvantagePlans.map(
                  (cardData: MNonSupplementPlan, index) => (
                    <div key={cardData.id}>
                      <MedicareAdvantageCard
                        medicareAdvantagePlan={cardData}
                        onEnroll={handleOnEnroll}
                      />
                      {index % 2 !== 0 && (
                        <CallUsNow
                          heading='Not sure which Medicare plan is right for you?'
                          message='Our licensed insurance agents are here to help'
                          phoneNumber={getContact(customerStore.getBrandName())}
                        />
                      )}
                    </div>
                  )
                )}
                <PaginationWrapper>
                  {/* <Pagination
                    count={paginationCount}
                    color='primary'
                    size='small'
                    onChange={(event, page: number) => {
                      fireEvent('changePage', event, {
                        label: page,
                        description: `Medicare Advantage page number changed to ${page}`,
                        value: page,
                      })
                      handlePaginationChange(page)
                    }}
                    page={
                      medicareAdvantageFilterQuoteStore.getMAPlanRequestFilter()
                        .paginationDetail?.page
                    }
                  /> */}
                  {medicareAdvantageFilterQuoteStore.getTotalCount() !==
                    medicareAdvantagePlans.length &&
                    !medicareAdvantageFilterQuoteStore.paginationLoader && (
                      <Button
                        color='primary'
                        variant='contained'
                        onClick={handlePaginationChange}
                      >
                        Load More Plans
                      </Button>
                    )}
                  {medicareAdvantageFilterQuoteStore.paginationLoader && (
                    <ThreeDotsWrapper>
                      <ThreeDots color='#222C69' height={80} width={80} />
                    </ThreeDotsWrapper>
                  )}
                </PaginationWrapper>
              </>
            ) : (
              <>
                {medicareAdvantageFilterQuoteStore.isPlanFetched() &&
                !medicareAdvantageFilterQuoteStore.paginationLoader ? (
                  <NoPlanFoundCard />
                ) : (
                  <ThreeDotsWrapper>
                    <ThreeDots color='#222C69' height={80} width={80} />
                  </ThreeDotsWrapper>
                )}
              </>
            )}
          </>
        )}
      </CardsWrapper>
      {userActionStore.comparePlanCards.planType ===
        MedicareQuotesType.MEDICARE_ADVANTAGE &&
        userActionStore.comparePlanCards.compareCardsData.length !== 0 && (
          <BottomAddToCompare />
        )}
    </Container>
  )
}

export default observer(MedicareAdvantage)
