import React, { useEffect } from 'react'

import Button from '../../common/Button/Button.component'
import { HiOutlinePhone } from 'react-icons/hi'
import { Link } from 'react-router-dom'
import customerStore from '../../../datastore/CustomerStore'
import { getContact } from '../../../utils/getContact'
import styled from '@emotion/styled'
import theme from '../../../global/theme'

interface ModelProps {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  to?: string
}

const Container = styled.div`
  width: 500px;
  min-height: 220px;

  h2 {
    color: #272f69;
    padding-bottom: 20px;
  }
  p {
    margin-bottom: 16px;
    font-weight: 600;
    color: ${theme.colors.textDark};

    span {
      color: ${theme.colors.secondary};
      font-weight: bold;
    }
  }
  .highlight {
    color: inherit;
    color: ${theme.colors.secondary};
    font-weight: 700;
    padding-bottom: 20px;
    margin-left: 10px;
    text-decoration: none;

    .phone_icon {
      transform: translateY(5px);
      font-size: 20px;
      color: ${theme.colors.primary};
    }
  }

  div {
    display: flex;
    justify-content: space-between;
  }

  @media screen and (max-width: 750px) {
    width: 320px;
    div {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  @media screen and (max-width: 380px) {
    width: 290px;
  }
`
const ModalButton = styled(Link)`
  width: 100%;
  text-decoration: none;
  margin-top: 32px;

  @media screen and (max-width: 750px) {
    width: 100%;
  }
`

const ThankYouModal: React.FC<ModelProps> = ({ setOpen, to }) => {
  useEffect(() => {
    window.Invoca && window.Invoca.PNAPI.run()
  })
  return (
    <Container>
      <h2>Thank you!</h2>
      <p>Our licensed Medicare agent will be reaching out to you shortly!</p>
      <p>
        You are in good hands! We have helped over 31,000+ Medicare clients save
        hundreds of dollars by comparing Medicare plans online.
      </p>
      <p>
        Need to talk to us? Give us a call at
        <a
          href={`tel:${getContact(customerStore.getBrandName())}`}
          className='highlight phone-number-fade'
        >
          <HiOutlinePhone className='phone_icon' />{' '}
          {getContact(customerStore.getBrandName())}
        </a>
      </p>
      {/* <p className='highlight'> </p> */}
      <div>
        {/* <ModalButton to={RouteConstants.INFO_STEPS}>
          <Button variant='contained' bold={false} width='100%'>
            Close
          </Button>
        </ModalButton> */}
        <ModalButton to={to ? to : ''}>
          <Button
            color='info'
            bold={false}
            width='100%'
            onClick={(e) => setOpen(false)}
          >
            Continue Browsing
          </Button>
        </ModalButton>
      </div>
    </Container>
  )
}

export default ThankYouModal
