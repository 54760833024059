import StringConstants from '../constants/StringConstants'

// return leadBrand specific contact number
export const getContact = (leadBrand: any) => {
  if (leadBrand === 'medicareassociation') {
    return '(888) 338-9505'
  }

  return StringConstants.DEFAULT_PHONE_NUMBER
}
