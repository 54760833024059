import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io'

import Button from '../Button/Button.component'
import { FiChevronLeft } from 'react-icons/fi'
import Icon from '../Icon/Icon.component'
import MuiTab from '@mui/material/Tab'
import MuiTabs from '@mui/material/Tabs'
import { PreferenceCoverageShortNameEnum } from '../../../enums/PreferenceCoverageShortNameEnum'
import React from 'react'
import RouteConstants from '../../../constants/RouteConstants'
import TabLabelComponent from './TabLabel.component'
import customerStore from '../../../datastore/CustomerStore'
import styled from '@emotion/styled'
import theme from '../../../global/theme'
import { useNavigate } from 'react-router-dom'

interface InPageHeaderProps {
  onChange: (event: React.SyntheticEvent, newValue: number) => void
  currentTab: number
  tabLabels: Array<tabLabel>
  heading?: string
  subHeading?: string
  showBackButton?: boolean
  locationState?: string
  type?: string
  isDashboard?: boolean
  isProfile?: boolean
  isSavedPlans?: boolean
  justifyContent?: string
  bgColor?: string
  showBottomNavigator?: boolean
}

type tabLabel = {
  heading: string
  subHeading?: string
  subHeading2?: string
}

const Container = styled.div <{ bgColor?: string }>`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${({ bgColor }) => bgColor ? bgColor : theme.colors.lightBack};
  text-align: center;
`
const ArrowButtonContainer = styled.div`
  @media (max-width: 600px) {
    display: flex;
    align-items: center;
  }

  @media (max-width: 600px) {
    .hide-mobile-tabs {
      display: none;
    }
  }

  @media (min-width: 415px) and (max-width: 600px) {
    .fixed-width-tab {
      width: 314px;
    }
  }
`
const ArrowButton = styled.div`
  display: none;

  @media (max-width: 600px) {
    display: block;
    cursor: pointer;
    padding: 15px;
  }
`

const Tab = styled(MuiTab) <{ isDashboard?: boolean; isSavedPlans?: boolean }>`
  background-color: ${theme.colors.white};
  border: 1px solid ${theme.colors.primary};
  text-transform: capitalize;
  font-size: 18px;
  padding: 20px;
  min-width: ${({ isDashboard, isSavedPlans }) =>
    isDashboard || isSavedPlans ? '320px' : '150px'};
  position: relative;
  overflow: visible;

  &:hover {
    background-color: #f2f7fe;
  }

  @media (max-width: 1200px) {
    overflow: hidden;
  }

  @media (max-width: 600px) {
    width: 100%;
  }
`

const Heading = styled.h1`
  padding: 30px 10px;
  font-weight: 700;
  color: ${theme.colors.primary};
  text-transform: uppercase;
  font-size: 32px;
`

const Tabs = styled(MuiTabs) <{ showBottomNavigator: boolean }>`
  overflow: visible;

  .MuiTouchRipple-root {
    overflow: visible;

    @media (max-width: 1200px) {
      overflow: hidden;
    }
  }

  .MuiTabs-indicator {
    height: 6px;
  }

  .Mui-selected {
    background-color: ${theme.colors.vLightBlue};
    ${(props) => (props.showBottomNavigator && `
    &::after {
      width: 0;
      height: 0;
      // background-color: ${theme.colors.primary};
      position: absolute;
      left: 50%;
      bottom: -12px;
      content: '';
      transform: translateX(-50%);
      border-left: 12px solid transparent;
      border-right: 12px solid transparent;
      border-top: 12px solid ${theme.colors.primary};

      @media (max-width: 1200px) {
        display: none;
      }
    }
    `
  )}
  }

  @media (max-width: 1200px) {
    overflow: hidden;
  }

  @media (max-width: 600px) {
    display: flex;
    justify-content: center;

    &::after {
      position: absolute;
      bottom: -10px;
      content: '';
      border-left: 12px solid transparent;
      border-right: 12px solid transparent;
      border-top: 12px solid ${theme.colors.primary};
    }
  }
`

const TabContainer = styled.div<{ additionalTopMargin: boolean | undefined, justifyContent?: string }>`
  width: 100%;
  display: flex;
  justify-content: ${({ justifyContent }) => justifyContent ? justifyContent : 'center'};
  position: relative;
  margin-top: ${(props) => (props.additionalTopMargin ? '2.2rem' : '0rem')};

  .MuiTabs-root {
    .MuiTabs-scrollableX {
      overflow: visible;

      @media (max-width: 1200px) {
        overflow: auto;
        max-width: 100vw;
      }
    }
  }

  @media screen and (max-width: 439px) {
    margin-top: ${(props) => (props.additionalTopMargin ? '0.1rem' : '0rem')};
  }

  @media screen and (max-width: 750px) and (min-width: 439px) {
    margin-top: ${(props) => (props.additionalTopMargin ? '2.9rem' : '0rem')};
  }
`

const ButtonContainer = styled.div<{ showBackButton: boolean }>`
  display: ${(props) => (props.showBackButton ? 'block' : 'none')};
  position: absolute;
  top: 0px;
  left: 0px;
  padding: 30px 0px 0px 80px;
  margin-left: -54px;

  @media screen and (max-width: 850px) {
    position: static;
    padding: 10px 0px 0px 20px;
    display: ${(props) => (props.showBackButton ? 'flex' : 'none')};
    margin-left: 0px;
  }

  @media screen and (max-width: 750px) {
    margin-left: -24px;
  }
`

const InPageHeader: React.FC<InPageHeaderProps> = ({
  onChange,
  currentTab,
  tabLabels,
  heading,
  showBackButton = true,
  locationState = RouteConstants.DASHBOARD,
  type,
  isDashboard,
  isSavedPlans,
  isProfile = false,
  justifyContent,
  bgColor,
  showBottomNavigator = true,
}) => {
  const navigate = useNavigate()
  const [additionalTopMargin, setadditionalTopMargin] = React.useState<
    boolean | undefined
  >()

  React.useEffect(() => {
    if (isDashboard && customerStore.getCurrentTabStatus(currentTab))
      setadditionalTopMargin(true)
    else setadditionalTopMargin(false)
  }, [currentTab, isDashboard, customerStore.getCurrentTabStatus(currentTab)])

  const handleBack = () => {
    if (!customerStore.isProfileUpdating) {
      if (type === PreferenceCoverageShortNameEnum.ma)
        locationState = RouteConstants.DASHBOARD_MA
      else if (type === PreferenceCoverageShortNameEnum.ms)
        locationState = RouteConstants.DASHBOARD_MS
      else if (type === PreferenceCoverageShortNameEnum.pdp)
        locationState = RouteConstants.DASHBOARD_PDP
      navigate(locationState)
    }
  }
  return (
    <Container bgColor={bgColor}>
      <ButtonContainer showBackButton={showBackButton}>
        <Button
          variant='text'
          width='100px'
          onClick={handleBack}
          description='Back Button'
        >
          <span style={{ display: 'flex', alignItems: 'center' }}>
            <Icon childComponent={<FiChevronLeft />} />
            Back
          </span>
        </Button>
      </ButtonContainer>
      {heading && (<Heading>{heading}</Heading>)}
      <TabContainer additionalTopMargin={additionalTopMargin} justifyContent={justifyContent}>
        <div>
          <ArrowButtonContainer>
            <ArrowButton
              style={{
                paddingLeft: !isProfile && currentTab === 0 ? '35px' : '',
              }}
            >
              {currentTab !== 0 && (
                <span
                  onClick={(e) => {
                    if (currentTab > 0) {
                      onChange(e, currentTab - 1)
                    }
                  }}
                >
                  <Icon fontSize={'20px'} childComponent={<IoIosArrowBack />} />
                </span>
              )}
            </ArrowButton>
            <Tabs
              value={currentTab}
              onChange={onChange}
              variant='scrollable'
              scrollButtons='auto'
              showBottomNavigator={showBottomNavigator}
            >
              {tabLabels.map((tabLabel, index) => (
                <Tab
                  isDashboard={isDashboard}
                  isSavedPlans={isSavedPlans}
                  disableRipple
                  key={index}
                  label={<TabLabelComponent tabLabel={tabLabel} />}
                  className={`${!isProfile && 'fixed-width-tab'} ${index === currentTab ? '' : 'hide-mobile-tabs'
                    }`}
                />
              ))}
            </Tabs>

            <ArrowButton
              style={{
                paddingLeft:
                  !isProfile && currentTab === tabLabels.length - 1
                    ? '35px'
                    : '',
              }}
            >
              {currentTab !== tabLabels.length - 1 && (
                <span
                  onClick={(e) => {
                    if (currentTab < tabLabels.length - 1) {
                      onChange(e, currentTab + 1)
                    }
                  }}
                >
                  <Icon
                    fontSize={'20px'}
                    childComponent={<IoIosArrowForward />}
                  />
                </span>
              )}
            </ArrowButton>
          </ArrowButtonContainer>
        </div>
      </TabContainer>
    </Container>
  )
}

export default InPageHeader
