import '@fontsource/open-sans'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'
import './global/index.css'

import { ActionContextProvider, createConfig } from './cra'

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import ApiManager from '../src/api/ApiManager'
import App from './App'
import { HttpMethods } from './enums/HttpMethodsEnum'
import { LocalizationProvider } from '@mui/x-date-pickers'
import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import { SFLeadInterface } from './interface/salesforce/SFLeadInterface'
import TagManager from 'react-gtm-module'
import { ThemeProvider } from '@mui/material'
import UrlConstants from './constants/UrlConstants'
import customerStore from './datastore/CustomerStore'
import { isEmpty } from 'lodash'
import { observer } from 'mobx-react'
import reportWebVitals from './reportWebVitals'
import theme from './global/muiTheme'

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_CONTAINER
    ? process.env.REACT_APP_GTM_CONTAINER
    : 'GTM-PSVRJBQ',
}

TagManager.initialize(tagManagerArgs)

const getEnrolledPlans = async (): Promise<number | undefined> => {
  try {
    const res = await ApiManager.makeApiCallWithAuthentication(
      UrlConstants.GET_ENROLLMENT_COUNT.USECASE,
      HttpMethods.GET_METHOD,
      { 'for-spouse': false }
    ).catch((err) => {
      throw err
    })
    if (res.status === 200)
      return (
        res.data.data.maPlansCount +
        res.data.data.msPlansCount +
        res.data.data.mPartDPlansCount
      )
    else return undefined
  } catch (err) {
    throw err
  }
}

const idleTimeCallbackQt = async () => {
  const params = new URLSearchParams(window.location.search)
  let totalPlanCount = await getEnrolledPlans().catch((err) => {})
  let callbackStatus = params.has('callback_status_new')
    ? params.get('callback_status_new')
    : ''

  if (callbackStatus !== 'LQ Not in Dial Queue') {
    let pathname = window.location.pathname.replace(/\//g, '')
    const requestBody: SFLeadInterface = {
      leadAppStage: 'Quoting Tool',
      leadAppSubstage: pathname,
    }

    if (!isEmpty(totalPlanCount) && totalPlanCount === 0)
      requestBody['callbackLogic'] = 'Timeout'

    ApiManager.makeApiCallWithAuthentication(
      UrlConstants.SF_CALLBACK_LEAD_INFO_UPDATE.USECASE,
      HttpMethods.PATCH_METHOD,
      requestBody
    )
      .then((response) => {
        if (response.status === 200)
          console.log('Successfully updated the Salesforce during Idle time!')
        else
          throw new Error(
            'Something went wrong while updating the Salesforce during Idle time!'
          )
      })
      .catch((err: Error) => {
        console.log(
          `Error occurred when trying to update SF callback fields,Err - ${err.message}`
        )
      })
  }
}

createConfig({
  website: 'Quoting Tool',
  idleTimer: [],
})

declare global {
  interface Window {
    Invoca: any
    LeadiD: any
  }
}

const Index = observer(() => (
  <ThemeProvider theme={theme}>
    <ActionContextProvider
      idleTimeCallbackQt={idleTimeCallbackQt}
      leadBrand={customerStore.getBrandName()}
    >
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <App />
      </LocalizationProvider>
    </ActionContextProvider>
  </ThemeProvider>
))

ReactDOM.render(
  <React.StrictMode>
    <script
      src={`https://www.googleoptimize.com/optimize.js?id=${
        process.env.REACT_APP_GTM_OPTIMIZE_CONTAINER
          ? process.env.REACT_APP_GTM_OPTIMIZE_CONTAINER
          : 'OPT-K3NZQ59'
      }`}
    ></script>
    <Router>
      <Index />
    </Router>
  </React.StrictMode>,

  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
