import BouncingDotsLoader from '../Loading/BouncingDotsLoader'
import React from 'react'
import styled from '@emotion/styled'
import theme from '../../../global/theme'

const Container = styled.div`
  width: 100%;
  background-color: ${theme.colors.primary};
  justify-content: space-between;
  align-items: center;
  padding: 10px 50px;
  z-index: 1;
  margin-bottom: 1px;
  color: white;
  position: fixed;

  h3 {
    color: white;
    text-align: center;
  }

  @media screen and (max-width: 750px) {
    padding: 10px 20px;
  }
`

const QuotesPulledStatus: React.FC = () => {
  return (
    <Container>
      <h3>Preparing quotes</h3>
      <BouncingDotsLoader />
    </Container>
  )
}

export default QuotesPulledStatus
