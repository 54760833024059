// import {
//   AiFillHeart as FilledHeart,
//   AiOutlineHeart as OutlinedHeart,
// } from 'react-icons/ai'

import ApiManager from '../../api/ApiManager'
import Button from '../common/Button/Button.component'
import CarrierLogo from '../common/CarrierLogo'
import EnrollmentRequestConfirmation from '../DashboardPage/Modals/EnrollmentRequestConfirmation'
import { HttpMethods } from '../../enums/HttpMethodsEnum'
import { MedicareQuotesType } from '../../enums/MedicareQuoteTypeEnum'
import ModalComponent from '../common/Modal/Modal.component'
import React from 'react'
import UrlConstants from '../../constants/UrlConstants'
import customerStore from '../../datastore/CustomerStore'
import { getApiErrorMessage } from '../../utils/StringUtils'
import { isEmpty } from 'lodash'
import snackbarStore from '../../datastore/SnackbarStore'
import styled from '@emotion/styled'
import theme from '../../global/theme'
import userActionStore from '../../datastore/UserActionStore'
import { Rating } from '@mui/material'

interface CarouselCardProps {
  id: string
  carrier: string
  premium: number
  name: string
  favorite?: boolean
  enrollRequestStatus: boolean
  planType: MedicareQuotesType
  msPlanType?: string | undefined
  ridersApplied?: string[] | undefined
  onEnroll: (id: string) => void
  onRemove: (id: string) => void
  cardLoading?: boolean
  rating?: number
}

const Container = styled.div`
  max-width: 760px;
  min-width: 350px;
  border: 1px solid ${theme.colors.borderColor};
  padding: 20px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  @media screen and (max-width: 650px) {
    min-width: 250px;
  }
`
const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  div {
    display: contents;
    align-items: center;
    img {
      max-width: 146px;
      max-height: 45px;
    }
  }
`

const ContentWrapper = styled.div`
  text-align: center;
  h2 {
    font-size: 16px;
    color: ${theme.colors.textDark};
    font-weight: 600;
    padding-top: 12px;
  }

  p {
    font-size: 14px;
    font-weight: 600;
    color: ${theme.colors.textGrey};
    padding-top: 12px;

    span {
      font-weight: 700;
      font-size: 14px;
      color: ${theme.colors.textDark};
    }
  }
`

const FooterWrapper = styled.div`
  padding-top: 24px;
  display: flex;
  justify-content: center;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  div {
    display: flex;
    align-items: center;
    span {
      font-size: 14px;
      color: ${theme.colors.textGrey};
    }
  }
`

const CarouselCard: React.FC<CarouselCardProps> = ({
  id,
  name,
  premium,
  carrier,
  favorite = false,
  onEnroll,
  onRemove,
  enrollRequestStatus,
  planType,
  msPlanType,
  ridersApplied,
  cardLoading = false,
  rating,
}) => {
  const [enrollRequested, setEnrollRequested] =
    React.useState(enrollRequestStatus)
  const [enrollmentRequestPromptActive, setEnrollmentRequestPromptActive] =
    React.useState(false)
  const [preferredPhone, setPreferredPhone] = React.useState(
    customerStore.get().phone.replace('+1', '')
  )
  const enrollBtnText =
    planType === MedicareQuotesType.MEDICARE_SUPPLEMENT
      ? enrollRequested || enrollRequestStatus
        ? 'Application Requested'
        : 'APPLY'
      : enrollRequested || enrollRequestStatus
      ? 'Enrollment Requested'
      : 'ENROLL'
  const [loading, setLoading] = React.useState(false)

  const handleEnrollRequest = () => {
    if (
      id &&
      planType === MedicareQuotesType.MEDICARE_SUPPLEMENT &&
      isEmpty(userActionStore.getRidersApplied(id)) &&
      !isEmpty(ridersApplied)
    ) {
      userActionStore.setRidersApplied(id, ridersApplied!)
    }
    setLoading(true)
    const riders = id
      ? userActionStore.getRidersAppliedWithFullNames(id).length > 0
        ? userActionStore.getRidersAppliedWithFullNames(id)
        : undefined
      : undefined

    ApiManager.makeApiCallWithAuthentication(
      UrlConstants.PATCH_ENROLL_REQUESTED.USECASE,
      HttpMethods.PATCH_METHOD,
      {
        $planId: id,
        'quoteType': planType,
        'enrollRequested': true,
        'forSpouse': customerStore.get().forSpouse,
        versionId: process.env.REACT_APP_VERSION_ID,
        rider: riders?.toString(),
        'preferredPhoneNumber': '+1' + preferredPhone,
      }
    )
      .then((response) => {
        if (response.status === 200) {
          setLoading(false)
          snackbarStore.set({
            snackbarOpen: true,
            snackbarMessage:
              planType === MedicareQuotesType.MEDICARE_SUPPLEMENT
                ? 'Apply request saved successfully'
                : 'Enroll request saved successfully',
            snackbarType: 'success',
          })
          setEnrollRequested(true)
          userActionStore.setEnrollmentRequestedPlan({
            planId: id,
            planType: planType,
          })
          setEnrollmentRequestPromptActive(false)
          onEnroll(id)
        } else throw response
      })
      .catch((err) => {
        if (
          err.data?.message ===
          'Maximum limit reached for requesting enrollment for plans'
        ) {
          setLoading(false)
          snackbarStore.set({
            snackbarOpen: true,
            snackbarMessage: getApiErrorMessage(
              `save ${
                planType === MedicareQuotesType.MEDICARE_SUPPLEMENT
                  ? 'apply'
                  : 'enroll'
              } request. Maximum limit reached`
            ),
            snackbarType: 'error',
          })
          setEnrollRequested(false)
          setEnrollmentRequestPromptActive(false)
        } else {
          setLoading(false)
          snackbarStore.set({
            snackbarOpen: true,
            snackbarMessage: getApiErrorMessage(
              `save ${
                planType === MedicareQuotesType.MEDICARE_SUPPLEMENT
                  ? 'apply'
                  : 'enroll'
              } request`
            ),
            snackbarType: 'error',
          })
          setEnrollRequested(false)
          setEnrollmentRequestPromptActive(false)
        }
      })
  }

  return (
    <Container>
      {/* <div style={{ position: 'absolute', top: 0, right: 0 }}>
        <CloseIconButton onClick={() => onRemove(id)} />
      </div> */}
      {enrollmentRequestPromptActive && (
        <ModalComponent
          setOpen={() => {
            setEnrollmentRequestPromptActive(false)
          }}
          description='Popup - Request for Enrollment Confirmation'
          hideCloseButton={true}
        >
          <EnrollmentRequestConfirmation
            setOpen={() => {
              setEnrollmentRequestPromptActive(false)
            }}
            onEnroll={() => {
              handleEnrollRequest()
            }}
            planName={name}
            planId={id}
            planType={planType}
            buttonLoading={loading}
            preferredPhoneNumber={preferredPhone}
            setPreferredPhoneNumber={setPreferredPhone}
          />
        </ModalComponent>
      )}
      <LogoWrapper>
        <div>
          <CarrierLogo carrier={carrier} />
        </div>
      </LogoWrapper>
      <ContentWrapper>
        <h2>{name}</h2>
        {msPlanType && msPlanType !== '' && (
          <p>
            <b>
              Plan Type: <span>{msPlanType}</span>
            </b>
          </p>
        )}
        <p>
          Monthly Premium: <span>${premium.toFixed(2)}</span>
        </p>
        {planType !== MedicareQuotesType.MEDICARE_SUPPLEMENT && rating && (
           <Rating
           name='simple-controlled'
           value={rating || 0}
           precision={0.5}
           readOnly={true}
           sx={{
             marginTop: '1rem',
           }}
         />
        )}
        <FooterWrapper>
          {/* Commented by RB - Saved Plans not needed in Compare Plans */}
          {/* <div>
            <Checkbox
              icon={
                <OutlinedHeart
                  style={{
                    fontSize: '18px',
                    color: `${theme.colors.secondary}`,
                  }}
                />
              }
              checkedIcon={
                <FilledHeart
                  style={{
                    fontSize: '18px',
                    color: `${theme.colors.secondary}`,
                  }}
                />
              }
              checked={false}
            />{' '}
            <span>Favourite</span>
          </div> */}
          <div>
            <Button
              width='150px'
              variant='contained'
              color='secondary'
              bold={true}
              height='30px'
              onClick={() => {
                setEnrollmentRequestPromptActive(true)
              }}
              disabled={cardLoading || enrollRequested || enrollRequestStatus}
              additionalPadding={true}
              description={`Attempted Enrollment in ${name}`}
              id={`compareEnroll ${name}`}
              loading={cardLoading}
            >
              {enrollBtnText}
            </Button>
          </div>
        </FooterWrapper>
      </ContentWrapper>
    </Container>
  )
}

export default CarouselCard
