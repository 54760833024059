import { AiOutlineMail, AiOutlinePrinter } from 'react-icons/ai'
import React, { useRef } from 'react'

import { AbstractBenefits } from '../../interface/quotes/mNonSupplementPlans/common/AbstractBenefits'
import ApiManager from '../../api/ApiManager'
import Button from '../common/Button/Button.component'
import CarrierLogo from '../common/CarrierLogo'
import EnrollmentRequestConfirmation from '../DashboardPage/Modals/EnrollmentRequestConfirmation'
import { FiChevronLeft } from 'react-icons/fi'
import { HttpMethods } from '../../enums/HttpMethodsEnum'
import Icon from '../../components/common/Icon/Icon.component'
import { MedicareQuotesType } from '../../enums/MedicareQuoteTypeEnum'
import ModalComponent from '../common/Modal/Modal.component'
import RouteConstants from '../../constants/RouteConstants'
import SavePlanIconButton from '../common/SavePlan/SavePlanIconButton'
import TabGroup from './TabGroup'
import { ThreeDots } from 'react-loader-spinner'
import UrlConstants from '../../constants/UrlConstants'
import customerStore from '../../datastore/CustomerStore'
import { fireEvent } from '../../cra'
import { getApiErrorMessage } from '../../utils/StringUtils'
import { isEmpty } from 'lodash'
import snackbarStore from '../../datastore/SnackbarStore'
import styled from '@emotion/styled'
import theme from '../../global/theme'
import { useNavigate } from 'react-router-dom'
import { useReactToPrint } from 'react-to-print'
import userActionStore from '../../datastore/UserActionStore'

const Container = styled.div`
  top: 95px !important;
  padding-top: 10px;
  padding-bottom: 40px;
  top: 0px;
  z-index: 100;
  background-color: ${theme.colors.white};
  border-bottom: 1px solid ${theme.colors.borderColor};
  @media screen and (max-width: 400px) {
    top: 80px !important;
  }
  @media screen and (max-width: 300px) and (max-height: 800px) {
    position: relative !important;
  }
`

const TabGroupWrapper = styled.div`
  // position: sticky;
  padding: 30px 0px 0px 0px;
  // top: 220px;
  background-color: ${theme.colors.white};
  @media screen and (max-width: 600px) {
    top: 300px;
  }

  @media print {
    display: none;
  }
`

const HeadingWrapper = styled.div`
  display: flex;
  align-items: center;
  grid-gap: 30px;
  h2 {
    font-size: 24px;
    font-weight: 600;
  }
  img {
    max-height: 60px;
    max-width: 180px;
  }
  padding-bottom: 20px;
  border-bottom: 1px solid ${theme.colors.borderColor};

  @media screen and (max-width: 600px) {
    height: 80%;
    img {
      max-width: 100px;
    }
  }
`

const ButtonsWrapper = styled.div`
  padding-top: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  grid-gap: 10px;
  align-items: center;

  @media screen and (max-width: 750px) {
    justify-content: center;
  }
  .buttons {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .utility {
    display: flex;
    align-items: center;
    grid-gap: 20px;
    p {
      display: flex;
      align-items: center;
      grid-gap: 5px;
      font-size: 14px;
      color: ${theme.colors.primary};
      font-weight: 600;
      cursor: pointer;
      border-bottom: 1px solid ${theme.colors.primary};
    }
  }

  @media print {
    display: none;
  }
`
const ButtonContainer = styled.div`
  position: static;
  padding-bottom: 30px;

  @media screen and (min-width: 1001px) {
    margin-left: -180px;
  }
  @media screen and (max-width: 1000px) {
    margin-left: -80px;
  }
  @media screen and (max-width: 750px) {
    margin-left: -24px;
  }

  @media print {
    display: none;
  }
`

const HeaderSection: React.FC<{
  carrierLogoName: string
  planId?: string
  planName: string
  isFavorite: boolean
  planType: MedicareQuotesType
  handleOnEnroll: () => void
  onAddSaved: () => void
  onRemoveSaved: () => void
  planBenefitsTopics: string[]
  categories: Map<string, string[]>
  abstractDetails: AbstractBenefits[] | undefined
  currentTabTop: string
  handleTabChange: (e: React.SyntheticEvent, value: string) => void
  printComponentRef: React.MutableRefObject<null>
  enrollRequestedStatus: boolean
  setCurrentTabTop: React.Dispatch<React.SetStateAction<string>>
  currentTabBottom: string
  ridersApplied?: string[] | undefined
  sunfirePlanType?: MedicareQuotesType
}> = ({
  carrierLogoName,
  planId,
  planName,
  planType,
  isFavorite,
  onAddSaved,
  onRemoveSaved,
  handleOnEnroll,
  planBenefitsTopics,
  categories,
  abstractDetails,
  currentTabTop,
  handleTabChange,
  printComponentRef,
  enrollRequestedStatus,
  setCurrentTabTop,
  currentTabBottom,
  ridersApplied,
  sunfirePlanType,
}) => {
    const navigate = useNavigate()
    const [planBenefitTopic, setPlanBenefitTopic] =
      React.useState<string[]>(planBenefitsTopics)
    const [emailLoader, setEmailLoader] = React.useState<boolean>(false)
    const [printLoader, setPrintLoader] = React.useState<boolean>(false)
    const timerRef: { current: NodeJS.Timeout | null } = useRef(null)
    const [enrollRequested, setEnrollRequested] = React.useState(false)
    const [enrollmentRequestPromptActive, setEnrollmentRequestPromptActive] =
      React.useState(false)
    const enrollBtnText =
      planType === MedicareQuotesType.MEDICARE_SUPPLEMENT
        ? enrollRequested
          ? 'Application Requested'
          : 'APPLY'
        : enrollRequested
          ? 'Enrollment Requested'
          : 'ENROLL'
    const [loading, setLoading] = React.useState(false)
    const [preferredPhone, setPreferredPhone] = React.useState(
      customerStore.get().phone.replace('+1', '')
    )

    React.useEffect(() => {
      // Clear the interval when the component unmounts
      return () => {
        if (timerRef.current) clearTimeout(timerRef.current)
      }
    }, [])

    React.useEffect(() => {
      if (enrollRequestedStatus !== undefined) {
        setEnrollRequested(enrollRequestedStatus)
      }
    }, [enrollRequestedStatus])

    const handleEnrollRequest = () => {
      if (
        planId &&
        planType === MedicareQuotesType.MEDICARE_SUPPLEMENT &&
        isEmpty(userActionStore.getRidersApplied(planId)) &&
        !isEmpty(ridersApplied)
      ) {
        userActionStore.setRidersApplied(planId, ridersApplied!)
      }
      setLoading(true)
      const riders = planId
        ? userActionStore.getRidersAppliedWithFullNames(planId).length > 0
          ? userActionStore.getRidersAppliedWithFullNames(planId)
          : undefined
        : undefined
      ApiManager.makeApiCallWithAuthentication(
        UrlConstants.PATCH_ENROLL_REQUESTED.USECASE,
        HttpMethods.PATCH_METHOD,
        {
          $planId: planId,
          'quoteType': planType,
          'enrollRequested': true,
          'forSpouse': customerStore.get().forSpouse,
          versionId: process.env.REACT_APP_VERSION_ID,
          rider: riders?.toString(),
          'preferredPhoneNumber': '+1' + preferredPhone,
        }
      )
        .then((response) => {
          if (response.status === 200) {
            setLoading(false)
            snackbarStore.set({
              snackbarOpen: true,
              snackbarMessage: `${
              planType === MedicareQuotesType.MEDICARE_SUPPLEMENT
                  ? 'Apply'
                  : 'Enroll'
                } request saved successfully`,
              snackbarType: 'success',
            })
            setEnrollRequested(true)
            setEnrollmentRequestPromptActive(false)
            handleOnEnroll()
          } else throw response
        })
        .catch((err) => {
          if (
            err.data?.message ===
            'Maximum limit reached for requesting enrollment for plans'
          ) {
            setLoading(false)
            snackbarStore.set({
              snackbarOpen: true,
              snackbarMessage: getApiErrorMessage(
                `save ${
                planType === MedicareQuotesType.MEDICARE_SUPPLEMENT
                  ? 'apply'
                  : 'enroll'
                } request. Maximum limit reached`
              ),
              snackbarType: 'error',
            })
            setEnrollRequested(false)
            setEnrollmentRequestPromptActive(false)
          } else {
            setLoading(false)
            snackbarStore.set({
              snackbarOpen: true,
              snackbarMessage: getApiErrorMessage(
                `save ${
                planType === MedicareQuotesType.MEDICARE_SUPPLEMENT
                  ? 'apply'
                  : 'enroll'
                } request`
              ),
              snackbarType: 'error',
            })
            setEnrollRequested(false)
            setEnrollmentRequestPromptActive(false)
          }
        })
    }

    const handleOnEmailClicked = () => {
      setEmailLoader(true)
      //TODO: Handle MS Plans for spouse
      let emailPlanDetailsUsecase
      if(sunfirePlanType === MedicareQuotesType.MEDICARE_SUPPLEMENT)
        emailPlanDetailsUsecase = UrlConstants.POST_EMAIL_PLAN_DETAILS.USECASE
      else
        emailPlanDetailsUsecase = UrlConstants.POST_EMAIL_MNS_PLAN_DETAILS.USECASE
      
      ApiManager.makeApiCallWithAuthentication(
        emailPlanDetailsUsecase,
        HttpMethods.POST_METHOD,
        {
          planId: planId,
          medicareQuoteType: sunfirePlanType,
          forSpouse: false,
        }
      )
        .then((response) => {
          if (response.status === 200) {
            snackbarStore.set({
              snackbarOpen: true,
              snackbarMessage: 'Plan details will be sent soon.',
              snackbarType: 'success',
            })
          } else throw response
        })
        .catch((err) => {
          snackbarStore.set({
            snackbarOpen: true,
            snackbarMessage: getApiErrorMessage('send plan details'),
            snackbarType: 'error',
          })
        })
        .finally(() => setEmailLoader(false))
    }

    const reactToPrint = useReactToPrint({
      content: () => printComponentRef.current,
    })

    const handleOnPrintClicked = () => {
      setPrintLoader(true)
      timerRef.current = setTimeout(reactToPrint, 0)
      setPrintLoader(false)
    }

    return (
      <Container>
        {enrollmentRequestPromptActive && (
          <ModalComponent
            setOpen={() => {
              setEnrollmentRequestPromptActive(false)
            }}
            description='Popup - Request for Enrollment Confirmation'
            hideCloseButton={true}
          >
            <EnrollmentRequestConfirmation
              setOpen={() => {
                setEnrollmentRequestPromptActive(false)
              }}
              onEnroll={() => {
                handleEnrollRequest()
              }}
              planName={planName}
              planId={planId ? planId : ''}
              planType={planType}
              buttonLoading={loading}
              preferredPhoneNumber={preferredPhone}
              setPreferredPhoneNumber={setPreferredPhone}
            />
          </ModalComponent>
        )}
        <ButtonContainer>
          <Button
            variant='text'
            width='100px'
            onClick={() => {
              if (customerStore.currentTab === 0) {
                navigate(RouteConstants.DASHBOARD_MA)
              } else if (customerStore.currentTab === 1) {
                navigate(RouteConstants.DASHBOARD_MS)
              } else {
                navigate(RouteConstants.DASHBOARD_PDP)
              }
            }}
          >
            <span style={{ display: 'flex', alignItems: 'center' }}>
              <Icon childComponent={<FiChevronLeft />} />
              Back
            </span>
          </Button>
        </ButtonContainer>
        <HeadingWrapper>
          <CarrierLogo carrier={carrierLogoName} useAltLogo={true} />
          <h2>{planName}</h2>
        </HeadingWrapper>
        <ButtonsWrapper>
          <div className='buttons'>
            {planId && (
              <SavePlanIconButton
                planId={planId}
                planType={planType}
                saved={isFavorite}
                onAddSaved={onAddSaved}
                onRemoveSaved={onRemoveSaved}
              />
            )}
            <Button
              color='secondary'
              variant='contained'
              width='200px'
              bold={false}
              onClick={() => {
                setEnrollmentRequestPromptActive(true)
              }}
              disabled={enrollRequested}
              additionalPadding={enrollBtnText !== 'ENROLL'}
              description={`Complete Plan Details Page - Attempted Enrollment in ${planName}`}
              id={`Enroll ${planName}`}
            >
              {enrollBtnText}
            </Button>
          </div>
          <div className='utility'>
            <p
              onClick={(e) => {
                fireEvent('click', e, {
                  description: 'Print Plan Details in Complete Plan Details Page',
                })
                handleOnPrintClicked()
              }}
            >
              <AiOutlinePrinter
                style={{
                  fontSize: '18px',
                }}
              />{' '}
              Print Plan Details
              {printLoader && (
                <ThreeDots color='#222C69' height={20} width={40} />
              )}
            </p>
            <p
              onClick={(e) => {
                fireEvent('click', e, {
                  description: 'Email Plan Details in Complete Plan Details Page',
                })
                handleOnEmailClicked()
              }}
            >
              <AiOutlineMail
                style={{
                  fontSize: '18px',
                }}
              />{' '}
              Email Plan Details
              {emailLoader && (
                <ThreeDots color='#222C69' height={20} width={40} />
              )}
            </p>
          </div>
        </ButtonsWrapper>
        {/* {planBenefitTopic &&
          planType === MedicareQuotesType.MEDICARE_ADVANTAGE && (
            <>
              <TabGroupWrapper style={{ zIndex: 100 }}>
                <TabGroup
                  onChange={(e: React.SyntheticEvent, newValue: string) => {
                    if (newValue === 'Overview' || newValue === 'Drugs')
                      handleTabChange(e, newValue)
                    else {
                      handleTabChange(e, categories.get(newValue)![0])
                    }
                    setCurrentTabTop(newValue)
                  }}
                  currentTab={currentTabTop}
                  tabLabels={planBenefitTopic}
                />
              </TabGroupWrapper>
              {Array.from(categories.keys()).map((key) => {
              return (
                currentTabTop === key && (
                  <TabGroupWrapper style={{ zIndex: 100 }}>
                    <TabGroup
                      onChange={handleTabChange}
                      currentTab={currentTabBottom}
                      tabLabels={categories.get(key)!}
                    />
                  </TabGroupWrapper>
                )
              )
            })}
            </>
          )} */}
      </Container>
    )
  }

export default HeaderSection
