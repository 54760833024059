import React, { useEffect, useState } from 'react'

import { MedicareConstants } from '../../../../constants/MedicareConstants'
import Select from '../../../common/Select/Select.component'
import currentPlanStore from '../../../../datastore/CurrentPlanStore'
import { isEmpty } from 'lodash'
import { observer } from 'mobx-react'
import styled from '@emotion/styled'
import theme from '../../../../global/theme'

interface SelectSupplementPlanTypeProps extends ContainerProps {
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined
  question?: string
  description?: string
  disableMainNext?: () => void
  enableMainNext?: () => void
  handleHasNext?: (value: boolean) => void
}

interface ContainerProps {
  width?: string
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: baseline;
  @media screen and (max-width: 600px) {
    padding: 10px 5px;
    width: 100%;
  }
`

const Heading = styled.h3`
  font-size: 20px;
  font-weight: 700px;
  padding-bottom: 20px;
  color: ${theme.colors.primary};
`

const Description = styled.p`
  text-align: left;
  font-weight: 600;
`

const RadioButtonWrapper = styled.div`
  width: 100%;
  padding: 10px 0px;
  max-height: 200px;
  overflow-y: auto;
`
const SelectWrapper = styled.div<{ screenWidth: number }>`
  max-width: 465px;
  width: 100%;
  flex: 1;
  align-items: center;
  margin-top: 10px;
  .MuiSelect-select {
    display: block;
    left: auto !important;
    text-align: left !important;
  }
  @media screen and (min-width: 601px) and (max-width: 750px) {
    .MuiPopover-paper {
      min-width: ${(props) =>
        ((750 - props.screenWidth) / 50) * 4 + 60}% !important;
    }
  }

  @media screen and (max-width: 600px) {
    .MuiPopover-paper {
      min-width: ${(props) =>
        90 - ((600 - props.screenWidth) / 50) * 1.75}% !important;
    }
  }
`
const SelectSupplementPlanType: React.FC<SelectSupplementPlanTypeProps> = ({
  onClick,
  disableMainNext,
  enableMainNext,
  handleHasNext,
}) => {
  const [planTypes] = React.useState<string[]>(
    MedicareConstants.getPlanTypesMs().map((planType) => planType)
  )
  const [selectedPlanType, setSelectedPlanType] = useState<string>(
    currentPlanStore.planType ? currentPlanStore.planType : ''
  )
  const [dimensions, setDimensions] = React.useState({
    height: window.innerHeight,
    width: window.innerWidth,
  })
  useEffect(() => {
    if (
      currentPlanStore.quoteType === MedicareConstants.INSURANCE_TYPE.MS &&
      currentPlanStore.planType
    ) {
      currentPlanStore.setPlanType(currentPlanStore.planType)
    }
  }, [])

  useEffect(() => {
    handleHasNext && handleHasNext(true)
    if (!isEmpty(currentPlanStore.get().planType)) {
      enableMainNext && enableMainNext()
    } else {
      disableMainNext && disableMainNext()
    }
  }, [])

  return (
    <Container>
      <Heading>Please choose your Medicare Supplements Plan type</Heading>
      <Description>Select your plan type</Description>
      <SelectWrapper screenWidth={dimensions.width}>
        <Select
          formLabel='Select your plan type'
          options={planTypes ? planTypes : ([] as string[])}
          onChange={(e) => {
            currentPlanStore.setPlanType('')
            currentPlanStore.setProvider('')
            setSelectedPlanType(e.target.value)
            currentPlanStore.setPlanType(e.target.value)
            enableMainNext && enableMainNext()
          }}
          height='39px'
          value={
            selectedPlanType === MedicareConstants.PLAN_TYPE.NotSure ||
            selectedPlanType === MedicareConstants.PLAN_TYPE.Other
              ? selectedPlanType
              : isEmpty(selectedPlanType)
              ? undefined
              : selectedPlanType
          }
          isInfoSteps={true}
        ></Select>
      </SelectWrapper>
    </Container>
  )
}

export default observer(SelectSupplementPlanType)
