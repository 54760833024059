import React, { useEffect, useState } from 'react'

import { MedicareConstants } from '../../../../constants/MedicareConstants'
import RadioGroup from '../../../common/RadioGroup/RadioGroup.component'
import currentPlanStore from '../../../../datastore/CurrentPlanStore'
import { isEmpty } from 'lodash'
import { observer } from 'mobx-react'
import styled from '@emotion/styled'
import theme from '../../../../global/theme'

interface SelectPlanTypeProps extends ContainerProps {
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined
  disableMainNext?: () => void
  enableMainNext?: () => void
  handleHasNext?: (value: boolean) => void
}

interface ContainerProps {
  width?: string
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media screen and (max-width: 600px) {
    padding: 10px 5px;
    width: 100%;
  }
`

const Heading = styled.h3`
  font-size: 20px;
  font-weight: 700px;
  padding-bottom: 20px;
  color: ${theme.colors.primary};
`

const Description = styled.p`
  padding-top: 20px;
  font-weight: 600;
  text-align: left;
`

const RadioButtonWrapper = styled.div`
  width: 100%;
  padding: 10px 0px;
  max-height: 270px;
  overflow-y: auto;
  text-align: left;
`

const SelectPlanType: React.FC<SelectPlanTypeProps> = ({
  disableMainNext,
  enableMainNext,
  handleHasNext,
}) => {
  const [showOptions, setShowOptions] = useState<boolean>(false)
  const handlePlanType = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value) enableMainNext && enableMainNext()
    currentPlanStore.setQuoteType(e.target.value)
    currentPlanStore.setPlanType('')
    currentPlanStore.setProvider('')

    showNavigatedButton(currentPlanStore.get().quoteType)
  }

  const showNavigatedButton = (quoteType: string) => {
    if (
      ![
        MedicareConstants.INSURANCE_TYPE.MA,
        MedicareConstants.INSURANCE_TYPE.MS,
        MedicareConstants.INSURANCE_TYPE.Group,
        MedicareConstants.INSURANCE_TYPE.IH,
        MedicareConstants.INSURANCE_TYPE.Retiree,
        MedicareConstants.INSURANCE_TYPE.Cobra,
      ].includes(quoteType)
    ) {
      handleHasNext && handleHasNext(false)
    } else {
      handleHasNext && handleHasNext(true)
    }
  }

  useEffect(() => {
    setShowOptions(
      currentPlanStore.get().coveredByHealthInsurance === true ? true : false
    )
    if (!isEmpty(currentPlanStore.quoteType)) {
      showNavigatedButton(currentPlanStore.quoteType)
      setShowOptions(true)
      enableMainNext && enableMainNext()
    }
    else {
      disableMainNext && disableMainNext()
    }
  }, [])

  const handleCoveredByHealthInsurance = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (e.target.value === 'Yes') {
      currentPlanStore.setCoveredByHealthInsurance(true)
      if (
        (currentPlanStore.quoteType &&
          ![
            MedicareConstants.INSURANCE_TYPE.AB,
            MedicareConstants.INSURANCE_TYPE.Medicaid,
            MedicareConstants.INSURANCE_TYPE.Tricare,
            MedicareConstants.INSURANCE_TYPE.None,
          ].includes(currentPlanStore.quoteType)) ||
        isEmpty(currentPlanStore.quoteType)
      ) {
        disableMainNext && disableMainNext()
      }
      setShowOptions(true)
    } else {
      currentPlanStore.setCoveredByHealthInsurance(false)
      currentPlanStore.setPlanType('')
      currentPlanStore.setProvider('')
      currentPlanStore.setQuoteType('')
      setShowOptions(false)
      enableMainNext && enableMainNext()
    }
  }

  React.useEffect(() => {
    if (!currentPlanStore.coveredByHealthInsurance) {
      enableMainNext && enableMainNext()
    }
  }, [currentPlanStore.coveredByHealthInsurance, enableMainNext])

  return (
    <Container>
      <Heading>Are you currently covered by other Health Insurance?</Heading>
      <RadioButtonWrapper>
        <RadioGroup
          alignItems='center'
          justifyContent='center'
          radioButtons={['Yes', 'No']}
          row={true}
          onChange={handleCoveredByHealthInsurance}
          value={
            currentPlanStore.get().coveredByHealthInsurance === true
              ? 'Yes'
              : currentPlanStore.get().coveredByHealthInsurance === false
              ? 'No'
              : ''
          }
          radioGroupName={`Info Steps - Are you currently covered by other Health Insurance?`}
          radioButtonWidth='130px'
        />
      </RadioButtonWrapper>
      {showOptions && (
        <>
          <Description>
            Please choose which type of insurance you currently have
          </Description>
          <RadioButtonWrapper>
            <RadioGroup
              radioButtons={MedicareConstants.getInsuranceTypes()}
              width='100%'
              onChange={handlePlanType}
              value={currentPlanStore.quoteType || 'Medicare Advantage'}
            />
          </RadioButtonWrapper>
        </>
      )}
    </Container>
  )
}

export default observer(SelectPlanType)
