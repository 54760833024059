export interface CarrierNonStdPlanStdPlanRiders {
  [carrierKey: string]: {
    [nonStdPlanKey: string]: {
      [stdPlanKey: string]: string[]
    }
  }
}
const RidersPlans: CarrierNonStdPlanStdPlanRiders = {
  UnitedHealthcare: {
    WI_BASE: {
      F: ['WIR_TVL', 'WIR_HH', 'WIR_PTB'],
      G: ['WIR_TVL', 'WIR_PTB'],
      N: ['WIR_TVL', 'WIR_PTB'],
    },
  },
  Others: {
    WI_BASE: {
      A: [] as string[],
      F: ['WIR_TVL', 'WIR_HH', 'WIR_PTA', 'WIR_PTB', 'WIR_EXCS'],
      G: ['WIR_TVL', 'WIR_HH', 'WIR_PTA', 'WIR_EXCS'],
      N: ['WIR_TVL', 'WIR_HH', 'WIR_PTA', 'WIR_PBCO'],
    },
    WI_HDED: {},
    'WI_50%': {},
    'WI_75%': {},
    'MN_50%': {},
    'MN_75%': {},
    'MN_A50%': {},
    MN_BASIC: {
      A: [] as string[],
      F: ['MNR_PTA', 'MNR_PTB', 'MNR_EXCS', 'MNR_PREV'],
      G: ['MNR_PTA', 'MNR_EXCS', 'MNR_PREV'],
    },
    MN_EXTB: {},
    MN_XBAS2: {},
    MN_PBCO: {},
    MN_HDED: {},
    MN_HDED2: {},
  },
}

export default RidersPlans
