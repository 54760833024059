import { isEmpty, isNull } from 'lodash'

import { ActionContext } from '../../../../cra'
import ApiManager from '../../../../api/ApiManager'
import BottomStepNavigator from '../../BottomStepNavigator.component'
import BouncingDotsLoader from '../../../common/Loading/BouncingDotsLoader'
import Button from '../../../common/Button/Button.component'
import Card from '../../../common/Card/Card.component'
import { County } from '../../../../interface/CountyInterface'
import { HttpMethods } from '../../../../enums/HttpMethodsEnum'
import { MdLocationPin } from 'react-icons/md'
import ModalComponent from '../../../common/Modal/Modal.component'
import OverlayLoader from '../../../common/OverlayLoader/OverlayLoader'
import PaginationConstants from '../../../../constants/PaginationConstants'
import PharmacyListSearch from '../../../ProfilePage/Modals/PharmacyListSearch'
import { PharmacyObj } from '../../../../interface/PharmacyInterface'
import { PharmacyRadiusEnum } from '../../../../enums/PharmacyRadiusEnum'
import PostalCodeSelection from '../../../common/PostalCodeSelection/PostalCodeSelection'
import RadioGroup from '../../../common/RadioGroup/RadioGroup.component'
import React from 'react'
import RouteConstants from '../../../../constants/RouteConstants'
import SecureComponent from '../../../../pages/common/SecureComponent'
import Select from '../../../common/Select/Select.component'
import { SelectChangeEvent } from '@mui/material'
import SkeletonCard from '../../../DashboardPage/SkeletonCard'
import { SnackbarTypes } from '../../../../enums/SnackbarTypesEnum'
import StringConstants from '../../../../constants/StringConstants'
import Tooltip from '../../../common/Tooltip/Tooltip.component'
import UrlConstants from '../../../../constants/UrlConstants'
import customerStore from '../../../../datastore/CustomerStore'
import { fetchQuotesPullStatus } from '../../../../utils/quotesPullStatus'
import { getApiErrorMessage } from '../../../../utils/StringUtils'
import { getPlanYear } from '../../../../utils/CommonUtils'
import mPartDPlansFilterStore from '../../../../datastore/medicareQuotes/MPartPlanFilterQuotesStore'
import medicareAdvantageFilterQuoteStore from '../../../../datastore/medicareQuotes/MedicareAdvantageFilterQuoteStore'
import { observer } from 'mobx-react'
import snackbarStore from '../../../../datastore/SnackbarStore'
import styled from '@emotion/styled'
import theme from '../../../../global/theme'
import { useNavigate } from 'react-router-dom'
import userActionStore from '../../../../datastore/UserActionStore'

interface PharmacyScreenProps {
  showBottomStepNavigator?: boolean
  redirect?: boolean
}

type PharmacyType = 'COMMUNITY_PHARMACY' | 'MAIL_ORDER_PHARMACY'

const Container = styled.div`
  padding: 30px 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media screen and (max-width: 600px) {
    padding: 30px 20px;
  }
`
const Heading = styled.h3`
  font-size: 20px;
  font-weight: 700px;
  padding-bottom: 20px;
  color: ${theme.colors.primary};
`
const Description = styled.p`
  padding-bottom: 20px;
  font-weight: 600;
`
const RadioButtonWrapper = styled.div`
  width: 100%;
  padding: 10px 0px;
`

const AddressWrapper = styled.div`
  padding: 10px 0px;
`

const SearchPromptWrapper = styled.div`
  padding: 10px 0px 10px 0px;

  @media screen and (max-width: 700px) {
    padding: 10px 0px 10px 0px;
  }
`
const DisableText = styled.div`
  font-size: 14px;
  margin-left: 5px;
  color: ${theme.colors.secondary};
`
const Address = styled.div`
  font-weight: 700;
  span {
    margin-left: 15px;
    font-weight: normal;
    color: ${theme.colors.primary};
    font-style: italic;
    text-decoration: underline;
    cursor: pointer;
  }
`

const SelectedPharmacyWrapper = styled.div`
  h2 {
    color: ${theme.colors.primary};
    padding-top: 20px;
    font-size: 18px;
  }
`

const SelectedPharmacyCard = styled.div`
  h3 {
    font-weight: 600;
    color: ${theme.colors.textDark};
    font-size: 16px;
    padding-bottom: 10px;
    max-width: 65%;
  }
  p {
    font-weight: 400;
    color: ${theme.colors.textGrey};
    font-size: 14px;
  }
`

const PharmacyScreen: React.FC<PharmacyScreenProps> = ({
  showBottomStepNavigator = true,
  redirect = true,
}) => {
  const [zipcodeInfo, setZipcodeInfo] = React.useState<{
    code: string
    county?: County
    state?: string
    optCounties?: County[]
  }>({
    code: customerStore.pharmacyPostalCode || customerStore.postalCode,
    county: userActionStore.zipCodeCache.find(
      (iterator) => iterator.code === customerStore.pharmacyPostalCode
    )?.county || {
      countyName: customerStore.county,
      countyFips: '',
    },
    state:
      userActionStore.zipCodeCache.find(
        (iterator) => iterator.code === customerStore.pharmacyPostalCode
      )?.state || customerStore.state,
    optCounties: [],
  })
  const [showPostalCodeSelection, setShowPostalCodeSelection] =
    React.useState(false)
  const [pharmacyList, setPharmacyList] = React.useState<PharmacyObj[]>([])
  const [pharmacyType, setPharmacyType] =
    React.useState<PharmacyType>('COMMUNITY_PHARMACY')

  const [addedPharmacyList, setAddedPharmacyList] = React.useState<
    PharmacyObj[]
  >([])

  const [isPharmacySelected, setIsPharmacySelected] = React.useState(false)
  const [radioButtonOption, setRadioButtonOption] = React.useState('Retail')
  const [state, setState] = React.useState({
    loading: true,
    buttonLoading: false,
    nextButtonLoading: false,
    addedPharmaciesLoading: false,
  })
  const [overlayLoading, setOverlayLoading] = React.useState(false)
  const [isOpenPharmacySearch, setOpenPharmacySearch] = React.useState(false)
  const [searchInputLoading, setSearchInputLoading] = React.useState(false)
  const radiusOptions = Object.values(PharmacyRadiusEnum)
  const isButtonDisabled =
    customerStore.isMAQuotesProcessing ||
    customerStore.isMSQuotesProcessing ||
    customerStore.isMPartDQuotesProcessing
  const [radiusDisplayValue, setRadiusDisplayValue] = React.useState(
    radiusOptions[0].toString()
  )
  const [radius, setRadius] = React.useState<number>(
    +radiusOptions[0].toString().replace(' miles', '')
  )

  const navigate = useNavigate()
  const { trackCurrentPage } = React.useContext(ActionContext)

  const forSpouse = customerStore.get().forSpouse

  const getPharmacyInfo = React.useCallback(() => {
    return new Promise((resolve, reject) => {
      ApiManager.makeApiCallWithAuthentication(
        UrlConstants.GET_SELECTED_PHARMACY_DETAILS_SF.USECASE,
        HttpMethods.GET_METHOD,
        { $forSpouse: forSpouse }
      )
        .then((res) => {
          if (res.status === 200) {
            const pharmacyData = res.data.data as PharmacyObj[]
            setAddedPharmacyList([...pharmacyData])

            setState((s) => ({
              ...s,
              loading: false,
            }))
            if (pharmacyData.length !== 0) {
              setSearchInputLoading(false)
              setIsPharmacySelected(true)
            } else setIsPharmacySelected(false)
          } else if (
            res.data?.message === 'No pharmacy exists for the customer'
          ) {
            setState((s) => ({
              ...s,
              loading: false,
            }))
          } else throw res

          resolve(res)
        })
        .catch((error) => {
          setRadioButtonOption('Retail')
          setIsPharmacySelected(false)
          setAddedPharmacyList([])
          snackbarStore.set({
            snackbarMessage: getApiErrorMessage(
              'fetch selected pharmacy details'
            ),
            snackbarOpen: true,
            snackbarType: 'error',
          })

          setState((s) => ({
            ...s,
            loading: false,
          }))
          reject(error)
        })
    })
  }, [forSpouse])

  React.useEffect(() => {
    trackCurrentPage(
      `${showBottomStepNavigator ? 'Info Steps -' : 'Profile -'} ${
        forSpouse ? 'Spouse/Partner’s Pharmacy' : 'Pharmacy'
      }`
    )
    setRadioButtonOption('Retail')
    setAddedPharmacyList([])
    setIsPharmacySelected(false)
    getPharmacyInfo()
  }, [forSpouse])

  React.useEffect(() => {
    window.scroll({ top: 0, behavior: 'smooth' })
  }, [])

  const handlePharmacyTypeChange = (e: any) => {
    setRadioButtonOption(e.target.value)
    if (e.target.value === 'Mail Order')
      handleSavePharmacy(null, 'MAIL_ORDER_PHARMACY')
  }

  const changeRadius = (selectedRadiusDisplayValue: string) => {
    setRadius(+selectedRadiusDisplayValue.replace(' miles', ''))
    setRadiusDisplayValue(selectedRadiusDisplayValue)
  }

  const handleRadiusChange = (e: SelectChangeEvent<string>) => {
    changeRadius(e.target.value)
  }

  const handlePharmacySelection = (item: any, page: number) => {
    handleSavePharmacy(item.index, 'COMMUNITY_PHARMACY', page)
  }

  const handleSelectedPharmacyDelete = (id: string): void => {
    setState({
      ...state,
      addedPharmaciesLoading: true,
    })

    const addedPharmacies = addedPharmacyList

    ApiManager.makeApiCallWithAuthentication(
      UrlConstants.GET_SELECTED_PHARMACY_DETAILS_SF.USECASE,
      HttpMethods.GET_METHOD,
      { $forSpouse: customerStore.get().forSpouse }
    )
      .then((res) => {
        if (res.status === 200) {
          ApiManager.makeApiCallWithAuthentication(
            UrlConstants.DELETE_PHARMACY_DETAIL_SF.USECASE,
            HttpMethods.DELETE_METHOD,
            {
              $pharmacyId: id,
              $forSpouse: customerStore.get().forSpouse,
            }
          )
            .then((res) => {
              snackbarStore.set({
                snackbarMessage: StringConstants.SNACKBAR_DELETE_SUCCESS_MSG,
                snackbarOpen: true,
                snackbarType: SnackbarTypes.SUCCESS,
              })
              if (!forSpouse) {
                medicareAdvantageFilterQuoteStore.clearStore()
                mPartDPlansFilterStore.clearStore()
                fetchQuotesPullStatus(getPlanYear())
              }
              if (addedPharmacies.length === 1) {
                setIsPharmacySelected(false)
                setAddedPharmacyList([])
                setRadioButtonOption('Retail')

                if (customerStore.get().forSpouse === false) {
                  ApiManager.makeApiCallWithAuthentication(
                    UrlConstants.DELETE_INFO_STEPS.USECASE,
                    HttpMethods.DELETE_METHOD,
                    {
                      $infoStep: 'pharmacy',
                    }
                  )
                    .then((res) => {
                      if (res.status === 200) {
                        customerStore.setInfoSteps([
                          ...customerStore.infoSteps.filter(
                            (iterator: string) => {
                              if (iterator !== 'pharmacy') return iterator
                            }
                          ),
                        ])
                      } else throw res
                    })
                    .catch((err) => {
                      snackbarStore.set({
                        snackbarMessage: 'Something went wrong',
                        snackbarOpen: true,
                        snackbarType: 'error',
                      })
                    })
                }
              } else if (addedPharmacies.length === 3) {
                const filteredPharmacies = addedPharmacies.filter(
                  (iterator) => iterator.id !== id
                )
                setAddedPharmacyList(filteredPharmacies)
              }
              getPharmacyInfo().catch((err) => {
                throw err
              })
            })
            .catch((err) => {})
            .finally(() => {
              setState({
                ...state,
                addedPharmaciesLoading: false,
              })
            })
        }
      })
      .catch((error) => {
        snackbarStore.set({
          snackbarMessage: getApiErrorMessage(
            'fetch selected pharmacy details'
          ),
          snackbarOpen: true,
          snackbarType: SnackbarTypes.ERROR,
        })
      })
  }

  const handleSavePharmacy = (
    index: number | null,
    type: 'COMMUNITY_PHARMACY' | 'MAIL_ORDER_PHARMACY',
    page?: number
  ) => {
    let pharmacyDetails = {} as PharmacyObj
    if (!isNull(index) && type === 'COMMUNITY_PHARMACY')
      pharmacyDetails = pharmacyList[index]
    else
      pharmacyDetails = {
        id: '2',
        name: 'MAIL ORDER PHARMACY',
        pharmacyType: 'MAIL_ORDER_PHARMACY',
      }

    setState({
      ...state,
      // loading: true,
      buttonLoading: true,
      nextButtonLoading: true,
      addedPharmaciesLoading: true,
    })
    setOverlayLoading(() => true)
    setOpenPharmacySearch(false)
    setSearchInputLoading(true)
    setIsPharmacySelected(true)

    ApiManager.makeApiCallWithAuthentication(
      UrlConstants.POST_PHARMACY_DETAILS_SF.USECASE,
      HttpMethods.POST_METHOD,
      {
        $pharmacyId: pharmacyDetails.id,
        $forSpouse: customerStore.get().forSpouse,
        pharmacyType:
          type === 'MAIL_ORDER_PHARMACY'
            ? 'MAIL ORDER PHARMACY'
            : 'COMMUNITY PHARMACY',
      }
    )
      .then((res) => {
        if (res.data.status === 'success') {
          snackbarStore.set({
            snackbarOpen: true,
            snackbarMessage: StringConstants.SNACKBAR_SAVE_SUCCESS_MSG,
            snackbarType: SnackbarTypes.SUCCESS,
          })

          if (customerStore.get().forSpouse === false) {
            ApiManager.makeApiCallWithAuthentication(
              UrlConstants.PATCH_INFO_STEPS.USECASE,
              HttpMethods.PATCH_METHOD,
              {
                $infoStep: 'pharmacy',
              }
            )
              .then((res) => {
                if (res.status === 200) {
                  if (!forSpouse) {
                    medicareAdvantageFilterQuoteStore.clearStore()
                    mPartDPlansFilterStore.clearStore()
                    fetchQuotesPullStatus(getPlanYear())
                  }
                  customerStore.setInfoSteps([
                    ...customerStore.infoSteps,
                    'pharmacy',
                  ])
                } else throw res
              })
              .catch((err) => {
                snackbarStore.set({
                  snackbarMessage: 'Something went wrong',
                  snackbarOpen: true,
                  snackbarType: 'error',
                })
              })
          }
          getPharmacyInfo().catch((err) => {
            throw err
          })
        } else throw res
      })
      .catch((err) => {
        if (
          /Pharmacy/.test(err?.data?.message) &&
          /already exists/.test(err?.data?.message)
        ) {
          if (isEmpty(pharmacyDetails)) {
            getPharmacyInfo().catch((error) => {
              err = error
            })
          }
          if (!customerStore.get().infoSteps.includes('pharmacy'))
            customerStore.setInfoSteps('pharmacy')
          snackbarStore.set({
            snackbarOpen: true,
            snackbarMessage: getApiErrorMessage(
              'save pharmacy details. Added pharmacy exists'
            ),
            snackbarType: SnackbarTypes.ERROR,
          })
        } else {
          snackbarStore.set({
            snackbarOpen: true,
            snackbarMessage: getApiErrorMessage('save pharmacy details'),
            snackbarType: SnackbarTypes.ERROR,
          })
        }
        setSearchInputLoading(false)
        setRadioButtonOption('Retail')
        setIsPharmacySelected(false)
      })
      .finally(() => {
        setState({
          ...state,
          loading: false,
          buttonLoading: false,
          nextButtonLoading: false,
          addedPharmaciesLoading: false,
        })
        setOverlayLoading(() => false)
      })
  }

  return state.loading ? (
    <SkeletonCard height={100} />
  ) : (
    <Container>
      {isOpenPharmacySearch && (
        <ModalComponent
          setOpen={() => {
            setOpenPharmacySearch(false)
          }}
          description='Popup - Pharmacy Search'
        >
          <PharmacyListSearch
            zipCode={zipcodeInfo.code}
            radius={radius}
            setOpen={() => {
              setOpenPharmacySearch(false)
            }}
            handleSearchClick={handlePharmacySelection}
            setPharmacyList={setPharmacyList}
            radiusOptions={radiusOptions}
            selectNextRadiusOption={changeRadius}
          />
        </ModalComponent>
      )}
      {overlayLoading && <OverlayLoader />}
      <Heading>Choose your pharmacy</Heading>
      <Description>
        Drug costs vary based on the pharmacy you use. Choosing pharmacies lets
        us show you your estimated drug costs, helping you pick the lowest cost
        plan. You don’t have to choose the pharmacies you currently use.
        <br /> Add both mail-order and retail pharmacies to find the lowest
        cost.
      </Description>

      {!isPharmacySelected && radioButtonOption === 'Retail' && (
        <RadioButtonWrapper>
          <RadioGroup
            radioButtons={
              addedPharmacyList.find(
                (iterator) => iterator.defaultMailOrder === true
              )
                ? ['Retail']
                : ['Retail', 'Mail Order']
            }
            row={window.innerWidth > 700 ? true : false}
            width={window.innerWidth > 700 ? '500px' : '100%'}
            onChange={handlePharmacyTypeChange}
            formLabel='Select your pharmacy type'
            value={radioButtonOption}
            radioGroupName={`${
              showBottomStepNavigator ? 'Info Steps -' : 'Profile -'
            } Choose your pharmacy`}
            disabledValues={
              customerStore.isMAQuotesProcessing ||
              customerStore.isMSQuotesProcessing ||
              customerStore.isMPartDQuotesProcessing
                ? ['Mail Order']
                : undefined
            }
          />
        </RadioButtonWrapper>
      )}

      {!isPharmacySelected && radioButtonOption === 'Mail Order' && (
        <BouncingDotsLoader />
      )}

      {pharmacyType === 'COMMUNITY_PHARMACY' &&
        !isPharmacySelected &&
        radioButtonOption === 'Retail' && (
          <AddressWrapper>
            <p>Search for Pharmacy near you</p>
            <Address>
              {zipcodeInfo.code && (
                <p style={{ display: 'inline' }}>
                  {zipcodeInfo.code} - {zipcodeInfo.county?.countyName},{' '}
                  {zipcodeInfo.state}
                </p>
              )}
              <span
                onClick={() => {
                  setShowPostalCodeSelection(!showPostalCodeSelection)
                }}
              >
                {zipcodeInfo.code ? 'Change' : 'Add your Postal code'}
              </span>
            </Address>
            <p style={{ marginTop: '20px' }}>Search within</p>
            <Select
              formLabel=''
              options={radiusOptions}
              width='100px'
              height='39px'
              margin='10px 0px -10px 0px'
              labelMargin='0px 0px 0px 0px'
              value={radiusDisplayValue}
              onChange={handleRadiusChange}
            />
            {showPostalCodeSelection && (
              <PostalCodeSelection
                onSubmit={(zipInfo: {
                  code: string
                  county?: County
                  state?: string
                  optCounties?: County[]
                }) => {
                  if (zipInfo.code) {
                    setZipcodeInfo({ ...zipcodeInfo, ...zipInfo })
                    customerStore.setPharmacyPostalCode(zipInfo.code)
                    setShowPostalCodeSelection(false)
                  }
                }}
                onCancel={() => setShowPostalCodeSelection(false)}
              />
            )}
          </AddressWrapper>
        )}

      {!isPharmacySelected &&
        pharmacyType === 'COMMUNITY_PHARMACY' &&
        radioButtonOption === 'Retail' && (
          <SearchPromptWrapper>
            {searchInputLoading ? (
              <BouncingDotsLoader />
            ) : (
              <>
                <Button
                  color='primary'
                  variant='contained'
                  onClick={() => {
                    setOpenPharmacySearch(true)
                  }}
                  disabled={
                    customerStore.isMAQuotesProcessing ||
                    customerStore.isMSQuotesProcessing ||
                    customerStore.isMPartDQuotesProcessing
                  }
                >
                  Search Pharmacies
                </Button>
                {isButtonDisabled && (
                  <DisableText>
                    {StringConstants.DISABLE_BUTTON_MSG}
                  </DisableText>
                )}
              </>
            )}
          </SearchPromptWrapper>
        )}

      {isPharmacySelected && (
        <SelectedPharmacyWrapper>
          <h2>Saved Pharmacies</h2>

          {isPharmacySelected && state.addedPharmaciesLoading && (
            <BouncingDotsLoader />
          )}

          {isPharmacySelected &&
            !state.addedPharmaciesLoading &&
            addedPharmacyList.length !== 0 &&
            addedPharmacyList.map((iterator: any) => {
              if (iterator.defaultMailOrder === false) {
                return (
                  <Card
                    cardType='deleteOnly'
                    deleteCard={() => handleSelectedPharmacyDelete(iterator.id)}
                    width='100%'
                    onlyDeleteButton
                    key={iterator.id}
                  >
                    <SelectedPharmacyCard>
                      <h3>
                        {iterator && iterator.name}
                        {iterator && iterator.isDefaultPharmacy && (
                          <Tooltip
                            title={StringConstants.TOOLTIP_DEFAULT_PHARMACY_MSG}
                            content={'i'}
                          />
                        )}
                      </h3>
                      <p style={{ paddingBottom: '10px' }}>
                        {iterator.address1 +
                          ', ' +
                          iterator.city +
                          ', ' +
                          iterator.state +
                          ', ' +
                          iterator.zip}
                      </p>
                      <p>
                        <MdLocationPin
                          style={{
                            fontSize: '14px',
                          }}
                        />
                        {iterator.distance
                          ? ' ' + iterator.distance + ' mi'
                          : 'N/A'}
                      </p>
                    </SelectedPharmacyCard>
                  </Card>
                )
              } else if (iterator.defaultMailOrder === true) {
                return (
                  <Card
                    cardType='deleteOnly'
                    deleteCard={() => handleSelectedPharmacyDelete(iterator.id)}
                    width='100%'
                    onlyDeleteButton
                    key={iterator.id}
                  >
                    <>
                      <h3
                        style={{
                          color: theme.colors.textGrey,
                          maxWidth: '65%',
                        }}
                      >
                        Mail Order Pharmacy
                      </h3>
                      <SelectedPharmacyCard>
                        <p style={{ paddingTop: '10px' }}>
                          Add both mail-order and retail pharmacies to find the
                          lowest cost.
                        </p>
                      </SelectedPharmacyCard>
                    </>
                  </Card>
                )
              }
              return <></>
            })}
        </SelectedPharmacyWrapper>
      )}

      <div style={{ padding: isPharmacySelected ? '20px 0px' : '0px 0px' }}>
        {isPharmacySelected &&
          addedPharmacyList.length >= 1 &&
          addedPharmacyList.length < 3 &&
          !state.addedPharmaciesLoading && (
            <>
              <Button
                color='primary'
                variant='contained'
                onClick={() => {
                  setIsPharmacySelected(false)
                  setRadioButtonOption('Retail')
                }}
                margin='0 1rem 0 0'
                width='auto'
                bold={false}
                disabled={
                  customerStore.isMAQuotesProcessing ||
                  customerStore.isMSQuotesProcessing ||
                  customerStore.isMPartDQuotesProcessing
                }
              >
                Add Another Pharmacy
              </Button>
              {isButtonDisabled && (
                <DisableText>{StringConstants.DISABLE_BUTTON_MSG}</DisableText>
              )}
            </>
          )}
      </div>

      {!isPharmacySelected &&
        addedPharmacyList.length === 1 &&
        !state.addedPharmaciesLoading &&
        !searchInputLoading && (
          <Button
            color='secondary'
            variant='contained'
            onClick={() => {
              setIsPharmacySelected(true)
              setRadioButtonOption('Retail')
            }}
            margin='0 1rem 0 0'
          >
            Cancel
          </Button>
        )}

      {showBottomStepNavigator && (
        <BottomStepNavigator
          handleNext={() => {
            window.scrollTo({ top: 0, behavior: 'smooth' })
            navigate(RouteConstants.INFO_STEPS_CURRENT_PLAN)
          }}
          handleBack={() => {
            window.scrollTo({ top: 0, behavior: 'smooth' })
            navigate(RouteConstants.INFO_STEPS_HEALTH)
          }}
          disableNext={addedPharmacyList.length === 0}
          isLoading={state.nextButtonLoading}
          currentInfoStep='Pharmacy'
        />
      )}
    </Container>
  )
}

export default SecureComponent(observer(PharmacyScreen))
