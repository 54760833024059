import React from 'react'
import RadioGroup from '../../../common/RadioGroup/RadioGroup.component'

interface RadioQuestionI {
  id: any
  options: any
  value: any
  handleResponseSelection: (id: any, responseArray: any) => void
  radioGroupName?: string
}

const RadioQuestion: React.FC<RadioQuestionI> = ({
  id,
  options,
  value,
  handleResponseSelection,
  radioGroupName = ''
}) => {
  const handleRadioChange = (e: any) => {
    handleResponseSelection(id, [e.target.value])
  }

  return (
    <>
      <RadioGroup
        radioButtons={[...options.map((option: any) => option.option)]}
        onChange={handleRadioChange}
        value={value}
        radioGroupName={radioGroupName}
      />
    </>
  )
}

export default RadioQuestion
