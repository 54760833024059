import {
  IconButton,
  InputAdornment,
  TextField as MuiTextField,
} from '@mui/material'

import React from 'react'
import { AiFillEye as Visibility } from 'react-icons/ai'
import { AiFillEyeInvisible as VisibilityOff } from 'react-icons/ai'
import styled from '@emotion/styled'

interface InputFieldProps {
  label?: string
  width?: string
  fullWidth?: boolean
  size?: 'small' | 'medium'
  color?: 'primary' | 'secondary' | 'info' | undefined
  error?: boolean
  value?: string
  helperText?: string
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  fontSize?: number
  additionMarginBottom?: string
}

const TextField = styled(MuiTextField)({
  '.MuiOutlinedInput-root': {
    borderRadius: '30px',
    paddingTop: '2px',
    width: '100%',
  },
  '.MuiOutlinedInput-input': {},
  '.MuiInputLabel-root': {
    fontSize: '16px',
  },
  '.MuiInputLabel-shrink': {
    marginInlineStart: '-4px',
  },
  '.MuiFormHelperText-root': {
    paddingTop:'0px',
  },
})

const PasswordField: React.FC<InputFieldProps> = ({
  label,
  fullWidth = false,
  size = 'small',
  width,
  value,
  color = 'primary',
  error = false,
  helperText = '',
  onBlur,
  onChange,
  fontSize = 16,
  additionMarginBottom,
}) => {
  const [showPassword, setShowPassword] = React.useState(false)

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }

  return (
    <TextField
      type={showPassword ? 'text' : 'password'}
      variant='outlined'
      color={color}
      label={label}
      margin='dense'
      value={value}
      error={error}
      helperText={helperText}
      onBlur={onBlur}
      onChange={onChange}
      size={size}
      fullWidth={fullWidth}
      InputProps={{
        endAdornment: (
          <InputAdornment position='end'>
            <IconButton
              aria-label='toggle password visibility'
              onClick={handleClickShowPassword}
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        ),
        style: { fontSize: fontSize },
      }}
      sx={{
        width: width,
        marginBottom: additionMarginBottom ? additionMarginBottom : ''
      }}
      InputLabelProps={{
        sx: {
          fontSize: `${fontSize}px`,
        },
      }}
    />
  )
}

export default PasswordField
