import Button from '../../common/Button/Button.component'
import React from 'react'
import styled from '@emotion/styled'
import theme from '../../../global/theme'

interface ModelProps {
  onConfirm: () => void
  onReject: () => void
  drug: string
  edit: boolean
}

const Container = styled.div`
  width: 500px;
  padding: 20px;
  @media screen and (max-width: 650px) {
    width: 100%;
    padding: 20px;
  }
  h3 {
    color: #272f69;
    padding-top: 0px;
    padding-bottom: 30px;
  }
  p {
    margin-bottom: 16px;
    font-weight: 600;
    font-size: 14px;
    color: ${theme.colors.textDark};
  }

  div {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
`
const ModalButton = styled.div`
  width: 150px;
  text-decoration: none;

  @media screen and (max-width: 750px) {
    width: 100%;
  }
`

const AddDrugConfirmationPopup: React.FC<ModelProps> = ({
  onConfirm,
  onReject,
  drug,
  edit,
}) => {
  return (
    <Container>
      <h3>Are you sure you want to {edit ? 'edit' : 'add'}?</h3>
      <p style={{ fontSize: '18px' }}>{drug}</p>
      <p>Click “Yes” to confirm !</p>
      <div>
        <ModalButton>
          <Button
            variant='contained'
            bold={false}
            width='100%'
            color='secondary'
            onClick={(e) => {
              onConfirm()
            }}
            fontSize='14px'
          >
            Yes
          </Button>
        </ModalButton>

        <ModalButton>
          <Button
            color='info'
            bold={false}
            width='100%'
            onClick={(e) => {
              onReject()
            }}
            fontSize='14px'
          >
            No
          </Button>
        </ModalButton>
      </div>
    </Container>
  )
}

export default AddDrugConfirmationPopup
