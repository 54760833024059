import Finish from './StepperComponents/Finish'
import React from 'react'
import Reset from './StepperComponents/Reset'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import Stepper from '@mui/material/Stepper'
import Verification from './StepperComponents/Verification'
import { useNavigate } from 'react-router-dom'

const ResetPasswordStepper: React.FC = () => {
  const navigate = useNavigate()
  const [activeStep, setActiveStep] = React.useState(0)
  const [currentPassword, setCurrentPassword] = React.useState('')

  const handleNext = () => {
    if (activeStep < stepDetails.length)
      setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const handleFinish = () => {
    navigate('/')
  }

  const setPassword = (password: string) => {
    setCurrentPassword(password)
  }

  const getPassword = () => {
    return currentPassword
  }

  const stepDetails = [
    {
      label: 'Verification',
      component: (
        <Verification
          handleNext={handleNext}
          handleBack={handleBack}
          setPassword={setPassword}
        />
      ),
    },
    {
      label: 'Reset',
      component: (
        <Reset
          handleNext={handleNext}
          handleBack={handleBack}
          getPassword={getPassword}
          setPassword={setPassword}
        />
      ),
    },
    {
      label: 'Finish',
      component: null,
    },
  ]

  return (
    <>
      <Stepper activeStep={activeStep}>
        {stepDetails.map((iterator) => {
          return (
            <Step key={iterator.label}>
              <StepLabel>{iterator.label}</StepLabel>
            </Step>
          )
        })}
      </Stepper>
      {activeStep < stepDetails.length - 1 ? (
        stepDetails[activeStep].component
      ) : (
        <Finish handleFinish={handleFinish} handleBack={handleBack} />
      )}
    </>
  )
}

export default ResetPasswordStepper
