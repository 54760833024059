import { ActionContext, fireEvent } from '../../../../cra'
import React, { useEffect, useRef, useState } from 'react'
import { isEmpty, isEqual, isNull } from 'lodash'

import BottomAddToCompare from '../../BottomAddToCompare/BottomAddToCompare.component'
import Button from '../../../common/Button/Button.component'
import CallUsNow from '../../Common/Recommendation/CallUsNow.component'
import FilterBox from '../../Common/FilterBox.component.tsx/FilterBox'
import FilterPrescription from './FilterPrescription.component'
import { MNonSupplementPlan } from '../../../../interface/quotes/mNonSupplementPlans/MNonSupplementPlan'
import { MPartDPlan } from '../../../../interface/quotes/mNonSupplementPlans/medicarePartD/MPartDPlan'
import { MPartDPlanDisplayFilter } from '../../../../interface/quotes/mNonSupplementPlans/medicarePartD/MPartDPlanDisplayFilters'
import MedicarePrescriptionCard from './MedicarePrescriptionCard.component'
import { MedicareQuotesType } from '../../../../enums/MedicareQuoteTypeEnum'
import ModalComponent from '../../../common/Modal/Modal.component'
import NoPlanFoundCard from '../../NoPlanFoundCard'
import { Pagination } from '@mui/material'
import PaginationConstants from '../../../../constants/PaginationConstants'
import { RiFilter2Fill } from 'react-icons/ri'
import SortBy from '../../Common/SortByDropdown.component.tsx/SortBy'
import ThankYouModal from '../../Modals/ThankYouModal'
import { ThreeDots } from 'react-loader-spinner'
import customerStore from '../../../../datastore/CustomerStore'
import { getContact } from '../../../../utils/getContact'
import { isUndefined } from 'lodash'
import mPartDPlansFilterStore from '../../../../datastore/medicareQuotes/MPartPlanFilterQuotesStore'
import { observer } from 'mobx-react'
import styled from '@emotion/styled'
import theme from '../../../../global/theme'
import userActionStore from '../../../../datastore/UserActionStore'

const Container = styled.div`
  display: flex;
  justify-content: center;
  @media screen and (max-width: 950px) {
    justify-content: center;
  }
`
const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 1.25em 0;
`

const CardsWrapper = styled.div`
  padding: 20px 20px 150px 20px;
`
const UtilityContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  grid-gap: 20px;
  margin: 30px 20px;
  padding: 10px 0px;
  border-bottom: 1px solid ${theme.colors.borderColor};
  h3 {
    display: none;
    align-items: center;
    padding: 8px 24px;
    border-radius: 4px;
    border: 1px solid ${theme.colors.primary};
    @media screen and (max-width: 950px) {
      cursor: pointer;
      display: flex;
    }
  }
  @media screen and (max-width: 640px) {
    margin: 30px 0px;
  }
`
const ThreeDotsWrapper = styled.div`
  display: flex;
  margin: 20px;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  width: 900px;

  @media screen and (max-width: 1250px) {
    width: 700px;
  }

  @media screen and (max-width: 1050px) {
    width: 600px;
  }

  @media screen and (max-width: 640px) {
    width: 100%;
    margin: 20px 0px;
  }
`

const FilterContainer = styled.div`
  display: block;

  @media screen and (max-width: 600px) {
    display: flex;
    flex-direction: column-reverse;
  }
`

const MedicarePrescription: React.FC<{
  medicarePartDPlans: MNonSupplementPlan[]
}> = ({ medicarePartDPlans }) => {
  const [sortPlanOptions, setSortPlanOptions] = useState<
    {
      title: string
      value: string
    }[]
  >([])

  const [mPartDPlansFilters, setMPartDPlansFilters] =
    useState<MPartDPlanDisplayFilter>({} as MPartDPlanDisplayFilter)

  useEffect(() => {
    if (!isUndefined(mPartDPlansFilterStore.getMPartDPlansFilters())) {
      setMPartDPlansFilters(mPartDPlansFilterStore.getMPartDPlansFilters())
    }
  }, [mPartDPlansFilterStore.getMPartDPlansFilters()])

  const [currentSortOption, setCurrentSortOption] = useState<{
    label: string
    index: number
  }>()

  const paginationCount = Math.ceil(
    isNull(mPartDPlansFilterStore.getTotalCount())
      ? 0
      : mPartDPlansFilterStore.getTotalCount()! /
          (mPartDPlansFilterStore.getMPartDPlanRequestFilters().paginationDetail
            ?.size as number)
  )

  const { trackCurrentPage } = React.useContext(ActionContext)
  const [showFilter, setShowFilter] = React.useState(false)
  const filterPrescriptionRef = useRef<any>(null)
  const handleShowFilter = () => {
    setShowFilter(!showFilter)
  }

  const [clearFilterState, setClearFilterState] = React.useState(false)

  useEffect(() => {
    trackCurrentPage('Dashboard - Medicare Prescription Drugs')
  }, [trackCurrentPage])

  useEffect(() => {
    if (!isEqual(mPartDPlansFilterStore.getMPartDPlansFilters(), {})) {
      const planOptions = mPartDPlansFilterStore
        .getMPartDPlansFilters()
        .sortOptions.map((option) => {
          return { title: option, value: option }
        })

      setSortPlanOptions(planOptions)

      setCurrentSortOption({
        label: mPartDPlansFilterStore.getMPartDPlansFilters().selectSortOption!,
        index: planOptions.findIndex((option) => {
          return (
            option.title ===
            mPartDPlansFilterStore.getMPartDPlansFilters().selectSortOption!
          )
        }),
      })
    }
  }, [
    JSON.stringify(
      mPartDPlansFilterStore.getMPartDPlansFilters().selectSortOption
    ),
  ])

  useEffect(() => {
    if (!isEmpty(medicarePartDPlans)) {
      mPartDPlansFilterStore.setPlansFetched(true)
      mPartDPlansFilterStore.setPaginationApplied(false)
      mPartDPlansFilterStore.setPaginationLoader(false)
    }
    mPartDPlansFilterStore.setClearFilters(false)
  }, [medicarePartDPlans])

  const [thankYouModalActive, setThankYouModalActive] = React.useState(false)

  const handleSortChange = (e: any) => {
    const sortOption: string = e.target.innerText
      .split()[0]
      .split(' (High to Low)')[0]
    if (
      mPartDPlansFilterStore.getMPartDPlansFilters().selectSortOption !==
      sortOption
    ) {
      mPartDPlansFilterStore.setPrevSortType(
        mPartDPlansFilterStore.getMPartDPlansFilters().selectSortOption
      )
      mPartDPlansFilterStore.mPartDPlanFilters.selectSortOption = sortOption

      if (sortOption === 'Overall Rating') {
        mPartDPlansFilterStore.setSortDetail({
          order: 'DESC',
          type: sortOption,
        })
      } else {
        mPartDPlansFilterStore.setSortDetail({
          order: 'ASC',
          type: sortOption,
        })
      }
      mPartDPlansFilterStore.setFilterApplied(true)

      mPartDPlansFilterStore.setPaginationDetails({
        page: PaginationConstants.DEFAULT_PAGE,
        size: PaginationConstants.DEFAULT_PAGE_SIZE,
      })
      customerStore.setMPDPageNumber(PaginationConstants.DEFAULT_PAGE)
      customerStore.setMPDPageSize(PaginationConstants.DEFAULT_PAGE_SIZE)
    }
  }

  const handlePaginationChange = () => {
    // window.scrollTo(0, 0)
    mPartDPlansFilterStore.setPaginationLoader(true)
    mPartDPlansFilterStore.setPaginationDetails({
      page: customerStore.mpdPageNumber + PaginationConstants.DEFAULT_PAGE,
      size: PaginationConstants.DEFAULT_PAGE_SIZE,
    })
    // const currentFilter = mPartDPlansFilterStore.getMPartDPlanRequestFilters()
    // currentFilter.paginationDetail = {
    //   page: page,
    //   size: PaginationConstants.DEFAULT_PAGE_SIZE,
    // }
    customerStore.setMPDPageNumber(
      customerStore.mpdPageNumber + PaginationConstants.DEFAULT_PAGE
    )
    customerStore.setMPDPageSize(
      customerStore.mpdPageSize + PaginationConstants.DEFAULT_PAGE_SIZE
    )
    mPartDPlansFilterStore.setPaginationApplied(true)
    //mPartDPlansFilterStore.setMPartDPlanRequestFilters(currentFilter)
  }

  const handleOnEnroll = () => {
    setThankYouModalActive(true)
  }

  useEffect(() => {
    const closeFilterPrescription = (e: any) => {
      if (
        filterPrescriptionRef.current !== null &&
        e.composedPath() &&
        !e
          .composedPath()
          .includes(filterPrescriptionRef.current.lastElementChild) &&
        showFilter === true
      ) {
        setShowFilter(false)
      }
    }
    document.body.addEventListener('click', closeFilterPrescription)
    return () =>
      document.body.removeEventListener('click', closeFilterPrescription)
  }, [])

  const clearButtonState = () => {
    setClearFilterState(!clearFilterState)
  }
  const [selectedFilters, setSelectedFilters] = useState<string[]>([])

  const updateFilters = (unFilter: string) => {
    selectedFilters.splice(selectedFilters.indexOf(unFilter), 1)
    if (mPartDPlansFilters) {
      const checkedCarriers = mPartDPlansFilters.carriers?.map((item) =>
        item.value === unFilter ? { ...item, checked: !item.checked } : item
      )
      const checkedDrugs = mPartDPlansFilters.drugCheckBoxes?.map((item) =>
        item.value === unFilter ? { ...item, checked: !item.checked } : item
      )

      const checkedProviders = mPartDPlansFilters.providers?.map((item) =>
        item.value === unFilter ? { ...item, checked: !item.checked } : item
      )

      setMPartDPlansFilters({
        ...mPartDPlansFilters,
        carriers: checkedCarriers,
        drugCheckBoxes: checkedDrugs,
        providers: checkedProviders,
      })
      mPartDPlansFilterStore.setIsFilterChanged(true)
    }
  }

  return !mPartDPlansFilterStore.isPlanFetched() ? (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <ThreeDots color='#222C69' height={80} width={80} />
    </div>
  ) : (
    <Container>
      {thankYouModalActive && (
        <ModalComponent setOpen={() => setThankYouModalActive(false)}>
          <ThankYouModal setOpen={() => setThankYouModalActive(false)} />
        </ModalComponent>
      )}
      {!isEmpty(mPartDPlansFilterStore.getMPartDPlansFilters()) && (
        <FilterPrescription
          showFilter={showFilter}
          handleShowFilter={handleShowFilter}
          filterRef={filterPrescriptionRef}
          clearFilterState={clearFilterState}
          clearButtonState={clearButtonState}
          setSelectedFilters={(Value: string[]) => {
            setSelectedFilters(Value)
          }}
          mPartDPlansFilters={mPartDPlansFilters}
          setMPartDPlansFilters={setMPartDPlansFilters}
        />
      )}

      <CardsWrapper>
        <FilterContainer>
          <FilterBox
            clearButtonState={clearButtonState}
            selectedFilters={selectedFilters}
            updateFilters={updateFilters}
          />
          <UtilityContainer>
            <h3
              onClick={() => {
                handleShowFilter()
              }}
              style={{ color: `${theme.colors.primary}`, fontWeight: '600' }}
            >
              <RiFilter2Fill /> Filters
            </h3>
            {sortPlanOptions && (
              <SortBy
                options={sortPlanOptions}
                value={currentSortOption}
                onChange={handleSortChange}
              />
            )}
          </UtilityContainer>
        </FilterContainer>
        {mPartDPlansFilterStore.isFilterApplied() ||
        mPartDPlansFilterStore.isFiltersCleared() ? (
          <ThreeDotsWrapper>
            <ThreeDots color='#222C69' height={80} width={80} />
          </ThreeDotsWrapper>
        ) : (
          <>
            {medicarePartDPlans && medicarePartDPlans.length > 0 ? (
              <>
                {medicarePartDPlans.map((cardData, index) => (
                  <div key={cardData.id}>
                    <MedicarePrescriptionCard
                      medicarePartDPlan={cardData}
                      onEnroll={handleOnEnroll}
                    />
                    {index % 2 !== 0 && (
                      <CallUsNow
                        heading='Not sure which Medicare plan is right for you?'
                        message='Our licensed insurance agents are here to help'
                        phoneNumber={getContact(customerStore.getBrandName())}
                      />
                    )}
                  </div>
                ))}
                <PaginationWrapper>
                  {/* <Pagination
                    count={paginationCount}
                    color='primary'
                    size='small'
                    onChange={(event, page: number) => {
                      fireEvent('changePage', event, {
                        label: page,
                        description: `Medicare Prescription page number changed to ${page}`,
                        value: page,
                      })
                      handlePaginationChange(page)
                    }}
                    page={
                      mPartDPlansFilterStore.getMPartDPlanRequestFilters()
                        .paginationDetail?.page
                    }
                  /> */}
                  {mPartDPlansFilterStore.getTotalCount() !==
                    medicarePartDPlans.length &&
                    !mPartDPlansFilterStore.paginationLoader && (
                      <Button
                        color='primary'
                        variant='contained'
                        onClick={handlePaginationChange}
                      >
                        Load More Plans
                      </Button>
                    )}
                  {mPartDPlansFilterStore.paginationLoader && (
                    <ThreeDotsWrapper>
                      <ThreeDots color='#222C69' height={80} width={80} />
                    </ThreeDotsWrapper>
                  )}
                </PaginationWrapper>
              </>
            ) : (
              <>
                {mPartDPlansFilterStore.isPlanFetched() &&
                !mPartDPlansFilterStore.paginationLoader ? (
                  <NoPlanFoundCard />
                ) : (
                  <ThreeDotsWrapper>
                    <ThreeDots color='#222C69' height={80} width={80} />
                  </ThreeDotsWrapper>
                )}
              </>
            )}
          </>
        )}
      </CardsWrapper>
      {/* ) : (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            fontWeight: 'bold',
            fontSize: '1.5rem',
          }}
        >
          <p>No Medicare Part D Plans!!</p>
        </div>
      )} */}
      {userActionStore.comparePlanCards.planType ===
        MedicareQuotesType.MEDICARE_PRESCRIPTION &&
        userActionStore.comparePlanCards.compareCardsData.length !== 0 && (
          <BottomAddToCompare />
        )}
    </Container>
  )
}

export default observer(MedicarePrescription)
