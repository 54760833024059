export interface CarrierNonStdToStdPlans {
  [carrierKey: string]: {
    [nonStdPlanKey: string]: string[]
  }
}
const NonStdToStdPlans: CarrierNonStdToStdPlans = {
  UnitedHealthcare: {
    WI_BASE: ['F', 'G', 'N'],
  },
  Others: {
    WI_BASE: ['A', 'F', 'G', 'N'],
    WI_HDED: [] as string[],
    MN_BASIC: ['A', 'F', 'G'],
    MN_PBCO: ['N'],
    MN_HDED: ['HDF', 'HDG'],
    MN_HDED2: ['HDF', 'HDG'],
  },
}

export default NonStdToStdPlans
