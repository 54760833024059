import InfoStepsStepper from '../../components/InfoStepsPage/Stepper.InfoSteps'
import LayoutComponent from '../../components/common/Layout/Layout.component'
import { Outlet } from 'react-router-dom'
import SecureComponent from '../common/SecureComponent'
import styled from '@emotion/styled'
import theme from '../../global/theme'

const Container = styled.div`
  min-height: 100vh;
  background-color: ${theme.colors.white};
  display: flex;
  justify-content: center;
  width: 100%;
`
const StepperWrapper = styled.div`
  padding-top: 10px;
  width: inherit;

  .MuiStepper-root {
    align-items: baseline;
    padding-top: 50px;
    padding-left: 30px;
    height: 60vh;

    .MuiStepConnector-root {
      padding-left: 10px;
      padding-top: 20px;
    }

    .MuiStepConnector-line {
      height: 40px;
    }
  }
`

const Sidebar = styled.div`
  width: 30%;
  background-color: #fff;
  color: red;
  box-shadow: 5px 0 5px rgba(0, 0, 0, 0.1);

  @media screen and (max-width: 900px) {
    width: 50%;
  }

  @media screen and (max-width: 600px) {
    display: none;
  }
`

const Card = styled.div`
  border: 1px solid ${theme.colors.borderColor};
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  overflow: visible;
  width: 100%;
  margin: 40px 64px;
  min-height: 10vh;
  height: max-content;

  @media screen and (max-width: 600px) {
    margin: 24px 24px;
  }
`

const InfoStepsPage = () => {
  return (
    <LayoutComponent>
      <Container>
        <Sidebar>
          <StepperWrapper>
            <InfoStepsStepper />
          </StepperWrapper>
        </Sidebar>
        <Card>
          <Outlet />
        </Card>
      </Container>
    </LayoutComponent>
  )
}

export default SecureComponent(InfoStepsPage)
