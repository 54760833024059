import styled from '@emotion/styled'
import React from 'react'
import BottomStepNavigator from '../../BottomStepNavigator.component'
import Question from '../Common/Question'
import AddDoctor from './AddDoctor.component'
import { observer } from 'mobx-react'
import SecureComponent from '../../../../pages/common/SecureComponent'
import RouteConstants from '../../../../constants/RouteConstants'
import { useNavigate } from 'react-router-dom'

interface DoctorScreenProps {
  showBottomStepNavigator?: boolean
  redirect?: boolean
  isSpouse?: boolean
}

const Container = styled.div`
  padding: 30px 20px;
  display: flex;
  justify-content: center;
  flex-direction:column;
  align-items:center;
`

const DoctorsScreen: React.FC<DoctorScreenProps> = ({
  showBottomStepNavigator = true,
  redirect = true,
  isSpouse
}) => {
  const [questionResponse, setQuestionResponse] = React.useState('')

  const navigate = useNavigate()

  const handleNext = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    navigate(RouteConstants.INFO_STEPS_CURRENT_PLAN)
  }

  return (
    <Container>
      {questionResponse === 'Yes' ? (
        <AddDoctor isSpouse={isSpouse} handleNext={handleNext} isInfoSteps={true} />
      ) : (
        <Question
          handleYes={() => setQuestionResponse('Yes')}
          handleNo={() => {
            window.scrollTo({ top: 0, behavior: 'smooth' })
            navigate(RouteConstants.INFO_STEPS_CURRENT_PLAN)
          }}
          width='500px'
          heading='Do you have a Primary Care Physician who you’d like to be covered in your plan?'
          description='This can help us in suggesting the right plan for you'
        />
      )}
      {showBottomStepNavigator && (
        <BottomStepNavigator
          handleNext={handleNext}
          handleBack={() => {
            window.scrollTo({ top: 0, behavior: 'smooth' })
            navigate(RouteConstants.INFO_STEPS_PHARMACY)
          }}
          handleViewPlans={() => {
            window.scrollTo({ top: 0, behavior: 'smooth' })
            navigate(RouteConstants.DASHBOARD)
          }}
          disableNext={questionResponse === 'Yes' ? false : true}
          currentInfoStep='Physicians'
        />
      )}
    </Container>
  )
}

export default SecureComponent(observer(DoctorsScreen))
