import {
  AiOutlineCheck as Covered,
  AiOutlineClose as NotCovered,
} from 'react-icons/ai'
import { Table, TableBody } from '@mui/material'

import { DrugCost } from '../../interface/quotes/mNonSupplementPlans/DrugCost'
import Icon from '../common/Icon/Icon.component'
import MuiTableCell from '@mui/material/TableCell'
import MuiTableRow from '@mui/material/TableRow'
import StringConstants from '../../constants/StringConstants'
import Tooltip from '../common/Tooltip/Tooltip.component'
import { observer } from 'mobx-react'
import styled from '@emotion/styled'
import theme from '../../global/theme'
import { useNavigate } from 'react-router-dom'

interface PharmacyCoverageProps {
  pharmacyCoverages: DrugCost[]
}

const PharmacyCoverageWrapper = styled.div`
  div {
    display: 'block';
    width: 100%;
  }
  h2 {
    font-size: 12px;
    color: ${theme.colors.textGrey};
    padding-bottom: 8px;
  }
`

const PharmacyCoverageTableCell = styled(MuiTableCell)<{
  width: string
}>`
  font-weight: 700;
  color: ${theme.colors.textGrey};
  width: ${({ width }) => width};
  vertical-align: middle;
  padding: 5px;
  border: none;
  align-self: center;
  & p {
    font-size: 12px !important;
    color: ${theme.colors.textGrey}!important;
  }
  span {
    display: inline-flex;
    p {
      font-size: 12px !important;
      font-weight: 500;
      color: ${theme.colors.textGrey}!important;
    }
  }
`
const PharmacyCoverageTableRow = styled(MuiTableRow)`
  background: white;
`
const NavigationWrapper = styled.span`
  padding-left: 10px;
  text-decoration: underline;
  font-weight: bold;
  color: ${theme.colors.primary};
  font-size: 14px;
`
const Label = styled.p`
  color: ${theme.colors.primary};
  font-size: 16px;
  font-weight: 700;
  padding-right: 20px;
  margin: 5px 0px;
`

const AddPharmacyWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`

const PharmacyCoverage: React.FC<PharmacyCoverageProps> = ({
  pharmacyCoverages,
}) => {
  const navigate = useNavigate()
  return (
    <>
      <AddPharmacyWrapper>
        <Table>
          <TableBody>
            <PharmacyCoverageTableRow>
              <PharmacyCoverageTableCell width={'40%'} colSpan={1}>
                <h2>Pharmacy Coverage</h2>
              </PharmacyCoverageTableCell>
              <PharmacyCoverageTableCell width={'60%'} colSpan={1}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    minWidth: '15rem',
                  }}
                >
                  <NavigationWrapper
                    style={{
                      cursor: 'pointer',
                      padding: '0px',
                      marginTop: '3px',
                    }}
                    onClick={() => {
                      navigate({
                        pathname: '/profile/myprofile/pharmacy',
                      })
                    }}
                  >
                    + Add Preferred Pharmacy
                  </NavigationWrapper>
                  <Label>
                    <Tooltip title={StringConstants.TOOLTIP_ADD_Pharmacy} />
                  </Label>
                </div>
              </PharmacyCoverageTableCell>
            </PharmacyCoverageTableRow>
          </TableBody>
        </Table>
      </AddPharmacyWrapper>

      {pharmacyCoverages?.length > 0 && (
        <PharmacyCoverageWrapper>
          <Table>
            <TableBody>
              <PharmacyCoverageTableRow>
                <PharmacyCoverageTableCell width={'60%'} colSpan={1}>
                  {pharmacyCoverages.map((pharmacyCoverage, index) => (
                    <span key={index}>
                      <p>
                        {pharmacyCoverage.pharmacyNetworkStatus === '0' ||
                        pharmacyCoverage.pharmacyNetworkStatus === '-1' ? (
                          <Icon
                            childComponent={<NotCovered />}
                            color={theme.colors.danger}
                            fontSize='12px'
                          />
                        ) : (
                          <Icon
                            childComponent={<Covered />}
                            color={theme.colors.success}
                            fontSize='12px'
                          />
                        )}

                        {pharmacyCoverage.pharmacyName}
                      </p>
                    </span>
                  ))}
                </PharmacyCoverageTableCell>
              </PharmacyCoverageTableRow>
            </TableBody>
          </Table>
        </PharmacyCoverageWrapper>
      )}
    </>
  )
}

export default observer(PharmacyCoverage)
