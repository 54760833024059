const RoleConstants = {
  AGENT: 'AGENT',
  CUSTOMER: 'CUSTOMER',
  SUPER_ADMIN: 'SUPER_ADMIN',
  DISPLAY_NAMES: {
    AGENT: 'Agent',
    CUSTOMER: 'Customer',
    SUPER_ADMIN: 'Super Admin',
  },
}

export default RoleConstants
