import Button from '../../../common/Button/Button.component'
import { TbAccessibleOff } from 'react-icons/tb'
import styled from '@emotion/styled'
import theme from '../../../../global/theme'
import { useNavigate } from 'react-router-dom'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 900px;
  margin: auto;
  padding: 2rem 4rem;

  @media (max-width: 600) {
    min-width: 200px;
    padding: 2rem 1rem;
  }

  @media screen and (max-width: 1250px) {
    width: 700px;
  }

  @media screen and (max-width: 1050px) {
    width: 600px;
  }

  @media screen and (max-width: 640px) {
    width: 100%;
    margin: 20px 0px;
  }
`

const HeadingContent = styled.h3`
  color: ${theme.colors.textGrey};
  font-weight: 400;
  font-size: 2rem;
  margin-top: 2.5rem;
`
const Content = styled.h4`
  color: ${theme.colors.textGrey};
  font-weight: 200;
  font-size: 1.5rem;
  margin-top: 1.5rem;
  text-align: center;
`

const AccessDeniedPage = () => {
  window.onbeforeunload = () => {}
  const navigate = useNavigate()
  return (
    <Container>
      <TbAccessibleOff
        color={theme.colors.textGrey}
        size='10rem'
        style={{ marginTop: '3rem' }}
      />
      <HeadingContent>Access Denied</HeadingContent>
      <Content>
        The page or resource you were trying to reach is absolutely forbidden
        for some reason
      </Content>
      <div style={{ marginTop: '40px' }}>
        <Button
          variant='contained'
          color='info'
          width='200px'
          onClick={() => navigate(-1)}
        >
          Go Back
        </Button>
      </div>
    </Container>
  )
}

export default AccessDeniedPage
