import { APIResponse } from '../interface/APIResponseInterface'
import ApiManager from '../api/ApiManager'
import { HttpMethods } from '../enums/HttpMethodsEnum'
import { QuotesPullStatus } from '../interface/quotes/QuotesPullStatus'
import RouteConstants from '../constants/RouteConstants'
import UrlConstants from '../constants/UrlConstants'
import customerStore from '../datastore/CustomerStore'
import snackbarStore from '../datastore/SnackbarStore'

export const fetchQuotesPullStatus = (planYear?: number) => {
  console.log('fetching Quotes Pull status')
  if (
    RouteConstants.DASHBOARD !==
    '/' + window.location.pathname.split('/')[1]
  ) {
    ApiManager.makeApiCallWithAuthentication(
      UrlConstants.GET_MEDICARE_PLANS_COUNT.USECASE,
      HttpMethods.GET_METHOD,
      planYear ? { 'plan-year': planYear } : {}
    )
      .then((response: APIResponse<QuotesPullStatus>) => {
        if (response.status === 200) {
          customerStore.setQuotePulledStatusMA(
            response.data.data.isMAQuotesProcessing
          )
          customerStore.setQuotePulledStatusMPartD(
            response.data.data.isMPartDQuotesProcessing
          )
          customerStore.setQuotePulledStatusMS(
            response.data.data.isMSQuotesProcessing
          )
          if (
            response.data.data.isMAQuotesProcessing ||
            response.data.data.isMPartDQuotesProcessing ||
            response.data.data.isMSQuotesProcessing
          ) {
            setTimeout(() => {
              fetchQuotesPullStatus(planYear)
            }, 15000)
          }
        } else if (response.status === 401) {
          console.log(
            'User logged out, hence unable to fetch Quotes Pull Status.'
          )
        } else {
          snackbarStore.set({
            snackbarOpen: true,
            snackbarMessage: 'Unable to fetch Quotes Pull Status',
            snackbarType: 'error',
          })
        }
      })
      .catch((err) => {
        snackbarStore.set({
          snackbarOpen: true,
          snackbarMessage: 'Unable to fetch Quotes Pull Status',
          snackbarType: 'error',
        })
      })
  }
}
