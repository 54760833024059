import styled from '@emotion/styled'
import { SyncLoader } from 'react-spinners'

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2rem 0;
`

const BouncingDotsLoader = () => {
  return (
    <Container>
      <SyncLoader size='12px' color='#C4C4C4' />
    </Container>
  )
}

export default BouncingDotsLoader
