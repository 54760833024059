import AddedDoctor from './AddedDoctor.component'
import { GiHeartPlus } from 'react-icons/gi'
import Icon from '../../../common/Icon/Icon.component'
import PostalCodeSelection from '../../../common/PostalCodeSelection/PostalCodeSelection'
import React from 'react'
import SearchPrompt from './SearchPrompt.component'
import styled from '@emotion/styled'
import theme from '../../../../global/theme'
import { withRouter } from '../../../common/WithRouter/WithRouter.component'
import { getCityDetails, getProviders, lookupProviders, updateProviderAddress } from '../../../../service/providers'
import customerStore from '../../../../datastore/CustomerStore'
import NearCities from './NearCities.component'
import { isEmpty, isUndefined } from 'lodash'
import { County } from '../../../../interface/CountyInterface'
import userActionStore from '../../../../datastore/UserActionStore'
import BouncingDotsLoader from '../../../common/Loading/BouncingDotsLoader'
import ModalComponent from '../../../common/Modal/Modal.component'
import ProviderCardComponent from '../../ProviderCard.component'
import snackbarStore from '../../../../datastore/SnackbarStore'
import StringConstants from '../../../../constants/StringConstants'
import { SnackbarTypes } from '../../../../enums/SnackbarTypesEnum'
import { getApiErrorMessage } from '../../../../utils/StringUtils'
import { fontSize } from '@mui/system'
import mPartDPlansFilterStore from '../../../../datastore/medicareQuotes/MPartPlanFilterQuotesStore'
import medicareAdvantageFilterQuoteStore from '../../../../datastore/medicareQuotes/MedicareAdvantageFilterQuoteStore'
import Tooltip from '../../../common/Tooltip/Tooltip.component'
import QuotesRefreshProvider from '../../../../context/QuotesRefreshContext'

interface AddDoctorProps {
  handleNext?: () => void
  isSpouse?: boolean
  isInfoSteps?: boolean
}

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 20px;

  @media screen and (min-width: 700px) {
    margin-bottom: 250px;
  }

  @media screen and (max-width: 700px) {
    grid-template-columns: 1fr;
  }
`
const AddedDoctorsWrapper = styled.div`
  border-left: 2px solid ${theme.colors.borderColor};
  padding: 10px 20px 10px 20px;

  @media screen and (max-width: 700px) {
    border-left: none;
    padding: 20px 0px;
  }
`

const SearchPromptWrapper = styled.div`
  padding: 10px 20px 10px 0px;
  h3 {
    color: ${theme.colors.primary};
    font-size: 20px;
    font-weight: 700;
    padding-bottom: 10px;
  }
  p {
    font-size: 14px;
    padding-top: 20px;
  }
  .bold {
    padding-top: 5px;
    padding-bottom: 2px;
    font-weight: 600;

    span {
      padding-left: 10px;
      font-size: 12px;
      text-decoration: underline;
      color: ${theme.colors.primary};
      font-style: italic;
    }
  }

  @media screen and (max-width: 700px) {
    padding: 20px 0px;
  }
`
const AddedDoctorsHeader = styled.div`
  font-weight: 600;
  display: flex;
  align-items: center;
  font-size: 14px;
  padding-left: 30px;
  span {
    padding-left: 5px;
  }

  @media screen and (max-width: 700px) {
    padding-left: 0px;
  }
`
const Address = styled.div`
  padding: 20px 0;
  h3 {
    font-size: 16px;
    font-weight: bold;
    color: ${theme.colors.primary};
  }
  p {
    color: ${theme.colors.textDark};
    font-weight: bold;
    font-size: 14px;
  }
`
const DoctorListWrapper = styled.div`
  margin-top:20px;
  padding: 20px 0px;
  max-height: 420px;
  overflow-y: scroll;
`

const AddDoctor: React.FC<AddDoctorProps> = ({ handleNext, isSpouse, isInfoSteps }) => {
  const [addDoctorState, setAddDoctorState] = React.useState<{
    AddedDoctor: any[]
    Doctor: any[]
    addingDoctors: boolean
  }>({
    AddedDoctor: [],
    Doctor: [],
    addingDoctors: false,
  })

  const [zipcodeInfo, setZipcodeInfo] = React.useState<{
    code: string
    county?: County
    state?: string
    optCounties?: County[]
  }>({
    code: customerStore.pharmacyPostalCode || customerStore.postalCode,
    county: userActionStore.zipCodeCache.find(
      (iterator) => iterator.code === customerStore.pharmacyPostalCode
    )?.county || {
      countyName: customerStore.county,
      countyFips: '',
    },
    state:
      userActionStore.zipCodeCache.find(
        (iterator) => iterator.code === customerStore.pharmacyPostalCode
      )?.state || customerStore.state,
    optCounties: [],
  })

  const [cityDetails, setCityDetails] = React.useState<any>()
  const [loadingFetch, setLoadingFetch] = React.useState<boolean>()
  const [providersLookUpData, setProvidersLookUpData] = React.useState<any>()
  const [selectedCity, setSelectedCity] = React.useState<any>({})
  const [forSpouse, setForSpouse] = React.useState(
    isUndefined(isSpouse) ? customerStore.forSpouse : isSpouse
  )
  const [showProvidersSearchBar, setShowProvidersSearchBar] = React.useState<boolean>(false)
  const [isEditProvider, setIsEditProvider] = React.useState<boolean>(false)
  const [popUpModal, setpopUpModal] = React.useState<boolean>(false)
  const [editProviderLoading, setEditProviderLoading] = React.useState<boolean>(false)
  const [searchDoctor, setSearchDoctor] = React.useState(false)
  const [addProviderLoading, setAddProviderLoading] = React.useState<boolean>(false)
  const [deleteProviderLoading, setDeleteProviderLoading] =
    React.useState<boolean>(false)
  const [loadingCityDetails, setLoadingCityDetails] = React.useState<boolean>(false)

  React.useEffect(() => {
    fetchCityDetails()
    fetchAllProviders()
  }, [])

  const fetchLookUpProviders = (doctorPrefix: string) => {
    setLoadingFetch(true)
    lookupProviders({
      $name: doctorPrefix,
      type: 'cloudsearch',
      city: selectedCity.city,
      state: selectedCity.state,
      lat: selectedCity.latitude,
      lng: selectedCity.longitude
    }).then((response: any) => {
      const data = response.data.data.map(
        (x: { id: string; name: string }) => {
          return { title: x.name, id: x.id }
        }
      )
      setProvidersLookUpData(data)
    })
      .finally(() => {
        setLoadingFetch(false)
      })
  }

  const fetchAllProviders = () => {
    getProviders({ $forSpouse: forSpouse }).then((response: any) => {
      setAddDoctorState({
        ...addDoctorState,
        AddedDoctor: response.data.data,
      })
    })
  }

  const fetchCityDetails = () => {
    setLoadingCityDetails(true)
    getCityDetails({ $zipcode: customerStore.postalCode, $radius: 100, $size: 6 }).then((response: any) => {
      setCityDetails(response.data.data)
      setLoadingCityDetails(false)
    }).catch((err) => {
      setLoadingCityDetails(false)
      snackbarStore.set({
        snackbarMessage: 'Could not fetch neighbouring cities',
        snackbarOpen: true,
        snackbarType: SnackbarTypes.ERROR,
      })
    })
  }

  const handleCancel = () => {
    setIsEditProvider(false)
    setAddDoctorState({
      ...addDoctorState,
      Doctor: [],
      addingDoctors: false,
    })
  }

  const handleEditProviders = (data: any) => {
    setEditProviderLoading(true)
    updateProviderAddress(addDoctorState.Doctor[0].id, data.npi, data.id, forSpouse).then((response: any) => {
      mPartDPlansFilterStore.clearStore()
      medicareAdvantageFilterQuoteStore.clearStore()
      snackbarStore.set({
        snackbarMessage: StringConstants.SNACKBAR_SAVE_SUCCESS_MSG,
        snackbarOpen: true,
        snackbarType: SnackbarTypes.SUCCESS,
      })
      let index = (addDoctorState.AddedDoctor.findIndex((x: any) =>
        x.npi === data.npi
      ))
      let addedDoctorsAfterUpdate = addDoctorState.AddedDoctor

      addedDoctorsAfterUpdate[index] = { ...addedDoctorsAfterUpdate[index], id: addDoctorState.Doctor[0].id, npi: data.npi, externalId: data.id, address1: data.address1, address2: data.address2 }
      setAddDoctorState({
        addingDoctors: false,
        Doctor: [],
        AddedDoctor: addedDoctorsAfterUpdate
      })
      setIsEditProvider(false)
      setEditProviderLoading(false)
    }).catch((err: any) => {
      snackbarStore.set({
        snackbarMessage: getApiErrorMessage('update provider'),
        snackbarOpen: true,
        snackbarType: SnackbarTypes.ERROR,
      })
      setIsEditProvider(true)
      setEditProviderLoading(false)
    })

  }

  return (
    <QuotesRefreshProvider>
      <Container>
        <SearchPromptWrapper>
          <h3>Add Your Physician</h3>
          <p>
            Adding your physician can help us in determining if they are covered in
            a Medicare plan, and in suggesting plans that offer maximum physician
            coverage.
          </p>
          {!showProvidersSearchBar &&
            <Address>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <h3>Where is your physician located?</h3>
                <Tooltip
                  title={StringConstants.TOOLTIP_PHYSICIAN_LOCATION_MSG}
                  content={'i'}
                />
              </div>
              {/* <p>Search for a zip code:</p>
            <PostalCodeSelection
              onSubmit={(zipInfo: {
                code: string
                county?: County
                state?: string
                optCounties?: County[]
              }) => {
                if (zipInfo.code) {
                  setZipcodeInfo({ ...zipcodeInfo, ...zipInfo })
                  setShowProvidersSearchBar(!showProvidersSearchBar)
                }
              }}
            /> */}
              {!loadingCityDetails ? <>
                {cityDetails ?
                  <NearCities cities={cityDetails} setSelectedCity={setSelectedCity} setShowProvidersSearchBar={setShowProvidersSearchBar}
                  /> : (<p style={{ fontWeight: '400' }}>There are no neighbouring cities available
                  </p>)}
              </> : <BouncingDotsLoader />
              }
            </Address>

          }
          {showProvidersSearchBar && (
            <>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <p>Searching for physicians near
                  {
                    (!isEmpty(selectedCity)) ?
                      (<h3>{selectedCity.city}, {selectedCity.state}</h3>) : (<><span> zipcode <h3>{zipcodeInfo.code}</h3></span></>)
                  }
                </p>
                <p
                  style={{ textDecoration: 'underline', marginLeft: '20px', fontSize: '12px', fontWeight: '600', color: `${theme.colors.primary}`, cursor: 'pointer' }}
                  onClick={() => {
                    setShowProvidersSearchBar(!showProvidersSearchBar)
                    setSelectedCity({})
                    setZipcodeInfo({ code: '' })
                    setSearchDoctor(true)
                  }}
                >
                  Change
                </p>

              </div>
              {!addProviderLoading ? <>
                <p>What is your physician's name?</p>
                <SearchPrompt
                  addDoctorState={addDoctorState}
                  setAddDoctorState={setAddDoctorState}
                  handleNext={handleNext}
                  selectedCity={selectedCity}
                  fetchLookUpProviders={fetchLookUpProviders}
                  loadingFetch={loadingFetch}
                  providersLookUpData={providersLookUpData}
                  forSpouse={forSpouse}
                  setSearchDoctor={setSearchDoctor}
                  searchDoctor={searchDoctor}
                  setAddProviderLoading={setAddProviderLoading}
                  isInfoSteps={isInfoSteps}
                />
              </> : (<BouncingDotsLoader />
              )}
            </>
          )}
        </SearchPromptWrapper>
        <AddedDoctorsWrapper>
          <AddedDoctorsHeader>
            <Icon childComponent={<GiHeartPlus />} fontSize='18px' />
            <span>Added Physicians: {addDoctorState.AddedDoctor.length}</span>
          </AddedDoctorsHeader>
          {addProviderLoading || deleteProviderLoading ? (
            <BouncingDotsLoader />
          ) : (
            <DoctorListWrapper>
              {addDoctorState.AddedDoctor.map((item, key) => (
                <AddedDoctor
                  key={key}
                  addDoctorState={addDoctorState}
                  setAddDoctorState={setAddDoctorState}
                  data={item}
                  setDeleteProviderLoading={setDeleteProviderLoading}
                  setIsEditProvider={setIsEditProvider}
                  forSpouse={forSpouse}
                />
              ))}
            </DoctorListWrapper>)}
        </AddedDoctorsWrapper>

        {
          isEditProvider &&
          <ModalComponent
            setOpen={() => setpopUpModal(false)}
            description='Popup - User Information Prompt'
            hideCloseButton={true}
          >
            <ProviderCardComponent data={addDoctorState.Doctor[0]} handleCancel={handleCancel} handleEditProviders={handleEditProviders} editProviderLoading={editProviderLoading} />

          </ModalComponent>
        }
      </Container >
    </QuotesRefreshProvider>
  )
}

export default withRouter(AddDoctor)
