import { HiOutlinePhone, HiPhone } from 'react-icons/hi'
import React, { useEffect, useRef } from 'react'
import {
  RiArrowDropDownLine,
  RiArrowDropUpLine,
  RiLogoutBoxRLine,
} from 'react-icons/ri'
import { useLocation, useNavigate } from 'react-router-dom'

import { AiFillHeart } from 'react-icons/ai'
import ApiManager from '../../../api/ApiManager'
import Banner from './Banner.component'
import { BrandSpecificThemeEnum } from '../../../enums/BrandSpecificThemeEnum'
import { CgProfile } from 'react-icons/cg'
import { HttpMethods } from '../../../enums/HttpMethodsEnum'
import LazyLoadImageFromUrl from '../LazyLoadImageFromUrl'
import RouteConstants from '../../../constants/RouteConstants'
import UrlConstants from '../../../constants/UrlConstants'
import { convertNameToTitleCase } from '../../../utils/StringUtils'
import currentPlanStore from '../../../datastore/CurrentPlanStore'
import customerStore from '../../../datastore/CustomerStore'
import { deleteToken } from '../../../utils/AuthUtils'
import { fireEvent } from '../../../cra'
import { getContact } from '../../../utils/getContact'
import mPartDPlansFilterStore from '../../../datastore/medicareQuotes/MPartPlanFilterQuotesStore'
import medicareAdvantageFilterQuoteStore from '../../../datastore/medicareQuotes/MedicareAdvantageFilterQuoteStore'
import msPlanFiltersStore from '../../../datastore/medicareQuotes/MSupplementPlanFiltersStore'
import { observer } from 'mobx-react'
import prescriptionStore from '../../../datastore/PrescriptionStore'
import snackbarStore from '../../../datastore/SnackbarStore'
import styled from '@emotion/styled'
import theme from '../../../global/theme'
import userActionStore from '../../../datastore/UserActionStore'

interface NavbarProps {
  type?: string
  isBanner?: boolean
}

interface LogoContainerProps {
  isBanner: boolean
  brandName?: string
}

interface UserContainerProps {
  isBanner: boolean
}

interface ContactContainerProps {
  isBanner: boolean
  themeColor?: string
}

const MainWrapper = styled.div`
  position: sticky;
  top: 0;
  z-index: 1000;
  width: 100%;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  background-color: ${theme.colors.white};
`

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-gap: 20px;
  padding: 15px 50px;
  @media screen and (max-width: 750px) {
    padding: 15px 20px;
  }
  @media screen and (max-width: 400px) {
    padding: 10px 10px;
  }
`

const LogoContainer = styled.div<LogoContainerProps>`
  max-width: ${({ brandName }) =>
    brandName === 'medicareassociation' ? '300px' : '200px'};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  img {
    width: 100%;
    height: 100%;
  }

  @media screen and (max-width: 600px) {
    max-width: 200px;
    width: 100%;
    justify-content: initial;

    img {
      width: ${({ brandName }) =>
    brandName?.startsWith('aetna') || brandName?.startsWith('devoted')
      ? '50%'
      : brandName?.startsWith('mutual')
        ? '40%'
        : '100%'};
      height: 100%;
    }
  }
`

const ContactContainer = styled.div<ContactContainerProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  p {
    font-size: 14px;
    font-weight: 600;
    color: ${theme.colors.primary};
    display: flex;
    align-items: center;
  }
  span {
    color: ${theme.colors.secondary};
    font-size: 20px;
    font-weight: 700;
  }
  .LowerText {
    display: flex;
    align-items: flex-end;
    margin-top: 0.25rem;
    text-decoration: none;
    color: inherit;
  }

  @media screen and (max-width: 700px) {
    border: 2px solid ${({ themeColor }) => themeColor};
    border-radius: 50% 50%;
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: ${({ themeColor }) => themeColor};
    &:hover {
      background: ${({ themeColor }) => themeColor};
      color: ${theme.colors.white};
    }
    .UpperText {
      display: none;
    }
    .LowerText {
      margin-top: 0;
      span {
        display: none;
      }
    }
  }
`

const ContactIcon = styled(HiOutlinePhone)`
  font-size: 28px;
  margin-right: 10px;

  @media screen and (max-width: 700px) {
    display: none;
  }
`
const ContactIconMobile = styled(HiPhone)`
  font-size: 28px;
  margin-right: 10px;
  display: none;

  @media screen and (max-width: 700px) {
    font-size: 16px;
    margin-right: 0;
    display: initial;
  }
`

const UserContainer = styled.div<UserContainerProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  p {
    padding: 4px 16px;
    color: ${theme.colors.primary};
    font-size: 18px;
    font-weight: 600;
    cursor: pointer;
  }

  @media screen and (max-width: 750px) {
    p {
      font-size: 16px;
      padding: 4px 4px;
    }
  }
  @media screen and (max-width: 550px) {
    .userName {
      display: none;
    }
  }
`

const UserIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  width: 32px;
  border-radius: 50%;
  background-color: ${theme.colors.primary};
  color: ${theme.colors.textLight};
  font-size: 20px;
  font-weight: 600;
  font-family: Helvetica, sans-serif;
  align-items: center;
`
const Dropdown = styled.div`
  position: absolute;
  top: 48px;
  left: 48px;
  border-radius: 4px;
  box-shadow: 0px 4px 10px 4px rgba(0, 0, 0, 0.1);
  width: 230px;
  border: 1px solid ${theme.colors.borderColor};
  background-color: ${theme.colors.white};
  padding: 10px;
  @media screen and (max-width: 700px) {
    width: 180px;
    right: 48px;
    left: initial;
  }
`

const RightContainer = styled.div`
  position: relative;
  display: flex;
`

const BorderDiv = styled.div`
  height: 100%;
  width: 1px;
  background-color: ${theme.colors.primary};
  margin: 0px 10px;
  @media screen and (max-width: 550px) {
    margin: 0px 4px;
  }
`
const Navlink = styled.p`
  margin: 8px 0px;
  padding: 10px 12px !important;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 600;
  color: ${theme.colors.primary};
  display: flex;
  align-items: center;
  transition: all 0.2s;

  &:hover {
    background-color: ${theme.colors.lightBack};
  }
`

const BottomBorder = styled.div`
  width: 100%;
  height: 0.8px;
  background-color: ${theme.colors.borderColor};
  margin: 8px 0px;
`

const Navbar: React.FC<NavbarProps> = ({ type }) => {
  const navigate = useNavigate()
  const location = useLocation()

  const [dropDownState, setDropDownState] = React.useState(false)
  const [selectedState, setSelectedState] = React.useState(0)
  const navbarDropDownRef = useRef(null)

  const [dimensions, setDimensions] = React.useState({
    height: window.innerHeight,
    width: window.innerWidth,
  })
  var isBanner = false
  if (
    customerStore.getBrandName() === 'aetna-medicare' || customerStore.getBrandName() === 'devoted' ||
    customerStore.getBrandName() === 'mutual of omaha-medicare' ||
    customerStore.getBrandName() === 'medicareassociation'
  ) {
    isBanner = true
  }

  const NavbarOptions = [
    {
      id: 1,
      title: 'Profile',
      Icon: <CgProfile style={{ marginRight: '12px' }} />,
      function: (e: any) => {
        fireEvent('click', e, {
          description: 'Dashboard - Navbar Profile Link',
        })
        navigate(RouteConstants.PROFILE + '/myprofile/profile', {
          state: { from: location },
        })
        setSelectedState(1)
        setDropDownState(false)
      },
    },
    {
      id: 2,
      title: 'Saved Plans',
      Icon: <AiFillHeart style={{ marginRight: '12px' }} />,
      function: (e: any) => {
        fireEvent('click', e, {
          description: 'Dashboard - Navbar Saved Plans Link',
        })
        navigate(RouteConstants.SAVED_PLANS, { state: type })
        setSelectedState(2)
        setDropDownState(false)
      },
    },
    {
      id: 3,
      title: 'Logout',
      Icon: <RiLogoutBoxRLine style={{ marginRight: '12px' }} />,
      function: (e: any) => {
        fireEvent('click', e, {
          description: 'Dashboard - Navbar Logout Link',
        })
        ApiManager.makeApiCallWithAuthentication(
          UrlConstants.LOGOUT.USECASE,
          HttpMethods.POST_METHOD
        )
          .then((res) => {
            if (res.status === 200) {
              deleteToken()
              customerStore.reset()
              userActionStore.reset()
              snackbarStore.reset()
              prescriptionStore.reset()
              currentPlanStore.reset()
              medicareAdvantageFilterQuoteStore.clearStore()
              medicareAdvantageFilterQuoteStore.clearMAPlanRequestFilters()
              msPlanFiltersStore.clearMSPlanFilterStore()
              msPlanFiltersStore.clearMSPlanRequestFilters()
              mPartDPlansFilterStore.clearStore()
              mPartDPlansFilterStore.clearMPartDPlanRequestFilters()
              localStorage.clear()
              sessionStorage.clear()
              fireEvent('logoutSuccessful', e, {
                description: 'Dashboard - Navbar Logout Link',
              })
              navigate(RouteConstants.LOGIN, { replace: true })
            } else {
              throw new Error('Failed to contact the server')
            }
          })
          .catch((err) => {
            deleteToken()
            customerStore.reset()
            userActionStore.reset()
            snackbarStore.reset()
            prescriptionStore.reset()
            currentPlanStore.reset()
            medicareAdvantageFilterQuoteStore.clearStore()
            medicareAdvantageFilterQuoteStore.clearMAPlanRequestFilters()
            msPlanFiltersStore.clearMSPlanFilterStore()
            msPlanFiltersStore.clearMSPlanRequestFilters()
            mPartDPlansFilterStore.clearStore()
            mPartDPlansFilterStore.clearMPartDPlanRequestFilters()
            localStorage.clear()
            sessionStorage.clear()
            fireEvent('logoutFailed', e, {
              description: 'Dashboard - Navbar Logout Link',
            })
            navigate(RouteConstants.LOGIN, { replace: true })
          })
        setSelectedState(3)
        setDropDownState(false)
      },
    },
  ]

  const debounce = <A extends unknown[]>(
    callback: (...args: A) => unknown,
    msDelay: number
  ) => {
    let timer: NodeJS.Timeout | undefined

    return (...args: A) => {
      clearTimeout(timer)

      timer = setTimeout(() => {
        timer = undefined
        callback(...args)
      }, msDelay)
    }
  }

  useEffect(() => {
    const debouncedHandleResize = debounce(function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      })
    }, 100)

    window.addEventListener('resize', debouncedHandleResize)

    return () => {
      window.removeEventListener('resize', debouncedHandleResize)
    }
  })

  useEffect(() => {
    const closeDropDown = (e: any) => {
      if (
        e.composedPath() &&
        e.composedPath()[1] !== navbarDropDownRef.current
      ) {
        setDropDownState(false)
      }
    }
    document.body.addEventListener('click', closeDropDown)
    return () => document.body.removeEventListener('click', closeDropDown)
  }, [])

  useEffect(() => {
    window.Invoca && window.Invoca.PNAPI.run()
  })

  const customerFirstName = convertNameToTitleCase(
    customerStore.firstName.trim()
  )
  return (
    <MainWrapper>
      {isBanner && <Banner />}
      <Container>
        <LogoContainer
          isBanner={isBanner}
          onClick={() => {
            if (!customerStore.isProfileUpdating) {
              navigate(`/dashboard/${customerStore.preferenceCoverage}`)
            }
          }}
          brandName={customerStore.getBrandName()}
        >
          <LazyLoadImageFromUrl
            brandName={
              customerStore.get().brandName ? customerStore.get().brandName : ''
            }
            useAltLogo={dimensions.width <= 600}
            isNav={true}
            isLoading={userActionStore.getIsLoading()}
          />
        </LogoContainer>
        <RightContainer>
          {customerStore.id && (
            <>
              <UserContainer
                isBanner={isBanner}
                ref={navbarDropDownRef}
                onClick={() => setDropDownState(!dropDownState)}
              >
                <UserIcon>{customerStore.firstName[0].toUpperCase()}</UserIcon>
                <p className='userName'>
                  {customerFirstName.length > 11
                    ? customerFirstName.slice(0, 8) + '...'
                    : customerFirstName.trim()}
                </p>
                {dropDownState && (
                  <div>
                    <RiArrowDropUpLine
                      style={{ cursor: 'pointer' }}
                      onClick={() => setDropDownState(false)}
                    />{' '}
                    <Dropdown>
                      {NavbarOptions.map((item, index) => (
                        <div key={item.id}>
                          <Navlink
                            key={item.id}
                            onClick={item.function}
                            style={{
                              backgroundColor: `${selectedState === index + 1
                                ? theme.colors.contrastPrimary
                                : ''
                                }`,
                            }}
                          >
                            {item.Icon}
                            {item.title}
                          </Navlink>
                          {index + 1 < NavbarOptions.length && <BottomBorder />}
                        </div>
                      ))}
                    </Dropdown>
                  </div>
                )}
                {!dropDownState && (
                  <RiArrowDropDownLine
                    style={{ cursor: 'pointer' }}
                    onClick={() => setDropDownState(true)}
                  />
                )}
              </UserContainer>
              <BorderDiv />
            </>
          )}

          <ContactContainer
            isBanner={isBanner}
            themeColor={
              BrandSpecificThemeEnum[customerStore.getBrandName()] ||
              theme.colors.primary
            }
          >
            <p className='UpperText'>Speak With A Licensed Insurance Agent</p>
            <a
              href={`tel:${getContact(customerStore.getBrandName())}`}
              className='LowerText phone-number-fade'
              onClick={() => {
                fireEvent(
                  'initiateCall',
                  {},
                  {
                    description: `Header Call Button - Request Callback on ${getContact(
                      customerStore.getBrandName()
                    )}`,
                    value: getContact(customerStore.getBrandName()),
                  }
                )
              }}
            >
              <ContactIcon />
              <ContactIconMobile />
              <span>{getContact(customerStore.getBrandName())}</span>
            </a>
          </ContactContainer>
        </RightContainer>
      </Container>
    </MainWrapper>
  )
}

export default observer(Navbar)
