import { useLocation, useNavigate } from 'react-router-dom'

import React from 'react'
import RouteConstants from '../../../constants/RouteConstants'
import ValidationUtils from '../../../utils/validation/ValidationUtils'
import currentPlanStore from '../../../datastore/CurrentPlanStore'
import customerStore from '../../../datastore/CustomerStore'
import { deleteToken } from '../../../utils/AuthUtils'
import { getParamsFromUrl } from '../../../utils/UrlUtils'
import { isNull } from 'lodash'
import mPartDPlansFilterStore from '../../../datastore/medicareQuotes/MPartPlanFilterQuotesStore'
import medicareAdvantageFilterQuoteStore from '../../../datastore/medicareQuotes/MedicareAdvantageFilterQuoteStore'
import msPlanFiltersStore from '../../../datastore/medicareQuotes/MSupplementPlanFiltersStore'
import prescriptionStore from '../../../datastore/PrescriptionStore'
import snackbarStore from '../../../datastore/SnackbarStore'
import userActionStore from '../../../datastore/UserActionStore'

export function withRouter(Children: any) {
  return (props: any) => {
    const location = useLocation()
    const navigate = useNavigate()

    React.useEffect(() => {
      if (location.pathname === RouteConstants.LOGIN && location.search) {
        const autoLoginUsername = getParamsFromUrl(location.search, 'username')
        const autoLoginPwd = getParamsFromUrl(location.search, 'token')
        if (
          !isNull(autoLoginUsername) &&
          ValidationUtils.validateUsername(autoLoginUsername) &&
          !isNull(autoLoginPwd)
        ) {
          try {
            deleteToken()
            customerStore.reset()
            userActionStore.reset()
            snackbarStore.reset()
            prescriptionStore.reset()
            currentPlanStore.reset()
            medicareAdvantageFilterQuoteStore.clearStore()
            medicareAdvantageFilterQuoteStore.clearMAPlanRequestFilters()
            msPlanFiltersStore.clearMSPlanFilterStore()
            msPlanFiltersStore.clearMSPlanRequestFilters()
            mPartDPlansFilterStore.clearStore()
            mPartDPlansFilterStore.clearMPartDPlanRequestFilters()
            navigate(location, { replace: true })
          } catch (err) {
            console.log('Could not reset customer')
          }
        }
      }
    }, [])

    return <Children {...props} navigate={navigate} location={location} />
  }
}
