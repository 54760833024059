import React, { ReactNode, useEffect, useState } from 'react'

import customerStore from '../datastore/CustomerStore'
import { observer } from 'mobx-react'

interface QuotesRefreshContextValue {
  isQuotesRefreshing: boolean
  setIsQuotesRefreshing: (flag: boolean) => void
}

interface QuotesRefreshProviderProps {
  children: ReactNode
}

//context
const QuotesRefreshContext = React.createContext<QuotesRefreshContextValue>({
  isQuotesRefreshing: true,
  setIsQuotesRefreshing: () => {},
})

// Component to manage the context
function QuotesRefreshProvider({ children }: QuotesRefreshProviderProps) {
  const [isQuotesRefreshing, setIsQuotesRefreshing] = useState(
    customerStore.isMAQuotesProcessing || customerStore.isMPartDQuotesProcessing
  )

  useEffect(() => {
    if (
      !customerStore.isMAQuotesProcessing &&
      !customerStore.isMPartDQuotesProcessing
    ) {
      console.log(
        ' !customerStore.isMAQuotesProcessing && !customerStore.isMPartDQuotesProcessing',
        !customerStore.isMAQuotesProcessing &&
          !customerStore.isMPartDQuotesProcessing
      )
      setIsQuotesRefreshing(false)
    } else {
      setIsQuotesRefreshing(true)
    }
  }, [
    JSON.stringify(customerStore.isMAQuotesProcessing),
    JSON.stringify(customerStore.isMPartDQuotesProcessing),
  ])

  const updateIsQuotesRefreshing = (newTheme: boolean) => {
    setIsQuotesRefreshing(newTheme)
  }

  return (
    <QuotesRefreshContext.Provider
      value={{
        isQuotesRefreshing,
        setIsQuotesRefreshing: updateIsQuotesRefreshing,
      }}
    >
      {children}
    </QuotesRefreshContext.Provider>
  )
}

export default observer(QuotesRefreshProvider)

export { QuotesRefreshContext }
