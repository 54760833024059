import {
  capitalizeFirstCharacter,
  convertNameToTitleCase,
  getApiErrorMessage,
} from '../../../utils/StringUtils'
import { isEmpty, isNull, isUndefined } from 'lodash'

import { ActionContext } from '../../../cra'
import ApiManager from '../../../api/ApiManager'
import Button from '../../common/Button/Button.component'
import Card from '../../common/Card/Card.component'
import CarrierLogo from '../../common/CarrierLogo'
import DateField from '../../common/DateField'
import Filter from 'bad-words'
import { HttpMethods } from '../../../enums/HttpMethodsEnum'
import InputField from '../../common/InputField/InputField.component'
import RadioGroup from '../../common/RadioGroup/RadioGroup.component'
import React from 'react'
import RouteConstants from '../../../constants/RouteConstants'
import SecureComponent from '../../../pages/common/SecureComponent'
import SelectMonthYear from '../../common/Select/SelectMonthYear.component'
import SkeletonCard from '../../DashboardPage/SkeletonCard'
import { SnackbarTypes } from '../../../enums/SnackbarTypesEnum'
import StringConstants from '../../../constants/StringConstants'
import UrlConstants from '../../../constants/UrlConstants'
import ValidationUtils from '../../../utils/validation/ValidationUtils'
import currentPlanStore from '../../../datastore/CurrentPlanStore'
import customerStore from '../../../datastore/CustomerStore'
import dayjs from 'dayjs'
import { getDateWithinApprovedDOBRange } from '../../../utils/CommonUtils'
import mPartDPlansFilterStore from '../../../datastore/medicareQuotes/MPartPlanFilterQuotesStore'
import medicareAdvantageFilterQuoteStore from '../../../datastore/medicareQuotes/MedicareAdvantageFilterQuoteStore'
import moment from 'moment'
import msPlanFiltersStore from '../../../datastore/medicareQuotes/MSupplementPlanFiltersStore'
import { observer } from 'mobx-react'
import snackbarStore from '../../../datastore/SnackbarStore'
import styled from '@emotion/styled'
import theme from '../../../global/theme'
import { useNavigate } from 'react-router-dom'
import { withRouter } from '../../common/WithRouter/WithRouter.component'

interface DataProps {
  spouseOptIn: boolean
  firstName: string
  lastName: string
  dateOfBirth: string
  currentMedicarePartBDateSelf: string | null
  spouseFirstName: string
  spouseLastName: string
  spouseDateOfBirth: string
  currentMedicarePartBDateSpouse: string | null
  gender: string | null
  spouseGender: string | null
  quotingToolVersionId: string
}

interface ProfileProps {
  isSpouse: boolean
}

interface LabelProps {
  padding?: string
}

const Container = styled.div`
  width: 100%;
`

const SectionWrapper = styled.div`
  width: 100%;
  padding: 10px 0px 30px 0px;
  border-top: 1px solid ${theme.colors.borderColor};
`

const Label = styled.h4<LabelProps>`
  font-size: 16px;
  color: ${theme.colors.textGrey};
  padding: ${({ padding }) => (padding ? padding : '20px 10px 5px 10px')};
  font-weight: 600;
`

const CurrentPlanWrapper = styled.div`
  div {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    padding: 5px 10px;
    border-radius: 4px;
    img {
      width: 80px;
      margin-right: 40px;
    }
    h3 {
      font-size: 18px;
      color: ${theme.colors.textGrey};
    }

    p {
      font-size: 14px;
      display: flex;
      align-items: center;
      text-align: left;
      width: 100%;
      font-weight: 400;
      color: ${theme.colors.textGrey};
      span {
        margin-left: 10px;
        margin-right: 10px;
        font-weight: 600;
        font-size: 16px;
      }
    }
  }

  .contrastBack {
    background-color: ${theme.colors.contrastPrimary};
    justify-content: space-between;
  }
`

const SelectMonthYearWrapper = styled.div`
  display: flex;
  padding: 10px 0px;
`

const DisableText = styled.div`
  font-size: 14px;
  margin-left: 5px;
  color: ${theme.colors.secondary};
`

const Profile: React.FC<ProfileProps> = (props) => {
  const filter = new Filter()
  const navigate = useNavigate()
  const [firstName, setFirstName] = React.useState('')
  const [lastName, setLastName] = React.useState('')
  const [dob, setDOB] = React.useState('')
  const [gender, setGender] = React.useState<string>('')
  const [isEnrolled, setIsEnrolled] = React.useState(true)
  const [effectiveDate, setEffectiveDate] = React.useState('')
  const [monthlyPremium, setMonthlyPremium] = React.useState('')
  const [rxCoverageIncluded, setRxCoverageIncluded] = React.useState<
    null | boolean
  >(null)
  const [planName, setPlanName] = React.useState('')
  const [carrier, setCarrier] = React.useState('')
  const genderOptions = ['Male', 'Female']
  const [state, setState] = React.useState({
    firstNameError: false,
    firstNameHelperText: '',
    lastNameError: false,
    lastNameHelperText: '',
    dobError: false,
    dobHelperText: '',
    effectiveDateError: false,
    effectiveDateHelperText: '',
    anticipatedEndDateError: false,
    anticipatedEndDateHelperText: '',
    loading: false,
    buttonLoading: false,
  })
  const [shouldResetEffectiveDate, setShouldResetEffectiveDate] =
    React.useState(false)
  const { trackCurrentPage } = React.useContext(ActionContext)
  const yearsTo = dayjs().year()
  const defaultYear = yearsTo - 67
  const currentDate = dayjs(dob || new Date())
  const defaultDate = currentDate.year(defaultYear).format('YYYY-MM-DD')
  const isButtonDisabled =
    customerStore.isMAQuotesProcessing ||
    customerStore.isMSQuotesProcessing ||
    customerStore.isMPartDQuotesProcessing

  const fetchCustomerDetails = async (
    doNotSuppress = true
  ): Promise<boolean> => {
    let success = false
    await ApiManager.makeApiCallWithAuthentication(
      UrlConstants.GET_CUSTOMER_DETAILS.USECASE,
      HttpMethods.GET_METHOD
    )
      .then((response) => {
        if (response.status === 200) {
          const data = response.data.data
          customerStore.set(data)
          success = true
          return success
        } else throw response
      })
      .catch((err) => {
        snackbarStore.set({
          snackbarMessage: getApiErrorMessage('fetch customer details'),
          snackbarOpen: true,
          snackbarType: SnackbarTypes.ERROR,
        })
        return success
      })
    return success
  }

  const fetchInfoSteps = () => {
    ApiManager.makeApiCallWithAuthentication(
      UrlConstants.GET_INFO_STEPS.USECASE,
      HttpMethods.GET_METHOD
    )
      .then((res) => {
        const infoStepsArray = res.data.data as string[]
        customerStore.setInfoSteps(infoStepsArray)
      })
      .catch((err) => {
        snackbarStore.set({
          snackbarMessage: getApiErrorMessage('fetch user progress'),
          snackbarOpen: true,
          snackbarType: SnackbarTypes.ERROR,
        })
      })
  }

  React.useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    fetchCustomerDetails()
    fetchInfoSteps()
  }, [])

  React.useEffect(() => {
    trackCurrentPage(
      props.isSpouse
        ? 'Profile - Spouse/Partner Profile'
        : 'Profile - Profile Page'
    )
    currentPlanStore.set(
      props.isSpouse
        ? customerStore.currentPlanSpouse || null
        : customerStore.currentPlanCustomer || null
    )
    setFirstName(
      props.isSpouse ? customerStore.spouseFirstName : customerStore.firstName
    )
    setLastName(
      props.isSpouse ? customerStore.spouseLastName : customerStore.lastName
    )
    setDOB(
      props.isSpouse
        ? customerStore.spouseDateOfBirth.substring(0, 10)
        : customerStore.dateOfBirth.substring(0, 10)
    )
    setGender(
      props.isSpouse
        ? capitalizeFirstCharacter(customerStore.spouseGender)
        : capitalizeFirstCharacter(customerStore.gender)
    )
    let effectiveDateFromSupplementalForm = props.isSpouse
      ? !isNull(customerStore.currentMedicarePartBDateSpouse)
        ? customerStore.currentMedicarePartBDateSpouse.substring(0, 10)
        : ''
      : !isNull(customerStore.currentMedicarePartBDateSelf)
      ? customerStore.currentMedicarePartBDateSelf.substring(0, 10)
      : ''
    let effectiveDateFromBoth = props.isSpouse
      ? !isNull(customerStore.currentPlanSpouse)
        ? !isUndefined(customerStore.currentPlanSpouse.effectiveDate)
          ? isNull(customerStore.currentPlanSpouse.effectiveDate)
            ? ''
            : customerStore.currentPlanSpouse.effectiveDate.substring(0, 10)
          : effectiveDateFromSupplementalForm
        : effectiveDateFromSupplementalForm
      : !isNull(customerStore.currentPlanCustomer)
      ? !isUndefined(customerStore.currentPlanCustomer.effectiveDate)
        ? isNull(customerStore.currentPlanCustomer.effectiveDate)
          ? ''
          : customerStore.currentPlanCustomer.effectiveDate.substring(0, 10)
        : effectiveDateFromSupplementalForm
      : effectiveDateFromSupplementalForm
    setEffectiveDate(effectiveDateFromBoth)
    setIsEnrolled(isEmpty(effectiveDateFromBoth) ? false : true)
  }, [
    props.isSpouse,
    customerStore.firstName,
    customerStore.spouseFirstName,
    customerStore.lastName,
    customerStore.spouseLastName,
    customerStore.dateOfBirth,
    customerStore.spouseDateOfBirth,
    customerStore.currentMedicarePartBDateSelf,
    customerStore.currentMedicarePartBDateSpouse,
    customerStore.currentPlanCustomer,
    customerStore.currentPlanSpouse,
  ])

  React.useEffect(() => {
    setMonthlyPremium(currentPlanStore.monthlyPremium)
  }, [currentPlanStore.monthlyPremium])

  React.useEffect(() => {
    setRxCoverageIncluded(currentPlanStore.rxIncluded)
  }, [currentPlanStore.rxIncluded])

  React.useEffect(() => {
    if (!isEmpty(currentPlanStore.planType))
      setPlanName(currentPlanStore.planType)
  }, [currentPlanStore, currentPlanStore.planType])

  React.useEffect(() => {
    let carrier = ''
    if (!isEmpty(currentPlanStore.provider)) {
      carrier = currentPlanStore.provider
    }
    setCarrier(carrier)
  }, [currentPlanStore, currentPlanStore.provider])

  const validateFirstName = (firstName: string) => {
    const firstNameError = !ValidationUtils.validateFirstName(firstName)
    setState({
      ...state,
      firstNameError,
      firstNameHelperText: isEmpty(firstName)
        ? StringConstants.UI_ERROR_MSG_FOR_EMPTY_FIRST_NAME
        : firstNameError
        ? StringConstants.UI_ERROR_MSG_FOR_INVALID_FIRST_NAME
        : '',
    })
  }
  const handleFirstNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const firstName = isEmpty(e.target.value)
      ? ''
      : filter.clean(e.target.value).replaceAll('*', '')
    validateFirstName(firstName)
    setFirstName(firstName)
  }

  const validateLastName = (lastName: string) => {
    const lastNameError = !ValidationUtils.validateLastName(lastName)
    setState({
      ...state,
      lastNameError,
      lastNameHelperText: isEmpty(lastName)
        ? StringConstants.UI_ERROR_MSG_FOR_EMPTY_LAST_NAME
        : lastNameError
        ? StringConstants.UI_ERROR_MSG_FOR_INVALID_LAST_NAME
        : '',
    })
  }
  const handleLastNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const lastName = isEmpty(e.target.value)
      ? ''
      : filter.clean(e.target.value).replaceAll('*', '')
    validateLastName(lastName)
    setLastName(lastName)
  }

  const validateDOB = (dob: string) => {
    const dobError = !ValidationUtils.validateDOB(dob)
    setState({
      ...state,
      dobError,
      dobHelperText: isEmpty(dob)
        ? StringConstants.UI_ERROR_MSG_FOR_EMPTY_DOB
        : dobError
        ? StringConstants.UI_ERROR_MSG_FOR_INVALID_DOB
        : '',
    })
    return dobError
  }
  const handleDOBChange = (e: any) => {
    if (e) {
      const givenDOB = dayjs(e.$d).format('YYYY-MM-DD')
      if (validateDOB(givenDOB)) setDOB(givenDOB)
    }
  }
  const handleInputDOB = (e: any) => {
    if (e) {
      const givenDOB = dayjs(e.$d).format('YYYY-MM-DD')
      if (e.$y >= 1000) {
        const finalDate = getDateWithinApprovedDOBRange(e)
        validateDOB(finalDate)
        setDOB(finalDate)
      } else validateDOB(givenDOB)
    }
  }

  const validateEffectiveDate = (effectiveDate: string) => {
    const effectiveDateError =
      !ValidationUtils.validateEffectiveDate(effectiveDate)
    setState({
      ...state,
      effectiveDateError,
      effectiveDateHelperText: effectiveDateError
        ? isEmpty(effectiveDate)
          ? StringConstants.UI_ERROR_MSG_FOR_EMPTY_PART_B_EFFECTIVE_DATE
          : StringConstants.UI_ERROR_MSG_FOR_INVALID_PART_B_EFFECTIVE_DATE
        : '',
    })
  }
  const handleEffectiveDateChange = (inputDate: string) => {
    validateEffectiveDate(inputDate)
    setIsEnrolled(true)
    setEffectiveDate(inputDate)
  }

  const handleIsEnrolledChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let effectiveDateError =
      !ValidationUtils.validateEffectiveDate(effectiveDate)
    if (e.target.value === 'Yes') {
      setIsEnrolled(true)
      setPlanName(currentPlanStore.planType)
      setCarrier(currentPlanStore.provider)
    } else {
      setIsEnrolled(false)
      setEffectiveDate('')
      setShouldResetEffectiveDate(true)
      setPlanName('')
      setCarrier('')
      effectiveDateError = false
    }
    setState({
      ...state,
      effectiveDateError,
      effectiveDateHelperText: effectiveDateError
        ? isEmpty(effectiveDate)
          ? StringConstants.UI_ERROR_MSG_FOR_EMPTY_PART_B_EFFECTIVE_DATE
          : StringConstants.UI_ERROR_MSG_FOR_INVALID_PART_B_EFFECTIVE_DATE
        : '',
    })
  }

  const handleGenderChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setGender(e.target.value)
  }

  const handleSave = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    customerStore.setIsQuotesPullAPiCalled(false)
    customerStore.setIsProfileUpdating(true)
    let isEffectiveDateChanged = false,
      isDOBChanged = false,
      isGenderChanged = false
    e.preventDefault()
    if (isEmpty(firstName)) {
      setState({
        ...state,
        firstNameError: true,
        firstNameHelperText: StringConstants.UI_ERROR_MSG_FOR_EMPTY_FIRST_NAME,
      })
    }
    if (isEmpty(lastName)) {
      setState({
        ...state,
        lastNameError: true,
        lastNameHelperText: StringConstants.UI_ERROR_MSG_FOR_EMPTY_LAST_NAME,
      })
    }
    if (isEmpty(dob)) {
      setState({
        ...state,
        dobError: true,
        dobHelperText: StringConstants.UI_ERROR_MSG_FOR_EMPTY_DOB,
      })
    }
    if (!isEmpty(dob)) {
      validateDOB(dob)
    }
    if (
      !(
        state.firstNameError ||
        state.lastNameError ||
        state.dobError ||
        state.effectiveDateError
      )
    ) {
      let data: Partial<DataProps> = {}
      if (props.isSpouse) {
        if (firstName !== customerStore.spouseFirstName)
          data.spouseFirstName = firstName
        if (lastName !== customerStore.spouseLastName)
          data.spouseLastName = lastName

        if (gender !== capitalizeFirstCharacter(customerStore.spouseGender))
          data.spouseGender = gender.toLowerCase()
        if (
          dob !== '' &&
          (isEmpty(customerStore.spouseDateOfBirth) ||
            (!isEmpty(customerStore.spouseDateOfBirth) &&
              dob !== customerStore.spouseDateOfBirth.substring(0, 10)))
        )
          data.spouseDateOfBirth = dob
        if (
          ((isEmpty(customerStore.currentMedicarePartBDateSpouse) ||
            isEmpty(currentPlanStore)) &&
            effectiveDate !== '') ||
          (!isEmpty(customerStore.currentMedicarePartBDateSpouse) &&
            effectiveDate !==
              customerStore.currentMedicarePartBDateSpouse?.substring(0, 10)) ||
          (!isEmpty(customerStore.currentPlanSpouse?.effectiveDate) &&
            effectiveDate !==
              customerStore.currentPlanSpouse?.effectiveDate?.substring(0, 10))
        ) {
          if (effectiveDate === '') {
            data.currentMedicarePartBDateSpouse = null
          } else {
            data.currentMedicarePartBDateSpouse = effectiveDate
          }
        }
      } else {
        if (firstName !== customerStore.firstName) data.firstName = firstName
        if (lastName !== customerStore.lastName) data.lastName = lastName
        if (gender.toLowerCase() !== customerStore.gender) {
          isGenderChanged = true
          data.gender = gender.toLowerCase()
        }
        if (
          dob !== '' &&
          (isEmpty(customerStore.dateOfBirth) ||
            (!isEmpty(customerStore.dateOfBirth) &&
              dob !== customerStore.dateOfBirth.substring(0, 10)))
        ) {
          isDOBChanged = true
          data.dateOfBirth = dob
        }
        if (
          ((isEmpty(customerStore.currentMedicarePartBDateSelf) ||
            isEmpty(currentPlanStore)) &&
            effectiveDate !== '') ||
          (!isEmpty(customerStore.currentMedicarePartBDateSelf) &&
            effectiveDate !==
              customerStore.currentMedicarePartBDateSelf?.substring(0, 10)) ||
          (!isEmpty(customerStore.currentPlanCustomer?.effectiveDate) &&
            effectiveDate !==
              customerStore.currentPlanCustomer?.effectiveDate?.substring(
                0,
                10
              ))
        ) {
          isEffectiveDateChanged = true
          if (effectiveDate === '') {
            data.currentMedicarePartBDateSelf = null
          } else {
            data.currentMedicarePartBDateSelf = effectiveDate
          }
        }
      }
      if (!isEmpty(data)) {
        data.quotingToolVersionId = process.env.REACT_APP_VERSION_ID
        setState({
          ...state,
          buttonLoading: true,
        })
        ApiManager.makeApiCallWithAuthentication(
          UrlConstants.UPDATE_CUSTOMER_DETAILS.USECASE,
          HttpMethods.PATCH_METHOD,
          data
        )
          .then(async (response) => {
            if (response.status === 200) {
              if ((await fetchCustomerDetails(false)) === true) {
                if (
                  isEffectiveDateChanged &&
                  !isEmpty(customerStore.dateOfBirth) &&
                  !isEmpty(customerStore.gender)
                ) {
                  msPlanFiltersStore.clearMSPlanFilterStore()
                  medicareAdvantageFilterQuoteStore.clearStore()
                  mPartDPlansFilterStore.clearStore()
                  customerStore.setQuotePulledStatusMS(true)
                } else if (
                  isEffectiveDateChanged &&
                  isEmpty(customerStore.dateOfBirth) &&
                  isEmpty(customerStore.gender)
                ) {
                  medicareAdvantageFilterQuoteStore.clearStore()
                  mPartDPlansFilterStore.clearStore()
                } else if (
                  !isEmpty(customerStore.dateOfBirth) &&
                  isDOBChanged
                ) {
                  msPlanFiltersStore.clearMSPlanFilterStore()
                  customerStore.setQuotePulledStatusMS(true)
                } else if (!isEmpty(customerStore.gender) && isGenderChanged) {
                  msPlanFiltersStore.clearMSPlanFilterStore()
                  customerStore.setQuotePulledStatusMS(true)
                }
                snackbarStore.set({
                  snackbarMessage: StringConstants.SNACKBAR_SAVE_SUCCESS_MSG,
                  snackbarOpen: true,
                  snackbarType: SnackbarTypes.SUCCESS,
                })
              }
            } else throw response
          })
          .catch((err) => {
            snackbarStore.set({
              snackbarMessage: getApiErrorMessage('update customer details'),
              snackbarOpen: true,
              snackbarType: SnackbarTypes.ERROR,
            })
          })
          .finally(() => {
            customerStore.setIsProfileUpdating(false)
            setState({
              ...state,
              loading: false,
              buttonLoading: false,
            })
          })
      } else {
        customerStore.setIsProfileUpdating(false)
        snackbarStore.set({
          snackbarMessage: StringConstants.SNACKBAR_NO_DATA_TO_SAVE_MSG,
          snackbarOpen: true,
          snackbarType: SnackbarTypes.WARNING,
        })
      }
    }
  }

  return !state.loading ? (
    <Container>
      <SectionWrapper>
        <Label>First Name</Label>
        <InputField
          error={state.firstNameError}
          helperText={state.firstNameHelperText}
          width='100%'
          label={props.isSpouse ? 'First name' : 'Enter your first name'}
          value={convertNameToTitleCase(firstName)}
          onChange={handleFirstNameChange}
        />
        {/*<Label>Middle Name(optional)</Label>
        <InputField label='Your middle name' width='100%' />*/}
        <Label>Last Name</Label>
        <InputField
          error={state.lastNameError}
          helperText={state.lastNameHelperText}
          width='100%'
          label={props.isSpouse ? 'Last name' : 'Enter your last name'}
          value={convertNameToTitleCase(lastName)}
          onChange={handleLastNameChange}
        />
        <Label>Date of Birth</Label>
        <DateField
          format='MM/DD/YYYY'
          value={dayjs(dayjs(dob).format('YYYY-MM-DD'))}
          minDate={dayjs(
            (moment().year() - 120).toString().substring(0, 2) + '00-01-01'
          )}
          maxDate={dayjs(moment().format('YYYY-MM-DD'))}
          defaultCalendarMonth={dayjs(
            moment(defaultDate).startOf('day').format('YYYY-MM-DD')
          )}
          error={state.dobError}
          errorText={state.dobHelperText}
          readonly={false}
          onAccept={handleDOBChange}
          onChange={handleInputDOB}
        />
        <Label>Gender</Label>
        <RadioGroup
          radioButtons={genderOptions}
          row={true}
          onChange={handleGenderChange}
          value={gender}
          radioGroupName='Gender'
        />
        {!props.isSpouse && (
          <>
            <Label>Password</Label>
            <Button onClick={() => navigate(RouteConstants.RESET_PASSWORD)}>
              Change Password
            </Button>
          </>
        )}
      </SectionWrapper>
      <SectionWrapper>
        <Label padding='20px 0px 5px 0px'>
          Currently enrolled in Medicare Part B?
        </Label>
        <RadioGroup
          radioButtons={['Yes', 'No']}
          row={true}
          onChange={handleIsEnrolledChange}
          value={isEnrolled ? 'Yes' : 'No'}
          radioGroupName='Currently enrolled in Medicare Part B?'
        />
        <Label padding='20px 0px 5px 0px'>
          Medicare Part B effective date?
        </Label>
        <SelectMonthYearWrapper>
          <SelectMonthYear
            value={effectiveDate}
            resetDate={shouldResetEffectiveDate}
            handleChange={handleEffectiveDateChange}
            showValidationError={state.effectiveDateError}
            setValidationErrorTrue={() => {
              setIsEnrolled(true)
              setState({
                ...state,
                effectiveDateError: true,
              })
            }}
            setValidationErrorFalse={() => {
              setShouldResetEffectiveDate(false)
              setState({
                ...state,
                effectiveDateError: false,
              })
            }}
          />
        </SelectMonthYearWrapper>
      </SectionWrapper>
      {planName !== '' && (
        <SectionWrapper>
          <Label>Current Plan</Label>x
          <Card cardType='readOnly' borderColor={theme.colors.primary}>
            <CurrentPlanWrapper>
              <div>
                <CarrierLogo carrier={carrier} useAltLogo={true} />
                <h3>{planName}</h3>
              </div>
              <div className='contrastBack'>
                <p>
                  Monthly Premium <span>${monthlyPremium}</span>
                </p>
                {!isNull(rxCoverageIncluded) && (
                  <p>
                    Rx Coverage {rxCoverageIncluded === false && 'not'} included
                  </p>
                )}
              </div>
            </CurrentPlanWrapper>
          </Card>
        </SectionWrapper>
      )}
      <SectionWrapper>
        <Button
          variant='contained'
          color='secondary'
          onClick={handleSave}
          loading={state.buttonLoading}
          disabled={
            customerStore.isMAQuotesProcessing ||
            customerStore.isMSQuotesProcessing ||
            customerStore.isMPartDQuotesProcessing ||
            state.buttonLoading
          }
        >
          Save Changes
        </Button>
        {isButtonDisabled && (
          <DisableText>{StringConstants.DISABLE_BUTTON_MSG}</DisableText>
        )}
      </SectionWrapper>
    </Container>
  ) : (
    <SkeletonCard />
  )
}

export default SecureComponent(withRouter(observer(Profile)))
