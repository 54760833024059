import React from 'react'
import styled from '@emotion/styled'
import theme from '../../../global/theme'
import CloseIconButton from '../Button/CloseIconButton.component'

interface NotificationProps {
  children: JSX.Element | JSX.Element[]
  open: boolean
  setOpen: () => void
}

interface ContainerProps {
  open: boolean
}

const Container = styled.div<ContainerProps>`
  width: 100%;
  background-color: ${theme.colors.primary};
  justify-content: space-between;
  align-items: center;
  display: ${(props) => (props.open ? 'flex' : 'none')};
  padding: 5px 50px;
  z-index: 1;
  margin-bottom: 1px;

  @media screen and (max-width: 750px) {
    padding: 10px 20px;
  }
`

const TopNotificationChip: React.FC<NotificationProps> = ({
  children,
  open,
  setOpen
}) => {
  // const [open, setOpen] = React.useState<boolean>(true)
  return (
    <Container open={open}>
      {children}
      <CloseIconButton
        onClick={setOpen}
        fontSize='14px'
        textColor={theme.colors.white}
        iconType='bold'
      />
    </Container>
  )
}

export default TopNotificationChip
