import styled from '@emotion/styled'
import React from 'react'
import theme from '../../../../global/theme'
import Button from '../../../common/Button/Button.component'
import RadioGroup from '../../../common/RadioGroup/RadioGroup.component'
import { isEmpty } from 'lodash'
import BouncingDotsLoader from '../../../common/Loading/BouncingDotsLoader'
import customerStore from '../../../../datastore/CustomerStore'
import StringConstants from '../../../../constants/StringConstants'

interface DoctorDetailedSelectionProps {
  data: {
    id: number
    title: string
    specialization: string
    location: any[]
    isPrimaryPhysician: ['Yes', 'No']
  }
  handleAddDoctor: (id: number) => void
  handleCancel?: (id: number) => void
  providerAddress: any
  loading?: boolean
  isQuotesRefreshing?: boolean
}

const Container = styled.div`

  margin-top: 20px;
  width: 100%;
  min-height: 300px;
  border-radius: 4px;
  border: 1px solid ${theme.colors.borderColor};
  padding: 15px 20px;
  display:flex;
  flex-direction:column;
  justify-content:space-evenly;

  h3 {
    color: ${theme.colors.primary};
    font: 20px;
    padding-bottom: 2px;
  }

  h4 {
    font-size: 14px;
    font-weight: 400;
    color: ${theme.colors.textDark};
    padding-bottom: 30px;
  }

  div {
    padding-bottom: 10px;
    
  }
`

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: -1%;
  margin-right: 2.15%;
`

const DisableText = styled.div`
  font-size: 14px;
  margin-left: 5px;
  color: ${theme.colors.secondary};
`

const DoctorDetailedSelection: React.FC<DoctorDetailedSelectionProps> = ({
  data,
  handleAddDoctor,
  handleCancel,
  providerAddress,
  loading,
  isQuotesRefreshing
}) => {
  const [close, setClose] = React.useState(false)
  const [selectedAddres, setSelectedAddress] = React.useState<any>({})
  const [addressError, setAddressError] = React.useState<boolean>(false)

  return (
    <>
      {!close && (
        <Container>
          <div>
            <h3>{!isEmpty(data) && `${data.title}`}</h3></div>
          {!loading ? (
            <div>
              <RadioGroup
                formLabel={providerAddress.length > 0 ? `This physician has ${providerAddress.length} office(s). Select the office location where you visit them` : `This physician does not have an office in the location provided`}
                radioButtons={providerAddress.length > 0 ? providerAddress.map((address: any) => {
                  return (address.address1 + ' ' + address.address2 + '_ext_' + address.id)
                }) : []}

                onChange={(e) => {
                  let value = e.target.value
                  if (providerAddress.length > 0) {
                    const address = providerAddress.filter((selectedAddres: any) => {
                      return value === (selectedAddres.address1 + ' ' + selectedAddres.address2) + '_ext_' + selectedAddres.id
                    })
                    setSelectedAddress(address)
                  }
                  setAddressError(false)
                }}
                error={addressError}
                isValueModified={true}
                valueToBeRemoved={'_ext_'}
              />
            </div>
          ) : <BouncingDotsLoader />
          }
          <ButtonWrapper>
            {isQuotesRefreshing && (
              <DisableText>
                {StringConstants.DISABLE_BUTTON_MSG}
              </DisableText>
            )}
            <Button
              variant='contained'
              width='100%'
              onClick={() => {
                if (handleAddDoctor) {
                  if (!isEmpty(selectedAddres)) {
                    setAddressError(false)
                    handleAddDoctor(selectedAddres)
                    setClose(true)
                  } else setAddressError(true)

                }
              }}
              disabled={
                customerStore.isMAQuotesProcessing ||
                customerStore.isMSQuotesProcessing ||
                customerStore.isMPartDQuotesProcessing
              }
            >
              ADD PHYSICIAN
            </Button>
            <Button
              variant='outlined'
              width='100%'
              color='info'
              onClick={() => {
                if (handleCancel) {
                  handleCancel(providerAddress)
                }
              }}
            >
              CANCEL
            </Button>
          </ButtonWrapper>
        </Container>
      )}
    </>
  )
}

export default DoctorDetailedSelection
