import Button from '../common/Button/Button.component'
import { Link } from 'react-router-dom'
import RouteConstants from '../../constants/RouteConstants'
import styled from '@emotion/styled'
import theme from '../../global/theme'

const Container = styled.div`
  width: 350px;
  min-height: 220px;

  h3 {
    color: ${theme.colors.primary};
    padding-bottom: 10px;
  }

  p {
    padding: 10px 0px;
    color: ${theme.colors.textDark};
  }

  @media screen and (max-width: 550px) {
    width: 300px;
  }

  @media screen and (max-width: 375px) {
    width: 260px;
  }
`

const ButtonContainer = styled(Link)`
  padding-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
`

const PasswordResetSuccessful = () => {
  return (
    <Container>
      <h3>Password reset successfully!</h3>
      <p>You can now use this new password to log into your account.</p>
      <p>Keep this password safe and private. Do not share it with anyone.</p>

      <ButtonContainer to={RouteConstants.LOGIN}>
        <Button
          width='100%'
          color='secondary'
          variant='contained'
          textDecoration='none'
          bold={false}
        >
          Done
        </Button>
      </ButtonContainer>
    </Container>
  )
}

export default PasswordResetSuccessful
