import styled from '@emotion/styled'
import React from 'react'
import eipLogo from '../../../assets/vectors/eipLogo.svg'
import BouncingDotsLoader from './BouncingDotsLoader'
import theme from '../../../global/theme'

interface LoadingComopnentProps {
  data: Array<string>
}

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  img {
    width: 160px;
  }
  h2 {
    color: ${theme.colors.primary};
    font-size: 24px;
    padding-top: 30px;
    padding-bottom: 20px;
  }
  p {
    max-width: 500px;
    text-align: center;
    font-size: 14px;
    color: ${theme.colors.textDark};
  }
`
const Container = styled.div`
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${theme.colors.white};
`

const LoadingComponent: React.FC<LoadingComopnentProps> = ({ data }) => {
  const randomQuote = data[Math.floor(Math.random() * data.length)]
  return (
    <Container>
      <Content>
        <BouncingDotsLoader />
        <img src={eipLogo} alt='logo' />
        <h2>Did you know?</h2>
        <p>{randomQuote}</p>
      </Content>
    </Container>
  )
}

export default LoadingComponent
