import { HiOutlinePhone } from 'react-icons/hi'
import customerStore from '../../datastore/CustomerStore'
import { getContact } from '../../utils/getContact'
import styled from '@emotion/styled'
import theme from '../../global/theme'
import { useEffect } from 'react'

const ContactContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 25vh;
  p {
    font-size: 14px;
    font-weight: 600;
    color: ${theme.colors.primary};
    display: flex;
    align-items: center;
    text-align: center;
  }
  span {
    color: ${theme.colors.secondary};
    font-size: 20px;
    font-weight: 700;
  }
  .LowerText {
    display: flex;
    align-items: flex-end;
    margin-top: 0.25rem;
    text-decoration: none;
    color: inherit;
  }

  @media screen and (max-width: 600px) {
    .UpperText {
      font-size: 12px;
      // display: none;
    }
    .LowerText {
      span {
        font-size: 16px;
      }
    }
  }
`

const ContactIcon = styled(HiOutlinePhone)`
  font-size: 28px;
  margin-right: 10px;

  @media screen and (max-width: 750px) {
    font-size: 18px;
  }
  /* @media screen and (max-width: 550px) {
    display: none;
  } */
`

const ContactCard: React.FC = () => {
  useEffect(() => {
    window.Invoca && window.Invoca.PNAPI.run()
  }, [])
  return (
    <ContactContainer>
      <p className='UpperText'>
        Don’t worry, your plans are getting ready!
        <br />
        Our team will reach out to you shortly to help you find the right plan!
        <br />
        You can also speak to us on
        <br />
      </p>
      <a
        href={`tel:${getContact(customerStore.getBrandName())}`}
        className='LowerText phone-number-fade'
      >
        <ContactIcon size='22px' />{' '}
        <span>{getContact(customerStore.getBrandName())}</span>
      </a>
    </ContactContainer>
  )
}
export default ContactCard
