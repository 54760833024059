import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import AdvantagePopUpCard from '../../components/DashboardPage/PageSections/PopUpPlanCards/MedicareAdvantageCard'
import ApiManager from '../../api/ApiManager'
import Footer from '../../components/common/Footer/Footer.component'
import { HttpMethods } from '../../enums/HttpMethodsEnum'
import InPageHeader from '../../components/common/InPageHeader/InPageHeader.component'
import Loader from '../../components/common/Loader'
import { MAPlan } from '../../interface/quotes/mNonSupplementPlans/medicareAdvantage/MAPlan'
import { MPartDPlan } from '../../interface/quotes/mNonSupplementPlans/medicarePartD/MPartDPlan'
import { MSPlan } from '../../interface/quotes/mSupplementPlans/MSPlan'
import ModalComponent from '../../components/common/Modal/Modal.component'
import NpPopUpPrompt from '../../components/DashboardPage/Modals/NpPopUpPrompt'
import { PreferenceCoverageShortNameEnum } from '../../enums/PreferenceCoverageShortNameEnum'
import PrescriptionPopUpCard from '../../components/DashboardPage/PageSections/PopUpPlanCards/MedicarePrescriptionCard'
import RouteConstants from '../../constants/RouteConstants'
import StringConstants from '../../constants/StringConstants'
import SupplementPopUpCard from '../../components/DashboardPage/PageSections/PopUpPlanCards/MedicareSupplementCard'
import UrlConstants from '../../constants/UrlConstants'
import ZipUtils from '../../utils/ZipUtils'
import { getApiErrorMessage } from '../../utils/StringUtils'
import { isEmpty } from 'lodash'
import { observer } from 'mobx-react'
import snackbarStore from '../../datastore/SnackbarStore'
import styled from '@emotion/styled'
import theme from '../../global/theme'
import { ActionContext } from '../../cra'

const Container = styled.div`
  min-height: 100vh;
  background-color: ${theme.colors.white};
`

const ZipCode = styled.div`
  @media only screen and (min-width: 1024px) {
    width: 20%;
    padding-left: 120px;
    padding-top: 30px;
  }

  @media screen and (max-width: 600px) {
    display: none;
  }
`
const CardsWrapper = styled.div`
  padding: 10px 20px 150px 10px;
`

const CardContainer = styled.div`
  display: flex;
  flex: 1;

  h3 {
    font-size: 16px;
    font-weight: bold;
    color: ${theme.colors.textDark};
    padding-bottom: 5px;
  }
  p {
    font-weight: normal;
    text-decoration: none;
  }

  @media screen and (max-width: 600px) {
    .zipCode {
      flex-direction: column-reverse;
    }

    .card {
      width: auto;
      min-height: 200px;
    }

    .last {
      background: #aa0000;
    }
  }
`

const PopUpDashboardPage = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)

  const [zipCodeDetails, setzipCodeDetails] = useState<{
    ZipCodeVal: string
    county: string
    stateCode: string
    fipsCode: string
  }>(
    {} as {
      ZipCodeVal: string
      county: string
      stateCode: string
      fipsCode: string
    }
  )

  const [tabValue, settabValue] = useState(Number)

  const [maPlans, setMAPlans] = useState<MAPlan[]>([])
  const [msPlans, setMSPlans] = useState<MSPlan[]>([])
  const [mPartDPlans, setMPartDPlans] = useState<MPartDPlan[]>([])
  const [popUpModal, setpopUpModal] = React.useState<{
    active: boolean
  }>({
    active: false,
  })
  const [leadBrand, setLeadBrand] = React.useState<string>('')
  const [isLoading, setIsLoading] = React.useState<boolean>(true)
  const { trackCurrentPage } = React.useContext(ActionContext)

  useEffect(() => {
    window.onbeforeunload = null
    const path = window.location.pathname.split('/')[2]
    if (queryParams.has('Coverage_Multi__c')) {
      const multiCoverage = queryParams.get('Coverage_Multi__c')
      if (multiCoverage?.includes('Supplement')) setTab('ms')
      else if (multiCoverage?.includes('Advantage')) setTab('ma')
      else if (multiCoverage?.includes('Prescription')) setTab('pdp')
      else setTab('ms')
    } else if (path !== '') setTab(path)
    else {
      const type = queryParams.has('type') ? queryParams.get('type') : ''
      setTab(type)
    }
    if (queryParams.has('leadBrand'))
      setLeadBrand(queryParams.get('leadBrand') as string)

    checkURL()
  }, [])

  React.useEffect(() => {
    trackCurrentPage('Np Pop-up')
  }, [])

  const setTab = (preferenceCoverage: string | null) => {
    if (preferenceCoverage === PreferenceCoverageShortNameEnum.ms)
      settabValue(1)
    else if (preferenceCoverage === PreferenceCoverageShortNameEnum.pdp)
      settabValue(2)
    else settabValue(0)
  }

  const checkURL = () => {
    if (queryParams.toString().length > 0 && queryParams.has('postalCode'))
      return null
    else navigate(RouteConstants.ACCESS_DENIED)
  }

  useEffect(() => {
    if (queryParams.has('postalCode')) {
      var postalCode = queryParams.get('postalCode')
      if (typeof postalCode === 'string') {
        ZipUtils.GetCountyForZip(postalCode)
          .then((res: any) => {
            setzipCodeDetails({
              ZipCodeVal: res.zipcodes[0].zipcode,
              county: res.zipcodes[0].county_name,
              stateCode: res.zipcodes[0].state_abbreviation,
              fipsCode: res.zipcodes[0].county_fips,
            })
          })
          .catch((err: Error) => {
            snackbarStore.set({
              snackbarOpen: true,
              snackbarMessage: getApiErrorMessage(
                'Unable to fetch zipcode details'
              ),
              snackbarType: 'error',
            })
          })
      }
    }
  }, [])

  useEffect(() => {
    if (maPlans.length > 0 && msPlans.length > 0 && mPartDPlans.length > 0)
      setIsLoading(false)
  }, [maPlans.length, msPlans.length, mPartDPlans.length])

  useEffect(() => {
    if (isLoading) {
      const timer = setTimeout(() => {
        window.location.href = `${process.env.REACT_APP_DEMOGRAPHICS_THANKS_PAGE_URL}?leadBrand=${leadBrand}`
      }, 30000)
      return () => clearTimeout(timer)
    }
  }, [isLoading, leadBrand])

  useEffect(() => {
    if (!isEmpty(zipCodeDetails)) {
      getMSPlans()
      getMAPlans()
      getMPartDPlans()
    }
  }, [zipCodeDetails])

  const getMSPlans = () => {
    let data: Object = {
      zip: zipCodeDetails.ZipCodeVal,
      stateCode: zipCodeDetails.stateCode,
      age: 65,
      gender: 'male',
    }
    ApiManager.makeApiCallWithAuthentication(
      UrlConstants.GET_SUPPLEMENT_PLANS_FOR_POP_UP.USECASE,
      HttpMethods.GET_METHOD,
      data
    )
      .then((msPlansResponse) => {
        if (msPlansResponse.status === 200) {
          if (msPlansResponse.data && msPlansResponse.data.data) {
            const convertedMSPlans: MSPlan[] = []
            for (let msPlan of msPlansResponse.data.data) {
              convertedMSPlans.push(msPlan)
            }
            if (convertedMSPlans.length > 10) {
              setMSPlans(convertedMSPlans.splice(0, 10))
            } else {
              setMSPlans(convertedMSPlans)
            }
          }
        } else {
          setMSPlans([])
        }
      })
      .catch((err: Error) => {
        snackbarStore.set({
          snackbarOpen: true,
          snackbarMessage: getApiErrorMessage(
            'Unable to fetch Medicare Supplement plans'
          ),
          snackbarType: 'error',
        })
      })
  }

  const getMAPlans = () => {
    let data: Object = {
      'fips-code': zipCodeDetails.fipsCode,
    }
    ApiManager.makeApiCallWithAuthentication(
      UrlConstants.GET_ADVANTAGE_PLANS_FOR_POP_UP.USECASE,
      HttpMethods.GET_METHOD,
      data
    )
      .then((maPlansResponse) => {
        if (maPlansResponse.status === 200) {
          if (maPlansResponse.data && maPlansResponse.data.data) {
            const convertedMAPlans: MAPlan[] = []
            for (let maPlan of maPlansResponse.data.data) {
              convertedMAPlans.push(maPlan)
            }
            setMAPlans(convertedMAPlans)
          }
        } else {
          setMAPlans([])
        }
      })
      .catch((err: Error) => {
        snackbarStore.set({
          snackbarOpen: true,
          snackbarMessage: getApiErrorMessage(
            'Unable to fetch Medicare Advantage plans'
          ),
          snackbarType: 'error',
        })
      })
  }

  const getMPartDPlans = () => {
    let data: Object = {
      'fips-code': zipCodeDetails.fipsCode,
    }
    ApiManager.makeApiCallWithAuthentication(
      UrlConstants.GET_PART_D_PLANS_FOR_POP_UP.USECASE,
      HttpMethods.GET_METHOD,
      data
    )
      .then((mPartDPlansResponse) => {
        if (mPartDPlansResponse.status === 200) {
          if (mPartDPlansResponse.data && mPartDPlansResponse.data.data) {
            const convertedPartDPlans: MPartDPlan[] = []
            for (let mPartDPlan of mPartDPlansResponse.data.data) {
              convertedPartDPlans.push(mPartDPlan)
            }
            setMPartDPlans(convertedPartDPlans)
          }
        } else {
          setMPartDPlans([])
        }
      })
      .catch((err: Error) => {
        snackbarStore.set({
          snackbarOpen: true,
          snackbarMessage: getApiErrorMessage(
            'Unable to fetch Medicare Part D Plans'
          ),
          snackbarType: 'error',
        })
      })
  }

  const handleTabChange = (e: React.SyntheticEvent, newValue: number) => {
    settabValue(newValue)
  }

  const handleSubmit = () => {
    setpopUpModal({ active: true })
  }

  return !isLoading ? (
    <Container>
      {popUpModal.active && (
        <ModalComponent
          setOpen={() =>
            setpopUpModal({
              active: false,
            })
          }
          description='Popup - User Information Prompt'
        >
          <NpPopUpPrompt
            zipCodeDetails={zipCodeDetails}
            tabValue={tabValue}
            leadBrand={leadBrand}
          />
        </ModalComponent>
      )}
      <InPageHeader
        onChange={handleTabChange}
        currentTab={tabValue}
        showBackButton={false}
        tabLabels={[
          {
            heading: 'Medicare Advantage Plan (Part C)',
          },
          {
            heading: 'Medicare Supplement (Medigap)',
          },
          {
            heading: 'Medicare Prescription Drugs (Part D)',
          },
        ]}
        heading='BROWSE MEDICARE PLANS'
      />
      <CardContainer>
        <ZipCode>
          <div>
            <h3>Showing plans for</h3>
            <p style={{ userSelect: 'none' }}>
              {zipCodeDetails.ZipCodeVal + ', ' + zipCodeDetails.county}
            </p>{' '}
          </div>
        </ZipCode>
        <CardsWrapper>
          {tabValue === 0 ? (
            <AdvantagePopUpCard
              handleSubmit={handleSubmit}
              medicareAdvantagePlans={maPlans as MAPlan[]}
            />
          ) : (
            ''
          )}
          {tabValue === 1 ? (
            <SupplementPopUpCard
              handleSubmit={handleSubmit}
              medicareSupplementPlans={msPlans as MSPlan[]}
            />
          ) : (
            ''
          )}
          {tabValue === 2 ? (
            <PrescriptionPopUpCard
              handleSubmit={handleSubmit}
              medicarePartDPlans={mPartDPlans as MPartDPlan[]}
            />
          ) : (
            ''
          )}
        </CardsWrapper>
      </CardContainer>
      <Footer showDiscountsDisclaimer={false} />
    </Container>
  ) : (
    <Loader
      message={StringConstants.NP_POP_UP_CAROUSEL_MESSAGE}
      contents={StringConstants.NP_POP_UP_LOADER_CAROUSEL_CONTENT}
      leadBrand={leadBrand}
    />
  )
}

export default observer(PopUpDashboardPage)
