import React from 'react'
import Tooltip from '../common/Tooltip/Tooltip.component'
import styled from '@emotion/styled'
import theme from '../../global/theme'

interface TableRowProps {
  header: JSX.Element | string | undefined
  tooltip?: string
  background?: 'dark' | 'light'
}

const Container = styled.div<{ background?: 'dark' | 'light' }>`
  // border-bottom: 1px solid ${theme.colors.borderColor};
  display: flex;
  font-size: 14px;
`

const Header = styled.div<{ background?: 'dark' | 'light' }>`
  padding: 10px 40px;
  min-width: 350px;
  max-width: 450px;
  font-weight: 700;
  display: flex;
  align-items: center;
  border-right: 1px solid ${theme.colors.borderColor};
  background-color: ${({ background }) =>
    background === 'dark'
      ? theme.colors.backgroundColorContainerDark
      : theme.colors.white};

  span {
    width: 200px;
  }

  @media screen and (max-width: 1200px) {
    padding: 6px 10px;
  }
  @media screen and (max-width: 900px) {
    min-width: 250px;
  }
  @media screen and (max-width: 650px) {
    min-width: 150px;
    span {
      width: 100px;
    }
  }
`
const ContentWrapper = styled.div<{
  background?: 'dark' | 'light'
  headerLength?: number
}>`
  display: flex;
  flex: 1;
  height: headerLength? calc(floor((headerLength/30)))*100% : 100%;
  justify-content: flex-start;
  background-color: ${({ background }) =>
    background === 'dark'
      ? theme.colors.backgroundColorContainerDark
      : theme.colors.white};
`

const TableRow: React.FC<TableRowProps> = ({
  header,
  tooltip,
  children,
  background,
}) => {
  return (
    <Container background={background}>
      <Header background={background}>
        <span>{header}</span> {tooltip ? <Tooltip title={tooltip} /> : ''}
      </Header>
      <ContentWrapper
        background={background}
        headerLength={typeof header === 'string' ? header.length : undefined}
      >
        {children}
      </ContentWrapper>
    </Container>
  )
}

export default TableRow
