import React from 'react'
import flag from '../../assets/images/flag-logo.png'

interface CarrierLogoProps {
  carrier: string
  width?: string
  height?: string
  useAltLogo?: boolean
}

const CarrierLogo: React.FC<CarrierLogoProps> = ({
  carrier = '',
  width,
  height,
  useAltLogo = false,
}) => {
  const imageBaseUrl = process.env.REACT_APP_CDN_BASE_URL + '/carriers/'
  let imgsrc = flag

  if (
    carrier.includes('Aetna') ||
    carrier.includes('American Continental Ins') ||
    carrier.includes('Continental Life Ins') ||
    carrier.includes('SilverScript')
  )
    imgsrc = imageBaseUrl + 'aetna.png'
  else if (
    carrier.includes('UnitedHealthcare') ||
    carrier.includes('United Hlthcare')
  )
    imgsrc = imageBaseUrl + 'unitedhealthcare.png'
  else if (
    carrier.includes('American Retirement Life Ins') ||
    carrier.includes('Cigna') ||
    carrier.includes('CIGNA') ||
    carrier.includes('Loyal Amer')
  )
    imgsrc = imageBaseUrl + 'cigna.png'
  else if (carrier.includes('Kaiser Permanente'))
    imgsrc = imageBaseUrl + 'kaiser-permanente.png'
  else if (carrier.includes('Humana')) imgsrc = imageBaseUrl + 'humana.png'
  else if (carrier.includes('Bankers Life'))
    imgsrc = imageBaseUrl + 'bankers-life.png'
  else if (carrier.includes('Omaha') || carrier.includes('United World')) {
    if (useAltLogo === true) imgsrc = imageBaseUrl + 'mutualofomaha-small.svg'
    else imgsrc = imageBaseUrl + 'mutualofomaha.svg'
  } else if (carrier.includes('AARP')) imgsrc = imageBaseUrl + 'aarp.png'
  else if (carrier.includes('Anthem')) imgsrc = imageBaseUrl + 'anthem.png'
  else if (carrier.includes('Highmark')) imgsrc = imageBaseUrl + 'highmark.png'
  else if (
    carrier.includes('BCBS') ||
    carrier.includes('Blue Cross of ') ||
    carrier.includes('Blue Shield of') ||
    carrier.includes('Blue Cross and Blue Shield') ||
    carrier.includes('BlueCross') ||
    carrier.includes('BlueShield')
  )
    imgsrc = imageBaseUrl + 'blue-cross-blue-shield.png'
  else if (carrier.includes('Colonial Penn'))
    imgsrc = imageBaseUrl + 'colonial-penn.png'
  else if (carrier.includes('Freedom Health'))
    imgsrc = imageBaseUrl + 'freedom.png'
  else if (
    carrier.includes('Physicians Life') ||
    carrier.includes('Physicians Mut')
  )
    imgsrc = imageBaseUrl + 'physicians-mutual.png'
  else if (carrier.includes('USAA')) imgsrc = imageBaseUrl + 'usaa.png'
  else if (carrier.includes('Accendo')) imgsrc = imageBaseUrl + 'accendo.png'
  else if (carrier.includes('American Family Life Assur'))
    imgsrc = imageBaseUrl + 'aflac.png'
  else if (carrier.includes('Bankers Fidelity'))
    imgsrc = imageBaseUrl + 'bankers-fidelity.png'
  else if (carrier.includes('Capitol Life Ins'))
    imgsrc = imageBaseUrl + 'capitol-life-ins.jpeg'
  else if (carrier.includes('Family Life Ins'))
    imgsrc = imageBaseUrl + 'family-life-ins.png'
  else if (carrier.includes('Gerber Life Ins'))
    imgsrc = imageBaseUrl + 'gerber-life-ins.png'
  else if (carrier.includes('Globe Life'))
    imgsrc = imageBaseUrl + 'globe-life.png'
  else if (carrier.includes('Government Personnel Mut'))
    imgsrc = imageBaseUrl + 'gpm-life.jpeg'
  else if (carrier.includes('GPM Health and Life'))
    imgsrc = imageBaseUrl + 'gpm-health-life.jpeg'
  else if (carrier.includes('Elips Life'))
    imgsrc = imageBaseUrl + 'elips-life.jpeg'
  else if (carrier.includes('Lumico')) imgsrc = imageBaseUrl + 'lumico.png'
  else if (carrier.includes('Manhattan Life'))
    imgsrc = imageBaseUrl + 'manhattan-life.png'
  else if (carrier.includes('Medico')) imgsrc = imageBaseUrl + 'medico.jpeg'
  else if (carrier.includes('Allstate'))
    imgsrc = imageBaseUrl + 'national-general.png'
  else if (carrier.includes('Nassau')) imgsrc = imageBaseUrl + 'nassau.jpeg'
  else if (carrier.includes('United Amer'))
    imgsrc = imageBaseUrl + 'united-american.png'
  else if (carrier.includes('Western United Life'))
    imgsrc = imageBaseUrl + 'western-united-life.png'
  else if (
    carrier.includes('Wellcare') ||
    carrier.includes('WellCare') ||
    carrier.includes('WELLCARE')
  )
    imgsrc = imageBaseUrl + 'wellcare.png'
  else if (carrier.includes('Devoted'))
    imgsrc = imageBaseUrl + 'devoted.png'
  else {
    imgsrc = flag
    carrier = 'EIP'
  }

  return (
    <img
      src={imgsrc}
      alt={carrier}
      width={width}
      height={height}
      onError={({ currentTarget }) => {
        currentTarget.onerror = null // prevents looping
        currentTarget.src = flag
        if (height) currentTarget.width = +height.replace('px', '')
      }}
    />
  )
}

export default CarrierLogo
