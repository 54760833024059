import React, { useState } from 'react'

import Button from '../../../common/Button/Button.component'
import styled from '@emotion/styled'
import theme from '../../../../global/theme'

interface QuestionProps extends ContainerProps {
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined
  handleYes?: () => void
  handleNo?: () => void
  heading?: string
  description?: string
  buttontext1?: string
  buttontext2?: string
  isRequired?: boolean
  disableMainNext?: () => void
  enableMainNext?: () => void
  question?: string
}

interface ContainerProps {
  width?: string
}

const Container = styled.div<ContainerProps>`
  text-align: center;
  width: ${(props) => props.width};
  h3 {
    font-size: 20px;
    font-weight: 700px;
    padding-bottom: 20px;
    color: #272f69;
  }

  p {
    padding-bottom: 20px;
  }

  div {
    display: flex;
    justify-content: center;
    gap: 20px;
  }

  @media screen and (max-width: 500px) {
    width: 100%;
  }
`

const Question: React.FC<QuestionProps> = ({
  onClick,
  handleYes,
  handleNo,
  heading = '',
  description = '',
  buttontext1 = 'Yes',
  buttontext2 = 'No',
  width,
  isRequired = false,
  disableMainNext,
  enableMainNext,
  question,
}) => {
  const [isYesBtnHovered, setIsYesBtnHovered] = useState(false)
  const [isNoBtnHovered, setIsNoBtnHovered] = useState(false)
  const [response, setResponse] = React.useState<'Yes' | 'No' | null>(null)

  const handleYesBtnHover = () => setIsYesBtnHovered(true)
  const handleYesBtnLeave = () => setIsYesBtnHovered(false)
  const handleNoBtnHover = () => setIsNoBtnHovered(true)
  const handleNoBtnLeave = () => setIsNoBtnHovered(false)

  React.useEffect(() => {
    if (question === 'Question1') disableMainNext && disableMainNext()
    else enableMainNext && enableMainNext()
  }, [])

  return (
    <Container width={width}>
      <h3>
        {heading}
        {isRequired ? (
          <span style={{ color: theme.colors.secondary }}>*</span>
        ) : (
          ''
        )}
      </h3>
      <p>{description}</p>
      <div>
        <Button
          color='primary'
          width='100px'
          bold={false}
          textTransform='uppercase'
          value='Yes'
          onClick={() => {
            setResponse('Yes')
            handleYes && handleYes()
          }}
          handleHover={handleYesBtnHover}
          handleLeave={handleYesBtnLeave}
          variant={
            isYesBtnHovered || response === 'Yes' ? 'contained' : 'outlined'
          }
        >
          {buttontext1}
        </Button>
        <Button
          color='primary'
          width='100px'
          bold={false}
          textTransform='uppercase'
          value='No'
          onClick={() => {
            setResponse('No')
            handleNo && handleNo()
          }}
          handleHover={handleNoBtnHover}
          handleLeave={handleNoBtnLeave}
          variant={
            isNoBtnHovered || response === 'No' ? 'contained' : 'outlined'
          }
        >
          {buttontext2}
        </Button>
      </div>
    </Container>
  )
}

export default Question
