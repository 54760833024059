import ApiManager from '../../api/ApiManager'
import Button from '../../components/common/Button/Button.component'
import CarouselHeader from '../../components/ComparePlansPage/CarouselHeader'
import { FiChevronLeft } from 'react-icons/fi'
import { HttpMethods } from '../../enums/HttpMethodsEnum'
import Icon from '../../components/common/Icon/Icon.component'
import LayoutComponent from '../../components/common/Layout/Layout.component'
import { MNSCompletePlan } from '../../interface/quotes/mNonSupplementPlans/MNSCompletePlan'
import { MSPlan } from '../../interface/quotes/mSupplementPlans/MSPlan'
import { MSPlanGenericDetails } from '../../interface/quotes/mSupplementPlans/MSPlanGenericDetails'
import MedicareAdvantageTable from '../../components/ComparePlansPage/Tables/MedicareAdvantageTable'
import MedicarePartDTable from '../../components/ComparePlansPage/Tables/MedicarePartDTable'
import { MedicareQuotesType } from '../../enums/MedicareQuoteTypeEnum'
import MedicareSupplementTable from '../../components/ComparePlansPage/Tables/MedicareSupplementTable'
import React from 'react'
import SecureComponent from '../common/SecureComponent'
import SkeletonCard from '../../components/DashboardPage/SkeletonCard'
import UrlConstants from '../../constants/UrlConstants'
import { getApiErrorMessage } from '../../utils/StringUtils'
import { observer } from 'mobx-react'
import snackbarStore from '../../datastore/SnackbarStore'
import styled from '@emotion/styled'
import theme from '../../global/theme'
import { useNavigate } from 'react-router-dom'
import userActionStore from '../../datastore/UserActionStore'
import { isUndefined } from 'lodash'

const Container = styled.div`
  padding: 50px 20px;
  min-height: 100vh;
  background-color: ${theme.colors.white};
`

// Will need later, so commenting out
// const DoctorHeader = styled.div`
//   .name {
//     font-size: 16px;
//     font-weight: 700;
//     color: ${theme.colors.textDark};
//   }

//   .type {
//     font-weight: 400;
//     font-size: 12px;
//     color: ${theme.colors.textGrey};
//     font-style: italic;
//   }

//   .address {
//     padding-top: 8px;
//     font-weight: 400;
//     font-size: 12px;
//     color: ${theme.colors.textGrey};
//   }
// `
const CarouselHeaderWrapper = styled.div`
  overflow: hidden;
  position: relative;
`

const ButtonDiv = styled.div`
  position: absolute;
  left: 50px;
  z-index: 500;
  display: contents;

  @media (max-width: 600px) {
    position: relative;
    top: 0;
    left: 0;
  }
`

const HeaderWrapper = styled.div`
  position: -webkit-sticky;
  position: sticky;
  top: 6rem;
  background: white;
  z-index: 1;
  @media screen and (max-width: 400px) {
    top: 5rem;
  }
`

const ComparePlansPage = () => {
  const [cardDetailsInOrder, setCardDetailsInOrder] = React.useState<
    (MSPlan | undefined)[]
  >([])
  const [mnsCardDetailsInOrder, setMNSCardDetailsInOrder] =
    React.useState<MNSCompletePlan>()

  const [state, setState] = React.useState({
    loading: false,
  })

  const handleHeaderScroll = () => {
    const source = document.getElementsByClassName('scrollSource')[0]
    const target = document.getElementsByClassName('scrollTarget')
    for (let i = 0; i < target.length; i++) {
      target[i].scrollLeft = source.scrollLeft
      target[i].scrollTop = source.scrollTop
    }
  }

  React.useEffect(() => {
    window.scroll(0, 0)
    if (userActionStore.comparePlanCards.compareCardsData.length !== 0) {
      setState({
        ...state,
        loading: true,
      })
      const medicareQuoteType = userActionStore.comparePlanCards.planType
      if (medicareQuoteType !== MedicareQuotesType.MEDICARE_SUPPLEMENT) {
        const planIds = userActionStore
          .getComparePlanCards()
          .compareCardsData.map((iterator) => iterator.sunfirePlanId)

        ApiManager.makeApiCallWithAuthentication(
          UrlConstants.RETRIEVE_MNS_COMPARE_PLAN_DETAILS.USECASE,
          HttpMethods.POST_METHOD,
          {
            planIds,
          }
        )
          .then((response) => {
            const responseData = response.data.data
            if(!isUndefined(responseData))
              setMNSCardDetailsInOrder(responseData ? responseData : {})
            else
              throw new Error('Response is undefined')
          })
          .catch((err) => {
            snackbarStore.set({
              snackbarMessage: getApiErrorMessage('fetch MNS plan details'),
              snackbarOpen: true,
              snackbarType: 'error',
            })
          })
          .finally(() => {
            setState({
              ...state,
              loading: false,
            })
          })
      } else {
        const planIds = userActionStore
          .getComparePlanCards()
          .compareCardsData.map((iterator) => iterator.id)
        ApiManager.makeApiCallWithAuthentication(
          UrlConstants.RETRIEVE_COMPARE_PLAN_DETAILS.USECASE,
          HttpMethods.POST_METHOD,
          {
            medicareQuoteType,
            forSpouse: false,
            planIds,
          }
        )
          .then((response) => {
            // Get plan details in the same order as userActionStore.comparePlanCards
            const responseData: {
              msPlan: MSPlan
              msPlanGenericDetails: MSPlanGenericDetails[]
            }[] = response.data.data

            if(!isUndefined(responseData)) {
              const cardsArray =
                userActionStore.comparePlanCards.compareCardsData.map(
                  (iterator) => {
                    const card = responseData.find(
                      (itr) => itr.msPlan.id === iterator.id
                    )
                    if (card)
                      card.msPlan.msPlanGenericDetails =
                        card?.msPlanGenericDetails

                    return card?.msPlan
                  }
                )
              setCardDetailsInOrder(cardsArray ? cardsArray : [])
            }
            else
              throw new Error('Response is undefined')
          })
          .catch((err) => {
            snackbarStore.set({
              snackbarMessage: getApiErrorMessage('fetch plan details'),
              snackbarOpen: true,
              snackbarType: 'error',
            })
          })
          .finally(() => {
            setState({
              ...state,
              loading: false,
            })
          })
      }
    } else {
      console.log('loading plan details')
    }
  }, [userActionStore.comparePlanCards.compareCardsData])

  const navigate = useNavigate()

  const handleBack = () => {
    navigate(-1)
  }

  return (
    <LayoutComponent>
      <Container>
        <HeaderWrapper>
          <ButtonDiv>
            <Button variant='text' width='100px' onClick={handleBack}>
              <span style={{ display: 'flex', alignItems: 'center' }}>
                <Icon childComponent={<FiChevronLeft />} />
                Back
              </span>
            </Button>
          </ButtonDiv>
          <CarouselHeaderWrapper>
            <CarouselHeader
              className='scrollSource'
              handleScroll={handleHeaderScroll}
              loading={state.loading}
              cardDetailsInOrder={
                userActionStore.comparePlanCards.planType ===
                MedicareQuotesType.MEDICARE_SUPPLEMENT
                  ? cardDetailsInOrder
                  : mnsCardDetailsInOrder
              }
              planType={userActionStore.comparePlanCards.planType}
            />
          </CarouselHeaderWrapper>
          {state.loading && <SkeletonCard />}
        </HeaderWrapper>

        {!state.loading &&
          userActionStore.comparePlanCards.planType ===
            MedicareQuotesType.MEDICARE_ADVANTAGE && (
            <MedicareAdvantageTable
              cardDetailsInOrder={mnsCardDetailsInOrder as MNSCompletePlan}
            />
          )}

        {!state.loading &&
          userActionStore.comparePlanCards.planType ===
            MedicareQuotesType.MEDICARE_SUPPLEMENT && (
            <MedicareSupplementTable
              cardDetailsInOrder={cardDetailsInOrder as (MSPlan | undefined)[]}
            />
          )}

        {!state.loading &&
          userActionStore.comparePlanCards.planType ===
            MedicareQuotesType.MEDICARE_PRESCRIPTION && (
            <MedicarePartDTable
              cardDetailsInOrder={mnsCardDetailsInOrder as MNSCompletePlan}
            />
          )}
      </Container>
    </LayoutComponent>
  )
}

export default SecureComponent(observer(ComparePlansPage))
