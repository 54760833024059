import { BsCheckCircleFill } from 'react-icons/bs'
import Button from '../common/Button/Button.component'
import { Link } from 'react-router-dom'
import RouteConstants from '../../constants/RouteConstants'
import styled from '@emotion/styled'
import theme from '../../global/theme'

const Container = styled.div`
  width: 350px;
  text-align: center;
  h3 {
    color: ${theme.colors.textDark};
  }

  p {
    padding: 30px 0px;
  }

  @media screen and (max-width: 550px) {
    width: 300px;
  }

  @media screen and (max-width: 355px) {
    width: 275px;
  }
`

const ButtonContainer = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
`

const TickIcon = styled(BsCheckCircleFill)`
  color: ${theme.colors.success};
  font-size: 48px;
  margin-bottom: 20px;
`

const EmailSent = () => {
  return (
    <Container>
      <TickIcon />
      <h3>
        We have sent you a mail
        <br /> with the password reset link.
      </h3>
      <p>Don’t forget to check your spam folder</p>
      <ButtonContainer to={RouteConstants.LOGIN}>
        <Button width='100%' color='secondary' variant='contained' bold={false} description='Reset Password Link sent to the User'>
          Done
        </Button>
      </ButtonContainer>
    </Container>
  )
}

export default EmailSent
