import Button from '../../common/Button/Button.component'
import InputField from '../../common/InputField/InputField.component'
import MaskedInput from 'react-maskedinput'
import { MedicareQuotesType } from '../../../enums/MedicareQuoteTypeEnum'
import RadioGroup from '../../common/RadioGroup/RadioGroup.component'
import React from 'react'
import StringConstants from '../../../constants/StringConstants'
import ValidationUtils from '../../../utils/validation/ValidationUtils'
import customerStore from '../../../datastore/CustomerStore'
import { fireEvent } from '../../../cra'
import styled from '@emotion/styled'
import theme from '../../../global/theme'

interface ModelProps {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  onEnroll: () => void
  planName: string
  planType: MedicareQuotesType
  planId: string
  buttonLoading: boolean
  preferredPhoneNumber?: string
  setPreferredPhoneNumber?: (data: string) => void
}

const Container = styled.div`
  width: 600px;
  min-height: 220px;

  h2 {
    color: #272f69;
    padding-bottom: 24px;
  }
  p {
    margin-bottom: 16px;
    font-weight: 600;
    font-size: 16px;
    color: ${theme.colors.textDark};
    div {
      font-size: 18px;
    }
  }

  div {
    display: flex;
    justify-content: space-between;
  }

  @media screen and (max-width: 750px) {
    width: 320px;
    div {
    }
    .modalButtonContainer {
      display: contents;
    }
  }

  @media screen and (max-width: 380px) {
    width: 290px;
  }

  @media screen and (min-width: 501px) and (max-width: 750px) {
    padding-top: 3px;
    margin: -20px -30px;
  }

  @media screen and (max-width: 500px) {
    padding-top: 3px;
  }

  @media screen and (min-width: 751px) {
    padding-top: 23px;
    padding-bottom: 20px;
    padding-right: 27px;
    padding-left: 31px;
    margin: -40px -50px;
  }
`
const ModalButton = styled.div`
  width: 150px;
  text-decoration: none;

  @media screen and (max-width: 750px) {
    width: 100%;
  }
`

const ContactText = styled.div`
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
  margin-bottom: 10px;

  @media screen and (max-width: 750px) {
    margin-bottom: 0px;
  }
`
const ContactContainer = styled.div`
  .highlight {
    color: ${theme.colors.primary};
    font-size: 16px;
    font-weight: 700;
    text-decoration: none;
    margin-bottom: 6px;
  }
  @media screen and (max-width: 750px) {
    margin-bottom: 0px;
    .highlight {
      padding-top: 6px;
    }
  }
`
const Label = styled.h4`
  font-size: 16px;
  color: ${theme.colors.textGrey};
  padding-bottom: 5px;
  font-weight: 600;
  @media screen and (max-width: 750px) {
    padding: 5px 0px;
  }
`
const PhoneContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;

  p {
    font-weight: 400;
    margin-bottom: -4px;
  }
`
const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
`
const EnrollmentRequestConfirmation: React.FC<ModelProps> = ({
  setOpen,
  onEnroll,
  planName,
  planType,
  planId,
  buttonLoading,
  preferredPhoneNumber,
  setPreferredPhoneNumber,
}) => {
  const formatPhoneNumber = (phoneNumber: string): string => {
    phoneNumber = phoneNumber.replace('+1', '')
    const areaCode = phoneNumber.slice(0, 3)
    const firstPart = phoneNumber.slice(3, 6)
    const secondPart = phoneNumber.slice(6)

    return `(${areaCode}) ${firstPart}-${secondPart}`
  }
  const [isPreferredNumber, setIsPreferredNumber] =
    React.useState<boolean>(false)
  const [hasPreferredNumber, setHasPreferredNumber] = React.useState<string>('')
  const [preferredPhoneValue, setPreferredPhoneValue] =
    React.useState<string>('')
  const leadPhoneNumber = customerStore.get().phone.replace('+1', '')
  const formattedLeadPhoneNumber = formatPhoneNumber(customerStore.get().phone)
  const [state, setState] = React.useState({
    alternatePhoneError: false,
    alternatePhoneHelperText: '',
  })
  const validatePreferredPhone = (alternatePhone: string) => {
    var alternatePhoneError = true
    let conditionalStateData = {}
    ValidationUtils.verifyPhoneNumber(alternatePhone)
      .then((response: any) => {
        if (response.data.data.status === 'valid') {
          alternatePhoneError = false
          setState({
            ...state,
            alternatePhoneError:
              alternatePhoneError || leadPhoneNumber === alternatePhone,
            alternatePhoneHelperText: alternatePhoneError
              ? StringConstants.UI_ERROR_MSG_FOR_INVALID_ALTERNATE_PHONE
              : leadPhoneNumber === alternatePhone
              ? StringConstants.UI_ERROR_MSG_FOR_PREFERRED_PHONE_SAME_AS_PHONE
              : '',
            ...conditionalStateData,
          })
        }
      })
      .catch((err: Error) => {
        console.log(err)
      })
    setState({
      ...state,
      alternatePhoneError:
        alternatePhoneError || leadPhoneNumber === alternatePhone,
      alternatePhoneHelperText: alternatePhoneError
        ? StringConstants.UI_ERROR_MSG_FOR_INVALID_ALTERNATE_PHONE
        : leadPhoneNumber === alternatePhone
        ? StringConstants.UI_ERROR_MSG_FOR_PREFERRED_PHONE_SAME_AS_PHONE
        : '',
      ...conditionalStateData,
    })
  }

  const handlePreferredPhoneChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    let alternatePhone = e.target.value.replace(/[- )( _]/g, '')
    validatePreferredPhone(alternatePhone)
    setPreferredPhoneValue(alternatePhone)
    setPreferredPhoneNumber && setPreferredPhoneNumber(alternatePhone)
  }

  const handlePreferenceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setHasPreferredNumber(e.target.value)
    e.target.value === 'Yes'
      ? setIsPreferredNumber(false)
      : e.target.value === 'No'
      ? setIsPreferredNumber(true)
      : setIsPreferredNumber(false)
  }

  return (
    <Container>
      <h2>Let's get you started!</h2>
      <ContactText>
        We want to make sure we contact you at the right number. Is your
        existing phone number the preferred one for us to call?
      </ContactText>
      <ContactContainer>
        <p className='highlight'>{formattedLeadPhoneNumber}</p>
      </ContactContainer>
      <RadioGroup
        radioButtons={['Yes', 'No']}
        row={true}
        value={hasPreferredNumber}
        type='unstyled'
        radioGroupName='Gender'
        onChange={handlePreferenceChange}
        width='30%'
        extraPaddingBottom='5px'
        disabledValues={buttonLoading ? ['Yes', 'No'] : []}
      />
      {isPreferredNumber && (
        <PhoneContainer>
          <Label>Enter your Preferred Phone Number</Label>
          <InputContainer>
            <InputField
              error={state.alternatePhoneError}
              helperText={state.alternatePhoneHelperText}
              width='90%'
              label='Phone Number'
              value={preferredPhoneValue}
              onChange={handlePreferredPhoneChange}
              inputComponent={MaskedInput}
              inputProps={{ mask: '(111) 111-1111' }}
              shrink={preferredPhoneNumber ? true : undefined}
              placeholder='(123) 456-7890'
              disabled={buttonLoading}
            />
          </InputContainer>
        </PhoneContainer>
      )}
      <p>
        Hit "Continue" to confirm your interest in{' '}
        {planType === MedicareQuotesType.MEDICARE_SUPPLEMENT
          ? 'applying'
          : 'enrolling'}
        {' for '}
        <div>
          <b>{planName}!</b>
        </div>
      </p>
      <div className={'modalButtonContainer'}>
        <ModalButton>
          <Button
            variant='contained'
            bold={false}
            width='100%'
            color='secondary'
            onClick={(e) => {
              fireEvent(
                planType === MedicareQuotesType.MEDICARE_SUPPLEMENT
                  ? 'confirmApply'
                  : 'confirmEnroll',
                e,
                {
                  description: `Confirmed ${
                    planType === MedicareQuotesType.MEDICARE_SUPPLEMENT
                      ? 'Application'
                      : 'Enrollment'
                  } in ${planName}`,
                  planType: planType,
                  planName: planName,
                  planId: planId,
                }
              )
              onEnroll()
            }}
            loading={buttonLoading}
            disabled={
              buttonLoading ||
              hasPreferredNumber === '' ||
              (hasPreferredNumber === 'No' &&
                (preferredPhoneNumber === '' ||
                  state.alternatePhoneError ||
                  preferredPhoneValue === ''))
            }
            fontSize='14px'
            description={`Requested ${
              planType === MedicareQuotesType.MEDICARE_SUPPLEMENT
                ? 'Application'
                : 'Enrollment'
            } in ${planName}`}
            id={`confirmEnroll ${planName}`}
          >
            Continue
          </Button>
        </ModalButton>

        <ModalButton>
          <Button
            color='info'
            bold={false}
            width='100%'
            onClick={(e) => {
              fireEvent(
                planType === MedicareQuotesType.MEDICARE_SUPPLEMENT
                  ? 'rejectApply'
                  : 'rejectEnroll',
                e,
                {
                  description: `Rejected ${
                    planType === MedicareQuotesType.MEDICARE_SUPPLEMENT
                      ? 'Application'
                      : 'Enrollment'
                  } in ${planName}`,
                  planType: planType,
                  planName: planName,
                  planId: planId,
                }
              )
              setOpen(false)
            }}
            disabled={buttonLoading}
            fontSize='14px'
            description={`Cancelled ${
              planType === MedicareQuotesType.MEDICARE_SUPPLEMENT
                ? 'Application'
                : 'Enrollment'
            } in ${planName}`}
          >
            Cancel
          </Button>
        </ModalButton>
      </div>
    </Container>
  )
}

export default EnrollmentRequestConfirmation
