import React, { useEffect } from 'react'

import ApiManager from '../../../api/ApiManager'
import { Button } from '@mui/material'
import { HttpMethods } from '../../../enums/HttpMethodsEnum'
import InputField from '../../common/InputField/InputField.component'
import { MdLocationPin } from 'react-icons/md'
import PaginationConstants from '../../../constants/PaginationConstants'
import { PharmacyObj } from '../../../interface/PharmacyInterface'
import { SnackbarTypes } from '../../../enums/SnackbarTypesEnum'
import { ThreeDots } from 'react-loader-spinner'
import UrlConstants from '../../../constants/UrlConstants'
import { getApiErrorMessage } from '../../../utils/StringUtils'
import snackbarStore from '../../../datastore/SnackbarStore'
import { sortBy } from 'lodash'
import styled from '@emotion/styled'
import theme from '../../../global/theme'
import customerStore from '../../../datastore/CustomerStore'

interface ModelProps {
  handleSearchClick?: (item: object, page: number) => void
  radius: number
  zipCode: string
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  setPharmacyList: React.Dispatch<React.SetStateAction<PharmacyObj[]>>
  radiusOptions: string[]
  selectNextRadiusOption: (selectedRadiusDisplayValue: string) => void
}

const Container = styled.div`
  width: 500px;
  margin-bottom: -40px;
  overflow-y: scroll;

  h3 {
    color: #272f69;
    padding-bottom: 30px;
  }

  @media screen and (max-width: 750px) {
    width: 320px;
    div {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      span {
        width: 200px;
        padding-left: 5px;
      }
    }
  }

  @media screen and (max-width: 380px) {
    width: 290px;
  }

  @media screen and (max-width: 500px) {
    margin-bottom: -20px;
  }
`
const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 1.25em 0;
`
const SearchItem = styled.div`
  border-bottom: 1px solid ${theme.colors.borderColor};
  text-align: left;
  padding: 10px;
  cursor: pointer;
  &:hover {
    background-color: ${theme.colors.contrastPrimary};
  }
`
let items: PharmacyObj[] = []
let page: number = PaginationConstants.DEFAULT_PAGE
let data: any[] = []

const PharmacyListSearch: React.FC<ModelProps> = ({
  handleSearchClick,
  radius,
  zipCode,
  setOpen,
  setPharmacyList,
  radiusOptions,
  selectNextRadiusOption,
}) => {
  const [searchWord, setSearchWord] = React.useState<string>('')
  const [displayData, setDisplayData] = React.useState<any[]>([])
  const [loading, setLoading] = React.useState(false)
  const [count, setCount] = React.useState<number>(5)

  const handleTextChange = (word: string) => {
    if (word === '') {
      setDisplayData(data)
    } else {
      const newFilter = data
        ? data.filter((value) => {
          return value.title.toLowerCase().includes(word.toLowerCase())
        })
        : []
      setDisplayData(newFilter)
    }
  }

  const handleFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
    let word = ''
    if (e.target.value.length >= 0) {
      word = e.target.value
      setSearchWord(e.target.value)
    }
    handleTextChange(word)
  }

  const getPharmacyListFromSF = (page: number) => {
    setLoading(true)
    if (page === 1) items = []
    ApiManager.makeApiCallWithAuthentication(
      UrlConstants.GET_PHARMACY_WITH_ZIP_SF.USECASE,
      HttpMethods.POST_METHOD,
      {
        zip: zipCode,
        radius: radius,
        paginationDetail: {
          page: page,
          size: PaginationConstants.DEFAULT_PHARMACY_LIST_SIZE,
        },
        isSpouse: customerStore.get().forSpouse,
        isMailOrderPharmacy: false
      }
    )
      .then((res) => {
        if (res.status === 200) {
          const newlyPulledPharmacies: PharmacyObj[] = []
          res.data.data.forEach((iterator: any) => {
            if (iterator.name === '') {
              return
            } else {
              const tempData: any = iterator
              tempData.pageNumber = page
              newlyPulledPharmacies.push(tempData)
            }
          })

          const pharmaciesSortedByDistance = sortBy(
            [...items, ...newlyPulledPharmacies],
            function (o: any) {
              return o.distance
            }
          )
          items = pharmaciesSortedByDistance

          let listOfPharmacies = items.map((item: any, index: any) => {

            return {
              index,
              title: item.name,
              subtitle: `${item.address1}${item.address2 ? ', ' + item.address2 : ''
                }, ${item.city}, ${item.state}, ${item.zip}`,
              distance: item.distance,
              page: item.pageNumber,
            }
          })
          setCount(
            Math.ceil(
              res.data.totalCount /
              PaginationConstants.DEFAULT_PHARMACY_LIST_SIZE
            )
          )
          data = listOfPharmacies
          setPharmacyList(items)
          setDisplayData(listOfPharmacies)
          setLoading(false)
          if (searchWord !== '') handleTextChange(searchWord)
        } else throw res
      })
      .catch((err) => {
        snackbarStore.set({
          snackbarOpen: true,
          snackbarMessage: getApiErrorMessage('fetch pharmacy with zip'),
          snackbarType: SnackbarTypes.ERROR,
        })
        setLoading(false)
        setOpen(false)
      })
  }

  useEffect(() => {
    getPharmacyListFromSF(PaginationConstants.DEFAULT_PAGE)
    return () => {
      items = []
      page = PaginationConstants.DEFAULT_PAGE
      data = []
    }
  }, [])

  const handleLoadMore = () => {
    if (page === 1 && isNaN(count)) {
      let selectedRadiusDisplayValue = radius + ' miles'
      for (let cntr = 0; cntr < radiusOptions.length - 1; cntr++) {
        if (
          selectedRadiusDisplayValue === radiusOptions[cntr].toString() &&
          cntr + 1 < radiusOptions.length
        ) {
          selectedRadiusDisplayValue = radiusOptions[cntr + 1].toString()
          radius = +selectedRadiusDisplayValue.replace(' miles', '')
          selectNextRadiusOption(selectedRadiusDisplayValue)
          break
        }
      }
    } else page += 1
    getPharmacyListFromSF(page)
  }

  return (
    <Container>
      <h3>
        Choose a pharmacy
        <div style={{ color: theme.colors.textGrey, fontSize: 12 }}>
          Radius selected: {radius + ' miles'}
        </div>
      </h3>

      <div style={{ width: '100%' }}>
        <InputField
          width='100%'
          onChange={handleFilter}
          placeholder='Start typing your pharmacy name...'
          additionalTextPaddingLeft='15px'
          disabled={loading}
        />
      </div>

      {loading ? (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            alignItems: 'center',
            justifyContent: 'center',
            height: '60vh',
          }}
        >
          <span
            style={{
              fontSize: '14px',
              color: theme.colors.primary,
              paddingBottom: '5px',
              fontWeight: '700',
            }}
          >
            Please wait while we fetch the pharmacies nearby
          </span>
          <ThreeDots color='#222C69' height={80} width={80} />
        </div>
      ) : (
        <div
          style={{
            overflowY: 'auto',
            maxHeight: '60vh',
            borderLeft: `solid 1px ${theme.colors.borderColor}`,
            borderRight: `solid 1px ${theme.colors.borderColor}`,
            borderBottom: `solid 1px ${theme.colors.borderColor}`,
          }}
        >
          {displayData.length > 0 ? (
            displayData.map((item, key) => {
              return (
                <SearchItem
                  onClick={() => {
                    if (handleSearchClick) {
                      handleSearchClick(item, item.page)
                    }
                  }}
                  key={key}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                    }}
                  >
                    <div style={{ maxWidth: '60%' }}>
                      <p
                        style={{
                          fontSize: '14px',
                          color: theme.colors.textDark,
                          paddingBottom: '5px',
                          fontWeight: '700',
                          maxWidth: '300px',
                        }}
                      >
                        {item.title}
                      </p>
                      <p
                        style={{
                          fontSize: '12px',
                          color: theme.colors.textGrey,
                          maxWidth: '300px',
                        }}
                      >
                        {item?.subtitle}
                      </p>
                    </div>
                    <div
                      style={{
                        fontSize: '14px',
                        color: theme.colors.textDark,
                        textAlign: 'right',
                        flexWrap: 'nowrap',
                      }}
                    >
                      <MdLocationPin
                        style={{
                          fontSize: '14px',
                        }}
                      />
                      {' ' + item?.distance.toFixed(2) + ' mi'}
                    </div>
                  </div>
                </SearchItem>
              )
            })
          ) : (
            <SearchItem>
              <p
                style={{
                  fontSize: '14px',
                  color: theme.colors.textDark,
                  paddingBottom: '5px',
                }}
              >
                No location available near you. Please click on Load More to
                expand your search.
              </p>
            </SearchItem>
          )}
        </div>
      )}
      {!loading && (
        <PaginationWrapper>
          <Button
            color='primary'
            variant='contained'
            onClick={handleLoadMore}
            disabled={
              page === count ||
              radius + ' miles' ===
              radiusOptions[radiusOptions.length - 1].toString()
            }
          >
            Load More
          </Button>
        </PaginationWrapper>
      )}
    </Container>
  )
}

export default PharmacyListSearch
