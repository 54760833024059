import {
  addQueryParamsToApiUrl,
  generateUrl,
  updatePathParamsForApiUrl,
} from './UrlGenerator'
import axios, { AxiosResponse } from 'axios'

import { HttpMethods } from '../enums/HttpMethodsEnum'
import StringConstants from '../constants/StringConstants'
import cookie from 'react-cookies'

function makeApiCallWithAuthentication(
  usecase: string,
  httpMethod: HttpMethods,
  data: object = {}
): Promise<AxiosResponse> {
  return makeApiCallInternal(usecase, httpMethod, true, data)
}

function makeApiCall(
  usecase: string,
  httpMethod: HttpMethods,
  data: object = {}
): Promise<AxiosResponse> {
  return makeApiCallInternal(usecase, httpMethod, false, data)
}

function makeApiCallInternal(
  usecase: string,
  httpMethod: HttpMethods,
  authenticatedCall: boolean,
  data: object
): Promise<AxiosResponse> {
  let { url, params } = updatePathParamsForApiUrl(generateUrl(usecase), data)
  let headers = {
    headers: {},
  }

  if (authenticatedCall)
    headers = {
      headers: {
        Authorization: 'Bearer ' + cookie.load(StringConstants.COOKIE_TOKEN),
      },
    }

  if (usecase === 'VERIFY_PHONE_NUMBER') {
    headers = {
      headers: {
        'x-api-key': process.env.REACT_APP_XVERIFY_API_KEY,
      },
    }
  }
  if (usecase === 'GET_CITY_DETAILS') {
    headers = {
      headers: {
        'x-api-key': process.env.REACT_APP_SUNFIRE_LOCATION_API_KEY
      }
    }
  }

  switch (httpMethod) {
    case HttpMethods.GET_METHOD:
      if (params) {
        url = addQueryParamsToApiUrl(url, params)
      }
      return axios.get(url, headers).catch((err) => {
        return err.response
      })
    case HttpMethods.POST_METHOD:
      return axios.post(url, params, headers).catch((err) => {
        return err.response
      })
    case HttpMethods.PATCH_METHOD:
      return axios.patch(url, params, headers).catch((err) => {
        return err.response
      })
    case HttpMethods.DELETE_METHOD:
      if (params) {
        url = addQueryParamsToApiUrl(url, params)
      }
      return axios.delete(url, headers)
    default:
      throw new Error('No HTTP Method found')
  }
}

const ApiManager = {
  makeApiCallWithAuthentication,
  makeApiCall,
}

export default ApiManager
