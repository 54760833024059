const getParamsFromUrl = (urlSearchParams: string, param: string) => {
  return new URLSearchParams(urlSearchParams).get(param)
}

const getAllParamsFromUrl = (urlSearchParams: string, param: string) => {
  return new URLSearchParams(urlSearchParams).getAll(param)
}

const getAllParamsAsStringFromUrl = (
  urlSearchParams: string,
  omitList: { [key: string]: any }
) => {
  const entries = Array.from(new URLSearchParams(urlSearchParams).entries())
  let result = ''
  let index = 0
  for (var entry of entries) {
    if (omitList.includes(entry[0])) continue
    if (index === 0) result += '?'
    else result += '&'
    result += entry[0] + '=' + entry[1]
    index = index + 1
  }
  return result
}

const getEntriesFromUrl = (urlSearchParams: string) => {
  return new URLSearchParams(urlSearchParams).entries()
}

export {
  getParamsFromUrl,
  getAllParamsAsStringFromUrl,
  getAllParamsFromUrl,
  getEntriesFromUrl,
}
