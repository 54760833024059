import { InputAdornment } from '@mui/material'
import MuiTextField from '@mui/material/TextField'
import React from 'react'
import { fireEvent } from '../../../cra'
import { isEmpty } from 'lodash'
import styled from '@emotion/styled'

interface InputFieldProps {
  placeholder?: string
  label?: string
  width?: string
  fullWidth?: boolean
  size?: 'small' | 'medium'
  color?: 'primary' | 'secondary' | 'info' | undefined
  error?: boolean
  type?: string
  symbol?: string | JSX.Element
  value?: string | number | null
  helperText?: string
  readOnly?: boolean
  onBlur?: (e: any) => void
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  additionalTextPaddingLeft?: string
  disabled?: boolean
  description?: string
  inputProps?: { min?: string; max?: string; mask?: string }
  inputComponent?: any
  shrink?: boolean
  onFocus?: (e: any) => void
}

const TextField = styled(MuiTextField)({
  '.MuiOutlinedInput-root': {
    borderRadius: '30px',
    fontSize: '16px',
    paddingTop: '2px',
    width: '100%',
  },
  '.MuiOutlinedInput-input': {},
  '.MuiInputLabel-root': {
    fontSize: '16px',
  },
  '.MuiInputLabel-shrink': {
    marginInlineStart: '-4px',
  },
})

const InputField: React.FC<InputFieldProps> = ({
  label,
  fullWidth = false,
  size = 'small',
  width,
  color = 'primary',
  error = false,
  type = 'text',
  value,
  symbol = '',
  helperText = '',
  readOnly = false,
  onBlur,
  onChange,
  placeholder,
  additionalTextPaddingLeft,
  disabled,
  description = '',
  inputProps,
  inputComponent,
  shrink,
  onFocus,
}) => {
  const fontSize = 14
  return (
    <TextField
      onChange={onChange}
      onBlur={(e) => {
        fireEvent('textInput', e, {
          description: description || null,
          label: label || e.target.placeholder || null,
          value,
        })
        onBlur && onBlur(e)
      }}
      onKeyDown={(e: any) => {
        if (e.key === 'Enter') {
          e.target.blur()
        }
      }}
      variant='outlined'
      color={color}
      label={label}
      margin='dense'
      size={size}
      value={value}
      error={error}
      helperText={helperText}
      fullWidth={fullWidth}
      type={type}
      placeholder={placeholder}
      disabled={disabled ? disabled : false}
      inputProps={inputProps}
      onFocus={onFocus}
      sx={{
        width: width,
        '@media screen and (max-width: 750px)': {
          '.MuiOutlinedInput-root': {
            paddingLeft: !isEmpty(additionalTextPaddingLeft)
              ? additionalTextPaddingLeft
              : '0px',
          },
        },
      }}
      InputLabelProps={{
        shrink: shrink,
        sx: {
          fontSize: `${fontSize}px`,
        },
      }}
      InputProps={{
        inputComponent: inputComponent,
        startAdornment:
          symbol !== '' ? (
            <InputAdornment
              sx={{
                '@media screen and (max-width: 750px)': {
                  paddingLeft: `${fontSize}px`,
                },
              }}
              position='start'
            >
              {symbol}
            </InputAdornment>
          ) : undefined,
        readOnly: readOnly,
      }}
    />
  )
}

export default InputField
