import FilterChip from '../FilterChip.component.tsx/FilterChip'
import { MdClear } from 'react-icons/md'
import mPartDPlansFilterStore from '../../../../datastore/medicareQuotes/MPartPlanFilterQuotesStore'
import medicareAdvantageFilterQuoteStore from '../../../../datastore/medicareQuotes/MedicareAdvantageFilterQuoteStore'
import msPlanFiltersStore from '../../../../datastore/medicareQuotes/MSupplementPlanFiltersStore'
import styled from '@emotion/styled'

const TopBox = styled.div`
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  padding: 10px 0px 10px 10px;
  row-gap: 12px;
  width: 100%;
  max-width: 750px;
  height: 47px;
  background: #ffffff;
  border: 1px solid #515151;
  border-radius: 5px 0px 0px 5px;
  margin-bottom: 10px;
  color: #cccccc;

  &::-webkit-scrollbar {
    height: 2px;
  }

  @media screen and (max-width: 750px) {
    display: flex;
    flex-wrap: wrap;
    overflow-y: auto;
  }
`

const ClearBox = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  padding: 10px 15px;
  max-width: 181px;
  height: 47px;
  cursor: pointer;
  background: #ffffff;
  border-width: 1px 1px 1px 1px;
  border-style: solid;
  border-color: #222c69;
  border-radius: 0px 5px 5px 0px;
`

const ClearText = styled.p`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 25px;
  color: #222c69;
  white-space: nowrap;
`

const ClearIcon = styled.div`
* Vector */

position: absolute;
left: 29.17%;
right: 29.17%;
top: 29.17%;
bottom: 29.17%;
margin: 0px 0px 0px 0px;
padding:5px

`

const FixedContainer = styled.div`
  display: flex;
`

interface ContainerProps {
  greyOut: boolean
}

const Container = styled.div<ContainerProps>`
  opacity: ${({ greyOut }) => (greyOut ? 0.5 : 1)};
  pointer-events: ${({ greyOut }) => (greyOut ? 'none' : 'auto')};
`

interface FilterBoxProps {
  clearButtonState: () => void
  selectedFilters: string[]
  updateFilters: (data: string) => any
}

const FilterBox = ({
  clearButtonState,
  selectedFilters,
  updateFilters,
}: FilterBoxProps) => {
  return (
    <Container
      greyOut={
        msPlanFiltersStore.filterApplied ||
        mPartDPlansFilterStore.filterApplied ||
        medicareAdvantageFilterQuoteStore.filterApplied
      }
    >
      <FixedContainer key={Math.random()}>
        <TopBox>
          {selectedFilters.length > 0
            ? selectedFilters.map((filter, index) => (
                <FilterChip
                  text={filter}
                  updateFilters={updateFilters}
                  key={index}
                />
              ))
            : null}
        </TopBox>
        <ClearBox onClick={clearButtonState}>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <ClearText>Clear Filters</ClearText>
            <ClearIcon>
              <MdClear style={{ color: '#222C69' }} />
            </ClearIcon>
          </div>
        </ClearBox>
      </FixedContainer>
    </Container>
  )
}

export default FilterBox
