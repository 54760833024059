import React from 'react'
import Skeleton from '@mui/material/Skeleton'
import styled from '@emotion/styled'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 2rem;
  justify-content: space-between;
`

const CustomSkeleton = styled(Skeleton)`
  border-radius: 8px;
`

const SkeletonCard: React.FC<{
  height?: number
}> = ({ height = 200 }) => {
  return (
    <Container>
      <CustomSkeleton
        variant='rectangular'
        width='100%'
        height={height}
        sx={{ marginBottom: '20px' }}
      />
      <CustomSkeleton
        variant='rectangular'
        width='100%'
        height={height}
        sx={{ marginBottom: '20px' }}
      />
      <CustomSkeleton
        variant='rectangular'
        width='100%'
        height={height}
        sx={{ marginBottom: '20px' }}
      />
    </Container>
  )
}

export default SkeletonCard
