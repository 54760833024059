import { deleteToken, isAuthenticatedUser } from '../../utils/AuthUtils'

import { ActionContext } from '../../cra'
import LayoutComponent from '../../components/common/Layout/Layout.component'
import LoginForm from '../../components/LoginPage/LoginForm.component'
import React from 'react'
import RouteConstants from '../../constants/RouteConstants'
import currentPlanStore from '../../datastore/CurrentPlanStore'
import customerStore from '../../datastore/CustomerStore'
import mPartDPlansFilterStore from '../../datastore/medicareQuotes/MPartPlanFilterQuotesStore'
import medicareAdvantageFilterQuoteStore from '../../datastore/medicareQuotes/MedicareAdvantageFilterQuoteStore'
import msPlanFiltersStore from '../../datastore/medicareQuotes/MSupplementPlanFiltersStore'
import prescriptionStore from '../../datastore/PrescriptionStore'
import snackbarStore from '../../datastore/SnackbarStore'
import { useNavigate } from 'react-router-dom'
import userActionStore from '../../datastore/UserActionStore'

const LoginPage: React.FC = () => {
  const navigate = useNavigate()

  const { trackCurrentPage } = React.useContext(ActionContext)

  React.useEffect(() => {
    if (isAuthenticatedUser()) {
      navigate(RouteConstants.DASHBOARD, { replace: true })
    } else {
      deleteToken()
      customerStore.reset()
      userActionStore.reset()
      snackbarStore.reset()
      prescriptionStore.reset()
      currentPlanStore.reset()
      medicareAdvantageFilterQuoteStore.clearStore()
      medicareAdvantageFilterQuoteStore.clearMAPlanRequestFilters()
      msPlanFiltersStore.clearMSPlanFilterStore()
      msPlanFiltersStore.clearMSPlanRequestFilters()
      mPartDPlansFilterStore.clearStore()
      mPartDPlansFilterStore.clearMPartDPlanRequestFilters()
      localStorage.clear()
      sessionStorage.clear()
    }

    trackCurrentPage('Login Page')
  }, [navigate, trackCurrentPage])

  return (
    <LayoutComponent>
      <LoginForm />
    </LayoutComponent>
  )
}

export default LoginPage
