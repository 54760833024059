import { County } from '../interface/CountyInterface'
import axios from 'axios'
import { isEmpty } from 'lodash'

const GetCountyForZip = (zipCode: string) => {
  return new Promise((resolve, reject) => {
    if (zipCode) {
      axios
        .get(
          `https://us-zipcode.api.smartystreets.com/lookup?zipcode=${zipCode}&key=27259743547683152`
        )
        .then((response: any) => {
          if (response.data[0].status !== 'invalid_zipcode') {
            resolve(response.data[0])
          } else throw response
        })
        .catch((error: any) => {
          console.error('Invalid Zipcode ', error.data)
          reject(error)
        })
    } else reject('zipCode is empty')
  })
}

const GetCountyCityAndStateForZip = (
  zipCode: string
): Promise<{
  counties: County[]
  city: string
  state: string
}> => {
  return new Promise((resolve, reject) => {
    let counties: County[] = [],
      city: string = '',
      state: string = ''
    GetCountyForZip(zipCode)
      .then((response: any) => {
        if (!isEmpty(response)) {
          if (response.zipcodes && response.zipcodes.length > 0) {
            response.zipcodes.forEach((zipObject: any) => {
              if (!isEmpty(zipObject.default_city))
                city = zipObject.default_city
              if (!isEmpty(zipObject.state))
                state = zipObject.state_abbreviation
              const isCountyNamePresent: boolean = counties.some(
                (county) => county.countyName === zipObject.county_name
              )
              if (!isEmpty(zipObject.county_name) && !isCountyNamePresent) {
                counties.push({
                  countyFips: zipObject.county_fips,
                  countyName: zipObject.county_name,
                })
              }
              if (
                zipObject.alternate_counties &&
                zipObject.alternate_counties.length > 0
              ) {
                zipObject.alternate_counties.forEach((altCounties: any) => {
                  if (
                    !isEmpty(altCounties.county_name) &&
                    !isCountyNamePresent
                  ) {
                    counties.push({
                      countyFips: altCounties.county_fips,
                      countyName: altCounties.county_name,
                    })
                  }
                })
              }
            })
            resolve({
              counties,
              city,
              state,
            })
          }
          reject('Invalid Zip code')
        } else {
          reject('Zip code is empty')
        }
      })
      .catch((error: any) => {
        reject(error)
      })
  })
}

const ZipUtils = {
  GetCountyCityAndStateForZip,
  GetCountyForZip,
}

export default ZipUtils
