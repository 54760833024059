import React from 'react'
import styled from '@emotion/styled'
import theme from '../../global/theme'

interface TableColumnProps {
  alignItems?: string
}

interface EditColumnWrapperProps {
  alignItems?: string
}

const ColumnWrapper = styled.div<EditColumnWrapperProps>`
  padding: 10px 40px;
  display: flex;
  align-items: ${(props) => (props.alignItems ? props.alignItems : 'center')};
  justify-content: center;
  border-left: 1px solid ${theme.colors.borderColor};
  border-right: 1px solid ${theme.colors.borderColor};
  text-align: center;
  word-wrap: break-word;
  flex-wrap: wrap;
  max-width: 760px;
  min-width: 350px;
  flex: 1;
  flex-direction: column;
  @media screen and (max-width: 650px) {
    min-width: 250px;
  }
`

const TableColumn: React.FC<TableColumnProps> = ({ children, alignItems }) => {
  return <ColumnWrapper alignItems={alignItems}>{children}</ColumnWrapper>
}

export default TableColumn
