import MuiTooltip, {
  TooltipProps as MuiTooltipProps,
  tooltipClasses,
} from '@mui/material/Tooltip'

import { styled as MuiStyled } from '@mui/material/styles'
import React from 'react'
import { fireEvent } from '../../../cra'
import styled from '@emotion/styled'
import theme from '../../../global/theme'

interface TooltipProps {
  title: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal
  content?: any
  placement?:
  | 'bottom-end'
  | 'bottom-start'
  | 'bottom'
  | 'left-end'
  | 'left-start'
  | 'left'
  | 'right-end'
  | 'right-start'
  | 'right'
  | 'top-end'
  | 'top-start'
  | 'top'
}

const StyledTooltip = MuiStyled(({ className, ...props }: MuiTooltipProps) => (
  <MuiTooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
    maxWidth: '160px',
  },
}))

const Span = styled.span<{
  isContent?: boolean
}>`
  margin-left: 6px;
  // padding: 1px 6.5px;
  width: ${(props) => !props.isContent ? `18px !important` : 'none'};
  height: 18px;
  border: ${(props) => !props.isContent ? `1px solid ${theme.colors.primary}` : 'none'};
  border-radius: ${(props) => !props.isContent ? `18px` : `none`};
  font-size: 12px;
  color: ${theme.colors.primary};
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight:700;
`

const Tooltip: React.FC<TooltipProps> = ({ title, placement = 'right', content = '?' }) => {
  return (
    <StyledTooltip
      title={title}
      placement={placement}
      enterTouchDelay={0}
      leaveTouchDelay={8000}
      sx={{ zIndex: 9999 }}
      onMouseEnter={(e) => {
        fireEvent('hover', e, {
          value: title,
          description: 'Quoting Tool Tooltip',
        })
      }}
    >
      <Span isContent={content !== '?' && content !== 'i'}>{content}</Span>
    </StyledTooltip>
  )
}

export default Tooltip
