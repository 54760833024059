import ApiManager from '../../api/ApiManager'
import { HttpMethods } from '../../enums/HttpMethodsEnum'
import { ScriptSnapshot } from 'typescript'
import USStates from '../../constants/USStates'
import UrlConstants from '../../constants/UrlConstants'
import { isEmpty } from 'lodash'
import moment from 'moment'

const validateEmail = (email: string) => {
  if (isEmpty(email)) return false
  else if (email.length > 79) return false
  else if (
    /^[0-9a-zA-z_]+(?:\.[0-9a-zA-Z_]+)*@[a-zA-Z0-9]{2,}(?:\.[a-zA-Z]{2,})+$/.test(
      email
    )
  )
    return true
  else return false
}

const validateCurrentPassword = (password: string) => {
  if (isEmpty(password)) return false
  else if (password.length > 255) return false
  else if (
    /^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])*(?!.*\s)(?!.*[^a-zA-Z0-9~!@#$?]).{8,}$/.test(
      password
    )
  )
    return true
  else return false
}

const validatePasswordToBeSet = (password: string) => {
  if (isEmpty(password)) return false
  else if (password.length > 255) return false
  else if (
    /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[~!@#$?])(?!.*\s)(?!.*[^a-zA-Z0-9~!@#$?]).{8,}$/.test(
      password
    )
  )
    return true
  else return false
}

const validatePhoneNumber = (phoneNumber: string) => {
  if (isEmpty(phoneNumber)) return false
  else if (phoneNumber.length > 255) return false
  const phoneNumberPattern =
    /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im
  if (phoneNumberPattern.test(phoneNumber)) return true
  return false
}

const validateSsn = (ssn: string) => {
  if (isEmpty(ssn)) return false

  const ssnPattern = /^(?!666|000|9\d{2})\d{3}-(?!00)\d{2}-(?!0{4})\d{4}$/
  const sameConsecutiveSsnPattern = /^(\d)\1\1-\1\1-\1\1\1\1$/
  if (
    ssnPattern.test(ssn) &&
    !sameConsecutiveSsnPattern.test(ssn) &&
    ssn !== '123-45-6789'
  )
    return true
  return false
}

const validateUsername = (name: string) => {
  if (isEmpty(name)) return false
  if (name.length !== 7) return false
  if (/^[a-zA-Z0-9]{7}$/.test(name)) return true
  return false
}

const validateZipcode = (zipcode: string) => {
  if (isEmpty(zipcode)) return false
  if (/(^\d{5}$)/.test(zipcode)) return true
  return false
}

const validateState = (state: string) => {
  const stateInUpper = state.toUpperCase()
  if (isEmpty(stateInUpper)) return false

  if (/^([A-Z]{2})$/.test(stateInUpper)) {
    const usStates = USStates.STATES.map((estado) => estado.abbreviation)
    if (usStates.includes(stateInUpper)) return true
    else return false
  }
  return false
}

const validateCity = (city: string) => {
  if (isEmpty(city)) return false
  else if (city.length > 255) return false
  if (
    /[a-zA-z]{3,}/.test(city) &&
    /^[a-zA-Z\u0080-\u024F]+(?:([\ \-\']|(\.\ ))[a-zA-Z\u0080-\u024F]+)*$/.test(
      city
    )
  )
    return true
  else return false
}

const validateStreet = (street: string) => {
  if (isEmpty(street)) return false
  else if (street.length > 255) return false
  if (/[a-zA-z]{3,}/.test(street)) return true
  else return false
}

const validateFirstName = (firstName: string) => {
  if (isEmpty(firstName)) return false
  else if (firstName.length > 255) return false
  else if (/^(?!'$)(?!''+$)[a-zA-Z0-9' ]+(?<!^')$/.test(firstName)) return true
  else return false
}

const validateLastName = (lastName: string) => {
  if (isEmpty(lastName)) return false
  else if (lastName.length > 255) return false
  else if (/^(?!'$)(?!''+$)[a-zA-Z0-9' ]+(?<!^')$/.test(lastName)) return true
  else return false
}

const validateFullName = (fullName: string) => {
  if (isEmpty(fullName)) return false
  else if (fullName.length > 255) return false
  else if (
    /^(?!')(?!''+$)[a-zA-Z0-9' ]{2,}\s[a-zA-Z0-9' ]{2,}(?<!^')$/.test(fullName)
  )
    return true
  else return false
}

const validateDOB = (dob: string) => {
  if (isEmpty(dob)) return false
  if (dob === 'Invalid Date') return false
  let currentDate = moment().format('YYYY-MM-DD')
  let minDateYear = (moment().year() - 120).toString().substring(0, 2) + '00'
  let minDate = minDateYear + '-01-01'
  let dobDate = moment(moment(dob).format('YYYY-MM-DD'))
  if (dobDate.isAfter(moment(currentDate))) return false
  if (dobDate.isBefore(minDate)) return false
  return true
}

const validateEffectiveDate = (effectiveDate: string) => {
  if (isEmpty(effectiveDate)) return false
  else return true
}

const validateAnticipatedEndDate = (
  anticipatedEndDate: string,
  effectiveDate: string
) => {
  if (isEmpty(anticipatedEndDate)) return false
  if (moment(anticipatedEndDate).isSameOrBefore(moment(effectiveDate)))
    return false
  else return true
}

const validateDrugQuantity = (quantity: string) => {
  if (isEmpty(quantity)) return false
  else if (quantity.length > 2) return false
  else if (/^[1-9][0-9]{0,2}$/.test(quantity)) return true
  else return false
}

const checkDrugQuantity = (quantity: string) => {
  if (/^0+$/.test(quantity) || quantity.startsWith('0')) return true
  else return false
}

const verifyPhoneNumber = (phone: string) => {
  return new Promise((resolve, reject) => {
    if (validatePhoneNumber(phone)) {
      ApiManager.makeApiCallWithAuthentication(
        UrlConstants.VERIFY_PHONE_NUMBER.USECASE,
        HttpMethods.GET_METHOD,
        {
          $phone: '+1' + phone,
        }
      )
        .then((response) => {
          resolve(response)
        })
        .catch((err: Error) => {
          reject(err)
        })
    }
  })
}

const ValidationUtils = {
  validateEmail,
  validateCurrentPassword,
  validatePasswordToBeSet,
  validatePhoneNumber,
  validateUsername,
  validateZipcode,
  validateState,
  validateCity,
  validateStreet,
  validateFirstName,
  validateLastName,
  validateFullName,
  validateDOB,
  validateEffectiveDate,
  validateAnticipatedEndDate,
  validateDrugQuantity,
  verifyPhoneNumber,
  checkDrugQuantity,
  validateSsn,
}

export default ValidationUtils
