import { Table, TableBody, TableContainer } from '@mui/material'

import ApiManager from '../../../../api/ApiManager'
import BottomStepNavigator from '../../BottomStepNavigator.component'
import BouncingDotsLoader from '../../../common/Loading/BouncingDotsLoader'
import Button from '../../../common/Button/Button.component'
import Card from '../../../common/Card/Card.component'
import { CurrentPlanISComponentEnum } from '../../../../enums/CurrentPlanISComponentEnum'
import DateQuestion from './DateQuestion'
import { HttpMethods } from '../../../../enums/HttpMethodsEnum'
import { MdModeEdit } from 'react-icons/md'
import { MedicareConstants } from '../../../../constants/MedicareConstants'
import MuiTableCell from '@mui/material/TableCell'
import MuiTableRow from '@mui/material/TableRow'
import Question from '../Common/Question'
import React from 'react'
import RouteConstants from '../../../../constants/RouteConstants'
import SecureComponent from '../../../../pages/common/SecureComponent'
import SelectAdvantagePlan from './SelectAdvantagePlan'
import SelectAdvantagePlanProvider from './SelectAdvantagePlanProvider'
import SelectGroupOrIHPlanProvider from './SelectGroupOrIHPlanProvider'
import SelectGroupOrIHPlanType from './SelectGroupOrIHPlanType'
import SelectPlanType from './SelectPlanType'
import SelectSupplementPlan from './SelectSupplementPlan'
import SelectSupplementPlanType from './SelectSupplementPlanType'
import { SnackbarTypes } from '../../../../enums/SnackbarTypesEnum'
import StringConstants from '../../../../constants/StringConstants'
import UrlConstants from '../../../../constants/UrlConstants'
import currentPlanStore from '../../../../datastore/CurrentPlanStore'
import customerStore from '../../../../datastore/CustomerStore'
import { getApiErrorMessage } from '../../../../utils/StringUtils'
import { isEmpty } from 'lodash'
import mPartDPlansFilterStore from '../../../../datastore/medicareQuotes/MPartPlanFilterQuotesStore'
import medicareAdvantageFilterQuoteStore from '../../../../datastore/medicareQuotes/MedicareAdvantageFilterQuoteStore'
import moment from 'moment'
import msPlanFiltersStore from '../../../../datastore/medicareQuotes/MSupplementPlanFiltersStore'
import { observer } from 'mobx-react'
import snackbarStore from '../../../../datastore/SnackbarStore'
import styled from '@emotion/styled'
import theme from '../../../../global/theme'
import { useNavigate } from 'react-router-dom'

const Container = styled.div`
  width: 100%;
  padding: 24px 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  @media screen and (max-width: 600px) {
    width: 100%;
  }
`
const SectionWrapper = styled.div`
  width: 100%;
  padding: 10px 0px 30px 0px;
  border-top: 1px solid ${theme.colors.borderColor};
`
const Heading = styled.h3`
  font-size: 20px;
  font-weight: 700px;
  color: ${theme.colors.primary};
`
const Description = styled.p`
  padding: 20px 40px;
  width: 100%;
  font-weight: 600;
  @media screen and (max-width: 600px) {
    padding: 20px 0px;
  }
`

const TableRow = styled(MuiTableRow)`
  &:nth-of-type(odd) {
    background-color: ${theme.colors.contrastPrimary};
  }
`

const DisableText = styled.div`
  font-size: 14px;
  margin-left: 5px;
  color: ${theme.colors.secondary};
`

const TableCell = styled(MuiTableCell)`
  color: ${theme.colors.textGrey};
  font-size: 14px;
  border-bottom: 0px;
  &.MuiTableCell-root {
    width: ${(props) => (props.width ? props.width : '50%')};
  }
`

const CurrentPlan: React.FC = () => {
  const navigate = useNavigate()
  const [disableNext, setDisableNext] = React.useState(true)
  const [currentPlanStep, setCurrentPlanStep] =
    React.useState<CurrentPlanISComponentEnum>(
      CurrentPlanISComponentEnum.QUESTION_1
    )

  const [loading, setLoading] = React.useState(true)

  const [state, setState] = React.useState({
    nextButtonLoading: false,
  })

  const [displayCard, setDisplayCard] = React.useState<boolean>(false)

  const [effectiveDate, setEffectiveDate] = React.useState('')
  const [hasNext, setHasNext] = React.useState<boolean>(true)

  const handleQuestionChange = (question: CurrentPlanISComponentEnum) => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    setCurrentPlanStep(question)
  }

  const disableMainNext = () => {
    setDisableNext(true)
  }
  const enableMainNext = () => {
    setDisableNext(false)
  }

  const handleHasNext = (value: boolean) => {
    setHasNext(value)
  }

  const updateCurrentPlanStep = () => {
    customerStore.setIsProfileUpdating(true)
    setState({
      ...state,
      nextButtonLoading: true,
    })
    ApiManager.makeApiCallWithAuthentication(
      UrlConstants.PATCH_INFO_STEPS.USECASE,
      HttpMethods.PATCH_METHOD,
      {
        $infoStep: 'currentPlan',
      }
    )
      .then((res) => {
        if (res.status === 200) {
          snackbarStore.set({
            snackbarMessage: StringConstants.SNACKBAR_SAVE_SUCCESS_MSG,
            snackbarOpen: true,
            snackbarType: SnackbarTypes.SUCCESS,
          })
          navigate(RouteConstants.DASHBOARD)
        } else throw res
      })
      .catch((err) => {
        snackbarStore.set({
          snackbarMessage: 'Something went wrong',
          snackbarOpen: true,
          snackbarType: 'error',
        })
      })
      .finally(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' })
        customerStore.setIsProfileUpdating(false)
        setState({
          ...state,
          nextButtonLoading: false,
        })
      })
  }

  const handleEdit = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    setDisplayCard(false)
  }

  const getHandleNext = (componentName: CurrentPlanISComponentEnum) => {
    if (
      componentName === CurrentPlanISComponentEnum.QUESTION_4 &&
      (currentPlanStore.get().quoteType ===
        MedicareConstants.INSURANCE_TYPE.AB ||
        currentPlanStore.get().quoteType ===
        MedicareConstants.INSURANCE_TYPE.Medicaid ||
        currentPlanStore.get().quoteType ===
        MedicareConstants.INSURANCE_TYPE.Tricare ||
        currentPlanStore.get().quoteType ===
        MedicareConstants.INSURANCE_TYPE.None ||
        isEmpty(currentPlanStore.quoteType))
    ) {
      return handleCurrentPlanFinish()
    }

    const handlers: Record<CurrentPlanISComponentEnum, () => void> = {
      [CurrentPlanISComponentEnum.QUESTION_1]: () => {},
      [CurrentPlanISComponentEnum.QUESTION_2]: () =>
        handleQuestionChange(CurrentPlanISComponentEnum.QUESTION_4),
      [CurrentPlanISComponentEnum.QUESTION_3]: () =>
        handleQuestionChange(CurrentPlanISComponentEnum.QUESTION_4),
      [CurrentPlanISComponentEnum.QUESTION_4]: () => {
        const quoteType = currentPlanStore.get().quoteType
        switch (quoteType) {
          case MedicareConstants.INSURANCE_TYPE.MA:
            return handleQuestionChange(CurrentPlanISComponentEnum.QUESTION_5)
          case MedicareConstants.INSURANCE_TYPE.MS:
            return handleQuestionChange(CurrentPlanISComponentEnum.QUESTION_7)
          case MedicareConstants.INSURANCE_TYPE.IH:
          case MedicareConstants.INSURANCE_TYPE.Group:
            return handleQuestionChange(CurrentPlanISComponentEnum.QUESTION_9)
          default:
            return handleQuestionChange(CurrentPlanISComponentEnum.QUESTION_10)
        }
      },
      [CurrentPlanISComponentEnum.QUESTION_5]: () =>
        handleQuestionChange(CurrentPlanISComponentEnum.QUESTION_6),
      [CurrentPlanISComponentEnum.QUESTION_6]: () => handleCurrentPlanFinish(),
      [CurrentPlanISComponentEnum.QUESTION_7]: () =>
        handleQuestionChange(CurrentPlanISComponentEnum.QUESTION_8),
      [CurrentPlanISComponentEnum.QUESTION_8]: () => handleCurrentPlanFinish(),
      [CurrentPlanISComponentEnum.QUESTION_9]: () =>
        handleQuestionChange(CurrentPlanISComponentEnum.QUESTION_10),
      [CurrentPlanISComponentEnum.QUESTION_10]: () => handleCurrentPlanFinish(),
      [CurrentPlanISComponentEnum.FINISHED]: () => {},
    }

    const handler = handlers[componentName]
    if (handler) {
      return handler()
    }
  }

  const getHandleBack = (componentName: CurrentPlanISComponentEnum) => {
    const handlers: Record<CurrentPlanISComponentEnum, () => void> = {
      [CurrentPlanISComponentEnum.QUESTION_1]: () =>
        navigate(RouteConstants.INFO_STEPS_DOCTORS),
      [CurrentPlanISComponentEnum.QUESTION_2]: () =>
        handleQuestionChange(CurrentPlanISComponentEnum.QUESTION_1),
      [CurrentPlanISComponentEnum.QUESTION_3]: () =>
        handleQuestionChange(CurrentPlanISComponentEnum.QUESTION_1),
      [CurrentPlanISComponentEnum.QUESTION_4]: () =>
        handleQuestionChange(CurrentPlanISComponentEnum.QUESTION_2),
      [CurrentPlanISComponentEnum.QUESTION_5]: () =>
        handleQuestionChange(CurrentPlanISComponentEnum.QUESTION_4),
      [CurrentPlanISComponentEnum.QUESTION_6]: () =>
        handleQuestionChange(CurrentPlanISComponentEnum.QUESTION_5),
      [CurrentPlanISComponentEnum.QUESTION_7]: () =>
        handleQuestionChange(CurrentPlanISComponentEnum.QUESTION_4),
      [CurrentPlanISComponentEnum.QUESTION_8]: () =>
        handleQuestionChange(CurrentPlanISComponentEnum.QUESTION_7),
      [CurrentPlanISComponentEnum.QUESTION_9]: () =>
        handleQuestionChange(CurrentPlanISComponentEnum.QUESTION_4),
      [CurrentPlanISComponentEnum.QUESTION_10]: () => {
        return currentPlanStore.get().quoteType ===
          MedicareConstants.INSURANCE_TYPE.IH ||
          currentPlanStore.get().quoteType ===
          MedicareConstants.INSURANCE_TYPE.Group
          ? handleQuestionChange(CurrentPlanISComponentEnum.QUESTION_9)
          : handleQuestionChange(CurrentPlanISComponentEnum.QUESTION_4)
      },
      [CurrentPlanISComponentEnum.FINISHED]: () => {},
    }

    const handler = handlers[componentName]

    if (handler) {
      return handler()
    }
  }

  const changeComponent = (componentName: CurrentPlanISComponentEnum) => {
    switch (componentName) {
      case CurrentPlanISComponentEnum.QUESTION_1:
        return (
          <Question
            key={CurrentPlanISComponentEnum.QUESTION_1}
            heading='Are you currently enrolled in Medicare Part B?'
            isRequired={true}
            description='Filters may be applied based on your current coverage to showcase plans most suitable for you. You can modify this anytime by removing the filters.'
            handleYes={() =>
              handleQuestionChange(CurrentPlanISComponentEnum.QUESTION_2)
            }
            handleNo={() =>
              handleQuestionChange(CurrentPlanISComponentEnum.QUESTION_3)
            }
            disableMainNext={disableMainNext}
            enableMainNext={enableMainNext}
            question={CurrentPlanISComponentEnum.QUESTION_1}
          />
        )
      case CurrentPlanISComponentEnum.QUESTION_2:
        return (
          <DateQuestion
            key={CurrentPlanISComponentEnum.QUESTION_2}
            question='When did you enroll into Medicare Part B?'
            description='If you aren’t sure, please make an estimate.'
            secondaryFunction={(date: string) => {
              currentPlanStore.setEffectiveDate(
                date ? (date === '' ? null : date) : null
              )
            }}
            dateFieldInputProps={{
              max: moment().add(1, 'year').year().toString() + '-12-31',
            }}
            disableMainNext={disableMainNext}
            enableMainNext={enableMainNext}
            handleHasNext={handleHasNext}
          />
        )
      case CurrentPlanISComponentEnum.QUESTION_3:
        return (
          <DateQuestion
            key={CurrentPlanISComponentEnum.QUESTION_3}
            question='When does your Medicare Part B take effect?'
            description='If you aren’t sure, please make an estimate.'
            secondaryFunction={(date: string) => {
              currentPlanStore.setEffectiveDate(
                date ? (date === '' ? null : date) : null
              )
            }}
            dateFieldInputProps={{
              max: moment().add(1, 'year').year().toString() + '-12-31',
            }}
            disableMainNext={disableMainNext}
            enableMainNext={enableMainNext}
            handleHasNext={handleHasNext}
          />
        )
      case CurrentPlanISComponentEnum.QUESTION_4:
        return (
          <SelectPlanType
            disableMainNext={disableMainNext}
            enableMainNext={enableMainNext}
            handleHasNext={handleHasNext}
          />
        )
      case CurrentPlanISComponentEnum.QUESTION_5:
        return (
          <SelectAdvantagePlanProvider
            disableMainNext={disableMainNext}
            enableMainNext={enableMainNext}
            handleHasNext={handleHasNext}
          />
        )
      case CurrentPlanISComponentEnum.QUESTION_6:
        return (
          <SelectAdvantagePlan
            disableMainNext={disableMainNext}
            enableMainNext={enableMainNext}
            handleHasNext={handleHasNext}
          />
        )
      case CurrentPlanISComponentEnum.QUESTION_7:
        return (
          <SelectSupplementPlanType
            disableMainNext={disableMainNext}
            enableMainNext={enableMainNext}
            handleHasNext={handleHasNext}
          />
        )
      case CurrentPlanISComponentEnum.QUESTION_8:
        return (
          <SelectSupplementPlan
            disableMainNext={disableMainNext}
            enableMainNext={enableMainNext}
            handleHasNext={handleHasNext}
          />
        )
      case CurrentPlanISComponentEnum.QUESTION_9:
        return (
          <SelectGroupOrIHPlanType
            disableMainNext={disableMainNext}
            enableMainNext={enableMainNext}
            handleHasNext={handleHasNext}
          />
        )
      case CurrentPlanISComponentEnum.QUESTION_10:
        return (
          <SelectGroupOrIHPlanProvider
            disableMainNext={disableMainNext}
            enableMainNext={enableMainNext}
            handleHasNext={handleHasNext}
          />
        )
    }
  }

  React.useEffect(() => {
    window.scroll({ top: 0, behavior: 'smooth' })
    currentPlanStore.reset()
    if (customerStore.infoSteps.includes('currentPlan')) {
      navigate(RouteConstants.DASHBOARD, { replace: true })
    } else {
      ApiManager.makeApiCallWithAuthentication(
        UrlConstants.GET_CUSTOMER_DETAILS.USECASE,
        HttpMethods.GET_METHOD
      )
        .then((response) => {
          if (response.status === 200) {
            const data = response.data.data
            if (
              data.currentMedicarePartBDateSelf ||
              data.currentPlanTypeSelf ||
              data.currentProviderSelf ||
              data.currentCoverageSelf
            ) {
              setDisplayCard(true)

              if (data.currentMedicarePartBDateSelf) {
                const parts = data.currentMedicarePartBDateSelf
                  .substring(0, 10)
                  .split('-')
                currentPlanStore.setEffectiveDate(
                  data.currentMedicarePartBDateSelf
                )
                setEffectiveDate(`${parts[1]}-${parts[2]}-${parts[0]}`)
              }
              currentPlanStore.setPlanType(data.currentPlanTypeSelf)
              currentPlanStore.setProvider(data.currentProviderSelf)

              if (data.currentCoverageSelf) {
                currentPlanStore.setQuoteType(data.currentCoverageSelf)
                currentPlanStore.setCoveredByHealthInsurance(true)
              } else {
                currentPlanStore.setCoveredByHealthInsurance(false)
              }
              enableMainNext && enableMainNext()
            }
            customerStore.set(data)

            // Commented by RB
            // snackbarStore.set({
            //   snackbarMessage: StringConstants.SNACKBAR_DATA_FETCH_SUCCESS_MSG,
            //   snackbarOpen: true,
            //   snackbarType: SnackbarTypes.SUCCESS,
            // })
          } else {
            throw response
          }
        })
        .catch((err) => {
          snackbarStore.set({
            snackbarMessage: getApiErrorMessage('fetch customer details'),
            snackbarOpen: true,
            snackbarType: SnackbarTypes.ERROR,
          })
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }, [])

  const handleCurrentPlanFinish = () => {
    customerStore.setIsProfileUpdating(true)
    setState({
      ...state,
      nextButtonLoading: true,
    })
    if (displayCard) {
      ApiManager.makeApiCallWithAuthentication(
        UrlConstants.PATCH_INFO_STEPS.USECASE,
        HttpMethods.PATCH_METHOD,
        {
          $infoStep: 'currentPlan',
        }
      )
        .then((res) => {
          if (res.status === 200) {
            snackbarStore.set({
              snackbarMessage: StringConstants.SNACKBAR_SAVE_SUCCESS_MSG,
              snackbarOpen: true,
              snackbarType: SnackbarTypes.SUCCESS,
            })
            navigate(RouteConstants.DASHBOARD)
          } else throw res
        })
        .catch((err) => {
          snackbarStore.set({
            snackbarMessage: 'Something went wrong',
            snackbarOpen: true,
            snackbarType: 'error',
          })
        })
        .finally(() => {
          customerStore.setIsProfileUpdating(false)
          setState({
            ...state,
            nextButtonLoading: false,
          })
        })
    } else {
      const currentPlan = {
        'for-spouse': customerStore.get().forSpouse,
        effectiveDate: isEmpty(currentPlanStore.get().effectiveDate)
          ? null
          : currentPlanStore.effectiveDate!.substring(0, 10),
        //anticipatedEndDate: currentPlanStore.get().anticipatedEndDate,
        quoteType: currentPlanStore.get().quoteType,
        planType: currentPlanStore.get().planType,
        provider: currentPlanStore.get().provider,
      }

      ApiManager.makeApiCallWithAuthentication(
        UrlConstants.PATCH_SUPPLEMENTAL_QUESTIONS.USECASE,
        HttpMethods.PATCH_METHOD,
        currentPlan
      )
        .then((res) => {
          if (res.status === 200) {
            ApiManager.makeApiCallWithAuthentication(
              UrlConstants.PATCH_INFO_STEPS.USECASE,
              HttpMethods.PATCH_METHOD,
              {
                $infoStep: 'currentPlan',
              }
            )
              .then((res) => {
                if (res.status === 200) {
                  customerStore.setSupplementalQuestionAnswers({
                    currentCoverageSelf: currentPlan.quoteType,
                    currentPlanTypeSelf: currentPlan.planType,
                    currentProviderSelf: currentPlan.provider,
                    currentMedicarePartBDateSelf: currentPlan.effectiveDate,
                  })
                  customerStore.setFiltersInfoStep({
                    coverageSelf: currentPlan.quoteType,
                    planTypeSelf: currentPlan.planType,
                    providerSelf: currentPlan.provider,
                  })
                  medicareAdvantageFilterQuoteStore.clearStore()
                  msPlanFiltersStore.clearMSPlanFilterStore()
                  mPartDPlansFilterStore.clearStore()
                  snackbarStore.set({
                    snackbarMessage: StringConstants.SNACKBAR_SAVE_SUCCESS_MSG,
                    snackbarOpen: true,
                    snackbarType: SnackbarTypes.SUCCESS,
                  })
                  navigate(RouteConstants.DASHBOARD)
                } else throw res
              })
              .catch((err) => {
                snackbarStore.set({
                  snackbarMessage: 'Something went wrong',
                  snackbarOpen: true,
                  snackbarType: 'error',
                })
              })
          } else throw res
        })
        .catch((err) => {
          snackbarStore.set({
            snackbarMessage: getApiErrorMessage(`save plan`),
            snackbarOpen: true,
            snackbarType: 'error',
          })
        })
        .finally(() => {
          customerStore.setIsProfileUpdating(false)
          setState({
            ...state,
            nextButtonLoading: false,
          })
        })
    }
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  return loading ? (
    <BouncingDotsLoader />
  ) : (
    <Container>
      {displayCard ? (
        <SectionWrapper>
          <Heading>Choose your Current Plan</Heading>
          <Description>
            {StringConstants.INFO_STEPS_CURRENT_PLAN_DESCRIPTION}
          </Description>
          <Card cardType='readOnly' borderColor={theme.colors.primary}>
            <TableContainer>
              <Table>
                <TableBody>
                  {customerStore.currentCoverageSelf && (
                    <TableRow key={`TypeOfInsurance`}>
                      <TableCell
                        width='50%'
                        align='right'
                        component='th'
                        scope='row'
                        style={{
                          color: `${theme.colors.textGrey}`,
                          fontWeight: 600,
                        }}
                      >
                        Type of insurance
                      </TableCell>
                      <TableCell
                        width='50%'
                        align='left'
                        style={{ fontWeight: 400 }}
                      >
                        {customerStore.currentCoverageSelf}
                      </TableCell>
                    </TableRow>
                  )}
                  {customerStore.currentMedicarePartBDateSelf && (
                    <TableRow key={`PartBEffectiveDate`}>
                      <TableCell
                        width='50%'
                        align='right'
                        component='th'
                        scope='row'
                        style={{
                          color: `${theme.colors.textGrey}`,
                          fontWeight: 600,
                        }}
                      >
                        Part B Effective Date
                      </TableCell>
                      <TableCell
                        width='50%'
                        align='left'
                        style={{ fontWeight: 400 }}
                      >
                        {effectiveDate}
                      </TableCell>
                    </TableRow>
                  )}
                  {customerStore.currentPlanTypeSelf && (
                    <TableRow key={`Plan`}>
                      <TableCell
                        width='50%'
                        component='th'
                        align='right'
                        scope='row'
                        style={{
                          color: `${theme.colors.textGrey}`,
                          fontWeight: 600,
                        }}
                      >
                        Plan
                      </TableCell>
                      <TableCell
                        width='50%'
                        align='left'
                        style={{ fontWeight: 400 }}
                      >
                        {customerStore.currentPlanTypeSelf}
                      </TableCell>
                    </TableRow>
                  )}
                  {customerStore.currentProviderSelf && (
                    <TableRow key={`Carrier`}>
                      <TableCell
                        width='50%'
                        component='th'
                        align='right'
                        scope='row'
                        style={{
                          color: `${theme.colors.textGrey}`,
                          fontWeight: 600,
                        }}
                      >
                        Carrier
                      </TableCell>
                      <TableCell
                        width='50%'
                        align='left'
                        style={{ fontWeight: 400 }}
                      >
                        <span>{customerStore.currentProviderSelf}</span>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Card>
          <Button
            width='200px'
            margin='0px 10px 0px 0px'
            bold={false}
            onClick={handleEdit}
            variant='contained'
            disabled={
              customerStore.isMAQuotesProcessing ||
              customerStore.isMSQuotesProcessing ||
              customerStore.isMPartDQuotesProcessing
            }
          >
            <>
              Edit
              <MdModeEdit style={{ position: 'relative', left: '50px' }} />
            </>
          </Button>
          {(customerStore.isMAQuotesProcessing ||
            customerStore.isMSQuotesProcessing ||
            customerStore.isMPartDQuotesProcessing) && (
              <DisableText>{StringConstants.DISABLE_BUTTON_MSG}</DisableText>
            )}
        </SectionWrapper>
      ) : currentPlanStep !== CurrentPlanISComponentEnum.FINISHED ? (
        changeComponent(currentPlanStep)
      ) : (
        <>{navigate(RouteConstants.DASHBOARD)}</>
      )}

      <BottomStepNavigator
        handleNext={() => {
          displayCard ? updateCurrentPlanStep() : getHandleNext(currentPlanStep)
        }}
        handleBack={() => {
          window.scrollTo({ top: 0, behavior: 'smooth' })
          getHandleBack(currentPlanStep)
        }}
        handleViewPlans={() => {
          updateCurrentPlanStep()
        }}
        disableNext={disableNext}
        isLoading={state.nextButtonLoading}
        currentInfoStep='Current Plan'
        buttonText3={hasNext ? 'Next' : 'Finish'}
      />
    </Container>
  )
}

export default SecureComponent(observer(CurrentPlan))
