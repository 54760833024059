export interface RiderType {
  [key: string]: string
}

export const RiderTypesEnum: RiderType = {
  MA_CORE: 'Core plan',
  MA_SUPP1:
    'Standard Plan with Part B deductible - Applicable subject to conditions',
  MA_SUPP1A: 'Standard Plan',
  'MN_50%': '50% Plan',
  MN_BASIC: 'Basic Plan',
  MN_HDED: 'High Deductible Base Plan',
  MN_PBCO: 'Part B Copay',
  MN_XBAS: 'Extended Basic Plan',
  MNR_EXCS: 'Part B Excess Rider',
  MNR_PREV: 'Preventive Health Rider',
  MNR_UPRV:
    'Coverage of 100% of Usual and Customary Charges Preventive Care Rider',
  MNR_PTA: 'Part A Deductible Rider',
  MNR_PTB: 'Part B Deductible Rider',
  MNR_UHC1: 'Part A Deductible + Part B Excess + Preventive Care',
  'WI_50%': '50% Cost-Sharing Plan',
  'WI_75%': '25% Cost-Sharing Plan',
  WI_BASE: 'Base Plan',
  WI_HDED: 'Plan HDF',
  'WIR_A50%': '50% Part A Deductible',
  WIR_DBEN: 'Deferred Benefits Rider',
  WIR_EXCS: 'Part B Excess Rider',
  WIR_HH: 'Home Health Care Rider',
  WIR_PBCO: 'Part B Copay',
  WIR_PTA: 'Part A Deductible Rider',
  WIR_PTB: 'Part B Deductible Rider',
  WIR_TVL: 'Foreign Travel Rider',
  WIR_CMB1: 'Basic + Home Health Care',
  WIR_UA2: 'Basic + Part A Deductible + Foreign Travel + Home Health Care',
  WIR_UA3:
    'Basic + Part A Deductible + Foreign Travel + Home Health Care + Part B Excess',
  WIR_UA4:
    'Basic + Part A Deductible + Foreign Travel + Home Health Care + Copay Discount',
  WIR_UHR1: 'Part A Deductible + Part B Excess + Foreign Travel',
  WIR_UHR2: '50% Part A Deductible + Part B Excess + Foreign Travel',
}
