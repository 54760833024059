export class MedicareConstants {
  static INSURANCE_TYPE = {
    MS: 'Medicare Supplement (Medigap)',
    MA: 'Medicare Advantage (HMO, PPO, PFFS)',
    AB: 'Original Medicare A & B Only', // ends planType, carrier
    Group: 'Group',
    Retiree: 'Retiree', // ends planType
    Cobra: 'Cobra', // ends planType
    IH: 'Individual Health',
    Medicaid: 'Medicaid', // ends planType, carrier
    Tricare: 'Tricare', // ends planType, carrier
    None: 'None', // ends planType, carrier
  }

  static getInsuranceTypes = () => [
    MedicareConstants.INSURANCE_TYPE.MS,
    MedicareConstants.INSURANCE_TYPE.MA,
    MedicareConstants.INSURANCE_TYPE.AB,
    MedicareConstants.INSURANCE_TYPE.Group,
    MedicareConstants.INSURANCE_TYPE.Retiree,
    MedicareConstants.INSURANCE_TYPE.Cobra,
    MedicareConstants.INSURANCE_TYPE.IH,
    MedicareConstants.INSURANCE_TYPE.Medicaid,
    MedicareConstants.INSURANCE_TYPE.Tricare,
    MedicareConstants.INSURANCE_TYPE.None,
  ]

  static PLAN_TYPE = {
    // MS
    PF: 'Plan F',
    PFHD: 'Plan F High Deductible',
    PG: 'Plan G',
    PGHD: 'Plan G High Deductible',
    PN: 'Plan N',
    PA: 'Plan A',
    PB: 'Plan B',
    PC: 'Plan C',
    PD: 'Plan D',
    PK: 'Plan K',
    PL: 'Plan L',
    PM: 'Plan M',
    Other: 'Other',
    NotSure: "I'm Not Sure",
    // MA
    HMO: 'HMO (Health Maintenance Organization)',
    PPO: 'PPO (Preferred Provider Organization)',
    PFFS: 'PFFS (Private Fee-For-Service)',
    SNP: 'SNP (Special Needs Plan)',
    // IH & Group
    EPO: 'EPO (Exclusive Provider Organization)',
    POS: 'POS (Point of Service)',
    HSA: 'HSA (Health Savings Account)',
  }

  static getPlanTypesMs = () => [
    MedicareConstants.PLAN_TYPE.PF,
    MedicareConstants.PLAN_TYPE.PFHD,
    MedicareConstants.PLAN_TYPE.PG,
    MedicareConstants.PLAN_TYPE.PGHD,
    MedicareConstants.PLAN_TYPE.PN,
    MedicareConstants.PLAN_TYPE.PA,
    MedicareConstants.PLAN_TYPE.PB,
    MedicareConstants.PLAN_TYPE.PC,
    MedicareConstants.PLAN_TYPE.PD,
    MedicareConstants.PLAN_TYPE.PK,
    MedicareConstants.PLAN_TYPE.PL,
    MedicareConstants.PLAN_TYPE.PM,
    MedicareConstants.PLAN_TYPE.Other,
    MedicareConstants.PLAN_TYPE.NotSure,
  ]

  static getPlanTypesMa = () => [
    MedicareConstants.PLAN_TYPE.HMO,
    MedicareConstants.PLAN_TYPE.PPO,
    MedicareConstants.PLAN_TYPE.PFFS,
    MedicareConstants.PLAN_TYPE.SNP,
    MedicareConstants.PLAN_TYPE.Other,
  ]

  static getPlanTypesIh = () => [
    MedicareConstants.PLAN_TYPE.HMO,
    MedicareConstants.PLAN_TYPE.PPO,
    MedicareConstants.PLAN_TYPE.EPO,
    MedicareConstants.PLAN_TYPE.POS,
    MedicareConstants.PLAN_TYPE.HSA,
    MedicareConstants.PLAN_TYPE.Other,
    MedicareConstants.PLAN_TYPE.NotSure,
  ]

  static CARRIER_LIST = [
    'AARP',
    'Aetna',
    'American Continental',
    'American Retirement Life',
    'Anthem Blue Cross Blue Shield',
    'Assurant',
    'Bankers Fidelity',
    'Bankers Life and Casualty',
    'Blue Cross and Blue Shield',
    'Central States Indemnity',
    'Cigna',
    'Combined Insurance',
    'Continental Life',
    'Coventry Health Care',
    'Devoted',
    'EmblemHealth',
    'Equitable',
    'ForeThought',
    'Gerber',
    'Government Personnel Mutual (GPM)',
    'Health Net',
    'HealthSpring',
    'Heartland National',
    'Highmark',
    'Humana',
    'Individual Assurance Company (IAC)',
    'Kaiser',
    'Loyal American',
    'Manhattan Life',
    'Medico',
    'Molina Healthcare',
    'Mutual of Omaha',
    'New Era',
    'Omaha Insurance',
    'Oxford Life',
    'Philadelphia American',
    'SilverScript',
    'Standard Life and Casualty',
    'State Farm',
    'Stonebridge',
    'Thrivent Financial',
    'TransAmerica',
    'United American',
    'United World',
    'United of Omaha',
    'UnitedHealthcare',
    'Universal American',
    'WellCare',
    'WellPoint',
  ].sort()

  static otherOption = 'Other'
  static noneOption = 'None'
}
