import React, { useEffect } from 'react'
import Select, { SelectChangeEvent } from '@mui/material/Select'

import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import convertMonthYearToDateString from './MonthYearToDateConversionUtils'
import { isEmpty } from 'lodash'
import styled from '@emotion/styled'
import { styled as styledMui } from '@mui/material/styles'

interface SelectMonthYearProps {
  value: string
  resetDate?: boolean
  showValidationError: boolean
  setValidationErrorTrue: () => void
  setValidationErrorFalse: () => void
  handleChange: (e: any) => void
  disabled?: boolean
  isInfoStep?: boolean
}

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

const years: number[] = []

const startYear = new Date().getFullYear() + 1
const currentYear = 1950

for (let i = startYear; i > currentYear - 1; i--) {
  years.push(i)
}

const StyledSelect = styledMui(Select)`
width: 120px;
border-radius: 9999px;
`

const SelectMonthYear: React.FC<SelectMonthYearProps> = ({
  handleChange,
  value,
  showValidationError,
  setValidationErrorTrue,
  setValidationErrorFalse,
  resetDate = false,
  disabled = false,
  isInfoStep = false,
}) => {
  const [month, setMonth] = React.useState('')
  const [year, setYear] = React.useState('')

  const [validations, setValidations] = React.useState([
    isValid(month),
    isValid(year),
  ])

  function isValid(str: string) {
    return str !== ''
  }

  const handleMonthChange = (event: SelectChangeEvent<unknown>) => {
    const selectedMonthValue =
      typeof event.target.value === 'string' ? event.target.value : undefined
    if (selectedMonthValue) {
      setMonth(selectedMonthValue)
      setValidationErrorFalse()
    }
  }
  const handleYearChange = (event: SelectChangeEvent<unknown>) => {
    const selectedYearValue =
      typeof event.target.value === 'number' ? event.target.value : undefined
    if (selectedYearValue) {
      setYear(selectedYearValue.toString())
      setValidationErrorFalse()
    }
  }

  useEffect(() => {
    if (!isEmpty(value)) {
      const data = value.split('-')
      if (data.length === 3) {
        const monthData = Number.parseInt(data[1])
        if (!Number.isNaN(monthData)) setMonth(months[monthData - 1])
        setYear(data[0])
      }
    } else if (isEmpty(value) || disabled) {
      setMonth('')
      setYear('')
    }
  }, [value, disabled])

  useEffect(() => {
    if (resetDate) {
      setMonth('')
      setYear('')
    }
  }, [resetDate])

  useEffect(() => {
    const isSelectedMonthValid = isValid(month)
    const isSelectedYearValid = isValid(year)
    if (isSelectedMonthValid && isSelectedYearValid) {
      handleChange(convertMonthYearToDateString(`${month}-${year}`))
    } else if (isSelectedMonthValid || isSelectedYearValid)
      setValidationErrorTrue()
    setValidations([isSelectedMonthValid, isSelectedYearValid])
  }, [month, year])

  const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: start;
  `

  return (
    <Container>
      <FormControl size='small'>
        <InputLabel
          id='month-simple-select-label'
          sx={{
            color: showValidationError && !validations[0] ? 'red' : 'grey',
          }}
        >
          Month
        </InputLabel>
        <StyledSelect
          labelId='month-select-label'
          error={showValidationError && !validations[0]}
          id={'select-month'}
          value={month}
          label='month'
          onChange={(event) => handleMonthChange(event)}
          disabled={disabled}
          sx={{
            minWidth: 'max-content',
            ...(isInfoStep && {
              '@media screen and (max-width: 800px)': {
                width: '150px',
              },
              '@media screen and (max-width: 380px)': {
                width: '120px',
              },
              '@media screen and (max-width: 320px)': {
                width: '100px',
              },
              width: '200px',
            }),
          }}
        >
          {months.map((m) => (
            <MenuItem key={m} value={m}>
              {m}
            </MenuItem>
          ))}
        </StyledSelect>
        {showValidationError && !validations[0] && (
          <span style={{ color: 'red', paddingTop: '5px' }}>
            {' '}
            Required Field
          </span>
        )}
      </FormControl>
      <div style={{ height: '10px', width: '10px' }} />
      <FormControl size='small'>
        <InputLabel
          id='year-simple-select-label'
          sx={{
            color: showValidationError && !validations[1] ? 'red' : 'grey',
          }}
        >
          Year
        </InputLabel>
        <StyledSelect
          error={showValidationError && !validations[1]}
          labelId='year-select-label'
          id={'select-year'}
          value={year}
          label='year'
          onChange={(event) => handleYearChange(event)}
          disabled={disabled}
          sx={{
            minWidth: 'max-content',
            ...(isInfoStep && {
              '@media screen and (max-width: 800px)': {
                width: '150px',
              },
              '@media screen and (max-width: 380px)': {
                width: '120px',
              },
              '@media screen and (max-width: 320px)': {
                width: '100px',
              },
              width: '200px',
            }),
          }}
        >
          {years.map((y) => (
            <MenuItem key={y} sx={{ height: '100%' }} value={y}>
              {y}
            </MenuItem>
          ))}
        </StyledSelect>
        {showValidationError && !validations[1] && (
          <span style={{ color: 'red', paddingTop: '5px' }}>
            {' '}
            Required Field
          </span>
        )}
      </FormControl>
    </Container>
  )
}

export default SelectMonthYear
