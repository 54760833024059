import DeviceDetector from 'device-detector-js'
import Filter from 'bad-words'
import { deviceDetect } from 'react-device-detect'
import { isEmpty } from 'lodash'

const deviceDetector = new DeviceDetector()
const filter = new Filter()
const isBrowser = typeof window !== 'undefined'
const userAgent = navigator.userAgent
const device = deviceDetect(userAgent)
const FormUtils = {
  cleansePhone: (num: string) => {
    return num.replace(/[^0-9]/g, '')
  },

  getURL: () => {
    return typeof window !== 'undefined' ? window.location.href : ''
  },

  getDeviceDetect: () => {
    return device
  },

  getOS: () => {
    return device.osName || ''
  },

  getOSVersion: () => {
    return device.osVersion || ''
  },

  getBrowserName: () => {
    return device.browserName
  },

  getDeviceDetails: () => {
    let deviceType
    if (isBrowser) {
      deviceType = deviceDetector.parse(
        deviceDetect(userAgent).userAgent
      ).device
      return deviceType
    }
    return deviceType
  },

  getWindow: () => {
    if (isBrowser) {
      return global.window
    }
    return null
  },

  getDocument: () => {
    if (isBrowser) {
      return global.document
    }
    return null
  },

  getAge: (dateString: string) => {
    let today = new Date()
    let birthDate = new Date(dateString)
    let age = today.getFullYear() - birthDate.getFullYear()
    let m = today.getMonth() - birthDate.getMonth()
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--
    }
    return age
  },

  getCurrentDate: () => {
    let today = new Date()
    let dd = String(today.getDate()).padStart(2, '0')
    let mm = String(today.getMonth() + 1).padStart(2, '0')
    let yyyy = today.getFullYear()
    let h = today.getHours()
    let m = today.getMinutes()
    let s = today.getSeconds()

    return mm + '/' + dd + '/' + yyyy + ' - ' + h + ':' + m + ':' + s
  },

  cleanString: (inputString: string) => {
    if (!isEmpty()) return filter.clean(inputString).replaceAll('*', '')
    return inputString
  },

  scrollToTop: () => {
    if (global.window) global.window.scrollTo(0, 0)
  },

  getLeadLandingPage: () => {
    return global.document.location.origin + global.document.location.pathname
  },

  getLeadReferringUrl: () => {
    if (global.document.referrer)
      return new URL(global.document.referrer).hostname
    return null
  },

  isQueryParamEmpty: (queryParamValue: string | null) => {
    if (
      isEmpty(queryParamValue) ||
      queryParamValue === '' ||
      queryParamValue === 'undefined' ||
      queryParamValue === 'null'
    )
      return true;
    return false;
  },
}

export default FormUtils
