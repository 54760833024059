import { DatePicker } from '@mui/x-date-pickers'
import React from 'react'
import styled from '@emotion/styled'

interface DatePickerProps {
  autoFocus?: boolean
  closeOnSelect?: boolean
  defaultCalendarMonth?: any
  defaultCalendarYear?: any
  disableFuture?: boolean
  disablePast?: boolean
  disabled?: boolean
  desktopModeMediaQuery?: string
  disableHighlightToday?: boolean
  disableOpenPickerIcon?: boolean
  format?: string
  formatDensity?: 'spacious' | 'dense' | undefined
  label?: string
  loading?: boolean
  value?: any
  maxDate?: any
  minDate?: any
  onAccept?: (date: any) => void
  onChange?: (date: any) => void
  onClose?: () => void
  openTo?: 'year' | 'day' | 'month' | undefined
  onOpen?: () => void
  readonly?: boolean
  error?: boolean
  errorText?: string
}

const CustomDatePicker = styled(DatePicker)({
  '.MuiOutlinedInput-root': {
    borderRadius: '30px',
    fontSize: '16px',
    marginTop: '0.5rem',
    marginBottom: '0.5rem',
    width: '100%',
  },
  '.MuiOutlinedInput-input': {
    padding: '7.5px 14px',
  },
  '.MuiInputLabel-root': {
    fontSize: '16px',
  },
  '.MuiOutlinedInput-notchedOutline': {
    borderColor: 'rgba(0, 0, 0, 0.23)',
  },
  '.MuiFormHelperText-root': {
    marginTop: '-4px',
  },
  // '.MuiInputLabel-shrink': {
  //   marginInlineStart: '-4px',
  // },
})

const DateField: React.FC<DatePickerProps> = ({
  autoFocus = false,
  closeOnSelect = true,
  defaultCalendarMonth,
  defaultCalendarYear,
  disableFuture,
  desktopModeMediaQuery,
  disablePast = false,
  format,
  formatDensity = 'dense',
  label,
  value,
  maxDate,
  minDate,
  onAccept,
  onChange,
  onClose,
  openTo = 'day',
  onOpen,
  readonly = true,
  error,
  errorText,
}) => {
  return (
    <CustomDatePicker
      value={value}
      disablePast={disablePast}
      disableFuture={disableFuture}
      minDate={minDate}
      maxDate={maxDate}
      onAccept={onAccept}
      defaultCalendarMonth={defaultCalendarMonth}
      format={format}
      openTo={openTo}
      label={label}
      slotProps={{
        textField: {
          readOnly: readonly,
          error: error,
          helperText: error ? errorText : '',
        } as any,
      }}
      onOpen={() => {
        onOpen && onOpen()
        if (window.scrollY < 200) window.scrollTo({ top: 200 })
        // Get the current page scroll position
        window.onscroll = function () {
          window.scrollTo(document.documentElement.scrollLeft, 200)
        }
      }}
      onClose={() => {
        onClose && onClose()
        window.onscroll = function () {}
      }}
      onChange={onChange}
    />
  )
}
export default DateField
