import React, { useEffect, useState } from 'react'

import { MdClear } from 'react-icons/md'
import mPartDPlansFilterStore from '../../../../datastore/medicareQuotes/MPartPlanFilterQuotesStore'
import medicareAdvantageFilterQuoteStore from '../../../../datastore/medicareQuotes/MedicareAdvantageFilterQuoteStore'
import msPlanFiltersStore from '../../../../datastore/medicareQuotes/MSupplementPlanFiltersStore'
import styled from '@emotion/styled'

const Chip = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  width: fit-content;
  gap: 5px;
  justify-content: center;
  align-items: center;
  padding: 4px 12px 4px 4px;
  height: 27px;
  margin: 0px 10px 0px 0px;
  background: #e3eeff;
  border: 0.5px solid #222c69;
`

const ChipText = styled.p`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  max-width: 200px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  /* identical to box height */
  color: #000000;
`

interface FilterChipProps {
  text: string
  updateFilters: (data: string) => any
}

const FilterChip = ({ text, updateFilters }: FilterChipProps) => {
  var updatedText = ''
  const [maCarriers, setMACarriers] = useState<string[]>([])
  const [msCarriers, setMSCarriers] = useState<string[]>([])
  const [mpdCarriers, setMPDCarriers] = useState<string[]>([])
  const [maPlanType, setMAPlanType] = useState<string[]>([])
  const [msPlanType, setMSPlanType] = useState<string[]>([])
  const [maBenefits, setMABenefits] = useState<string[]>([])
  const [amRatings, setAMRatings] = useState<string[]>([])
  const [maDrugs, setMADrugs] = useState<string[]>([])
  const [mpdDrugs, setMPDDrugs] = useState<string[]>([])
  const [maProviders, setMAProviders] = useState<string[]>([])
  const [pdpProviders, setPDPProviders] = useState<string[]>([])

  useEffect(() => {
    const medicareAdvantageFilters =
      medicareAdvantageFilterQuoteStore.getMedicareAdvantageFilters()

    if (medicareAdvantageFilters?.carriers) {
      const carriers = medicareAdvantageFilters.carriers.map(
        (carrier) => carrier.value
      )
      setMACarriers(carriers)
    }
    if (medicareAdvantageFilters?.planTypeCheckbox) {
      const planType = medicareAdvantageFilters.planTypeCheckbox.map(
        (planType) => planType.value
      )
      setMAPlanType(planType)
    }
    if (medicareAdvantageFilters?.providers) {
      const providers = medicareAdvantageFilters.providers.map(
        (provider) => provider.value
      )
      setMAProviders(providers)
    }
    if (medicareAdvantageFilters?.planBenefitsCheckbox) {
      const planBenefits = medicareAdvantageFilters.planBenefitsCheckbox.map(
        (planBenefits) => planBenefits.value
      )
      setMABenefits(planBenefits)
    }
    if (medicareAdvantageFilters?.drugsCheckbox) {
      const drugs = medicareAdvantageFilters.drugsCheckbox.map(
        (drugs) => drugs.value
      )
      setMADrugs(drugs)
    }

    const msPlanFilters = msPlanFiltersStore.getMSPlansFilters()
    if (msPlanFilters?.carriers) {
      const carriers = msPlanFilters.carriers.map((carrier) => carrier.value)
      setMSCarriers(carriers)
    }
    if (msPlanFilters?.planTypes) {
      const planType = msPlanFilters.planTypes.map((planType) => planType.label)
      setMSPlanType(planType)
    }
    if (msPlanFilters?.ambestRatings) {
      const amRating = msPlanFilters.ambestRatings.map(
        (amRating) => amRating.value
      )
      setAMRatings(amRating)
    }
    const mPartDPlansFilters = mPartDPlansFilterStore.getMPartDPlansFilters()
    if (mPartDPlansFilters?.carriers) {
      const carriers = mPartDPlansFilters.carriers.map(
        (carrier) => carrier.value
      )
      setMPDCarriers(carriers)
    }
    if (mPartDPlansFilters?.drugCheckBoxes) {
      const drugs = mPartDPlansFilters.drugCheckBoxes.map(
        (drugs) => drugs.value
      )
      setMPDDrugs(drugs)
    }

    if (mPartDPlansFilters?.providers) {
      const providers = mPartDPlansFilters.providers.map(
        (provider) => provider.value
      )
      setPDPProviders(providers)
    }
  }, [])

  if (
    maCarriers.includes(text) ||
    msCarriers.includes(text) ||
    mpdCarriers.includes(text)
  ) {
    updatedText = 'Carrier: ' + text
  }
  if (
    maPlanType.includes(text) ||
    msPlanType.includes(text) ||
    msPlanType.includes(text + '(High Deductible)')
  ) {
    updatedText = 'Plan Type: ' + text
  }
  if (maBenefits.includes(text)) {
    updatedText = 'Plan Benefits: ' + text
  }
  if (amRatings.includes(text)) {
    updatedText = 'AM Best Rating: ' + text
  }
  if (maDrugs.includes(text) || mpdDrugs.includes(text)) {
    updatedText = 'Drug: ' + text
  }

  if (maProviders.includes(text) || pdpProviders.includes(text)) {
    updatedText = `Provider: ${text}`
  }

  return (
    <Chip>
      <ChipText>{updatedText}</ChipText>
      <MdClear
        style={{ cursor: 'pointer', marginRight: '-10px', color: 'black' }}
        onClick={() => {
          updateFilters(text)
        }}
      />
    </Chip>
  )
}

export default FilterChip
