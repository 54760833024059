import { isEmpty, startCase } from 'lodash'

import { PrescriptionDrugFrequencyEnum } from '../enums/PrescriptionDrugFrequencyEnum'
import StringConstants from '../constants/StringConstants'

const convertCamelCaseToString = (camelCaseString: string) => {
  //camelCase
  return startCase(
    camelCaseString.replace(/([A-Z]+)/g, ' $1').replace(/([A-Z][a-z])/g, ' $1')
  )
}

const convertSnakeCaseToHeading = (snakeCaseString: string) => {
  //snake_case
  return startCase(snakeCaseString.replace('_', ' ').toLocaleLowerCase())
}

const capitalizeFirstCharacter = (str: string) => {
  //For example
  return !isEmpty(str) ? str.charAt(0).toUpperCase() + str.slice(1) : ''
}

const titleCase = (str: string) => {
  return !isEmpty(str)
    ? str.replace(/([^\W_]+[^\s-]*) */g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase()
      })
    : ''
}

const toSentenceCase = (str: string) => {
  if (!str) return ''
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
}

const removeSpaces = (str: string) => {
  return str.replace(/\s/g, '')
}

/**
 * Util function to get customized api error message
 * @param {string} message - customized message
 * @returns {string}
 */
const getApiErrorMessage = (message: string): string =>
  StringConstants.API_ERROR_MSG.replace(':message', message)

const convertNameToTitleCase = (name: string) => {
  return name.includes(`'`)
    ? name
        .split(`'`)
        .map((str) => titleCase(str))
        .join(`'`)
    : titleCase(name)
}

const ellipsis = (name: string, maxLength: number) => {
  return name.length > maxLength ? name.substring(0, maxLength) + '...' : name
}

const frequencyToText = (frequency: number) => {
  switch (frequency) {
    case 30:
      return 'every month'
    case 60:
      return 'every 2 months'
    case 90:
      return 'every 3 months'
    case 180:
      return 'every 6 months'
    case 360:
      return 'every 12 months'
    default:
      return 'every month'
  }
}

const textToFrequency = (text: string) => {
  switch (text) {
    case 'every month':
      return 30
    case 'every 2 months':
      return 60
    case 'every 3 months':
      return 90
    case 'every 6 months':
      return 180
    case 'every 12 months':
      return 360
    default:
      return 30
  }
}

export {
  convertCamelCaseToString,
  convertSnakeCaseToHeading,
  getApiErrorMessage,
  capitalizeFirstCharacter,
  titleCase,
  toSentenceCase,
  convertNameToTitleCase,
  ellipsis,
  removeSpaces,
  frequencyToText,
  textToFrequency,
}
