import styled from '@emotion/styled'
import React from 'react'
import theme from '../../../global/theme'
import LoadingComponent from '../Loading/Loading.component'
import { PulseLoader } from 'react-spinners'

const MainContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100000;
  display: flex;
  align-items: center;
  justify-content: center;
`

const OverlayLoader = () => {
  return (
    <MainContainer>
      <PulseLoader size={15} color={theme.colors.white} loading={true} />
    </MainContainer>
  )
}

export default OverlayLoader
