// import AddSpousePrompt from './AddSpouseSteps/AddSpousePrompt'
import React from 'react'
import AddSpouseQuestionnaire from './AddSpouseSteps/AddSpouseQuestionnaire'
import styled from '@emotion/styled'
import theme from '../../global/theme'
import { ActionContext } from '../../cra'

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  min-width: 350px;
  min-height: 350px;
  border: 1.5px solid ${theme.colors.borderColor};
  border-radius: 4px;
  padding: 20px 20px;
`

const AddSpouse = () => {
  const { trackCurrentPage } = React.useContext(ActionContext)

  React.useEffect(() => {
    trackCurrentPage('Profile - Add Spouse/Partner')
  }, [trackCurrentPage])
  
  return (
    <Container>
      <Wrapper>
        {/* <AddSpousePrompt /> */}
        <AddSpouseQuestionnaire />
      </Wrapper>
    </Container>
  )
}

export default AddSpouse
