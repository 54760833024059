import {
  AiOutlineCheck as Covered,
  AiOutlineClose as NotCovered,
} from 'react-icons/ai'
import { Table, TableBody } from '@mui/material'

import { DrugInformation } from '../../interface/quotes/mNonSupplementPlans/DrugInformation'
import Icon from '../common/Icon/Icon.component'
import MuiTableCell from '@mui/material/TableCell'
import MuiTableRow from '@mui/material/TableRow'
import StringConstants from '../../constants/StringConstants'
import Tooltip from '../common/Tooltip/Tooltip.component'
import { observer } from 'mobx-react'
import styled from '@emotion/styled'
import theme from '../../global/theme'
import { titleCase } from '../../utils/StringUtils'
import { useNavigate } from 'react-router-dom'

interface PrescriptionCoverageProps {
  prescribedDrugsCoverage: DrugInformation[]
}

const PrescriptionCoverageWrapper = styled.div`
  div {
    display: 'block';
    width: 100%;
  }
  h2 {
    font-size: 12px;
    color: ${theme.colors.textGrey};
    padding-bottom: 8px;
  }
`

const PrescriptionCoverageTableCell = styled(MuiTableCell)<{
  width: string
}>`
  font-weight: 700;
  color: ${theme.colors.textGrey};
  width: ${({ width }) => width};
  vertical-align: middle;
  padding: 5px;
  border: none;
  align-self: center;
  & p {
    font-size: 12px !important;
    color: ${theme.colors.textGrey}!important;
  }
  span {
    display: inline-flex;
    p {
      font-size: 12px !important;
      font-weight: 500;
      color: ${theme.colors.textGrey}!important;
    }
  }
`
const PrescriptionCoverageTableRow = styled(MuiTableRow)`
  background: white;
`
const NavigationWrapper = styled.span`
  padding-left: 10px;
  text-decoration: underline;
  font-weight: bold;
  color: ${theme.colors.primary};
  font-size: 14px;
`
const Label = styled.p`
  color: ${theme.colors.primary};
  font-size: 16px;
  font-weight: 700;
  padding-right: 20px;
  margin: 5px 0px;
`

const AddPrescriptionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`

const PrescriptionCoverage: React.FC<PrescriptionCoverageProps> = ({
  prescribedDrugsCoverage,
}) => {
  const navigate = useNavigate()
  return (
    <>
      <AddPrescriptionWrapper>
        <PrescriptionCoverageTableRow>
          <PrescriptionCoverageTableCell width={'40%'} colSpan={1}>
            <h2>Prescriptions Drug Coverage</h2>
          </PrescriptionCoverageTableCell>
          <PrescriptionCoverageTableCell width={'60%'} colSpan={1}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                minWidth: '15rem',
              }}
            >
              <NavigationWrapper
                style={{ cursor: 'pointer', padding: '0px', marginTop: '3px' }}
                onClick={() => {
                  navigate({
                    pathname: '/profile/myprofile/prescriptions',
                  })
                }}
              >
                + Add Prescription Drugs
              </NavigationWrapper>
              <Label>
                <Tooltip title={StringConstants.TOOLTIP_ADD_PRESCRIPTION} />
              </Label>
            </div>
          </PrescriptionCoverageTableCell>
        </PrescriptionCoverageTableRow>
      </AddPrescriptionWrapper>

      {prescribedDrugsCoverage?.length > 0 && (
        <PrescriptionCoverageWrapper>
          <Table>
            <TableBody>
              <PrescriptionCoverageTableRow>
                <PrescriptionCoverageTableCell width={'60%'} colSpan={1}>
                  {prescribedDrugsCoverage.map((drug, index) => (
                    <span key={index}>
                      <p>
                        {drug.tier !== -1 ? (
                          <Icon
                            childComponent={<Covered />}
                            color={theme.colors.success}
                            fontSize='12px'
                          />
                        ) : (
                          <Icon
                            childComponent={<NotCovered />}
                            color={theme.colors.danger}
                            fontSize='12px'
                          />
                        )}

                        {titleCase(drug.name)}
                      </p>
                    </span>
                  ))}
                </PrescriptionCoverageTableCell>
              </PrescriptionCoverageTableRow>
            </TableBody>
          </Table>
        </PrescriptionCoverageWrapper>
      )}
    </>
  )
}

export default observer(PrescriptionCoverage)
