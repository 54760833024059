import AddPrescriptionStep from './AddPrescriptionStep'
import ApiManager from '../../../../api/ApiManager'
import DisclosureStep from './DisclosureStep'
import FallbackStep from './FallbackStep'
import { HttpMethods } from '../../../../enums/HttpMethodsEnum'
import MillimanQuestion from './MillimanQuestion'
import PrescriptionQuestion from './PrescriptionQuestion'
import QuotesRefreshProvider from '../../../../context/QuotesRefreshContext'
import React from 'react'
import ReviewPrescriptionStep from './ReviewPrescriptionStep'
import RouteConstants from '../../../../constants/RouteConstants'
import SecureComponent from '../../../../pages/common/SecureComponent'
import UrlConstants from '../../../../constants/UrlConstants'
import customerStore from '../../../../datastore/CustomerStore'
import snackbarStore from '../../../../datastore/SnackbarStore'
import styled from '@emotion/styled'
import { useNavigate } from 'react-router-dom'

const Container = styled.div`
  width: auto;
  padding: 24px 30px;
  display: flex;
  justify-content: center;

  @media screen and (max-width: 600px) {
    padding: 24px 24px;
  }
`

const PrescriptionScreen: React.FC<{
  pathContext?: string
}> = ({ pathContext = 'Profile' }) => {
  const navigate = useNavigate()

  const [buttonLoading, setButtonLoading] = React.useState(false)
  const [step, setStep] = React.useState<number>(1)

  const handleCompletePrescriptionStep = () => {
    setButtonLoading(true)
    if (customerStore.get().forSpouse === false) {
      ApiManager.makeApiCallWithAuthentication(
        UrlConstants.PATCH_INFO_STEPS.USECASE,
        HttpMethods.PATCH_METHOD,
        {
          $infoStep: 'prescription',
        }
      )
        .then((res) => {
          if (res.status === 200) {
            navigate(RouteConstants.INFO_STEPS_HEALTH)
          } else throw res
        })
        .catch((err) => {
          snackbarStore.set({
            snackbarMessage: 'Something went wrong',
            snackbarOpen: true,
            snackbarType: 'error',
          })
        })
        .finally(() => {
          setButtonLoading(false)
        })
    }
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  const handleStepBack = () => {
    if (step === 3) {
      setStep(2)
    } else {
      setStep(1)
    }
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  const getSubStepComponent = (step: number) => {
    switch (step) {
      case 1:
        return (
          <PrescriptionQuestion
            completePrescriptionStep={handleCompletePrescriptionStep}
            setStep={setStep}
            buttonLoading={buttonLoading}
          />
        )
      case 2:
        return (
          <MillimanQuestion
            setStep={setStep}
            buttonLoading={buttonLoading}
            handleStepBack={handleStepBack}
          />
        )
      case 3:
        return <DisclosureStep setStep={setStep} />
      case 4:
        return (
          <QuotesRefreshProvider>
            <ReviewPrescriptionStep
              setStep={setStep}
              completePrescriptionStep={handleCompletePrescriptionStep}
            />
          </QuotesRefreshProvider>
        )
      case 5:
        return (
          <FallbackStep
            setStep={setStep}
            completePrescriptionStep={handleCompletePrescriptionStep}
            buttonLoading={buttonLoading}
          />
        )
      case 6:
        return (
          <AddPrescriptionStep
            buttonLoading={buttonLoading}
            completePrescriptionStep={handleCompletePrescriptionStep}
            pathContext={pathContext}
            handleStepBack={handleStepBack}
          />
        )
      default:
        return (
          <AddPrescriptionStep
            buttonLoading={buttonLoading}
            completePrescriptionStep={handleCompletePrescriptionStep}
            pathContext={pathContext}
            handleStepBack={handleStepBack}
          />
        )
    }
  }

  return <Container>{getSubStepComponent(step)}</Container>
}

export default SecureComponent(PrescriptionScreen)
