import { CheckBoxOptionTooltipType } from '../interface/common/CheckBoxOptionTooltipType'
import StringConstants from './StringConstants'

const toolTipOptionArray: CheckBoxOptionTooltipType = {
  'Dental Coverage': StringConstants.TOOLTIP_DENTAL_COVERAGE,
  'Hearing Coverage': StringConstants.TOOLTIP_HEARING_COVERAGE,
  'Vision Coverage': StringConstants.TOOLTIP_VISION_COVERAGE,
  'Cancer, Heart Attack and Stroke': StringConstants.TOOLTIP_CHAS,
  'Hospital Indemnity': StringConstants.TOOLTIP_HOSPITAL_INDEMNITY,
}

export default toolTipOptionArray
