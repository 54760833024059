import React, { useEffect } from 'react'
import {
  convertNameToTitleCase,
  getApiErrorMessage,
} from '../../utils/StringUtils'
import { useLocation, useNavigate } from 'react-router-dom'

import ApiManager from '../../api/ApiManager'
import Button from '../common/Button/Button.component'
import { HttpMethods } from '../../enums/HttpMethodsEnum'
import PasswordField from '../common/InputField/PasswordField.component'
import PasswordStrength from '../common/PasswordStrength/PasswordStrength.component'
import ReCAPTCHA from 'react-google-recaptcha'
import RouteConstants from '../../constants/RouteConstants'
import { SnackbarTypes } from '../../enums/SnackbarTypesEnum'
import StringConstants from '../../constants/StringConstants'
import UrlConstants from '../../constants/UrlConstants'
import ValidationUtils from '../../utils/validation/ValidationUtils'
import { isEmpty } from 'lodash'
import snackbarStore from '../../datastore/SnackbarStore'
import styled from '@emotion/styled'
import theme from '../../global/theme'

interface ForgotPasswordResetProps {
  handleResetSuccess: () => void
}

interface CustomizedProps {
  token: string
  fullName: string
  email: string
  phone: string
}

const Container = styled.div`
  width: 400px;
  h2 {
    color: ${theme.colors.primary};
    padding: 15px 0px;
    font-weight: bold;
  }

  p {
    padding-bottom: 20px;
    color: ${theme.colors.textDark};
    font-size: 14px;
    font-weight: 600;
  }

  .MuiFormHelperText-root.Mui-error.MuiFormHelperText-sizeSmall.MuiFormHelperText-contained {
    font-weight: 400;
    text-decoration: none;
  }

  @media screen and (max-width: 470px) {
    width: 320px;
  }
`

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 30px;

  @media screen and (max-width: 500px) {
    flex-direction: column;
  }
`

const ButtonWrapper = styled.div`
  width: 150px;
  @media screen and (max-width: 500px) {
    width: 100%;
  }
`

const CaptchaWrapper = styled.div`
  .grecaptcha-badge {
    visibility: hidden;
  }
`

const ForgotPasswordReset: React.FC<ForgotPasswordResetProps> = ({
  handleResetSuccess,
}) => {
  const location = useLocation()
  const navigate = useNavigate()
  const [password, setPassword] = React.useState('')
  const [confirmPassword, setConfirmPassword] = React.useState('')
  const [token, setToken] = React.useState('')
  const [fullName, setFullName] = React.useState('')
  const [email, setEmail] = React.useState('')
  const [phone, setPhone] = React.useState('')
  const [state, setState] = React.useState({
    passwordError: false,
    passwordHelperText: '',
    confirmPasswordError: false,
    confirmPasswordHelperText: '',
    apiError: false,
    apiErrorText: '',
    loading: false,
  })
  const [disabled, setDisabled] = React.useState(false)
  const reCaptchaRef = React.useRef<ReCAPTCHA>(null)

  useEffect(() => {
    if (isEmpty(location.state)) {
      navigate(RouteConstants.LOGIN, { replace: true })
    } else {
      let locationState = location.state as CustomizedProps
      setToken(locationState.token)
      setFullName(locationState.fullName)
      setEmail(locationState.email)
      setPhone(locationState.phone)
    }
  }, [location.state, navigate])

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value)
    if (isEmpty(e.target.value)) {
      setState({
        ...state,
        passwordError: true,
        passwordHelperText: 'New password should have some value',
      })
    } else {
      const passwordError = !isValidPassword(e.target.value)
      setState({
        ...state,
        passwordError,
        passwordHelperText: passwordError
          ? 'Please check Password strength indicators.'
          : '',
      })
    }
  }

  const validatePasswordOnBlur = (_e: React.FocusEvent<HTMLInputElement>) => {
    if (isEmpty(password)) {
      setState({
        ...state,
        passwordError: true,
        passwordHelperText: 'New password should have some value',
      })
    } else {
      const passwordError = !isValidPassword(password)
      setState({
        ...state,
        passwordError,
        passwordHelperText: passwordError
          ? 'Please check Password strength indicators.'
          : '',
      })
    }
  }

  const handleConfirmPasswordChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setConfirmPassword(e.target.value)
    const confirmPasswordError = isEmpty(e.target.value)
    setState({
      ...state,
      confirmPasswordError,
      confirmPasswordHelperText: confirmPasswordError
        ? 'Confirm password should have some value'
        : '',
    })
  }

  const validateConfirmPasswordOnBlur = (
    _e: React.FocusEvent<HTMLInputElement>
  ) => {
    const confirmPasswordError = isEmpty(confirmPassword)
    setState({
      ...state,
      confirmPasswordError,
      confirmPasswordHelperText: confirmPasswordError
        ? 'Confirm password should have some value'
        : '',
    })
  }

  const isValidPassword = (pwd: string) => {
    return ValidationUtils.validatePasswordToBeSet(pwd)
  }

  const handleBack = (_e: React.MouseEvent<HTMLButtonElement>) => {
    navigate(RouteConstants.LOGIN, { replace: true })
  }

  const handleNext = (_e: React.MouseEvent<HTMLButtonElement>) => {
    setDisabled(true)
    const passwordError = !isValidPassword(password)
    const passwordHelperText =
      passwordError === true ? 'Please check Password strength indicators.' : ''
    const confirmPasswordError =
      password.valueOf() !== confirmPassword.valueOf()
    const confirmPasswordHelperText =
      confirmPasswordError === true
        ? 'Confirm password entry should match New password entry.'
        : ''

    if (isEmpty(password)) {
      setDisabled(false)
      setState({
        ...state,
        passwordError: true,
        passwordHelperText: 'New password should have some value',
      })
    } else if (isEmpty(confirmPassword)) {
      setDisabled(false)
      setState({
        ...state,
        confirmPasswordError: true,
        confirmPasswordHelperText: 'Confirm password should have some value',
      })
    } else if (passwordError) {
      setDisabled(false)
      setState({
        ...state,
        passwordError,
        passwordHelperText,
      })
    } else if (confirmPasswordError) {
      setDisabled(false)
      setState({
        ...state,
        confirmPasswordError,
        confirmPasswordHelperText,
      })
    } else if (
      password.length > 0 &&
      ((email &&
        (password.toLowerCase().includes(email.toLowerCase()) ||
          password
            .toLowerCase()
            .includes(email.toLowerCase().split('@')[0]))) ||
        (phone &&
          (password.toLowerCase().includes(phone) ||
            password.toLowerCase().includes(phone.substring(-10)))) ||
        (fullName &&
          (password.toLowerCase().includes(fullName.toLowerCase()) ||
            fullName
              .toLowerCase()
              .split(' ')
              .some(
                (subName) =>
                  subName.length > 1 && password.toLowerCase().includes(subName)
              ))))
    ) {
      setDisabled(false)
      setState({
        ...state,
        passwordError: true,
        passwordHelperText: 'Please check Password strength indicators.',
      })
    } else {
      reCaptchaRef.current?.executeAsync().then((reCaptchaToken) => {
        ApiManager.makeApiCall(
          UrlConstants.RESET_FORGOT_PASSWORD.USECASE,
          HttpMethods.POST_METHOD,
          { $token: token, password: password, reCaptchaToken: reCaptchaToken }
        )
          .then((response) => {
            if (response.status === 200) {
              location.state = {}
              handleResetSuccess()
              snackbarStore.set({
                snackbarOpen: true,
                snackbarMessage: 'Password reset successfully',
                snackbarType: SnackbarTypes.SUCCESS,
              })
            } else throw response
          })
          .catch((err) => {
            if (
              err.data?.message ===
              StringConstants.API_ERROR_MSG_FOR_RESET_PASSWORD
            ) {
              snackbarStore.set({
                snackbarOpen: true,
                snackbarMessage:
                  StringConstants.UI_ERROR_MSG_FOR_RESET_PASSWORD_FAIL,
                snackbarType: SnackbarTypes.ERROR,
              })
            } else if (
              err.data?.message ===
              StringConstants.API_ERROR_MSG_FOR_SAME_PASSWORD_RESET
            ) {
              snackbarStore.set({
                snackbarMessage:
                  StringConstants.UI_ERROR_MSG_FOR_REUSING_CURRENT_PASSWORD,
                snackbarOpen: true,
                snackbarType: 'error',
              })
            } else {
              snackbarStore.set({
                snackbarOpen: true,
                snackbarMessage: getApiErrorMessage('reset password'),
                snackbarType: SnackbarTypes.ERROR,
              })
            }
          })
          .finally(() => {
            setDisabled(false)
          })
      })
    }
  }

  return (
    <Container>
      <h2>Reset password</h2>
      <p>
        Welcome back, {convertNameToTitleCase(fullName)}. Choose a strong new
        password for your account.
      </p>
      <PasswordField
        error={state.passwordError}
        helperText={state.passwordHelperText}
        fullWidth
        label='Enter new Password'
        value={password}
        color='info'
        onChange={handlePasswordChange}
        onBlur={validatePasswordOnBlur}
      />
      <PasswordField
        error={state.confirmPasswordError}
        helperText={state.confirmPasswordHelperText}
        fullWidth
        label='Confirm Password'
        color='info'
        value={confirmPassword}
        onChange={handleConfirmPasswordChange}
        onBlur={validateConfirmPasswordOnBlur}
      />
      <PasswordStrength
        password={password}
        name={fullName}
        email={email}
        phone={phone}
      />
      <ButtonContainer>
        <ButtonWrapper>
          <Button width='100%' color='info' onClick={handleBack}>
            BACK
          </Button>
        </ButtonWrapper>
        <ButtonWrapper>
          <Button
            width='100%'
            color='secondary'
            variant='contained'
            disabled={disabled}
            onClick={handleNext}
          >
            RESET
          </Button>
        </ButtonWrapper>
        <CaptchaWrapper>
          <ReCAPTCHA
            ref={reCaptchaRef}
            sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY!}
            size='invisible'
          />
        </CaptchaWrapper>
      </ButtonContainer>
    </Container>
  )
}

export default ForgotPasswordReset
