import styled from '@emotion/styled'
import React from 'react'
import theme from '../../../global/theme'
import RadioGroup from '../../common/RadioGroup/RadioGroup.component'

interface RadioQuestionI {
  options: any
  value: any
  question: any
  isRequired: any
  id: any
  handleResponseSelection: (id: any, responseArray: any) => void
  radioGroupName?: string
}

const Label = styled.h4`
  font-size: 16px;
  color: ${theme.colors.textGrey};
  padding: 20px 0px 5px 0px;
  font-weight: 600;
`

const RadioQuestion: React.FC<RadioQuestionI> = ({
  options,
  value,
  question,
  isRequired,
  id,
  handleResponseSelection,
  radioGroupName = ''
}) => {
  const handleRadioChange = (e: any) => {
    handleResponseSelection(id, [e.target.value])
  }
  return (
    <>
      <Label>
        {question}{' '}
        {isRequired && <span style={{ color: theme.colors.secondary }}>*</span>}
      </Label>
      <RadioGroup
        radioButtons={[...options.map((option: any) => option.option)]}
        onChange={handleRadioChange}
        value={value}
        radioGroupName={radioGroupName}
      />
    </>
  )
}

export default RadioQuestion
