import { forEach, isEmpty } from 'lodash'

import UrlConstants from '../constants/UrlConstants'
import { getEntriesFromUrl } from '../utils/UrlUtils'

const generateUrl = (usecase: string) => {
  let baseUrl = process.env.REACT_APP_API_ENDPOINT || ''

  if (usecase === 'CDN_ENDPOINT') baseUrl = UrlConstants[usecase].URL
  else if (
    UrlConstants[usecase] &&
    usecase === UrlConstants[usecase]['USECASE']
  )
    baseUrl += UrlConstants[usecase].URL

  return baseUrl
}

const updatePathParamsForApiUrl = (
  url: string,
  params: { [key: string]: any }
) => {
  const updatedUrl = new URL(url)
  if (params instanceof FormData) {
    Array.from(params.entries()).forEach((pair) => {
      let key: string = pair[0]
      let value: FormDataEntryValue = pair[1]
      if (key.charAt(0) === '$' && updatedUrl.pathname.includes(key)) {
        updatedUrl.pathname = updatedUrl.pathname.replace(key, value.toString())
        params.delete(key)
      }
    })
  } else {
    params &&
      Object.keys(params).forEach((key) => {
        if (key.charAt(0) === '$' && updatedUrl.pathname.includes(key)) {
          updatedUrl.pathname = updatedUrl.pathname.replace(key, params[key])
          delete params[key]
        }
      })
  }

  return { url: updatedUrl.toString(), params }
}

/**
 * Replace Path params in a UI Route.
 * Method returns complete url for the given route.
 * Ensure route has path param differentiated with colon (:) infront of the variable
 * Note : ":" automatically added to the keys in param
 * @param {RouteConstants} route
 * @param {object} params
 */
const getUiUrlWithPathParams = (
  route: string,
  params: { [key: string]: any }
) => {
  Object.keys(params).forEach((key) => {
    if (route.includes(':' + key)) {
      route = route.replace(':' + key, params[key])
    }
  })
  const url = window.location.origin + route
  return url
}

const addQueryParamsToApiUrl = (
  url: string,
  params: { [key: string]: any }
) => {
  const updatedUrl = new URL(url)

  Object.keys(params).forEach((key) => {
    if (!(key.charAt(0) === '$' && updatedUrl.pathname.includes(key)))
      if (Array.isArray(params[key])) {
        forEach(params[key], (value) => {
          updatedUrl.searchParams.append(key, value)
        })
      } else {
        updatedUrl.searchParams.set(key, params[key])
      }
  })
  return updatedUrl.toString()
}

/**
 * Add Query params to a UI Route
 * Method returns a complete url for the give UI route.
 * (Best used for window.history.replace)
 * @param {RouteConstants} route
 * @param {Object} params
 */
const getUiUrlWithQueryParams = (
  route: string,
  params: { [key: string]: any }
) => {
  let url = new URL(window.location.origin + route)
  Object.keys(params).forEach((key) => {
    url.searchParams.set(key, params[key])
  })

  return url.toString()
}

/**
 * Add Query params to any URI.
 * Does not convert the route param to URL
 * @param {*} route
 * @param {*} params
 */
const addQueryParamsToUrl = (route: string, params: { [key: string]: any }) => {
  if (isEmpty(params)) return route
  let pathname = route
  Object.keys(params).forEach((key, index) => {
    if (index === 0) pathname += '?'
    else pathname += '&'
    let value = params[key]
    if (Array.isArray(value)) {
      value.forEach((each, i) =>
        i < value.length - 1
          ? (pathname += key + '=' + encodeURIComponent(each) + '&')
          : (pathname += key + '=' + encodeURIComponent(each))
      )
    } else pathname += key + '=' + encodeURIComponent(value)
  })
  return pathname
}

const getUpdatedUrl = (route: string, params: { [key: string]: any }) => {
  let oldParams: { [key: string]: any } = {}
  const entries = getEntriesFromUrl(window.location.search)
  Array.from(entries).forEach((each) =>
    oldParams.hasOwnProperty(each[0])
      ? oldParams[each[0]].push(each[1])
      : (oldParams[each[0]] = Array.of(each[1]))
  )
  let newParams = {
    ...oldParams,
    ...params,
  }
  Object.keys(newParams).forEach(
    (key) =>
      (isEmpty(newParams[key]) || !newParams[key]) && delete newParams[key]
  )
  return addQueryParamsToUrl(route, newParams)
}

export {
  generateUrl,
  updatePathParamsForApiUrl,
  addQueryParamsToApiUrl,
  getUiUrlWithPathParams,
  getUiUrlWithQueryParams,
  addQueryParamsToUrl,
  getUpdatedUrl,
}
