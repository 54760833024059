import React, { useEffect } from 'react'
import { isEmpty, toLower } from 'lodash'

import ApiManager from '../../../api/ApiManager'
import Button from '../../common/Button/Button.component'
import CarrierLogo from '../../common/CarrierLogo'
import Cookies from 'universal-cookie'
import Filter from 'bad-words'
import FormUtils from '../../../utils/FormUtils'
import { HttpMethods } from '../../../enums/HttpMethodsEnum'
import InputField from '../../common/InputField/InputField.component'
import LazyLoadImageFromUrl from '../../common/LazyLoadImageFromUrl'
import MaskedInput from 'react-maskedinput'
import { PreferenceCoverageEnum } from '../../../enums/PreferenceCoverageEnum'
import { PreferenceCoverageShortNameEnum } from '../../../enums/PreferenceCoverageShortNameEnum'
import RadioGroup from '../../common/RadioGroup/RadioGroup.component'
import Select from '../../common/Select/Select.component'
import StringConstants from '../../../constants/StringConstants'
import SubFooter from '../../common/Footer/NpPopUpPromptFooter.component'
import UrlConstants from '../../../constants/UrlConstants'
import ValidationUtils from '../../../utils/validation/ValidationUtils'
import { fireEvent } from '../../../cra'
import { getApiErrorMessage } from '../../../utils/StringUtils'
import months from '../../../constants/MonthConstants'
import snackbarStore from '../../../datastore/SnackbarStore'
import styled from '@emotion/styled'
import theme from '../../../global/theme'
import { useLocation } from 'react-router-dom'

interface ModelProps {
  zipCodeDetails: {
    ZipCodeVal: string
    county: string
    stateCode: string
    fipsCode: string
  }
  tabValue: Number
  leadBrand: string
}

const ScrollBar = styled.div`
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 600px;
`

const Container = styled.div`
  width: 500px;

  h2 {
    color: #272f69;
    padding-bottom: 20px;
  }
  p {
    margin-bottom: 16px;
    font-weight: 600;
    color: ${theme.colors.textDark};

    span {
      color: #ba0000;
      font-weight: bold;
    }
  }
  .highlight {
    color: #262e67;
    font-weight: 700;
    padding-bottom: 20px;
    font-size: 18px;
  }

  div {
    display: flex;
    justify-content: space-between;
  }

  @media screen and (max-width: 750px) {
    width: 320px;
    div {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  @media screen and (max-width: 380px) {
    width: 290px;
  }
`

const Label = styled.h4`
  font-size: 16px;
  color: ${theme.colors.textGrey};
  padding: 20px 10px 5px 10px;
  font-weight: 600;
`

const SectionWrapper = styled.div`
  padding: 10px 0px 0px 0px;
  display: flex;
`

const GenderSectionWrapper = styled.div`
  margin-right: 400px;
  @media screen and (max-width: 750px) {
    padding-left: 70px;
  }
`

const DateSectionWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-top: 15px;
`

const SpouseSectionWrapper = styled.div`
  margin-right: 400px;
  @media screen and (max-width: 750px) {
    padding-left: 60px;
  }
`

const SelectWrapper = styled.div`
  @media screen and (max-width: 750px) {
    .MuiPopover-paper {
      display: block;
      left: auto !important;
    }
    .MuiSelect-select {
      display: block;
      margin-left: auto;
    }
  }
`
const LogoContainer = styled.div`
  margin-top: -2px;
  max-width: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  img {
    max-width: 180px;
    max-height: 80px;
    width: 100%;
    height: 100%;
  }

  @media screen and (max-width: 600px) {
    max-width: 80px;
    height: 80%;
    img {
      max-width: 150px;
      width: 150%;
    }
  }
`

const NpPopUpPrompt: React.FC<ModelProps> = ({
  zipCodeDetails,
  tabValue,
  leadBrand,
}) => {
  const monthOptions: string[] = Object.keys(months)
  const dayOptions: number[] = Array.from({ length: 31 }, (_, i) => i + 1)
  const currentYear = new Date().getFullYear()
  const yearOptions = []
  for (let i = currentYear - 110; i <= currentYear; i++)
    yearOptions.push(String(i))

  const filter = new Filter()
  const [firstName, setFirstName] = React.useState<string | null>('')
  const [lastName, setLastName] = React.useState<string | null>('')
  const [dob, setDOB] = React.useState<string | null>('')
  const [gender, setGender] = React.useState<string>('')
  const [email, setEmail] = React.useState<string | null>('')
  const [phone, setPhone] = React.useState<string | null>('')
  const [spouseOptIn, setSpouseOptIn] = React.useState<boolean | undefined>()
  const [spouseGender, setSpouseGender] = React.useState<string>('')
  const [spouseDob, setSpouseDOB] = React.useState<string | null>('')
  const [month, setMonth] = React.useState<string | null>('')
  const [year, setYear] = React.useState<string | null>('')
  const [day, setDay] = React.useState<string | null>('')
  const [spouseMonth, setSpouseMonth] = React.useState<string | null>('')
  const [spouseYear, setSpouseYear] = React.useState<string | null>('')
  const [spouseDay, setSpouseDay] = React.useState<string | null>('')
  const [optimizeExperimentID, setOptimizeExperimentID] = React.useState<
    string | null
  >('')
  const [optimizeExperimentCTAID, setOptimizeExperimentCTAID] = React.useState<
    string | null
  >('')
  const [callbackStatusNew, setCallbackStatusNew] = React.useState<
    'LQ Not in Dial Queue' | 'LQ Added to Dial Queue' | null
  >(null)
  const [callbackStatusOld, setCallbackStatusOld] = React.useState<
    'LQ Not in Dial Queue' | 'LQ Added to Dial Queue' | null
  >(null)
  const [callbackPercentage, setCallbackPercentage] = React.useState<
    number | null
  >(null)
  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const [salesforceID, setSalesforceID] = React.useState<string>('')

  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const cookies = new Cookies()
  const yearsTo = new Date().getFullYear()

  const [state, setState] = React.useState({
    firstNameError: false,
    firstNameHelperText: '',
    lastNameError: false,
    lastNameHelperText: '',
    emailError: false,
    emailHelperText: '',
    phoneError: false,
    phoneHelperText: '',
    dobError: false,
    dobHelperText: '',
    spouseDobError: false,
    spouseDobHelperText: '',
  })

  useEffect(() => {
    if (queryParams.has('first_name'))
      setFirstName(queryParams.get('first_name'))

    if (queryParams.has('last_name')) setLastName(queryParams.get('last_name'))

    if (queryParams.has('email')) setEmail(queryParams.get('email'))

    if (queryParams.has('phone')) {
      let phone = queryParams.get('phone')
      setPhone(phone)
      validatePhone(phone)
    }

    if (queryParams.has('day')) setDay(queryParams.get('day'))

    if (queryParams.has('month') && queryParams.get('month') !== null) {
      let month: any = queryParams.get('month')
      let monthIndex = Object.keys(months)

      setMonth(monthIndex[month - 1])
    }

    if (queryParams.has('year')) setYear(queryParams.get('year'))

    if (queryParams.has('isPartnerSelected')) {
      if (queryParams.get('isPartnerSelected') === 'yes') setSpouseOptIn(true)
      else setSpouseOptIn(false)
    }

    if (queryParams.has('gender'))
      queryParams.get('gender') === 'male'
        ? setGender('Male')
        : setGender('Female')

    if (queryParams.has('spouse_gender'))
      queryParams.get('spouse_gender') === 'male'
        ? setSpouseGender('Male')
        : setSpouseGender('Female')

    if (queryParams.has('spouse_day'))
      setSpouseDay(queryParams.get('spouse_day'))

    if (
      queryParams.has('spouse_month') &&
      queryParams.get('spouse_month') !== null
    ) {
      let month: any = queryParams.get('spouse_month')
      let monthIndex = Object.keys(months)

      setSpouseMonth(monthIndex[month - 1])
    }

    if (queryParams.has('spouse_year'))
      setSpouseYear(queryParams.get('spouse_year'))

    if (queryParams.has('_gaexp'))
      setOptimizeExperimentID(queryParams.get('_gaexp'))

    if (queryParams.has('optimizeleadquality'))
      setOptimizeExperimentCTAID(queryParams.get('optimizeleadquality'))

    if (
      queryParams.has('callback_status_new') &&
      queryParams.get('callback_status_new') !== '' &&
      queryParams.get('callback_status_new') !== null &&
      queryParams.get('callback_status_new') !== 'null'
    ) {
      setCallbackStatusNew(
        queryParams.get('callback_status_new') as
        | 'LQ Not in Dial Queue'
        | 'LQ Added to Dial Queue'
      )
    } else {
      setCallbackStatusNew(null)
    }

    if (
      queryParams.has('callback_status') &&
      queryParams.get('callback_status') !== '' &&
      queryParams.get('callback_status') !== null &&
      queryParams.get('callback_status') !== 'null'
    ) {
      setCallbackStatusOld(
        queryParams.get('callback_status') as
        | 'LQ Not in Dial Queue'
        | 'LQ Added to Dial Queue'
      )
    } else {
      setCallbackStatusOld(null)
    }

    if (
      queryParams.has('callback_percentage') &&
      queryParams.get('callback_percentage') !== '' &&
      queryParams.get('callback_percentage') !== null
    ) {
      setCallbackPercentage(
        parseInt(queryParams.get('callback_percentage') as string)
      )
    } else {
      setCallbackPercentage(null)
    }
  }, [])

  useEffect(() => {
    if (month && day && year) {
      let dob = calculateDOB(year, month, day)
      setDOB(dob)
    }
  }, [month, day, year])

  useEffect(() => {
    if (spouseMonth && spouseDay && spouseYear) {
      let spouseDob = calculateDOB(spouseYear, spouseMonth, spouseDay)
      setSpouseDOB(spouseDob)
    }
  }, [spouseMonth, spouseDay, spouseYear])

  const calculateDOB = (year: string, month: string, day: string) => {
    let convertedDay: string = ''

    if (day.toString().length < 2) convertedDay = '0' + day
    else convertedDay = day

    return [year, months[month], convertedDay].join('-')
  }

  const validateFirstName = (firstName: string) => {
    const firstNameError = !ValidationUtils.validateFirstName(firstName)
    setState({
      ...state,
      firstNameError,
      firstNameHelperText: isEmpty(firstName)
        ? StringConstants.UI_ERROR_MSG_FOR_EMPTY_FIRST_NAME
        : firstNameError
          ? StringConstants.UI_ERROR_MSG_FOR_INVALID_FIRST_NAME
          : '',
    })
  }

  const handleFirstNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const firstName = isEmpty(e.target.value)
      ? ''
      : filter.clean(e.target.value).replaceAll('*', '')
    validateFirstName(firstName)
    setFirstName(firstName)
  }

  const validateLastName = (lastName: string) => {
    const lastNameError = !ValidationUtils.validateLastName(lastName)
    setState({
      ...state,
      lastNameError,
      lastNameHelperText: isEmpty(lastName)
        ? StringConstants.UI_ERROR_MSG_FOR_EMPTY_LAST_NAME
        : lastNameError
          ? StringConstants.UI_ERROR_MSG_FOR_INVALID_LAST_NAME
          : '',
    })
  }

  const handleLastNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const lastName = isEmpty(e.target.value)
      ? ''
      : filter.clean(e.target.value).replaceAll('*', '')
    validateLastName(lastName)
    setLastName(lastName)
  }

  const handleGenderChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setGender(e.target.value)
  }

  const validateEmail = (email: string) => {
    const emailError = !ValidationUtils.validateEmail(email)
    setState({
      ...state,
      emailError,
      emailHelperText: isEmpty(email)
        ? StringConstants.UI_ERROR_MSG_FOR_EMPTY_EMAIL
        : emailError
          ? StringConstants.UI_ERROR_MSG_FOR_INVALID_EMAIL
          : '',
    })
  }

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    validateEmail(e.target.value)
    setEmail(e.target.value)
  }

  const validatePhone = (phone: string | null) => {
    phone = phone ? phone.replace(/[- )( _]/g, '') : ''
    var phoneError = true
    ValidationUtils.verifyPhoneNumber(phone)
      .then((response: any) => {
        if (response.data.data.status === 'valid') {
          phoneError = false
          setState({
            ...state,
            phoneError,
            phoneHelperText: isEmpty(phone)
              ? StringConstants.UI_ERROR_MSG_FOR_EMPTY_PHONE
              : phoneError
                ? StringConstants.UI_ERROR_MSG_FOR_INVALID_PHONE
                : '',
          })
        }
      })
      .catch((err: any) => {
        console.log(err)
      })
    setState({
      ...state,
      phoneError,
      phoneHelperText: isEmpty(phone)
        ? StringConstants.UI_ERROR_MSG_FOR_EMPTY_PHONE
        : phoneError
          ? StringConstants.UI_ERROR_MSG_FOR_INVALID_PHONE
          : '',
    })
  }

  const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    validatePhone(e.target.value)
    setPhone(e.target.value)
  }

  const handleSpouseOptInChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === 'Yes') setSpouseOptIn(true)
    else setSpouseOptIn(false)
  }

  const handleSpouseGenderChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSpouseGender(e.target.value)
  }

  const handleMonthChange = (event: { target: { value: any } }) => {
    setMonth(event.target.value || '')
  }

  const handleDayChange = (event: { target: { value: any } }) => {
    setDay(event.target.value)
  }

  const handleYearChange = (event: { target: { value: any } }) => {
    setYear(event.target.value)
  }

  const handleSpouseMonthChange = (event: { target: { value: any } }) => {
    setSpouseMonth(event.target.value || '')
  }

  const handleSpouseDayChange = (event: { target: { value: any } }) => {
    setSpouseDay(event.target.value)
  }

  const handleSpouseYearChange = (event: { target: { value: any } }) => {
    setSpouseYear(event.target.value)
  }

  const debounce = <A extends unknown[]>(
    callback: (...args: A) => unknown,
    msDelay: number
  ) => {
    let timer: NodeJS.Timeout | undefined

    return (...args: A) => {
      clearTimeout(timer)

      timer = setTimeout(() => {
        timer = undefined
        callback(...args)
      }, msDelay)
    }
  }

  const [dimensions, setDimensions] = React.useState({
    height: window.innerHeight,
    width: window.innerWidth,
  })

  React.useEffect(() => {
    const debouncedHandleResize = debounce(function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      })
    }, 100)

    window.addEventListener('resize', debouncedHandleResize)

    return () => {
      window.removeEventListener('resize', debouncedHandleResize)
    }
  })

  const navigateUser = (username: string, passThroughCode: string) => {
    var pc = ''
    if (tabValue === 0) pc = PreferenceCoverageShortNameEnum.ma
    else if (tabValue === 1) pc = PreferenceCoverageShortNameEnum.ms
    else if (tabValue === 2) pc = PreferenceCoverageShortNameEnum.pdp
    let newUrl =
      process.env.REACT_APP_REDIRECT_APP_URL +
      `?pc=${pc}&username=` +
      username +
      `&token=` +
      passThroughCode +
      `&nxt=QT-LOGIN`

    if (queryParams.has('_gl')) newUrl += `&_gl=${queryParams.get('_gl')}`
    if (queryParams.has('leadBrand')) newUrl += `&leadBrand=${leadBrand}`

    window.open(newUrl, '_self')
  }

  const generateReport = (salesforceID: any) => {
    const params = new URLSearchParams(location.search)

    console.log('GENERATE REPORT')

    fireEvent(
      'organicLead',
      {},
      {
        oldCallbackStatus: callbackStatusOld,
        newCallbackStatus: callbackStatusNew,
        callbackPercentage: callbackPercentage,
        leadURL: params.has('lead_URL')
          ? params.get('lead_URL')
          : params.has('leadURL')
            ? params.get('leadURL')
            : '',
        gid: params.has('_gid') ? params.get('_gid') : '',
        leadId: salesforceID,
        date: new Date().toISOString().substring(0, 10),
      }
    )
  }

  const getLeadAdSource = () => {
    let leadAdSource = queryParams.has('utm_source')
      ? queryParams.get('utm_source')
      : 'direct'
    return leadAdSource
  }

  const getLeadMedium = () => {
    let leadAdMedium = queryParams.has('utm_medium')
      ? queryParams.get('utm_medium')
      : 'search'
    return leadAdMedium
  }

  const getLeadSource = () => {
    if (queryParams.has('utm_source')) {
      let utm_source = queryParams.get('utm_source')
      if (utm_source === 'social' || utm_source === 'fb')
        return 'Facebook - Online'
      else if (utm_source === 'excelimpact' || utm_source === 'apollo')
        return 'Partner - Online'
      else if (
        (email && email.includes('@test.com')) ||
        (lastName && lastName.toLowerCase() === 'test')
      )
        return 'Test'
      else return 'MedicareFAQ - Online'
    } else return ''
  }

  const getAP = () => {
    const apValue =
      cookies.get('trustedFormId') !== undefined
        ? cookies.get('trustedFormId')
        : ''
    return apValue
  }

  const getExternalLeadiD = () => {
    const liD =
      cookies.get('externalLeadiD') !== undefined
        ? cookies.get('externalLeadiD')
        : ''
    if (typeof window !== 'undefined' && window.LeadiD) window.LeadiD.snap()
    return liD
  }

  const getSelectedPlan = () => {
    if (queryParams.has('Coverage_Multi__c')) {
      const multiCoverage = queryParams.get('Coverage_Multi__c')
      return multiCoverage
    } else {
      if (tabValue === 0) return PreferenceCoverageEnum.MEDICARE_ADVANTAGE
      else if (tabValue === 1) return PreferenceCoverageEnum.MEDICARE_SUPPLEMENT
      else return PreferenceCoverageEnum.MEDICARE_PART_D
    }
  }

  const submitResponse = (data: Object) => {
    setIsLoading(true)
    ApiManager.makeApiCall(
      UrlConstants.SUBMIT_PRIMARY_FORM.USECASE,
      HttpMethods.POST_METHOD,
      data
    )
      .then((response) => {
        if (response.status === 200) {
          if (response.data && response.data.data) {
            let pass = response.data.data.passthroughCode
            let userName = response.data.data.username
            let salesforceID = response.data.data.salesforceID
            setSalesforceID(salesforceID)
            if (callbackPercentage !== null) {
              generateReport(salesforceID)
            }
            navigateUser(userName, pass)
          }
        } else throw new Error('Something went wrong while submitting the form')
      })
      .catch((err: Error) => {
        setIsLoading(false)
        console.log('Error while submitting the form: ' + err.message)
        snackbarStore.set({
          snackbarOpen: true,
          snackbarMessage: getApiErrorMessage('Unable to store details'),
          snackbarType: 'error',
        })
      })
  }

  const getHighIncomeCampaignTerms = () => {
    const utm_campaign = queryParams.has('utm_campaign')
      ? queryParams.get('utm_campaign')
      : null

    const demoMatch = utm_campaign && utm_campaign.match(/d-(.*?)_/)
    const targetMatch = utm_campaign && utm_campaign.match(/t-(.*?)_/)

    const demo = demoMatch ? demoMatch[1].split(':') : null
    const target = targetMatch ? targetMatch[1].split(':') : null

    let targetingIncome = null
    let targetingVisitor = null

    if (target) {
      target.forEach((value) => {
        if (value.includes('income')) {
          targetingIncome = value.split('income')[0].trim()
        } else if (value.includes('visitors')) {
          targetingVisitor = value.split('visitors')[0].trim()
        }
      })
    }

    const res = {
      demographicalInfo: demo ? demo.join(', ').trim() : null,
      targetingIncome,
      targetingVisitor,
    }

    return res
  }

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    var data = []
    e.preventDefault()
    const campaignTerms = getHighIncomeCampaignTerms()
    if (
      !isEmpty(firstName) &&
      !isEmpty(lastName) &&
      !isEmpty(email) &&
      !isEmpty(phone) &&
      spouseOptIn !== undefined
    ) {
      if (
        !state.firstNameError &&
        !state.lastNameError &&
        !state.emailError &&
        !state.phoneError
      ) {
        data.push({
          firstName: firstName,
          lastName: lastName,
          email: email,
          phone: '+1' + phone!.replace(/[- )(]/g, ''),
          smsOptIn: true,
          postalCode: zipCodeDetails.ZipCodeVal,
          county: zipCodeDetails.county,
          countyFips: zipCodeDetails.fipsCode,
          state: zipCodeDetails.stateCode,
          spouseOptIn: spouseOptIn,
          sessionStartTime: queryParams.has('sessionStartTime')
            ? queryParams.get('sessionStartTime')
            : '',
          sessionEndTime: new Date().toISOString(),
          leadCreationStage: 'No Price Popup',
          callbackStatus: callbackStatusNew,
          optimizeExperimentID: optimizeExperimentID,
          optimizeExperimentCTAID: optimizeExperimentCTAID,
          preferenceCoverage:
            tabValue === 0
              ? PreferenceCoverageShortNameEnum.ma
              : tabValue === 1
                ? PreferenceCoverageShortNameEnum.ms
                : PreferenceCoverageShortNameEnum.pdp,
          hotjarSessionID: !queryParams.has('hotjarSession')
            ? ''
            : !FormUtils.isQueryParamEmpty(queryParams.get('hotjarSession'))
              ? queryParams.get('hotjarSession')
              : '',
          leadGoogleClientID: !queryParams.has('leadClientIDGoogle')
            ? ''
            : !FormUtils.isQueryParamEmpty(
              queryParams.get('leadClientIDGoogle')
            )
              ? queryParams.get('leadClientIDGoogle')
              : '',

          leadPageId: !queryParams.has('leadPageID')
            ? ''
            : !FormUtils.isQueryParamEmpty(queryParams.get('leadPageID'))
              ? queryParams.get('leadPageID')
              : '',
          quotingToolVersionId: process.env.REACT_APP_VERSION_ID,
          termId: !queryParams.has('termId')
            ? ''
            : !FormUtils.isQueryParamEmpty(queryParams.get('termId'))
              ? queryParams.get('termId')
              : '',
          imageId: !queryParams.has('imageId')
            ? ''
            : !FormUtils.isQueryParamEmpty(queryParams.get('imageId'))
              ? queryParams.get('imageId')
              : '',
          brandName: !queryParams.has('leadBrand')
            ? ''
            : !FormUtils.isQueryParamEmpty(queryParams.get('leadBrand'))
              ? queryParams.get('leadBrand')
              : '',
          selectedPlan: getSelectedPlan(),
          adGroupID: !queryParams.has('adgroup_ID')
            ? ''
            : !FormUtils.isQueryParamEmpty(queryParams.get('adgroup_ID'))
              ? queryParams.get('adgroup_ID')
              : '',
          leadAdGroup: !queryParams.has('adgroupid')
            ? ''
            : !FormUtils.isQueryParamEmpty(queryParams.get('adgroupid'))
              ? queryParams.get('adgroupid')
              : '',
          leadAdPosition: !queryParams.has('adposition')
            ? ''
            : !FormUtils.isQueryParamEmpty(queryParams.get('adposition'))
              ? queryParams.get('adposition')
              : '',
          campaignID: !queryParams.has('campaignID')
            ? ''
            : !FormUtils.isQueryParamEmpty(queryParams.get('campaignID'))
              ? queryParams.get('campaignID')
              : '',
          leadExtension: !queryParams.has('extensionid')
            ? ''
            : !FormUtils.isQueryParamEmpty(queryParams.get('extensionid'))
              ? queryParams.get('extensionid')
              : '',
          GCLID: !queryParams.has('gclid')
            ? ''
            : !FormUtils.isQueryParamEmpty(queryParams.get('gclid'))
              ? queryParams.get('gclid')
              : '',
          keywordID: !queryParams.has('keywordID')
            ? ''
            : !FormUtils.isQueryParamEmpty(queryParams.get('keywordID'))
              ? queryParams.get('keywordID')
              : '',
          leadLocation: !queryParams.has('location')
            ? ''
            : !FormUtils.isQueryParamEmpty(queryParams.get('location'))
              ? queryParams.get('location')
              : '',
          leadMatchType: !queryParams.has('matchtype')
            ? ''
            : !FormUtils.isQueryParamEmpty(queryParams.get('matchtype'))
              ? queryParams.get('matchtype')
              : '',
          leadNetwork: !queryParams.has('network')
            ? ''
            : !FormUtils.isQueryParamEmpty(queryParams.get('network'))
              ? queryParams.get('network')
              : '',
          leadPlacement: !queryParams.has('placement')
            ? ''
            : !FormUtils.isQueryParamEmpty(queryParams.get('placement'))
              ? queryParams.get('placement')
              : '',
          leadAdCampaign: !queryParams.has('utm_campaign')
            ? ''
            : !FormUtils.isQueryParamEmpty(queryParams.get('utm_campaign'))
              ? queryParams.get('utm_campaign')
              : '',
          leadContent: !queryParams.has('utm_content')
            ? ''
            : !FormUtils.isQueryParamEmpty(queryParams.get('utm_content'))
              ? queryParams.get('utm_content')
              : '',
          leadMedium: getLeadMedium(),
          leadAdSource: getLeadAdSource(),
          leadKeyword: !queryParams.has('utm_term')
            ? ''
            : !FormUtils.isQueryParamEmpty(queryParams.get('utm_term'))
              ? queryParams.get('utm_term')
              : '',
          leadURL: !queryParams.has('leadURL')
            ? ''
            : !FormUtils.isQueryParamEmpty(queryParams.get('leadURL'))
              ? queryParams.get('leadURL')
              : '',
          leadDevice: FormUtils.getDeviceDetails()
            ? FormUtils.getDeviceDetails()?.type
            : '',
          leadBrowser: FormUtils.getBrowserName(),
          leadOS: FormUtils.getOS(),
          deviceBrand: FormUtils.getDeviceDetails()
            ? FormUtils.getDeviceDetails()?.brand
            : '',
          deviceName: FormUtils.getDeviceDetails()
            ? FormUtils.getDeviceDetails()?.model
            : '',
          osVersion: FormUtils.getOSVersion(),
          screenHeight: FormUtils.getWindow()
            ? FormUtils.getWindow()?.outerHeight
            : '',
          screenWidth: FormUtils.getWindow()
            ? FormUtils.getWindow()?.outerWidth
            : '',
          landingPageUrl: queryParams.has('lead_Landing-Page')
            ? queryParams.get('lead_Landing-Page')
            : queryParams.has('lead_Landing-Page')
              ? queryParams.get('lead_Landing-Page')
              : FormUtils.getLeadLandingPage()
                ? FormUtils.getLeadLandingPage()
                : '',
          leadReferralUrl: queryParams.has('lead_Referring-URL')
            ? queryParams.get('lead_Referring-URL')
            : queryParams.has('leadReferringUrl')
              ? queryParams.get('leadReferringUrl')
              : FormUtils.getLeadReferringUrl()
                ? FormUtils.getLeadReferringUrl()
                : '',
          leadStatus: 'New',
          leadSubStatus: '',
          leadID: salesforceID,
          leadQueryString: !queryParams.has('querystring')
            ? ''
            : !FormUtils.isQueryParamEmpty(queryParams.get('querystring'))
              ? queryParams.get('querystring')
              : '',
          leadIPAddress: !queryParams.has('lead_IP-Address')
            ? ''
            : !FormUtils.isQueryParamEmpty(queryParams.get('lead_IP-Address'))
              ? queryParams.get('lead_IP-Address')
              : '',
          leadUrl: queryParams.has('lead_URL')
            ? queryParams.get('lead_URL')
            : queryParams.has('leadURL')
              ? queryParams.get('leadURL')
              : '',
          leadSource: getLeadSource(),
          activeProspect: getAP(),
          journayaLeadID: getExternalLeadiD(),
          leadExtensionID: !queryParams.has('extensionid')
            ? ''
            : !FormUtils.isQueryParamEmpty(queryParams.get('extensionid'))
              ? queryParams.get('extensionid')
              : '',
          FBCLID: !queryParams.has('fbclid')
            ? ''
            : !FormUtils.isQueryParamEmpty(queryParams.get('fbclid'))
              ? queryParams.get('fbclid')
              : '',
          MSCLKID: !queryParams.has('msclkid')
            ? ''
            : !FormUtils.isQueryParamEmpty(queryParams.get('msclkid'))
              ? queryParams.get('msclkid')
              : '',
          MATOKENID: queryParams.has('MATOKENID')
            ? queryParams.get('MATOKENID')
            : queryParams.has('matokenID')
              ? queryParams.get('matokenID')
              : '',
          excludeFromBoberdooAPIs: queryParams.has('info')
            ? ('' + queryParams.get('info')).toLowerCase() === 'true'
            : false,
          originalLeadAdSource: !queryParams.has(
            'marketing_Original-Lead-Ad-Source'
          )
            ? ''
            : !FormUtils.isQueryParamEmpty(
              queryParams.get('marketing_Original-Lead-Ad-Source')
            )
              ? queryParams.get('marketing_Original-Lead-Ad-Source')
              : '',
          originalLeadMedium: !queryParams.has('marketing_Original-Lead-Medium')
            ? ''
            : !FormUtils.isQueryParamEmpty(
              queryParams.get('marketing_Original-Lead-Medium')
            )
              ? queryParams.get('marketing_Original-Lead-Medium')
              : '',
          originalLeadAdCampaign: !queryParams.has(
            'marketing_Original-Lead-Ad-Campaign'
          )
            ? ''
            : !FormUtils.isQueryParamEmpty(
              queryParams.get('marketing_Original-Lead-Ad-Campaign')
            )
              ? queryParams.get('marketing_Original-Lead-Ad-Campaign')
              : '',
          originalLeadAdGroup: !queryParams.has(
            'marketing_Original-Lead-Ad-Group'
          )
            ? ''
            : !FormUtils.isQueryParamEmpty(
              queryParams.get('marketing_Original-Lead-Ad-Group')
            )
              ? queryParams.get('marketing_Original-Lead-Ad-Group')
              : '',
          originalLeadUrl: !queryParams.has('marketing_Original-Lead-URL')
            ? ''
            : !FormUtils.isQueryParamEmpty(
              queryParams.get('marketing_Original-Lead-URL')
            )
              ? queryParams.get('marketing_Original-Lead-URL')
              : '',
          originalGoogleClientID: !queryParams.has(
            'marketing_Original-Google-Client-ID'
          )
            ? ''
            : !FormUtils.isQueryParamEmpty(
              queryParams.get('marketing_Original-Google-Client-ID')
            )
              ? queryParams.get('marketing_Original-Google-Client-ID')
              : '',
          originalLeadNetwork: !queryParams.has(
            'marketing_Original-Lead-Network'
          )
            ? ''
            : !FormUtils.isQueryParamEmpty(
              queryParams.get('marketing_Original-Lead-Network')
            )
              ? queryParams.get('marketing_Original-Lead-Network')
              : '',
          originalLeadMatchType: !queryParams.has(
            'marketing_Original-Lead-Match-Type'
          )
            ? ''
            : !FormUtils.isQueryParamEmpty(
              queryParams.get('marketing_Original-Lead-Match-Type')
            )
              ? queryParams.get('marketing_Original-Lead-Match-Type')
              : '',
          originalLeadKeyword: !queryParams.has(
            'marketing_Original-Lead-Keywords'
          )
            ? ''
            : !FormUtils.isQueryParamEmpty(
              queryParams.get('marketing_Original-Lead-Keywords')
            )
              ? queryParams.get('marketing_Original-Lead-Keywords')
              : '',
          originalLeadQueryString: !queryParams.has(
            'marketing_Original-Lead-Query-String'
          )
            ? ''
            : !FormUtils.isQueryParamEmpty(
              queryParams.get('marketing_Original-Lead-Query-String')
            )
              ? queryParams.get('marketing_Original-Lead-Query-String')
              : '',
          originalLeadContent: !queryParams.has(
            'marketing_Original-Lead-Content'
          )
            ? ''
            : !FormUtils.isQueryParamEmpty(
              queryParams.get('marketing_Original-Lead-Content')
            )
              ? queryParams.get('marketing_Original-Lead-Content')
              : '',
          originalLeadExtension: !queryParams.has(
            'marketing_Original-Lead-Extension'
          )
            ? ''
            : !FormUtils.isQueryParamEmpty(
              queryParams.get('marketing_Original-Lead-Extension')
            )
              ? queryParams.get('marketing_Original-Lead-Extension')
              : '',
          originalLeadDevice: !queryParams.has('marketing_Original-Lead-Device')
            ? ''
            : !FormUtils.isQueryParamEmpty(
              queryParams.get('marketing_Original-Lead-Device')
            )
              ? queryParams.get('marketing_Original-Lead-Device')
              : '',
          originalLeadLocation: !queryParams.has(
            'marketing_Original-Lead-Location'
          )
            ? ''
            : !FormUtils.isQueryParamEmpty(
              queryParams.get('marketing_Original-Lead-Location')
            )
              ? queryParams.get('marketing_Original-Lead-Location')
              : '',
          originalLeadPlacement: !queryParams.has(
            'marketing_Original-Lead-Placement'
          )
            ? ''
            : !FormUtils.isQueryParamEmpty(
              queryParams.get('marketing_Original-Lead-Placement')
            )
              ? queryParams.get('marketing_Original-Lead-Placement')
              : '',
          originalLeadAdPosition: !queryParams.has(
            'marketing_Original-Lead-Ad-Position'
          )
            ? ''
            : !FormUtils.isQueryParamEmpty(
              queryParams.get('marketing_Original-Lead-Ad-Position')
            )
              ? queryParams.get('marketing_Original-Lead-Ad-Position')
              : '',
          originalLeadDeviceBrand: !queryParams.has(
            'marketing_Original-Lead-Device-Brand'
          )
            ? ''
            : !FormUtils.isQueryParamEmpty(
              queryParams.get('marketing_Original-Lead-Device-Brand')
            )
              ? queryParams.get('marketing_Original-Lead-Device-Brand')
              : '',
          originalLeadDeviceName: !queryParams.has(
            'marketing_Original-Lead-Device-Name'
          )
            ? ''
            : !FormUtils.isQueryParamEmpty(
              queryParams.get('marketing_Original-Lead-Device-Name')
            )
              ? queryParams.get('marketing_Original-Lead-Device-Name')
              : '',
          originalLeadOsVersion: !queryParams.has(
            'marketing_Original-Lead-Lead-OS-Version'
          )
            ? ''
            : !FormUtils.isQueryParamEmpty(
              queryParams.get('marketing_Original-Lead-Lead-OS-Version')
            )
              ? queryParams.get('marketing_Original-Lead-Lead-OS-Version')
              : '',
          originalLeadScreenHeight: !queryParams.has(
            'marketing_Original-Lead-Screen-Height'
          )
            ? ''
            : !FormUtils.isQueryParamEmpty(
              queryParams.get('marketing_Original-Lead-Screen-Height')
            )
              ? queryParams.get('marketing_Original-Lead-Screen-Height')
              : '',
          originalLeadScreenWidth: !queryParams.has(
            'marketing_Original-Lead-Screen-Width'
          )
            ? ''
            : !FormUtils.isQueryParamEmpty(
              queryParams.get('marketing_Original-Lead-Screen-Width')
            )
              ? queryParams.get('marketing_Original-Lead-Screen-Width')
              : '',
          originalLeadLandingPage: !queryParams.has(
            'marketing_Original-Lead-Landing-Page'
          )
            ? ''
            : !FormUtils.isQueryParamEmpty(
              queryParams.get('marketing_Original-Lead-Landing-Page')
            )
              ? queryParams.get('marketing_Original-Lead-Landing-Page')
              : '',
          originalLeadReferringUrl: !queryParams.has(
            'marketing_Original-Lead-Referring-URL'
          )
            ? ''
            : !FormUtils.isQueryParamEmpty(
              queryParams.get('marketing_Original-Lead-Referring-URL')
            )
              ? queryParams.get('marketing_Original-Lead-Referring-URL')
              : '',
          originalLeadIPAddress: !queryParams.has(
            'marketing_Original-Lead-IP-Address'
          )
            ? ''
            : !FormUtils.isQueryParamEmpty(
              queryParams.get('marketing_Original-Lead-IP-Address')
            )
              ? queryParams.get('marketing_Original-Lead-IP-Address')
              : '',
          originalLeadBrowser: !queryParams.has(
            'marketing_Original-Lead-Lead-Browser'
          )
            ? ''
            : !FormUtils.isQueryParamEmpty(
              queryParams.get('marketing_Original-Lead-Lead-Browser')
            )
              ? queryParams.get('marketing_Original-Lead-Lead-Browser')
              : '',
          originalLeadOS: !queryParams.has(
            'marketing_Original-Lead-Operating-System'
          )
            ? ''
            : !FormUtils.isQueryParamEmpty(
              queryParams.get('marketing_Original-Lead-Operating-System')
            )
              ? queryParams.get('marketing_Original-Lead-Operating-System')
              : '',
          originalLeadCampaignID: !queryParams.has(
            'marketing_Original-Lead-Campaign-ID'
          )
            ? ''
            : !FormUtils.isQueryParamEmpty(
              queryParams.get('marketing_Original-Lead-Campaign-ID')
            )
              ? queryParams.get('marketing_Original-Lead-Campaign-ID')
              : '',
          originalLeadAdGroupID: !queryParams.has(
            'marketing_Original-Lead-Ad-Group-ID'
          )
            ? ''
            : !FormUtils.isQueryParamEmpty(
              queryParams.get('marketing_Original-Lead-Ad-Group-ID')
            )
              ? queryParams.get('marketing_Original-Lead-Ad-Group-ID')
              : '',
          originalLeadKeywordID: !queryParams.has(
            'marketing_Original-Lead-Keyword-ID'
          )
            ? ''
            : !FormUtils.isQueryParamEmpty(
              queryParams.get('marketing_Original-Lead-Keyword-ID')
            )
              ? queryParams.get('marketing_Original-Lead-Keyword-ID')
              : '',
          originalLeadExtensionID: !queryParams.has(
            'marketing_Original-Lead-Extension-ID'
          )
            ? ''
            : !FormUtils.isQueryParamEmpty(
              queryParams.get('marketing_Original-Lead-Extension-ID')
            )
              ? queryParams.get('marketing_Original-Lead-Extension-ID')
              : '',

          phoneAdType: getLeadAdSource() + ' - ' + getLeadMedium(),
          redirectURL: window.location.origin,
          salesforceLeadID: salesforceID,
          demographicsVersionId: !queryParams.has('demographicsVersionId')
            ? ''
            : !FormUtils.isQueryParamEmpty(
              queryParams.get('demographicsVersionId')
            )
              ? queryParams.get('demographicsVersionId')
              : '',
          sessionCount: !queryParams.has('sessionCount')
            ? ''
            : !FormUtils.isQueryParamEmpty(queryParams.get('sessionCount'))
              ? queryParams.get('sessionCount')
              : '',
          targetingIncome: campaignTerms.targetingIncome,
          targetingVisitor: campaignTerms.targetingVisitor,
          targetingDemographics: campaignTerms.demographicalInfo
        })

        if (
          queryParams.has('marketing_Original-Lead-Date') &&
          !FormUtils.isQueryParamEmpty(
            queryParams.get('marketing_Original-Lead-Date')
          )
        ) {
          const leadDate = {
            originalLeadDate: queryParams.get('marketing_Original-Lead-Date'),
          }
          data[0] = Object.assign({}, data[0], leadDate)
        }

        if (callbackStatusNew !== 'LQ Not in Dial Queue') {
          const callbackData = {
            leadAppStage: 'Demographics',
            callbackLogic: 'Exit Intent',
          }
          data[0] = Object.assign({}, data[0], callbackData)
        }

        if (tabValue !== 1) submitResponse(data[0])
        else {
          if (!isEmpty(dob) && !isEmpty(gender)) {
            const additionalData = {
              dateOfBirth: dob,
              gender: toLower(gender),
            }
            data[0] = Object.assign({}, data[0], additionalData)
            if (spouseOptIn) {
              if (!isEmpty(spouseGender) && !isEmpty(spouseDob)) {
                const spouseData = {
                  spouseGender: toLower(spouseGender),
                  spouseDateOfBirth: spouseDob,
                }
                data[0] = Object.assign({}, data[0], spouseData)
                submitResponse(data[0])
              } else
                alert('Please fill the Spouse/Partner’s Gender or Age details')
            } else submitResponse(data[0])
          } else alert('Please fill the Gender or Age details')
        }
      } else {
        alert('Please fill valid details')
      }
    } else {
      alert('Please fill all the details')
    }
  }

  return (
    <ScrollBar>
      <Container>
        <div style={{ justifyContent: 'center' }}>
          {(leadBrand && leadBrand.startsWith('aetna')) || leadBrand.startsWith('devoted') ||
            leadBrand.startsWith('mutual') ||
            leadBrand.startsWith('medicareassociation') ? (
            <LogoContainer>
              <LazyLoadImageFromUrl
                brandName={leadBrand}
                useAltLogo={dimensions.width <= 600}
              />
            </LogoContainer>
          ) : (
            <CarrierLogo carrier='EIP' height='80px' width='100px' />
          )}
        </div>
        <Label style={{ textAlign: 'center' }}>
          Just one more step! <br />
          Please provide the following information so that we can help find the
          right plan for you!
        </Label>
        <form id='np-popup-form'>
          <Label>
            First Name{' '}
            <span style={{ color: `${theme.colors.secondary}` }}>*</span>
          </Label>
          <InputField
            additionalTextPaddingLeft='15px'
            error={state.firstNameError}
            helperText={state.firstNameHelperText}
            width='100%'
            label='First name'
            value={firstName}
            onChange={handleFirstNameChange}
          />
          <Label>
            Last Name{' '}
            <span style={{ color: `${theme.colors.secondary}` }}>*</span>
          </Label>
          <InputField
            additionalTextPaddingLeft='15px'
            error={state.lastNameError}
            helperText={state.lastNameHelperText}
            width='100%'
            label='Last name'
            value={lastName}
            onChange={handleLastNameChange}
          />
          <Label>
            Your Email{' '}
            <span style={{ color: `${theme.colors.secondary}` }}>*</span>
          </Label>
          <InputField
            additionalTextPaddingLeft='15px'
            error={state.emailError}
            helperText={state.emailHelperText}
            width='100%'
            label='Enter your email address'
            value={email}
            onChange={handleEmailChange}
          />
          <Label>
            Phone Number{' '}
            <span style={{ color: `${theme.colors.secondary}` }}>*</span>
          </Label>
          <InputField
            additionalTextPaddingLeft='15px'
            error={state.phoneError}
            helperText={state.phoneHelperText}
            width='100%'
            label='Enter your phone number'
            placeholder='(123) 456-7890'
            value={phone}
            onChange={handlePhoneChange}
            inputComponent={MaskedInput}
            inputProps={{ mask: '(111) 111-1111' }}
            shrink={phone ? true : undefined}
          />
          {tabValue === 1 && (
            <Label>
              Date of Birth{' '}
              <span style={{ color: `${theme.colors.secondary}` }}>*</span>
            </Label>
          )}
          {tabValue === 1 && (
            <DateSectionWrapper>
              <input type='hidden' id='age'></input>
              <SelectWrapper>
                <Select
                  formLabel='Month'
                  options={monthOptions}
                  value={month}
                  onChange={handleMonthChange}
                  height='39px'
                  width={
                    dimensions.width <= 380
                      ? '290px'
                      : dimensions.width <= 750
                        ? '320px'
                        : '150px'
                  }
                ></Select>
              </SelectWrapper>

              <SelectWrapper>
                <Select
                  formLabel='Day'
                  options={dayOptions}
                  value={day}
                  onChange={handleDayChange}
                  height='39px'
                  width={
                    dimensions.width <= 380
                      ? '290px'
                      : dimensions.width <= 750
                        ? '320px'
                        : '150px'
                  }
                ></Select>
              </SelectWrapper>

              <SelectWrapper>
                <Select
                  formLabel='Year'
                  options={yearOptions}
                  value={year}
                  onChange={handleYearChange}
                  onMouseUp={() => {
                    if (isEmpty(year)) setYear((yearsTo - 67).toString())
                  }}
                  height='39px'
                  width={
                    dimensions.width <= 380
                      ? '290px'
                      : dimensions.width <= 750
                        ? '320px'
                        : '150px'
                  }
                ></Select>
              </SelectWrapper>
            </DateSectionWrapper>
          )}
          {tabValue === 1 && (
            <Label>
              {' '}
              Gender{' '}
              <span style={{ color: `${theme.colors.secondary}` }}>*</span>{' '}
            </Label>
          )}
          {tabValue === 1 && (
            <GenderSectionWrapper>
              <RadioGroup
                radioButtons={['Male', 'Female']}
                row={false}
                value={gender}
                radioGroupName='Gender'
                onChange={handleGenderChange}
              />
            </GenderSectionWrapper>
          )}
          <Label>
            {' '}
            Would you like to view rates for a Spouse/Partner?{' '}
            <span style={{ color: `${theme.colors.secondary}` }}>*</span>{' '}
          </Label>
          <SpouseSectionWrapper>
            <RadioGroup
              radioButtons={['Yes', 'No']}
              row={false}
              value={
                spouseOptIn === undefined ? '' : spouseOptIn ? 'Yes' : 'No'
              }
              radioGroupName='Spouse/Partner Opt-in'
              onChange={handleSpouseOptInChange}
            />
          </SpouseSectionWrapper>
          {spouseOptIn && tabValue === 1 && (
            <Label>
              {' '}
              Spouse/Partner’s Gender{' '}
              <span style={{ color: `${theme.colors.secondary}` }}>*</span>{' '}
            </Label>
          )}
          {spouseOptIn && tabValue === 1 && (
            <GenderSectionWrapper>
              <RadioGroup
                radioButtons={['Male', 'Female']}
                row={false}
                value={spouseGender}
                radioGroupName='Spouse/Partner’s Gender'
                onChange={handleSpouseGenderChange}
              />
            </GenderSectionWrapper>
          )}
          {spouseOptIn && tabValue === 1 && (
            <Label>
              {' '}
              Spouse/Partner’s Date of Birth{' '}
              <span style={{ color: `${theme.colors.secondary}` }}>*</span>{' '}
            </Label>
          )}
          {spouseOptIn && tabValue === 1 && (
            <DateSectionWrapper>
              <SelectWrapper>
                <Select
                  formLabel='Month'
                  options={monthOptions}
                  value={spouseMonth}
                  onChange={handleSpouseMonthChange}
                  height='39px'
                  width={
                    dimensions.width <= 380
                      ? '290px'
                      : dimensions.width <= 750
                        ? '320px'
                        : '150px'
                  }
                ></Select>
              </SelectWrapper>

              <SelectWrapper>
                <Select
                  formLabel='Day'
                  options={dayOptions}
                  value={spouseDay}
                  onChange={handleSpouseDayChange}
                  height='39px'
                  width={
                    dimensions.width <= 380
                      ? '290px'
                      : dimensions.width <= 750
                        ? '320px'
                        : '150px'
                  }
                ></Select>
              </SelectWrapper>

              <SelectWrapper>
                <Select
                  formLabel='Year'
                  options={yearOptions}
                  value={spouseYear}
                  onChange={handleSpouseYearChange}
                  onMouseUp={() => {
                    if (isEmpty(spouseYear))
                      setSpouseYear((yearsTo - 67).toString())
                  }}
                  height='39px'
                  width={
                    dimensions.width <= 380
                      ? '290px'
                      : dimensions.width <= 750
                        ? '320px'
                        : '150px'
                  }
                ></Select>
              </SelectWrapper>
            </DateSectionWrapper>
          )}
          <SectionWrapper>
            <div style={{ margin: 'auto' }}>
              <Button
                variant='contained'
                color='secondary'
                onClick={handleSubmit}
                loading={isLoading}
                disabled={isLoading}
              >
                Submit
              </Button>
            </div>
          </SectionWrapper>
          <SubFooter isMsTab={tabValue === 1 ? true : false} />
        </form>
      </Container>
    </ScrollBar>
  )
}

export default NpPopUpPrompt
