import {
  capitalizeFirstCharacter,
  getApiErrorMessage,
} from '../../utils/StringUtils'
import { isEmpty, toLower } from 'lodash'
import { useEffect, useState } from 'react'

import ApiManager from '../../api/ApiManager'
import Button from '../common/Button/Button.component'
import DateField from '../common/DateField'
import { HttpMethods } from '../../enums/HttpMethodsEnum'
import RadioGroup from '../common/RadioGroup/RadioGroup.component'
import { SnackbarTypes } from '../../enums/SnackbarTypesEnum'
import StringConstants from '../../constants/StringConstants'
import { ThreeDots } from 'react-loader-spinner'
import UrlConstants from '../../constants/UrlConstants'
import ValidationUtils from '../../utils/validation/ValidationUtils'
import customerStore from '../../datastore/CustomerStore'
import dayjs from 'dayjs'
import { getDateWithinApprovedDOBRange } from '../../utils/CommonUtils'
import moment from 'moment'
import snackbarStore from '../../datastore/SnackbarStore'
import styled from '@emotion/styled'
import theme from '../../global/theme'

interface AddDetailsProps {
  initiateTimer: () => void
}

interface HealthQuestionProps {
  id: string
  inputType: 'RADIO' | 'CHECKBOX'
  isRequired: boolean
  options: { option: string }[]
  question: string
  response: string[]
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 30vw;
  margin: auto;

  @media screen and (max-width: 750px) {
    width: 70vw;
  }

  @media screen and (max-width: 1200px) and (min-width: 750px) {
    width: 40vw;
  }
`

const HeadingContent = styled.h3`
  color: ${theme.colors.primary};
  font-weight: 600;
  font-size: 1.5rem;
  margin-top: 3rem;
  text-align: center;
  margin-bottom: 1rem;
`

const ButtonWrapper = styled.div`
  padding: 25px 0px 50px 0px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`
const Label = styled.h4`
  font-size: 16px;
  color: ${theme.colors.textGrey};
  padding: 20px 0px 5px 0px;
  font-weight: 600;
`

const RadioSectionWrapper = styled.div`
  padding-right: 220px;
  width: 145%;

  @media screen and (max-width: 750px) {
    width: 100%;
  }

  .MuiFormGroup-root {
    @media screen and (max-width: 750px) {
      width: fit-content;
    }
  }
`
const DisableText = styled.div`
  font-size: 14px;
  margin-left: 5px;
  color: ${theme.colors.secondary};
`

const ThreeDotsWrapper = styled.div`
  display: flex;
  margin: 20px;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  width: 100%;

  @media screen and (max-width: 640px) {
    width: 100%;
    margin: 20px 0px;
  }
`

const AddDetails: React.FC<AddDetailsProps> = ({ initiateTimer }) => {
  const [dob, setDOB] = useState(customerStore.getDOB().substring(0, 10))
  const [gender, setGender] = useState<string>(
    capitalizeFirstCharacter(customerStore.getGender())
  )
  const [spouseDob, setSpouseDOB] = useState(customerStore.getSpouseDOB())
  const [spouseGender, setSpouseGender] = useState<string>(
    capitalizeFirstCharacter(customerStore.getSpouseGender())
  )
  const [isTobaccoConsumer, setIsTobaccoConsumer] = useState<
    boolean | undefined
  >(undefined)
  const [isSpouseTobaccoConsumer, setIsSpouseTobaccoConsumer] = useState<
    boolean | undefined
  >(undefined)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isPageLoading, setIsPageLoading] = useState<boolean>(true)
  const [isDisabled, setIsDisabled] = useState<boolean>(true)
  const [state, setState] = useState({
    dobError: false,
    dobHelperText: '',
    spouseDobError: false,
    spouseDobHelperText: '',
    loading: false,
    buttonLoading: false,
  })
  const yearsTo = dayjs().year()
  const defaultYear = yearsTo - 67
  const currentDate = dayjs(dob || new Date())
  const defaultDate = currentDate.year(defaultYear).format('YYYY-MM-DD')

  const tobaccoData = (isSpouse: boolean) => {
    ApiManager.makeApiCallWithAuthentication(
      UrlConstants.GET_HEALTH_QUESTIONNAIRE.USECASE,
      HttpMethods.GET_METHOD,
      {
        'for-spouse': isSpouse,
      }
    )
      .then((res) => {
        if (res.status === 200) {
          const healthQuestion = res.data.data.find(
            (x: HealthQuestionProps) =>
              x.question === 'Have you used tobacco in the last 12 months?'
          )
          const response =
            (healthQuestion.responses &&
              healthQuestion.responses.length > 0 &&
              healthQuestion.responses[0] &&
              healthQuestion.responses[0].response) ||
            []
          const tempTobaccoConsumption = response.includes('Yes')
            ? true
            : response.includes('No')
            ? false
            : undefined
          if (isSpouse) {
            setIsSpouseTobaccoConsumer(tempTobaccoConsumption)
          } else {
            setIsTobaccoConsumer(tempTobaccoConsumption)
          }
        } else throw res
      })
      .catch(() => {
        snackbarStore.set({
          snackbarMessage: getApiErrorMessage(
            'fetch tobacco consumption preference'
          ),
          snackbarOpen: true,
          snackbarType: 'error',
        })
      })
      .finally(() => {
        setIsPageLoading(false) // Set loading state to false after the API call is completed
      })
  }

  useEffect(() => {
    tobaccoData(false)
    if (customerStore.getSpouseOptIn()) {
      tobaccoData(true)
    }
  }, [])

  useEffect(() => {
    const hasRequiredFields =
      !isEmpty(dob) &&
      !isEmpty(gender) &&
      (customerStore.spouseOptIn
        ? !isEmpty(spouseDob) &&
          !isEmpty(spouseGender) &&
          isSpouseTobaccoConsumer !== undefined
        : true) &&
      isTobaccoConsumer !== undefined

    setIsDisabled(!hasRequiredFields)
  }, [
    dob,
    gender,
    isTobaccoConsumer,
    spouseDob,
    spouseGender,
    isSpouseTobaccoConsumer,
  ])

  const handleGenderChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setGender(e.target.value)
  }

  const handleSpouseGenderChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSpouseGender(e.target.value)
  }

  const validateDOB = (dob: string) => {
    const dobError = !ValidationUtils.validateDOB(dob)
    setState({
      ...state,
      dobError,
      dobHelperText: isEmpty(dob)
        ? StringConstants.UI_ERROR_MSG_FOR_EMPTY_DOB
        : dobError
        ? StringConstants.UI_ERROR_MSG_FOR_INVALID_DOB
        : '',
    })
    return dobError
  }
  const handleDOBChange = (e: any) => {
    if (e) {
      const givenDOB = dayjs(e.$d).format('YYYY-MM-DD')
      if (validateDOB(givenDOB)) setDOB(givenDOB)
    }
  }
  const handleInputDOB = (e: any) => {
    if (e) {
      const givenDOB = dayjs(e.$d).format('YYYY-MM-DD')
      if (e.$y >= 1000) {
        const finalDate = getDateWithinApprovedDOBRange(e)
        validateDOB(finalDate)
        setDOB(finalDate)
      } else validateDOB(givenDOB)
    }
  }

  const validateSpouseDOB = (spouseDob: string) => {
    const spouseDobError = !ValidationUtils.validateDOB(spouseDob)
    setState({
      ...state,
      spouseDobError,
      spouseDobHelperText: isEmpty(spouseDob)
        ? StringConstants.UI_ERROR_MSG_FOR_EMPTY_DOB
        : spouseDobError
        ? StringConstants.UI_ERROR_MSG_FOR_INVALID_DOB
        : '',
    })
    return spouseDobError
  }
  const handleSpouseDOBChange = (e: any) => {
    if (e) {
      const givenDOB = dayjs(e.$d).format('YYYY-MM-DD')
      if (validateSpouseDOB(givenDOB)) setSpouseDOB(givenDOB)
    }
  }
  const handleInputSpouseDOB = (e: any) => {
    if (e) {
      const givenDOB = dayjs(e.$d).format('YYYY-MM-DD')
      if (e.$y >= 1000) {
        const finalDate = getDateWithinApprovedDOBRange(e)
        validateSpouseDOB(finalDate)
        setSpouseDOB(finalDate)
      } else validateDOB(givenDOB)
    }
  }

  const handleTobaccoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === 'Yes') setIsTobaccoConsumer(true)
    else setIsTobaccoConsumer(false)
  }

  const handleSpouseTobaccoChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (e.target.value === 'Yes') setIsSpouseTobaccoConsumer(true)
    else setIsSpouseTobaccoConsumer(false)
  }

  const fetchCustomerDetails = async (
    doNotSuppress = true
  ): Promise<boolean> => {
    let success = false
    await ApiManager.makeApiCallWithAuthentication(
      UrlConstants.GET_CUSTOMER_DETAILS.USECASE,
      HttpMethods.GET_METHOD
    )
      .then((response) => {
        if (response.status === 200) {
          const data = response.data.data
          customerStore.set(data)
          success = true
          return success
        } else throw response
      })
      .catch((err) => {
        snackbarStore.set({
          snackbarMessage: getApiErrorMessage('fetch customer details'),
          snackbarOpen: true,
          snackbarType: SnackbarTypes.ERROR,
        })
        return success
      })
    return success
  }

  const submitResponse = (data: Object) => {
    setIsLoading(true)
    ApiManager.makeApiCallWithAuthentication(
      UrlConstants.UPDATE_CUSTOMER_DETAILS.USECASE,
      HttpMethods.PATCH_METHOD,
      data
    )
      .then(async (response) => {
        if (response.status === 200) {
          if ((await fetchCustomerDetails(false)) === true) {
            customerStore.setQuotePulledStatusMS(true)
            initiateTimer()
            snackbarStore.set({
              snackbarMessage: StringConstants.SNACKBAR_SAVE_SUCCESS_MSG,
              snackbarOpen: true,
              snackbarType: SnackbarTypes.SUCCESS,
            })
          }
        } else throw response
      })
      .catch((err) => {
        snackbarStore.set({
          snackbarMessage: getApiErrorMessage('update customer details'),
          snackbarOpen: true,
          snackbarType: SnackbarTypes.ERROR,
        })
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const handleSubmit = () => {
    var data = []
    if (!isEmpty(gender) && !isEmpty(dob) && isTobaccoConsumer !== undefined) {
      validateDOB(dob)
      if (!state.dobError) {
        data.push({
          dateOfBirth: dob,
          gender: toLower(gender),
          consumesTobacco: isTobaccoConsumer,
        })
        if (customerStore.spouseOptIn) {
          if (
            !isEmpty(spouseGender) &&
            !isEmpty(spouseDob) &&
            isSpouseTobaccoConsumer !== undefined
          ) {
            validateSpouseDOB(spouseDob)
            if (!state.spouseDobError) {
              const spouseData = {
                spouseGender: toLower(spouseGender),
                spouseDateOfBirth: spouseDob,
                spouseConsumesTobacco: isSpouseTobaccoConsumer,
              }
              data[0] = Object.assign({}, data[0], spouseData)
              submitResponse(data[0])
            } else {
              snackbarStore.set({
                snackbarMessage:
                  'Please provide the valid date of birth for spouse',
                snackbarOpen: true,
                snackbarType: SnackbarTypes.WARNING,
              })
            }
          } else {
            snackbarStore.set({
              snackbarMessage: 'Please fill all the spouse details',
              snackbarOpen: true,
              snackbarType: SnackbarTypes.WARNING,
            })
          }
        } else submitResponse(data[0])
      } else {
        snackbarStore.set({
          snackbarMessage: 'Please provide the valid date of birth',
          snackbarOpen: true,
          snackbarType: SnackbarTypes.WARNING,
        })
      }
    } else {
      snackbarStore.set({
        snackbarMessage: 'Please fill all the details',
        snackbarOpen: true,
        snackbarType: SnackbarTypes.WARNING,
      })
    }
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  if (isPageLoading) {
    return (
      <ThreeDotsWrapper>
        <ThreeDots color='#222C69' height={80} width={80} />
      </ThreeDotsWrapper>
    )
  }

  return (
    <Container>
      <HeadingContent>
        Please update your gender and age to view Medicare Supplement Plans
      </HeadingContent>
      <Label>
        Date of Birth
        <span style={{ color: `${theme.colors.secondary}` }}>*</span>
      </Label>
      <DateField
        format='MM/DD/YYYY'
        value={dayjs(dayjs(dob).format('YYYY-MM-DD'))}
        minDate={dayjs(
          (moment().year() - 120).toString().substring(0, 2) + '00-01-01'
        )}
        maxDate={dayjs(moment().format('YYYY-MM-DD'))}
        defaultCalendarMonth={dayjs(
          moment(defaultDate).startOf('day').format('YYYY-MM-DD')
        )}
        error={state.dobError}
        errorText={state.dobHelperText}
        readonly={false}
        onAccept={handleDOBChange}
        onChange={handleInputDOB}
      />
      <Label>
        Gender <span style={{ color: `${theme.colors.secondary}` }}> * </span>
      </Label>

      <RadioSectionWrapper>
        <RadioGroup
          radioButtons={['Male', 'Female']}
          row={false}
          value={gender}
          radioGroupName='Gender'
          onChange={handleGenderChange}
        />
      </RadioSectionWrapper>

      <Label>
        Have you consumed tobacco in the last 12 months ?{' '}
        <span style={{ color: `${theme.colors.secondary}` }}> * </span>
      </Label>

      <RadioSectionWrapper>
        <RadioGroup
          radioButtons={['Yes', 'No']}
          row={false}
          value={
            isTobaccoConsumer === undefined
              ? ''
              : isTobaccoConsumer
              ? 'Yes'
              : 'No'
          }
          radioGroupName='Tobacco question'
          onChange={handleTobaccoChange}
        />
      </RadioSectionWrapper>
      {customerStore.spouseOptIn && (
        <Label>
          Spouse / Partner Date of Birth
          <span style={{ color: `${theme.colors.secondary}` }}>*</span>
        </Label>
      )}
      {customerStore.spouseOptIn && (
        <DateField
          format='MM/DD/YYYY'
          value={dayjs(dayjs(spouseDob).format('YYYY-MM-DD'))}
          minDate={dayjs(
            (moment().year() - 120).toString().substring(0, 2) + '00-01-01'
          )}
          maxDate={dayjs(moment().format('YYYY-MM-DD'))}
          defaultCalendarMonth={dayjs(
            moment(defaultDate).startOf('day').format('YYYY-MM-DD')
          )}
          error={state.spouseDobError}
          errorText={state.spouseDobHelperText}
          readonly={false}
          onAccept={handleSpouseDOBChange}
          onChange={handleInputSpouseDOB}
        />
      )}
      {customerStore.spouseOptIn && (
        <Label>
          Spouse / Partner Gender{' '}
          <span style={{ color: `${theme.colors.secondary}` }}> * </span>
        </Label>
      )}
      {customerStore.spouseOptIn && (
        <RadioSectionWrapper>
          <RadioGroup
            radioButtons={['Male', 'Female']}
            row={false}
            value={spouseGender}
            radioGroupName='Spouse Gender'
            onChange={handleSpouseGenderChange}
          />
        </RadioSectionWrapper>
      )}
      {customerStore.spouseOptIn && (
        <Label>
          Has your spouse / partner consumed tobacco in the last 12 months ?{' '}
          <span style={{ color: `${theme.colors.secondary}` }}> * </span>
        </Label>
      )}

      {customerStore.spouseOptIn && (
        <RadioSectionWrapper>
          <RadioGroup
            radioButtons={['Yes', 'No']}
            row={false}
            value={
              isSpouseTobaccoConsumer === undefined
                ? ''
                : isSpouseTobaccoConsumer
                ? 'Yes'
                : 'No'
            }
            radioGroupName='Spouse Tobacco question'
            onChange={handleSpouseTobaccoChange}
          />
        </RadioSectionWrapper>
      )}

      <ButtonWrapper>
        <Button
          variant='contained'
          color='secondary'
          onClick={handleSubmit}
          loading={isLoading}
          disabled={
            isDisabled ||
            customerStore.isMAQuotesProcessing ||
            customerStore.isMSQuotesProcessing ||
            customerStore.isMPartDQuotesProcessing
          }
        >
          Update
        </Button>
        {(customerStore.isMAQuotesProcessing ||
          customerStore.isMSQuotesProcessing ||
          customerStore.isMPartDQuotesProcessing) && (
          <DisableText>{StringConstants.DISABLE_BUTTON_MSG}</DisableText>
        )}
      </ButtonWrapper>
    </Container>
  )
}

export default AddDetails
