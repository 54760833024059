import Button from '../../common/Button/Button.component'
import React from 'react'
import styled from '@emotion/styled'
import theme from '../../../global/theme'

interface ModelProps {
  onConfirm: () => void
  onReject: () => void
  year: string
}

const Container = styled.div`
  width: 400px;
  min-height: 150px;

  h3 {
    color: #272f69;
    padding-top: 0px;
    padding-bottom: 30px;
  }
  p {
    margin-bottom: 16px;
    font-weight: 600;
    font-size: 14px;
    color: ${theme.colors.textDark};
  }

  div {
    display: flex;
    justify-content: space-between;
  }

  @media screen and (max-width: 750px) {
    width: 320px;
    div {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  @media screen and (max-width: 380px) {
    width: 290px;
  }

  @media screen and (min-width: 501px) and (max-width: 750px) {
    padding-top: 3px;
    margin: -20px -30px;
  }

  @media screen and (max-width: 500px) {
    padding-top: 3px;
  }

  @media screen and (min-width: 751px) {
    padding-top: 23px;
    padding-bottom: 20px;
    padding-right: 27px;
    padding-left: 31px;
    margin: -40px -50px;
  }
`
const ModalButton = styled.div`
  width: 150px;
  text-decoration: none;

  @media screen and (max-width: 750px) {
    width: 100%;
  }
`

const PlanYearChangeConfirmationPopUp: React.FC<ModelProps> = ({
  onConfirm,
  onReject,
  year,
}) => {
  return (
    <Container>
      <h3>Are you sure you want to view plans for {year}?</h3>
      <p>Click “Yes” to confirm !</p>
      <div>
        <ModalButton>
          <Button
            variant='contained'
            bold={false}
            width='100%'
            color='secondary'
            onClick={(e) => {
              onConfirm()
            }}
            fontSize='14px'
          >
            Yes
          </Button>
        </ModalButton>

        <ModalButton>
          <Button
            color='info'
            bold={false}
            width='100%'
            onClick={(e) => {
              onReject()
            }}
            fontSize='14px'
          >
            No
          </Button>
        </ModalButton>
      </div>
    </Container>
  )
}

export default PlanYearChangeConfirmationPopUp
